import { Box } from "components/Box";
import { Card } from "components/Card";
import { useDropdown } from "hooks/dropdown";
import React from "react";
import styled from "styled-components";

export interface IDropdown {
  items: Array<{
    label: string;
    onClick: () => any;
  }>;
}

const Root = styled.div`
  position: relative;
`;

const WrapperChildren = styled.div`
  cursor: pointer;
`;
const Item = styled.div`
  cursor: pointer;
`;
const WrapperDropdown = styled.div`
  ${({ theme }) => `
    right: 0;
    position: absolute;
    top: 100%;
    margin-top: ${theme.space[2]}px;
    min-width: 180px;
    z-index: 1000;
  `}
`;

export const Dropdown: React.FC<IDropdown> = ({ items, children }) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  return (
    <Root>
      <WrapperChildren ref={buttonRef} role="button" aria-hidden="true">
        {children}
      </WrapperChildren>
      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Card>
            <Box p={5}>
              {items.map(({ label, onClick }) => (
                <Item
                  key={label}
                  onClick={handleClick(onClick)}
                  aria-hidden="true"
                  role="button"
                >
                  <p>{label}</p>
                </Item>
              ))}
            </Box>
          </Card>
        </WrapperDropdown>
      )}
    </Root>
  );
};
