import styled from "styled-components";

interface IWrapperProblem {
  severity: string;
}

export const ProblemTag = styled.div<IWrapperProblem>`
  ${({ severity, theme }) => `
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: ${theme.space[1]}px;
    text-align: center;
    line-height: 25px;
    background-color: ${
      severity === "error"
        ? theme.palette.neutral.error
        : theme.palette.neutral.warring
    };
    color: ${
      severity === "error"
        ? theme.palette.text.error
        : theme.palette.text.warring
    };
    border-radius: 50%;
  `}
`;
