import { FieldSetTitle } from "components/FieldSetTitle";
import { Label } from "components/Select";
import MultiSelect, { OptionsType } from "react-select";
import { useState, useEffect } from "react";
import { useFetchDictionaryTags } from "hooks/fetchDictionaryTags";
import { DisruptionTag } from "components/DisruptionTag";

interface IDisruptionTags {
  onChange: (tags: OptionsType<IOption>) => void;
  selectedOptions?: { id: string; name: string }[];
}

interface IOption {
  label: string;
  value: string;
  active: boolean;
}

export const DisruptionTags: React.FC<IDisruptionTags> = ({
  onChange,
  selectedOptions,
}) => {
  const [optionsTags, setOptionsTags] = useState<Array<IOption>>([]);
  const { dictionaryTags } = useFetchDictionaryTags();

  const handleRemove = (elementId: string) => {
    const newVal = selectedOptions
      ?.filter((val) => val.id !== elementId)
      .map(({ id, name }) => ({ value: id, label: name, active: true }));

    if (newVal && onChange) onChange(newVal);
  };

  useEffect(() => {
    dictionaryTags &&
      setOptionsTags(
        dictionaryTags
          ?.filter((val) => val.active === true)
          .map(({ id, name }) => ({
            value: id,
            label: name,
            active: true,
          })) ?? []
      );
  }, [dictionaryTags]);

  return optionsTags ? (
    <>
      <FieldSetTitle>Wybierz Tag</FieldSetTitle>
      <Label>Tag</Label>
      <MultiSelect<IOption, true>
        value={selectedOptions?.map((tag) => ({
          label: tag.name,
          value: tag.id,
          active: true,
        }))}
        onChange={onChange}
        options={optionsTags}
        isMulti
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
      />

      {selectedOptions?.map((tag) => (
        <DisruptionTag
          key={tag.id}
          value={tag.id}
          label={tag.name}
          handleRemove={handleRemove}
        />
      ))}
    </>
  ) : null;
};
