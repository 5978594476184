import React from "react";
import { PageLayout } from "components/PageLayout";
import { Card } from "components/Card";
import { Box } from "components/Box";
import { RailwayLineForm } from "containers/RailwayLineForm";
import styled from "styled-components";

const StyledCard = styled(Card)`
  width: 90%;
  margin: 20px auto 0;
`

export const AddRailwayLineView: React.FC = () => {
  return (
    <PageLayout displaySidebarTable={false} pageTitle="Dodaj kolej liniowy">
      <StyledCard>
        <Box mt={8} px={8} py={6}>
          <RailwayLineForm />
        </Box>
      </StyledCard>
    </PageLayout>
  );
};
