export const rolesByExports = {
  "OR,ORR,DOR-SABP": {
    LIST: "ROLE_EXPORTS_SA_LIST",
    CREATE: "ROLE_EXPORTS_SA_CREATE",
    SHOW: "ROLE_EXPORTS_SA_SHOW",
  },
  "OR,ORR,DOR-CW": {
    LIST: "ROLE_EXPORTS_CW_LIST",
    CREATE: "ROLE_EXPORTS_CW_CREATE",
    SHOW: "ROLE_EXPORTS_CW_SHOW",
  },
  customer_report: {
    LIST: "ROLE_EXPORTS_CUSTOMER_REPORT_LIST",
    CREATE: "ROLE_EXPORTS_CUSTOMER_REPORT_CREATE",
    SHOW: "ROLE_EXPORTS_CUSTOMER_REPORT_SHOW",
  },
  FDS: {
    LIST: "ROLE_EXPORTS_FDS_TASK_LIST",
    CREATE: "ROLE_EXPORTS_FDS_TASK_CREATE",
    SHOW: "ROLE_EXPORTS_FDS_TASK_SHOW",
  },
  "OTK-Fonon": {
    LIST: "ROLE_EXPORTS_OTK_FONON_LIST",
    CREATE: "ROLE_EXPORTS_OTK_FONON_CREATE",
    SHOW: "ROLE_EXPORTS_OTK_FONON_SHOW",
  },
  "OTK-SPC-2": {
    LIST: "ROLE_EXPORTS_OTK_SPC_2_LIST",
    CREATE: "ROLE_EXPORTS_OTK_SPC_2_CREATE",
    SHOW: "ROLE_EXPORTS_OTK_SPC_2_SHOW",
  },
  HRF: {
    LIST: "ROLE_EXPORTS_HRF_LIST",
    CREATE: "ROLE_EXPORTS_HRF_CREATE",
    SHOW: "ROLE_EXPORTS_HRF_SHOW",
  },
  MRW: {
    LIST: "ROLE_EXPORTS_MRW_LIST",
    CREATE: "ROLE_EXPORTS_MRW_CREATE",
    SHOW: "ROLE_EXPORTS_MRW_SHOW",
  },
  payment_schedule: {
    LIST: "ROLE_EXPORTS_PAYMENT_SCHEDULE_LIST",
    CREATE: "ROLE_EXPORTS_PAYMENT_SCHEDULE_CREATE",
    SHOW: "ROLE_EXPORTS_PAYMENT_SCHEDULE_SHOW",
  },
};
