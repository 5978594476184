import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => `
    html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }

    body {
      margin: 0;
      font-family: Lato, sans-serif;
      font-size: 13px;
      line-height: 18px;
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background};
    }
    button {
      font-family: Lato, sans-serif;
    }

    .u-pointer {
      cursor: pointer;
    }
    .item-child {
        background-color: #f5f5f5;
    }
    div .item-child:first-child {
      border-top: 1px solid;
    }
    .item-child .td:first-child {
        padding-left: 30px !important;
    }
    .item-child .td:last-child {
        padding-right: 30px !important;
    }
    .bgc-white {
        background-color: white;
    }

    // react-tabs
    .react-tabs {
      &__tab-list {
        display: flex;
        margin: 0;
        padding: 0 ${theme.space[6]}px;
        border-bottom: 1px solid rgba(30, 46, 102, 0.1);
      }

      &__tab {
        top: 0;
        bottom: 0;
        display: inline-flex;
        align-items: center;
        height: 56px;
        padding: 0 ${theme.space[4]}px;
        text-align: center;
        color: rgba(30, 46, 102, 0.65);
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 13px;
        text-transform: uppercase;
        border: 0;

        &--selected {
          color: ${theme.palette.primary};

          &::after {
            content: '';
            display: block;
            height: 2px;
            background-color: ${theme.palette.primary};
            position: absolute;
            bottom: 0;
            left: ${theme.space[3]}px;
            right: ${theme.space[3]}px;
            border-radius: 4px;
          }
        }
      }
    }
    
    // react-datepicker
    .react-datepicker-popper {
      z-index: 10;
    }

    .hidden-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .hidden-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  `}
`;
