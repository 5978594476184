import React from "react";
import { PageLayout } from "components/PageLayout";
import { Card } from "components/Card";
import { Box } from "components/Box";
import { KeyForm } from "containers/KeyForm";

export const AddKeyView: React.FC = () => {
  return (
    <PageLayout pageTitle="Dodaj klucz" isScroll>
      <Card>
        <Box px={8} py={6}>
          <KeyForm />
        </Box>
      </Card>
    </PageLayout>
  );
};
