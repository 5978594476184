import React, { useContext, useState } from "react";

export interface ILayoutStateContext {
  visibleSidebar: boolean;
  setVisibleSidebar: (visibleSidebar: boolean) => void;
}

const LayoutStateContext = React.createContext<ILayoutStateContext | undefined>(
  undefined
);

export const LayoutContextProvider: React.FC = ({ children }) => {
  const [visibleSidebar, setVisibleSidebar] = useState(true);

  return (
    <LayoutStateContext.Provider value={{ visibleSidebar, setVisibleSidebar }}>
      {children}
    </LayoutStateContext.Provider>
  );
};

export const useLayoutStateContext = () => {
  const context = useContext(LayoutStateContext);
  if (!context) {
    throw new Error(
      "useLayoutStateContext must be inside a LayoutContextProvider with a value"
    );
  }
  return context;
};
