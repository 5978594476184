import { Button } from "components/Button";
import { Input } from "components/Input";
import { PageLayout } from "components/PageLayout";
import { Select } from "components/Select";
import { Table } from "components/Table";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { envProjectName } from "envData";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { useMemo, useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Column } from "react-table";
import { toast } from "react-toastify";
import {
  fetchExportFile,
  fetchExportGenerateFile,
  fetchExportGenerateHRFFile,
  fetchExportHRFFile,
  fetchExportsHistory,
  fetchHRFExportHistory,
  fetchImportsHistory,
  fetchKOMRW,
  fetchKOMRWSingleFile,
  fetchKOMRWmulti,
  fetchMRWImportsHistory,
  taskMetaContextType,
  fetchExportCustomerFile,
  fetchKOsbyMRW,
  fetchExportsHistoryDepr,
  fetchExportGenerateFileDepr,
  fetchImportFile,
  fetchMRWImportFile,
} from "services/importExport";
import { MRWKOsgroup } from "types/importExport";
import { downloadFile } from "utilities/downloadFile";
import { ddmmyyyyTo_mmddyyyy, formatDate } from "utilities/formatDate";
import {
  HelperPanelWrapper,
  Header,
  TitlePanelWrapper,
  ColouredColumnName,
} from "./ImportExportPanelView.styled";
import { rolesByImports } from "constants/rolesByImports";
import { rolesByExports } from "constants/rolesByExports";

type IImportExportPanelTable = {
  name?: string;
  date?: string;
  status?: string;
  mrwNumber?: string;
  mrwId?: string;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  objectNumber?: string;
  objectType?: string;
  fileNumber?: string;
  filename?: string;
  fileName?: string;
  period?: string;
  project?: string;
  reportName?: string;
  report?: { reportFileName: string; id: string };
};

interface IRawTableData {
  hash: string;
  date: string;
  sheetName: string;
  customerStatus: string;
  createdAt: string;
  fileName?: string;
  status?: string;
}

interface IGroupedMRWsById {
  key: string;
  value: string[];
}

const taskMetaContexts: taskMetaContextType[] = [
  "SA",
  "CW",
  "TI",
  "customer_report",
  "HRF",
  "MRW",
  "OTK",
  "PŚP",
  "task_areas",
  "payment_schedule",
  "FDS",
  "objects",
  "payment_date",
];

const translatedContextParam = {
  "OTK-Fonon": "fonon",
  "OTK-SPC-2": "spc-2",
  PŚP: "psp",
  FDS: "fds",
  objects: "general",
};

const translatedContextType = {
  OTK: "otk",
  PŚP: "psp",
  task_areas: "task_areas",
  FDS: "fds",
  objects: "objects",
  payment_date: "payment_date",
};

export const ImportExportPanelView = () => {
  const { setTableData } = useTableSidebarDispatchContext();

  const params = useParams<{ actionType: string; type: string }>();

  const [contextType, setContextType] = useState<taskMetaContextType | null>();
  const [tableContent, setTableContent] = useState<
    IImportExportPanelTable[] | []
  >([]);

  const [mrwTableContent, setMrwTableContent] = useState<MRWKOsgroup[] | []>(
    []
  );

  const [isGenerationButtonActive, setIsGenerationButtonActive] =
    useState(true);
  const [inter, setInter] = useState<NodeJS.Timeout | null>(null);
  const [minutes, setMinutes] = useState(0);

  const [reportName, setReportName] = useState("hrf");

  const [KOsbyMRW, setKOsbyMRW] = useState([]);

  const [isTableDropDown, setTableDropDown] = useState<boolean>(false);

  const history = useHistory();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const getContextTypeFromURL = (url: string) => {
    taskMetaContexts.map((item) => {
      url.includes(item) ? setContextType(item) : setTableContent([]);
    });
  };

  const convertDate = (inputFormat: string) => {
    function pad(s: number) {
      return s < 10 ? "0" + s : s;
    }
    const d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  };

  const filterExportDataWithoutProblems = useCallback(
    (rawData: IRawTableData[]): IImportExportPanelTable[] => {
      return rawData.map((item: IRawTableData) => ({
        ...item,
        name: item.hash || item.fileName,
        date: convertDate(item.date || item.createdAt),
        status: item.status === "generated" ? "wygenerowano" : "przesłano",
      }));
    },
    []
  );

  const filterImportDataWithoutProblems = useCallback(
    (rawData: IRawTableData[]): IImportExportPanelTable[] => {
      return rawData.map((item: IRawTableData) => ({
        ...item,
        name: item.sheetName,
        date: convertDate(item.createdAt),
        status: item.customerStatus == "uploaded" ? "przesłano" : "przesyłanie",
      }));
    },
    []
  );

  const downloadZip = (mrwUid, fileName) =>
    fetchKOMRWmulti(mrwUid)
      .then((response) => response.blob())
      .then((blob) => {
        downloadFile(blob, `${fileName}`);
      })
      .catch(() => {
        toast.error("Wystąpił problem z pobieraniem pliku.");
      });

  const retrieveTasks = useCallback(() => {
    if (contextType) {
      if (params.actionType === "export") {
        if (contextType === "HRF") {
          fetchHRFExportHistory({
            limit: 20,
          })
            .then(({ data }) => {
              setTableContent(data);
            })
            .catch(() => {
              setTableContent([]);
            });
        } else if (contextType === "MRW") {
          getMRWs();
        } else if (
          contextType === "customer_report" ||
          contextType === "payment_schedule" ||
          contextType === "FDS" ||
          contextType === "OTK"
        ) {
          fetchExportsHistory(
            translatedContextType[contextType] || contextType,
            contextType === "OTK" && translatedContextParam[params.type]
          )
            .then(({ data }) => {
              setTableContent(
                filterExportDataWithoutProblems(
                  data as unknown as IRawTableData[]
                )
              );
            })
            .catch(() => {
              setTableContent([]);
            });
        } else {
          fetchExportsHistoryDepr({
            context: contextType,
          })
            .then((response) => {
              setTableContent(
                filterExportDataWithoutProblems(response as IRawTableData[])
              );
            })
            .catch(() => {
              setTableContent([]);
            });
        }
      } else if (params.actionType === "import") {
        if (contextType === "MRW") {
          fetchMRWImportsHistory({})
            .then((response) => {
              setTableContent(response.data);
            })
            .catch(() => {
              setTableContent([]);
            });
        } else {
          fetchImportsHistory({
            context: translatedContextParam[params.type] || contextType,
            type: translatedContextType[contextType] || "task_meta",
          })
            .then((response) => {
              setTableContent(
                filterImportDataWithoutProblems(
                  (response as { data: IRawTableData[] }).data
                )
              );
            })
            .catch(() => {
              setTableContent([]);
            });
        }
      }
    }
  }, [
    contextType,
    filterExportDataWithoutProblems,
    filterImportDataWithoutProblems,
    params.actionType,
  ]);

  const handleClickName = useCallback(
    (itemName: string, fileName?: string) => {
      if (params.actionType === "export") {
        if (
          contextType &&
          loggedUserRoles?.includes("ROLE_EXPORT_SHOW") &&
          loggedUserRoles?.includes(rolesByExports[params.type]["SHOW"])
        ) {
          if (contextType === "HRF") {
            fetchExportHRFFile({
              exportName: itemName,
            })
              .then((response) => response.blob())
              .then((blob) => {
                downloadFile(blob, `${itemName}.xlsx`);
              })
              .catch(() => {
                toast.error("Wystąpił problem z pobieraniem pliku.");
              });
          } else if (contextType === "MRW") {
            fetchKOMRWSingleFile(itemName)
              .then((response) => response.blob())
              .then((blob) => {
                downloadFile(blob, `${fileName}.xlsx`);
              })
              .catch(() => {
                toast.error("Wystąpił problem z pobieraniem pliku.");
              });
          } else if (
            contextType === "customer_report" ||
            contextType === "FDS" ||
            contextType === "OTK" ||
            contextType === "payment_schedule"
          ) {
            fetchExportCustomerFile(itemName)
              .then((response) => response.blob())
              .then((blob) => {
                downloadFile(blob, `${fileName}`);
              })
              .catch(() => {
                toast.error("Wystąpił problem z pobieraniem pliku.");
              });
          } else {
            fetchExportFile({
              context: contextType,
              exportName: itemName,
            })
              .then((response) => response.blob())
              .then((blob) => {
                downloadFile(blob, `${fileName}.xlsx`);
              })
              .catch(() => {
                toast.error("Wystąpił problem z pobieraniem pliku.");
              });
          }
        }
      }

      if (params.actionType === "import") {
        if (
          contextType &&
          loggedUserRoles?.includes("ROLE_IMPORT_SHOW") &&
          loggedUserRoles?.includes(rolesByImports[params.type]["SHOW"])
        ) {
          if (contextType === "MRW") {
            fetchMRWImportFile(itemName)
              .then((response) => response.blob())
              .then((blob) => {
                downloadFile(blob, `${itemName}.xlsx`);
              })
              .catch(() => {
                toast.error("Wystąpił problem z pobieraniem pliku.");
              });
          } else {
            fetchImportFile(itemName)
              .then((response) => response.blob())
              .then((blob) => {
                downloadFile(blob, `${itemName}.xlsx`);
              })
              .catch(() => {
                toast.error("Wystąpił problem z pobieraniem pliku.");
              });
          }
        }
      }
    },
    [contextType, params.actionType, loggedUserRoles]
  );

  const handleImportingClick = () => {
    if (contextType) {
      history.push(`/import-export-panel/new/import/${params.type}`);
    }
  };

  const handleGenerationClick = () => {
    if (contextType) {
      setIsGenerationButtonActive(false);
      localStorage.setItem("generationTime", new Date().toString());

      startCheckingGenerationTime();

      if (contextType === "HRF") {
        fetchExportGenerateHRFFile({ reportName })
          .then(() => {
            toast.info(
              "Raport pojawi się za kilka lub kilkanaście minut. Należy odświeżyć stronę po wygenerowaniu raportu."
            );
            retrieveTasks();
          })
          .catch(() => {
            toast.error("Wystąpił problem z generowaniem pliku.");
          });
      } else if (
        contextType === "customer_report" ||
        contextType === "payment_schedule" ||
        contextType === "FDS" ||
        contextType === "OTK"
      ) {
        fetchExportGenerateFile(
          translatedContextType[contextType] || contextType,
          contextType === "OTK" && translatedContextParam[params.type]
        )
          .then(() => {
            toast.info(
              "Raport pojawi się za kilka lub kilkanaście minut. Należy odświeżyć stronę po wygenerowaniu raportu."
            );
            retrieveTasks();
          })
          .catch(() => {
            toast.error("Wystąpił problem z generowaniem pliku.");
          });
      } else {
        fetchExportGenerateFileDepr({
          context: contextType,
        })
          .then(() => {
            toast.info("Raport pojawi się za 15 minut.");
          })
          .catch(() => {
            toast.error("Wystąpił problem z generowaniem pliku.");
          });
      }
    }
  };

  const CheckGenerationTime = () => {
    if (
      new Date().getTime() -
        Date.parse(localStorage.getItem("generationTime") as string) >
      900000
    ) {
      setIsGenerationButtonActive(true);
      localStorage.removeItem("generationTime");
    }
  };

  const startCheckingGenerationTime = useCallback(() => {
    setMinutes(0);
    setIsGenerationButtonActive(false);
    CheckGenerationTime();
    setInter(
      setInterval(() => {
        CheckGenerationTime();
        setMinutes((minutes) => minutes + 1);
      }, 60000)
    );
  }, []);

  useEffect(() => {
    if (localStorage.getItem("generationTime")) {
      startCheckingGenerationTime();
    }
  }, [startCheckingGenerationTime]);

  useEffect(() => {
    if (minutes >= 15) {
      setIsGenerationButtonActive(true);
      if (inter) clearInterval(inter);
    }
  }, [minutes]);

  useEffect(() => {
    getContextTypeFromURL(params.type);
  }, [params.type]);

  useEffect(() => {
    retrieveTasks();
  }, [retrieveTasks]);

  useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, [setTableData]);

  const getMRWs = (limit = undefined) =>
    fetchKOMRW({ limit })
      .then((response) => {
        setMrwTableContent(response.data);
      })
      .catch(() => {
        setTableContent([]);
      });

  const getKOsbyMRW = (uploadedMrwId) =>
    fetchKOsbyMRW({ uploadedMrwId })
      .then((response) => {
        setKOsbyMRW(response.data);
      })
      .catch(() => {
        setKOsbyMRW([]);
      });

  const topLevelColumns: Array<Column<MRWKOsgroup>> = useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "zipFilename",
        Cell: (item) => (
          <ColouredColumnName
            onClick={() => {
              setTableDropDown((prev) => !prev);
              setMrwTableContent([item.row.original]);
              getKOsbyMRW(item.row.original.uploadedMrwId);
            }}
          >
            {item.row.original.zipFilename}
          </ColouredColumnName>
        ),
      },
      {
        Header: "liczba obiektów",
        accessor: "mrwNumber",
        Cell: (item) => (
          <ColouredColumnName>{item.row.original.countKO}</ColouredColumnName>
        ),
      },
      {
        Header: "Pobierz ZIP",
        accessor: "uploadedMrwId",
        Cell: (item) => (
          <Button
            onClick={() =>
              downloadZip(
                item.row.original.uploadedMrwId,
                item.row.original.zipFilename
              )
            }
          >
            pobierz ZIP
          </Button>
        ),
      },
    ],
    [contextType, handleClickName, params.actionType]
  );

  const columns: Array<Column<IImportExportPanelTable>> = useMemo(
    () =>
      contextType === "HRF"
        ? [
            {
              Header: "Raport",
              disableSortBy: true,
              Cell: (item) => (
                <ColouredColumnName
                  onClick={() => handleClickName(item.row.original.report.id)}
                >
                  {item.row.original?.report?.reportFileName}
                </ColouredColumnName>
              ),
            },
            {
              Header: "Nazwa raportu",
              accessor: "reportName",
            },
            {
              Header: "Status",
              Cell: (item) =>
                item.row.original.status === "new" ? "generowanie" : "gotowy",
            },
            {
              Header: "Stworzono",
              accessor: "createdAt",
              Cell: (item) =>
                item?.row?.original?.createdAt
                  ? formatDate(new Date(item.row.original.createdAt))
                  : "",
              sortType: (a, b) => {
                return (
                  new Date(ddmmyyyyTo_mmddyyyy(b.values.createdAt)).valueOf() -
                  new Date(ddmmyyyyTo_mmddyyyy(a.values.createdAt)).valueOf()
                );
              },
            },
            {
              Header: "Zaktualizowano",
              accessor: "updatedAt",
              Cell: (item) =>
                item?.row?.original?.updatedAt
                  ? formatDate(new Date(item.row.original.updatedAt))
                  : "",
              sortType: (a, b) => {
                return (
                  new Date(ddmmyyyyTo_mmddyyyy(b.values.updatedAt)).valueOf() -
                  new Date(ddmmyyyyTo_mmddyyyy(a.values.updatedAt)).valueOf()
                );
              },
            },
          ]
        : contextType === "MRW" && params.actionType === "export"
        ? [
            {
              Header: "ID",
              accessor: "id",
              disableSortBy: true,
              width: "250",
              Cell: (item) => (
                <ColouredColumnName
                  onClick={() =>
                    handleClickName(
                      item.row.original.id,
                      item.row.original.fileName
                    )
                  }
                >
                  {item.row.original.fileName}
                </ColouredColumnName>
              ),
            },
            {
              Header: "Numer MRW",
              accessor: "mrwNumber",
            },
            {
              Header: "Typ obiektu",
              accessor: "objectType",
            },
            {
              Header: "Numer obiektu",
              accessor: "objectNumber",
            },
            {
              Header: "Utworzono",
              accessor: "createdAt",
              sortType: (a, b) => {
                return (
                  new Date(b.values.createdAt).valueOf() -
                  new Date(a.values.createdAt).valueOf()
                );
              },
            },
            {
              Header: "Zaktualizowano",
              accessor: "updatedAt",
              sortType: (a, b) => {
                return (
                  new Date(b.values.updatedAt).valueOf() -
                  new Date(a.values.updatedAt).valueOf()
                );
              },
            },
          ]
        : contextType === "MRW" && params.actionType === "import"
        ? [
            {
              Header: "ID",
              accessor: "id",
              disableSortBy: true,
              Cell: (item) => (
                <ColouredColumnName
                  onClick={() =>
                    handleClickName(
                      item.row.original.id,
                      item.row.original.filename
                    )
                  }
                >
                  {item.row.original.id}
                </ColouredColumnName>
              ),
            },
            {
              Header: "Numer pliku",
              accessor: "fileNumber",
            },
            {
              Header: "Nazwa pliku",
              accessor: "filename",
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: (item) =>
                item.row.original.status === "processed"
                  ? "przetworzony"
                  : "czeka na rozpatrzenie",
            },
            {
              Header: "Okres",
              accessor: "period",
              disableSortBy: true,
            },
            {
              Header: "Utworzono",
              accessor: "createdAt",
              sortType: (a, b) => {
                return (
                  new Date(b.values.createdAt).valueOf() -
                  new Date(a.values.createdAt).valueOf()
                );
              },
            },
            {
              Header: "Zaktualizowano",
              accessor: "updatedAt",
              sortType: (a, b) => {
                return (
                  new Date(b.values.updatedAt).valueOf() -
                  new Date(a.values.updatedAt).valueOf()
                );
              },
            },
            {
              Header: "Projekt",
              accessor: "project",
            },
          ]
        : contextType === "customer_report"
        ? [
            {
              Header: "Nazwa",
              accessor: "fileName",
              disableSortBy: true,
              Cell: (item) => (
                <ColouredColumnName
                  onClick={() =>
                    handleClickName(
                      item.row.original.id,
                      item.row.original.fileName
                    )
                  }
                >
                  {item.row.original.fileName}
                </ColouredColumnName>
              ),
            },
            {
              Header: "Data",
              accessor: "createdAt",
              sortType: (a, b) => {
                return (
                  new Date(ddmmyyyyTo_mmddyyyy(b.values.createdAt)).valueOf() -
                  new Date(ddmmyyyyTo_mmddyyyy(a.values.createdAt)).valueOf()
                );
              },
              Cell: (item) => formatDate(new Date(item.row.original.createdAt)),
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: (item) =>
                item.row.original.status === "generated"
                  ? "wygenerowano"
                  : "generowanie",
            },
          ]
        : [
            {
              Header: "Nazwa",
              accessor: "name",
              disableSortBy: true,
              Cell: (item) => (
                <ColouredColumnName
                  onClick={() =>
                    handleClickName(
                      item.row.original.id || item.row.original.name,
                      item.row.original.name
                    )
                  }
                >
                  {item.row.original.name}
                </ColouredColumnName>
              ),
            },
            {
              Header: "Data",
              accessor: "date",
              sortType: (a, b) => {
                return (
                  new Date(ddmmyyyyTo_mmddyyyy(b.values.date)).valueOf() -
                  new Date(ddmmyyyyTo_mmddyyyy(a.values.date)).valueOf()
                );
              },
            },
            {
              Header: "Status",
              disableSortBy: true,
              accessor: "status",
            },
          ],
    [contextType, handleClickName, params.actionType]
  );

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <HelperPanelWrapper>
        <Header>
          {params.actionType.charAt(0).toUpperCase() +
            params.actionType.slice(1)}{" "}
          {params.type}
        </Header>

        {contextType === "HRF" && params.actionType === "export" && (
          <Select
            onChange={(e) => {
              setReportName(e.target.value);
            }}
          >
            {envProjectName !== "herkules" && (
              <option value="hrf">Wszystkie</option>
            )}
            <option value="or">OR</option>
            {envProjectName !== "herkules" && (
              <>
                <option value="disruption">Zakłócenia</option>
                <option value="fds">FDS</option>
                <option value="tunnel">Tunnele</option>
                <option value="otk-fonon">OTK-Fonon</option>
                <option value="otk-spc2">OTK-SPC2</option>
                <option value="otk">OTK</option>
                <option value="milestone">Kamienie Milowe</option>
                <option value="we-certification">Certyfikacje WE</option>
                <option value="utk-certification">Nr Dopuszczenia UTK</option>
                <option value="ready-to-we-certification">Nr gotowości</option>
                <option value="oc-osz">OC-OSZ</option>
              </>
            )}
          </Select>
        )}

        {contextType !== "MRW" && params.actionType === "export"
          ? loggedUserRoles?.includes(
              rolesByExports[params.type]["CREATE"]
            ) && (
              <Button
                disabled={!isGenerationButtonActive}
                bordered
                onClick={handleGenerationClick}
              >
                generuj
              </Button>
            )
          : params.actionType === "import" &&
            loggedUserRoles?.includes(
              rolesByImports[params.type]["CREATE"]
            ) && (
              <Button bordered onClick={handleImportingClick}>
                importuj
              </Button>
            )}
      </HelperPanelWrapper>

      <TitlePanelWrapper>
        Historia{" "}
        {params.actionType === "export" ? "exportowanych" : "zaimportowanych"}{" "}
        plików
      </TitlePanelWrapper>

      {contextType === "MRW" && params.actionType === "export" ? (
        <Table
          fullHeight={true}
          columns={topLevelColumns}
          data={mrwTableContent}
        />
      ) : (
        <Table<IImportExportPanelTable>
          fullHeight={true}
          columns={columns}
          data={tableContent}
        />
      )}
      {contextType === "MRW" &&
        params.actionType === "export" &&
        KOsbyMRW.length &&
        isTableDropDown && (
          <Table fullHeight={true} columns={columns} data={KOsbyMRW} />
        )}
    </PageLayout>
  );
};
