import React, { useContext, useState } from "react";

export interface ITaskContext {
  locationName: string | null;
}

export interface ITaskDispatchContext {
  setLocationName: (locationName: string | null) => void;
}

const TaskContext = React.createContext<ITaskContext | undefined>(undefined);

const TaskDispatchContext = React.createContext<
  ITaskDispatchContext | undefined
>(undefined);

export const TaskContextProvider: React.FC = ({ children }) => {
  const [locationName, setLocationName] = useState<string | null>(null);

  return (
    <TaskContext.Provider value={{ locationName }}>
      <TaskDispatchContext.Provider
        value={{
          setLocationName,
        }}
      >
        {children}
      </TaskDispatchContext.Provider>
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error(
      "useTaskContext must be inside a TaskContextProvider with a value"
    );
  }

  return context;
};

export const useTaskDispatchContext = () => {
  const context = useContext(TaskDispatchContext);
  if (!context) {
    throw new Error(
      "useTaskDispatchContext must be inside a TaskDispatchProvider with a value"
    );
  }

  return context;
};
