/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList } from "react-tabs";
import {
  getDateTwo,
  getDateTwoSABPFormSection,
  getDateTwoSABPFormStructure,
  getDateTwoTI,
  updateDateTwo,
} from "services/tasks";
import { DateTwoDetails } from "containers/DateTwoDetails/DateTwoDetails";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import React, { useEffect, useMemo, useState } from "react";
import { StyledContainer, StyledTabPanel } from "./DateTwo.styled";
import { Accordion } from "components/Accordion/Accordion";
import { envSABPForm, envTIForm } from "envData";
import { DateTwoSABPForm } from "containers/DateTwoSABPForm/DateTwoSABPForm";
import { DateTwoTIForm } from "containers/DateTwoTIForm/DateTwoTIForm";
import { IDateTwoDataSABPandTI, IDateTwoDataTI } from "types/dateTwo";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { rolesByObjects } from "constants/rolesByObjects";

export const DateTwo = ({
  remoteFolderPath,
}: {
  remoteFolderPath?: string;
}) => {
  const [dataCW, setDataCW] = useState<any[]>([]);
  const [dataSABP, setDataSABP] = useState<IDateTwoDataSABPandTI[]>([]);
  const [dataTI, setDataTI] = useState<IDateTwoDataSABPandTI[]>();
  const { setTableData } = useTableSidebarDispatchContext();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const params = useParams<{ taskId: string; type: string }>();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const typeWork = [
    loggedUserRoles?.includes(rolesByObjects[params.type]["DATE2_CW_SHOW"]) && {
      label: "CW",
      content: dataCW,
    },
    envTIForm == "on" &&
      loggedUserRoles?.includes(
        rolesByObjects[params.type]["DATE2_TI_SHOW"]
      ) && {
        label: "TI",
        content: dataTI,
      },
    loggedUserRoles?.includes(
      rolesByObjects[params.type]["DATE2_SABP_SHOW"]
    ) && {
      label: "SABP",
      content: dataSABP,
    },
  ].filter((item) => item !== undefined);

  const openedTabs = useMemo(
    () => ({
      CW: [false, false, false, false, false, false, false],
      SABP: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    }),
    []
  );

  // now only for "dane_ogolne_dotyczace_pwk", indexes 6, 10, 26
  const indexSeparation = {
    dane_ogolne_dotyczace_pwk: [6, 11, 28],
  };
  const prepareFieldSeparators = (
    dateTwoDataSABPtaSABP: IDateTwoDataSABPandTI
  ) => {
    const group = dateTwoDataSABPtaSABP.groups.find(
      (el) => el.name === "dane_ogolne_dotyczace_pwk"
    );
    if (!group || !group.fields || group.fields.length < 1) return;
    for (const index of indexSeparation.dane_ogolne_dotyczace_pwk) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      group.fields.splice(index, 0, { type: "breakLine" });
    }
  };

  const OnAccordionSABPClick = (sectionName: string) => {
    getDateTwoSABPFormSection(params.taskId, sectionName).then((res) => {
      prepareFieldSeparators(res);
      setDataSABP((prevState) => {
        prevState.find((item) => item.name === sectionName).groups = res.groups;
        return [...prevState];
      });
    });
  };

  const saveUpdateDate = (
    dateId: string,
    dateValue: {
      name: string;
      value: string;
    }
  ) => {
    updateDateTwo(dateId, dateValue.value).then(() => fetchData());
  };

  const fetchData = () => {
    getDateTwo(params.taskId, "CW").then((res) => {
      setDataCW(res);
    });

    getDateTwoTI(params.taskId).then((res) => {
      const restructedRes = res.map((accordionItem) => ({
        ...accordionItem,
        items: accordionItem.items.map((item) =>
          item.type === "simple_date"
            ? {
                ...item,
                value: {
                  actual: item.actual,
                  forecast: item.forecast,
                  custom: [
                    {
                      id: "",
                      value: item.customText,
                      label: "",
                      type: "",
                    },
                  ],
                },
              }
            : item
        ),
      }));

      setDataTI(restructedRes);
    });

    if (envSABPForm == "on") {
      getDateTwoSABPFormStructure(params.taskId).then((res) => {
        setDataSABP(res);
      });
    } else {
      getDateTwo(params.taskId, "SA").then((res) => {
        const restructedRes = res.map((accordionItem) => ({
          ...accordionItem,
          items: accordionItem.items.map((item) =>
            item.type === "simple_date"
              ? {
                  ...item,
                  value: {
                    actual: item.actual,
                    forecast: item.forecast,
                    custom: [
                      {
                        id: "",
                        value: item.customText,
                        label: "",
                        type: "",
                      },
                    ],
                  },
                }
              : item
          ),
        }));

        setDataSABP(restructedRes);
      });
    }
  };

  useEffect(() => {
    fetchData();

    setTableData({
      isOpen: false,
      detailsObject: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {params.type === "FDS" ? (
        <StyledContainer py={3}>
          {dataSABP.map((accordionItem, index) =>
            envSABPForm == "on" ? (
              <Accordion
                title={accordionItem.label}
                key={accordionItem.name}
                OnOpenClick={() => {
                  OnAccordionSABPClick(accordionItem.name);
                }}
                isAccordionOpen={openedTabs.SABP[index]}
              >
                {accordionItem.groups ? (
                  <DateTwoSABPForm
                    groups={accordionItem.groups}
                    taskId={params.taskId}
                    sectionName={accordionItem.name}
                    remoteFolderPath={remoteFolderPath}
                  />
                ) : (
                  "ładowanie..."
                )}
              </Accordion>
            ) : (
              <Accordion
                title={accordionItem.name}
                key={accordionItem.name}
                OnOpenClick={() => {
                  openedTabs.SABP[index] = !openedTabs.SABP[index];
                }}
                isAccordionOpen={openedTabs.SABP[index]}
              >
                <DateTwoDetails
                  details={accordionItem.items}
                  fetchData={fetchData}
                  saveUpdateDate={saveUpdateDate}
                  dataType="SABP"
                />
              </Accordion>
            )
          )}
        </StyledContainer>
      ) : (
        <Tabs
          selectedIndex={currentIndex}
          onSelect={(currentIndex) => setCurrentIndex(currentIndex)}
        >
          <TabList>
            {typeWork.map(
              ({ label }) => label && <Tab key={label}>Prace {label}</Tab>
            )}
          </TabList>
          {typeWork.map(({ label, content }) => (
            <React.Fragment key={label}>
              {label === "CW" ? (
                <StyledTabPanel key={label}>
                  {content.map((accordionItem, index) => (
                    <Accordion
                      title={accordionItem.name}
                      key={accordionItem.name}
                      OnOpenClick={(): void => {
                        openedTabs.CW[index] = !openedTabs.CW[index];
                      }}
                      isAccordionOpen={openedTabs.CW[index]}
                    >
                      <DateTwoDetails
                        details={accordionItem.items}
                        saveUpdateDate={saveUpdateDate}
                        dataType="CW"
                      />
                    </Accordion>
                  ))}
                </StyledTabPanel>
              ) : label === "SABP" ? (
                <StyledTabPanel key={label}>
                  {dataSABP.map((accordionItem, index) =>
                    envSABPForm == "on" ? (
                      <Accordion
                        title={accordionItem.label}
                        key={accordionItem.name}
                        OnOpenClick={() => {
                          OnAccordionSABPClick(accordionItem.name);
                        }}
                        isAccordionOpen={openedTabs.SABP[index]}
                      >
                        {accordionItem.groups ? (
                          <DateTwoSABPForm
                            groups={accordionItem.groups}
                            taskId={params.taskId}
                            sectionName={accordionItem.name}
                            remoteFolderPath={remoteFolderPath}
                          />
                        ) : (
                          "ładowanie..."
                        )}
                      </Accordion>
                    ) : (
                      <Accordion
                        title={accordionItem.name}
                        key={accordionItem.name}
                        OnOpenClick={() => {
                          openedTabs.SABP[index] = !openedTabs.SABP[index];
                        }}
                        isAccordionOpen={openedTabs.SABP[index]}
                      >
                        <DateTwoDetails
                          details={accordionItem.items}
                          saveUpdateDate={saveUpdateDate}
                          dataType="SABP"
                        />
                      </Accordion>
                    )
                  )}
                </StyledTabPanel>
              ) : (
                <StyledTabPanel key={label}>
                  {dataTI?.length && (
                    <DateTwoDetails
                      details={dataTI[0].items}
                      fetchData={fetchData}
                      saveUpdateDate={saveUpdateDate}
                      dataType="TI"
                    />
                  )}
                </StyledTabPanel>
              )}
            </React.Fragment>
          ))}
        </Tabs>
      )}
    </>
  );
};
