import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Select } from "components/Select";
import { useState, useEffect } from "react";
import {
  getDictSubtask,
  getPlaningChart,
  IPlanningBars,
} from "services/dashboard";
import { TaskType } from "types/task";
import DatePicker from "react-datepicker";
import { Checkbox } from "components/Checkbox";
import EChartsReact from "echarts-for-react";
import { Button } from "components/Button";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { formatDate } from "utilities/formatDate";
import { toast } from "react-toastify";

const taskType = [
  "Ostateczne pozwolenie na budowę",
  "Data zakończenia montażu wieży",
  "Integration / Completed",
];

type IGroup = "day" | "month";

interface IStyledButton {
  active?: IGroup;
}

const StyledButtonD = styled(Button)<IStyledButton>`
  ${({ theme, active }) => `
    color: ${
      active === "day"
        ? `${theme.palette.primary}`
        : `${theme.palette.text.primary}`
    };
  `}
`;
const StyledButtonM = styled(Button)<IStyledButton>`
  ${({ theme, active }) => `
    color: ${
      active === "month"
        ? `${theme.palette.primary}`
        : `${theme.palette.text.primary}`
    };
  `}
`;

export const LineGraph = () => {
  const [subtasksName, setSubtasksName] = useState<string[]>();
  const [dateValue, setDateValue] = useState<Date>(new Date());
  const [selectedType, setSelectedType] = useState<TaskType>(TaskType.OR);
  const [data, setData] = useState<IPlanningBars | null>(null);
  const [visibleForecast, setVisibleForecast] = useState(true);
  const [visibleActual, setVisibleActual] = useState(true);
  const [group, setGroup] = useState<IGroup>("day");

  const { register, watch } = useForm({
    defaultValues: {
      dateForecast: "2021-08",
      dateActual: "2021-08",
      taskType: "",
    },
  });

  const watcher = watch();

  useEffect(() => {
    getDictSubtask(selectedType).then((res) =>
      setSubtasksName(res as string[])
    );
  }, [selectedType]);

  const generateXAxisData = () => {
    if (!data) return [];
    const arrayObjects = Object.entries(data);
    console.log(arrayObjects);
    return arrayObjects.map((val) => `${formatDate(new Date(val[0]))}`);
  };

  const generateForecastData = () => {
    if (!data) return [];
    const arrayObjects = Object.entries(data);
    const a = arrayObjects.map((val) => val[1]);
    const b = a.map((val) => val.forecast);
    return visibleForecast ? b : [];
  };

  const generateActualData = () => {
    if (!data) return [];
    const arrayObjects = Object.entries(data);
    const a = arrayObjects.map((val) => val[1]);
    const b = a.map((val) => val.actual);
    return visibleActual ? b : [];
  };

  const sumForecastData = () => {
    const forecastData = [...generateForecastData()];
    const sum = forecastData.reduce((val, acc) => {
      return val + acc;
    }, 0);
    return sum;
  };

  const sumActualData = () => {
    const actualData = [...generateActualData()];
    const sum = actualData.reduce((val, acc) => {
      return val + acc;
    }, 0);
    return sum;
  };

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: generateXAxisData(),
    },
    yAxis: {
      type: "value",
    },
    legend: {
      bottom: "bottom",
      data: ["Forecast", "Actual"],
    },
    series: [
      {
        name: "Forecast",
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: generateForecastData(),
        color: ["#37CC73"],
        label: {
          normal: {
            show: true,
            position: "top",
          },
        },
      },
      {
        name: "Actual",
        type: "bar",
        label: {
          normal: {
            show: true,
            position: "top",
          },
        },
        emphasis: {
          focus: "series",
        },
        data: generateActualData(),
        color: ["#005AFF"],
      },
    ],
  };

  useEffect(() => {
    getPlaningChart(
      watcher.dateActual,
      watcher.dateForecast,
      watcher.taskType,
      group
    )
      .then((res) => {
        setData(res);
      })
      .catch(() => toast.error("Wystąpił błąd."));
  }, [watcher.dateActual, watcher.dateForecast, watcher.taskType, group]);

  return (
    <Card>
      <Box px={4} py={3}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "500px" }}
          >
            <Select label="Zakres dat Forecast" {...register("dateForecast")}>
              <option key="2021-08" value="2021-08">
                01-08-2021
              </option>
              <option key="2021-09" value="2021-09">
                01-09-2021
              </option>
              <option key="2021-10" value="2021-10">
                01-10-2021
              </option>
            </Select>
            <Select label="Zakres dat Actual" {...register("dateActual")}>
              <option key="2021-08" value="2021-08">
                01-08-2021
              </option>
              <option key="2021-09" value="2021-09">
                01-09-2021
              </option>
              <option key="2021-10" value="2021-10">
                01-10-2021
              </option>
            </Select>
            <Checkbox
              label={`Aktualne: ${sumActualData()}`}
              checked={visibleActual}
              onChange={() => {
                generateActualData();
                setVisibleActual((prevState) => !prevState);
              }}
            />
            <Checkbox
              label={`Planowane: ${sumForecastData()}`}
              checked={visibleForecast}
              onChange={() => {
                generateForecastData();
                setVisibleForecast((prevState) => !prevState);
              }}
            />
          </Flex>
          <Flex justifyContent="flex-end" style={{ width: "400px" }}>
            <Select label="Rodzaj Prac" {...register("taskType")}>
              <option key=""></option>
              {taskType.map((val) => (
                <option key={val}>{val}</option>
              ))}
            </Select>
          </Flex>
        </Flex>
        <EChartsReact option={option} style={{ transform: "scale(1.05)" }} />
        <Flex>
          <StyledButtonD active={group} onClick={() => setGroup("day")}>
            1D
          </StyledButtonD>
          <StyledButtonM active={group} onClick={() => setGroup("month")}>
            1M
          </StyledButtonM>
        </Flex>
      </Box>
    </Card>
  );
};
