export const http = async <T = null>(
  url: string,
  options?: RequestInit
): Promise<T> => {
  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.status === 401) {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
        window.location.replace("/");
      }
    }
    if (
      response.status === 500 ||
      response.status === 403 ||
      response.status === 409
    ) {
      throw Error;
    }
  }

  if (response.status === 204) {
    return new Promise((resolve) => {
      resolve(null as any);
    });
  }

  if (response.statusText === "No Content") {
    return new Promise((resolve) => {
      resolve(null as any);
    });
  }

  return response.json();
};

export const httpFiles = async (url: string, options?: RequestInit) => {
  const response = await fetch(url, options);
  if (!response.ok) {
    if (response.status === 401) {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
        window.location.replace("/");
      }
    } else {
      throw Error;
    }
  }

  return response;
};
