import { DICTIONARY_USERS_URL } from "constants/path";
import React, { useContext, useEffect, useState } from "react";
import { TableType } from "types/tableType";
import { fetchAPI } from "utilities/fetchAPI";

interface ColumnConfig {
  [TableType.DISRUPTION_LIST]?: string[];
  [TableType.TASK_LIST]?: string[];
  [TableType.RAILWAYLINE_LIST]?: string[];
  [TableType.DATA_LIST]?: string[];
}
export interface UserProfile {
  id: string;
  firstname: string;
  lastname: string;
  avatar: string;
  position: string;
  columnsConfig: ColumnConfig;
  email: string;
  partner: string;
  tag: string;
}
export interface IAuthStateContext {
  token: string | null;
  userProfile: UserProfile | null;
}

export interface IAuthDispatchContext {
  setToken: (token: string | null) => void;
  fetchUserProfile: () => void;
}

const AuthStateContext = React.createContext<IAuthStateContext | undefined>(
  undefined
);

const AuthDispatchContext = React.createContext<
  IAuthDispatchContext | undefined
>(undefined);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token") ?? null
  );
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    fetchAPI(`${DICTIONARY_USERS_URL}/profile`).then((response: any) => {
      setUserProfile(response);
    });
  }, []);

  return (
    <AuthStateContext.Provider value={{ token, userProfile }}>
      <AuthDispatchContext.Provider
        value={{
          setToken: (token: string | null) => {
            if (token === null) {
              localStorage.removeItem("token");
            } else {
              localStorage.setItem("token", token);
            }
            setToken(token);
          },
          fetchUserProfile: () => {
            fetchAPI(`${DICTIONARY_USERS_URL}/profile`).then(
              (response: any) => {
                setUserProfile(response);
              }
            );
          },
        }}
      >
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthStateContext = () => {
  const context = useContext(AuthStateContext);
  if (!context) {
    throw new Error(
      "useAuthStateContext must be inside a AuthStateContextProvider with a value"
    );
  }
  return context;
};

export const useAuthDispatchContext = () => {
  const context = useContext(AuthDispatchContext);
  if (!context) {
    throw new Error(
      "useAuthDispatchContext must be inside a AuthStateDispatchProvider with a value"
    );
  }
  return context;
};
