import { useEffect, useState, useMemo, useCallback } from "react";
import { Table } from "components/Table";
import { Column } from "react-table";
import { Hr } from "components/Hr";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Loader } from "components/Loader/Loader";
import {
  fetchCertificationDataService,
  removeRelatedObject,
  getCertyficationInvertedDataService,
  updateRelationObjectService,
} from "services/certyfication";
import { ITask } from "types/task";
import { ReactComponent as TrachIcon } from "icons/trash.svg";
import { ReactComponent as PencilIcon } from "icons/pencil.svg";
import { useParams, useHistory } from "react-router-dom";
import { ConfirmModal } from "components/ConfirmModal";
import { relatedSchema } from "schemas/RelatedForm";
import { toast } from "react-toastify";
import { FieldSetTitle } from "components/FieldSetTitle";
import { CreateNewCertificationModal } from "components/CreateNewCertificationModal";
import { fetchTask } from "services/tasks";
import { format } from "date-fns";
import { UpdateCertificationModal } from "components/UpdateCertificationModal";
import styled from "styled-components";
import { ConnectToCertificationModal } from "../components/ConnectToCertificationModal";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { rolesByObjects } from "constants/rolesByObjects";

type ICertyficationTask = {
  chainage: number;
  chainageEnd: null;
  chainageStart: null;
  code: string;
  company: null;
  completion: number;
  contractorCW: string;
  contractorSA: string;
  endDate: string;
  iZ: string;
  id: string;
  inHrf: boolean;
  length: number | null;
  line: string;
  locationName: string;
  name: string;
  objectName: string;
  objectNameIE50: string;
  problems: any[];
  startDate: string;
  subtaskDULICPEndDate: null;
  subtaskDULICPStartDate: null;
  subtaskPnBEndDate: string;
  subtaskPnBStartDate: string;
  subtaskPraceBudowlaneEndDate: string;
  subtaskPraceBudowlaneStartDate: string;
  subtaskPraceProjektoweEndDate: string;
  subtaskPraceProjektoweStartDate: string;
  subtaskPradNaObiekcieEndDate: string;
  subtaskPradNaObiekcieStartDate: string;
  subtaskUruchomienieEndDate: string;
  subtaskUruchomienieStartDate: string;
  subtaskWdrozenieEndDate: string | null;
  subtaskWdrozenieStartDate: string | null;
  subtaskZagospodarowanieEndDate: string;
  subtaskZagospodarowanieStartDate: string;
  taskmetaActualMontazWiezyEndDate: string;
  taskmetaForecastMontazWiezyEndDate: string;
  tastmetaActualMontazWiezyEndDateDate: string;
  tastmetaForecastMontazWiezyEndDateDate: string;
  type: string;
};
type IInvertedDate = {
  id: any;
  name: string;
  type: string;
  code: string;
  startDate: string;
  endDate: string;
  chainage: number;
  chainageStart: number;
  chainageEnd: number;
  itemsCount: number;
  doneItemsCount: number;
};
type ICertyficationData = {
  cwEndDate: string;
  duration: number;
  electricityDate: string;
  measurementDate: null;
  saEndDate: string;
  stage: string;
  task: ICertyficationTask;
  tiEndDate: null;
  type: string;
};

const WrapperIcon = styled.p`
  font-size: 18px;
  cursor: pointer;

  &:last-child {
    margin-left: 8px;
    opacity: 0.5;
  }
`;

export const Certyfication = () => {
  const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [removedItem, setRemovedItem] = useState<any>(null);
  const [taskDetails, setTaskDetails] = useState<ITask | null>(null);
  const [doneObjectCount, setDoneObjectCount] = useState(0);
  const [doneCertificationCount, setDoneCertificationCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [relatedData, setRelatedData] = useState<ICertyficationData[]>([]);
  const [relatedInverseData, setRelatedInverseData] = useState<IInvertedDate[]>(
    []
  );
  const [summaryDates, setSummaryDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [isOpenUpdateCertificationModal, setIsOpenUpdateCertificationModal] =
    useState(false);
  const [detailsClickedObject, setDetailsClickedObject] = useState<{
    duration: number;
    type: string;
    objectId: string;
  } | null>(null);
  const history = useHistory();
  const params = useParams<{ taskId: string; type: string }>();
  const isCertification =
    params.type === "CertyfikacjaWE" ||
    params.type === "Certyfikacja" ||
    params.type === "ReadyToWE";

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const findStartAndEndDate = useCallback(() => {
    if (relatedInverseData.length) {
      const minDate = new Date(
        Math.min(
          ...relatedInverseData.map((element) => {
            return new Date(element.startDate) as unknown as number;
          })
        )
      );

      const maxDate = new Date(
        Math.max(
          ...relatedInverseData.map((element) => {
            return new Date(element.endDate) as unknown as number;
          })
        )
      );

      if (maxDate instanceof Date) {
        setSummaryDates({
          startDate: format(minDate, "yyyy-MM-dd"),
          endDate: format(maxDate, "yyyy-MM-dd"),
        });
      }
    } else if (relatedData.length) {
      const minDate = new Date(
        Math.min(
          ...relatedData.map((element) => {
            return new Date(element.task.startDate) as unknown as number;
          })
        )
      );

      const maxDate = new Date(
        Math.max(
          ...relatedData.map((element) => {
            return new Date(element.task.endDate) as unknown as number;
          })
        )
      );

      if (maxDate instanceof Date) {
        setSummaryDates({
          startDate: format(minDate, "yyyy-MM-dd"),
          endDate: format(maxDate, "yyyy-MM-dd"),
        });
      }
    }
  }, [relatedData, relatedInverseData]);

  const handleRemoveRelated = (data) => {
    const objectId = isCertification
      ? data.row.original.task.id
      : params.taskId;
    const taskId = isCertification ? params.taskId : data.row.original.id;
    removeRelatedObject(taskId, objectId)
      .then(() => {
        fetchCertification();
        toast.success("Obiekt został usunięty");
      })
      .catch(() => {
        toast.error("Nie udało się usunąć połączenia.");
      })
      .finally(() => setIsOpen(false));
  };

  const actionButtons = (item) => (
    <Flex alignItems="center">
      {loggedUserRoles?.includes(
        rolesByObjects[params.type]["CERTIFICATION_DELETE"]
      ) && (
        <WrapperIcon
          onClick={() => {
            setIsOpen(true);
            setRemovedItem(item);
          }}
        >
          <TrachIcon />
        </WrapperIcon>
      )}
      {loggedUserRoles?.includes(
        rolesByObjects[params.type]["CERTIFICATION_UPDATE"]
      ) && (
        <WrapperIcon
          onClick={() => {
            const args = {
              duration: item.row.original?.duration,
              type: item.row.original.type,
              objectId: item.row.original.id,
            };
            setIsOpenUpdateCertificationModal(true);
            setDetailsClickedObject(args);
          }}
        >
          <PencilIcon />
        </WrapperIcon>
      )}
    </Flex>
  );

  useEffect(() => {
    findStartAndEndDate();
  }, [findStartAndEndDate]);

  const columnsForInverse: Array<Column<IInvertedDate>> = useMemo(
    () => [
      {
        Header: "Numer certyfikacji",
        accessor: "code",
      },
      {
        Header: "Typ obiektu",
        accessor: "type",
      },
      {
        Header: "Rozpoczęcie",
        accessor: "startDate",
        Cell: (item) =>
          format(new Date(item.row.original.startDate), "dd-MM-yyy"),
      },
      {
        Header: "Zakończenie",
        accessor: "endDate",
        Cell: (item) =>
          format(new Date(item.row.original.endDate), "dd-MM-yyy"),
      },
      {
        Header: "Km od",
        accessor: "chainageStart",
      },
      {
        Header: "Km do",
        accessor: "chainageEnd",
      },
      {
        Header: "Ilość",
        accessor: "doneItemsCount",
      },
    ],
    []
  );

  const columns: Array<Column<ICertyficationData>> = useMemo(
    () => [
      {
        Header: "Code",
        width: 100,
        Cell: (item) => (
          <p
            onClick={() =>
              history.push(`/objects/OR,ORR/${item.row.original.id}`)
            }
          >
            {item.row.original.task.code}
          </p>
        ),
      },
      {
        Header: "Nazwa",
        width: 200,
        Cell: (item) => item.row.original.task.name,
      },
      {
        Header: "Linia",
        width: 50,
        Cell: (item) => item.row.original.task.line,
      },
      {
        Header: "Koniec CW",
        Cell: (item) =>
          format(new Date(item.row.original.cwEndDate), "dd-MM-yyy"),
      },
      {
        Header: "Koniec TI",
        Cell: (item) =>
          format(new Date(item.row.original.tiEndDate), "dd-MM-yyy"),
      },
      {
        Header: "Koniec SABP",
        Cell: (item) =>
          format(new Date(item.row.original.saEndDate), "dd-MM-yyy"),
      },
      {
        Header: "Task",
        width: 80,
        Cell: (item) => item.row.original.task.type,
      },
      {
        Header: "Opóźnienie",
        accessor: "duration",
      },
      {
        Header: "Typ",
        accessor: "type",
      },
      {
        Header: "Akcje",
        Cell: (item) => actionButtons(item),
      },
    ],
    [loggedUserRoles]
  );

  const getObjectDone = () => {
    if (!relatedData) return;
    const today = new Date().getTime();
    const filteredData = relatedData.filter((item) => {
      const cwDate = new Date(item.cwEndDate).getTime();

      return today >= cwDate;
    });
    setDoneObjectCount(filteredData.length);
  };

  const getCertificationDone = () => {
    if (!relatedInverseData) return;
    const today = new Date().getTime();
    const filteredData = relatedInverseData.filter((item) => {
      const cwDate = new Date(item.endDate).getTime();

      return today >= cwDate;
    });
    setDoneCertificationCount(filteredData.length);
  };

  const fetchTaskDetails = async () => {
    const taskDetails = await fetchTask({
      taskId: params.taskId,
    });
    setTaskDetails(taskDetails);
  };

  const fetchCertification = () => {
    setLoading(true);
    if (isCertification) {
      fetchCertificationDataService(params.taskId)
        .then((res) => {
          setRelatedData(res.data);
        })
        .catch((err) => toast.error("Błąd podczas pobierania danych."))
        .finally(() => setLoading(false));
    } else {
      getCertyficationInvertedDataService(params.taskId)
        .then((res) => {
          setRelatedInverseData(res.data);
        })
        .catch((err) => toast.error("Błąd podczas pobierania danych."))
        .finally(() => setLoading(false));
    }
  };

  const UpdateDataValue = ({
    duration,
    type,
  }: {
    duration: number;
    type: string;
  }) => {
    const payload = {
      taskId: isCertification ? params.taskId : detailsClickedObject.objectId,
      objectId: isCertification ? detailsClickedObject.objectId : params.taskId,
      duration,
      type,
    };
    updateRelationObjectService(payload)
      .then((response) => {
        console.log("response => ", response);
      })
      .catch((err) => {
        toast.error("Coś poszło nie tak :(");
      })
      .finally(() => {
        setIsOpenUpdateCertificationModal(false);
      });
  };

  useEffect(() => {
    fetchCertification();
    fetchTaskDetails();
  }, []);

  useEffect(() => {
    getObjectDone();
    getCertificationDone();
  }, [relatedData, relatedInverseData]);

  return (
    <>
      <Flex justifyContent="space-between">
        <div>
          <Box mr={4}>
            <FieldSetTitle label="CSC">{taskDetails?.code}</FieldSetTitle>
          </Box>
          <FieldSetTitle label="Wygenerowana nazwa">
            {taskDetails?.name}
          </FieldSetTitle>
        </div>
        {isCertification &&
          loggedUserRoles?.includes(
            rolesByObjects[params.type]["CERTIFICATION_CREATE"]
          ) && (
            <Flex>
              <Button onClick={() => setIsCreateNewOpen(true)} bordered>
                Dodaj
              </Button>
            </Flex>
          )}
      </Flex>
      <Flex mb={5}></Flex>

      <Flex justifyContent="space-between" mb={4}>
        <FieldSetTitle label="Rozpoczęcie">
          {summaryDates.startDate}
        </FieldSetTitle>
        <FieldSetTitle label="Zakończenie">
          {summaryDates.endDate}
        </FieldSetTitle>
        {/* <FieldSetTitle label="Czas trwania">80 dni</FieldSetTitle>
        <FieldSetTitle label="Etap">3</FieldSetTitle> */}
        {isCertification ? (
          <>
            <FieldSetTitle label="Liczba obiektów do certyfikacji">
              {relatedData?.length}
            </FieldSetTitle>
            <FieldSetTitle label="Liczba wykonanych obiektów">
              {doneObjectCount}
            </FieldSetTitle>
          </>
        ) : (
          <>
            <FieldSetTitle label="Liczba certyfikacji">
              {relatedInverseData?.length}
            </FieldSetTitle>
            <FieldSetTitle label="Liczba wykonanych certyfikacji">
              {doneCertificationCount}
            </FieldSetTitle>
          </>
        )}
      </Flex>

      {isCertification && (
        <Flex justifyContent="flex-start">
          <Box mr={4}>
            <FieldSetTitle label="Kilometr od">
              {taskDetails?.chainageStart}
            </FieldSetTitle>
          </Box>
          <Box mr={4}>
            <FieldSetTitle label="Kilometr do">
              {taskDetails?.chainageEnd}
            </FieldSetTitle>
          </Box>
          <FieldSetTitle label="Suma kilometrów certyfikacji">
            {(taskDetails?.chainageEnd - taskDetails?.chainageStart).toFixed(3)}
          </FieldSetTitle>
        </Flex>
      )}
      <Hr />
      {loading ? (
        <Loader />
      ) : (
        <>
          {isCertification ? (
            <Table
              fullHeight={false}
              columns={columns}
              data={relatedData}
              getLink={() => ""}
            />
          ) : (
            <Table
              fullHeight={false}
              columns={columnsForInverse}
              data={relatedInverseData}
              getLink={() => ""}
            />
          )}
        </>
      )}
      <ConfirmModal
        handleConfirm={() => handleRemoveRelated(removedItem)}
        confirmBtnText="Usuń"
        header="Usunąć powiązanie?"
        isOpen={isOpen}
        onCancelClick={() => setIsOpen(false)}
      />
      <UpdateCertificationModal
        detailsClickedObject={detailsClickedObject}
        handleSubmit={UpdateDataValue}
        isOpen={isOpenUpdateCertificationModal}
        onCancelClick={() => setIsOpenUpdateCertificationModal(false)}
      />
      {
        isCertification && (
          <>
            <CreateNewCertificationModal
              isOpen={isCreateNewOpen}
              handleCloseModal={() => setIsCreateNewOpen(false)}
              taskId={params.taskId}
              fetchCertification={fetchCertification}
            />
          </>
        )
        // :
        // <ConnectToCertificationModal
        //   key={relatedInverseData.length}
        //   isOpen={isCreateNewOpen}
        //   handleCloseModal={() => setIsCreateNewOpen(false)}
        //   objectId={params.taskId}
        //   existingIds={relatedInverseData.map(el => el.id)}
        //   fetchCertification={fetchCertification}
        // />
      }
    </>
  );
};
