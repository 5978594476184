import styled from "styled-components";
import { flexbox, FlexboxProps, space, SpaceProps } from "styled-system";

interface IFlex extends SpaceProps, FlexboxProps {
  gutter?: number;
  gap?: number;
}

export const Flex = styled.div<IFlex>(
  space,
  flexbox,
  ({ theme, gutter, gap }) =>
    `
    display: flex;
    ${gutter ? `margin: 0 -${theme.space[gutter]}px` : ""};

    > * {
      ${gutter ? `padding: 0 ${theme.space[gutter]}px` : ""};
    }
    ${gap ? `gap:${gap}px` : ""}
  `
);
