import React from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import styled from "styled-components";

interface IConfirmModal {
  onCancelClick: () => void;
  handleConfirm: () => void;
  isOpen: boolean;
  header: string;
  confirmBtnText: "Dodaj" | "Usuń" | "OK";
}

interface IButton {
  variant: "success" | "cancel";
}

const Header = styled.p`
  font-size: 18px;
`;
const Button = styled.div<IButton>`
  ${({ theme, variant }) => `
      background-color: ${
        variant === "success" ? theme.palette.primary : "transparent"
      };
      padding: ${theme.space[2]}px;
      cursor: pointer;
  `}
`;

export const ConfirmModal: React.FC<IConfirmModal> = ({
  onCancelClick,
  handleConfirm,
  isOpen,
  header,
  confirmBtnText,
}) => {
  return (
    <Modal onCancelClick={onCancelClick} isOpen={isOpen}>
      <Box p={4}>
        <Header>{header}</Header>
        <Flex justifyContent="flex-end">
          <Box mr={2}>
            <Button variant="success" onClick={handleConfirm}>
              {confirmBtnText}
            </Button>
          </Box>
          <Button variant="cancel" onClick={onCancelClick}>
            Anuluj
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};
