import { Box } from "components/Box";
import { Card } from "components/Card";
import { PageLayout } from "components/PageLayout";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchTaskDetails,
  getTaskAntennas,
  createPayment,
  IDictionary,
  IPayment,
  fetchTask,
  updatePayment,
} from "services/tasks";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ICustomTaskDetails } from "types/taskDetails";
import { TaskDetails } from "containers/TaskDetails";
import { Payments } from "containers/Payments";
import { DateHRF } from "containers/DateHRF/DateHRF";
import { Timeline } from "containers/Timeline";
import { Certyfication } from "containers/Certyfication";
import { DateTwo } from "containers/DateTwo/DateTwo";
import { Implementation } from "containers/Implementation";
import {
  TaskContextProvider,
  useTaskDispatchContext,
} from "contexts/TaskContext";
import styled from "styled-components";
import { IAntenna } from "types/antenna";
import { useFetchAPI } from "hooks/fetchAPI";
import {
  DICTIONARY_PAYMENT_SCOPE,
  DICTIONARY_PAYMENT_TASK,
} from "constants/path";
import {
  useTableSidebarDispatchContext,
  useTableSidebarContext,
} from "contexts/TableSidebarContext";
import { toast } from "react-toastify";
import { TaskType } from "types/task";
import { Notifications } from "containers/Notifications";
import { ObjectDisruptions } from "containers/ObjectDisruptions";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { rolesByObjects } from "constants/rolesByObjects";

const StyledBox = styled(Box)`
  height: calc(100vh - 120px);
  overflow: auto;
  overscroll-behavior: contain;
`;

const TaskViewNotConnected = () => {
  const { taskId, type } = useParams<{ taskId: string; type: TaskType }>();
  const [antennas, setAntennas] = useState<Array<IAntenna> | null>(null);
  const [taskDetails, setTaskDetails] = useState<ICustomTaskDetails | null>(
    null
  );
  const { setLocationName } = useTaskDispatchContext();

  const { setTableData } = useTableSidebarDispatchContext();
  const { tableData } = useTableSidebarContext();

  const { data: paymentScope } = useFetchAPI<IDictionary[]>(
    DICTIONARY_PAYMENT_SCOPE
  );
  const { data: paymentTask } = useFetchAPI<IDictionary[]>(
    DICTIONARY_PAYMENT_TASK
  );

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const createNewPayment = (payload: IPayment) => {
    createPayment({ ...payload, taskId })
      .then(() => {
        toast.success("Płatność została utworzona.");
      })
      .catch(() => toast.error("Coś poszło nie tak z tworzeniem płatności."));
  };

  const handleUpdatePayment = (payload: IPayment) => {
    updatePayment(payload.id, { ...payload, taskId })
      .then(() => {
        toast.success("Płatność została zaktualizowana.");
      })
      .catch(() => toast.error("Coś poszło nie tak z edytowaniem płatności."));
  };

  const getBusinessDatesCount = (startDate: Date, endDate: Date) => {
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  };

  const getFullTaskDetails = async () => {
    try {
      const rawTaskDetails = await fetchTaskDetails({ taskId });

      const {
        code,
        startDate,
        endDate,
        name,
        type,
        completion,
        chainageLength,
        duration,
        contractor,
        active,
      } = await fetchTask({
        taskId,
      });

      setTaskDetails({
        ...rawTaskDetails,
        coordinator: rawTaskDetails.koordynator,
        code,
        startDate,
        endDate,
        duration,
        completion,
        name,
        type,
        chainageLength,
        year: new Date(startDate).getFullYear(),
        contractor,
        active,
        chainage:
          rawTaskDetails?.chainage?.toLocaleString("PL", {
            maximumFractionDigits: 3,
          }) || "",
      });
    } catch {
      toast.error("Nie udało się pobrać informacji.");
    }
  };

  useEffect(() => {
    getFullTaskDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taskDetails?.locationName) {
      setLocationName(taskDetails.locationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetails]);

  useEffect(() => {
    if ((type as string) === "OR,ORR") {
      getTaskAntennas({ taskId }).then((response) => {
        setAntennas(response.antennas);
      });
    }
    setTableData({
      ...tableData,
      isOpen: tableData ? tableData.isOpen : true,
      detailsObject: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableData({
      ...tableData,
      showComments: taskId ? true : false,
    });
  }, [taskId, taskDetails]);

  return (
    <PageLayout
      pageTitle={`Szczegóły obiektu ${
        (taskDetails && taskDetails.locationName) ?? ""
      }`}
    >
      <Card>
        <Tabs>
          <TabList>
            {loggedUserRoles?.includes(
              rolesByObjects[type]["OVERALL_SHOW"]
            ) && <Tab>Dane ogólne</Tab>}
            {type !== "OTK" &&
              ((type as string) === "OR,ORR" ||
                loggedUserRoles?.includes(
                  rolesByObjects[type]["DATE2_SHOW"]
                )) && (
                <>
                  {/*{type !== "FDS" && <Tab>Daty</Tab>}*/}
                  <Tab>Daty 2</Tab>
                </>
              )}
            {loggedUserRoles?.includes(
              rolesByObjects[type]["DATES_HRF_SHOW"]
            ) && <Tab>Daty HRF</Tab>}
            <Tab>Zakłócenia</Tab>
            {loggedUserRoles?.includes(
              rolesByObjects[type]["CERTIFICATION_SHOW"]
            ) && <Tab>Certyfikacja</Tab>}
            {loggedUserRoles?.includes(
              rolesByObjects[type]["PAYMENT_SHOW"]
            ) && <Tab>Płatności</Tab>}
            {loggedUserRoles?.includes(
              rolesByObjects[type]["HISTORY_SHOW"]
            ) && <Tab>Historia</Tab>}
            {![
              "Certyfikacja",
              "CertyfikacjaWE",
              "CertyfikacjaUTK",
              "ReadyToWE",
            ].includes(type) && <Tab>Zgłoszenia</Tab>}
            {antennas ? <Tab>Implementacja</Tab> : null}
          </TabList>

          <StyledBox px={8} py={6}>
            {loggedUserRoles?.includes(
              rolesByObjects[type]["OVERALL_SHOW"]
            ) && (
              <TabPanel>
                <Box mb={6}>
                  {taskDetails ? <TaskDetails details={taskDetails} /> : null}
                </Box>
              </TabPanel>
            )}
            {type !== "OTK" &&
              ((type as string) === "OR,ORR" ||
                loggedUserRoles?.includes(
                  rolesByObjects[type]["DATE2_SHOW"]
                )) && (
                <>
                  {/*{type !== "FDS" && (*/}
                  {/*  <TabPanel>*/}
                  {/*    <TaskDates taskId={taskId} />*/}
                  {/*  </TabPanel>*/}
                  {/*)}*/}
                  <TabPanel>
                    <DateTwo remoteFolderPath={taskDetails?.remoteFolderPath} />
                  </TabPanel>
                </>
              )}
            {loggedUserRoles?.includes(
              rolesByObjects[type]["DATES_HRF_SHOW"]
            ) && (
              <TabPanel>
                <DateHRF taskId={taskId} />
              </TabPanel>
            )}
            <TabPanel>
              <ObjectDisruptions taskId={taskId} />
            </TabPanel>
            {loggedUserRoles?.includes(
              rolesByObjects[type]["CERTIFICATION_SHOW"]
            ) && (
              <TabPanel>
                <Certyfication />
              </TabPanel>
            )}
            {loggedUserRoles?.includes(
              rolesByObjects[type]["PAYMENT_SHOW"]
            ) && (
              <TabPanel>
                <Payments
                  createNewPayment={createNewPayment}
                  handleUpdatePayment={handleUpdatePayment}
                  paymentScope={paymentScope}
                  paymentTask={paymentTask}
                />
              </TabPanel>
            )}
            {loggedUserRoles?.includes(
              rolesByObjects[type]["HISTORY_SHOW"]
            ) && (
              <TabPanel>
                <Timeline taskId={taskId} />
              </TabPanel>
            )}
            <TabPanel>
              <Notifications taskId={taskId} />
            </TabPanel>
            {antennas ? (
              <TabPanel>
                <Implementation taskId={taskId} antennas={antennas} />
              </TabPanel>
            ) : null}
          </StyledBox>
        </Tabs>
      </Card>
    </PageLayout>
  );
};

export const TaskView: React.FC = () => (
  <TaskContextProvider>
    <TaskViewNotConnected />
  </TaskContextProvider>
);
