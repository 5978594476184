export const urlToPageTitleMap = {
  "OR,ORR": "OR,ORR",
  milestone: "milestone",
  OR: "OR",
  ORR: "ORR",
  Tunel: "Tunel",
  DOR: "DOR",
  FDS: "FDS",
  OTK: "OTK",
  OSZ: "OSZ",
  OC: "OC",
  CertyfikacjaWE: "Nr Certyfikacji WE",
  Certyfikacja: "Nr Dopuszczenia UTK",
  ReadyToWE: "Nr gotowości WE",
  task: "Task",
  bedrock: "Bedrock",
  OtherTask: "OtherTask",
};
