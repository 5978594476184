import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { PageLayout } from "components/PageLayout";
import { TagCard } from "containers/TagCard/TagCard";
import { TagForm } from "containers/TagForm/TagForm";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import React, { FC, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  activateTags,
  deactivateTags,
  getTagsList,
  getTagsTypes,
} from "services/tasks";
import { TagType } from "types/tags";
import { HelperPanelWrapper, Header, ContentWrapper } from "./TagsView.styled";

const TagsView: FC = () => {
  const { setTableData } = useTableSidebarDispatchContext();
  const [tags, setTags] = useState<TagType[]>([]);
  const [chosenTag, setChosenTag] = useState<TagType>();
  const [checkedTags, setCheckedTags] = useState([]);
  const [isTagFormOpen, setIsTagFormOpen] = useState(false);
  const [tagsTypes, setTagsType] = useState([{ label: "", value: "" }]);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const retrieveTagsList = async () => {
    try {
      const { data } = await getTagsList();
      setTags(data);
    } catch (error) {
      toast.error("Nie udało się pobrać tagów.");
    }
  };

  const retrieveTagsTypes = async () => {
    const objectTypeTranslation = {
      milestone: "Kamień milowy",
      Disruption: "Zakłócenie",
    };

    try {
      const res = await getTagsTypes();
      const translatedRes = res.map((tagType) => ({
        label: objectTypeTranslation[tagType] || tagType,
        value: tagType,
      }));
      setTagsType(translatedRes);
    } catch (error) {
      toast.error("Nie udało się pobrać tagów.");
    }
  };

  const handleActivateTags = useCallback(async () => {
    try {
      await activateTags(checkedTags);
      await retrieveTagsList();

      toast.success("Tagi zostały aktywowane.");
    } catch (error) {
      toast.error("Nie udało się aktywować tagów.");
    }
  }, [checkedTags]);

  const handleDeactivateTags = useCallback(async () => {
    try {
      await deactivateTags(checkedTags);
      await retrieveTagsList();

      toast.success("Tagi zostały dezaktywowane.");
    } catch (error) {
      toast.error("Nie udało się dezaktywować tagów.");
    }
  }, [checkedTags]);

  useEffect(() => {
    retrieveTagsList();
    retrieveTagsTypes();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, [setTableData]);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      {isTagFormOpen ? (
        <>
          <HelperPanelWrapper>
            <Header>Dodawanie nowego tagu</Header>
            <Box mr={6}>
              <Button
                bordered
                onClick={() => {
                  setIsTagFormOpen((prevState) => !prevState);
                  setChosenTag(null);
                }}
              >
                Wróć
              </Button>
            </Box>
          </HelperPanelWrapper>

          <ContentWrapper>
            <TagForm
              chosenTag={chosenTag}
              setIsTagFormOpen={setIsTagFormOpen}
              retrieveTagsList={retrieveTagsList}
              tagsTypes={tagsTypes}
            />
          </ContentWrapper>
        </>
      ) : (
        <>
          <HelperPanelWrapper>
            <Header>Tagi</Header>
            <ButtonsGroup>
              <Button
                bordered
                onClick={() => {
                  handleActivateTags();
                }}
              >
                AKTYWUJ
              </Button>
              <Button
                onClick={() => {
                  handleDeactivateTags();
                }}
                bordered
              >
                DEZAKTYWUJ
              </Button>
              {loggedUserRoles?.includes("ROLE_ADMINISTRATION_CREATE") && (
                <Box ml={6}>
                  <Button bordered onClick={() => setIsTagFormOpen(true)}>
                    Dodaj Tag
                  </Button>
                </Box>
              )}
            </ButtonsGroup>
          </HelperPanelWrapper>
          <ContentWrapper>
            <TagCard
              title="Aktywne tagi"
              tags={tags?.filter(({ active }) => active)}
              setTags={setTags}
              chosenTag={chosenTag}
              setChosenTag={setChosenTag}
              setIsTagFormOpen={setIsTagFormOpen}
              checkedTags={checkedTags}
              setCheckedTags={setCheckedTags}
            />
            <TagCard
              title="Nieaktywne tagi"
              tags={tags?.filter(({ active }) => !active)}
              setTags={setTags}
              chosenTag={chosenTag}
              setChosenTag={setChosenTag}
              setIsTagFormOpen={setIsTagFormOpen}
              checkedTags={checkedTags}
              setCheckedTags={setCheckedTags}
            />
          </ContentWrapper>
        </>
      )}
    </PageLayout>
  );
};

export default TagsView;
