import { Box } from "components/Box";
import { TabPanel } from "react-tabs";
import styled from "styled-components";

export const StyledTabPanel = styled(TabPanel)`
  ${({ theme }) => `
    background-color: ${theme.palette.background};
    padding: ${theme.space[1]}px;

    > div {
      height: auto !important;
      max-height: none !important;
    }
  `}
`;
export const Link = styled.a`
  ${({ theme }) => `
    padding: ${theme.space[5]}px ${theme.space[1]}px;
    text-align: center;
  `}
`;

export const StyledContainer = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.background};
  `}
`;
