import styled from "styled-components";
import { Radio } from "./Radio";

export const RadioGroup = styled.div`
  ${({ theme }) => `
    display: flex;

    > * {
      margin-left: ${theme.space[6]}px;
    }
  `}
`;
