import { ToastContainer } from "react-toastify";
import { Router } from "components/Router";
import { routes } from "routes";

export const App: React.FC = () => {
  return (
    <>
      <Router routes={routes} />
      <div id="root-datepicker-portal" />
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default App;
