import { Flex } from "components/Flex";
import React, { useCallback, useMemo, useState } from "react";
import {
  IconAdd,
  IconEdit,
  IconDelete,
  StyledBox,
} from "./PermissionsCard.styled";
import { Box } from "components/Box";
import { IUserGroup } from "types/permissions";
import { CustomTable } from "components/CustomTable";
import { toast } from "react-toastify";
import { deleteUserGroup } from "services/permissions";
import { AddUserToGroupModal } from "components/AddUserToGroupModal";
import { IUser } from "types/users";
import { addUserToGroups } from "services/user";
import { ConfirmModal } from "components/ConfirmModal";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface PermissionsCardProps {
  userGroups: IUserGroup[];
  setUserGroups: React.Dispatch<React.SetStateAction<IUserGroup[]>>;
  setIsUserGroupFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chosenUserGroup: IUserGroup;
  setChosenUserGroup: React.Dispatch<React.SetStateAction<IUserGroup>>;
}

export const PermissionsCard = ({
  userGroups,
  setUserGroups,
  setIsUserGroupFormOpen,
  chosenUserGroup,
  setChosenUserGroup,
}: PermissionsCardProps) => {
  const [colVis, setColVis] = useState(Array(7).fill(true));
  const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const handleDeleteUserGroup = useCallback(
    async (groupId: string) => {
      try {
        await deleteUserGroup(groupId);
        setUserGroups((prevUsers) =>
          prevUsers.filter((prevUser) => prevUser.id !== groupId)
        );
        setIsOpenConfirmModal(false);
        toast.success("Grupa użytkowników została usunięta.");
      } catch (error) {
        toast.error("Nie udało się usunąć grupy użytkowników.");
      }
    },
    [setUserGroups]
  );

  const onAddUserClick = (newUser: IUser) => {
    addUserToGroups(chosenUserGroup.id, { user: newUser.id })
      .then(() => {
        setIsCreateNewOpen(false);
        toast.success("Zaktualizowano grupe użytkowników.");
      })
      .catch(() => {
        toast.error("Nie udało się zaktualizować grup użytkownika.");
      });
  };

  const columns = useMemo(
    () => [
      {
        name: "Nazwa grupy",
        value: (e) => <Box pt={4}>{e.humanizeName}</Box>,
        width: 180,
      },
      {
        name: "Ilość członków",
        value: (e) => e.usersCount,
        width: 120,
      },
      {
        name: "Wgląd",
        value: (e) => e.rolesShowCount,
        width: 120,
      },
      {
        name: "Edycja",
        value: (e) => e.rolesUpdateCount,
        width: 150,
      },
      {
        name: "Dodaj osobę",
        value: (e) => <IconAdd onClick={() => setIsCreateNewOpen(true)} />,
        width: 120,
      },
      ...(loggedUserRoles?.includes("ROLE_USER_GROUP_UPDATE")
        ? [
            {
              name: "Edytuj",
              value: (e) => (
                <IconEdit
                  onClick={() => {
                    setIsUserGroupFormOpen(true);
                    setChosenUserGroup(e);
                  }}
                />
              ),
              width: 100,
            },
          ]
        : []),
      ...(loggedUserRoles?.includes("ROLE_USER_GROUP_DELETE")
        ? [
            {
              name: "Usuń",
              value: (e) => (
                <IconDelete onClick={() => setIsOpenConfirmModal(true)} />
              ),
              width: 100,
            },
          ]
        : []),
    ],
    [loggedUserRoles, setChosenUserGroup, setIsUserGroupFormOpen]
  );

  return (
    <>
      <Flex flexDirection={"column"}>
        <StyledBox>
          <CustomTable
            showLongText
            columnList={columns}
            data={userGroups}
            colVis={colVis}
            clickTableRow={(userGroup) => {
              setChosenUserGroup(userGroup);
            }}
          />
        </StyledBox>
      </Flex>

      <ConfirmModal
        handleConfirm={() => handleDeleteUserGroup(chosenUserGroup.id)}
        onCancelClick={() => {
          setIsOpenConfirmModal(false);
          setChosenUserGroup(null);
        }}
        isOpen={isOpenConfirmModal}
        header="Czy na pewno chcesz usuąć grupę użytkowników?"
        confirmBtnText="Usuń"
      />

      <AddUserToGroupModal
        isOpen={isCreateNewOpen}
        handleCloseModal={() => setIsCreateNewOpen(false)}
        onAddUserClick={onAddUserClick}
      />
    </>
  );
};
