import { useFetchAPI } from "./fetchAPI";
import { DICTIONARY_CONTRACTORS_URL } from "constants/path";
import { DictionaryContractorsType } from "types/task";

export const useFetchContractors = () => {
  const { data } = useFetchAPI<DictionaryContractorsType>(
    DICTIONARY_CONTRACTORS_URL
  );
  return { dictionaryContractors: data };
};
