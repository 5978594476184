import React, { useEffect } from "react";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Input } from "components/Input";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  StyledHeading,
  StyledWrapperFlex,
  WrapperInput,
} from "./TagForm.styled";
import { Label } from "components/Label";
import { createTag, updateTag } from "services/tasks";
import { TagType } from "types/tags";
import { Select } from "components/Select";

interface TagFormProps {
  chosenTag?: TagType | null;
  setIsTagFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  retrieveTagsList: () => void;
  tagsTypes: { label: string; value: string }[];
}

interface TagFormDataType {
  name?: string;
  objectType?: string;
  active?: string | boolean;
}

export const TagForm = ({
  chosenTag,
  setIsTagFormOpen,
  retrieveTagsList,
  tagsTypes,
}: TagFormProps) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: chosenTag,
  });

  const onsubmit = (data: TagFormDataType) => {
    let keyData: keyof typeof data;
    for (keyData in data) {
      if (data[keyData] === undefined) delete data[keyData];
    }

    const reqPayload = {
      name: data.name,
      objectType: data.objectType,
      active: data.active === "true" || data.active === true ? true : false,
    };

    if (chosenTag?.id) {
      updateTag(chosenTag?.id, reqPayload)
        .then(() => {
          reset();
          setIsTagFormOpen((prevState) => !prevState);

          toast.success("Zaktualizowano tag.");
        })
        .then(() => {
          retrieveTagsList();
        })
        .catch(() => {
          toast.error("Nie udało się zaktualizować tagu.");
        });
    } else {
      createTag(reqPayload)
        .then(() => {
          reset();
          setIsTagFormOpen((prevState) => !prevState);

          toast.success("Dodano tag.");
        })
        .then(() => {
          retrieveTagsList();
        })
        .catch(() => {
          toast.error("Nie udało się dodać tagu.");
        });
    }
  };

  useEffect(() => {
    if (chosenTag) {
      setTimeout(async () => {
        reset(chosenTag);
      }, 2000);
    }
  }, [chosenTag, reset]);

  return (
    <form onSubmit={handleSubmit(onsubmit)}>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledHeading>1. Dane ogólne</StyledHeading>
      </Flex>
      <StyledWrapperFlex
        justifyContent="flex-start"
        alignItems="center"
        p={10}
        pr={20}
        mb={10}
      >
        <WrapperInput mr={2}>
          <Label>Name</Label>
          <Input {...register("name")} />
        </WrapperInput>

        <WrapperInput mr={2}>
          <Label>Typ obiektu</Label>
          <Select {...register("objectType")}>
            {tagsTypes.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </WrapperInput>

        <WrapperInput mr={2}>
          <Label>Status</Label>
          <Select {...register("active")}>
            <option value="true">Aktywny</option>
            <option value="false">Nie aktywny</option>
          </Select>
        </WrapperInput>
      </StyledWrapperFlex>

      <Flex justifyContent="flex-end" mr={10}>
        <Button type="submit" bordered>
          Zapisz
        </Button>
      </Flex>
    </form>
  );
};
