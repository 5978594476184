import React, { useState, useEffect } from "react";
import { PageLayout } from "components/PageLayout";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import { Input } from "components/Input";
import EChartsReact from "echarts-for-react";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";

interface IData {
  partner: string;
  cost: number;
  delay: number;
}

const response = [
  {
    partner: "Nokia",
    cost: 100,
    delay: 12,
  },
  {
    partner: "Herkules",
    cost: 100,
    delay: 522,
  },
  {
    partner: "Fonon",
    cost: 100,
    delay: 141,
  },
  {
    partner: "Spc-2",
    cost: 50,
    delay: 222,
  },
  {
    partner: "razem",
    cost: 350,
    delay: 897,
  },
];

export const GeneralCostView = () => {
  const [data, setData] = useState<IData[]>(response);
  const { setTableData } = useTableSidebarDispatchContext();

  const generateXAxisData = () =>
    data.filter((val) => val.partner !== "razem").map((item) => item.delay);

  const generateYAxisData = () =>
    data.filter((val) => val.partner !== "razem").map((item) => item.partner);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: generateYAxisData(),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Forecast",
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        color: ["#7D33F2"],
        data: generateXAxisData(),
      },
    ],
  };

  const handleChangeDelaySumValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "cost" | "delay"
  ) => {
    e.preventDefault();
    const cloneData = [...data];
    const foundIndex = cloneData.findIndex(
      (item) => item.partner === e.target.name
    );
    cloneData[foundIndex][type] = Number(e.target.value);
    setData(cloneData);
    generateSum();
  };

  const generateSum = () => {
    const cloneData = [...data];
    const sumDelay = cloneData
      .filter((item) => item.partner !== "razem")
      .reduce((val, acc) => {
        return acc.delay + val;
      }, 0);
    const sumCost = cloneData
      .filter((item) => item.partner !== "razem")
      .reduce((val, acc) => acc.cost + val, 0);
    const foundIndex = cloneData.findIndex((item) => item.partner === "razem");
    cloneData[foundIndex] = {
      delay: sumDelay,
      cost: sumCost * sumDelay,
      partner: "razem",
    };
    setData(cloneData);
  };

  useEffect(() => {
    setTimeout(() => {
      setTableData({
        hiddenSidebar: true,
      });
    });
  }, []);

  return (
    <PageLayout displaySidebarTable={false}>
      <Box mx={5}>
        <h3>Koszta ogólne</h3>
        <Card>
          <Box p={3}>
            Estymowana data zakończenia projektu: <strong>04-11-2024</strong>
          </Box>
        </Card>
        <Flex
          justifyContent="space-between"
          justifyItems="center"
          alignItems="baseline"
        >
          <Flex flexDirection="column">
            <p></p>
            <Box mb={6}>
              <p>Dni opóźnienia (kalendarzowe)</p>
            </Box>
            <p>Koszta (pln)</p>
          </Flex>
          {data
            .filter((val) => val.partner !== "razem")
            .map((item) => (
              <Flex key={item.partner} flexDirection="column">
                <h4>{item.partner}</h4>
                <Box mb={6}>
                  <Input
                    name={item.partner}
                    value={item.delay}
                    onChange={(e) => handleChangeDelaySumValue(e, "delay")}
                  />
                </Box>
                <Input
                  value={item.cost}
                  name={item.partner}
                  onChange={(e) => handleChangeDelaySumValue(e, "cost")}
                />
              </Flex>
            ))}
          {data
            .filter((val) => val.partner === "razem")
            .map((item) => (
              <Flex key={item.partner} flexDirection="column">
                <h4>{item.partner}</h4>
                <Box mb={6}>
                  <Input
                    name={item.partner}
                    value={item.delay}
                    onChange={(e) => handleChangeDelaySumValue(e, "delay")}
                  />
                </Box>
                <Input value={item.cost} disabled />
              </Flex>
            ))}
        </Flex>

        <Box mt={10}>
          <EChartsReact option={option} />
        </Box>
      </Box>
    </PageLayout>
  );
};
