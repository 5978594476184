import React, { useMemo } from "react";
import { formatDate } from "utilities/formatDate";

interface IDetails {
  fieldSetTitle: string;
  addingField?: boolean;
  fields: IField[];
}

interface IField {
  name: string;
  label: string;
  type?: string;
  disabled?: boolean;
}

export const useTaskDetails = (typeParam: string) => {
  const [detailsFieldsSets, setDetailsFieldsSets] = React.useState<IDetails[]>(
    []
  );

  const detailsFieldsOTK: IDetails[] = useMemo(
    () => [
      {
        fieldSetTitle: "Dane ogólne",
        fields: [
          {
            label: "Id.",
            name: "task",
          },
          {
            label: "CSC",
            name: "code",
          },
          {
            label: "Nazwa zadania",
            name: "name",
          },
          {
            label: "Ścieżka do folderu",
            name: "remoteFolderPath",
          },
          {
            label: "Wykonawca",
            name: "company",
          },
          {
            label: "Koordynator",
            name: "coordinator",
          },
          {
            label: "IZ",
            name: "iZ",
          },
          {
            label: "Nr lini",
            name: "lineNumber",
          },
          {
            label: "km_od",
            name: "chainageStart",
          },
          {
            label: "km_do",
            name: "chainageEnd",
          },
          {
            label: "długość",
            name: "chainageLength",
          },
          {
            label: "Wykonano %",
            name: "completion",
            disabled: true,
          },
          {
            label: "Typ obiektu",
            name: "type",
          },
          {
            label: "Załącznik",
            name: "zalacznik",
          },
          {
            label: "Załącznik 2",
            name: "zalacznik2",
          },
          {
            label: "Jednostronne",
            name: "jednostronna",
            type: "select",
          },
          {
            label: "Rozpoczęcie",
            name: "startDate",
            type: "date",
            Cell: (item) =>
              item.row.original.startDate
                ? formatDate(new Date(item.row.original.startDate))
                : "--",
          },
          {
            label: "Zakończenie",
            name: "endDate",
            type: "date",
            Cell: (item) => formatDate(new Date(item.row.original.endDate)),
          },
          {
            label: "Czas trwania",
            name: "duration",
          },
          {
            label: "Termin ostateczny",
            name: "terminOstateczny",
            type: "date",
          },
          {
            label: "Etap",
            name: "phase",
          },
          {
            label: "Rok",
            name: "year",
          },
          {
            label: "Aktywny",
            name: "active",
            type: "select",
          },
        ],
      },
    ],
    []
  );

  const detailsFieldsFDS: IDetails[] = useMemo(
    () => [
      {
        fieldSetTitle: "Dane ogólne",
        fields: [
          {
            label: "CSC",
            name: "code",
          },
          {
            label: "Nazwa",
            name: "taskName",
          },
          {
            label: "Ścieżka do folderu",
            name: "remoteFolderPath",
          },
          {
            label: "Nazwa IE-50",
            name: "objectNameIE50",
          },
          {
            label: "Nazwa lokalizacji",
            name: "locationName",
          },
          {
            label: "Typ obiektu",
            name: "type",
          },
          {
            label: "Wykonawca",
            name: "contractorId",
          },
          {
            label: "Czy jest w HRF",
            name: "inHRF",
            type: "select",
          },
          {
            label: "Rozpoczęcie",
            name: "startDate",
            type: "date",
          },
          {
            label: "Zakończenie",
            name: "endDate",
            type: "date",
            Cell: (item) => formatDate(new Date(item.row.original.endDate)),
          },
          {
            label: "Czas trwania",
            name: "duration",
          },
          {
            label: "Termin ostateczny",
            name: "terminOstateczny",
            type: "date",
          },
          {
            label: "Wykonano %",
            name: "completion",
            disabled: true,
          },
          {
            label: "PLK IZ",
            name: "plkIZ",
          },
          {
            label: "Sekcja ISE",
            name: "sectionISE",
          },
          {
            label: "Właściciel ziemi",
            name: "landOwner",
          },
          {
            label: "IZ",
            name: "iZ",
          },
          {
            label: "Aktywny",
            name: "active",
            type: "select",
          },
        ],
      },
      {
        fieldSetTitle: "Dane lokalizacji",
        fields: [
          {
            label: "Kilometraz OPZ",
            name: "chainageOPZ",
          },
          {
            label: "Kilometraz rzeczywisty",
            name: "chainageReal",
          },

          {
            label: "Szerokość geograficzna",
            name: "latitude",
          },
          {
            label: "Długość geograficzna",
            name: "longitude",
          },
        ],
      },
      {
        fieldSetTitle: "Dane informacyjne",
        fields: [
          {
            label: "Zal 8 Date",
            name: "annex8Date",
            type: "date",
          },
          {
            label: "Ilość terminali FDS na obiekcie zał 8 17 07 2018",
            name: "fdsTerminalNum817072018",
          },
          {
            label: "Ilość terminali FDS na obiekcie zal 8 29 03 2018",
            name: "fdsTerminalNum829032018",
          },
        ],
      },
    ],
    []
  );

  const detailsFieldsCertificationCWandUTK: IDetails[] = useMemo(
    () => [
      {
        fieldSetTitle: "Dane ogólne",
        fields: [
          {
            label: "Id.",
            name: "task",
          },
          {
            label: "CSC",
            name: "code",
          },
          {
            label: "Nazwa zadania",
            name: "name",
          },
          {
            label: "Ścieżka do folderu",
            name: "remoteFolderPath",
          },
          {
            label: "Etap",
            name: "phase",
          },
          {
            label: "Typ obiektu",
            name: "type",
          },
          {
            label: "Nr lini",
            name: "lineNumber",
            disabled: true,
          },
          {
            label: "km_od",
            name: "chainageStart",
          },
          {
            label: "km_do",
            name: "chainageEnd",
          },
          {
            label: "długość",
            name: "length",
            disabled: true,
          },
          {
            label: "Wykonano %",
            name: "completion",
            disabled: true,
          },

          {
            label: "Czas trwania",
            name: "duration",
          },

          {
            label: "Rozpoczęcie",
            name: "startDate",
            type: "date",
          },
          {
            label: "Zakończenie",
            name: "endDate",
            type: "date",
            Cell: (item) => formatDate(new Date(item.row.original.endDate)),
          },
          {
            label: "Termin ostateczny",
            name: "terminOstateczny",
            type: "date",
          },
          {
            label: "Aktywny",
            name: "active",
            type: "select",
          },
        ],
      },
    ],
    []
  );

  const detailsFieldsMilestone: IDetails[] = useMemo(
    () => [
      {
        fieldSetTitle: "Dane ogólne",
        fields: [
          {
            label: "Id.",
            name: "task",
          },
          {
            label: "CSC",
            name: "code",
          },
          {
            label: "Nazwa zadania",
            name: "name",
          },
          {
            label: "Ścieżka do folderu",
            name: "remoteFolderPath",
          },
          {
            label: "Typ obiektu",
            name: "type",
          },
          {
            label: "Nr lini",
            name: "lineNumber",
          },
          {
            label: "km_od",
            name: "chainageStart",
          },
          {
            label: "km_do",
            name: "chainageEnd",
          },
          {
            label: "długość",
            name: "chainageLength",
          },
          {
            label: "Wykonano %",
            name: "completion",
            disabled: true,
          },

          {
            label: "Czas trwania",
            name: "duration",
          },

          {
            label: "Rozpoczęcie",
            name: "startDate",
            type: "date",
          },
          {
            label: "Zakończenie",
            name: "endDate",
            type: "date",
            Cell: (item) => formatDate(new Date(item.row.original.endDate)),
          },
          {
            label: "Termin ostateczny",
            name: "terminOstateczny",
            type: "date",
          },
          {
            label: "Aktywny",
            name: "active",
            type: "select",
          },
        ],
      },
    ],
    []
  );

  const detailsFieldsOld: IDetails[] = useMemo(
    () => [
      {
        fieldSetTitle: "Dane ogólne",
        fields: [
          {
            label: "CSC",
            name: "code",
          },
          {
            label: "Nazwa",
            name: "taskName",
          },
          {
            label: "Nazwa IE-50",
            name: "objectNameIE50",
          },
          {
            label: "Ścieżka do folderu",
            name: "remoteFolderPath",
          },
          {
            label: "Nr OR 7 1 36 2",
            name: "nrOR71362",
          },
          {
            label: "Project ID",
            name: "projectId",
          },
          {
            label: "SMP Name",
            name: "smpName",
          },
          {
            label: "SMP ID",
            name: "smpId",
          },
          {
            label: "Work Order ID",
            name: "workOrderId",
          },
          {
            label: "Work Order ID",
            name: "workOrderId",
          },
          {
            label: "Nazwa Odcinka",
            name: "sectionName",
          },
          {
            label: "SPC Partner",
            name: "spcPartner",
          },
          {
            label: "Wariant",
            name: "variant",
          },
          {
            label: "Typ Obiektu",
            name: "type",
          },

          {
            label: "Nazwa lokalizacji",
            name: "locationName",
          },
          {
            label: "Wykonawca SABP",
            name: "contractorSA",
          },
          {
            label: "Wykonawca CW",
            name: "contractorCW",
          },
          {
            label: "Czy jest w HRF",
            name: "inHRF",
            type: "select",
          },
          {
            label: "Rozpoczęcie",
            name: "startDate",
            type: "date",
          },
          {
            label: "Zakończenie",
            name: "endDate",
            type: "date",
          },
          {
            label: "Czas trwania",
            name: "duration",
          },
          {
            label: "Termin ostateczny",
            name: "terminOstateczny",
            type: "date",
          },
          {
            label: "Wykonano %",
            name: "completion",
            disabled: true,
          },
          {
            label: "Nazwa lini - relacja",
            name: "lineName",
          },
          {
            label: "Numer lini",
            name: "lineNumber",
          },
          {
            label: "Numer lini OTK",
            name: "lineNumberOTK",
          },
          {
            label: "Kilometr",
            name: "chainage",
          },
          {
            label: "PLK IZ",
            name: "plkIZ",
          },
          {
            label: "Sekcja ISE",
            name: "sectionISE",
          },
          {
            label: "IZ",
            name: "iZ",
          },
          {
            label: "Wysokość wieży",
            name: "towerHeight",
          },
          {
            label: "Komentarz",
            name: "notes",
          },
          {
            label: "Aktywny",
            name: "active",
            type: "select",
          },
        ],
      },
      {
        fieldSetTitle: "Dane lokalizacji",
        addingField: true,
        fields: [
          {
            label: "Właściciel gruntu",
            name: "landOwner",
          },
          {
            label: "Nazwa lokalizacji",
            name: "locationName",
          },

          {
            label: "Numer działki",
            name: "plotNumber",
          },
          {
            label: "latitude",
            name: "latitude",
          },
          {
            label: "longitude",
            name: "longitude",
          },
          {
            label: "Województwo",
            name: "voivodeship",
          },
          {
            label: "Gmina",
            name: "community",
          },
          {
            label: "Powiat",
            name: "district",
          },
          {
            label: "Obręb",
            name: "area",
          },
          {
            label: "Numer obrębu",
            name: "areaNumber",
          },
        ],
      },
      {
        fieldSetTitle: "Dane informacyjne",
        fields: [
          {
            label: "Zmiana lokalizacji",
            name: "locationChange",
            type: "select",
          },
          {
            label: "Faza",
            name: "phase",
          },
          {
            label: "Etap",
            name: "stage",
          },
          {
            label: "Nowy etap",
            name: "stageNew",
          },
          {
            label: "Konserwator zabytkow",
            name: "konserwatorZabytkow",
            type: "select",
          },
          {
            label: "Natura 2000",
            name: "natura2000",
            type: "select",
          },
          {
            label: "Konserwator przyrody",
            name: "konserwatorPrzyrody",
            type: "select",
          },
          {
            label: "Wycinka drzew",
            name: "wycinkaDrzew",
            type: "select",
          },
          {
            label: "Zjazdy z drogi",
            name: "zjazdyZDrogi",
            type: "select",
          },
          {
            label: "MPZP",
            name: "mPZP",
            type: "select",
          },
          {
            label: "DULICP",
            name: "dULICP",
            type: "select",
          },
          {
            label: "Decyzja Środowiskowa",
            name: "decyzjaSrodowiskowa",
            type: "select",
          },
        ],
      },
    ],
    []
  );

  const renderColumns = () => {
    switch (typeParam) {
      case "OTK":
        setDetailsFieldsSets(detailsFieldsOTK);
        break;
      case "FDS":
        setDetailsFieldsSets(detailsFieldsFDS);
        break;
      case "CertyfikacjaWE":
        setDetailsFieldsSets(detailsFieldsCertificationCWandUTK);
        break;
      case "Certyfikacja":
        setDetailsFieldsSets(detailsFieldsCertificationCWandUTK);
        break;
      case "ReadyToWE":
        setDetailsFieldsSets(detailsFieldsCertificationCWandUTK);
        break;
      case "Milestone":
        setDetailsFieldsSets(detailsFieldsMilestone);
        break;
      case "milestone":
        setDetailsFieldsSets(detailsFieldsMilestone);
        break;
      default:
        setDetailsFieldsSets(detailsFieldsOld);
        break;
    }
  };

  React.useEffect(() => {
    renderColumns();
  }, [typeParam]);

  return { detailsFieldsSets, setDetailsFieldsSets };
};
