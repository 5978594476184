import React from "react";
import { Flex } from "components/Flex";
import { Button } from "../Button";
import { Wrapper, StyledBox, Paragraph, Heading } from "./DateDetails.styled";

interface IProps {
  selected: string;
  editDetails: (() => void) | undefined;
}

interface ISelected {
  context: number;
  meta: null;
  name: string;
  type: string;
  value: {
    actual: string;
    forecast: string;
    custom: string;
  };
}
interface IParsedData {
  items: ISelected[];
  taskName: string;
}

export const DateDetails: React.FC<IProps> = ({ selected, editDetails }) => {
  const parsedData: IParsedData = JSON.parse(selected);
  return (
    <Wrapper mt={5}>
      {parsedData && (
        <>
          <Heading>
            <p>{parsedData.taskName}</p>
            <Button bordered onClick={editDetails}>
              Edytuj
            </Button>
          </Heading>
          {parsedData.items.map((item: ISelected) => (
            <>
              <StyledBox mx={4} px={3} py={1} mb={2} key={item.name}>
                <Flex justifyContent="space-between" alignItems="flex-start">
                  <Paragraph>{item.name}</Paragraph>
                  <Paragraph>{item.context}</Paragraph>
                </Flex>
                <Flex justifyContent="space-between" flexDirection="column">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Paragraph>Forecast</Paragraph>
                    <Paragraph>{item.value?.forecast || "--"}</Paragraph>
                  </Flex>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Paragraph>Actual</Paragraph>
                    <Paragraph>{item.value?.actual || "--"}</Paragraph>
                  </Flex>
                </Flex>
              </StyledBox>
            </>
          ))}
        </>
      )}
    </Wrapper>
  );
};
