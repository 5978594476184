import styled from "styled-components";
import addIcon from "../../icons/person.svg";
import editIcon from "../../icons/pen.svg";
import removeIcon from "../../icons/trash.svg";
import { Box } from "components/Box";

export const IconAdd = styled.img.attrs({
  alt: "add",
  width: 20,
  height: 20,
  src: addIcon,
})`
  cursor: pointer;
  max-width: 20px;
`;
export const IconEdit = styled.img.attrs({
  alt: "edit",
  width: 20,
  height: 20,
  src: editIcon,
})`
  cursor: pointer;
  max-width: 20px;
`;
export const IconDelete = styled.img.attrs({
  alt: "remove",
  width: 20,
  height: 20,
  src: removeIcon,
})`
  cursor: pointer;
  max-width: 20px;
`;

export const StyledBox = styled(Box)`
  width: 922px;
`;
