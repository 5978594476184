import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Input } from "components/Input";
import { toast } from "react-toastify";
import { HelperPanelWrapper, ContentWrapper } from "./UsersView.styled";
import { updateSomeonesPassword } from "services/user";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";

const Typography = styled.p<{ variant?: "error" }>`
  line-height: 15px;
  margin: 0;
  ${({ theme, variant }) => `
    ${variant === "error" && `color: ${theme.palette.text.warring}`}
  `}
`;

const updatePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Hasło musi być większe od 6 znaków.")
    .max(128, "Hasło musi być mniej od 128 znaków."),
  repassword: yup
    .string()
    .oneOf([yup.ref("password")], "Hasła nie pasują do siebie."),
});

export const ChangeUsersPasswordView = () => {
  const history = useHistory();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  const { id } = useParams<{ id: string }>();

  const onsubmit = (data) => {
    updateSomeonesPassword({ id, password: data.password })
      .then(() => {
        history.push("../");
        toast.success("Zaktualizowano hasło użytkownika.");
      })
      .catch(() => {
        toast.error("Nie udało się zaktualizować hasła użytkownika.");
      });
  };

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <Flex>
        <HelperPanelWrapper>Zmiana hasła użytkownika</HelperPanelWrapper>
        <Box mt={4} mr={6}>
          <Button
            bordered
            onClick={() => {
              history.push("../");
            }}
          >
            Wróć
          </Button>
        </Box>
      </Flex>
      <ContentWrapper>
        <form onSubmit={handleSubmit(onsubmit)}>
          <Card>
            <Box mr={2} p={4}>
              <Flex>
                <Input label="Nowe hasło" {...register("password")} />
                <Box ml={2}>
                  <Input
                    label="Powtórz nowe hasło"
                    {...register("repassword")}
                  />
                </Box>
                <Box ml={2} mt={4}>
                  <Button type="submit" bordered>
                    Zapisz
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Card>
          {formState.errors.password && (
            <Flex m={1} flexBasis="100%" justifyContent="flex-start">
              <Typography variant="error">
                {formState.errors.password.message}
              </Typography>
            </Flex>
          )}
          {formState.errors.repassword && (
            <Flex m={1} flexBasis="100%" justifyContent="flex-start">
              <Typography variant="error">
                {formState.errors.repassword.message}
              </Typography>
            </Flex>
          )}
        </form>
      </ContentWrapper>
    </PageLayout>
  );
};
