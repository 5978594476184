import {
  ITask,
  ITaskChild,
  ITaskHistory,
  ITaskMeta,
  ITaskPredecessor,
  SharedDirectoryItemType,
  TaskDisruptionType,
  TaskType,
} from "types/task";
import { fetchAPI, fetchFilesAPI } from "utilities/fetchAPI";
import qs from "qs";
import { ITaskDetails } from "types/taskDetails";
import { IAntenna } from "types/antenna";
import { IPagination } from "types/pagination";
import { IFilters } from "../contexts/TasksContext";
import {
  dateTwoSABPFormSectionUrl,
  dateTwoSABPFormStructureUrl,
  dateTwoUrl,
  dateTwoUrlTI,
  getChartLineStatus,
  updateDateTwoUrl,
} from "constants/path";
import { IDateTwoDataSABPandTI, IDateTwoDataTI } from "types/dateTwo";
import axios from "axios";
import { RegTagBodyType, TagType } from "types/tags";
import { RequestResponseType } from "types/request";

export type taskMetaContext = "SA" | "CW" | "TI" | "none";
export type taskMetaType = "date" | "other";

interface IFetchTasks {
  type: TaskType | Array<TaskType>;
  limit?: number;
  offset?: number;
  filters?: Partial<IFilters>;
  lineNumberFilter?: number;
  code?: string;
}

export interface IFetchTasksResponse {
  data: Array<ITask>;
  pagination: IPagination;
}

export interface IUpdateTask {
  code: string;
  type: TaskType | Array<TaskType>;
  name: string;
  parent: string;
  startDate: string;
  endDate: string;
}

export const fetchTasks = ({
  type,
  limit,
  offset,
  filters,
  lineNumberFilter,
  code,
}: IFetchTasks) => {
  const query = qs.stringify(
    {
      limit: limit ?? undefined,
      offset,
      filter: filters,
      "filter[lineNumber]": lineNumberFilter ?? undefined,
      code: code ?? undefined,
    },
    { arrayFormat: "brackets" }
  );

  return Array.isArray(type)
    ? fetchAPI<IFetchTasksResponse>(
        `${process.env.REACT_APP_API_URL}/tasks/list-multiple/${type.join(
          ","
        )}?${query}`
      )
    : fetchAPI<IFetchTasksResponse>(
        `${process.env.REACT_APP_API_URL}/tasks/list/${type}?${query}`
      );
};

interface IFetchTask {
  taskId: string;
}

export const fetchTask = ({ taskId }: IFetchTask) => {
  return fetchAPI<ITask>(`${process.env.REACT_APP_API_URL}/task/${taskId}`);
};

interface IFindOTKTasks {
  lineNumber: string;
  chainageStart: string;
  chainageEnd: string;
  positionType?: string;
}

export const findOTKTasks = ({
  lineNumber,
  chainageStart,
  chainageEnd,
  positionType,
}: IFindOTKTasks) => {
  const query = qs.stringify(
    {
      "filter[positionType]": positionType ?? undefined,
    },
    { arrayFormat: "brackets" }
  );
  return fetchAPI<number>(
    `${process.env.REACT_APP_API_URL}/disruptions/otk/find-objects/${lineNumber}/${chainageStart}/${chainageEnd}?${query}`
  );
};

export const findORTasks = ({
  lineNumber,
  chainageStart,
  chainageEnd,
}: IFindOTKTasks) => {
  console.log("src/services/tasks.ts 91: export const findORTasks");
  return fetchAPI<number>(
    `${process.env.REACT_APP_API_URL}/disruptions/or/find-objects/${lineNumber}/${chainageStart}/${chainageEnd}`
  );
};
export const fetchTaskDetails = ({ taskId }: { taskId: string }) => {
  return fetchAPI<ITaskDetails>(
    `${process.env.REACT_APP_API_URL}/task-details/${taskId}`
  );
};

export const fetchTaskChildren = ({ taskId }: { taskId: string }) => {
  return fetchAPI<ITaskChild[]>(
    `${process.env.REACT_APP_API_URL}/task/${taskId}/children`
  );
};

export const fetchTaskPredecessors = ({ taskId }: { taskId: string }) => {
  return fetchAPI<ITaskPredecessor[]>(
    `${process.env.REACT_APP_API_URL}/task-predecessors/predecessors/${taskId}`
  );
};

export const fetchTaskPredecessorsDates = ({ taskId }: { taskId: string }) => {
  return fetchAPI<ITaskPredecessor[]>(
    `${process.env.REACT_APP_API_URL}/task-predecessors/dates/${taskId}`
  );
};

export const updateTask = (taskId: string, payload: IUpdateTask) => {
  return fetchAPI<ITaskChild>(
    `${process.env.REACT_APP_API_URL}/task/${taskId}`,
    { method: "PUT", body: JSON.stringify(payload) }
  );
};

export const updateHRFTask = (taskId: string, payload: IUpdateTask) => {
  return fetchAPI<ITaskChild & RequestResponseType>(
    `${process.env.REACT_APP_API_URL}/tasks/hrf/${taskId}`,
    { method: "PUT", body: JSON.stringify(payload) }
  );
};

export const updatePredecessorTaskMode = (
  taskId: string,
  payload: { predecessorsDatesManual: boolean }
) => {
  return fetchAPI<RequestResponseType>(
    `${process.env.REACT_APP_API_URL}/task-predecessors/dates/${taskId}/set-manual-strategy`,
    { method: "PUT", body: JSON.stringify(payload) }
  );
};

export const createTaskPredecessor = (payload: {
  task: string;
  predecessor: string;
  relationType: string;
  duration: number;
}) =>
  fetchAPI<RequestResponseType>(
    `${process.env.REACT_APP_API_URL}/task-predecessors/predecessors/`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );

export const deleteTaskPredecessor = (id: string) =>
  fetchAPI<RequestResponseType>(
    `${process.env.REACT_APP_API_URL}/task-predecessors/predecessors/${id}`,
    {
      method: "DELETE",
    }
  );

export const updateTaskOverall = ({
  taskId,
  taskDetails,
}: {
  taskId: string;
  taskDetails: ITaskDetails;
}) => {
  return fetchAPI<ITaskDetails>(
    `${process.env.REACT_APP_API_URL}/task-overall/${taskId}`,
    { method: "PUT", body: JSON.stringify(taskDetails) }
  );
};

export const updateTaskDetails = ({
  taskId,
  taskDetails,
}: {
  taskId: string;
  taskDetails: ITaskDetails;
}) => {
  return fetchAPI<ITaskDetails>(
    `${process.env.REACT_APP_API_URL}/task-details/${taskId}`,
    { method: "PUT", body: JSON.stringify(taskDetails) }
  );
};

type IFetchGroupedTaskMetaListResponse = Array<Array<ITaskMeta>>;

export const fetchGroupedTaskMetaList = (
  taskId: string,
  onlyDates?: boolean
) => {
  const query = qs.stringify({ onlyDates });
  return fetchAPI<IFetchGroupedTaskMetaListResponse>(
    `${process.env.REACT_APP_API_URL}/task-meta/${taskId}/list/grouped?${query}`
  );
};

export const fetchTaskMetaList = (
  taskId: string,
  filter?: {
    context?: taskMetaContext;
    type?: string;
    group?: string;
    label?: string;
  }
) => {
  const query = qs.stringify({ filter });
  return fetchAPI<(ITaskMeta & { context: taskMetaContext })[]>(
    `${process.env.REACT_APP_API_URL}/task-meta/${taskId}/list?${query}`
  );
};

interface IUpdateTaskMeta {
  taskMetaId: string;
  value: string;
}

export const updateTaskMeta = ({ taskMetaId, value }: IUpdateTaskMeta) => {
  return fetchAPI<ITaskMeta>(
    `${process.env.REACT_APP_API_URL}/task-meta/${taskMetaId}`,
    {
      method: "PUT",
      body: JSON.stringify({ value }),
    }
  );
};

interface IGetTaskAntennas {
  taskId: string;
}

interface IGetTaskAntennasResponse {
  antennas: Array<IAntenna>;
}

export const getTaskAntennas = ({ taskId }: IGetTaskAntennas) =>
  fetchAPI<IGetTaskAntennasResponse>(
    `${process.env.REACT_APP_API_URL}/task/or/implementation/${taskId}`
  );

interface IUpdateTaskAntennas {
  taskId: string;
  antennas: Array<IAntenna>;
}

interface IUpdateTaskAntennasResponse {
  antennas: Array<IAntenna>;
}

export const updateTaskAntennas = ({ taskId, antennas }: IUpdateTaskAntennas) =>
  fetchAPI<IUpdateTaskAntennasResponse>(
    `${process.env.REACT_APP_API_URL}/task/or/implementation/${taskId}`,
    {
      method: "PUT",
      body: JSON.stringify({ antennas }),
    }
  );

export const fetchTaskHistory = (
  taskId: string,
  dateFrom: string,
  dateTo: string
) =>
  fetchAPI<ITaskHistory[]>(
    `${process.env.REACT_APP_API_URL}/tasks/${taskId}/history/${dateFrom}/${dateTo}`
  );

export interface IPayment {
  id: string;
  mrw?: string;
  paymentTask: string;
  scope: string;
  lumpSumAmount: number;
  settledAmount: number;
  remainingAmount: number;
  taskId?: string;
  edit?: string;
  remove?: string;
  mrwNumber?: string;
  paymentDateRelations?: { name: string; dateTaskCode: string }[];
  delivery_date?: string;
  launch_date?: string;
  project_date?: string;
  cwe_date?: string;
  utk_date?: string;
}

export interface IPaymentsSummary {
  lumpSumAmount: number;
  settledAmount: number;
  remainingAmount: number;
  fraction: number;
  totalSettledAmount: number;
  lumpSumAmountPercentageComplete?: {
    value: string;
    formetted: string;
  };
  remainingAmountPercentageComplete?: {
    value: string;
    formetted: string;
  };
  settledAmountPercentageComplete?: {
    value: string;
    formetted: string;
  };
}

export interface IDictionary {
  id: string;
  name: string;
}

export const getPaymentTask = () =>
  fetchAPI<IDictionary[]>(
    `${process.env.REACT_APP_API_URL}/dict/task/payment/task`
  );
export const getPaymentScope = () =>
  fetchAPI<IDictionary[]>(
    `${process.env.REACT_APP_API_URL}/dict/task/payment/scope`
  );

export const getPaymentsSummary = (taskUuid: string) =>
  fetchAPI<IPaymentsSummary>(
    `${process.env.REACT_APP_API_URL}/task/payment/summary/${taskUuid}`
  );

export const getPayments = (taskUuid: string) =>
  fetchAPI<IPayment[]>(
    `${process.env.REACT_APP_API_URL}/task/payment/${taskUuid}`
  );

export const createPayment = (payload: IPayment) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/task/payment`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

export const updatePayment = (paymentUuid: string, payload: IPayment) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/task/payment/${paymentUuid}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });

export const deletePayment = (paymentUuid: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/task/payment/${paymentUuid}`, {
    method: "DELETE",
  });

export interface IEpisodes {
  ID: number;
  name: string;
  from: string;
  to: string;
  distance: string;
}
export interface IDataLine {
  episodes: IEpisodes[];
  outlineLevel: string;
}

export const fetchChartLineStatus = (id: number) =>
  fetchAPI<IDataLine[]>(getChartLineStatus(id));

export interface IDateTwoResponse {
  name: string;
  startDate: string;
  endDate: string;
  items: {
    type: string;
    name: string;
    context: number;
    forecast: string;
    actual: string;
    customText: string;
    id: {
      forecast: string;
      actual: string;
      custom: string;
      customText: string;
    }[];
    value: {
      forecast: string;
      actual: string;
      custom: {
        id: string;
        value: string;
        label: string;
        type: string;
      }[];
    };
    meta: null;
  }[];
  groups: [];
}

interface DateTwoSimpleDateValueType {
  taskId: string;
  itemType: string;
  forecastProperty: string;
  actualProperty: string;
  forecast: string;
  actual: string;
  customText?: string;
}

export const getDateTwo = (taskUuid: string, type: string) =>
  fetchAPI<IDateTwoResponse[]>(dateTwoUrl(taskUuid, type));
export const updateDateTwo = (taskMetaUuid: string, value: string) =>
  fetchAPI(updateDateTwoUrl(taskMetaUuid), {
    method: "PUT",
    body: JSON.stringify({ value }),
  });

export const updateDateTwoSimpleDate = (value: DateTwoSimpleDateValueType) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/task/dates/item/update`, {
    method: "PUT",
    body: JSON.stringify(value),
  });

export const getDateTwoSABPFormStructure = (taskUuid: string) =>
  fetchAPI<IDateTwoDataSABPandTI[]>(dateTwoSABPFormStructureUrl(taskUuid));

export const getDateTwoSABPFormSection = (
  taskUuid: string,
  sectionName: string
) =>
  fetchAPI<IDateTwoDataSABPandTI>(
    dateTwoSABPFormSectionUrl(taskUuid, sectionName)
  );

export const updateDateTwoSABPFormSection = ({
  taskId,
  sectionName,
  taskDetails,
}: {
  taskId: string;
  sectionName: string;
  taskDetails: any;
}) => {
  return fetchAPI(dateTwoSABPFormSectionUrl(taskId, sectionName), {
    method: "PUT",
    body: JSON.stringify(taskDetails),
  });
};

export const getDateTwoTI = (taskUuid: string) =>
  fetchAPI<IDateTwoResponse[]>(dateTwoUrlTI(taskUuid));

export type ITaskNotification = {
  id: string;
  content: string;
  date: string | Date;
  status: string;
  personAvatar: string;
  impactName: string;
  impact: string;
};

export const getTaskNotifications = ({ taskId }: { taskId: string }) =>
  fetchAPI<{ data: ITaskNotification[] }>(
    `${process.env.REACT_APP_API_URL}/task/${taskId}/issue/`
  );

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createTaskNotification = ({
  taskId,
  taskDetails,
}: {
  taskId: string;
  taskDetails: Partial<ITaskNotification>;
}) => {
  return fetchAPI(`${process.env.REACT_APP_API_URL}/task/${taskId}/issue/`, {
    method: "POST",
    body: JSON.stringify(taskDetails),
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateTaskNotification = ({
  taskId,
  notificationId,
  taskDetails,
}: {
  taskId: string;
  notificationId: string;
  taskDetails: Partial<ITaskNotification>;
}) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/task/${taskId}/issue/${notificationId}`,
    {
      method: "PUT",
      body: JSON.stringify(taskDetails),
    }
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteTaskNotification = ({
  taskId,
  notificationId,
}: {
  taskId: string;
  notificationId: string;
}) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/task/${taskId}/issue/${notificationId}`,
    {
      method: "DELETE",
    }
  );
};

export const downloadTaskFilteredReport = (
  taskType: string,
  filter: { [key: string]: string | string[] | number | number[] | boolean },
  select?: { selectedColumns: string[] }
) => {
  const query = qs.stringify({
    taskType,
    filter,
    select,
  });

  return axios.get(
    `${process.env.REACT_APP_API_URL}/report/task/filtered?${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const fetchUploadMediaObject = (data: FormData) =>
  fetchFilesAPI(`${process.env.REACT_APP_API_URL}/media_object/upload`, {
    method: "POST",
    body: data,
  });

export const fetchDownloadMediaObject = (mediaObjectId: string) =>
  fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/media_object/download/${mediaObjectId}`
  );

export const fetchTaskDisruptions = ({ taskId }: { taskId: string }) => {
  return fetchAPI<{ data: TaskDisruptionType[] }>(
    `${process.env.REACT_APP_API_URL}/task/${taskId}/disruptions/`
  );
};

interface GetTagsListType {
  data: TagType[];
}

interface TagsListFilterType {
  objectType: string;
  active: boolean;
}

export const getTagsList = (filters?: TagsListFilterType) => {
  const query = qs.stringify(
    {
      filter: filters,
    },
    { arrayFormat: "brackets" }
  );
  return fetchAPI<GetTagsListType>(
    `${process.env.REACT_APP_API_URL}/dict/tag/?${query}`
  );
};

export const getTag = (id: string) =>
  fetchAPI<TagType>(`${process.env.REACT_APP_API_URL}/dict/tag/${id}`);

export const createTag = (tagData: RegTagBodyType) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/dict/tag/`, {
    method: "POST",
    body: JSON.stringify(tagData),
  });

export const updateTag = (id: string, tagData: RegTagBodyType) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/dict/tag/${id}`, {
    method: "PUT",
    body: JSON.stringify(tagData),
  });

export const deleteTag = (id: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/dict/tag/${id}`, {
    method: "DELETE",
  });

export const getTagsTypes = () =>
  fetchAPI<string[]>(
    `${process.env.REACT_APP_API_URL}/dict/tag/options/types-list`
  );

export const activateTags = (ids: string[]) =>
  fetchAPI<string[]>(
    `${process.env.REACT_APP_API_URL}/dict/tag/patch/activate`,
    {
      method: "PUT",
      body: JSON.stringify(ids),
    }
  );

export const deactivateTags = (ids: string[]) =>
  fetchAPI<string[]>(
    `${process.env.REACT_APP_API_URL}/dict/tag/patch/deactivate`,
    {
      method: "PUT",
      body: JSON.stringify(ids),
    }
  );

export const getSharedDirectoryFilesList = (path?: string) => {
  const query = qs.stringify(
    {
      path,
    },
    { arrayFormat: "brackets" }
  );
  return fetchAPI<SharedDirectoryItemType[]>(
    `${process.env.REACT_APP_API_URL}/shared-directory/file-list/?${query}`
  );
};

export const downloadSharedDirectoryFile = (path: string) => {
  const query = qs.stringify(
    {
      path,
    },
    { arrayFormat: "brackets" }
  );
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/shared-directory/download-file/?${query}`
  );
};
