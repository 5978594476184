/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from "react";
import logo from "images/logo.svg";
import herkulesLogo from "images/herkules-logo.png";
import { ReactComponent as TaskManagerIcon } from "icons/sidebar-task-manager-icon.svg";
import { ReactComponent as AdminPanelIcon } from "icons/sidebar-admin-panel.svg";
import { ReactComponent as ImportExportPanelIcon } from "icons/sidebar-import-export.svg";
import { useRouteMatch } from "react-router-dom";
import { useLayoutStateContext } from "contexts/LayoutContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  Root,
  LogoOuterWrapper,
  LogoInnerWrapper,
  LogoLink,
  Logo,
  Heading,
  Hamburger,
  SidebarItems,
  FirstLevelMenu,
  FirstLevelMenuInner,
  NestedLevelMenusWrapper,
  MenuItem,
  AppVersionContainer,
} from "./Sidebar.styled";
import { envImportExportPanel, envProjectName } from "envData";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { appVersion } from "config/appVersion";
import { useSidebarConfig } from "hooks/useSidebarConfig";

type INaviType = "object" | "disruption" | "adminPanel" | "keys";

interface IActiveNavigation {
  type: INaviType;
  active: boolean;
}

export const Sidebar = () => {
  const {
    adminNavigationData,
    defaultValue,
    importExportNavigationData,
    keysNavigationData,
    navigationData,
  } = useSidebarConfig();

  const params = useRouteMatch<any>();
  const [active, setActive] = useState<IActiveNavigation[]>(defaultValue);

  const [open, setOpen] = useState(false);
  const { visibleSidebar, setVisibleSidebar } = useLayoutStateContext();

  const [activePanel, setActivePanel] = useState(
    params.url.includes("admin")
      ? "admin"
      : params.url.includes("import-export-panel")
      ? "import-export-panel"
      : "objects"
  );

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const linksToRender = loggedUserRoles
    ? activePanel === "admin"
      ? adminNavigationData
      : activePanel === "import-export-panel"
      ? importExportNavigationData
      : envProjectName === "herkules"
      ? loggedUserRoles?.includes("ROLE_OR_OVERALL_LIST")
        ? navigationData
        : keysNavigationData
      : navigationData
    : [];

  const toggleVisibleMenu = () => {
    setVisibleSidebar(!visibleSidebar);
  };

  const hadnelToggleActiveNavigation = (type: INaviType) => {
    const copyActive = [...active];
    const foundItem = copyActive.find((item) => item.type === type)!;
    const changedValue = { type: foundItem.type, active: !foundItem.active };
    const foundIndexItem = copyActive.findIndex((item) => item.type === type);

    copyActive.splice(foundIndexItem, 1);
    copyActive.splice(foundIndexItem, 0, changedValue);

    setActive(copyActive);
  };

  const activeIndexValue = () => {
    const copyActive = [...defaultValue];
    linksToRender.map((val) => {
      const navigationPosition = val.navigation.find((nav) => {
        if (params.url.includes(nav.to)) {
          return nav.to === params.url.match(nav.to)![0];
        }

        return nav.to === params.url;
      })!;

      if (val.navigation.includes(navigationPosition)) {
        console.log("elo");
        const foundIndex = copyActive.findIndex(
          (item) => item.type === val.type
        );
        copyActive.splice(foundIndex, 1);
        copyActive.splice(foundIndex, 0, {
          type: val.type,
          active: true,
        });
        defaultValue;

        setActive([
          ...defaultValue.filter((item) => item.type !== val.type),
          {
            type: val.type,
            active: true,
          },
        ]);
      }
    });
  };

  React.useLayoutEffect(() => {
    activeIndexValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.url, activePanel]);

  return (
    <Root
      visibleSidebar={visibleSidebar}
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <LogoOuterWrapper>
        <LogoInnerWrapper>
          <LogoLink to="/">
            <Logo
              src={envProjectName === "herkules" ? herkulesLogo : logo}
              alt="logo"
            />
          </LogoLink>
          <Hamburger onClick={toggleVisibleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </Hamburger>
        </LogoInnerWrapper>
      </LogoOuterWrapper>
      <SidebarItems>
        <FirstLevelMenu>
          <FirstLevelMenuInner>
            <TaskManagerIcon onClick={() => setActivePanel("objects")} />
            {loggedUserRoles?.includes("ROLE_ADMINISTRATION_LIST") && (
              <AdminPanelIcon onClick={() => setActivePanel("admin")} />
            )}
            {envImportExportPanel == "on" &&
              (loggedUserRoles?.find((role) => role.includes("ROLE_IMPORTS")) ||
                loggedUserRoles?.find((role) =>
                  role.includes("ROLE_EXPORTS")
                )) && (
                <ImportExportPanelIcon
                  onClick={() => setActivePanel("import-export-panel")}
                />
              )}
          </FirstLevelMenuInner>
        </FirstLevelMenu>
        <NestedLevelMenusWrapper>
          {linksToRender.map((item) => {
            const isActive = active.find(
              (val) => val.type === item.type
            )!.active;
            return (
              <>
                <Heading
                  active={isActive}
                  onClick={() => hadnelToggleActiveNavigation(item.type)}
                >
                  {isActive ? "-" : "+"} {item.heading}
                </Heading>
                {isActive ? (
                  <>
                    {item.navigation.map((nav) => (
                      <>
                        <MenuItem to={nav.to} nested>
                          {nav.name}
                        </MenuItem>
                      </>
                    ))}
                  </>
                ) : null}
              </>
            );
          })}
        </NestedLevelMenusWrapper>
      </SidebarItems>
      <AppVersionContainer ml={5} mb={2}>
        {appVersion}
      </AppVersionContainer>
    </Root>
  );
};
