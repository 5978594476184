import { forwardRef } from "react";
import styled from "styled-components";

interface IField extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
}

const Root = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => `
    flex: 0 0 auto;
    position: relative;
    ${fullWidth ? "width: 100%" : ""}
  `};
`;

const Label = styled.label`
  ${({ theme }) => `
    display: table;
    margin-bottom: -${theme.space[1]}px;
    margin-left: ${theme.space[3]}px;
    padding-left: ${theme.space[1]}px;
    position: relative;
    color: rgba(19, 19, 21, 0.65);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 15px;
    background-color: ${theme.palette.neutral.white};
  `};
`;

const StyledTextarea = styled.textarea`
  ${({ theme }) => `
    width: 100%;
    height: 96px;
    padding: 6px ${theme.space[4]}px 6px;
    border: 1px solid rgba(30, 46, 102, 0.15);
    border-radius: 8px;
    outline: none;
    font-family: inherit;
  `}
`;
const Icon = styled.div`
  position: absolute;
  right: 8px;
  top: 25px;
  transform: translateY(-50%);
  display: flex;
  font-size: 12px;
  color: rgba(30, 46, 102, 0.3);
`;

export const Textarea = forwardRef<HTMLTextAreaElement, IField>(
  ({ label, icon, fullWidth, ...props }, ref) => (
    <Root fullWidth={fullWidth}>
      <Label>{label}</Label>
      <StyledTextarea ref={ref} {...props} />
      <Icon>{icon}</Icon>
    </Root>
  )
);
