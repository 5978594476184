import React, { useState, useEffect } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { Input } from "components/Input";
import { Label, Select } from "components/Select";
import { ReactComponent as CloseIcon } from "icons/delete.svg";
import styled from "styled-components";

interface IProps {
  isOpen: boolean;
  handleSubmit: ({ duration: number, type: string }) => void;
  onCancelClick: () => void;
  detailsClickedObject?: {
    duration?: number;
    type?: string;
    objectId: string;
  };
}

const Wrapper = styled(Box)`
  position: relative;
`;
const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const typeOption = [
  { value: "start-start", label: "Start-start" },
  { value: "end-start", label: "Koniec-start" },
  { value: "end-end", label: "Koniec-koniec" },
];

export const UpdateCertificationModal: React.FC<IProps> = ({
  isOpen,
  handleSubmit,
  onCancelClick,
  detailsClickedObject,
}) => {
  const [count, setCount] = useState(0);
  const [typeValue, setTypeValue] = useState("");

  useEffect(() => {
    setCount(detailsClickedObject?.duration);
  }, [detailsClickedObject?.duration]);

  useEffect(() => {
    setTypeValue(detailsClickedObject?.type);
  }, [detailsClickedObject?.type]);

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Wrapper p={6}>
        <CloseButton onClick={onCancelClick} />
        <Box mb={10}>
          <h1>Edycja powiązania.</h1>
        </Box>
        <Flex>
          <Box mr={2}>
            <Label>Liczna dni opóźnienia</Label>
            <Input
              type="number"
              value={count}
              onChange={(event: React.FormEvent<HTMLInputElement>) =>
                setCount(Number(event.currentTarget.value))
              }
            />
          </Box>
          <Box>
            <Select
              label="Type"
              onChange={(item) => setTypeValue(item.currentTarget.value)}
            >
              <option key={typeValue || ""}>{typeValue || ""}</option>
              {typeOption.map((val) => (
                <option key={val.label} value={val.value}>
                  {val.label}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
        <Box mt={4}>
          <Flex justifyContent="flex-end">
            <Box mr={2}>
              <Button
                bordered
                onClick={() =>
                  handleSubmit({ duration: count, type: typeValue })
                }
              >
                Zapisz
              </Button>
            </Box>
            <Button onClick={onCancelClick}>Anuluj</Button>
          </Flex>
        </Box>
      </Wrapper>
    </Modal>
  );
};
