import { fetchAPI } from "utilities/fetchAPI";
import { IPagination } from "types/pagination";

type ICertyficationTask = {
  chainage: number;
  chainageEnd: null;
  chainageStart: null;
  code: string;
  company: null;
  completion: number;
  contractorCW: string;
  contractorSA: string;
  endDate: string;
  iZ: string;
  id: string;
  inHrf: boolean;
  length: number | null;
  line: string;
  locationName: string;
  name: string;
  objectName: string;
  objectNameIE50: string;
  problems: any[];
  startDate: string;
  subtaskDULICPEndDate: null;
  subtaskDULICPStartDate: null;
  subtaskPnBEndDate: string;
  subtaskPnBStartDate: string;
  subtaskPraceBudowlaneEndDate: string;
  subtaskPraceBudowlaneStartDate: string;
  subtaskPraceProjektoweEndDate: string;
  subtaskPraceProjektoweStartDate: string;
  subtaskPradNaObiekcieEndDate: string;
  subtaskPradNaObiekcieStartDate: string;
  subtaskUruchomienieEndDate: string;
  subtaskUruchomienieStartDate: string;
  subtaskWdrozenieEndDate: string | null;
  subtaskWdrozenieStartDate: string | null;
  subtaskZagospodarowanieEndDate: string;
  subtaskZagospodarowanieStartDate: string;
  taskmetaActualMontazWiezyEndDate: string;
  taskmetaForecastMontazWiezyEndDate: string;
  tastmetaActualMontazWiezyEndDateDate: string;
  tastmetaForecastMontazWiezyEndDateDate: string;
  type: string;
};

type ICertyficationData = {
  cwEndDate: string;
  duration: number;
  electricityDate: string;
  measurementDate: null;
  saEndDate: string;
  stage: string;
  task: ICertyficationTask;
  tiEndDate: null;
  type: string;
};

interface ICreateResponse {
  errors: Array<string>;
  id: string;
  isValidRequest: boolean;
  success: boolean;
}

interface IResponse {
  data: ICertyficationData[];
  pagination: IPagination;
}

interface IInvertedDate {
  id: any;
  name: string;
  type: string;
  code: string;
  startDate: string;
  endDate: string;
  chainage: number;
  chainageStart: number;
  chainageEnd: number;
  itemsCount: number;
  doneItemsCount: number;
}

interface IResponseInverse {
  data: IInvertedDate[];
  pagination: IPagination;
}

interface IPayloadUpdate {
  taskId: string;
  objectId: string;
  duration: number;
  type: string;
}

export const fetchCertificationDataService = (id: string) => {
  return fetchAPI<IResponse>(
    `${process.env.REACT_APP_API_URL}/task-relations/certification/${id}`
  );
};

export const createNewRelationsService = (
  id: string,
  payload: { duration: number; object: string }
) => {
  return fetchAPI<ICreateResponse>(
    `${process.env.REACT_APP_API_URL}/task-relations/certification/${id}`,
    {
      method: "POST",
      body: JSON.stringify({ ...payload }),
    }
  );
};

export const removeRelatedObject = (taskId: string, objectId: string) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/task-relations/certification/${taskId}/related/${objectId}`,
    {
      method: "DELETE",
    }
  );

export const updateRelationObjectService = ({
  duration,
  type,
  taskId,
  objectId,
}: IPayloadUpdate) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/task-relations/certification/${taskId}/related/${objectId}`,
    {
      method: "PUT",
      body: JSON.stringify({ duration, type }),
    }
  );

export const getCertyficationInvertedDataService = (taskId: string) =>
  fetchAPI<IResponseInverse>(
    `${process.env.REACT_APP_API_URL}/task-relations/certification/inverted/${taskId}`
  );
