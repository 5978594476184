export const rolesByObjects = {
  OTK: {
    OVERALL_SHOW: "ROLE_OTK_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_OTK_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_OTK_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_OTK_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_OTK_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_OTK_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_OTK_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_OTK_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_OTK_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_OTK_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_OTK_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_OTK_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_OTK_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_OTK_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_OTK_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_OTK_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_OTK_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_OTK_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_OTK_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_OTK_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_OTK_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_OTK_HISTORY_SHOW",
  },
  "OR,ORR": {
    OVERALL_SHOW: "ROLE_OR_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_OR_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_OR_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_OR_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_OR_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_OR_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_OR_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_OR_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_OR_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_OR_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_OR_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_OR_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_OR_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_OR_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_OR_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_OR_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_OR_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_OR_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_OR_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_OR_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_OR_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_OR_HISTORY_SHOW",
  },
  FDS: {
    OVERALL_SHOW: "ROLE_FDS_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_FDS_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_FDS_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_FDS_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_FDS_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_FDS_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_FDS_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_FDS_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_FDS_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_FDS_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_FDS_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_FDS_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_FDS_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_FDS_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_FDS_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_FDS_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_FDS_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_FDS_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_FDS_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_FDS_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_FDS_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_FDS_HISTORY_SHOW",
  },
  Tunel: {
    OVERALL_SHOW: "ROLE_TUNNELS_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_TUNNELS_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_TUNNELS_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_TUNNELS_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_TUNNELS_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_TUNNELS_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_TUNNELS_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_TUNNELS_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_TUNNELS_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_TUNNELS_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_TUNNELS_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_TUNNELS_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_TUNNELS_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_TUNNELS_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_TUNNELS_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_TUNNELS_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_TUNNELS_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_TUNNELS_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_TUNNELS_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_TUNNELS_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_TUNNELS_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_TUNNELS_HISTORY_SHOW",
  },
  milestone: {
    OVERALL_SHOW: "ROLE_MILESTONE_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_MILESTONE_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_MILESTONE_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_MILESTONE_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_MILESTONE_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_MILESTONE_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_MILESTONE_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_MILESTONE_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_MILESTONE_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_MILESTONE_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_MILESTONE_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_MILESTONE_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_MILESTONE_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_MILESTONE_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_MILESTONE_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_MILESTONE_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_MILESTONE_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_MILESTONE_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_MILESTONE_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_MILESTONE_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_MILESTONE_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_MILESTONE_HISTORY_SHOW",
  },
  OSZ: {
    OVERALL_SHOW: "ROLE_OSZ_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_OSZ_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_OSZ_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_OSZ_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_OSZ_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_OSZ_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_OSZ_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_OSZ_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_OSZ_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_OSZ_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_OSZ_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_OSZ_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_OSZ_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_OSZ_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_OSZ_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_OSZ_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_OSZ_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_OSZ_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_OSZ_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_OSZ_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_OSZ_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_OSZ_HISTORY_SHOW",
  },
  OC: {
    OVERALL_SHOW: "ROLE_OC_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_OC_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_OC_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_OC_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_OC_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_OC_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_OC_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_OC_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_OC_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_OC_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_OC_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_OC_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_OC_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_OC_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_OC_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_OC_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_OC_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_OC_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_OC_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_OC_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_OC_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_OC_HISTORY_SHOW",
  },
  CertyfikacjaWE: {
    OVERALL_SHOW: "ROLE_CERTIFICATION_WE_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_CERTIFICATION_WE_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_CERTIFICATION_WE_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_CERTIFICATION_WE_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_CERTIFICATION_WE_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_CERTIFICATION_WE_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_CERTIFICATION_WE_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_CERTIFICATION_WE_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_CERTIFICATION_WE_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_CERTIFICATION_WE_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_CERTIFICATION_WE_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_CERTIFICATION_WE_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_CERTIFICATION_WE_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_CERTIFICATION_WE_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_CERTIFICATION_WE_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_CERTIFICATION_WE_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_CERTIFICATION_WE_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_CERTIFICATION_WE_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_CERTIFICATION_WE_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_CERTIFICATION_WE_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_CERTIFICATION_WE_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_CERTIFICATION_WE_HISTORY_SHOW",
  },
  Certyfikacja: {
    OVERALL_SHOW: "ROLE_CERTIFICATION_UTK_OVERALL_SHOW",
    OVERALL_UPDATE: "ROLE_CERTIFICATION_UTK_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_CERTIFICATION_UTK_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_CERTIFICATION_UTK_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_CERTIFICATION_UTK_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_CERTIFICATION_UTK_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_CERTIFICATION_UTK_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_CERTIFICATION_UTK_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_CERTIFICATION_UTK_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_CERTIFICATION_UTK_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_CERTIFICATION_UTK_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_CERTIFICATION_UTK_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_CERTIFICATION_UTK_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_CERTIFICATION_UTK_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_CERTIFICATION_UTK_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_CERTIFICATION_UTK_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_CERTIFICATION_UTK_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_CERTIFICATION_UTK_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_CERTIFICATION_UTK_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_CERTIFICATION_UTK_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_CERTIFICATION_UTK_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_CERTIFICATION_UTK_HISTORY_SHOW",
  },
  ReadyToWE: {
    OVERALL_SHOW: "ROLE_READY_TO_WE_OVERALL_LIST",
    OVERALL_UPDATE: "ROLE_READY_TO_WE_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_READY_TO_WE_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_READY_TO_WE_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_READY_TO_WE_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_READY_TO_WE_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_READY_TO_WE_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_READY_TO_WE_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_READY_TO_WE_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_READY_TO_WE_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_READY_TO_WE_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_READY_TO_WE_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_READY_TO_WE_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_READY_TO_WE_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_READY_TO_WE_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_READY_TO_WE_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_READY_TO_WE_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_READY_TO_WE_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_READY_TO_WE_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_READY_TO_WE_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_READY_TO_WE_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_READY_TO_WE_HISTORY_SHOW",
  },
  OtherTask: {
    OVERALL_SHOW: "ROLE_OTHER_TASK_OVERALL_LIST",
    OVERALL_UPDATE: "ROLE_OTHER_TASK_OVERALL_UPDATE",
    DATE2_SHOW: "ROLE_OTHER_TASK_DATE2_SHOW",
    DATE2_UPDATE: "ROLE_OTHER_TASK_DATE2_UPDATE",

    DATE2_CW_SHOW: "ROLE_OTHER_TASK_DATE2_CW_SHOW",
    DATE2_CW_UPDATE: "ROLE_OTHER_TASK_DATE2_CW_UPDATE",
    DATE2_SABP_SHOW: "ROLE_OTHER_TASK_DATE2_SABP_SHOW",
    DATE2_SABP_UPDATE: "ROLE_OTHER_TASK_DATE2_SABP_UPDATE",
    DATE2_TI_SHOW: "ROLE_OTHER_TASK_DATE2_TI_SHOW",
    DATE2_TI_UPDATE: "ROLE_OTHER_TASK_DATE2_TI_UPDATE",

    DATES_HRF_SHOW: "ROLE_OTHER_TASK_DATES_HRF_SHOW",
    DATES_HRF_UPDATE: "ROLE_OTHER_TASK_DATES_HRF_UPDATE",
    CERTIFICATION_SHOW: "ROLE_OTHER_TASK_CERTIFICATION_SHOW",
    CERTIFICATION_LIST: "ROLE_OTHER_TASK_CERTIFICATION_LIST",
    CERTIFICATION_CREATE: "ROLE_OTHER_TASK_CERTIFICATION_CREATE",
    CERTIFICATION_UPDATE: "ROLE_OTHER_TASK_CERTIFICATION_UPDATE",
    CERTIFICATION_DELETE: "ROLE_OTHER_TASK_CERTIFICATION_DELETE",

    PAYMENT_SHOW: "ROLE_OTHER_TASK_PAYMENT_SHOW",
    PAYMENT_CREATE: "ROLE_OTHER_TASK_PAYMENT_CREATE",
    PAYMENT_UPDATE: "ROLE_OTHER_TASK_PAYMENT_UPDATE",
    PAYMENT_DELETE: "ROLE_OTHER_TASK_PAYMENT_DELETE",

    HISTORY_SHOW: "ROLE_OTHER_TASK_HISTORY_SHOW",
  },
};
