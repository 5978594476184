import styled from "styled-components";

interface IFieldSetTitle {
  label?: string;
}

const Root = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.space[3]}px;
    color: ${theme.palette.text.primary};
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 21px;
  `}
`;
const Label = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
`;

export const FieldSetTitle: React.FC<IFieldSetTitle> = ({
  label,
  children,
}) => {
  return (
    <Root>
      <Label>{label}</Label>
      {children}
    </Root>
  );
};
