import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const StyledTag = styled.div`
  ${({ theme }) => `
    display: inline-block;
    align-items: center;
    margin: ${theme.space[2]}px ${theme.space[1]}px;
    padding: ${theme.space[1]}px ${theme.space[3]}px;
    color: #001870a1;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    background-color: #e6f4ff;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
  `};
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => `
    margin-left: ${theme.space[2]}px;
  `};
`;

interface IDisruptionTag {
  value: string;
  label: string;
  handleRemove?: (value: string) => void;
}

export const DisruptionTag: React.FC<IDisruptionTag> = ({
  value,
  label,
  handleRemove,
}) => {
  return (
    <StyledTag>
      {label}
      {handleRemove && (
        <StyledIcon icon={faTimes} onClick={() => handleRemove(value)} />
      )}
    </StyledTag>
  );
};
