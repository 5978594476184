import { useState, useEffect } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Input } from "components/Input";
import { useFetchAPI } from "hooks/fetchAPI";
import { DICTIONARY_LINES_URL } from "constants/path";
import { useForm, Controller } from "react-hook-form";
import { fetchTasks } from "services/tasks";
import { createNewRelationsService } from "services/certyfication";
import { ITask, TaskType } from "types/task";
import { Label, Select } from "components/Select";
import MultiSelect from "react-select";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "icons/delete.svg";
import { Button } from "components/Button";
import { relatedSchema } from "schemas/RelatedForm";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  taskId: string;
  fetchCertification: () => void;
}

interface IFormData {
  duration: number;
  object: string;
  type: string;
  railwayLine?: string;
  objectType?:
    | ""
    | "OTK"
    | "OR"
    | "FDS"
    | "TUNELE"
    | "milestone"
    | "OSZ"
    | "OC"
    | "CERTYFIKACJA_WE"
    | "Certyfikacja";
}

const Wrapper = styled(Box)`
  position: relative;
  width: 40vw;
  height: 60vh;
`;
const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const WrapperField = styled.div`
  width: 50%;
`;
const StyledMultiSelect = styled(MultiSelect)<{ error: boolean }>`
  ${({ error }) => `
    width: 100%;
    ${
      error &&
      `
      border: 1px solid red !important;
    `
    }
  `}
`;
const StyledSelect = styled(Select)`
  width: 100%;
  height: 38px;
`;
const WrapperButtons = styled(Flex)`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;
const typeOption = [
  { value: "start-start", label: "Start-start" },
  { value: "end-start", label: "Koniec-start" },
  { value: "end-end", label: "Koniec-koniec" },
];

const defaultValues: IFormData = {
  duration: 0,
  object: "",
  type: "",
  objectType: "",
  railwayLine: "",
};

export const CreateNewCertificationModal = ({
  isOpen,
  handleCloseModal,
  taskId,
  fetchCertification,
}: IProps): JSX.Element => {
  const [availableORorFDSs, setavailableORorFDSs] = useState<Array<ITask>>([]);

  const { data: railwayLines } =
    useFetchAPI<Record<string, number>>(DICTIONARY_LINES_URL);

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
    reset,
  } = useForm<IFormData>({
    defaultValues,
    resolver: yupResolver(relatedSchema),
  });

  const ObjectTypeWacherValue = watch("objectType");
  const railwayLineValue = watch("railwayLine");

  const handleORorFDSInputSearch = (e) => {
    if (!e) return;
    fetchTasks({
      type: ObjectTypeWacherValue as TaskType,
      lineNumberFilter:
        railwayLineValue !== "" ? parseInt(railwayLineValue, 10) : undefined,
      filters: { search: e },
    }).then(({ data }) => {
      if (!availableORorFDSs.find((item) => item.code === data[0].code)) {
        setavailableORorFDSs((prevState) => [...data, ...prevState]);
      }
    });
  };

  const onSubmit = handleSubmit(({ duration, object, type }) => {
    const payload = { object, type, duration: Number(duration) };
    createNewRelationsService(taskId, payload)
      .then((data) => {
        if (data.errors.length > 0) {
          for (const err of data.errors) {
            toast.error(err);
          }
          return;
        }
        fetchCertification();
        handleCloseModal();
        toast.success("Powiązano obiekt z certyfikatem");
      })
      .catch((err) => {
        toast.error("Nie udało się dodać obiektu.");
      });
    reset();
  });

  useEffect(() => {
    if (
      [
        "OTK",
        "OR",
        "FDS",
        "milestone",
        "Tunel",
        "ReadyToWE",
        "CertyfikacjaWE",
        "Certyfikacja",
        "OC",
        "OSZ",
        "OtherTask",
      ].includes(ObjectTypeWacherValue)
    ) {
      fetchTasks({
        type: ObjectTypeWacherValue as TaskType,
        limit: 50,
        lineNumberFilter:
          railwayLineValue !== "" ? parseInt(railwayLineValue, 10) : undefined,
      }).then(({ data }) => {
        setavailableORorFDSs(data);
      });
    }
  }, [ObjectTypeWacherValue, railwayLineValue]);

  return (
    <Modal isOpen={isOpen} onCancelClick={handleCloseModal}>
      <Wrapper p={6}>
        <Box mb={10}>
          <h1>Nowe powiązanie</h1>
        </Box>
        <CloseButton onClick={handleCloseModal} />
        <form onSubmit={onSubmit}>
          <Box mr={2}>
            <Select label="Typ obiektu" {...register("objectType")}>
              <option value="" />
              <option value="OTK">OTK</option>
              <option value="OR">OR/ORR</option>
              <option value="FDS">FDS</option>
              <option value="Tunel">TUNELE</option>
              <option value="milestone">KAMIENIE MILOWE</option>
              <option value="OSZ">OSZ</option>
              <option value="OC">OC</option>
              <option value="ReadyToWE">GOTOWOŚĆ DO CERTYFIKACJI WE</option>
              <option value="CertyfikacjaWE">NR CERTYFIKACJI WE</option>
              <option value="Certyfikacja">NR DOPUSZCZENIA UTK</option>
              <option value="OtherTask">Inne</option>
            </Select>
          </Box>
          {/* <Flex> */}
          {[
            "OTK",
            "OR",
            "FDS",
            "Tunel",
            "milestone",
            "OSZ",
            "OC",
            "ReadyToWE",
            "CertyfikacjaWE",
            "Certyfikacja",
            "OtherTask",
          ].includes(ObjectTypeWacherValue) && (
            <>
              <Box my={6}>
                <Flex alignItems="center" justifyContent="space-between">
                  <WrapperField>
                    <StyledSelect
                      label="Linia kolejowa"
                      {...register("railwayLine")}
                    >
                      <option value=""></option>
                      {railwayLines
                        ? Object.entries(railwayLines).map((item) => (
                            <option key={item[0]} value={item[1]}>
                              {item[0]}
                            </option>
                          ))
                        : null}
                    </StyledSelect>
                  </WrapperField>
                  <WrapperField>
                    <Box key={ObjectTypeWacherValue} ml={2}>
                      <Label>Wybierz obiekt</Label>
                      <Controller
                        control={control}
                        name="object"
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <StyledMultiSelect
                              onInputChange={handleORorFDSInputSearch}
                              // styles={customSelectStyles}
                              error={!!errors.object?.message}
                              inputRef={ref}
                              required
                              // value={value}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              onChange={(val) => {
                                onChange(val.value);
                              }}
                              options={availableORorFDSs.map((ORorFDS) => {
                                const code = ORorFDS.code
                                  ? `CSC: ${ORorFDS.code} - `
                                  : "";
                                const locationName = ORorFDS.locationName || "";
                                const chainage = ORorFDS.chainage
                                  ? ` - ${ORorFDS.chainage}km`
                                  : "";
                                return {
                                  label: code + locationName + chainage,
                                  value: ORorFDS.id,
                                };
                              })}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </WrapperField>
                </Flex>
              </Box>
              <Flex justifyContent="space-between">
                <WrapperField>
                  <Box ml={2}>
                    <Select
                      label="Typ"
                      {...register("type")}
                      error={!!errors.type?.message}
                    >
                      <option key=""></option>
                      {typeOption.map((val) => (
                        <option key={val.label} value={val.value}>
                          {val.label}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </WrapperField>

                <WrapperField>
                  <Box mx={2}>
                    <Input
                      {...register("duration")}
                      type="number"
                      label="Liczba dni opóźnienia"
                    />
                  </Box>
                </WrapperField>
              </Flex>
              <WrapperButtons justifyContent="flex-end">
                <Box mr={2}>
                  <Button onClick={handleCloseModal} bordered>
                    Anuluj
                  </Button>
                </Box>
                <Button type="submit" bordered>
                  Dodaj
                </Button>
              </WrapperButtons>
            </>
          )}
          {/* </Flex> */}
        </form>
      </Wrapper>
    </Modal>
  );
};
