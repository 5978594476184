import { PageLayout } from "components/PageLayout";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import styled from "styled-components";
import { KeyPublicationsForm } from "containers/KeyPublicationsForm/KeyPublicationsForm";
import { useParams } from "react-router-dom";

const Root = styled(Flex)`
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
`;
const StyledBox = styled(Box)`
  height: calc(100% - 60px);
  width: 100%;
  background-color: #fff;
  border: 20px solid #f4f5f8;
  border-bottom: 0;
  overflow: auto;
`;
const StyledCard = styled(Card)`
  overflow: auto;
  width: 100%;
  background-color: #f4f5f8;
`;

export const KeyPublicationView = () => {
  const { id, givenId, requestId } =
    useParams<{ id: string; givenId: string; requestId: string }>();

  return (
    <PageLayout>
      <Root justifyContent="space-between">
        <StyledCard>
          <StyledBox px={8} py={6}>
            <KeyPublicationsForm
              givenId={givenId && givenId}
              requestId={requestId && requestId}
            />
          </StyledBox>
        </StyledCard>
      </Root>
    </PageLayout>
  );
};
