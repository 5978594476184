/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import { Box } from "components/Box";
import { Flex } from "./Flex";
import { forwardRef, useEffect, useState } from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import { TableType } from "types/tableType";
import { Button } from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faEnvelope,
  faFilter,
  faEllipsisH,
  faLongArrowAltRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "./Dropdown";
import qs from "qs";
import { useHistory, useParams } from "react-router";
import { DisruptionDetails } from "./DisruptionDetails";
import { IDisruption } from "types/disruption";
import { DICTIONARY_USERS_URL } from "constants/path";
import { fetchAPI } from "utilities/fetchAPI";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { useFetchDictionaryUsers } from "hooks/fetchDictionaryUsers";
import { CommentsWindow } from "./CommentsWindow";
import { TaskDetails } from "./TaskDetails";
import { DateDetails } from "./DateDetails/DateDetails";
import { ITaskDetails } from "../types/taskDetails";
import { Loader } from "components/Loader/Loader";

import {
  useTableSidebarContext,
  useTableSidebarDispatchContext,
  ITableData,
} from "contexts/TableSidebarContext";
import { PredecessorsList } from "./PredecessorsList/PredecessorsList";

const transitionDuration = 300;

interface IRoot {
  state: TransitionStatus;
}
interface ITableSidebar extends ITableData {
  toggleSidebar: () => void;
}

const Label = styled.label`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Root = styled.div<IRoot>`
  ${({ theme, state }) => `
    max-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    // navbar
    padding-top: 64px;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 2px 8px 0 rgba(30,46,102,0.1);
    font-size: 12px;
    line-height: 15px;
    transition: transform ${transitionDuration}ms ease-in-out;
    transform: translateX(
        ${state === "entering" || state === "entered" ? 0 : 460}px
    );
    z-index: 1;
  `}
`;

const ConfirmButton = styled(Button)`
  ${({ theme }) => `
    margin-top: ${theme.space[3]}px;
  `}
`;
const StyledFlex = styled(Flex)`
  margin-bottom: 10px;
`;

interface ITab {
  isActive: boolean;
}

const Tab = styled(Box)<ITab>`
  ${({ isActive }) => `
  width: 100%;
  padding: 23px;
  border-left: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${isActive ? "black" : "gray"};
  background: ${isActive ? "white" : "#f4f4f4"};
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
`}
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  transition: all 0.3s ease-in-out;
`;
const WrapperContent = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
`;

const ToggleArrow = styled.div`
  color: ${({ theme }) => theme.palette.primary};
  position: fixed;
  top: 30%;
  z-index: 2000;
  background: white;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

export const TableSidebar = forwardRef<HTMLDivElement, ITableSidebar>(
  (
    {
      data,
      isOpen,
      tableName,
      columnNames,
      isLoading,
      renewSidebar,
      columnVisibility,
      changeColVis,
      allColumns,
      toggleSidebar,
      FilterComponent,
      RaportDownloadComponent,
      RaportExportComponent,
      fetchRowData,
      tableType,
      openDetailsView,
      detailsObject,
      dataId,
      dataPayload,
      hideFiltersAndOptions,
    },
    ref
  ) => {
    const history = useHistory();

    const { tableData } = useTableSidebarContext();
    const { setTableData } = useTableSidebarDispatchContext();

    const [currentTab, setCurrentTab] = useState(0);
    const [menuIndex, setMenuIndex] = useState(-1);
    const [columnState, setColumnState] = useState();
    const [updateChecked, setUpdateChecked] = useState<any>(null);
    const [defaultCheckboxStates, setCheckboxStates] = useState<boolean[]>();
    const [checkboxStateLoaded, setCheckboxStateLoaded] =
      useState<boolean>(false);
    const [zapiszButtonText, setZapiszButtonText] = useState<string>("Zapisz");
    const [tableId, setTableId] = useState("");
    const [selectedData, setSelectedData] = useState<
      IDisruption | ITaskDetails | any
    >(null);
    const [isScrolling, setIsScrolling] = useState(false);

    // Ensure filter UI is populated at the right time and only once
    const [filterUpdated, setFilterUpdated] = useState<boolean>(false);
    const [columnVisibility1, setColumnVisibility1] = useState(
      new Array(50).fill(true)
    );

    const { selected } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    const params = useParams();

    const { fetchUserProfile } = useAuthDispatchContext();
    const { dictionaryUsers } = useFetchDictionaryUsers();

    const getFetchField = () => {
      switch (tableType) {
        case TableType.DISRUPTION_LIST:
          return "disruptionId";
        case TableType.TASK_LIST:
          return "taskId";
        case TableType.RAILWAYLINE_LIST:
          return "railwayLineId";
        case TableType.DATA_LIST: {
          return "date2";
        }
        default:
          return "";
      }
    };

    const loadCheckboxVisibility = (tableName: any) => {
      if (tableName) {
        // Load checkbox states for this table id
        const itemId = `table-${tableName.replace(/[\W_]+/g, "")}`;
        const vals = localStorage.getItem(itemId);

        // Todo: refactor below
        if (vals && vals.length) {
          const vis = vals.split(",");
          const ready = vis.every(
            (entry) =>
              typeof entry === "string" &&
              (entry === "true" || entry === "false")
          );
          if (ready) {
            setCheckboxStateLoaded(true);
            const checkboxes: Array<boolean> = [];
            vis.forEach((entry, index) => {
              checkboxes[index] = entry === "true" ? true : false;
            });
            setCheckboxStates(checkboxes);
            //columnVisibility = checkboxes;
            setColumnVisibility1(checkboxes);
            setTableData({
              ...tableData,
              columnVisibility: checkboxes,
              isOpen: true,
              detailsObject: true,
            });
            if (changeColVis) changeColVis(checkboxes);
          }
        }
      }
    };

    const handleScroll = () => {
      setIsScrolling(true);

      setTimeout(() => {
        setIsScrolling(false);
      }, 1500);
    };

    useEffect(() => {
      loadCheckboxVisibility(tableName ? tableName : null);
      if (renewSidebar) {
        setTimeout(() => renewSidebar(), 2000);
      } else console.warn("renewSidebar() does not exist");
    }, [tableName]);

    useEffect(() => {
      if (dataId) {
        setCurrentTab(0);
        setSelectedData(dataPayload);
        setTableData({
          ...tableData,
          isOpen: true,
          detailsObject: true,
          dataId: dataId,
          dataPayload: dataPayload,
        });
      }
    }, [dataId]);

    useEffect(() => {
      if (
        tableData &&
        tableData.tableName &&
        // Don't load checkbox state more than once
        !checkboxStateLoaded
      ) {
        // Get table id name
        setTableId(tableData.tableName);
        loadCheckboxVisibility(tableData.tableName);
      }
    }, [tableData]);

    useEffect(() => {
      if (getFetchField() === "date2") {
        setCurrentTab(0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableType]);

    useEffect(() => {
      if (getFetchField() === "date2") {
        setCurrentTab(0);
      }
      if (selected && fetchRowData) {
        setCurrentTab(0);
        fetchRowData({ [getFetchField()]: selected }).then((data) => {
          setSelectedData(data);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, fetchRowData, tableType]);

    useEffect(() => {
      if (!filterUpdated && FilterComponent) {
        setFilterUpdated(true);
        if (renewSidebar) renewSidebar();
      }
    }, [FilterComponent, params]);

    useEffect(() => {
      ColumnsComponent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateChecked]);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll, true);

      return () => {
        window.removeEventListener("scroll", handleScroll, true);
      };
    }, []);

    const onSaveColumnSettings = () => {
      if (tableId && typeof tableId == "string") {
        if (columnVisibility1 && columnVisibility1.length > 0) {
          localStorage.setItem(`table-${tableId}`, columnVisibility1.join(","));
          setZapiszButtonText("Zapisane");
          // Restore original button state
          setTimeout(() => setZapiszButtonText("Zapisz"), 1000);
        }
      }
    };

    const items = [
      {
        label: "Ustawienia",
        onClick: () => {
          setMenuIndex(0);
          setCurrentTab(3);
        },
      },
      {
        label: "Raporty",
        onClick: () => {
          setMenuIndex(1);
          setCurrentTab(3);
        },
      },
    ];

    const filterItems = () => {
      if (RaportExportComponent || RaportDownloadComponent) return items;
      return items.slice(0, 1);
    };

    const onCheckboxChange = (index: number) => {
      if (columnVisibility) {
        const copy = [...columnVisibility1];
        copy[index] = !copy[index] ? true : false;
        columnVisibility = copy;
        setColumnVisibility1(copy);
        if (changeColVis) changeColVis(copy);
      }
    };

    const ColumnsComponent = () => (
      <Box p={4}>
        <p>
          <b>Ustawienia Kolumn</b>
        </p>

        {columnNames &&
          columnNames.map((name, index) => {
            const visible =
              columnVisibility1 && columnVisibility1[index]
                ? columnVisibility1[index]
                : false;

            return name ? (
              <div key={index}>
                <input
                  type="checkbox"
                  id={"box-" + index}
                  checked={visible}
                  onChange={() => onCheckboxChange(index)}
                />{" "}
                <Label>
                  <label className="tcl" htmlFor={"box-" + index}>
                    {name}
                  </label>
                </Label>
              </div>
            ) : null;
          })}

        <Flex alignItems="center" justifyContent="flex-end">
          <ConfirmButton
            id="ZapiszButton"
            type="button"
            onClick={onSaveColumnSettings}
            bordered
          >
            {zapiszButtonText}
          </ConfirmButton>
        </Flex>
      </Box>
    );

    const Raports = () => (
      <Box p={4}>
        <p>Raporty</p>
        <Box mt={4}>
          {RaportDownloadComponent && <RaportDownloadComponent />}
        </Box>
        <Box mt={4}>{RaportExportComponent && <RaportExportComponent />}</Box>
      </Box>
    );

    const renderDetails = () => {
      if (detailsObject && currentTab === 0 && tableType === "date_list") {
        return (
          <DateDetails
            selected={tableData?.data}
            editDetails={openDetailsView}
          />
        );
      }
      if (
        detailsObject &&
        currentTab === 0 &&
        tableType === "predecessors_list"
      ) {
        return <PredecessorsList data={tableData?.data} hoverName />;
      }
      if (detailsObject && currentTab === 0) {
        if (selectedData) {
          if (tableType === "disruption_list")
            return (
              <DisruptionDetails disruption={selectedData as IDisruption} />
            );
        } else
          return (
            <>
              <Box p={5}>Niczego nie wybrano</Box>
            </>
          );
        if (tableType === "task_list")
          return (
            <TaskDetails taskId={selectedData.task} typeParam={tableName} />
          );
        if (tableType === "railway_line_list") return <></>;
      }

      return <></>;
    };

    return (
      <>
        {!isScrolling && (
          <ToggleArrow
            style={{ right: tableData?.isOpen ? "300px" : "0" }}
            onClick={toggleSidebar}
          >
            {!tableData?.isOpen ? (
              <StyledFontAwesomeIcon size="2x" icon={faLongArrowAltLeft} />
            ) : (
              <StyledFontAwesomeIcon size="2x" icon={faLongArrowAltRight} />
            )}
          </ToggleArrow>
        )}

        {/* tmp_custom-table-01-27-2022 was:
           <Transition in={!!isOpen} timeout={transitionDuration}>
           (Remove this if new code doesn't cause bugs)
        */}

        <Transition in={!!tableData?.isOpen} timeout={transitionDuration}>
          {(state) => (
            <Root ref={ref} state={state}>
              {detailsObject && (
                <StyledFlex>
                  {/* Info circle tab */}
                  <Tab
                    style={{ borderLeft: "none" }}
                    isActive={currentTab === 0}
                    onClick={() => {
                      setCurrentTab(0);
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tab>

                  {/* Comments tab */}
                  <Tab
                    isActive={currentTab === 1}
                    onClick={() => setCurrentTab(1)}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Tab>

                  {!hideFiltersAndOptions && (
                    <>
                      {/* Filters tab */}
                      <Tab
                        isActive={currentTab === 2}
                        onClick={() => {
                          setCurrentTab(2);
                          if (!filterUpdated) if (renewSidebar) renewSidebar();
                          setFilterUpdated(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </Tab>

                      {/* Column visibility tab */}
                      <Tab
                        isActive={currentTab === 3}
                        onClick={() => setCurrentTab(3)}
                      >
                        <Dropdown items={filterItems()}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Dropdown>
                      </Tab>
                    </>
                  )}
                </StyledFlex>
              )}

              {isLoading ? <Loader /> : ""}

              {!tableData?.onlyFilters && (
                <>
                  {/* Display Details */}
                  {renderDetails()}

                  {/* Display Comments */}
                  {(currentTab === 1 || !detailsObject) && tableData && (
                    <>
                      <CommentsWindow
                        users={dictionaryUsers || []}
                        fullWidth
                        fullHeight={!detailsObject}
                        showComments={tableData.showComments}
                      />
                    </>
                  )}

                  {/* Display Checkbox Column Filters */}
                  {detailsObject && (
                    <>
                      {currentTab === 2 && FilterComponent && (
                        <WrapperContent>
                          <FilterComponent />
                        </WrapperContent>
                      )}
                      {currentTab === 3 && (
                        <>
                          {menuIndex === 0 && <ColumnsComponent />}
                          {menuIndex === 1 && <Raports />}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Root>
          )}
        </Transition>
      </>
    );
  }
);
