import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import SearchInput from "components/Search/Search";
import { UserForm } from "containers/UserForm/UserForm";
import { UsersCard } from "containers/UsersCard/UsersCard";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getUsersList, IUser } from "services/user";
import { HelperPanelWrapper, Header, ContentWrapper } from "./UsersView.styled";

const UsersView = () => {
  const { setTableData } = useTableSidebarDispatchContext();
  const [users, setUsers] = useState<IUser[]>([]);
  const [chosenUser, setChosenUser] = useState<IUser>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const fetchUsersList = async () => {
    try {
      const response = await getUsersList();
      setUsers(response);
    } catch (error) {
      toast.error("Nie udało się pobrać użytkowników.");
    }
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  useEffect(() => {
    if (!searchValue) {
      fetchUsersList();
    } else {
      setUsers((prevUsers) =>
        prevUsers.filter(
          (item) =>
            item.firstname?.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.lastname?.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue]);

  useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, [setTableData]);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      {isUserFormOpen ? (
        <>
          <Flex>
            <HelperPanelWrapper>
              Dodawanie nowego użytkownika
            </HelperPanelWrapper>
            <Box mt={4} mr={6}>
              <Button
                bordered
                onClick={() => {
                  setIsUserFormOpen((prevState) => !prevState);
                  setChosenUser(null);
                }}
              >
                Wróć
              </Button>
            </Box>
          </Flex>
          <ContentWrapper>
            <UserForm
              chosenUser={chosenUser}
              setIsUserFormOpen={setIsUserFormOpen}
              fetchUsersList={fetchUsersList}
            />
          </ContentWrapper>
        </>
      ) : (
        <>
          <HelperPanelWrapper>
            <Header>Lista użytkowników</Header>
            {loggedUserRoles?.includes("ROLE_USER_CREATE") && (
              <Button
                bordered
                onClick={() => {
                  setIsUserFormOpen(true);
                  setChosenUser(null);
                }}
              >
                Dodaj
              </Button>
            )}
            <SearchInput
              placeholder="Szukaj"
              value={searchValue}
              onChange={setSearchValue}
            />
          </HelperPanelWrapper>
          {users.length > 0 ? (
            <ContentWrapper>
              <UsersCard
                users={users}
                setUsers={setUsers}
                chosenUser={chosenUser}
                setChosenUser={setChosenUser}
                setIsUserFormOpen={setIsUserFormOpen}
              />
            </ContentWrapper>
          ) : (
            <Box pl={12} pt={4}>
              brak użytkowników
            </Box>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default UsersView;
