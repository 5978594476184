export const removeItemsFromArray = (arr, ...itemsToRemove) => {
  itemsToRemove.forEach(function (item) {
    let index = arr.indexOf(item);
    while (index !== -1) {
      arr.splice(index, 1);
      index = arr.indexOf(item);
    }
  });
  return arr;
};
