import React from "react";
import styled from "styled-components";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar/Sidebar";
import { useLayoutStateContext } from "contexts/LayoutContext";
import { useTableSidebarContext } from "contexts/TableSidebarContext";

interface IPageLayout {
  pageTitle?: string;
  pageHeaderRight?: React.ReactNode;
  displayButtonsGroup?: boolean;
  pageTitleSpace?: boolean;
  displaySidebarTable?: boolean;
  isScroll?: boolean;
}
interface IRoot {
  visibleSidebar: boolean;
}
interface IWrapperChildren {
  sidebarIsOpen: boolean | undefined;
}

const Root = styled.div<IRoot>`
  ${({ visibleSidebar }) => `
    height: 100vh;
    display: flex;
    flex-direction: column;
    // navbar
    padding-top: 64px;
    // sidebar
    padding-left: ${visibleSidebar ? "260px" : "60px"};
    transition: all .3s ease-in-out;
    background-color: #f8f8fa;
  `}
`;
const WrapperChildren = styled.div<IWrapperChildren>`
  ${({ sidebarIsOpen }) => `
    /* padding: 10px; */
    width: ${sidebarIsOpen ? "calc(100% - 300px)" : "100%"} ;
    height: 100%;
    background-color: #f8f8fa;
    transition: width .3s ease-in-out;
  `}
`;

interface IContent {
  isScroll?: boolean;
}
const Content = styled.div<IContent>`
  ${({ theme, isScroll }) => `
  height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: ${isScroll ? "auto" : "hidden"};
    // padding: ${theme.space[8]}px ${theme.space[5]}px;
  `};
`;

export const PageLayout: React.FC<IPageLayout> = ({
  children,
  isScroll = false,
}) => {
  const { tableData } = useTableSidebarContext();
  const { visibleSidebar } = useLayoutStateContext();

  return (
    <Root visibleSidebar={visibleSidebar}>
      <Navbar />
      <Sidebar />
      <Content isScroll={isScroll}>
        <WrapperChildren sidebarIsOpen={tableData?.isOpen}>
          {children}
        </WrapperChildren>
      </Content>
    </Root>
  );
};
