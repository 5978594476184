import React, { useCallback, useEffect, useState } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Select } from "components/Select";
import { Textarea } from "components/Textarea";
import {
  IDisruptionPayments,
  createDisruptionPayment,
  updateDisruptionPayment,
  deleteDisruptionPayment,
  getDisruptionUnitCosts,
} from "services/disruptions";
import { schema } from "schemas/DisruptionPaymentForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { ConfirmModal } from "components/ConfirmModal";
import { useFetchDictionaryPartners } from "hooks/fetchDictionaryPartners";
import { IDisruptionUnitCost } from "types/disruption";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface IDisruptionPaymentForm {
  handleCancelForm: (val: boolean) => void;
  // handleSubmitForm: () => void;
  setData: (data: IDisruptionPayments[]) => void;
  data: IDisruptionPayments[];
  selected: IDisruptionPayments | null;
  setSelected: (selected: IDisruptionPayments) => void;
}
interface IDiscruptionPartner {
  id: string;
  name: string;
}

interface IStyledSelect {
  width: string;
}

const WrapperField = styled.div<IStyledSelect>`
  ${({ width }) => `
    width: ${width};
  `}
`;
const Error = styled.p`
  ${({ theme }) => `
    margin: 0;
    color: ${theme.palette.text.warring};
  `}
`;

const wayOfDocumentations = [
  "Wycena rynkowa (np. uśredniona wartość z 3 zgromadzonych ofert na wykonanie określonych prac)",
  "Wycena według katalogów np. SEKOCENBUD",
  "Wycena według uśrednionego, dotychczasowego kosztu (np. uśredniony koszt określonej próby OR / km OTK, lub z określonego czasu, na przykład ostatnich 12 miesięcy) - nie uwzględnia wzrostu cen!",
  "Wycena wg. umów podwykonawczych oraz ŚWPP",
];

const defaultValues = {
  id: "",
  partner: "",
  costType: "",
  costEstimation: 0,
  units: 0,
  documentationType: "",
  comment: "",
  sum: 0,
  unitCost: "",
  unitCostCategories: "",
};

export const DisruptionPaymentForm: React.FC<IDisruptionPaymentForm> = ({
  handleCancelForm,
  setData,
  data,
  selected,
  setSelected,
}) => {
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [dictionaryUnitCosts, setDictionaryUnitCosts] =
    useState<IDisruptionUnitCost[]>();

  const incomeOptions = ["OR, ORR, DOR", "OTK", "FDS", "Inne"];

  const { disruptionId } = useParams<{ disruptionId: string }>();

  const { dictionaryPartners } = useFetchDictionaryPartners();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IDisruptionPayments>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const watcher = watch();

  const getSumValue = () => watcher.costEstimation * watcher.units;

  const onSubmit = (payment: IDisruptionPayments) => {
    const objWithSum = {
      ...payment,
      disruption: disruptionId,
      sum: getSumValue(),
    };

    if (selected && selected.id) {
      const dataCopy = [...data];
      const foundIndex = dataCopy.findIndex((val) => val.id === selected.id);

      updateDisruptionPayment(objWithSum, selected.id)
        .then((res) => {
          dataCopy.splice(foundIndex, 1);
          dataCopy.splice(foundIndex, 0, {
            ...res,
            partner: dictionaryPartners?.find(
              (val) => val.id === payment.partner
            )?.name,
          });
          setData(dataCopy);
          handleCancelForm(false);
          toast.success("Płatność została zmieniona.");
        })
        .catch(() => toast.error("Nie udało się zmienić płatności."));
    } else {
      delete objWithSum.id;
      createDisruptionPayment(objWithSum).then((res) => {
        const newObject = {
          ...res,
          partner: dictionaryPartners?.find((val) => val.id === res.partner)
            ?.name,
        };

        setData([newObject, ...data]);
        handleCancelForm(false);
        toast.success("Płatność została dodana.");
      });
    }
    setSelected(null);
  };

  const handleRemovePayment = () => {
    if (!selected?.id) return;
    deleteDisruptionPayment(selected.id)
      .then(() => {
        setIsOpenRemoveModal(false);
        handleCancelForm(false);
        toast.success("Płatność została usunięta.");
      })
      .catch(() => toast.error("Nie udało się usunąć płatności."));
  };

  const handleChangeUnitCosts = (e) => {
    const estPricePln = dictionaryUnitCosts.find(
      (item) => item.id === e.target.value
    ).estimatedPricePln;

    setValue("costEstimation", estPricePln);
    setValue("units", 1);
  };

  const handleDisruptionUnitCosts = useCallback(async () => {
    const disruptionUnitCosts = await getDisruptionUnitCosts(
      watch("unitCostCategories")
    );
    setDictionaryUnitCosts(disruptionUnitCosts.filter(({ status }) => status));
  }, [watch, watch("unitCostCategories")]);

  useEffect(() => {
    if (selected) {
      const {
        partner,
        costType,
        costEstimation,
        units,
        documentationType,
        comment,
        sum,
        unitCostCategories,
        unitCost,
      } = selected;
      setTimeout(() => {
        reset({
          partner,
          costType,
          costEstimation,
          units,
          documentationType,
          comment,
          sum,
          unitCostCategories,
          unitCost,
        });
      }, 1000);
    }
  }, [selected, dictionaryPartners, reset]);

  useEffect(() => {
    handleDisruptionUnitCosts();
  }, [handleDisruptionUnitCosts]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex justifyContent="space-between">
          {selected ? <h2>Edytuj koszt</h2> : <h2>Dodaj koszt</h2>}

          <Flex>
            {selected &&
              selected.id &&
              loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_COST_DELETE"
              ) && (
                <Box mr={2}>
                  <Button
                    type="button"
                    bordered
                    onClick={() => setIsOpenRemoveModal(true)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Box>
              )}
            <Box mr={2}>
              <Button bordered onClick={() => handleCancelForm(false)}>
                anuluj
              </Button>
            </Box>
            {selected && selected.id ? (
              <Button type="submit" bordered>
                Zapisz
              </Button>
            ) : (
              <Button type="submit" bordered>
                Dodaj
              </Button>
            )}
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" gap={16}>
          <WrapperField width="32%">
            <Select label="Partner" {...register("partner")}>
              <option value="" />
              {dictionaryPartners
                ?.filter(({ status }) => status)
                .map((partner) => (
                  <option value={partner.id} key={partner.id}>
                    {partner.name}
                  </option>
                ))}
            </Select>
            <Error style={{ color: "orangered" }}>
              {errors.partner && errors.partner.message}
            </Error>
          </WrapperField>

          <WrapperField width="32%">
            <Select label="Wpływ" {...register("unitCostCategories")}>
              <option value="" />
              {incomeOptions.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
            <Error style={{ color: "orangered" }}>
              {errors.partner && errors.partner.message}
            </Error>
          </WrapperField>

          <WrapperField width="32%">
            <Select
              label="Budowa nowego OR"
              {...register("unitCost")}
              onChange={handleChangeUnitCosts}
            >
              <option value="" />
              {dictionaryUnitCosts?.length > 0 &&
                dictionaryUnitCosts?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </Select>
            <Error style={{ color: "orangered" }}>
              {errors.partner && errors.partner.message}
            </Error>
          </WrapperField>
        </Flex>

        <Flex justifyContent="space-between" alignItems="flex-start">
          <WrapperField width="32%">
            <Input
              label="Wycena według kosztów bieżących"
              {...register("costEstimation")}
              type="number"
              min={0}
              step="any"
            />
            <Error style={{ color: "orangered" }}>
              {errors.costEstimation && errors.costEstimation.message}
            </Error>
          </WrapperField>
          <WrapperField width="32%">
            <Input
              label="Liczba jednostek"
              {...register("units")}
              type="number"
            />
            <Error style={{ color: "orangered" }}>
              {errors.units && errors.units.message}
            </Error>
          </WrapperField>
          <WrapperField width="32%">
            <Input
              label="Suma kosztu"
              {...register("sum")}
              value={getSumValue()}
              disabled
            />
          </WrapperField>
        </Flex>
        <WrapperField width="100%">
          <Select
            label="Sposób dokumentacji"
            {...register("documentationType")}
          >
            <option value="" />
            {wayOfDocumentations.map((doc) => (
              <option value={doc} key={doc}>
                {doc}
              </option>
            ))}
          </Select>
          <Error style={{ color: "orangered" }}>
            {errors.documentationType && errors.documentationType.message}
          </Error>
        </WrapperField>

        <Textarea label="Komentarz" {...register("comment")} />
      </form>

      <ConfirmModal
        handleConfirm={handleRemovePayment}
        onCancelClick={() => setIsOpenRemoveModal(false)}
        isOpen={isOpenRemoveModal}
        header="Czy na pewno chcesz usuąć płatność?"
        confirmBtnText="Usuń"
      />
    </>
  );
};
