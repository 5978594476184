import React, { useContext, useState } from "react";

export interface IFilters {
  id?: string;
  comment?: string;
  search?: string;
  company?: string[];
  contractorSA?: string;
  contractorCW?: string;
  type: string;
  chainage?: number;
  iz?: string[];
  tags?: string[];
  lineNumber?: string;
  completion?: number;
  locationName?: string;
  group?: string;
  inHRF?: boolean | "";
  hasError?: boolean | "";

  chainageFrom?: number | "";
  chainageTo?: number | "";
  chainageStartFrom?: number | "";
  chainageEndTo?: number | "";
  completionPercentageFrom?: number | "";
  completionPercentageTo?: number | "";
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;

  settledPercentageFrom?: number | "";
  settledPercentageTo?: number | "";
  subtaskPraceProjektoweStartDateFrom?: string;
  subtaskPraceProjektoweStartDateTo?: string;
  subtaskPraceProjektoweEndDateFrom?: string;
  subtaskPraceProjektoweEndDateTo?: string;

  subtaskDULICPStartDateFrom?: string;
  subtaskDULICPStartDateTo?: string;
  subtaskDULICPEndDateFrom?: string;
  subtaskDULICPEndDateTo?: string;

  subtaskPnBStartDateFrom?: string;
  subtaskPnBStartDateTo?: string;
  subtaskPnBEndDateFrom?: string;
  subtaskPnBEndDateTo?: string;

  subtaskPraceBudowlaneStartDateFrom?: string;
  subtaskPraceBudowlaneStartDateTo?: string;
  subtaskPraceBudowlaneEndDateFrom?: string;
  subtaskPraceBudowlaneEndDateTo?: string;

  subtaskPradNaObiekcieStartDateFrom?: string;
  subtaskPradNaObiekcieStartDateTo?: string;
  subtaskPradNaObiekcieEndDateFrom?: string;
  subtaskPradNaObiekcieEndDateTo?: string;

  subtaskZagospodarowanieStartDateFrom?: string;
  subtaskZagospodarowanieStartDateTo?: string;
  subtaskZagospodarowanieEndDateFrom?: string;
  subtaskZagospodarowanieEndDateTo?: string;

  subtaskWdrozenieStartDateFrom?: string;
  subtaskWdrozenieStartDateTo?: string;
  subtaskWdrozenieEndDateFrom?: string;
  subtaskWdrozenieEndDateTo?: string;

  subtaskUruchomienieStartDateFrom?: string;
  subtaskUruchomienieStartDateTo?: string;
  subtaskUruchomienieEndDateFrom?: string;
  subtaskUruchomienieEndDateTo?: string;

  taskMetaActualMontazWiezyEndDateFrom?: string;
  taskMetaActualMontazWiezyEndDateTo?: string;
  taskMetaForecastMontazWiezyEndDateFrom?: string;
  taskMetaForecastMontazWiezyEndDateTo?: string;

  positionType?: string;
  active?: boolean | "";
}

export interface ITasksStateContext {
  initialFilters: IFilters;
  filters: IFilters;
}

export interface ITasksDispatchContext {
  setFilters: (filters: IFilters) => void;
  cleanFilters: () => void;
}

const TasksStateContext = React.createContext<ITasksStateContext | undefined>(
  undefined
);

const TasksDispatchContext = React.createContext<
  ITasksDispatchContext | undefined
>(undefined);

export const TasksContextProvider: React.FC = ({ children }) => {
  const initialFilters: IFilters = {
    id: "",
    search: "",
    comment: "",
    company: [],
    contractorSA: "",
    contractorCW: "",
    type: "",
    group: "",
    chainage: undefined,
    iz: [],
    active: "",
    inHRF: "",
    hasError: "",
    chainageFrom: "",
    chainageTo: "",
    chainageStartFrom: "",
    chainageEndTo: "",
    completionPercentageFrom: "",
    completionPercentageTo: "",
    lineNumber: "",
    completion: undefined,
    locationName: "",
    startDateFrom: "",
    startDateTo: "",
    endDateFrom: "",
    endDateTo: "",
    settledPercentageFrom: "",
    settledPercentageTo: "",
    subtaskPraceProjektoweStartDateFrom: "",
    subtaskPraceProjektoweStartDateTo: "",
    subtaskPraceProjektoweEndDateFrom: "",
    subtaskPraceProjektoweEndDateTo: "",
    subtaskDULICPStartDateFrom: "",
    subtaskDULICPStartDateTo: "",
    subtaskDULICPEndDateFrom: "",
    subtaskDULICPEndDateTo: "",
    subtaskPnBStartDateFrom: "",
    subtaskPnBStartDateTo: "",
    subtaskPnBEndDateFrom: "",
    subtaskPnBEndDateTo: "",
    subtaskPraceBudowlaneStartDateFrom: "",
    subtaskPraceBudowlaneStartDateTo: "",
    subtaskPraceBudowlaneEndDateFrom: "",
    subtaskPraceBudowlaneEndDateTo: "",
    subtaskPradNaObiekcieStartDateFrom: "",
    subtaskPradNaObiekcieStartDateTo: "",
    subtaskPradNaObiekcieEndDateFrom: "",
    subtaskPradNaObiekcieEndDateTo: "",
    subtaskZagospodarowanieStartDateFrom: "",
    subtaskZagospodarowanieStartDateTo: "",
    subtaskZagospodarowanieEndDateFrom: "",
    subtaskZagospodarowanieEndDateTo: "",
    subtaskWdrozenieStartDateFrom: "",
    subtaskWdrozenieStartDateTo: "",
    subtaskWdrozenieEndDateFrom: "",
    subtaskWdrozenieEndDateTo: "",
    subtaskUruchomienieStartDateFrom: "",
    subtaskUruchomienieStartDateTo: "",
    subtaskUruchomienieEndDateFrom: "",
    subtaskUruchomienieEndDateTo: "",
  };
  const [filters, setFilters] = useState<IFilters>(initialFilters);

  const cleanFilters = () => {
    setFilters(initialFilters);
  };

  return (
    <TasksStateContext.Provider value={{ filters, initialFilters }}>
      <TasksDispatchContext.Provider
        value={{
          setFilters,
          cleanFilters,
        }}
      >
        {children}
      </TasksDispatchContext.Provider>
    </TasksStateContext.Provider>
  );
};

export const useTasksStateContext = () => {
  const context = useContext(TasksStateContext);
  if (!context) {
    throw new Error(
      "useTasksStateContext must be inside a TasksStateContextProvider with a value"
    );
  }
  return context;
};

export const useTasksDispatchContext = () => {
  const context = useContext(TasksDispatchContext);
  if (!context) {
    throw new Error(
      "useTasksDispatchContext must be inside a TasksStateDispatchProvider with a value"
    );
  }
  return context;
};
