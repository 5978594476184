import * as yup from "yup";

export const schema = yup.object().shape({
  partner: yup.string().required("Partner jest wymagany"),
  unitCostCategories: yup.string().required("Wpływ kosztu jest wymagany"),
  unitCost: yup.string().required("Budowa nowego OR"),
  costEstimation: yup
    .number()
    .typeError("Wymagana poprawna wycena kosztu")
    .required("Wycena kosztu jest wymagana"),
  units: yup.number().required("Liczba jednostek jest wymagana"),
  documentationType: yup.string().required("Sposób dokumentacji jest wymagany"),
});
