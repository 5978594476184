import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { CustomTable } from "components/CustomTable";
import styled from "styled-components";
import { formatDate } from "utilities/formatDate";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";
import {
  downloadKeyGivenFilteredReport,
  fetchDownloadKeyGivenDocument,
  fetchDownloadKeyGivenLeadingDocument,
  fetchKeysGiven,
} from "services/keys";
import { downloadFile } from "utilities/downloadFile";
import { PageLayout } from "../../components/PageLayout";
import { useTableSidebarDispatchContext } from "../../contexts/TableSidebarContext";
import { TableType } from "types/tableType";
import { KeysFilter } from "./components/KeysFilter";
import {
  IFilters,
  useKeysDispatchContext,
  useKeysStateContext,
} from "contexts/KeysContext";
import { useForm } from "react-hook-form";
import { Flex } from "components/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const StyledContent = styled.div`
  width: 80px;
  padding-top: 15px;
  padding-left: 15px;
`;

const TableContainer = styled.div`
  height: 100%;
  max-width: 1380px;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => `padding: ${theme.space[2]}px;`}
`;

export const StyledReportButton = styled(Button)`
  ${({ theme }) => `
    font-size: 15px;
    padding: 10px;
    transition: all .1s ease-in-out;
    &:hover {
        background-color: ${theme.palette.primary};
        color: #fff;
    }
  `}
`;

export const KeysGivenView = () => {
  const [colVis, setColVis] = useState(Array(9).fill(true));
  const [givenKeys, setGivenKeys] = useState([]);
  const { setTableData } = useTableSidebarDispatchContext();
  const history = useHistory();

  const { filters, initialFilters } = useKeysStateContext();
  const { setFilters, cleanFilters } = useKeysDispatchContext();

  const { register, reset, handleSubmit } = useForm<IFilters>();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const handleFilteredRaportDownload = () => {
    downloadKeyGivenFilteredReport(filters).then((data) => {
      downloadFile(data.data, "report.xlsx");
    });
  };

  const retrieveKeysGiven = async (data?: IFilters) => {
    fetchKeysGiven(data || {})
      .then(({ data }) => setGivenKeys(data))
      .catch(() => toast.error("Nie udało się pobrać wydań."));
  };

  useEffect(() => {
    setTimeout(() => {
      setTableData({
        tableName: TableType.KEYS_LIST,
        columnNames: columns,
        hiddenSidebar: false,
        detailsObject: true,
        isOpen: true,
        FilterComponent: () => (
          <KeysFilter
            register={register}
            reset={reset}
            handleSubmit={handleSubmit}
          />
        ),
      });
    });
  }, []);

  useEffect(() => {
    retrieveKeysGiven(filters);

    history.push({
      pathname: history.location.pathname,
      search: "",
    });
  }, [filters]);

  useEffect(() => {
    const storage = localStorage.getItem("keys_filters");

    if (storage) {
      const parsedStorage = JSON.parse(storage);
      reset(parsedStorage);
      setFilters(parsedStorage);
    } else {
      reset(initialFilters);
      cleanFilters();
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Numer Wydania",
        width: 120,
        value: ({ givenNumber }) => (
          <StyledContent>{givenNumber}</StyledContent>
        ),
      },
      {
        name: "Wykonawca",
        width: 120,
        value: ({ contractor }) => contractor,
      },
      {
        name: "Osoba",
        width: 90,
        value: ({ recipientName }) => recipientName,
      },
      {
        name: "Data wydania",
        width: 120,
        value: ({ givenDate }) => formatDate(new Date(givenDate)),
      },
      {
        name: "Ilość sztuk",
        width: 90,
        value: ({ quantity }) => quantity,
      },
      {
        name: "Odbiór osobisty / wysyłka",
        width: 200,
        value: ({ shipment }) => (shipment ? "wysyłka" : "Odbiór osobisty"),
      },
      ...(loggedUserRoles?.includes("ROLE_KEYS_ECONOMY_UPDATE")
        ? [
            {
              name: "",
              width: 110,
              value: ({ status, keyId, givenId, givenRequestId }) =>
                status === "ready_to_given" ? (
                  <Button
                    bordered
                    onClick={() => {
                      history.push(
                        `/keys/${keyId}/publication/edit/${givenId}/${givenRequestId}`
                      );
                    }}
                  >
                    Edytuj
                  </Button>
                ) : (
                  "edycja niemożliwa"
                ),
            },
            {
              name: "",
              width: 110,
              value: ({ givenRequestId, keyId }) => (
                <Button
                  bordered
                  onClick={() => {
                    history.push(`/keys/${keyId}/return/${givenRequestId}`);
                  }}
                >
                  Zwrot kluczy
                </Button>
              ),
            },
          ]
        : []),
      {
        name: "",
        width: 200,
        value: ({ givenRequestId, givenNumber }) => (
          <StyledButton
            bordered
            onClick={() => {
              fetchDownloadKeyGivenDocument(givenRequestId)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${givenNumber}.xlsx`);
                })
                .catch(() => toast.error("Nie udało się pobrać pliku."));

              fetchDownloadKeyGivenDocument(givenRequestId, true)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${givenNumber}-kopia.xlsx`);
                })
                .catch(() => toast.error("Nie udało się pobrać kopii pliku."));

              fetchDownloadKeyGivenLeadingDocument(givenRequestId)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${givenNumber}-pismo-przewodnie.xlsx`);
                })
                .catch(() =>
                  toast.error("Nie udało się pobrać pliku przewodniego.")
                );
            }}
          >
            Pobierz dokument wydania
          </StyledButton>
        ),
      },
    ],
    [loggedUserRoles]
  );

  return (
    <PageLayout>
      <Flex justifyContent="end" p={3}>
        <StyledReportButton
          bordered
          onClick={handleFilteredRaportDownload}
          title="Eksportuj do exela"
        >
          <FontAwesomeIcon icon={faFileExcel} />
        </StyledReportButton>
      </Flex>
      <TableContainer>
        {givenKeys?.length ? (
          <CustomTable
            showLongText
            columnList={columns}
            data={givenKeys}
            colVis={colVis}
          />
        ) : (
          "Brak wydań"
        )}
      </TableContainer>
    </PageLayout>
  );
};
