import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import DisruptionPerCategoryChart from "containers/DisruptionPerCategoryChart";
import { DisruptionBasicChart } from "containers/DisruptionBasicChart";
import AllDisruptionPieChart from "containers/AllDisruptionPieChart";
import React, { FC } from "react";
import styled from "styled-components";
import DisruptionPerUserChart from "containers/DisruptionPerUserChart";
import DisruptionPerStatusChart from "containers/DisruptionPerStatusChart";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";

const VerticalGraphWrapper = styled.div`
  padding: ${({ theme }) => theme.space[6]}px;
`;
const HorizontalGraphs = styled(Flex)`
  padding: ${({ theme }) => theme.space[6]}px;
  gap: ${({ theme }) => theme.space[12]}px;
  width: 100%;
  align-items: flex-start;
  & > * {
    flex-basis: 100%;
  }
  & > :first-child {
    gap: ${({ theme }) => theme.space[12]}px;
  }
`;

const Statistics: FC = () => {
  const { setTableData } = useTableSidebarDispatchContext();

  React.useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, [setTableData]);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <AllDisruptionPieChart />
      <VerticalGraphWrapper>
        <DisruptionBasicChart />
      </VerticalGraphWrapper>
      <HorizontalGraphs>
        <Flex flexDirection="column">
          <DisruptionPerUserChart />
          <DisruptionPerStatusChart />
        </Flex>
        <DisruptionPerCategoryChart />
      </HorizontalGraphs>
    </PageLayout>
  );
};

export default Statistics;
