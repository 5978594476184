import { fetchAPI } from "utilities/fetchAPI";
import { IPagination } from "types/pagination";


interface IContextMeta {
  partnerId: string;
  userId: string;
  disruptionId: string;
}
export interface INotification {
  content: string;
  createdAt: string;
  id: string;
  level: string;
  read: boolean;
  title: string;
  user: string;
  contextMeta: IContextMeta;
}

interface IFetchNotifications {
	data: INotification[];
	pagination: IPagination;
}


export const getNotifications = () =>
  fetchAPI<IFetchNotifications>(`${process.env.REACT_APP_API_URL}/notification`);


interface IReadNotification {
  id: string;
  createdAt: string;
  user: string;
  read: boolean;
  level: string;
  title: string;
  content: string;
}
	
	export const readNotfication = (notificationUuid: string) => fetchAPI<IReadNotification>(`${process.env.REACT_APP_API_URL}/notification/${notificationUuid}/read`, {
		method: "PUT"
	});