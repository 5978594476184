import { Button } from "components/Button";
import { Card } from "components/Card";
import { PageLayout } from "components/PageLayout";
import { Table } from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import { Accessor, Column } from "react-table";
import { downloadReportById, fetchReports } from "services/reports";
import styled from "styled-components";
import {
  DisruptionCategoriesType,
  DisruptionStatusesType,
} from "types/disruption";
import { IReport } from "types/report";
import { format, parseISO } from "date-fns";
import { reportTypesDictionary } from "constants/reportsTypesDictionary";
import { downloadFile } from "utilities/downloadFile";
import { formatDate } from "utilities/formatDate";
import { useFetchDisruptionCategories } from "hooks/fetchDisruptionCategories";
import { useFetchDisruptionStatuses } from "hooks/fetchDisruptionStatuses";
import { Flex } from "../components/Flex";
import { Loader } from "../components/Loader/Loader";
import { Box } from "../components/Box";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const TableCard = styled(Card)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${theme.space[4]}px;
  `};
`;
const parseAdditionalDataDate = ({
  date,
  dateFrom,
  dateTo,
}: {
  date?: string;
  dateFrom?: string;
  dateTo?: string;
}) => {
  if (date && !dateFrom && !dateTo) {
    return formatDate(new Date(date));
  } else if (dateFrom && dateTo) {
    return `${formatDate(new Date(dateFrom))} - ${formatDate(
      new Date(dateTo)
    )}`;
  }

  return null;
};

type AdditionalDataKeys =
  | "submittingEntity"
  | "affectedEntity"
  | "category"
  | "status"
  | "startDate"
  | "endDate";

const parseAdditionalData = ({
  data,
  disruptionCategories,
  disruptionStatuses,
}: {
  data: IReport["additionalData"];
  disruptionCategories: DisruptionCategoriesType | null;
  disruptionStatuses: DisruptionStatusesType | null;
}) => {
  const keysMap = {
    submittingEntity: "Z",
    affectedEntity: "W",
    category: "K",
    status: "S",
    startDate: "R",
    endDate: "Z",
  };

  return Array.from(
    new Map(
      Object.entries({
        submittingEntity: data?.submittingEntity
          ? data.submittingEntity.join(", ")
          : null,
        affectedEntity: data?.affectedEntity
          ? data.affectedEntity.join(", ")
          : null,
        category: data?.category
          ? disruptionCategories
            ? data.category
                .map(
                  (singleCategory) =>
                    disruptionCategories.find(({ id }) => id === singleCategory)
                      ?.name
                )
                .join(", ")
            : data.category.join(", ")
          : null,
        status: data?.status
          ? disruptionStatuses
            ? data.status
                .map(
                  (singleStatus) =>
                    disruptionStatuses.find(({ id }) => id === singleStatus)
                      ?.name
                )
                .join(", ")
            : data.status.join(", ")
          : null,
        startDate: parseAdditionalDataDate({
          date: data?.startDate,
          dateFrom: data?.startDateFrom,
          dateTo: data?.startDateTo,
        }),
        endDate: parseAdditionalDataDate({
          date: data?.endDate,
          dateFrom: data?.endDateFrom,
          dateTo: data?.endDateTo,
        }),
      })
    ),
    ([key, value]) =>
      value ? `${keysMap[key as AdditionalDataKeys]}: ${value}` : null
  )
    .filter((value) => value !== null)
    .join(", ");
};

export const ReportsView: React.FC = () => {
  const [requestPending, setRequestPending] = useState<boolean>(true);
  const [reports, setReports] = useState<Array<IReport>>([]);
  const { disruptionCategories } = useFetchDisruptionCategories();
  const { disruptionStatuses } = useFetchDisruptionStatuses();
  const { setTableData } = useTableSidebarDispatchContext();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const columns: Array<Column<IReport>> = useMemo(
    () => [
      {
        Header: "Typ",
        accessor: "type",
        Cell: (item) =>
          reportTypesDictionary[item.row.original.type]
            ? reportTypesDictionary[item.row.original.type]
            : "--",
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "user",
        disableSortBy: true,
      },
      {
        Header: "Data utworzenia",
        accessor: "createdAt",
        Cell: (item) =>
          item.row?.original.createdAt
            ? format(
                new Date(parseISO(item.row.original.createdAt)),
                "dd-MM-yyyy HH:mm:ss"
              )
            : null,
        disableSortBy: true,
      },
      {
        Header: "Filtry",
        accessor: "additionalData",
        Cell: (item) => {
          // return <p>additionalData</p>;
          const { additionalData } = item.row?.original;
          return parseAdditionalData({
            data: additionalData,
            disruptionCategories,
            disruptionStatuses,
          });
        },
      },
      ...(loggedUserRoles?.includes("ROLE_DISRUPTIONS_REPORT_SHOW")
        ? [
            {
              Header: "Pobierz",
              accessor: "path" as unknown as Accessor<IReport>,
              Cell: (item) => (
                <Button
                  bordered
                  onClick={async (event) => {
                    event.preventDefault();
                    const { id } = item.row.original;
                    const data = await downloadReportById(id);
                    downloadFile(data.data, `report-${id}.xlsx`);
                  }}
                >
                  Pobierz
                </Button>
              ),
              disableSortBy: true,
            },
          ]
        : []),
    ],
    [loggedUserRoles, disruptionCategories, disruptionStatuses]
  );

  const retrieveTasks = () => {
    setRequestPending(true);
    fetchReports().then((response) => {
      setReports(response.data);
      setRequestPending(false);
    });
  };

  useEffect(() => {
    retrieveTasks();
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, []);

  return (
    <PageLayout displaySidebarTable={false}>
      <TableCard>
        {reports && reports.length > 0 && !requestPending ? (
          <Table<IReport>
            columns={columns}
            data={reports}
            getLink={(row) => `/reports`}
          />
        ) : (
          <Box mt={20}>
            {requestPending ? (
              <Loader />
            ) : (
              <Flex justifyContent="center" alignItems="center">
                Brak raportów
              </Flex>
            )}
          </Box>
        )}
      </TableCard>
    </PageLayout>
  );
};
