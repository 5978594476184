import React from "react";
import { IComment } from "services/comments";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import styled from "styled-components";

interface IParagraph {
  small?: boolean;
}

const Root = styled(Box)`
  width: 100%;
  background-color: #f9f9f9;
  margin: 0;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
`;
const Paragraph = styled.p<IParagraph>`
  ${({ small }) => `
  font-size: ${small ? "10px" : "13px"};
  line-height: 1.4;
  margin: 5px;
  `}
`;
const StyldFlex = styled(Flex)`
  width: 100%;
`;

export const Comment: React.FC<IComment> = ({ user, createdAt, content }) => {
  return (
    <Root p={1} m={2}>
      <StyldFlex alignItems="center" justifyContent="space-between">
        <Paragraph small>{user}</Paragraph>
        <Paragraph small>{createdAt}</Paragraph>
      </StyldFlex>
      {content && <Paragraph dangerouslySetInnerHTML={{ __html: content }} />}
    </Root>
  );
};
