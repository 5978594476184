import { useMemo } from "react";
import { envProjectName, envProjectType } from "envData";
import { useFetchLoggedUserRoles } from "./fetchLoggedUserRoles";
import { rolesByImports } from "constants/rolesByImports";
import { rolesByExports } from "constants/rolesByExports";

type INaviType = "object" | "disruption" | "adminPanel" | "keys";

export interface INavigationData {
  heading: string;
  type: INaviType;
  navigation: Array<{ to: string; name: string }>;
}
interface IActiveNavigation {
  type: INaviType;
  active: boolean;
}

export const useSidebarConfig = () => {
  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const navigationData = useMemo<INavigationData[]>(
    () =>
      envProjectName === "herkules" && envProjectType === "production"
        ? [
            {
              heading: "Obiekty",
              type: "object",
              navigation: [
                ...(loggedUserRoles?.includes("ROLE_LINES_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/railway-lines",
                        name: "Linie kolejowe",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_MILESTONE_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/milestone",
                        name: "Kamienie milowe",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_OR_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/OR,ORR",
                        name: "OR/ORR",
                      },
                    ]
                  : []),
              ],
            },
            ...(loggedUserRoles?.includes("ROLE_KEYS_ECONOMY_LIST")
              ? [
                  {
                    heading: "Klucze",
                    type: "keys" as INaviType,
                    navigation: [
                      {
                        to: "/keys",
                        name: "Lista kluczy",
                      },
                      {
                        to: "/keys-given",
                        name: "Wydania",
                      },
                      {
                        to: "/keys-returns",
                        name: "Zwroty",
                      },
                    ],
                  },
                ]
              : []),
            {
              heading: "Zakłócenia",
              type: "disruption",
              navigation: [
                ...(loggedUserRoles?.includes("ROLE_DISRUPTIONS_BACKLOG_LIST")
                  ? [
                      {
                        to: "/backlog",
                        name: "Backlog",
                      },
                    ]
                  : []),
              ],
            },
          ]
        : [
            {
              heading: "Obiekty",
              type: "object",
              navigation: [
                ...(loggedUserRoles?.includes("ROLE_OBJECTS_DASHBOARD_LIST")
                  ? [
                      {
                        to: "/dashboard",
                        name: "DASHBOARD",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_LINES_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/railway-lines",
                        name: "Linie kolejowe",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_MILESTONE_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/milestone",
                        name: "Kamienie milowe",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_OR_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/OR,ORR",
                        name: "OR/ORR",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_TUNNELS_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/Tunel",
                        name: "Tunele",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_FDS_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/FDS",
                        name: "FDS",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_OTK_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/OTK",
                        name: "OTK",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_OSZ_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/OSZ",
                        name: "OSZ",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_OC_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/OC",
                        name: "OC",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_READY_TO_WE_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/ReadyToWE",
                        name: "Gotowość do certyfikacji WE",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes(
                  "ROLE_CERTIFICATION_WE_OVERALL_LIST"
                )
                  ? [
                      {
                        to: "/objects/CertyfikacjaWE",
                        name: "Certyfikacje WE",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes(
                  "ROLE_CERTIFICATION_UTK_OVERALL_LIST"
                )
                  ? [
                      {
                        to: "/objects/Certyfikacja",
                        name: "Dopuszczenie UTK",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_OTHER_TASK_OVERALL_LIST")
                  ? [
                      {
                        to: "/objects/OtherTask",
                        name: "Inne",
                      },
                    ]
                  : []),
              ],
            },
            {
              heading: "Zakłócenia",
              type: "disruption",
              navigation: [
                ...(loggedUserRoles?.includes(
                  "ROLE_DISRUPTIONS_LIST_OVERALL_LIST"
                )
                  ? [
                      {
                        to: "/objects/disruptions",
                        name: "Lista zakłóceń",
                      },
                    ]
                  : []),
                {
                  to: "/statystyki",
                  name: "Statystyki",
                },
                ...(loggedUserRoles?.includes(
                  "ROLE_DISRUPTIONS_OVERHEAD_COSTS_LIST"
                )
                  ? [
                      {
                        to: "/generalCost",
                        name: "Koszta ogólne",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_DISRUPTIONS_REPORTS_LIST")
                  ? [
                      {
                        to: "/reports",
                        name: "Raporty",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_DISRUPTIONS_ACTIVITY_SHOW")
                  ? [
                      {
                        to: "/userActivity",
                        name: "Twoja aktywność",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_DISRUPTIONS_BACKLOG_LIST")
                  ? [
                      {
                        to: "/backlog",
                        name: "Backlog",
                      },
                    ]
                  : []),
              ],
            },
          ],
    [loggedUserRoles]
  );

  const keysNavigationData = useMemo<INavigationData[]>(
    () => [
      ...(loggedUserRoles?.includes("ROLE_KEYS_ECONOMY_LIST")
        ? [
            {
              heading: "Klucze",
              type: "keys" as INaviType,
              navigation: [
                {
                  to: "/keys",
                  name: "Lista kluczy",
                },
                {
                  to: "/keys-given",
                  name: "Wydania",
                },
                {
                  to: "/keys-returns",
                  name: "Zwroty",
                },
              ],
            },
          ]
        : []),
    ],
    [loggedUserRoles]
  );

  const adminNavigationData = useMemo<INavigationData[]>(
    () => [
      ...(loggedUserRoles?.includes("ROLE_ADMINISTRATION_SHOW") ||
      loggedUserRoles?.includes("ROLE_ADMINISTRATION_LIST") ||
      loggedUserRoles?.includes("ROLE_USER_GROUPS_LIST") ||
      loggedUserRoles?.includes("ROLE_USERS_LIST")
        ? [
            {
              heading: "Administracja",
              type: "adminPanel" as INaviType,
              navigation: [
                ...(loggedUserRoles?.includes("ROLE_ADMINISTRATION_LIST")
                  ? [
                      {
                        to: "/admin/tags",
                        name: "TAGI",
                      },
                    ]
                  : []),
                {
                  to: "/admin/partners",
                  name: "PARTNERZY",
                },
                ...(loggedUserRoles?.includes("ROLE_USERS_LIST")
                  ? [
                      {
                        to: "/admin/users",
                        name: "UŻYTKOWNICY",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_USER_GROUPS_LIST")
                  ? [
                      {
                        to: "/admin/permissions",
                        name: "UPRAWNIENIA",
                      },
                    ]
                  : []),
                ...(loggedUserRoles?.includes("ROLE_ADMINISTRATION_LIST")
                  ? [
                      {
                        to: "/admin/dictionary",
                        name: "SŁOWNIKI",
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
    ],
    [loggedUserRoles]
  );

  const importExportNavigationData = useMemo<INavigationData[]>(
    () =>
      envProjectName === "herkules" && envProjectType === "production"
        ? [
            ...(loggedUserRoles?.find((role) => role.includes("ROLE_IMPORTS"))
              ? [
                  {
                    heading: "IMPORT",
                    type: "object" as INaviType,
                    navigation: [
                      ...(loggedUserRoles?.includes(
                        rolesByImports["OR,ORR,DOR-SABP"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/OR,ORR,DOR-SABP",
                              name: "OR/ORR/DOR - SABP",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["OR,ORR,DOR-CW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/OR,ORR,DOR-CW",
                              name: "OR/ORR/DOR - CW",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["MRW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/MRW",
                              name: "MRW",
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : []),
            ...(loggedUserRoles?.find((role) => role.includes("ROLE_EXPORTS"))
              ? [
                  {
                    heading: "EXPORT",
                    type: "disruption" as INaviType,
                    navigation: [
                      ...(loggedUserRoles?.includes(
                        rolesByExports["OR/ORR/DOR - SABP"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/OR,ORR,DOR-SABP",
                              name: "OR/ORR/DOR - SABP",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["OR,ORR,DOR-CW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/OR,ORR,DOR-CW",
                              name: "OR/ORR/DOR - CW",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["customer_report"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/customer_report",
                              name: "CUSTOMER",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["HRF"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/HRF",
                              name: "HRF",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["MRW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/MRW",
                              name: "MRW - KO",
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : []),
          ]
        : [
            ...(loggedUserRoles?.find((role) => role.includes("ROLE_IMPORTS"))
              ? [
                  {
                    heading: "IMPORT",
                    type: "object" as INaviType,
                    navigation: [
                      ...(loggedUserRoles?.includes(
                        rolesByImports["OR,ORR,DOR-SABP"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/OR,ORR,DOR-SABP",
                              name: "OR/ORR/DOR - SABP",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["OR,ORR,DOR-CW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/OR,ORR,DOR-CW",
                              name: "OR/ORR/DOR - CW",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["FDS"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/FDS",
                              name: "FDS",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["OTK-Fonon"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/OTK-Fonon",
                              name: "OTK - FONON",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["OTK-SPC-2"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/OTK-SPC-2",
                              name: "OTK-SPC-2",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["MRW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/MRW",
                              name: "MRW",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["PŚP"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/PŚP",
                              name: "PŚP",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["objects"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/objects",
                              name: "Obiekty",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["task_areas"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/task_areas",
                              name: "Obszary",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByImports["payment_date"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/import/payment_date",
                              name: "Daty płatności",
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : []),
            ...(loggedUserRoles?.find((role) => role.includes("ROLE_EXPORTS"))
              ? [
                  {
                    heading: "EXPORT",
                    type: "disruption" as INaviType,
                    navigation: [
                      ...(loggedUserRoles?.includes(
                        rolesByExports["OR,ORR,DOR-SABP"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/OR,ORR,DOR-SABP",
                              name: "OR/ORR/DOR - SABP",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["OR,ORR,DOR-CW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/OR,ORR,DOR-CW",
                              name: "OR/ORR/DOR - CW",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["customer_report"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/customer_report",
                              name: "CUSTOMER",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["FDS"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/FDS",
                              name: "FDS",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["OTK-Fonon"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/OTK-Fonon",
                              name: "OTK - FONON",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["OTK-SPC-2"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/OTK-SPC-2",
                              name: "OTK-SPC-2",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["HRF"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/HRF",
                              name: "HRF",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["MRW"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/MRW",
                              name: "MRW - KO",
                            },
                          ]
                        : []),
                      ...(loggedUserRoles?.includes(
                        rolesByExports["payment_schedule"]["LIST"]
                      )
                        ? [
                            {
                              to: "/import-export-panel/export/payment_schedule",
                              name: "Harmonogram Płatności",
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : []),
          ],
    [loggedUserRoles]
  );

  const defaultValue = useMemo<IActiveNavigation[]>(
    () => [
      {
        type: "object",
        active: false,
      },
      {
        type: "disruption",
        active: false,
      },
      {
        type: "keys",
        active: false,
      },
      {
        type: "adminPanel",
        active: false,
      },
    ],
    []
  );

  return {
    navigationData,
    keysNavigationData,
    adminNavigationData,
    importExportNavigationData,
    defaultValue,
  };
};
