/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomTable } from "components/CustomTable";

import { fetchDisruptions, fetchDisruption } from "services/disruptions";

import styled from "styled-components";
import { IDisruption } from "types/disruption";
import { Box } from "components/Box";
import { Label } from "components/Select";
import { Input } from "components/Input";
import { DISRUPTION_PARTNERS_URL } from "constants/path";
import { useFetchAPI } from "hooks/fetchAPI";
import { useForm, Controller } from "react-hook-form";
import MultiSelect, { components } from "react-select";
import qs from "qs";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "utilities/formatDate";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { dictionaryCategories } from "constants/dictionaryCategories";
import {
  useDisruptionsDispatchContext,
  useDisruptionsStateContext,
  IFilters,
} from "contexts/DisruptionsContext";
import { useFetchDisruptionCategories } from "hooks/fetchDisruptionCategories";
import { useFetchDisruptionStatuses } from "hooks/fetchDisruptionStatuses";
import { useFetchDictionaryIzs } from "hooks/fetchDictionaryIzs";
import { useFetchDictionaryTags } from "hooks/fetchDictionaryTags";
import { useFetchDictionaryLines } from "hooks/fetchDictionaryLines";
import { useFetchDictionaryUsers } from "hooks/fetchDictionaryUsers";
import { TableType } from "types/tableType";
import { Loader } from "../components/Loader/Loader";
import {
  useTableSidebarContext,
  useTableSidebarDispatchContext,
} from "contexts/TableSidebarContext";
import { ProblemTag } from "components/ProblemTag";
import { ReportsDownload } from "components/ReportsDownload/ReportsDownload";
import { DatePickerHeader } from "../components/DatePickerHeader";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { usefetchDictionaryProblemTypes } from "hooks/fetchDictionaryProblemTypes";
import { TooltipWrapper } from "components/TooltipWrapper";

const Filters = styled.div<{ itemsInRowNumber: number }>`
  ${({ theme, itemsInRowNumber }) => `
    display: grid;
    grid-template-columns: repeat(${itemsInRowNumber}, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[2]}px;
    &:not(:last-child) {
      margin-bottom: ${theme.space[2]}px;
    }
  `};
`;

const StyledButton = styled(Button)`
  ${({ theme }) => `
    font-size: 15px;
    padding: 10px;
    transition: all .1s ease-in-out;
    &:hover {
      background-color: ${theme.palette.primary};
      color: #fff;
    }
  `}
`;
const StyledMultiSelect = styled(MultiSelect)`
  max-width: 100%;
`;

const Link = styled.a`
  text-align: center;
`;

const WrapperFixedButtons = styled(Box)`
  position: fixed;
  z-index: 2;
  width: 256px;
  background-color: #fff;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 95%;
`;

interface IDiscruptionPartner {
  id: string;
  name: string;
  status: boolean;
}

const Option = (props: any) => {
  return (
    <TooltipWrapper content={dictionaryCategories[props.label]} left={50}>
      <components.Option {...props} />
    </TooltipWrapper>
  );
};

const tableCellDefinition = [
  { width: 110, name: "Nr zakłócenia", idName: "number", value: (e: any) => 1 },
  {
    width: 120,
    name: "Zgłaszający",
    idName: "submitting",
    value: (e: any) => 2,
  },
  {
    width: 240,
    name: "Przypisany",
    idName: "assigned_user",
    value: (e: any) => 3,
  },
  { width: 100, name: "Tagi", idName: "tags", value: (e: any) => 4 },
  {
    width: 200,
    name: "Możliwy wpływ na partnera",
    idName: "affected_entities",
    value: (e: any) => 5,
  },
  {
    width: 150,
    name: "Pow. o roszczeniu",
    idName: "submission_date",
    value: (e: any) => 6,
  },
  {
    width: 150,
    name: "Liczba aktualizacji",
    value: (e: any) => 7,
  },
  {
    width: 130,
    name: "Nr. linii",
    idName: "line_number",
    value: (e: any) => 8,
  },
  {
    width: 100,
    name: "Numer pisma",
    idName: "start_document",
    value: (e: any) => 9,
  },
  {
    width: 130,
    name: "Kategoryzacja",
    idName: "category",
    value: (e: any) => 10,
  },
  {
    width: 100,
    name: "Status",
    idName: "status",
    value: (e: any) => 11,
  },
  {
    width: 100,
    name: "Rozpoczęcie",
    idName: "start_date",
    value: (e: any) => 12,
  },
  {
    width: 100,
    name: "Zakończenie",
    idName: "end_date",
    value: (e: any) => 13,
  },
  { width: 100, name: "Błąd", value: (e: any) => 14 },
  { width: 100, name: "Opis", idName: "description", value: (e) => 14 },
];

const optionArray = [{ label: "", value: "" }];

export const DisruptionsView = () => {
  const [requestPending, setRequestPending] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | undefined>();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | undefined>("asc");
  const [disruptions, setDisruptions] = useState<Array<IDisruption>>();
  const [optionsPartners, setOptionsPartners] = useState(optionArray);
  const [optionsCategories, setOptionsCategories] = useState(optionArray);
  const [optionsStatuses, setOptionsStatuses] = useState(optionArray);
  const [usersOptions, setUsersOptions] = useState(optionArray);
  const [optionsIzs, setOptionsIzs] = useState(optionArray);
  const [optionsTags, setOptionsTags] =
    useState<{ label: string; value: string }[]>(null);
  const [optionsLines, setOptionsLines] =
    useState<{ label: number; value: number }[]>();
  const [optionsProblemType, setOptionsProblemType] =
    useState<{ label: string; value: string }[]>();

  const [totalFilteredCount, setTotalFilteredCount] = useState(0);
  const [tableOffset, setTableOffset] = useState(0);

  const [tableColumnDataLoaded, setTableColumnDataLoaded] = useState(false);

  const history = useHistory();

  const { selected } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();

  const { filters, initialFilters } = useDisruptionsStateContext();
  const { setFilters, cleanFilters } = useDisruptionsDispatchContext();

  const { register, control, watch, reset, handleSubmit } = useForm<IFilters>({
    defaultValues: filters,
    mode: "onTouched",
  });

  const { data: discruptionPartners } = useFetchAPI<IDiscruptionPartner[]>(
    DISRUPTION_PARTNERS_URL
  );
  const { dictionaryUsers } = useFetchDictionaryUsers();
  const { disruptionCategories } = useFetchDisruptionCategories();
  const { disruptionStatuses } = useFetchDisruptionStatuses();
  const { dictionaryIzs } = useFetchDictionaryIzs();
  const { dictionaryTags } = useFetchDictionaryTags();
  const { dictionaryLines } = useFetchDictionaryLines();
  const { dictionaryProblemTypes } = usefetchDictionaryProblemTypes();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  // Table state
  const [columnList, setColumnList] = useState(tableCellDefinition);
  const [tablePageOffset, setTablePageOffset] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(20);

  // Pagination state
  const [tableTotalPages, setTableTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [maxSegmentNumber, setMaxSegmentNumber] = useState(0);
  const [currentPageSegment, setCurrentPageSegment] = useState(0);
  const [selectedRow, setSelectedRow] = useState("");

  // Column state
  const [colVis, setColVis] = useState(Array(15).fill(true));
  const [colNames, setColNames] = useState(0);
  const [columnNames, setColumnNames] = useState<Array<string>>([""]);

  // Infinite scroll
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  useEffect(() => {
    if (sortBy) console.warn("sortBy = ", sortBy);
    if (sortOrder) console.warn("sortOrder = ", sortOrder);
  }, [sortBy, sortOrder]);

  const updateTableData = () => {
    setTableData({
      tableName: "disruptions",
      tableType: TableType.DISRUPTION_LIST,
      columnVisibility: colVis,
      changeColVis: (vis: Array<boolean>) => {
        setColVis(vis);
      },
      renewSidebar: () => {
        setColNames(Math.random());
      },
      columnNames: columnNames,
      hiddenSidebar: false,
      detailsObject: true, // display sidebar tabs
      isOpen: true,
      FilterComponent: DisruptionsFilter,
    });
  };

  const None = () => <div className="faded">–</div>;
  //const Info = () => <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
  const Info = () => <div>i</div>;

  // Displaying table cell values requires additional objects to be loaded first
  useEffect(() => {
    const ready = [
      discruptionPartners,
      disruptionCategories,
      disruptionStatuses,
      dictionaryIzs,
      dictionaryTags,
      dictionaryLines,
      dictionaryProblemTypes,
    ].every((entry) => entry != null);

    if (ready && !tableColumnDataLoaded) {
      setTableColumnDataLoaded(true);

      setColumnList([
        {
          width: 110,
          name: "Nr zakłócenia",
          value: (e: any) => (
            <Link
              style={{
                color: "gray",
                display: "inline-block",
                minWidth: 70,
                lineHeight: "57px",
                textDecoration: "none",
              }}
              href={`/objects/disruptions/${e.id}`}
            >
              {e.number}
            </Link>
          ),
        },
        {
          width: 100,
          name: "Zgłaszający",
          value: (e: any) => {
            if (discruptionPartners) {
              const foundPartner = discruptionPartners.find(
                (partner) => partner.id === e.submittingEntity
              );
              return <>{foundPartner && foundPartner.name}</>;
            } else return <None />;
          },
        },
        {
          width: 200,
          name: "Przypisany",
          value: (e: any) => {
            return e.assignedUser ? e.assignedUser : <None />;
          },
        },
        {
          width: 150,
          name: "Tagi",
          value: (e: any) => {
            if (e.tags && e.tags.length > 0) {
              return (
                <>
                  <TooltipWrapper
                    content={e.tags.map((T: any) => T.name).join(", ")}
                    left={0}
                    width={200}
                  >
                    {e.tags.map((T: any) => T.name).join(", ")}
                  </TooltipWrapper>
                </>
              );
            } else return <None />;
          },
        },
        {
          width: 200,
          name: "Możliwy wpływ na partnera",
          value: (e: any) => {
            return e.affectedEntities &&
              e.affectedEntities.length &&
              e.affectedEntities.length > 0 ? (
              e.affectedEntities.map((T: any) => T.name).join(", ")
            ) : (
              <None />
            );
          },
        },
        {
          width: 150,
          name: "Pow. o roszczeniu",
          value: (e: any) =>
            e.submissionDate ? formatDate(new Date(e.submissionDate)) : "--",
        },
        {
          width: 150,
          name: "Liczba aktualizacji",
          value: (e: any) => {
            return e.updates.length;
          },
        },
        {
          width: 130,
          name: "Nr. linii",
          value: (e: any) => {
            return e.lines && e.lines.length && e.lines.length > 0 ? (
              e.lines.join(", ")
            ) : (
              <None />
            );
          },
        },
        {
          width: 130,
          name: "Numer pisma",
          value: (e: any) => {
            return e.startDocument;
          },
        },
        {
          width: 130,
          name: "Kategoryzacja",
          value: (e: any) => {
            return `${
              disruptionCategories?.find(
                (category) => category.id === e.category
              )?.name ?? e?.category
            }`;
          },
        },
        {
          width: 170,
          name: "Status",
          value: (e: any) => {
            if (disruptionStatuses) {
              const value = disruptionStatuses.filter((f) => f.id === e.status);
              const retval = disruptionStatuses ? (
                `${value[0] ? value[0].name : <None />}`
              ) : (
                <None />
              );
              return retval;
            } else return <None />;
          },
        },
        {
          width: 120,
          name: "Rozpoczęcie",
          value: (e: any) =>
            e.startDate ? formatDate(new Date(e.startDate)) : "--",
        },
        {
          width: 120,
          name: "Zakończenie",
          value: (e: any) =>
            e.endDate ? formatDate(new Date(e.endDate)) : "--",
        },
        {
          width: 140,
          name: "Błąd",
          value: (e) => {
            if (e.problems.length) {
              return e.problems.map((item) => (
                <ProblemTag key={item.name} severity={item.severity}>
                  !
                </ProblemTag>
              ));
            } else return <None />;
          },
        },
        {
          width: 140,
          name: "Opis",
          value: (e) => (
            <TooltipWrapper content={e.description} left={0} width={215}>
              {e.description}
            </TooltipWrapper>
          ),
        },
      ]);
    }
  }, [
    discruptionPartners,
    disruptionCategories,
    disruptionStatuses,
    dictionaryIzs,
    dictionaryTags,
    dictionaryLines,
    dictionaryProblemTypes,
  ]);

  useEffect(() => {
    if (discruptionPartners) {
      setOptionsPartners(
        discruptionPartners
          .filter(({ status }) => status)
          .map((partner) => ({
            value: partner.id,
            label: partner.name,
          }))
      );
    }
  }, [discruptionPartners]);

  useLayoutEffect(() => {
    if (dictionaryUsers) {
      const arr: any = [];
      // console.log('dictionaryUsers=', dictionaryUsers)
      dictionaryUsers &&
        dictionaryUsers.forEach((item) =>
          arr.push({ value: item.email, label: item.email })
        );
      setUsersOptions(arr);
    }
  }, [dictionaryUsers]);

  useEffect(() => {
    if (disruptionCategories) {
      const arr: any = [];
      disruptionCategories &&
        disruptionCategories.forEach(({ id, name }) =>
          arr.push({ value: id, label: name })
        );
      setOptionsCategories(arr);
    }
  }, [disruptionCategories]);

  useEffect(() => {
    if (disruptionStatuses) {
      const arr: Array<{ label: string; value: string }> = [];
      disruptionStatuses &&
        disruptionStatuses.forEach(({ id, name }) =>
          arr.push({
            value: id,
            label: name,
          })
        );
      setOptionsStatuses(arr);
    }
  }, [disruptionStatuses]);

  useEffect(() => {
    if (dictionaryIzs) {
      const arr: any = [];
      dictionaryIzs
        .filter(({ status }) => status)
        .forEach(({ id, name }) =>
          arr.push({
            value: id,
            label: name,
          })
        );
      setOptionsIzs(arr);
    }
  }, [dictionaryIzs]);

  useEffect(() => {
    if (dictionaryTags) {
      const arr: any = [];
      dictionaryTags
        .filter((val) => val.active === true)
        .forEach(({ id, name }) =>
          arr.push({
            value: id,
            label: name,
          })
        );
      setOptionsTags(arr);
    }
  }, [dictionaryTags]);

  const updateOptionsLines = () => {
    if (dictionaryLines) {
      const arr: { value: number; label: number }[] = [];
      let key: keyof typeof dictionaryLines;
      for (key in dictionaryLines) {
        arr.push({
          value: dictionaryLines[key],
          label: dictionaryLines[key],
        });
      }
      setOptionsLines(arr);
    }
  };

  useEffect(() => {
    updateOptionsLines();
  }, [dictionaryLines]);

  const updateOptionsProblemTypes = () => {
    if (dictionaryProblemTypes) {
      const arr: { value: string; label: string }[] = [];
      let key: keyof typeof dictionaryProblemTypes;
      for (key in dictionaryProblemTypes) {
        arr.push({
          value: key,
          label: dictionaryProblemTypes[key],
        });
      }
      setOptionsProblemType(arr);
    }
  };

  useEffect(() => {
    updateOptionsProblemTypes();
  }, [dictionaryProblemTypes]);

  useEffect(() => {
    const storage = localStorage.getItem("disruptions_filters");

    if (storage) {
      const parsedStorage = JSON.parse(storage);
      reset(parsedStorage);
      retrieveDisruptions(parsedStorage);
    } else {
      reset(initialFilters);
      cleanFilters();
      retrieveDisruptions();
    }

    history.push({
      pathname: history.location.pathname,
      search: "",
    });
  }, []);

  useEffect(() => {
    setTableData({
      hiddenSidebar: false,
      detailsObject: true,
      tableType: TableType.DISRUPTION_LIST,
      isOpen: true,
      fetchRowData: fetchDisruption,
      FilterComponent: DisruptionsFilter,
    });
  }, [selected]);

  useEffect(() => {
    setTableData({
      hiddenSidebar: true,
      detailsObject: false,
      isOpen: false,
      tableType: TableType.DISRUPTION_LIST,
      /*onlyFilters: false,*/
      FilterComponent: DisruptionsFilter,
    });
  }, []);

  useLayoutEffect(() => {
    loadMoreData();
  }, [tableOffset]);

  useLayoutEffect(() => {
    if (isLoadingNext) {
      const nextPageNumber = pageNumber + 1;
      setTablePageOffset(nextPageNumber * tablePageSize);
      setTableOffset(nextPageNumber * tablePageSize);
      setPageNumber(nextPageNumber);
    }
  }, [isLoadingNext]);

  const watcher = watch();
  const {
    submittingEntity,
    affectedEntity,
    category,
    status,
    iz,
    tags,
    startDateFrom,
    startDateTo,
    endDateFrom,
    endDateTo,
    submissionDateFrom,
    submissionDateTo,
    endDocumentSubmissionDate,
    continuationSearch,
    assignedUser,
    problems,
    problemType,
    scopeSearch,
    code,
    lineNumber,
  } = watcher;

  // ** Will reload filter/lines/tags in sidebar
  useEffect(() => {
    updateTableData();
  }, [
    colNames,
    columnNames,
    discruptionPartners,
    disruptionCategories,
    disruptionStatuses,
    dictionaryIzs,
    optionsTags,
    dictionaryLines,
    dictionaryProblemTypes,
  ]);

  const generateNewFilters = (data: IFilters) => {
    setFilters(data);
    setTableOffset(0);
    setPageNumber(0);
    retrieveDisruptions(data);
    localStorage.setItem("disruptions_filters", JSON.stringify(data));
  };

  useLayoutEffect(() => {
    if (totalFilteredCount && tableTotalPages) {
      const total = totalFilteredCount;
      const fits = totalFilteredCount % tableTotalPages;
      const segs = Math.ceil(tableTotalPages / tablePageSize);
      const extra = fits != 0 ? 1 : 0;
      const pages = totalFilteredCount / tableTotalPages + extra;
      const segments = pages / tableTotalPages;
      setMaxSegmentNumber(segs);
    }
  }, [totalFilteredCount, tableTotalPages]);

  // Load first page of the table
  const retrieveDisruptions = async (data?: IFilters) => {
    setRequestPending(true);

    const response = await fetchDisruptions({
      ...data,
      sortBy,
      sortOrder,
      limit: tablePageSize,
      offset: tablePageOffset,
    });

    setDisruptions(response.data);

    if (response) {
      if (response.pagination) {
        setTotalFilteredCount(response.pagination.countFiltered);
        setTableTotalPages(
          Math.ceil(response.pagination.countFiltered / tablePageSize)
        );
      } else
        console.warn("response.pagination == undefined", response.pagination);
    }

    const colnames: Array<any> = []; // Create list of column names
    tableCellDefinition.map((entry) => colnames.push(entry.name));

    colnames.push("Zakresy", "Koszty");

    setColumnNames(colnames);

    /* This seems to not do anything:
    // If checkbox state is available in localStorange, apply it to column visibility
    const cb: Array<boolean> = [];
    const vals = localStorage.getItem(`table-disruptions`);
    if (vals && vals.length) {
      const vis = vals.split(",");
      vis.every((entry, index) =>
        entry === "true" ?
          cb[index] = false :
          cb[index] = false
      )
      setColVis(cb);
    }*/

    setRequestPending(false);
  };

  // Load more data into the table
  const loadMoreData = async () => {
    const response = await fetchDisruptions({
      submittingEntity,
      affectedEntity,
      category,
      status,
      iz,
      tags,
      startDateFrom,
      startDateTo,
      endDateFrom,
      endDateTo,
      submissionDateTo,
      submissionDateFrom,
      endDocumentSubmissionDate,
      continuationSearch,
      scopeSearch,
      assignedUser,
      problems,
      problemType,
      sortBy,
      sortOrder,
      limit: tablePageSize,
      offset: tableOffset,
      code,
      lineNumber,
    });

    const copy = disruptions;

    // After this "disruptions"
    // will contain updated list
    if (disruptions && response.data)
      Array.prototype.push.apply(disruptions, response.data);

    if (response && response.pagination)
      setTotalFilteredCount(response.pagination.countFiltered);
    else console.warn("response.pagination == undefined", response.pagination);

    setIsLoadingNext(false);

    if (response.pagination)
      setTotalFilteredCount(response.pagination.countFiltered);
  };

  const handleAddDisruption = () => {
    history.push(`/objects/disruptions/add`);
  };

  const cancelFilters = () => {
    retrieveDisruptions();
    reset(initialFilters);
    cleanFilters();
  };

  const DisruptionsFilter = () => (
    <Card style={{ marginBottom: "1rem" }}>
      <Box px={4} pb={3}>
        <form onSubmit={handleSubmit(generateNewFilters)}>
          <WrapperFixedButtons mb={5} py={2}>
            <Flex justifyContent="start">
              <Button type="submit" bordered>
                Filtruj
              </Button>
              <Box ml={2}>
                <Button onClick={() => cancelFilters()}>Wyczyść</Button>
              </Box>
            </Flex>
          </WrapperFixedButtons>
          <Filters itemsInRowNumber={1}>
            <Box pt={12}>
              <Label>Nr zakłócenia</Label>
              <Input {...register("code")} placeholder="Szukaj" />
            </Box>
            <Box>
              <Label>Zakres</Label>
              <Input placeholder="Szukaj" {...register("scopeSearch")} />
            </Box>
            {optionsPartners && (
              <Box>
                <Label>Zgłaszający</Label>
                <Controller
                  control={control}
                  defaultValue={optionsPartners.map((c) => c.value)}
                  name="submittingEntity"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      isFocusInMenuList={false}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsPartners.filter((c) =>
                        value.includes(c.value)
                      )}
                      // @ts-ignore
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={optionsPartners}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}
            {optionsPartners && (
              <Box>
                <Label>Wpływ na partnera</Label>
                <Controller
                  control={control}
                  defaultValue={optionsPartners.map((c) => c.value)}
                  name="affectedEntity"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsPartners.filter((c) =>
                        value.includes(c.label)
                      )}
                      // @ts-ignore
                      onChange={(val) => onChange(val.map((c) => c.label))}
                      options={optionsPartners}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}
            {usersOptions && (
              <Box>
                <Label>Przypisany</Label>
                <Controller
                  control={control}
                  defaultValue={usersOptions.map((c) => c.value)}
                  name="assignedUser"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={usersOptions.filter((c) =>
                        value.includes(c.value)
                      )}
                      // @ts-ignore
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={usersOptions}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}
            {optionsCategories && (
              <Box>
                <Label>Kategoria</Label>
                <Controller
                  control={control}
                  defaultValue={optionsCategories.map((c) => c.value)}
                  name="category"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsCategories.filter((c) =>
                        value.includes(c.value)
                      )}
                      // @ts-ignore
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={optionsCategories}
                      isMulti
                      components={{ Option }}
                    />
                  )}
                />
              </Box>
            )}

            {!optionsStatuses ? (
              <span style={{ color: "orangered" }}>
                optionsStatuses not available
              </span>
            ) : (
              ""
            )}

            {optionsStatuses && (
              <Box>
                <Label>Status</Label>
                <Controller
                  control={control}
                  defaultValue={optionsStatuses.map((c) => c.value)}
                  name="status"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsStatuses.filter((c) =>
                        value.includes(c.value)
                      )}
                      // @ts-ignore
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={optionsStatuses}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}

            {!optionsIzs ? (
              <span style={{ color: "orangered" }}>
                optionsIzs not available
              </span>
            ) : (
              ""
            )}

            {optionsIzs && (
              <Box>
                <Label>IZ</Label>
                <Controller
                  control={control}
                  defaultValue={optionsIzs.map((c) => c.value)}
                  name="iz"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsIzs.filter((c) => value.includes(c.value))}
                      // @ts-ignore
                      onChange={(val) => {
                        val.find((option) => option.value === "all")
                          ? onChange(optionsIzs.map((c) => c.value))
                          : onChange(val.map((c) => c.value));
                      }}
                      options={[
                        { value: "all", label: "Wszystkie" },
                        ...optionsIzs,
                      ]}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}

            {!optionsTags ? (
              <span style={{ color: "orangered" }}>Tags not available</span>
            ) : (
              ""
            )}

            {optionsTags && (
              <Box>
                <Label>Tag</Label>
                <Controller
                  control={control}
                  defaultValue={optionsTags.map((c) => c.value)}
                  name="tags"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsTags.filter((c) => value.includes(c.value))}
                      // @ts-ignore
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={optionsTags}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}

            {!optionsLines ? (
              <span style={{ color: "orangered" }}>
                Nr. linii not available
              </span>
            ) : (
              ""
            )}

            {optionsLines && (
              <Box>
                <Label>Nr. linii</Label>
                <Controller
                  control={control}
                  defaultValue={optionsLines.map((c) => c.value)}
                  name="lineNumber"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(generateNewFilters)();
                          e.preventDefault();
                        }
                      }}
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsLines.filter((c) =>
                        value.includes(c.value)
                      )}
                      onChange={(val) => {
                        val.find((option) => option.value === "all")
                          ? onChange(optionsLines.map((c) => c.value))
                          : onChange(val.map((c) => c.value));
                      }}
                      options={[
                        { value: "all", label: "Wszystkie" },
                        ...optionsLines,
                      ]}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}

            <Box>
              <Label>Typ błędu</Label>
              <Controller
                control={control}
                name="problemType"
                defaultValue={optionsProblemType?.map((c) => c.value)}
                render={({ field: { onChange, value, ref } }) => (
                  <StyledMultiSelect
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(generateNewFilters)();
                        e.preventDefault();
                      }
                    }}
                    closeMenuOnSelect={false}
                    inputRef={ref}
                    value={optionsProblemType?.filter((c) =>
                      value.includes(c.value)
                    )}
                    onChange={(val) => onChange(val.map((c) => c.value))}
                    options={optionsProblemType || []}
                    isMulti
                  />
                )}
              />
            </Box>
          </Filters>
          <Filters itemsInRowNumber={1}>
            <DatePickerHeader text={"Data rozpoczęcia"} />
            <Flex>
              <Controller
                control={control}
                name="startDateFrom"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText="od"
                  />
                )}
              />
              <Controller
                control={control}
                name="startDateTo"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText="do"
                  />
                )}
              />
            </Flex>
            <DatePickerHeader text={"Data zakończenia"} />
            <Flex>
              <Controller
                control={control}
                name="endDateFrom"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText="od"
                  />
                )}
              />
              <Controller
                control={control}
                name="endDateTo"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText="do"
                  />
                )}
              />
            </Flex>
            <DatePickerHeader text={"Pow. o roszczeniu"} />
            <Flex>
              <Controller
                control={control}
                name="submissionDateFrom"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText="od"
                  />
                )}
              />
              <Controller
                control={control}
                name="submissionDateTo"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText="do"
                  />
                )}
              />
            </Flex>
            <Controller
              control={control}
              name="endDocumentSubmissionDate"
              render={({ field }) => (
                <DatePicker
                  selected={field.value ? new Date(field.value) : undefined}
                  onChange={(date) => {
                    field.onChange(date instanceof Date ? date : "");
                  }}
                  customInput={<Input label="Data Pisma" value={field.value} />}
                  dateFormat="dd-MM-yyyy"
                  portalId="root-datepicker-portal"
                />
              )}
            />
          </Filters>
        </form>
      </Box>
    </Card>
  );

  // Todo 1~ limit to vertical scroll
  // Todo 2~ calculate based on %, not 800px
  const onTableScroll = (event: any, ref: any) => {
    const top = event.target.scrollTop;
    const totalHeight = ref.current.scrollHeight - ref.current.clientHeight;
    const percent = ((top / totalHeight) * 100).toFixed(0);
    if (percent === "100") setIsLoadingNext(true);
  };

  const clickTableRow = ({ id }) => {
    // Open sidebar
    setTableData({ ...tableData, isOpen: true, isLoading: true });

    // Update address bar
    history.push(`/objects/disruptions/?selected=${id}`);

    // Get disruption data
    const response = fetchDisruption({
      disruptionId: id,
    }).then((data) => {
      // Update in sidebar
      setTableData({
        ...tableData,
        detailsObject: true,
        dataId: id,
        dataPayload: data,
        tableType: TableType.DISRUPTION_LIST,
      });
    });
  };

  return (
    <>
      <PageLayout displayButtonsGroup={false} pageTitleSpace>
        <Box mx={5} p={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <h3>
              Zakłócenia - {totalFilteredCount}{" "}
              {requestPending ? (
                " - ładowanie..."
              ) : isLoadingNext ? (
                <div
                  style={{
                    position: "relative",
                    height: 24,
                    display: "inline-block",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      position: "absolute",
                      top: 2,
                      left: 6,
                      transform: "scale(0.75)",
                    }}
                  >
                    <Loader />
                  </div>
                </div>
              ) : (
                ""
              )}
            </h3>

            <Flex mx={2}>
              <ReportsDownload
                type="disruption"
                colVis={colVis}
                tableCellDefinition={tableCellDefinition}
                filters={{
                  submittingEntity,
                  affectedEntity,
                  category,
                  status,
                  iz,
                  tags,
                  startDateFrom,
                  startDateTo,
                  endDateFrom,
                  endDateTo,
                  submissionDateFrom,
                  submissionDateTo,
                  endDocumentSubmissionDate,
                  continuationSearch,
                  assignedUser,
                  problems,
                  problemType,
                  scopeSearch,
                  code,
                  lineNumber,
                }}
              />
              {loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_OVERALL_CREATE"
              ) && (
                <Box ml={2}>
                  <StyledButton
                    onClick={handleAddDisruption}
                    bordered
                    title="Dodaj zakłócenie"
                  >
                    {/* Dodaj zakłócenie */}
                    <FontAwesomeIcon icon={faPlus} />
                  </StyledButton>
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>
        {tableColumnDataLoaded &&
        disruptions !== undefined &&
        disruptions.length > 0 &&
        !requestPending ? (
          <>
            <CustomTable
              columnList={columnList}
              data={disruptions}
              colVis={colVis}
              onTableScroll={onTableScroll}
              clickTableRow={clickTableRow}
            />
          </>
        ) : (
          <Box>
            {requestPending ? (
              <Loader />
            ) : (
              <Flex justifyContent="center" alignItems="center">
                Brak obiektów
              </Flex>
            )}
          </Box>
        )}
      </PageLayout>
    </>
  );
};
