import { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface IRadio extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Input = styled.input`
  ${({ theme, disabled }) => `
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;

    &:checked {
      + ${Control} {
        border: 3px solid ${theme.palette.primary};
      }
    }

    &:disabled {
      + ${Control} {
        border-color: #dadada;
      }
    }
  `}
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
`;

const Control = styled.div`
  ${({ theme }) => `
    display: block;
    height: 16px;
    width: 16px;
    margin-right: ${theme.space[2]}px;
    border-radius: 50%;
    background-color: ${theme.palette.neutral.white};
    border: 1px solid rgba(30,46,102,0.15);
  `}
`;

export const Radio: React.FC<IRadio> = ({ className, label, ...props }) => (
  <Label className={className}>
    <Input {...props} type="radio" />
    <Control />
    {label}
  </Label>
);
