/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from "react";
import { Card } from "components/Card";
import EChartsReact from "echarts-for-react";
import styled from "styled-components";
import { Flex } from "components/Flex";
import { useForm } from "react-hook-form";
import { Select } from "components/Select";
import { useFetchDictionaryPartners } from "hooks/fetchDictionaryPartners";
import { getStatsForCategories } from "services/statistics";

interface IForm {
  partner: string;
}

export const DisruptionPerCategoryChart = () => {
  const { dictionaryPartners } = useFetchDictionaryPartners();
  const { register, watch } = useForm<IForm>({
    defaultValues: { partner: "" },
  });
  const watcher = watch("partner");
  const [statsByCategories, setStatsByCategories] = useState<
    Record<string, never>[]
  >([]);

  useEffect(() => {
    getStatsForCategories(watcher).then((res) => {
      setStatsByCategories(res as Record<string, never>[]);
    });
  }, [watcher]);

  const yAxisData = statsByCategories?.map((category) => category.name);
  const xAxisData = statsByCategories?.map((category) => category.cnt);
  const ChartWrapper = styled(Card)`
    max-height: 80%;
  `;
  const Heading = styled(Flex)`
    width: 100%;
    padding: 40px 32px 0;
  `;
  const Title = styled.p`
    align-self: flex-start;
    flex: 1;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.dark};
  `;

  const option = {
    grid: {
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    yAxis: {
      type: "category",
      data: yAxisData,
      axisLabel: {
        margin: 32,
      },
    },
    xAxis: {
      type: "value",
      axisLabel: {
        show: true,
      },
    },
    series: [
      {
        data: xAxisData,
        name: "Liczba zakłóceń",
        type: "bar",
        barGap: 0,
        color: "#005AFF",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          position: "right",
        },
      },
    ],
  };

  return (
    <ChartWrapper>
      <Heading justifyContent="space-between">
        <Title>Wykres kategorii względem liczby zakłóceń</Title>
        <Select label="Partner" {...register("partner")}>
          <option value={""} key=""></option>
          {dictionaryPartners
            ?.filter(({ status }) => status)
            .map((partner) => (
              <option value={partner.id} key={partner.id}>
                {partner.name}
              </option>
            ))}
        </Select>
      </Heading>
      <EChartsReact
        option={option}
        style={{
          width: "100%",
          height: 1100,
        }}
      />
    </ChartWrapper>
  );
};

export default DisruptionPerCategoryChart;
