import React from "react";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import styled from "styled-components";

interface IWarring {
  name: string;
  severity: string;
  type: string;
}
interface ISeverity {
  severity: string;
}
// severity: "warning" | "error";
const WarringWrapper = styled(Card)<ISeverity>`
  ${({ severity, theme }) => `
    background-color: ${
      severity === "error"
        ? theme.palette.neutral.error
        : theme.palette.neutral.warring
    };
    color: ${
      severity === "error"
        ? theme.palette.text.error
        : theme.palette.text.warring
    };
    margin-bottom: 12px;
  `}
`;

export const Warring: React.FC<IWarring> = ({ name, type, severity }) => {
  return (
    <WarringWrapper severity={severity}>
      <Box p={1} pl={5}>
        <Flex justifyContent="space-between" alignItems="center">
          <p>{name}</p>
        </Flex>
      </Box>
    </WarringWrapper>
  );
};
