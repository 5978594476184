/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex } from "components/Flex";
import { FC } from "react";
import { Label, StyledSearch } from "./Search.styled";

interface SearchInputProps {
  placeholder: string;
  value: any;
  onChange: (value: string) => void;
  background?: string;
  width?: number;
}
const SearchInput: FC<SearchInputProps> = ({
  onChange,
  value,
  placeholder,
  background,
  width,
}) => {
  return (
    <Flex gap={20}>
      <Label width={width}>
        <StyledSearch
          background={background}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          width={width}
        />
      </Label>
    </Flex>
  );
};

export default SearchInput;
