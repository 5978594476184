import styled from "styled-components";

export const ButtonsGroup = styled.div`
  ${({ theme }) => `
    display: flex;

    & > *:not(:last-child) {
      margin-right: ${theme.space[4]}px;
    }
  `};
`;
