import { Flex } from "components/Flex";
import { ORChart } from "./ORChart";

export const ORCharts: React.FC = () => {
  return (
    <Flex justifyContent="space-between">
      <ORChart title="SABP" />
      <ORChart title="CW" />
      <ORChart title="TI" />
    </Flex>
  );
};
