import React, { FC } from "react";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Select } from "components/Select";
import DatePicker from "react-datepicker";
import EChartsReact from "echarts-for-react";
import { useForm, Controller } from "react-hook-form";
import { format } from "date-fns";
import {
  getCertificationChart,
  ICertficationResponse,
} from "services/dashboard";
import { Input } from "components/Input";
import { formatDate } from "utilities/formatDate";
import { Button } from "components/Button";
import { toast } from "react-toastify";
import styled from "styled-components";

const taskType = ["Certyfikacja", "CertyfikacjaWE"];
interface IForm {
  dateFrom: Date;
  dateTo: Date;
  taskType: string;
}
type IGroup = "day" | "month";

interface IStyledButton {
  active?: IGroup;
}

const StyledButtonD = styled(Button)<IStyledButton>`
  ${({ theme, active }) => `
    color: ${
      active === "day"
        ? `${theme.palette.primary}`
        : `${theme.palette.text.primary}`
    };
  `}
`;
const StyledButtonM = styled(Button)<IStyledButton>`
  ${({ theme, active }) => `
    color: ${
      active === "month"
        ? `${theme.palette.primary}`
        : `${theme.palette.text.primary}`
    };
  `}
`;

interface Props {
  defaultTaskType?: string;
}

export const CertificationChart: FC<Props> = ({ defaultTaskType }) => {
  const [data, setData] = React.useState<ICertficationResponse[]>([]);
  const [group, setGroup] = React.useState<IGroup>();

  const { register, watch, control } = useForm<IForm>({
    defaultValues: {
      dateFrom: format(new Date(), "yyyy-MM-dd"),
      dateTo: format(new Date("2023-01-02"), "yyyy-MM-dd"),
      taskType: defaultTaskType || "",
    },
  });
  const watcher = watch();

  const generateXAxisData = () =>
    data.map(({ label }) => formatDate(new Date(label)));
  const generateYAxisData = () => data.map(({ value }) => value);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: generateXAxisData(),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Forecast",
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: generateYAxisData(),
        color: ["#005AFF"],
        label: {
          normal: {
            show: true,
            position: "top",
          },
        },
      },
    ],
  };

  React.useEffect(() => {
    getCertificationChart(
      `${watcher.dateFrom}`,
      `${watcher.dateTo}`,
      watcher.taskType,
      group
    )
      .then((res) => {
        setData(res);
      })
      .catch((error) => toast.error("Certyfikacje: błąd pobierania."));
  }, [watcher.dateFrom, watcher.dateTo, watcher.taskType, group]);

  return (
    <Card>
      <Box px={4} py={3}>
        <Flex justifyContent="space-between">
          <Flex>
            <Box mr={2}>
              <Controller
                control={control}
                name="dateFrom"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value ? new Date(field.value) : new Date()}
                    onChange={(date) => {
                      field.onChange(
                        date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                      );
                    }}
                    customInput={
                      <Input label="Data od" value={field.value.toString()} />
                    }
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name="dateTo"
              render={({ field }) => (
                <DatePicker
                  selected={field.value ? new Date(field.value) : new Date()}
                  onChange={(date) => {
                    field.onChange(
                      date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                    );
                  }}
                  customInput={
                    <Input label="Data do" value={field.value.toString()} />
                  }
                  dateFormat="dd-MM-yyyy"
                  portalId="root-datepicker-portal"
                />
              )}
            />
          </Flex>
          <Select label="Rodzaj Prac" {...register("taskType")}>
            <option key=""></option>
            {taskType.map((val) => (
              <option key={val}>{val}</option>
            ))}
          </Select>
        </Flex>
      </Box>
      <EChartsReact option={option} style={{ transform: "scale(1.05)" }} />
      <Box px={4} pb={3}>
        <Flex>
          <StyledButtonD active={group} onClick={() => setGroup("day")}>
            1D
          </StyledButtonD>
          {/* <Button>1T</Button> */}
          <StyledButtonM active={group} onClick={() => setGroup("month")}>
            1M
          </StyledButtonM>
        </Flex>
      </Box>
    </Card>
  );
};
