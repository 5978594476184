/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";

export interface IFilters {
  group?: string;
  label?: string;
  type?: string;
}

export interface ITasksDateContext {
  initialFilters: IFilters;
  filters: IFilters;
}

const TasksDateContext = React.createContext<ITasksDateContext | undefined>(
  undefined
);

export interface ITasksDateDispatchContext {
  setFilters: (filters: IFilters) => void;
  cleanFilters: () => void;
}

const TasksDateDispatchContext = React.createContext<
  ITasksDateDispatchContext | undefined
>(undefined);

export const TasksDateContextProvider: React.FC = ({ children }) => {
  const initialFilters: IFilters = {
    group: "",
    label: "",
    type: "",
  };
  const [filters, setFilters] = useState<IFilters>(initialFilters);

  const cleanFilters = () => {
    setFilters(initialFilters);
  };

  return (
    <TasksDateContext.Provider value={{ filters, initialFilters }}>
      <TasksDateDispatchContext.Provider
        value={{
          setFilters,
          cleanFilters,
        }}
      >
        {children}
      </TasksDateDispatchContext.Provider>
    </TasksDateContext.Provider>
  );
};

export const useTasksDateContext = () => {
  const context = useContext(TasksDateContext);
  if (!context) {
    throw new Error(
      "useTasksDatteContext must be inside a TasksDateContextProvider with a value"
    );
  }

  return context;
};

export const useTasksDispatchContext = () => {
  const context = useContext(TasksDateDispatchContext);
  if (!context) {
    throw new Error(
      "useTasksDateDispatchContext must be inside a TasksDateDispatchProvider with a value"
    );
  }

  return context;
};
