import { Box } from "components/Box";
import styled, { css } from "styled-components";

export const DateTwoDetailsBox = styled(Box)`
  background: white;

  > :nth-child(even) {
    background: #f4f4f4;
  }
`;

export const DetailsRow = styled.div<{ isHeader?: boolean; disable?: boolean }>`
  display: flex;
  align-items: center;
  ${({ theme, disable }) =>
    disable
      ? `
      background-color: ${theme.palette.grey};
      `
      : ""}

  > :not(:last-child) {
    border-right: 0.5px solid #dddddd;
  }
  div > input {
    max-width: 130px;
    padding: 0 8px;
    margin: 0 16px;
  }
  > :first-child {
    flex-basis: 25%;
  }
  > :nth-child(2) {
    flex-basis: 18%;
  }
  > :nth-child(3) {
    flex-basis: 18%;
  }
  > :nth-child(4) {
    flex-basis: 26%;
  }
  > :last-child {
    flex-basis: 5%;
  }
  p {
    margin: 0;
    padding: 16px 24px;
    color: #6f6c6d;
    font-weight: ${({ isHeader }) => (isHeader ? 600 : 400)};
    width: 100px;
    word-wrap: break-word;
  }

  ${({ isHeader }) =>
    isHeader
      ? css`
          border-bottom: 1px solid #dddddd;
        `
      : ""}
`;

export const CustomTitle = styled.div`
  font-size: 10px;
`;
