import React from "react";
import { Box } from "components/Box";
import { useParams } from "react-router-dom";
import { PageLayout } from "components/PageLayout";
import { TaskContextProvider } from "contexts/TaskContext";
import { Column } from "react-table";
import { PageTitle } from "components/PageTitle";
import { Table } from "components/Table";

interface IParams {
  taskId: string;
  type: string;
  taskName: string;
}
type IDateTwo = {
  group: number;
  label: string;
  type: string;
  value: string;
};
const response = [
  {
    group: 140,
    label: "Fundament rozpoczęcie rozpoczęcia zbrojenia",
    type: "Forecast",
    value: "20-03-2021",
  },
  {
    group: 140,
    label: "Fundament rozpoczęcie rozpoczęcia zbrojenia",
    type: "Actual",
    value: "20-03-2021",
  },
  {
    group: 150,
    label: "Odbiór zbrojenia",
    type: "Forecast",
    value: "20-03-2021",
  },
  {
    group: 150,
    label: "Odbiór zbrojenia",
    type: "Actual",
    value: "20-03-2021",
  },
  {
    group: 160,
    label: "Betonowanie",
    type: "Forecast",
    value: "20-03-2021",
  },
  {
    group: 160,
    label: "Betonowanie",
    type: "Actual",
    value: "20-03-2021",
  },
  {
    group: 170,
    label: "Fundament zakończenie wykonanie zasypki fundamentu",
    type: "Forecast",
    value: "20-03-2021",
  },
  {
    group: 170,
    label: "Fundament zakończenie wykonanie zasypki fundamentu",
    type: "Actual",
    value: "20-03-2021",
  },
  {
    group: 172,
    label:
      "Geodezja powykonawcza fundamentu zaakceptowanaGeodezja powykonawcza fundamentu zaakceptowana",
    type: "Forecast",
    value: "20-03-2021",
  },
  {
    group: 172,
    label:
      "Geodezja powykonawcza fundamentu zaakceptowanaGeodezja powykonawcza fundamentu zaakceptowana",
    type: "Actual",
    value: "20-03-2021",
  },
  {
    group: 176,
    label: "Protokół 2 odbioru fundamentu zaakceptowany",
    type: "Forecast",
    value: "20-03-2021",
  },
  {
    group: 176,
    label: "Protokół 2 odbioru fundamentu zaakceptowany",
    type: "Actual",
    value: "20-03-2021",
  },
];

export const TaskNameView = () => {
  const params = useParams<IParams>();

  const columns: Array<Column<IDateTwo>> = React.useMemo(
    () => [
      {
        Header: "Groupa",
        accessor: "group",
        disableSortBy: true,
        width: 80,
      },
      {
        Header: "Zadanie",
        accessor: "label",
        disableSortBy: true,
        width: 250,
      },
      {
        Header: "Typ",
        accessor: "type",
        disableSortBy: true,
      },
      {
        Header: "Data",
        accessor: "value",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <TaskContextProvider>
      <PageLayout>
        <Box p={10}>
          <PageTitle>{params.taskName}</PageTitle>
          <Table<IDateTwo>
            fullHeight={true}
            columns={columns}
            data={response}
            // onRowClick={hadnleClickRow}
          />
        </Box>
      </PageLayout>
      ;
    </TaskContextProvider>
  );
};
