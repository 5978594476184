import { Box } from "components/Box";
import { Flex } from "components/Flex";
import styled from "styled-components";
import { Select } from "components/Select";

export const StyledHeading = styled.h3`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const WrapperInput = styled(Box)`
  min-width: 150px;
`;

export const StyledWrapperFlex = styled(Flex)`
  border-radius: 7px;
  background-color: #fff;
`;

export const PreviewImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 auto;
`;

export const StyledSelect = styled(Select)`
  height: 38px;
`;
