import { Button } from "components/Button";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import { theme } from "config/theme";
import styled from "styled-components";
import deleteIcon from "../../icons/delete.svg";

export const GroupDescriptionWrapper = styled(Card)`
  max-width: 830px;
  min-height: 146px;
  margin-top: 24px;
  padding: 32px;
  display: flex;
  gap: 24px;
  > :first-child {
    flex-basis: 30%;
  }
  > :nth-child(2) {
    flex-basis: 66%;
  }
`;

export const GroupDescription = styled(Input)`
  height: 88%;
`;

export const GroupMembers = styled(Card)`
  padding: 24px;
  max-width: 360px;
`;

export const Title = styled.p`
  font-weight: 700;
  color: ${theme.palette.text.shadowGrey};
  font-size: 14px;
  margin-right: ${({ theme }) => theme.space[12]}px;
`;

export const AddUserButton = styled(Button)`
  font-size: 9px;
  font-weight: 800;
  letter-spacing: 0.4px;
  line-height: 18px;
  max-height: 26px;
`;

export const UserHeadingRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  p {
    font-size: 11px;
    margin: 0;
    font-weight: 700;
    color: ${theme.palette.text.darkGrey};
  }
`;

export const UserRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 13px;
    font-weight: bold;
    color: ${theme.palette.text.shadowGrey};
  }
`;

export const IconDelete = styled.img.attrs({
  alt: "remove",
  width: 12,
  height: 12,
  src: deleteIcon,
})`
  cursor: pointer;
  max-width: 12px;
  margin-right: 6px;
`;

export const PermissionsPanel = styled(Card)`
  padding: 24px;
  max-width: 360px;
`;

export const PermissionsHeadingRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  > * {
    p {
      font-size: 11px;
      margin: 0;
      font-weight: 700;
      color: ${theme.palette.text.darkGrey};
    }
  }
`;

export const OpenAllOptionsButton = styled(Button)`
  font-size: 9px;
  font-weight: 800;
  letter-spacing: 0.4px;
  line-height: 18px;
  max-height: 26px;
`;

export const StyledHeading = styled.h3`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const StyledHeadingFlex = styled(Flex)`
  width: 100%;
`;
