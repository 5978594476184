import React, { useState, useRef, useLayoutEffect, useEffect } from "react";

import { Input } from "components/Input";
import {
  DatePickerFlex,
  FieldFlex,
  Line,
  StyledBox,
  FileNameButton,
  StyledSectionTitle,
  TextareaFlex,
  StyledLinkFieldWrapper,
} from "./DateTwoSABPForm.styled";
import { Box } from "components/Box";
import { Controller, useForm } from "react-hook-form";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import {
  fetchDownloadMediaObject,
  fetchUploadMediaObject,
  updateDateTwoSABPFormSection,
} from "services/tasks";
import { toast } from "react-toastify";
import { Select } from "components/Select";
import { Textarea } from "components/Textarea";
import DatePicker from "react-datepicker";
import { Checkbox } from "components/Checkbox";
import { Label } from "components/Label";
import styled from "styled-components";
import { downloadFile } from "utilities/downloadFile";
import { MsFilePicker } from "components/MsFilePicker";
import { ReactComponent as AccordionIcon } from "icons/accordionIcon.svg";
import { envProjectName } from "envData";
import { SharedDirectoryFilePicker } from "components/SharedDirectoryFilePicker";

interface IProps {
  groups: IGroup[];
  taskId: string;
  sectionName: string;
  remoteFolderPath?: string;
}

interface IGroup {
  collapsible?: boolean;
  type: string;
  name: string;
  label: string;
  fields: {
    name: string;
    label: string;
    type: string;
    value: string | { id: string; name: string };
    config: {
      link?: boolean;
      disabled?: boolean;
      multiple: boolean;
      type: string;
      values: { [key: string]: string };
    };
  }[];
}

const CheckboxWrapper = styled(FieldFlex)`
  justify-content: center;
  align-content: center;
  width: 25%;
  margin-left: 78%;
  letter-spacing: 1px;
  line-height: 15px;
  color: rgba(19, 19, 21, 0.65);
  font-size: 11px;
  font-weight: 900;
  height: 50px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  width: 40%;
  justify-content: space-around;
  align-items: center;
`;

export const DateTwoSABPForm: React.FC<IProps> = ({
  groups,
  taskId,
  sectionName,
  remoteFolderPath,
}) => {
  const defaultValues: { [key: string]: string } = {};

  groups?.map((group) => {
    group.fields.map((field) => {
      if (field.type !== "file" && typeof field.value !== "object") {
        defaultValues[field.name] = field.value;
      }
    });
  });

  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues,
  });

  const [isInputDisabled, changeInputActivity] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [duplicateApplication, setDuplicateApplication] =
    useState<boolean>(false);
  const [expandedAccordionGroups, setExpandedAccordionGroups] = useState<
    string[]
  >([]);
  const [disabledFields, setDisabledFields] = useState([]);

  const setInputActivity = (bool: boolean) => changeInputActivity(bool);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const watchedField = watch(
    "ce08774df67858e739f8689326fd34c6_zasilanie_z_istniejacego_ppe"
  );

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (watchedField) {
      if (watchedField.toLowerCase().split(" ").join("") === "nie")
        setInputActivity(true);
      else setInputActivity(false);
    }
  });

  const uploadMediaObject = (file: File, formFieldName: string) => {
    const data = new FormData();

    data.append("file", file);

    fetchUploadMediaObject(data)
      .then((res) => res.json())
      .then((data) => {
        setValue(formFieldName, data.id);
        setUploadedFileName(data.originalName);
      })
      .catch(() => toast.error("Nie udało się wgrać pliku."));
  };

  const downloadMediaObject = (mediaObjectId: string, fileName: string) => {
    fetchDownloadMediaObject(mediaObjectId)
      .then((response) => response.blob())
      .then((blob) => {
        downloadFile(blob, fileName);
      })
      .catch(() => toast.error("Nie udało się wgrać pliku."));
  };

  const onSelectChange = (e, i, field, formField, group) => {
    field.onChange(e.target.value);

    if (
      /Status opinii ZOPI \d/.test(formField.label) ||
      /Status opini ZOPI \d/.test(formField.label) ||
      formField.label === "Status opinii ZOPI" ||
      formField.label === "Status opini ZOPI"
    ) {
      if (group.fields.length >= i + 2 && group.fields.length >= i + 3) {
        if (
          [
            "Odrzucony - zaakceptowane",
            "Akceptacja z uwagami",
            "Akceptacja bez uwag",
            "Samoakceptacja",
          ].includes(e.target.value)
        ) {
          setDisabledFields((prevState) => [
            ...prevState,
            group.fields[i + 2].name,
            group.fields[i + 3].name,
          ]);
        } else {
          setDisabledFields((prevState) =>
            prevState.filter(
              (fieldName) =>
                fieldName !== group.fields[i + 2].name &&
                fieldName !== group.fields[i + 3].name
            )
          );
        }
      }
      if (group.fields.length >= i + 4 && group.fields.length >= i + 5) {
        if (
          ["Odrzucony - do poprawy", "Odrzucony z uwagami"].includes(
            e.target.value
          )
        ) {
          setDisabledFields((prevState) => [
            ...prevState,
            group.fields[i + 4].name,
            group.fields[i + 5].name,
          ]);
        } else {
          setDisabledFields((prevState) =>
            prevState.filter(
              (fieldName) =>
                fieldName !== group.fields[i + 4].name &&
                fieldName !== group.fields[i + 5].name
            )
          );
        }
      }
    }
  };

  const checkInputActivity = (group: IGroup, index: number): boolean =>
    isInputDisabled &&
    index > 7 &&
    group.name === "wniosek_o_okreslenie_warunkow_przylaczenia"
      ? true
      : false;

  const onSubmit = handleSubmit((data) => {
    if (!groups) return;

    updateDateTwoSABPFormSection({
      taskId,
      sectionName,
      taskDetails: data,
    })
      .then(() => {
        toast.success("Zapisano zmiany.");
      })
      .catch((e) => {
        toast.error("Nie udało się zapisać zmian.");
      });
  });

  useEffect(() => {
    groups.map((group) =>
      group.fields.map((formField, i) => {
        if (
          /Status opinii ZOPI \d/.test(formField.label) ||
          /Status opini ZOPI \d/.test(formField.label) ||
          formField.label === "Status opinii ZOPI" ||
          formField.label === "Status opini ZOPI"
        ) {
          if (group.fields.length >= i + 2 && group.fields.length >= i + 3) {
            if (
              [
                "Odrzucony - zaakceptowane",
                "Akceptacja z uwagami",
                "Akceptacja bez uwag",
                "Samoakceptacja",
              ].includes(watch(formField.name))
            ) {
              setDisabledFields((prevState) => [
                ...prevState,
                group.fields[i + 2].name,
                group.fields[i + 3].name,
              ]);
            }
          }
          if (group.fields.length >= i + 4 && group.fields.length >= i + 5) {
            if (
              ["Odrzucony - do poprawy", "Odrzucony z uwagami"].includes(
                watch(formField.name)
              )
            ) {
              setDisabledFields((prevState) => [
                ...prevState,
                group.fields[i + 4].name,
                group.fields[i + 5].name,
              ]);
            }
          }
        }
      })
    );
  }, []);

  if (!groups) return <p>ładowanie...</p>;

  return (
    <StyledBox pb={4}>
      <form>
        {groups.map((group) => (
          <>
            <StyledSectionTitle>
              <Box mr={6}>{group.label}</Box> <Line />
              {group.collapsible && (
                <Box ml={6}>
                  <AccordionIcon
                    onClick={() => {
                      setExpandedAccordionGroups((prevState) =>
                        prevState.includes(group.name)
                          ? prevState.filter((name) => name !== group.name)
                          : [...prevState, group.name]
                      );
                    }}
                  />
                </Box>
              )}
            </StyledSectionTitle>
            {group.name === "dane_uop_i_wtp" && (
              <CheckboxWrapper>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    checked={duplicateApplication}
                    onChange={() => setDuplicateApplication((prev) => !prev)}
                  />
                  Powiel wniosek
                </CheckboxLabel>
              </CheckboxWrapper>
            )}
            {(!group.collapsible ||
              (group.collapsible &&
                expandedAccordionGroups.includes(group.name))) && (
              <Box p={5}>
                <Flex flexWrap="wrap">
                  {group.fields.map((formField, i) =>
                    formField.type === "select" ? (
                      <FieldFlex>
                        <Controller
                          key={formField.name}
                          control={control}
                          name={formField.name}
                          {...register(formField.name)}
                          render={({ field }) => (
                            <Select
                              {...field}
                              fullWidth
                              label={formField.label}
                              onChange={(e) => {
                                onSelectChange(e, i, field, formField, group);
                              }}
                              disabled={
                                formField.config.disabled ||
                                checkInputActivity(group, i)
                              }
                            >
                              {Object.keys(formField.config.values).map(
                                (key: string) => (
                                  <option
                                    key={formField.config.values[key]}
                                    value={formField.config.values[key]}
                                    selected={false}
                                  >
                                    {formField.config.values[key]}
                                  </option>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </FieldFlex>
                    ) : formField.config.link ? (
                      envProjectName === "nokia" ? (
                        <FieldFlex alignItems="center">
                          <StyledLinkFieldWrapper>
                            <Input
                              fullWidth
                              key={formField.name}
                              label={formField.label}
                              type={formField.type}
                              {...register(formField.name)}
                            />
                          </StyledLinkFieldWrapper>
                          <Box pt={3}>
                            <MsFilePicker
                              selectedFilePath={watch(formField.name) || null}
                              customOnSelectFile={(path: string) => {
                                setValue(formField.name, path);
                              }}
                            />
                          </Box>
                        </FieldFlex>
                      ) : (
                        <FieldFlex alignItems="flex-end">
                          <Input
                            key={formField.name}
                            label={formField.label}
                            type={formField.type}
                            disabled={
                              formField.config.disabled ||
                              checkInputActivity(group, i) ||
                              disabledFields.includes(formField.name)
                            }
                            {...register(formField.name)}
                          />
                          <Box pt={3} px={2}>
                            <SharedDirectoryFilePicker
                              selectedFilePath={
                                watch(formField.name) ||
                                remoteFolderPath ||
                                null
                              }
                              customOnSelectFile={(path: string) => {
                                setValue(formField.name, path);
                              }}
                            />
                          </Box>
                          <Button
                            type="button"
                            bordered
                            onClick={() => {
                              if (
                                navigator &&
                                navigator.clipboard &&
                                navigator.clipboard.writeText
                              ) {
                                return navigator.clipboard.writeText(
                                  watch(formField.name)
                                );
                              }
                            }}
                          >
                            kopiuj
                          </Button>
                        </FieldFlex>
                      )
                    ) : formField.type === "breakLine" ? (
                      <>
                        <Line />
                      </>
                    ) : formField.config.type === "date" ? (
                      <DatePickerFlex>
                        <Controller
                          control={control}
                          name={formField.name}
                          {...register(formField.name)}
                          render={({ field }) => (
                            <DatePicker
                              disabled={
                                formField.config.disabled ||
                                checkInputActivity(group, i) ||
                                disabledFields.includes(formField.name)
                              }
                              selected={
                                field.value ? new Date(field.value) : undefined
                              }
                              onChange={(date) => {
                                field.onChange(
                                  date instanceof Date ? date : ""
                                );
                              }}
                              customInput={
                                <Input
                                  label={formField.label}
                                  value={field.value}
                                />
                              }
                              dateFormat="dd-MM-yyyy"
                              portalId="root-datepicker-portal"
                            />
                          )}
                        />
                      </DatePickerFlex>
                    ) : formField.type === "textarea" ? (
                      <TextareaFlex>
                        <Textarea
                          disabled={formField.config.disabled}
                          fullWidth
                          key={formField.name}
                          label={formField.label}
                          {...register(formField.name)}
                        />
                      </TextareaFlex>
                    ) : formField.type === "checkbox" ? (
                      <FieldFlex>
                        <Controller
                          control={control}
                          name={formField.name}
                          render={({ field }) => (
                            <Checkbox
                              disabled={formField.config.disabled}
                              label={formField.label}
                              checked={field.value ? true : false}
                              onChange={(isChecked) => {
                                field.onChange(isChecked.target.checked);
                              }}
                            />
                          )}
                        />
                      </FieldFlex>
                    ) : formField.type === "file" ? (
                      <FieldFlex>
                        <Label>
                          {formField.label}

                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              if (event.target.files) {
                                const file = event.target.files[0];
                                if (file) {
                                  uploadMediaObject(file, formField.name);
                                }
                              }
                            }}
                          />

                          <Flex mt={1}>
                            <Box mr={3}>
                              <FileNameButton
                                type="button"
                                onClick={() => {
                                  if (typeof formField.value === "object")
                                    downloadMediaObject(
                                      formField.value?.id,
                                      formField.value?.name
                                    );
                                }}
                              >
                                {uploadedFileName ||
                                  (typeof formField.value === "object" &&
                                    formField.value?.name)}
                              </FileNameButton>
                            </Box>

                            <Button
                              type="button"
                              bordered
                              onClick={(event) => {
                                event.preventDefault();
                                fileInputRef.current?.click();
                              }}
                            >
                              Wgraj plik
                            </Button>
                          </Flex>
                        </Label>
                      </FieldFlex>
                    ) : (
                      <FieldFlex>
                        <Input
                          fullWidth
                          key={formField.name}
                          label={formField.label}
                          type={formField.type}
                          disabled={
                            formField.config.disabled ||
                            checkInputActivity(group, i) ||
                            disabledFields.includes(formField.name)
                          }
                          {...register(formField.name)}
                        />
                      </FieldFlex>
                    )
                  )}
                </Flex>
                {group.name === "dane_uop_i_wtp" && duplicateApplication && (
                  <Flex flexWrap="wrap">
                    {group.fields.map((formField) =>
                      formField.type === "select" ? (
                        <FieldFlex>
                          <Controller
                            key={`${formField.name}frozen`}
                            control={control}
                            name={`${formField.name}frozen`}
                            render={({ field }) => (
                              <Select
                                fullWidth
                                label={formField.label}
                                disabled={true}
                                value={watch(formField.name)}
                              >
                                {Object.keys(formField.config.values).map(
                                  (key: string) => (
                                    <option
                                      key={formField.config.values[key]}
                                      value={key}
                                      selected={false}
                                    >
                                      {formField.config.values[key]}
                                    </option>
                                  )
                                )}
                              </Select>
                            )}
                          />
                        </FieldFlex>
                      ) : formField.config.type === "date" ? (
                        <DatePickerFlex>
                          <Controller
                            control={control}
                            key={`${formField.name}frozen`}
                            name={`${formField.name}frozen`}
                            render={({ field }) => (
                              <DatePicker
                                disabled={true}
                                selected={
                                  watch(formField.name)
                                    ? new Date(watch(formField.name))
                                    : undefined
                                }
                                onChange={(date) => {
                                  field.onChange(
                                    date instanceof Date ? date : ""
                                  );
                                }}
                                customInput={
                                  <Input
                                    label={formField.label}
                                    value={watch(formField.name)}
                                  />
                                }
                                dateFormat="dd-MM-yyyy"
                                portalId="root-datepicker-portal"
                              />
                            )}
                          />
                        </DatePickerFlex>
                      ) : (
                        <FieldFlex>
                          <Input
                            fullWidth
                            key={formField.name}
                            label={formField.label}
                            type={formField.type}
                            disabled={true}
                            value={watch(formField.name)}
                          />
                        </FieldFlex>
                      )
                    )}
                  </Flex>
                )}
              </Box>
            )}
          </>
        ))}

        <Flex justifyContent="flex-end" mr={6}>
          <Button onClick={onSubmit} type="submit" bordered>
            Zapisz
          </Button>
        </Flex>
      </form>
    </StyledBox>
  );
};
