import { Column } from "react-table";
import { ITask } from "types/task";
import { Box } from "components/Box";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

export const useColumnsData = (typeParam: string) => {
  const [columns, setColumns] = React.useState<any[]>([]);
  const history = useHistory();

  const columnsOR: Array<Column<ITask>> = useMemo(
    () => [
      {
        Header: "CSC",
        accessor: "code",
        sticky: "left",
        maxWidth: 90,
        // minWidth: 120,
        width: 90,
        Cell: (item) => (
          <Box>
            <a href={`/objects/${typeParam}/${item?.row?.original?.id}`}>
              {item.row.original.code}
            </a>
          </Box>
        ),
      },
      {
        Header: "Nazwa",
        accessor: "objectName",
      },
      {
        Header: "Nazwa IE-50",
        accessor: "objectNameIE50",
      },
      {
        Header: "Nazwa lokalizacji",
        accessor: "locationName",
      },
      {
        Header: "CZY JEST W HRF",
        accessor: "inHrf",
        Cell: (item: any) => <p>{item.row.original.inHrf ? "tak" : "nie"}</p>,
      },
      {
        Header: "FIRMA SABP",
        accessor: "contractorSA",
        Cell: (item: any) => {
          if (item.row.original.contractorSA) {
            return <p>{item.row.original.contractorSA}</p>;
          } else {
            return <p>---</p>;
          }
        },
      },
      {
        Header: "FIRMA CW",
        accessor: "contractorCW",
        Cell: (item: any) => {
          if (item.row.original.contractorCW) {
            return <p>{item.row.original.contractorCW}</p>;
          } else {
            return <p>---</p>;
          }
        },
      },
      {
        Header: "Typ obiektu",
        accessor: "type",
      },
      {
        Header: "Kilometraż",
        accessor: "chainage",
      },
      {
        Header: "IZ",
        accessor: "iZ",
      },
      {
        Header: "Linia",
        accessor: "line",
      },
      {
        Header: "Rozpoczęcie 123",
        accessor: "startDate",
      },
      {
        Header: "Zakończenie",
        accessor: "endDate",
      },
      // new
      {
        Header: "Prace projektowe rozpoczęcie",
        accessor: "subtaskPraceProjektoweStartDate",
      },
      {
        Header: "Prace projektowe zakończenie",
        accessor: "subtaskPraceProjektoweEndDate",
      },
      {
        Header: "DULICP lokalizacyjna rozpoczęcie",
        accessor: "subtaskDULICPStartDate",
      },
      {
        Header: "DULICP zakończenie",
        accessor: "subtaskDULICPEndDate",
      },
      {
        Header: "PnB rozpoczęcie",
        accessor: "subtaskPnBStartDate",
      },
      {
        Header: "PnB zakończenie",
        accessor: "subtaskPnBEndDate",
      },
      {
        Header: "Prace budowlane rozpoczęcie",
        accessor: "subtaskPraceBudowlaneStartDate",
      },
      {
        Header: "Prace budowlane zakończenie",
        accessor: "subtaskPraceBudowlaneEndDate",
      },
      {
        Header: "Prąd na obiekcie rozpoczęcie",
        accessor: "subtaskPradNaObiekcieStartDate",
      },
      {
        Header: "Prąd na obiekcie zakończenie",
        accessor: "subtaskPradNaObiekcieEndDate",
      },
      {
        Header: "Zagospodarowanie rozpoczęcie",
        accessor: "subtaskZagospodarowanieStartDate",
      },
      {
        Header: "Zagospodarowanie zakończenie",
        accessor: "subtaskZagospodarowanieEndDate",
      },
      {
        Header: "Wdrożenie rozpoczęcie",
        accessor: "subtaskWdrozenieStartDate",
      },
      {
        Header: "Wdrożenie zakończenie",
        accessor: "subtaskWdrozenieEndDate",
      },
      {
        Header: "Uruchomienie rozpoczęcie",
        accessor: "subtaskUruchomienieStartDate",
      },
      {
        Header: "Uruchomienie zakończenie",
        accessor: "subtaskUruchomienieEndDate",
      },
      {
        Header: "Data zakończenia montaży wieży Forecast",
        accessor: "taskmetaForecastMontazWiezyEndDate",
      },
      {
        Header: "Data zakończenia montaży wieży Aktual",
        accessor: "taskmetaActualMontazWiezyEndDate",
      },
      {
        Header: "",
        accessor: "problems",
      },
      // {
      //   Header: "",
      //   accessor: "id",
      //   disableSortBy: true,
      //   maxWidth: 80,
      //   minWidth: 80,
      //   width: 80,
      //   sticky: "right",
      //   Cell: (item) => (
      //     <StyledFlex justifyContent="flex-end">
      //       <Button bordered onClick={handleLinkClick(item?.row?.original?.id)}>
      //         <FontAwesomeIcon icon={faArrowAltCircleRight} />
      //       </Button>
      //     </StyledFlex>
      //   ),
      // },
      // {
      //   Header: "Wykonanie",
      //   accessor: "completion",
      //   Cell: (item) =>
      //     item.row?.original?.completion !== null
      //       ? `${item.row.original.completion}%`
      //       : "loading",
      // },
    ],
    []
  );

  const columnsOTK: Array<Column<ITask>> = useMemo(
    () => [
      {
        Header: "CSC",
        accessor: "code",
        sticky: "left",
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        Cell: (item) => (
          <Box
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              event.stopPropagation();

              history.push(`/objects/${typeParam}/${item?.row?.original?.id}`);
            }}
          >
            <a href="">{item.row.original.code}</a>
          </Box>
        ),
      },
      {
        Header: "Firma",
        accessor: "company",
      },
      {
        Header: "IZ",
        accessor: "iZ",
      },
      {
        Header: "Linia",
        accessor: "line",
      },
      {
        Header: "Km od",
        accessor: "chainageStart",
      },
      {
        Header: "Km do",
        accessor: "chainageEnd",
      },
      {
        Header: "Długość",
        accessor: "length",
      },
      {
        Header: "Rozpoczęcie",
        accessor: "startDate",
      },
      {
        Header: "Zakończenie",
        accessor: "endDate",
      },
      // {
      //   Header: "",
      //   accessor: "id",
      //   disableSortBy: true,
      //   maxWidth: 80,
      //   minWidth: 80,
      //   width: 80,
      //   sticky: "right",
      //   Cell: (item) => (
      //     <StyledFlex justifyContent="flex-end">
      //       <Button bordered onClick={handleLinkClick(item?.row?.original?.id)}>
      //         <FontAwesomeIcon icon={faArrowAltCircleRight} />
      //       </Button>
      //     </StyledFlex>
      //   ),
      // },
      // {
      //   Header: "Wykonanie",
      //   accessor: "completion",
      //   Cell: (item) =>
      //     item.row?.original?.completion !== null
      //       ? `${item.row.original.completion}%`
      //       : "loading",
      // },
    ],
    []
  );

  const columnsCertification: Array<Column<ITask>> = useMemo(
    () => [
      {
        Header: "CSC",
        accessor: "code",
        sticky: "left",
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        Cell: (item) => (
          <Box
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              event.stopPropagation();

              history.push(`/objects/${typeParam}/${item?.row?.original?.id}`);
            }}
          >
            <a href="">{item.row.original.code}</a>
          </Box>
        ),
      },
      {
        Header: "Linia",
        accessor: "line",
      },
      {
        Header: "KM od",
        accessor: "chainageStart",
      },
      {
        Header: "km do",
        accessor: "chainageEnd",
      },
      {
        Header: "Długość",
        accessor: "length",
      },
      {
        Header: "Rozpoczęcie",
        accessor: "startDate",
      },
      {
        Header: "Zakończenie",
        accessor: "endDate",
      },
    ],
    []
  );

  const columnsFDS: Array<Column<ITask>> = useMemo(
    () => [
      {
        Header: "CSC",
        accessor: "code",
        sticky: "left",
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        Cell: (item) => (
          <Box
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              event.stopPropagation();

              history.push(`/objects/${typeParam}/${item?.row?.original?.id}`);
            }}
          >
            <a href="">{item.row.original.code}</a>
          </Box>
        ),
      },
      {
        Header: "Nazwa",
        accessor: "name",
      },
      {
        Header: "Nazwa IE-50",
        accessor: "objectNameIE50",
      },
      {
        Header: "Linia",
        accessor: "line",
      },
      {
        Header: "Km od",
        accessor: "chainageStart",
      },
      {
        Header: "km do",
        accessor: "chainageEnd",
      },
      {
        Header: "Czy jest w HRF",
        accessor: "inHrf",
      },
      {
        Header: "Długość",
        accessor: "length",
      },
      {
        Header: "Rozpoczęcie",
        accessor: "startDate",
      },
      {
        Header: "Zakończenie",
        accessor: "endDate",
      },
    ],
    []
  );

  const columnsOld: Array<Column<ITask>> = useMemo(
    () => [
      {
        Header: "CSC",
        accessor: "code",
        sticky: "left",
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        Cell: (item) => (
          <Box
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              event.stopPropagation();

              history.push(`/objects/${typeParam}/${item?.row?.original?.id}`);
            }}
          >
            <a href="">{item.row.original.code}</a>
          </Box>
        ),
      },
      {
        Header: "Kod",
        accessor: "locationName",
      },
      {
        Header: "CZY JEST W HRF",
        accessor: "inHrf",
        Cell: () => <p>-</p>,
      },
      {
        Header: "FIRMA SABP",
        accessor: "contractorSA",
      },
      {
        Header: "FIRMA CW",
        accessor: "contractorCW",
      },
      {
        Header: "Typ obiektu",
        accessor: "type",
      },
      {
        Header: "Kliometraż",
        accessor: "chainage",
      },
      {
        Header: "IZ",
        accessor: "iZ",
      },
      {
        Header: "Linia",
        accessor: "line",
      },
      {
        Header: "Rozpoczęcie",
        accessor: "startDate",
      },
      {
        Header: "Zakończenie",
        accessor: "endDate",
      },
    ],
    []
  );

  const renderColumns = () => {
    switch (typeParam) {
      case "OR,ORR":
        setColumns(columnsOR);
        break;
      case "OTK":
        setColumns(columnsOTK);
        break;
      case "CertyfikacjaWE":
        setColumns(columnsCertification);
        break;
      case "Certyfikacja":
        setColumns(columnsCertification);
        break;
      case "ReadyToWE":
        setColumns(columnsCertification);
        break;
      case "FDS":
        setColumns(columnsFDS);
        break;
      default:
        setColumns(columnsOld);
        break;
    }
  };

  React.useEffect(() => {
    renderColumns();
  }, [typeParam]);

  return { columns, columnsOld };
};
