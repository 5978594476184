import React, { useState, useEffect } from "react";
import { DisruptionForm } from "containers/DisruptionForm";
import { useParams } from "react-router-dom";
import { PageLayout } from "components/PageLayout";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ScopesForm } from "components/ScopesForm";
import { Updates } from "containers/Updates";
import { fetchDisruption } from "services/disruptions";
import { IDisruption } from "types/disruption";
import { ButtonsGroup } from "components/ButtonsGroup";
import {
  DisruptionContextProvider,
  useDisruptionDispatchContext,
} from "contexts/DisruptionContext";
import { DisruptionTimeline } from "containers/DisruptionTimeline";
import styled from "styled-components";
import {
  useTableSidebarDispatchContext,
  useTableSidebarContext,
} from "contexts/TableSidebarContext";
import { DisruptionPayments } from "containers/DisruptionPayments";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const Root = styled(Flex)`
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
`;
const StyledBox = styled(Box)`
  height: calc(100% - 60px);
  width: 100%;
  background-color: #fff;
  border: 20px solid #f4f5f8;
  border-bottom: 0;
  overflow: auto;
`;
const StyledCard = styled(Card)`
  overflow: auto;
  width: 100%;
  background-color: #f4f5f8;
`;
const StyledTabs = styled(Tabs)`
  height: 100%;
  overflow: hidden;
`;
const StyledTabList = styled(TabList)`
  margin: 0;
  padding: 0 10px;
  background-color: #fbfbfb;
  .react-tabs__tab--selected {
    background-color: #fbfbfb;
    &::after {
      bottom: 15px;
      height: 3px;
    }
  }
`;

export const EditDisruptionViewNotConnected = () => {
  const [disruption, setDisruption] = useState<IDisruption | null>(null);
  const { disruptionId } = useParams<{ disruptionId: string }>();
  const { setNumber } = useDisruptionDispatchContext();

  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const getDisruption = () => {
    fetchDisruption({
      disruptionId,
    }).then(setDisruption);
  };

  useEffect(() => {
    getDisruption();
  }, [disruptionId]);

  useEffect(() => {
    setTimeout(() => {
      setTableData({
        ...tableData,
        detailsObject: false,
        showComments: disruptionId ? true : false,
      });
    });
  }, [disruption, disruptionId]);

  useEffect(() => {
    if (disruption) {
      setNumber(disruption.number);
    }
  }, [disruption]);

  return (
    <PageLayout>
      <Root justifyContent="space-between">
        <StyledCard>
          <StyledTabs>
            <StyledTabList>
              <Flex justifyContent="space-between" alignItems="center">
                <div>
                  {loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_OVERALL_SHOW"
                  ) && <Tab>Dane ogólne</Tab>}
                  {loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_RANGES_LIST"
                  ) && <Tab>Zakresy</Tab>}
                  {loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_UPDATES_LIST"
                  ) && <Tab>Aktualizacje</Tab>}
                  {loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_COSTS_LIST"
                  ) && <Tab>Koszty</Tab>}
                  {loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_HISTORY_SHOW"
                  ) && <Tab>Historia</Tab>}
                </div>
                <ButtonsGroup id="page-header-buttons-group-portal" />
              </Flex>
            </StyledTabList>
            <StyledBox px={8} py={6}>
              {loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_OVERALL_SHOW"
              ) && (
                <TabPanel>
                  {disruption ? (
                    <DisruptionForm
                      getDisruption={getDisruption}
                      disruption={disruption}
                    />
                  ) : null}
                </TabPanel>
              )}
              {loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_RANGES_LIST"
              ) && (
                <TabPanel>
                  {disruption ? (
                    <ScopesForm
                      getDisruption={getDisruption}
                      disruption={disruption}
                      disruptionId={disruption.id}
                      scopes={disruption.scope}
                      otkSearchParams={disruption.otkSearchParams}
                    />
                  ) : null}
                </TabPanel>
              )}
              {loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_UPDATES_LIST"
              ) && (
                <TabPanel>
                  {disruption ? <Updates disruption={disruption} /> : null}
                </TabPanel>
              )}
              {loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_COSTS_LIST"
              ) && (
                <TabPanel>
                  <DisruptionPayments />
                </TabPanel>
              )}
              {loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_HISTORY_SHOW"
              ) && (
                <TabPanel>
                  {disruption ? (
                    <DisruptionTimeline disruptionId={disruption.id} />
                  ) : null}
                </TabPanel>
              )}
            </StyledBox>
          </StyledTabs>
        </StyledCard>
      </Root>
    </PageLayout>
  );
};

export const EditDisruptionView: React.FC = () => (
  <DisruptionContextProvider>
    <EditDisruptionViewNotConnected />
  </DisruptionContextProvider>
);
