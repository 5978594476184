import { useState } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import styled from "styled-components";
import { Button } from "components/Button";
import SearchInput from "./Search/Search";
import { theme } from "config/theme";
import { useFetchDictionaryUsers } from "hooks/fetchDictionaryUsers";
import { ReactComponent as AddIcon } from "icons/add.svg";
import { IUser } from "types/users";

interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  onAddUserClick: (newUser: IUser) => void;
}

const Wrapper = styled(Box)`
  position: relative;
  width: 35vw;
  height: 60vh;
`;

const UsersListWrapper = styled(Flex)`
  height: 220px;
  width: 350px;
  overflow: scroll;
`;

const UserWrapper = styled(Flex)`
  ${({ theme }) => `
    width: 100%;
    height: 40px;
    border: 1px ${theme.palette.grey} solid;
    padding: ${theme.space[2]}px;
    margin: 0 ${theme.space[1]}px;
  `}
`;

const AddButton = styled(Button)`
  height: 20px;
`;

export const AddUserToGroupModal = ({
  isOpen,
  handleCloseModal,
  onAddUserClick,
}: IProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { dictionaryUsers } = useFetchDictionaryUsers();

  return (
    <Modal isOpen={isOpen} onCancelClick={handleCloseModal}>
      <Wrapper p={12}>
        <Box mb={5}>
          <h3>Dodaj użytkownika</h3>
        </Box>

        <SearchInput
          placeholder="Szukaj"
          value={searchValue}
          onChange={setSearchValue}
          background={theme.palette.lightGrey}
          width={350}
        />

        <UsersListWrapper flexWrap="wrap">
          {dictionaryUsers &&
            dictionaryUsers
              .filter((searchUser) =>
                searchUser.email
                  ?.toLowerCase()
                  .includes(searchValue.toLowerCase())
              )
              .map((user) => (
                <UserWrapper key={user.id} justifyContent="space-between">
                  {user.email}
                  <AddButton>
                    <AddIcon
                      onClick={() => {
                        onAddUserClick(user);
                      }}
                    />
                  </AddButton>
                </UserWrapper>
              ))}
        </UsersListWrapper>

        <Flex mt={4} justifyContent="flex-end">
          <Button bordered onClick={handleCloseModal}>
            Wróć
          </Button>
        </Flex>
      </Wrapper>
    </Modal>
  );
};
