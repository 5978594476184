import { Button } from "components/Button";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Input } from "components/Input";
import { Label } from "components/Select";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Box } from "components/Box";
import { IRailwayLine } from "../types/railway-line";
import { Flex } from "../components/Flex";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  fetchRailwayLines,
  IPutRailwayLineResponse,
  putRailwayLine,
  deleteRailwayLine,
} from "../services/railwayLines";
import { useParams, useHistory } from "react-router-dom";
import { isValidFloatNumber } from "../utilities/validate";
import { ConfirmModal } from "components/ConfirmModal";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface IRailwayLineForm {
  railwayLines: {
    id: string;
    lineNumber: number;
    chainageStart: number;
    chainageEnd: number;
    chainage: number;
    attachments: { label: string; value: string }[];
  }[];
}

const Grid = styled.div`
  ${({ theme }) => `
    align-items: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const attachmentOptions = [
  { label: "Załącznik nr 1", value: "Załącznik nr 1" },
  { label: "Załącznik nr 5", value: "Załącznik nr 5" },
  { label: "Załącznik nr 21", value: "Załącznik nr 21" },
  { label: "inne", value: "inne" },
];

export const RailwayLineDetails: React.FC = () => {
  const { lineNumber } = useParams<{ lineNumber: string }>();
  const [railwayLines, setRailwayLines] = useState<IRailwayLine[]>([]);
  const [summaryLine, setSummaryLine] = useState<IRailwayLine | null>(null);
  const [idToDelete, setIdToDelete] = useState("");

  const history = useHistory();

  const { register, handleSubmit, control, reset } =
    useForm<IRailwayLineForm>();
  const calcChainage = (
    line: IRailwayLine | (IPutRailwayLineResponse & { chainage: number })
  ) => {
    line.chainage = Number((line.chainageEnd - line.chainageStart).toFixed(3));
    return line.chainage;
  };

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const calcSummaryLine = () => {
    const summaryLine: IRailwayLine = {
      id: "summary",
      lineNumber: Number(lineNumber),
      csc: "",
      chainageStart: 0,
      chainageEnd: 0,
      chainage: 0,
      attachments: [],
    };
    const attachments: string[] = [];
    if (railwayLines.length > 0)
      summaryLine.chainageStart = railwayLines[0].chainageStart;
    railwayLines.forEach((line) => {
      if (line.chainageEnd > summaryLine.chainageEnd)
        summaryLine.chainageEnd = line.chainageEnd;
      if (line.chainageStart < summaryLine.chainageStart)
        summaryLine.chainageStart = line.chainageStart;
      summaryLine.chainage += calcChainage(line);
      line.attachments.forEach(
        (el) => !attachments.includes(el) && attachments.push(el)
      );
    });
    summaryLine.attachments = attachments.filter((el) => !!el.length);
    setSummaryLine(summaryLine);
  };

  useEffect(() => {
    calcSummaryLine();
  }, [railwayLines]);

  const resetFormWithData = (
    railwayLinesData: IRailwayLine[] | IPutRailwayLineResponse[]
  ) => {
    const data = railwayLinesData.filter(
      (line) => line.lineNumber === Number(lineNumber)
    );
    setRailwayLines(data as IRailwayLine[]);
    const newValues = data.map((line, index) => {
      const formLine = Object.fromEntries(
        Object.entries(line).filter(([key, value]) => value !== null)
      );
      const attachments = line.attachments.map((el) => {
        return { label: el, value: el };
      });
      return { ...formLine, attachments };
    });
    reset({ railwayLines: newValues });
  };

  useEffect(() => {
    fetchRailwayLines({ filter: { lineNumber: lineNumber } })
      .then((response) => resetFormWithData(response.data))
      .catch(() => console.error("Failed to fetch railway lines"));
  }, []);

  const onSubmit = handleSubmit((data) => {
    const payloadArr = [];
    for (let i = 0; i < data.railwayLines.length; i++) {
      if (
        data.railwayLines[i].chainageStart > data.railwayLines[i].chainageEnd
      ) {
        toast.error("Km od nie może być większe od Km do");
        return;
      }
      payloadArr.push({
        ...data.railwayLines[i],
        lineNumber: Number(data.railwayLines[i].lineNumber),
        chainageStart: Number(data.railwayLines[i].chainageStart),
        chainageEnd: Number(data.railwayLines[i].chainageEnd),
        attachments: data.railwayLines[i].attachments.map((el) => el.value),
      });
    }

    Promise.all(
      payloadArr.map(
        (railwayLine) =>
          railwayLine &&
          putRailwayLine({ railwayLineId: railwayLine.id, railwayLine })
      )
    )
      .then((res) => {
        resetFormWithData(res);
        toast.success("Linia(e) kolejowa(e) została(li) zaktualizowana(e).");
      })
      .catch(() => {
        toast.error("Nie udało się zaktualizować linię kolejową.");
      });
  });

  const handleDeleteClick = () => {
    deleteRailwayLine(idToDelete)
      .then(() => {
        history.push("/objects/railway-lines");
        toast.success("Linia kolejowa została usunięte.");
      })
      .catch(() => {
        toast.error("Nie udało się usunąć liniję kolejową.");
      })
      .finally(() => {
        setIdToDelete("");
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex alignItems="center" justifyContent="space-between">
          <FieldSetTitle>Dane linii kolejowej</FieldSetTitle>
          {loggedUserRoles?.includes("ROLE_LINES_OVERALL_DELETE") && (
            <Button
              type="button"
              bordered
              onClick={() => setIdToDelete(railwayLines[0].id)}
            >
              Usuń
            </Button>
          )}
        </Flex>
        {railwayLines.length > 0 && (
          <>
            {summaryLine && (
              <Box>
                <Grid>
                  <p>
                    Długość:{" "}
                    <strong>{summaryLine.chainage.toFixed(3)} km</strong>
                  </p>
                  <p>
                    Km od: <strong>{summaryLine.chainageStart} km</strong>
                  </p>
                  <p>
                    Km do: <strong>{summaryLine.chainageEnd} km</strong>
                  </p>
                  <p>
                    Załączniki:{" "}
                    <strong>{summaryLine.attachments.join(" / ")}</strong>
                  </p>
                </Grid>
              </Box>
            )}
            {railwayLines.map((line, index) => (
              <Box key={index} my={2}>
                <Grid>
                  <div style={{ display: "none" }}>
                    <Input
                      label="id"
                      defaultValue={line.id}
                      {...register(`railwayLines.${index}.id` as const)}
                    />
                  </div>
                  <Input
                    label="Numer lini"
                    defaultValue={line.lineNumber}
                    {...register(`railwayLines.${index}.lineNumber` as const)}
                  />
                  <Controller
                    control={control}
                    name={`railwayLines.${index}.chainageStart` as const}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label="Km od"
                        defaultValue={line.chainageStart}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (
                            value == "-" ||
                            value.substr(value.length - 1) == "." ||
                            Number.isNaN(parseFloat(value))
                          )
                            field.onChange(0);
                        }}
                        onChange={(e) => {
                          field.onChange(
                            isValidFloatNumber(e.target.value)
                              ? e.target.value
                              : isNaN(parseFloat(e.target.value))
                              ? 0
                              : parseFloat(e.target.value)
                          );
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`railwayLines.${index}.chainageEnd` as const}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label="Km do"
                        defaultValue={line.chainageEnd}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (
                            value == "-" ||
                            value.substr(value.length - 1) == "." ||
                            Number.isNaN(parseFloat(value))
                          )
                            field.onChange(0);
                        }}
                        onChange={(e) => {
                          field.onChange(
                            isValidFloatNumber(e.target.value)
                              ? e.target.value
                              : isNaN(parseFloat(e.target.value))
                              ? 0
                              : parseFloat(e.target.value)
                          );
                        }}
                      />
                    )}
                  />
                  <Box>
                    <Label>załączniki</Label>
                    <Controller
                      name={`railwayLines.${index}.attachments` as const}
                      control={control}
                      render={({ field }) => (
                        <ReactSelect<{ label: string; value: string }, true>
                          {...field}
                          options={attachmentOptions}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Box>
            ))}
            {loggedUserRoles?.includes("ROLE_LINES_OVERALL_UPDATE") && (
              <Flex justifyContent="flex-end" mt={4}>
                <Button type="submit" bordered>
                  Zapisz
                </Button>
              </Flex>
            )}
          </>
        )}
      </form>
      <ConfirmModal
        handleConfirm={handleDeleteClick}
        onCancelClick={() => setIdToDelete("")}
        isOpen={!!idToDelete}
        header="Czy na pewno chcesz usuąć linię kolejową?"
        confirmBtnText="Usuń"
      />
    </>
  );
};
