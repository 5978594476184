import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
// eslint-disable-next-line import/no-unresolved
import { User } from "microsoft-graph";
import { toast } from "react-toastify";
import { GraphFolder, GraphItem } from "types/msGraph";

let graphClient: Client | undefined = undefined;

function ensureClient(authProvider: AuthCodeMSALBrowserAuthenticationProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
  }

  return graphClient;
}

export async function getUser(
  authProvider: AuthCodeMSALBrowserAuthenticationProvider
): Promise<User> {
  ensureClient(authProvider);

  const user: User = await graphClient
    ?.api("/me")
    .select("displayName,mail,mailboxSettings,userPrincipalName")
    .get();

  return user;
}

export const getFolderContents = async (
  authProvider: AuthCodeMSALBrowserAuthenticationProvider,
  folderId = "root"
): Promise<GraphFolder> => {
  ensureClient(authProvider);
  try {
    const res = await graphClient
      ?.api(
        folderId == "root" || folderId == "/drive/root:"
          ? "/me/drive/root"
          : `/me${folderId}`
      )
      .select("id,parentReference,name,webUrl,parentReference")
      .expand(
        "children($select=id,webUrl,name,fileSystemInfo,lastModifiedBy,createdBy,parentReference)"
      )
      .get();

    return parseFolder(res);
  } catch (error) {
    if (error) {
      try {
        const res = await graphClient
          ?.api("/me/drive/root")
          .select("id,parentReference,name,webUrl,parentReference")
          .expand(
            "children($select=id,webUrl,name,fileSystemInfo,lastModifiedBy,createdBy,parentReference)"
          )
          .get();

        return parseFolder(res);
      } catch (e) {
        toast.error("błąd Microsoft SharePoint");
      }
    }
  }
};

const parseFolder = (f): GraphFolder => ({
  name: f.name,
  webUrl: f.webUrl,
  parent: f.parentReference.id,
  children: parseItems(f?.children),
});

const parseItems = (i): GraphItem[] =>
  i?.map((i) => ({
    id: i.id,
    lastModifiedBy: i.lastModifiedBy.user.displayName,
    lastModifiedDateTime: i.fileSystemInfo.lastModifiedDateTime,
    name: i.name,
    webUrl: i.webUrl,
    parent: i.parentReference,
  }));
