import { Box } from "components/Box";
import { Card } from "components/Card";
import { Loader } from "components/Loader/Loader";
import { Select } from "components/Select";
import { useEffect, useState } from "react";
import { getDictSubtask, getOrStatus } from "services/dashboard";
import styled from "styled-components";
import { TaskType } from "types/task";
import ReactEcharts from "echarts-for-react";
import { Flex } from "components/Flex";
import { useForm } from "react-hook-form";

const Wrapper = styled.div`
  width: 30%;
  display: grid;
  grid-template-columns: 1fr;

  select {
    margin: 0 auto;
  }
`;

export const Label = styled.div`
  position: relative;
  line-height: 1;

  &::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const TableGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
`;
interface IData {
  current: number;
  finished: number;
  planned: number;
}
interface ICell {
  blackText?: boolean;
  boldText?: boolean;
  borderTop?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  width?: string;
}

const Cell = styled.div<ICell>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: ${({ width }) => width};
  font-weight: ${({ boldText }) => (boldText ? 700 : 400)};
  color: ${({ blackText }) =>
    blackText ? "black" : "rgba(30, 46, 102, 0.65);"};
  border-top: ${({ borderTop }) => borderTop && "1px solid lightgray"};
  border-left: ${({ borderLeft }) => borderLeft && "1px solid lightgray"};
  border-right: ${({ borderRight }) => borderRight && "1px solid lightgray"};
  border-bottom: ${({ borderBottom }) => borderBottom && "1px solid lightgray"};
`;

const TitleChart = styled.p`
  color: #005aff;
  text-align: center;
  font-weight: 700;
  margin-right: 10px;
  font-size: 20px;
`;

interface IData {
  current: number;
  finished: number;
  planned: number;
}

interface Props {
  title: string;
}

const subtasksNameByTitle = {
  SABP: ["Prace projektowe", "Decyzja PNB", "Decyzja lokalizacyjna"],
  CW: [
    "Prace budowlane (fundament, kontener i wieża) - Zgodnie z Aneksem nr 2",
    "Przyłącze energetyczne",
    "Dalsze prace budowlane (w tym zagospodarowanie terenu)",
  ],
  TI: ["Prace wdrożeniowe", "Uruchomienie"],
};

export const ORChart: React.FC<Props> = ({ title }) => {
  const [subtasksName, setSubtasksName] = useState<string[]>();
  const [data, setData] = useState<IData | null>(null);

  const { watch, register, reset } = useForm({
    defaultValues: {
      subtask: "",
    },
  });

  const { subtask: watchSubtask } = watch();

  const fetchORstatus = () =>
    getOrStatus(watchSubtask).then((res) => {
      setData(res);
    });

  useEffect(() => {
    getDictSubtask(TaskType[TaskType.OR]).then((res: any) => {
      reset({ subtask: res[0] });
      setSubtasksName(res as string[]);
    });
  }, []);

  useEffect(() => {
    fetchORstatus();
  }, [watchSubtask]);

  const getOptions = (planned: number, current: number, finished: number) => ({
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["30%", "60%"],
        label: {
          formatter: "{c}",
          position: "inside",
          color: "white",
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: planned, name: "Planowane" },
          { value: current, name: "W realizacji" },
          { value: finished, name: "Zakończone" },
        ],
        color: ["#23ABB6", "#37CC73", "#005AFF"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  });

  const renderSumValue = () => {
    const val = [data?.current, data?.finished, data?.planned];

    if (data) {
      return val.reduce((prev: any, curr: any) => prev + curr, 0).toFixed(0);
    } else {
      return 0;
    }
  };

  if (!data) return <Loader />;

  return (
    <Wrapper key="title">
      <Card>
        <Box px={4} py={3}>
          <Flex justifyContent="center">
            <TitleChart>{title}</TitleChart>
            <form>
              <Select label="Rodzaj prac" {...register("subtask")}>
                {/* {subtasksName?.map((name) => (
                  <option key={name}>{name}</option>
                ))} */}

                {subtasksNameByTitle[title]?.map((name) => (
                  <option key={name}>{name}</option>
                ))}
              </Select>
            </form>
          </Flex>

          <Box px={8}>
            <TableGrid>
              <Cell borderBottom />
              <Cell borderBottom>
                <b>liczba</b>
              </Cell>
              <Cell borderBottom>
                <Label color="#23ABB6">Planowane</Label>
              </Cell>
              <Cell borderBottom borderLeft>
                {data?.planned} szt.
              </Cell>
              <Cell borderBottom>
                <Label color="#37CC73">W realizacji</Label>
              </Cell>
              <Cell borderBottom borderLeft>
                {data?.current} szt.
              </Cell>
              <Cell borderBottom>
                <Label color="#005AFF">Zakończone</Label>
              </Cell>
              <Cell borderBottom borderLeft>
                {data?.finished} szt.
              </Cell>
              <Cell borderBottom>
                <Label color="#7D33F2">Podsumowanie</Label>
              </Cell>
              <Cell borderBottom borderLeft>
                {renderSumValue()} szt.
              </Cell>
            </TableGrid>
          </Box>
        </Box>
      </Card>
      <Box>
        <ReactEcharts
          option={getOptions(data.planned, data.current, data.finished)}
          style={{ height: 400, transform: "scale(1.5)" }}
        />
      </Box>
    </Wrapper>
  );
};
