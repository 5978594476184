import React from "react";
import { DisruptionForm } from "containers/DisruptionForm";
import { PageLayout } from "components/PageLayout";
import { Card } from "components/Card";
import { Box } from "components/Box";

export const AddDisruptionView: React.FC = () => {
  return (
    <PageLayout pageTitle="Dodaj zakłócenie" isScroll>
      <Card>
        <Box px={8} py={6}>
          <DisruptionForm />
        </Box>
      </Card>
    </PageLayout>
  );
};
