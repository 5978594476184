import { IRoute } from "types/route";
import { AddDisruptionView } from "views/AddDisruption";
import { EditDisruptionView } from "views/EditDisruption";
import { DisruptionsView } from "views/Disruptions";
import { HomeView } from "views/Home";
import { TaskView } from "views/Task";
import { TasksTypeView } from "views/TaskTypeView/TasksType";
import { LoginView } from "views/LoginView";
import { match } from "react-router-dom";
import { useTaskContext } from "contexts/TaskContext";
import { useDisruptionContext } from "contexts/DisruptionContext";
import { NotificationsView } from "views/Notifications";
import { ReportsView } from "views/Reports";
import { Charts } from "views/Charts";
import { Backlog } from "views/Backlog";
import { SettingsView } from "views/Settings";
import { RailwayLinesView } from "views/RailwayLines";
import { AddRailwayLineView } from "views/AddRailwayLinesView";
import { DashboardView } from "views/Dashboard";
import { RailwayLineView } from "./views/RailwayLine";
import { useRailwayLineContext } from "./contexts/RailwayLineContext";
import { UserActivityView } from "views/UserActivity";
import { TaskNameView } from "views/TaskName";
import { GeneralCostView } from "views/GeneralCost";
import Statistics from "views/Statistics";
import PermissionsView from "views/PermissionsView/PermissionsView";
import TagsView from "views/TagsView/TagsView";
import UsersView from "views/UsersView/UsersView";
import { PartnersView } from "views/PartnersView/PartnersView";
import { ImportExportPanelView } from "views/ImportExportPanelView/ImportExportPanelView";
import NewImportView from "views/NewImportView/NewImportView";
import DictionaryView from "./views/DictionaryView/DictionaryView";
import { KeysView } from "views/Keys/KeysView";
import { AddKeyView } from "views/Keys/AddKeyView";
import { KeyView } from "views/Keys/KeyView";
import { KeyPublicationView } from "views/Keys/KeyPublicationView";
import { KeyPublicationReturnView } from "views/Keys/KeyPublicationReturnView";
import { KeyPublicationConfirmView } from "views/Keys/KeyPublicationConfirmView";
import { KeysGivenView } from "./views/Keys/KeysGivenView";
import { KeysReturnsView } from "views/Keys/KeysReturnsView";
import { ChangeUsersPasswordView } from "views/UsersView/ChangeUsersPasswordView";

// Route config based on:
// https://medium.com/@michael.kutateladze/react-router-with-a-single-config-file-61777f306b4f

const DisruptionBreadcrumbs: React.FC<{
  match: match<{ disruptionId: string }>;
}> = ({ match }) => {
  const { number } = useDisruptionContext();
  return <span>{number ?? match.params.disruptionId}</span>;
};

const TaskBreadcrumb: React.FC<{ match: match<{ taskId: string }> }> = ({
  match,
}) => {
  const { locationName } = useTaskContext();
  return <span>{locationName ?? match.params.taskId}</span>;
};

const RailwayLineBreadcrumb: React.FC<{
  match: match<{ lineNumber: string }>;
}> = ({ match }) => {
  const { lineNumber } = useRailwayLineContext();
  return <span>{lineNumber ?? match.params.lineNumber}</span>;
};

export const routes: Array<IRoute> = [
  {
    path: "/login",
    exact: true,
    component: LoginView,
  },
  {
    path: "/",
    exact: true,
    breadcrumb: "Task Manager",
    component: HomeView,
    private: true,
  },
  {
    path: "/dashboard",
    exact: true,
    breadcrumb: "Dashboard",
    component: DashboardView,
    private: true,
  },
  {
    path: "/objects/disruptions",
    exact: true,
    component: DisruptionsView,
    breadcrumb: "Disruptions",
    private: true,
  },
  {
    path: "/objects/disruptions/add",
    exact: true,
    component: AddDisruptionView,
    breadcrumb: "Add disruption",
    private: true,
  },
  {
    path: "/objects/disruptions/:disruptionId",
    exact: true,
    component: EditDisruptionView,
    private: true,
    breadcrumb: DisruptionBreadcrumbs,
  },
  {
    path: "/objects/charts",
    exact: true,
    component: Charts,
    private: true,
  },
  {
    path: "/objects/railway-lines",
    exact: true,
    component: RailwayLinesView,
    private: true,
  },
  {
    path: "/objects/railway-lines/item/:id?",
    exact: true,
    component: AddRailwayLineView,
    breadcrumb: "Add railway line",
    private: true,
  },
  {
    path: "/objects/railway-lines/:lineNumber",
    exact: true,
    component: RailwayLineView,
    private: true,
    breadcrumb: RailwayLineBreadcrumb,
  },
  {
    path: "/objects/:type",
    exact: true,
    component: TasksTypeView,
    private: true,
  },
  {
    path: "/objects/:type/:taskId",
    exact: true,
    component: TaskView,
    private: true,
    breadcrumb: TaskBreadcrumb,
  },
  {
    path: "/objects/:type/:taskId/:taskName",
    exact: true,
    component: TaskNameView,
    private: true,
  },
  {
    path: "/keys",
    exact: true,
    component: KeysView,
    private: true,
  },
  {
    path: "/keys-given",
    exact: true,
    component: KeysGivenView,
    private: true,
  },
  {
    path: "/keys-returns",
    exact: true,
    component: KeysReturnsView,
    private: true,
  },
  {
    path: "/keys/add",
    exact: true,
    component: AddKeyView,
    breadcrumb: "Add key",
    private: true,
  },
  {
    path: "/keys/:id",
    exact: true,
    component: KeyView,
    breadcrumb: "Key",
    private: true,
  },
  {
    path: "/keys-publication",
    exact: true,
    component: KeyPublicationView,
    breadcrumb: "Key publication",
    private: true,
  },
  {
    path: "/keys-publication/:id",
    exact: true,
    component: KeyPublicationView,
    breadcrumb: "Key publication",
    private: true,
  },
  {
    path: "/keys/:id/publication",
    exact: true,
    component: KeyPublicationView,
    breadcrumb: "Key publication",
    private: true,
  },
  {
    path: "/keys/:id/publication/edit/:givenId/:requestId",
    exact: true,
    component: KeyPublicationView,
    breadcrumb: "Key publication edit",
    private: true,
  },
  {
    path: "/keys/:id/return/:givenRequestId",
    exact: true,
    component: KeyPublicationReturnView,
    breadcrumb: "Key publication",
    private: true,
  },
  {
    path: "/keys/:id/return/edit/:returnRequestId/:returnId",
    exact: true,
    component: KeyPublicationReturnView,
    breadcrumb: "Key publication edit",
    private: true,
  },
  {
    path: "/keys/publication-confirm/:linkId",
    exact: true,
    component: KeyPublicationConfirmView,
    breadcrumb: "Key publication",
    private: true,
  },
  {
    path: "/generalCost",
    exact: true,
    component: GeneralCostView,
    private: true,
  },
  {
    path: "/reports",
    exact: true,
    component: ReportsView,
    private: true,
  },
  {
    path: "/notifications",
    exact: true,
    component: NotificationsView,
    private: true,
  },
  {
    path: "/backlog",
    exact: true,
    component: Backlog,
    private: true,
  },
  {
    path: "/settings",
    exact: true,
    component: SettingsView,
    private: true,
  },
  {
    path: "/userActivity",
    exact: true,
    component: UserActivityView,
    private: true,
  },
  {
    path: "/statystyki",
    exact: true,
    component: Statistics,
    private: true,
  },
  {
    path: "/admin/permissions",
    exact: true,
    component: PermissionsView,
    private: true,
  },
  {
    path: "/admin/dictionary",
    exact: true,
    component: DictionaryView,
    private: true,
  },
  {
    path: "/admin/tags",
    exact: true,
    component: TagsView,
    private: true,
  },
  {
    path: "/admin/users",
    exact: true,
    component: UsersView,
    private: true,
  },
  {
    path: "/admin/users/change-password/:id",
    exact: true,
    component: ChangeUsersPasswordView,
    private: true,
  },
  {
    path: "/admin/partners",
    exact: true,
    component: PartnersView,
    private: true,
  },
  {
    path: "/import-export-panel/:actionType/:type",
    exact: true,
    component: ImportExportPanelView,
    private: true,
  },
  {
    path: "/import-export-panel/new/import/:type",
    exact: true,
    component: NewImportView,
    private: true,
  },
];
