import { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input = styled.input`
  ${({ theme }) => `
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;

    &:checked {
      + ${Control} {
        border: 3px solid ${theme.palette.primary};
      }
    }
  `}
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
`;

interface IControl {
  disabled?: boolean;
}

const Control = styled.div<IControl>`
  ${({ theme, disabled }) => `
    flex: 0 0 auto;
    display: block;
    height: 16px;
    width: 16px;
    margin-right: ${theme.space[2]}px;
    border-radius: 4px;
    background-color: ${theme.palette.neutral.white};
    border: 1px solid rgba(30,46,102,0.15);
    ${disabled ? "background-color: #dadada" : ""}
  `}
`;

export const Checkbox: React.FC<ICheckbox> = ({
  className,
  label,
  ...props
}) => (
  <Label className={className}>
    <Input {...props} type="checkbox" />
    <Control disabled={props.disabled} />
    {label}
  </Label>
);
