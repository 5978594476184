import styled from "styled-components";

export const Label = styled.label`
  ${({ theme }) => `
    display: table;
    margin-left: ${theme.space[3]}px;
    padding-left: ${theme.space[1]}px;
    position: relative;
    color: rgba(19, 19, 21, 0.65);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 15px;
    background-color: ${theme.palette.neutral.white};
  `};
`;
