import { TaskType } from "types/task";
import { fetchAPI } from "utilities/fetchAPI";

export const getOTKStatus = (subtask: string) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/chart/dashboard/otk-status?subtask=${subtask}`
  );
};

export const getDictSubtask = (taskType: TaskType) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/dict/task/${taskType}/subtask-names`
  );
};

interface IData {
  current: number;
  finished: number;
  planned: number;
}
export const getOrStatus = (subtask: string) => 
  fetchAPI<IData>(`${process.env.REACT_APP_API_URL}/chart/dashboard/or-status?subtask=${subtask}`);

export const getFDSstatus = (subtask: string) => 
  fetchAPI<IData>(`${process.env.REACT_APP_API_URL}/chart/dashboard/fds-status?subtask=${subtask}`)

export const getOTKoptions = () => 
  fetchAPI<string[]>(`${process.env.REACT_APP_API_URL}/chart/dashboard/otk-status/options`);

export interface IPlanningBars {
    actual: number;
    forecast: number;
    label: string;
  }

export const getPlaningChart = (actualDate: string, forecastDate: string, taskType: string, group?: string) =>
  fetchAPI<IPlanningBars>(`${process.env.REACT_APP_API_URL}/chart/dashboard/planning-bars/${actualDate}/${forecastDate}?task=${taskType}&group=${group}`);

export interface ICertficationResponse {
  label: string;
  value: string;
}

export const getCertificationChart = (dateFrom: string, dateTo: string, task?: string, group?: "day" | "month") => 
    fetchAPI<ICertficationResponse[]>(`${process.env.REACT_APP_API_URL}/chart/dashboard/certyfikacja-bars/${dateFrom}/${dateTo}?task=${task}&group=${group}`);