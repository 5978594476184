import styled from "styled-components";
import { Flex } from "components/Flex";
import { IDisruptionPaymentsSummary } from "services/disruptions";

interface IProps {
  data: IDisruptionPaymentsSummary[];
}

const Root = styled(Flex)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[5]}px;
    border-radius: ${theme.space[2]}px;
    font-weight: 700;
    letter-spacing: 1px;
    background: ${theme.palette.background};
    overflow: auto;
  `}
`;

const SummaryContainer = styled(Flex)`
  ${({ theme }) => `
    border-radius: ${theme.space[2]}px;
    margin: ${theme.space[2]}px;
    background-color: white;
    min-width: 160px;
    padding-bottom: ${theme.space[1]}px;
  `}
`;

const Title = styled.h4`
  ${({ theme }) => `
    margin: ${theme.space[2]}px;
    text-align: center;
  `}
`;

const Text = styled.p`
  ${({ theme }) => `
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 ${theme.space[3]}px 0 ${theme.space[3]}px;
  `}
`;

export const DisruptionPaymentsSummary: React.FC<IProps> = ({ data }) => {
  return (
    <Root>
      {data.map(({ partner, summary }) => (
        <SummaryContainer flexDirection="column" key={partner.id}>
          <Title>{partner.name.toUpperCase()}</Title>

          <Flex justifyContent="space-between">
            <Text>Ilość</Text>
            <Text>{summary.units} szt.</Text>
          </Flex>

          <Flex justifyContent="space-between">
            <Text>Suma</Text>
            <Text>{summary.sum} PLN</Text>
          </Flex>
        </SummaryContainer>
      ))}
    </Root>
  );
};
