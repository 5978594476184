import { useState } from "react";
import styled from "styled-components";
import { Box } from "./Box";

const StyledArea = styled(Box)`
  ${({ theme }) => `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border: 1px dashed rgba(30, 46, 102, 0.15);
    border-radius: ${theme.shape.borderRadius};
    font-size: 12px;
    color: lightgrey;
  `}
`;

const Label = styled.label`
  ${({ theme }) => `
    position: absolute;
    top: -8px;
    left: 16px;
    color: rgba(19, 19, 21, 0.65);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 15px;
    background-color: ${theme.palette.neutral.white};
  `};
`;

interface IDropArea {
  label: string;
}

export const DropArea: React.FC<IDropArea> = ({ label }) => {
  const [data, setData] = useState({} as any);
  const [error, setError] = useState("");
  const fileReader = new FileReader();

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const {
      dataTransfer: { files },
    } = event;
    // If there no file return
    if (files.length === 0) return;
    // If size of file more than 2MB return
    if (files[0].size / 1024 / 1024 > 2) {
      setError("File size exceeded the limit of 2MB");
      return false;
    }
    // Read and set filt into data variable
    // TODO
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      setData(fileReader?.result);
    };
  };
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <StyledArea
      onDrop={(e) => onDrop(e)}
      onDragStart={(e) => onDragStart(e)}
      onDragOver={(e) => onDragOver(e)}
    >
      <Label>{label}</Label>
    </StyledArea>
  );
};
