import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "components/PageLayout";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import styled from "styled-components";
import {
  useTableSidebarDispatchContext,
  useTableSidebarContext,
} from "contexts/TableSidebarContext";
import { KeyPublicationReturnForm } from "containers/KeyPublicationReturnForm/KeyPublicationReturnForm";

const Root = styled(Flex)`
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
`;
const StyledBox = styled(Box)`
  height: calc(100% - 60px);
  width: 100%;
  background-color: #fff;
  border: 20px solid #f4f5f8;
  border-bottom: 0;
  overflow: auto;
`;
const StyledCard = styled(Card)`
  overflow: auto;
  width: 100%;
  background-color: #f4f5f8;
`;

export const KeyPublicationReturnView = () => {
  const { id, givenRequestId, returnRequestId, returnId } = useParams<{
    id: string;
    givenRequestId: string;
    returnRequestId: string;
    returnId: string;
  }>();

  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();

  useEffect(() => {
    setTimeout(() => {
      setTableData({
        ...tableData,
        detailsObject: false,
      });
    });
  }, []);

  return (
    <PageLayout>
      <Root justifyContent="space-between">
        <StyledCard>
          <StyledBox px={8} py={6}>
            <KeyPublicationReturnForm
              givenRequestId={givenRequestId}
              returnRequestId={returnRequestId && returnRequestId}
              returnId={returnId && returnId}
            />
          </StyledBox>
        </StyledCard>
      </Root>
    </PageLayout>
  );
};
