import React, { useContext, useState } from "react";

export interface IDisruptionContext {
  number: string | null;
}

export interface IDisruptionDispatchContext {
  setNumber: (number: string | null) => void;
}

const DisruptionContext = React.createContext<IDisruptionContext | undefined>(
  undefined
);

const DisruptionDispatchContext = React.createContext<
  IDisruptionDispatchContext | undefined
>(undefined);

export const DisruptionContextProvider: React.FC = ({ children }) => {
  const [number, setNumber] = useState<string | null>(null);

  return (
    <DisruptionContext.Provider value={{ number }}>
      <DisruptionDispatchContext.Provider
        value={{
          setNumber,
        }}
      >
        {children}
      </DisruptionDispatchContext.Provider>
    </DisruptionContext.Provider>
  );
};

export const useDisruptionContext = () => {
  const context = useContext(DisruptionContext);
  if (!context) {
    throw new Error(
      "useDisruptionContext must be inside a DisruptionContextProvider with a value"
    );
  }

  return context;
};

export const useDisruptionDispatchContext = () => {
  const context = useContext(DisruptionDispatchContext);
  if (!context) {
    throw new Error(
      "useDisruptionDispatchContext must be inside a DisruptionDispatchProvider with a value"
    );
  }

  return context;
};
