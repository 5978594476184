import { http, httpFiles } from "./http";

export const fetchAPI = async <T>(url: string, options?: RequestInit) =>
  http<T>(url, {
    ...options,
    headers: {
      ...options?.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

export const fetchFilesAPI = async (url: string, options?: RequestInit) =>
  httpFiles(url, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
