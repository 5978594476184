import React, { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { Column, Row } from "react-table";
import { Flex } from "./Flex";
import { Box } from "./Box";
import { Button } from "./Button";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageLayout } from "./PageLayout";
import { Loader } from "./Loader/Loader";
import { fetchDisruption } from "../services/disruptions";
import { TableType } from "../types/tableType";
import { IDisruption } from "../types/disruption";
import { useSortableData } from "hooks/useSortableData";

const stickyCellZebraWhite = " sticky-cell zebra-white corner-cell";
const headerStyles = { borderBottom: "1px solid #ddd", fontWeight: "bold" };

interface PropsType {
  columnList?: {
    name: string;
    width: number;
    minWidth?: number;
    value: any;
    textRight?: boolean;
    disableClickRow?: boolean;
  }[];
  data?: any;
  onTableScroll?: any;
  typeParam?: any;
  clickTableRow?: any;
  colVis?: any;
  customRowList?: any;
  showLongText?: any;
  editItemIndex?: any;
  smallCell?: boolean;
  percentageWidth?: boolean;
  windowHeight?: boolean;
}

export const CustomTable = ({
  columnList, // Object describing table column width and values
  data, // The fetched data object
  onTableScroll, // Table scroll handler
  typeParam, // Used to identify type of task (OR,ORR, OTK, etc)
  clickTableRow,
  colVis, // Array of column visiblity states (true|false)[]
  customRowList,
  showLongText,
  smallCell,
  editItemIndex,
  percentageWidth = false,
  windowHeight = true,
}: PropsType) => {
  const [currentRowIndex, setCurrentRowIndex] = useState(-1);

  const { sortedItems, requestSort, sortConfig } = useSortableData(data);

  const tableCell = (
    columnIndex: number,
    rowIndex: number,
    sortedItems: any
  ) => {
    if (sortedItems && columnList[columnIndex])
      return (
        <span
          style={
            columnList[columnIndex].textRight
              ? {
                  display: "block",
                  textAlign: "end",
                  paddingRight: "4px",
                }
              : {}
          }
          key={`key-${columnIndex}`}
        >
          {columnList[columnIndex].value({ ...sortedItems, rowIndex })}
        </span>
      );
    else return <span key={`key-${columnIndex}`}>n/a</span>;
  };

  const TableHeader = () => {
    return (
      <>
        {columnList.map((column: any, index: number) => {
          return colVis[index] ? (
            <div
              key={`key-vis${index}`}
              style={{
                minWidth: `${column.minWidth || column.width}px`,
                width: `${column.width}${percentageWidth ? "%" : "px"}`,
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                background: "white",
              }}
              className={`table-column${
                index === 0 ? stickyCellZebraWhite : ""
              }`}
              onClick={() => requestSort(column.idName)}
            >
              <Flex>
                {column.name}

                {column.idName && sortConfig?.key === column.idName
                  ? sortConfig?.direction === "ascending"
                    ? "▲"
                    : "▼"
                  : undefined}
              </Flex>
            </div>
          ) : null;
        })}
      </>
    );
  };

  // Single table row
  const Row = (props: any) => {
    const {
      entry, // IDisruption (for example) *must have entry.id
      index, // Row index
      i, // .map()'s index
    } = props;

    let className = `table-cell ${
      index % 2 == 0 ? " zebra-stripe" : " zebra-white"
    } ${props.i === 0 ? ` sticky-cell sticky-shadow` : ""} ${
      smallCell && " table-cell-small"
    }`;

    if (currentRowIndex === index) className += " selected";

    return colVis[i] ? (
      <div
        key={`cell-key-${i}`}
        style={{
          padding: i == 0 ? 0 : "",
          minWidth: `${columnList[i].minWidth || columnList[i].width}px`,
          width: `${columnList[i].width}${percentageWidth ? "%" : "px"}`,
          whiteSpace: showLongText ? "normal" : "nowrap",
        }}
        onClick={() => {
          if (i != 0 && !columnList[i].disableClickRow) {
            // Avoid going to sidebar info on first column
            setCurrentRowIndex(index);
            clickTableRow && clickTableRow(entry, typeParam ? typeParam : null);
          }
        }}
        className={className}
      >
        {tableCell(i, index, entry)}
      </div>
    ) : null;
  };

  // List of <Row />'s
  const TableRows = () => {
    return (
      <>
        {editItemIndex === null && customRowList && (
          <div className={`cell-wrapper`}>
            {customRowList.map((c: any, i: number) => (
              <div
                key={`cell-key-${i}`}
                style={{
                  padding: i == 0 ? 0 : "",
                  minWidth: `${columnList[i].width}px`,
                }}
                className="table-cell zebra-white sticky-cell sticky-shadow"
              >
                <span>{customRowList[i].value({})}</span>
              </div>
            ))}
          </div>
        )}

        {sortedItems.length
          ? sortedItems.map((entry: any, index: number) => {
              return (
                <div key={`key${index}`} className={`cell-wrapper`}>
                  {editItemIndex == index
                    ? customRowList &&
                      customRowList.map((c: any, i: number) => (
                        <div
                          key={`cell-key-${i}`}
                          style={{
                            padding: i == 0 ? 0 : "",
                            minWidth: `${columnList[i].width}px`,
                          }}
                          className="table-cell zebra-white sticky-cell sticky-shadow"
                        >
                          <span>
                            {customRowList[i].value({
                              rowIndex: index,
                              ...entry,
                            })}
                          </span>
                        </div>
                      ))
                    : columnList.map((c: any, i: number) => {
                        return (
                          <Row
                            key={`row-key-${i}`}
                            i={i}
                            index={index}
                            entry={entry}
                          />
                        );
                      })}
                </div>
              );
            })
          : null}
      </>
    );
  };

  const tableRef = useRef(null);

  return (
    <>
      {sortedItems !== undefined && sortedItems.length > 0 ? (
        <>
          <div
            className="tbc"
            ref={tableRef}
            style={
              windowHeight
                ? { maxHeight: "calc(100% - 100px)" }
                : { display: "table" }
            }
            onScroll={onTableScroll ? (e) => onTableScroll(e, tableRef) : null}
          >
            <div className="twh">
              <TableHeader />
            </div>
            <div className="twr">
              <TableRows />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
