export const API_URL = process.env.REACT_APP_API_URL;

export const DISRUPTION_PARTNERS_URL = `${API_URL}/dict/partners`;
export const DISRUPTION_CATEGORIES_URL = `${API_URL}/disruption-categories`;
export const DISRUPTION_STATUSES_URL = `${API_URL}/disruption-statuses`;
export const DICTIONARY_PARTNERS_URL = `${API_URL}/dict/partners`;
export const DICTIONARY_CONTRACTORS_URL = `${API_URL}/dict/task/contractors`;
export const DICTIONARY_LINES_URL = `${API_URL}/dict/lines`;
export const DICTIONARY_IZS_URL = `${API_URL}/dict/izs`;
export const DICTIONARY_UNIT_COSTS_DICT = `${API_URL}/dict/unit_costs`;
export const DICTIONARY_UNIT_COSTS_DICT_CATEGORIES = `${API_URL}/dict/unit_costs/categories`;
export const DICTIONARY_TAGS_URL = `${API_URL}/dict/tag`;
export const DICTIONARY_USERS_URL = `${API_URL}/user`;
export const DICTIONARY_PAYMENT_SCOPE = `${API_URL}/dict/task/or/payment/scope`;
export const DICTIONARY_PAYMENT_TASK = `${API_URL}/dict/task/or/payment/task`;
export const DICTIONARY_OVERHEAD_COST = `${API_URL}/disruption-payment/overhead-cost`;
export const CREATE_DISRUPTION_PAYMENTS_URL = `${API_URL}/disruption-payments`;
export const DICTIONARY_KO_SIGNING_PERSON = `${API_URL}/mrw/ko-settings/`;
export const DICTIONARY_POSITION_TYPES = `${API_URL}/dict/task/position-types`;
export const DICTIONARY_PROBLEM_TYPES_URL = `${API_URL}/disruptions/filters/filter-problem-types-list`;

export const OPERATION_ON_DISRUPTION_PAYMENT = (disruptionPaymentId: string) =>
  `${API_URL}/disruption-payments/${disruptionPaymentId}`;
export const DISRUPTION_PAYMENTS_URL = (disruptionId: string) =>
  `${API_URL}/disruption-payments/${disruptionId}`;

export const getUnitCostsPerCategory = (categories: string) =>
  `${API_URL}/dict/unit_costs/%5B${categories
    ?.split(", ")
    .map(
      (item, i) =>
        `%22${item}%22${i !== categories.split(", ").length - 1 ? "%2C" : ""}`
    )
    .join("")}%5D/per-category`;

export const getDisruptionPath = (disruptionId: string) =>
  `${process.env.REACT_APP_API_URL}/disruptions/${disruptionId}`;

export const getChartLineStatus = (id: number) =>
  `${API_URL}/chart/dashboard/line-status/${id}`;

export const dateTwoUrl = (taskUuid: string, type: string) =>
  `${API_URL}/task/${taskUuid}/dates/dates-2/${type}`;
export const updateDateTwoUrl = (taskMetaUuid: string) =>
  `${API_URL}/task-meta/${taskMetaUuid}`;

export const dateTwoSABPFormStructureUrl = (taskUuid: string) =>
  `${API_URL}/form/herkules/sabp/${taskUuid}/structure`;

export const dateTwoSABPFormSectionUrl = (
  taskUuid: string,
  sectionName: string
) => `${API_URL}/form/herkules/sabp/${taskUuid}/section/${sectionName}`;

export const dateTwoUrlTI = (taskUuid: string) =>
  `${API_URL}/task/${taskUuid}/dates/dates-2/TI`;

export const getPossibleImpacts = (id: string) =>
  `${API_URL}/task/${id}/issue/possible-impacts`;

export const DISRUPTION_PAYMENTS_SUMMARY_URL = (disruptionId: string) =>
  `${API_URL}/disruption-payments/${disruptionId}/summary`;

export const LOGGED_USER_ROLES = `${API_URL}/user/logged-user/roles`;
