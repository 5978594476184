import React, { useEffect, useRef, useState } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Checkbox } from "components/Checkbox";
import {
  deleteKeyAttachment,
  downloadKeyAttachment,
  fetchKeyGivenAttachments,
  fetchKeySingleGiven,
  postKeyGiveRequest,
  updateKeyGiveRequest,
  uploadKeyAttachment,
} from "services/keys";
import { SearchKeyOR } from "components/SearchKeyOR";
import { IKeyGivenAttachments } from "types/keys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { downloadFile } from "utilities/downloadFile";
import styled from "styled-components";

const StyledFileFlex = styled(Flex)`
  border-radius: 5px;
  border: ${({ theme }) => theme.palette.grey} 2px solid;
`;

interface PropsType {
  givenId?: string;
  requestId?: string;
}

export const KeyPublicationsForm = ({ givenId, requestId }: PropsType) => {
  const [isShipment, setIsShipment] = useState(true);
  const [finalORs, setFinalORs] = useState<
    Array<{ key: string; quantity: string }>
  >([]);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>();
  const [oldFiles, setOldFiles] = useState<IKeyGivenAttachments[]>();

  const { register, handleSubmit, reset, getValues, watch, control, setValue } =
    useForm();

  const history = useHistory();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadKeyFileForm = (file: File, keyGivenRequest: string) => {
    const data = new FormData();

    data.append("file", file);
    data.append("keyGivenRequest", keyGivenRequest);

    uploadKeyAttachment(data).catch(() =>
      toast.error("Nie udało się wgrać pliku.")
    );
  };

  const onsubmit = (data) => {
    let keyData: keyof typeof data;
    for (keyData in data) {
      if (data[keyData] === undefined) delete data[keyData];
    }

    if (givenId) {
      const reqPayload = {
        recipientName: data.recipientName,
        contractor: data.contractor,
        recipientEmail: data.recipientEmail,
        shipment: isShipment,
        shippingAddress: isShipment
          ? {
              city: data.city,
              zipCode: data.zipCode,
              street: data.street,
              houseNumber: data.houseNumber,
              phoneNumber: data.phoneNumber,
            }
          : null,
      };

      updateKeyGiveRequest({ requestId, payload: reqPayload })
        .then((response) => {
          if (response.id) {
            if (requestId) {
              uploadedFiles?.map((uploadedFile) => {
                uploadKeyFileForm(uploadedFile, requestId);
              });
            }

            history.push("/keys-given");
            reset();

            toast.success("Edytowano wydanie kluczy.");
          } else {
            toast.error("Nie udało się edytować wydania kluczy.");
          }
        })
        .catch(() => {
          toast.error("Nie udało się edytować wydania kluczy.");
        });
    } else {
      const reqPayload = {
        recipientName: data.recipientName,
        contractor: data.contractor,
        recipientEmail: data.recipientEmail,
        shipment: isShipment,
        shippingAddress: isShipment
          ? {
              city: data.city,
              zipCode: data.zipCode,
              street: data.street,
              houseNumber: data.houseNumber,
              phoneNumber: data.phoneNumber,
            }
          : null,
        items: finalORs,
      };

      postKeyGiveRequest({ payload: reqPayload })
        .then((response) => {
          if (response.id) {
            uploadedFiles?.map((uploadedFile) => {
              uploadKeyFileForm(uploadedFile, response.id);
            });

            history.push("/keys-given");
            reset();

            toast.success("Wydano klucze.");
          } else {
            toast.error("Nie udało się wydać kluczy.");
          }
        })
        .catch(() => {
          toast.error("Nie udało się wydać kluczy.");
        });
    }
  };

  useEffect(() => {
    givenId &&
      fetchKeySingleGiven({ keyGivenId: givenId }).then((data) => {
        reset({ ...data, ...data.shippingAddress });
        setIsShipment(data.shipment);
      });
  }, [givenId, reset]);

  useEffect(() => {
    requestId &&
      fetchKeyGivenAttachments({ keyRequestId: requestId }).then((data) => {
        setOldFiles(data);
      });
  }, [requestId]);

  return (
    <>
      <Button
        bordered
        onClick={() => {
          history.push(givenId ? "/keys-given" : "/keys");
        }}
      >
        Wróć
      </Button>
      <form onSubmit={handleSubmit(onsubmit)}>
        <Flex justifyContent="flex-start" alignItems="center" p={10} pr={20}>
          <Box mr={2}>
            <Input label="Wykonawca" {...register("contractor")} />
          </Box>
          <Box mr={2}>
            <Input label="Osoba" {...register("recipientName")} />
          </Box>
        </Flex>

        {!givenId && (
          <SearchKeyOR
            type="publication"
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            register={register}
            setFinalORs={setFinalORs}
          />
        )}

        <Flex pl={10}>
          <Box mr={2}>
            <Checkbox
              label="Odbiór osobisty"
              checked={!isShipment}
              onChange={() => {
                setIsShipment((prevState) => !prevState);
              }}
            />
          </Box>
          <Checkbox
            label="Wysyłka"
            checked={isShipment}
            onChange={() => {
              setIsShipment((prevState) => !prevState);
            }}
          />
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          p={10}
          pr={20}
          mb={10}
          flexWrap="wrap"
        >
          <Box m={2}>
            <Input label="Mail" {...register("recipientEmail")} />
          </Box>
          {isShipment && (
            <>
              <Box m={2}>
                <Input label="Miasto" {...register("city")} />
              </Box>
              <Box m={2}>
                <Input label="Kod pocztowy" {...register("zipCode")} />
              </Box>
              <Box m={2}>
                <Input label="Ulica" {...register("street")} />
              </Box>
              <Box m={2}>
                <Input
                  label="Nr domu / nr mieszkania"
                  {...register("houseNumber")}
                />
              </Box>
              <Box m={2}>
                <Input
                  label="Telefon (opcjonalnie)"
                  {...register("phoneNumber")}
                />
              </Box>
            </>
          )}
        </Flex>

        <Flex>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(event) => {
              if (event.target.files) {
                setUploadedFiles(Array.from(event.target.files));
              }
            }}
          />

          <Flex mt={1} flexWrap="wrap">
            {uploadedFiles?.map((file) => (
              <StyledFileFlex key={file.name} pl={2} mr={3} alignItems="center">
                {file.name}
                <Button
                  type="button"
                  onClick={() => {
                    setUploadedFiles((prevUploadedFiles) =>
                      prevUploadedFiles.filter(
                        (prevUploadedFile) =>
                          prevUploadedFile.name !== file.name
                      )
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </StyledFileFlex>
            ))}

            {oldFiles?.map((file) => (
              <StyledFileFlex key={file?.id} pl={2} mr={3} alignItems="center">
                {file?.fileName}
                <Button
                  type="button"
                  onClick={() => {
                    downloadKeyAttachment(file?.id)
                      .then((response) => response.blob())
                      .then((blob) => {
                        downloadFile(blob, file?.fileName);
                      })
                      .catch(() => toast.error("Nie udało się pobrać pliku."));
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    deleteKeyAttachment(file?.id)
                      .then(() => {
                        setOldFiles((prevOldFiles) =>
                          prevOldFiles.filter(
                            (prevOldFile) => prevOldFile.id !== file?.id
                          )
                        );
                      })
                      .catch(() => toast.error("Nie udało się usunąć pliku."));
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </StyledFileFlex>
            ))}

            <Button
              type="button"
              bordered
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current?.click();
              }}
            >
              Wgraj pliki
            </Button>
          </Flex>
        </Flex>

        <Flex justifyContent="flex-end" mr={10} mt={2}>
          <Button type="submit" bordered>
            Zapisz
          </Button>
        </Flex>
      </form>
    </>
  );
};
