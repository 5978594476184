import React, { useContext, useState } from "react";

export interface IFilters {
  code: string;
  submittingEntity: string[];
  affectedEntity: string[];
  category: string[];
  status: string[];
  iz: string[];
  tags: string[];
  startDateFrom: string;
  startDateTo: string;
  endDateFrom: string;
  endDateTo: string;
  submissionDateFrom: string;
  submissionDateTo: string;
  endDocumentSubmissionDate: string;
  endDocumentSubmissionDateFrom: string;
  endDocumentSubmissionDateTo: string;
  continuationSearch: string;
  assignedUser: string[];
  problems: string[];
  problemType: string[];
  scopeSearch?: string;
  lineNumber: number[];
}

export interface IDisruptionsStateContext {
  initialFilters: IFilters;
  filters: IFilters;
}

export interface IDisruptionsDispatchContext {
  setFilters: (filters: IFilters) => void;
  cleanFilters: () => void;
}

const DisruptionsStateContext = React.createContext<
  IDisruptionsStateContext | undefined
>(undefined);

const DisruptionsDispatchContext = React.createContext<
  IDisruptionsDispatchContext | undefined
>(undefined);

export const DisruptionsContextProvider: React.FC = ({ children }) => {
  const initialFilters: IFilters = {
    code: "",
    submittingEntity: [],
    affectedEntity: [],
    category: [],
    status: [],
    iz: [],
    tags: [],
    startDateFrom: "",
    startDateTo: "",
    endDateFrom: "",
    endDateTo: "",
    submissionDateFrom: "",
    submissionDateTo: "",
    endDocumentSubmissionDate: "",
    endDocumentSubmissionDateFrom: "",
    endDocumentSubmissionDateTo: "",
    continuationSearch: "",
    assignedUser: [],
    problems: [],
    problemType: [],
    scopeSearch: "",
    lineNumber: [],
  };
  const [filters, setFilters] = useState<IFilters>(initialFilters);

  const cleanFilters = () => {
    setFilters(initialFilters);
  };

  return (
    <DisruptionsStateContext.Provider value={{ filters, initialFilters }}>
      <DisruptionsDispatchContext.Provider
        value={{
          setFilters,
          cleanFilters,
        }}
      >
        {children}
      </DisruptionsDispatchContext.Provider>
    </DisruptionsStateContext.Provider>
  );
};

export const useDisruptionsStateContext = () => {
  const context = useContext(DisruptionsStateContext);
  if (!context) {
    throw new Error(
      "useDisruptionsStateContext must be inside a DisruptionsStateContextProvider with a value"
    );
  }
  return context;
};

export const useDisruptionsDispatchContext = () => {
  const context = useContext(DisruptionsDispatchContext);
  if (!context) {
    throw new Error(
      "useDisruptionsDispatchContext must be inside a DisruptionsStateDispatchProvider with a value"
    );
  }
  return context;
};
