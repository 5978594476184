import { forwardRef } from "react";
import styled from "styled-components";

interface IField extends React.InputHTMLAttributes<HTMLInputElement> {
  textarea?: boolean;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  border?: boolean;
  onIconClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  disabled?: boolean;
}

const Root = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => `
    ${fullWidth ? "width: 100%;" : ""}
    flex: 0 0 auto;
    position: relative;
  `};
`;

const Label = styled.label`
  ${({ theme }) => `
    display: table;
    // margin-bottom: -${theme.space[1]}px;
    margin-left: ${theme.space[3]}px;
    padding-left: ${theme.space[1]}px;
    position: relative;
    color: rgba(19, 19, 21, 0.65);
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 15px;
  `};
`;

interface IStyledInput {
  icon: IField["icon"];
  border: IField["border"];
}

const StyledInput = styled.input<IStyledInput>`
  ${({ theme, disabled, icon, border }) => `
    width: 100%;
    height: 32px;
    padding: 0 ${theme.space[2]}px;
    ${icon ? "padding-right: 28px;" : ""}
    border: ${border ? "1px solid rgba(30, 46, 102, 0.15)" : "0"};
    border-radius: 8px;
    outline: none;
    ${disabled ? "background-color: #dadada" : ""}
  `}
`;

const Icon = styled.div`
  position: absolute;
  right: 20px;
  top: 65%;
  transform: translateY(-50%);
  display: flex;
  font-size: 12px;
  color: rgba(30, 46, 102, 0.3);
`;

export const Input = forwardRef<HTMLInputElement, IField>(
  (
    { label, icon, onIconClick, fullWidth, border = true, disabled, ...props },
    ref
  ) => (
    <Root fullWidth={fullWidth}>
      {label ? <Label>{label}</Label> : null}
      <StyledInput
        ref={ref}
        icon={icon}
        border={border}
        {...props}
        disabled={disabled}
      />
      <Icon onClick={onIconClick}>{icon}</Icon>
    </Root>
  )
);
