import React, { useContext, useState } from "react";

export interface IRailwayLine {
  lineNumber: number | null;
}

export interface IRailwayLineDispatchContext {
  setLineNumber: (lineNumber: number | null) => void;
}

const RailwayLineContext = React.createContext<IRailwayLine | undefined>(undefined);

const RailwayLineDispatchContext = React.createContext<
  IRailwayLineDispatchContext | undefined
>(undefined);

export const RailwayLineContextProvider: React.FC = ({ children }) => {
  const [lineNumber, setLineNumber] = useState<number | null>(null);

  return (
    <RailwayLineContext.Provider value={{ lineNumber }}>
      <RailwayLineDispatchContext.Provider
        value={{
          setLineNumber,
        }}
      >
        {children}
      </RailwayLineDispatchContext.Provider>
    </RailwayLineContext.Provider>
  );
};

export const useRailwayLineContext = () => {
  const context = useContext(RailwayLineContext);
  if (!context) {
    throw new Error(
      "useRailwayLineContext must be inside a RailwayLineContextProvider with a value"
    );
  }

  return context;
};

export const useRailwayLineDispatchContext = () => {
  const context = useContext(RailwayLineDispatchContext);
  if (!context) {
    throw new Error(
      "useRailwayLineDispatchContext must be inside a RailwayLineDispatchProvider with a value"
    );
  }

  return context;
};
