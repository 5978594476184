import React, { useEffect } from "react";
import { Button } from "components/Button";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Input } from "components/Input";
import { Select } from "components/Select";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { updateTaskOverall } from "services/tasks";
import styled from "styled-components";
import { ICustomTaskDetails, ITaskDetails } from "types/taskDetails";
import { toast } from "react-toastify";
import { Box } from "components/Box";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { useParams } from "react-router-dom";
import { useTaskDetails } from "hooks/useTaskDetails";
import { Flex } from "components/Flex";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";
import { Warring } from "components/Warring";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { rolesByObjects } from "constants/rolesByObjects";

interface ITaskDetailsComponent {
  details: ICustomTaskDetails;
}

const Grid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

export const TaskDetails: React.FC<ITaskDetailsComponent> = ({ details }) => {
  const { type: typeParam } = useParams<{ type: string }>();

  const { setTableData } = useTableSidebarDispatchContext();
  const { detailsFieldsSets } = useTaskDetails(typeParam);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const defaultValues = Object.fromEntries(
    Object.entries(details).filter(([key, value]) => value !== null)
  );

  const { register, handleSubmit, control } = useForm<ITaskDetails>({
    defaultValues: {
      ...defaultValues,
      landOwners: details.landOwner?.map((value) => ({
        name: value,
        label: "Właściciel gruntu",
      })),
    },
  });

  const { fields, remove, append } = useFieldArray({
    name: "landOwners",
    control,
  });

  const onSubmit = handleSubmit((data) => {
    if (details.task === undefined) return;

    const newData = {
      coordinator: data.coordinator,
      contractor: data.contractor,
      csc: data.code,
      name: data.name,
      nameIE50: data.objectNameIE50,
      locationName: data.locationName,
      contractorSA: data.contractorSA,
      contractorCW: data.contractorCW,
      inHRF: data.inHRF,
      startDate: data.startDate,
      endDate: data.endDate,
      duration: data.duration,
      deadline: data.terminOstateczny,
      lineName: data.lineName,
      lineNumber: data.lineNumber,
      lineNumberOTK: data.lineNumberOTK,
      chainage: data.chainage,
      plkIZ: data.plkIZ,
      sectionISE: data.sectionISE,
      iz: data.iZ,
      towerHeight: data.towerHeight,
      landOwner: data.landOwners.map((landOwner) => landOwner.name),
      plotNumber: data.plotNumber,
      latitude: data.latitude,
      longitude: data.longitude,
      voivodeship: data.voivodeship,
      district: data.district,
      area: data.area,
      areaNumber: data.areaNumber,
      locationChange: data.locationChange,
      phase: data.phase,
      stage: data.stage,
      stageNew: data.stageNew,
      restorer: data.konserwatorZabytkow,
      natura2000: data.natura2000,
      natureConservator: data.konserwatorPrzyrody,
      deforestation: data.wycinkaDrzew,
      exitRoad: data.zjazdyZDrogi,
      remoteFolderPath: data.remoteFolderPath,
      active: data.active,
    };

    updateTaskOverall({
      taskId: details.task,
      taskDetails: newData,
    })
      .then(() => {
        toast.success("Obiekt został zaktualizowany.");
      })
      .catch(() => {
        toast.error("Nie udało się zaktualizować obiektu.");
      });
  });

  const addNewField = () => {
    append({
      label: "Nowy Właściciel gruntu",
      name: "",
    });
  };

  useEffect(() => {
    setTableData({
      detailsObject: false,
    });
  }, []);

  return (
    <Box p={4}>
      {details.problems &&
        details.problems.map((item, index) => (
          <div key={index}>
            <Warring
              name={item.name}
              severity={item.severity}
              type={item.type}
            />
          </div>
        ))}
      <form onSubmit={onSubmit}>
        {loggedUserRoles?.includes(
          rolesByObjects[typeParam]["OVERALL_UPDATE"]
        ) && (
          <Flex justifyContent="right">
            <Button type="submit" bordered>
              Zapisz
            </Button>
          </Flex>
        )}

        {detailsFieldsSets.map((detailsFieldsSet) => (
          <Box mb={6} key={detailsFieldsSet.fieldSetTitle}>
            <Flex mb={3} justifyContent="space-between" alignItems="center">
              <FieldSetTitle>{detailsFieldsSet.fieldSetTitle}</FieldSetTitle>
              {detailsFieldsSet.addingField && (
                <Button type="button" bordered onClick={addNewField}>
                  Dodaj właściciela gruntu
                </Button>
              )}
            </Flex>
            <Grid>
              {detailsFieldsSet.fields.map((detailField) =>
                detailField.type === "select" ? (
                  <Controller
                    key={detailField.name}
                    control={control}
                    name={detailField.name as "name"}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={detailField.label as "label"}
                        onChange={(e) =>
                          field.onChange(e.target.value === "true")
                        }
                      >
                        <option value="true" selected={true}>
                          Tak
                        </option>
                        <option value="false" selected={false}>
                          Nie
                        </option>
                      </Select>
                    )}
                  />
                ) : detailField.type === "date" ? (
                  <Controller
                    key={detailField.name}
                    control={control}
                    name={detailField.name as "name"}
                    render={({ field }) => (
                      <DatePicker
                        selected={
                          new Date(field?.value).toString() !==
                            "Invalid Date" && new Date(field.value)
                        }
                        onChange={(date) => {
                          field.onChange(
                            date instanceof Date
                              ? format(date, "yyyy-MM-dd")
                              : ""
                          );
                        }}
                        customInput={
                          <Input
                            label={detailField.label as "label"}
                            value={field.value?.toString()}
                          />
                        }
                        dateFormat="dd-MM-yyyy"
                        portalId="root-datepicker-portal"
                      />
                    )}
                  />
                ) : detailField.label === "Właściciel gruntu" ? (
                  fields.map((field, index) => (
                    <Input
                      key={field.id}
                      label={field.label}
                      icon={index != 0 && <DeleteIcon />}
                      onIconClick={() => {
                        if (index != 0) remove(index);
                      }}
                      {...register(`landOwners.${index}.name` as const)}
                    />
                  ))
                ) : (
                  <Input
                    key={detailField.name}
                    label={detailField.label}
                    disabled={detailField.disabled}
                    {...register(detailField.name as "name")}
                  />
                )
              )}
            </Grid>
          </Box>
        ))}
      </form>
    </Box>
  );
};
