import React, { FC, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import styled from "styled-components";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import { Divider } from "components/Divider";
import {
  getDisruptionByPartner,
  getDisruptionByTypes,
} from "services/statistics";

const AllDisruptionPieChart: FC = () => {
  const [disruptionsByTypes, setDisruptionsByTypes] = useState<
    Record<string, never>
  >({});
  const [disruptionsByPartners, setDisruptionsByPartners] = useState<
    Record<string, never>
  >({});

  useEffect(() => {
    getDisruptionByTypes().then((res) => {
      setDisruptionsByTypes(res as Record<string, never>);
    });
    getDisruptionByPartner().then((res) => {
      setDisruptionsByPartners(res as Record<string, never>);
    });
  }, []);

  const headingStatistics: {
    basic: { title: string; name: string }[];
    partners: { title: string; name: string; color: string }[];
  } = {
    basic: [
      { title: "Lista wszystkich zakłóceń", name: "total" },
      { title: "Nieprzypisane zakłócenia", name: "unassigned" },
      { title: "Braki formalne", name: "warnings" },
      { title: "Błędy", name: "errors" },
    ],
    partners: [
      { title: "nokia", name: "nokia", color: "#23ABB6" },
      { title: "herkules", name: "herkules", color: "#005AFF" },
      { title: "fonon", name: "fonon", color: "#7D33F2" },
      { title: "spc-2", name: "spc-2", color: "#F47F31" },
    ],
  };

  const getOptions = (disruptions: Record<string, never>) => ({
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["25%", "50%"],
        label: {
          formatter: "{c}",
          position: "inside",
          color: "white",
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: disruptions.nokia, name: "nokia" },
          { value: disruptions.herkules, name: "herkules" },
          { value: disruptions.fonon, name: "fonon" },
          { value: disruptions["spc-2"], name: "spc-2" },
        ],
        color: ["#23ABB6", "#005AFF", "#7D33F2", "#F47F31"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          scaleSize: 3,
        },
        tooltip: {
          position: "inside",
          padding: 5,
          textStyle: {
            fontSize: 10,
          },
        },
      },
    ],
  });

  const HeadingCards = styled(Flex)`
    width: 100%;
    flex: 4;
    gap: ${({ theme }) => theme.space[6]}px;
    padding: ${({ theme }) => theme.space[6]}px;
    & > * {
      gap: ${({ theme }) => theme.space[6]}px;
    }
  `;
  const HeadingCard = styled(Card)`
    flex: 1;
    height: 106px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: ${({ theme }) => theme.space[4]}px;
    gap: ${({ theme }) => theme.space[2]}px;
  `;
  const HeadingCardTitle = styled.p`
    color: ${({ theme }) => theme.palette.text.darkGrey};
    min-height: 36px;
    margin: 0;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: space-around;
  `;
  const PieChartWrapper = styled.div`
    flex: 2.5;
    max-width: 450px;
    overflow: hidden;
  `;
  const ColorIcon = styled.div<{ color: string }>`
    width: 7px;
    height: 7px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
  `;

  return (
    <Flex>
      <HeadingCards flexDirection="column">
        <Flex>
          {headingStatistics.basic.map((card) => (
            <HeadingCard key={card.title}>
              <HeadingCardTitle>{card.title}</HeadingCardTitle>
              <Divider />
              {disruptionsByTypes[card.name]}
            </HeadingCard>
          ))}
        </Flex>
        <Flex>
          {headingStatistics.partners.map((card) => (
            <HeadingCard key={card.title}>
              <HeadingCardTitle>
                <ColorIcon color={card.color} />
                {card.title}
              </HeadingCardTitle>
              <Divider />
              {disruptionsByPartners[card.name]}
            </HeadingCard>
          ))}
        </Flex>
      </HeadingCards>
      <PieChartWrapper>
        <ReactEcharts
          option={getOptions(disruptionsByPartners)}
          style={{
            transform: "scale(1.45)",
            width: "100%",
          }}
        />
      </PieChartWrapper>
    </Flex>
  );
};

export default AllDisruptionPieChart;
