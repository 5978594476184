import React, { useEffect, useState } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Avatar } from "components/Avatar";
import { routes } from "routes";
import styled from "styled-components";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Dropdown } from "./Dropdown";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { NotificationDropdown } from "./NotificationsDropdown";
import { ReactComponent as BellIcon } from "icons/bell.svg";
import { getNotifications, INotification } from "services/notifications";
import { useHistory } from "react-router-dom";
import { IUser } from "views/Settings";
import { MsLoginButton } from "./MsLoginButton";

const Root = styled.div`
  ${({ theme }) => `
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background: ${theme.palette.neutral.white};
    box-shadow: 0 2px 8px 0 rgba(30,46,102,0.1);
  `};
`;

const InnerWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 ${theme.space[10]}px 0 ${260 + theme.space[10]}px;
  `}
`;
const P = styled.p`
  line-height: 0;
  text-align: right;
`;

const Breadcrumbs = styled.ul`
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
  color: rgba(19, 19, 21, 0.65);
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 18px;
`;

export const Navbar = () => {
  const breadcrumbs = useBreadcrumbs(routes);
  const { setToken } = useAuthDispatchContext();
  const [user, setUser] = useState<IUser>();
  const history = useHistory();

  const [notifications, setNotifications] = useState<INotification[]>([]);

  const items = [
    {
      label: "Ustawienia",
      onClick: () => {
        history.push("/settings");
      },
    },
    {
      label: "Wyloguj",
      onClick: () => {
        setToken(null);
        localStorage.removeItem("currentUser");
      },
    },
  ];

  const fetchNotifications = () => {
    getNotifications().then((res) => setNotifications(res.data));
  };

  const renderCurrentUserName = () => {
    if (user) {
      if (user?.firstname && user?.lastname) {
        return `${user?.firstname} ${user?.lastname}`;
      } else {
        return user.email;
      }
    }
  };

  const renderPartner = () => {
    if (user) {
      if (user?.partner) return user.partner;
      else return "";
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("currentUser"))) {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      setUser(user);
    }
  }, [localStorage.getItem("currentUser")]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Root>
      <InnerWrapper>
        <Breadcrumbs>
          {breadcrumbs.map(({ breadcrumb }, index) => [
            index ? (
              <React.Fragment key={index}>&nbsp;{"/"}&nbsp;</React.Fragment>
            ) : (
              ""
            ),
            breadcrumb,
          ])}
        </Breadcrumbs>
        <Flex alignItems="center">
          <MsLoginButton />
          <Box>
            <P>{renderCurrentUserName()}</P>
            <P>
              <strong>{renderPartner()}</strong>
            </P>
          </Box>
          <Box px={4}>
            <Dropdown items={items}>
              <Avatar url={user?.avatar} />
            </Dropdown>
          </Box>
          <NotificationDropdown items={notifications.slice(0, 50)}>
            <BellIcon />
          </NotificationDropdown>
        </Flex>
        {/* <Button bordered onClick={() => setToken(null)}>
          Wyloguj się
        </Button> */}
      </InnerWrapper>
    </Root>
  );
};
