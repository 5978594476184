import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "components/PageLayout";
import { Flex } from "components/Flex";
import { Card } from "components/Card";

import styled from "styled-components";
import { postKeyGivenConfirm } from "services/keys";
import { toast } from "react-toastify";

const Root = styled(Flex)`
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
`;

const StyledCard = styled(Card)`
  padding: 10px;
  overflow: auto;
  width: 100%;
  background-color: #f4f5f8;
`;

export const KeyPublicationConfirmView = () => {
  const { linkId } = useParams<{ linkId: string }>();

  const keyGivenConfirm = useCallback(() => {
    postKeyGivenConfirm({
      linkId,
    })
      .then(() => {
        toast.success("Potwierdzono wydanie klucza");
      })
      .catch(() => {
        toast.error("Nie udało się potwierdzić wydania klucza");
      });
  }, [linkId]);

  useEffect(() => {
    keyGivenConfirm();
  }, [keyGivenConfirm]);

  return (
    <PageLayout>
      <Root justifyContent="center">
        <StyledCard>Potwierdzenie wydania</StyledCard>
      </Root>
    </PageLayout>
  );
};
