import { faDownload, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Label } from "components/Label";
import {
  Root,
  StyledButton,
  WrapperChildren,
  WrapperDropdown,
  StyledFlex,
  StyledMultiSelect,
  StyledInput,
  StyledBox,
} from "./ReportsDownload.styled";
import MultiSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Button } from "components/Button";
import { useState } from "react";
import {
  downloadDisruptionReport,
  downloadDisruptionFilteredReport,
  downloadSummaryReports,
  downloadSubmittedReports,
} from "services/disruptions";
import { downloadFile } from "utilities/downloadFile";
import { Input } from "components/Input";
import { downloadTaskFilteredReport } from "services/tasks";

interface IDownloadType {
  value: string;
  label: string;
}

interface Props {
  type: "task" | "disruption" | "line-section";
  taskType?: string;
  tableCellDefinition: { idName?: string }[];
  filters: { [key: string]: string | string[] | number | number[] | boolean };
  colVis: boolean[];
}

export const ReportsDownload = ({
  type,
  taskType,
  tableCellDefinition,
  filters,
  colVis,
}: Props) => {
  const [isDownloadDropdownVisible, setIsDownloadDropdownVisible] =
    useState<boolean>(false);
  const [downloadType, setDownloadType] = useState<IDownloadType>({
    value: "months",
    label: "Miesięczny",
  });

  const [isRaportDownload, setRaportDownload] = useState<boolean>(false);

  const { control: controlDownload, watch: watchDownload } = useForm<{
    date: string;
    dateFrom: string;
    dateTo: string;
  }>({
    defaultValues: {
      date: "",
      dateFrom: "",
      dateTo: "",
    },
  });

  const { date, dateFrom, dateTo } = watchDownload();

  const optionsDownload: IDownloadType[] = [
    { value: "months", label: "Miesięczny" },
    { value: "grouped", label: "Zbiorcze zestawienie roszczeń" },
    { value: "notification", label: "Powiadomienie o roszczeniu" },
  ];

  const onRaportDownload = () => {
    const startDate = date;
    const dateObj = new Date(date);
    const endDate = format(
      new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0),
      "yyyy-MM-dd"
    );
    //TODO: connection with API
    downloadDisruptionReport(startDate, endDate).then((data) => {
      const months = [
        "styczeń",
        "luty",
        "marzec",
        "kwiecień",
        "maj",
        "czerwiec",
        "lipiec",
        "sierpień",
        "wrzesień",
        "październik",
        "listopad",
        "grudzień",
      ];
      const startDateObj = new Date(startDate);
      const monthName = months[startDateObj.getMonth()];
      const fileName = `raport-${monthName}-${startDateObj.getFullYear()}`;

      downloadFile(data.data, `${fileName}.xlsx`);
    });
    setRaportDownload(false);
    setIsDownloadDropdownVisible(false);
  };

  const onDownloadSummaryReports = async () => {
    const file = await downloadSummaryReports();
    const fileName = "Zbiorcze zestawienie roszczeń";
    downloadFile(file.data, `${fileName}.xlsx`);
    setIsDownloadDropdownVisible(false);
  };

  const onDownloadSubmitted = async () => {
    const rangeDate = {
      dateFrom,
      dateTo,
    };
    const file = await downloadSubmittedReports(rangeDate);
    const fileName = `powiadomienie-o-roszczeniu-${dateFrom}-${dateTo}`;
    downloadFile(file.data, `${fileName}.xlsx`);
    setIsDownloadDropdownVisible(false);
  };

  const handleFilteredRaportDownload = () => {
    const disruptionColumnsInRaport = [
      ...tableCellDefinition,
      { width: 100, name: "Zakresy", idName: "scopes", value: (e) => 15 },
      { width: 100, name: "Koszty", idName: "costs", value: (e) => 16 },
    ];

    const selectedColumns = (
      type === "disruption" ? disruptionColumnsInRaport : tableCellDefinition
    )
      .map((tableCell, index) => colVis[index] && tableCell?.idName)
      .filter((tableCellName) => tableCellName && tableCellName);

    if (type === "task") {
      downloadTaskFilteredReport(taskType, filters, {
        selectedColumns,
      }).then((data) => {
        downloadFile(data.data, "report.xlsx");
      });
    } else if (type === "disruption") {
      downloadDisruptionFilteredReport(filters, {
        selectedColumns,
      }).then((data) => {
        downloadFile(data.data, "report.xlsx");
      });
    }
  };

  const handleToggleClick = () => {
    setIsDownloadDropdownVisible(!isDownloadDropdownVisible);
  };

  const renderDownloadField = (type: string) => {
    switch (type) {
      case "months":
        return (
          <>
            <Controller
              control={controlDownload}
              name="date"
              render={({ field }) => (
                <DatePicker
                  selected={field.value ? new Date(field.value) : undefined}
                  onChange={(date) => {
                    setIsDownloadDropdownVisible(true);
                    field.onChange(
                      date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                    );
                  }}
                  customInput={<StyledInput label="Data" value={field.value} />}
                  dateFormat="MM-yyyy"
                  showMonthYearPicker
                  portalId="root-datepicker-portal"
                />
              )}
            />
            <Box ml={2}>
              <Button onClick={onRaportDownload} bordered>
                Pobierz
              </Button>
            </Box>
          </>
        );
      case "grouped":
        return (
          <StyledFlex justifyContent="flex-end">
            <Box mt={2}>
              <Button onClick={onDownloadSummaryReports} bordered>
                Pobierz
              </Button>
            </Box>
          </StyledFlex>
        );
      case "notification":
        return (
          <Flex flexDirection="column">
            <Flex>
              <Box mr={3}>
                <Controller
                  control={controlDownload}
                  name="dateFrom"
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value ? new Date(field.value) : undefined}
                      onChange={(date) => {
                        setIsDownloadDropdownVisible(true);
                        field.onChange(
                          date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                        );
                      }}
                      customInput={
                        <Input label="Rozpoczęcie od" value={field.value} />
                      }
                      dateFormat="dd-MM-yyyy"
                      portalId="root-datepicker-portal"
                    />
                  )}
                />
              </Box>
              <Controller
                control={controlDownload}
                name="dateTo"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      setIsDownloadDropdownVisible(true);
                      field.onChange(
                        date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                      );
                    }}
                    customInput={
                      <Input label="Zakończenie do" value={field.value} />
                    }
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                  />
                )}
              />
            </Flex>
            <Flex justifyContent="flex-end">
              <Box mt={2}>
                <Button onClick={onDownloadSubmitted} bordered>
                  Pobierz
                </Button>
              </Box>
            </Flex>
          </Flex>
        );
    }
  };

  return (
    <Box pl={3}>
      <Flex>
        {type === "disruption" && (
          <Root>
            <WrapperChildren
              onClick={handleToggleClick}
              role="button"
              aria-hidden="true"
            >
              <StyledButton bordered title="Pobierz raport">
                <FontAwesomeIcon icon={faDownload} />
              </StyledButton>
            </WrapperChildren>
            {isDownloadDropdownVisible && (
              <WrapperDropdown style={{ background: "white" }}>
                <StyledFlex justifyContent="space-between">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <StyledBox>
                      <Label>Typ dokumentu</Label>
                      <MultiSelect
                        options={optionsDownload}
                        value={downloadType}
                        onChange={(value) =>
                          setDownloadType(value ?? downloadType)
                        }
                      />
                    </StyledBox>
                    <StyledFlex
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      {renderDownloadField(downloadType?.value)}
                    </StyledFlex>
                  </Flex>
                </StyledFlex>
              </WrapperDropdown>
            )}
          </Root>
        )}

        <Box ml={2}>
          <StyledButton
            bordered
            onClick={handleFilteredRaportDownload}
            title="Eksportuj do exela"
          >
            <FontAwesomeIcon icon={faFileExcel} />
          </StyledButton>
        </Box>

        {isRaportDownload && (
          <StyledFlex justifyContent="space-between" mb={6}>
            <Flex justifyContent="space-between" alignItems="center">
              <Box mr={5}>
                <Label>Typ dokumentu</Label>
                <StyledMultiSelect
                  options={optionsDownload}
                  value={downloadType}
                  onChange={(value: IDownloadType) =>
                    setDownloadType(value ?? downloadType)
                  }
                />
              </Box>
              <Flex justifyContent="space-between" alignItems="flex-end">
                {renderDownloadField(downloadType?.value ?? "months")}
              </Flex>
            </Flex>
          </StyledFlex>
        )}
      </Flex>
    </Box>
  );
};
