import { fetchAPI, fetchFilesAPI } from "utilities/fetchAPI";
import qs from "qs";
import { envProjectName } from "envData";
import { ICustomerReport, MRWKOsgroup } from "types/importExport";
import { RequestResponseType } from "types/request";

export type taskMetaContextType =
  | "SA"
  | "CW"
  | "TI"
  | "customer_report"
  | "HRF"
  | "MRW"
  | "OTK"
  | "fonon"
  | "PŚP"
  | "spc-2"
  | "task_areas"
  | "payment_schedule"
  | "FDS"
  | "objects"
  | "payment_date";

interface IFetchExportsHistory {
  context: taskMetaContextType;
  type?: "task_meta" | "otk";
  limit?: number;
  offset?: number;
}

interface IFetchExportFile {
  context: taskMetaContextType;
  exportName: string;
}

interface IFetchExportHRFFile {
  exportName: string;
}

interface IFetchExportGenerateFile {
  context: taskMetaContextType;
}

interface IFetchPostImportFile {
  data: FormData;
  context: taskMetaContextType;
  type: "task_meta" | "otk" | "psp";
}

interface IFetchPostOTKImportFile {
  data: FormData;
  context: taskMetaContextType;
}

interface IFetchHRFExportHistory {
  limit?: number;
  offset?: number;
}

export const fetchExportsHistoryDepr = ({
  context,
  limit,
  offset,
}: IFetchExportsHistory) => {
  const query = qs.stringify(
    {
      limit: limit ?? undefined,
      offset,
    },
    { arrayFormat: "brackets" }
  );

  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/export/task-meta/${context}/list?${query}`
  );
};

export const fetchExportFile = ({ context, exportName }: IFetchExportFile) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/export/task-meta/${context}/${exportName}`
  );
};

export const fetchExportGenerateFileDepr = ({
  context,
}: IFetchExportGenerateFile) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/export/task-meta/${context}`,
    {
      method: "POST",
    }
  );
};

export const fetchImportFile = (importHistory: string) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/import/donwload-file/${importHistory}`
  );
};

export const fetchMRWImportFile = (uploadedMrw: string) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/mrw/download-import-file/${uploadedMrw}`
  );
};

export const fetchPostImportMRWFile = ({ data }: { data: FormData }) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/mrw/import/${envProjectName}`,
    {
      method: "POST",
      body: data,
    }
  );
};

export const fetchPostImportFile = ({
  data,
  context,
  type,
}: IFetchPostImportFile) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/import/${type}/${context}/request`,
    {
      method: "POST",
      body: data,
    }
  );
};

export const fetchPostOTKImportFile = ({
  data,
  context,
}: IFetchPostOTKImportFile) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/import/otk/${context}`,
    {
      method: "POST",
      body: data,
    }
  );
};

export const fetchExportsHistory = (contextType: string, context?: string) => {
  return fetchAPI<{ data: ICustomerReport[] }>(
    `${process.env.REACT_APP_API_URL}/export/history/${contextType}${
      context ? `?context=${context}` : ""
    }`
  );
};

export const fetchExportGenerateFile = (
  contextType: string,
  context?: string
) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/export/request/${contextType}${
      context ? `?context=${context}` : ""
    }`,
    {
      method: "POST",
    }
  );
};

export const fetchExportCustomerFile = (id: string) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/export/history/download-file/${id}
    `
  );
};

export const fetchImportsHistory = ({
  context,
  type,
}: IFetchExportsHistory) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/import/${type}/${context}/history/`,
    {
      method: "GET",
    }
  );
};

interface IFetchKOMRWRes {
  data: {
    mrwNumber?: string;
    mrwId?: string;
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    objectNumber?: string;
    objectType?: string;
  }[];
}

export const fetchMRWImportsHistory = ({
  limit,
  offset,
}: {
  limit?: number;
  offset?: number;
}) => {
  const query = qs.stringify(
    {
      limit: limit ?? undefined,
      offset,
    },
    { arrayFormat: "brackets" }
  );

  return fetchAPI<IFetchKOMRWRes>(
    `${process.env.REACT_APP_API_URL}/mrw/history?${query}`,
    {
      method: "GET",
    }
  );
};

interface IFetchGroupedKOListByMRW {
  data: MRWKOsgroup[];
}

export const fetchKOMRW = ({
  limit,
  offset,
}: {
  limit?: number;
  offset?: number;
}) => {
  const query = qs.stringify(
    {
      limit: limit ?? undefined,
      offset,
    },
    { arrayFormat: "brackets" }
  );

  return fetchAPI<IFetchGroupedKOListByMRW>(
    `${process.env.REACT_APP_API_URL}/mrw/ko/list-by-mrw?${query}`,
    {
      method: "GET",
    }
  );
};

export const fetchKOsbyMRW = ({
  uploadedMrwId,
  limit,
  offset,
}: {
  uploadedMrwId: string;
  limit?: number;
  offset?: number;
}) => {
  const query = qs.stringify(
    {
      limit: limit ?? undefined,
      offset,
    },
    { arrayFormat: "brackets" }
  );

  return fetchAPI<IFetchKOMRWRes>(
    `${process.env.REACT_APP_API_URL}/mrw/ko/${uploadedMrwId}?${query}`,
    {
      method: "GET",
    }
  );
};

interface IHRFExport {
  createdAt: string;
  failMessage: string;
  id: string;
  report: { reportFileName: string; id: string };
  reportName: string;
  status: string;
  updatedAt: string;
}

export const fetchHRFExportHistory = ({
  limit,
  offset,
}: IFetchHRFExportHistory) => {
  const query = qs.stringify(
    {
      limit: limit ?? undefined,
      offset,
    },
    { arrayFormat: "brackets" }
  );

  return fetchAPI<{ data: IHRFExport[] }>(
    `${process.env.REACT_APP_API_URL}/report/hrf/history?${query}`
  );
};

export const fetchExportGenerateHRFFile = ({
  reportName,
}: {
  reportName: string;
}) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/report/hrf/${reportName}/generate`
  );
};

export const fetchExportHRFFile = ({ exportName }: IFetchExportHRFFile) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/report/hrf/download/${exportName}`
  );
};

export const fetchKOMRWSingleFile = (uuid: string) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/mrw/download-single/ko/${uuid}`
  );
};

export const fetchKOMRWmulti = (uuid: string) => {
  return fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/mrw/download-multi/${uuid}`
  );
};

export const fetchPutMRWKOSettings = (data: {
  name: string;
  value: string;
}) => {
  return fetchAPI<RequestResponseType>(
    `${process.env.REACT_APP_API_URL}/mrw/ko-settings/`,
    {
      method: "PUT",
      body: JSON.stringify(data),
    }
  );
};
