import { useState, useEffect, useCallback } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import { useFetchAPI } from "hooks/fetchAPI";
import { DICTIONARY_LINES_URL } from "constants/path";
import { useForm, Controller } from "react-hook-form";
import {
  createTaskPredecessor,
  fetchTaskChildren,
  fetchTaskPredecessors,
  fetchTasks,
} from "services/tasks";
import { ITask, ITaskChild, ITaskPredecessor, TaskType } from "types/task";
import { Label, Select } from "components/Select";
import MultiSelect from "react-select";
import styled from "styled-components";
import { Button } from "components/Button";
import { PredecessorsList } from "components/PredecessorsList/PredecessorsList";
import { differenceInBusinessDays } from "date-fns";
import { toast } from "react-toastify";

interface IProps {
  task: ITaskChild;
  onSubmitSuccess?: () => void;
}

interface IFormData {
  duration: number;
  dateTo: string;
  dateFrom: string;
  object: string;
  relationType: string;
  railwayLine?: string;
  predecessor?: string;
  dateHRF?: string;
  objectType?:
    | ""
    | "OTK"
    | "OR"
    | "FDS"
    | "TUNELE"
    | "milestone"
    | "OSZ"
    | "OC"
    | "CERTYFIKACJA_WE"
    | "Certyfikacja"
    | "ReadyToWE"
    | "OtherTask";
}

const WrapperField = styled.div`
  width: 50%;
`;
const StyledMultiSelect = styled(MultiSelect)<{ error: boolean }>`
  ${({ error }) => `
    width: 100%;
    ${
      error &&
      `
      border: 1px solid red !important;
    `
    }
  `}
`;
const StyledSelect = styled(Select)`
  width: 100%;
  height: 38px;
`;
const WrapperButtons = styled(Flex)`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const typeOption = [
  { value: "end-start", label: "Zakończenie-Rozpoczęcie" },
  { value: "end-end", label: "Zakończenie-Zakończenie" },
];

const defaultValues: IFormData = {
  duration: 0,
  dateTo: "",
  dateFrom: "",
  object: "",
  relationType: "",
  objectType: "",
  railwayLine: "",
  predecessor: "",
  dateHRF: "",
};

export const PredecessorDateHRF = ({
  task,
  onSubmitSuccess,
}: IProps): JSX.Element => {
  const [availableObjects, setAvailableObjects] = useState<Array<ITask>>([]);
  const [availableDatesHRF, setAvailableDatesHRF] = useState<Array<ITaskChild>>(
    []
  );
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [taskPredecessors, setTaskPredecessors] = useState<ITaskPredecessor[]>(
    []
  );

  const { data: railwayLines } =
    useFetchAPI<Record<string, number>>(DICTIONARY_LINES_URL);

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm<IFormData>({
    defaultValues,
  });

  const ObjectTypeWacherValue = watch("objectType");
  const railwayLineValue = watch("railwayLine");

  const handleORorFDSInputSearch = (e) => {
    if (!e) return;
    fetchTasks({
      type: ObjectTypeWacherValue as TaskType,
      lineNumberFilter:
        railwayLineValue !== "" ? parseInt(railwayLineValue, 10) : undefined,
      filters: { search: e },
    }).then(({ data }) => {
      if (!availableObjects.find((item) => item.code === data[0].code)) {
        setAvailableObjects((prevState) => [...data, ...prevState]);
      }
    });
  };

  const onSubmit = handleSubmit(async ({ duration, dateHRF, relationType }) => {
    const payload = {
      task: task.id,
      relationType,
      duration,
      predecessor: dateHRF,
    };

    const res = await createTaskPredecessor(payload);
    if (res.success) {
      reset();

      toast.success("Dodano poprzenik");
      setIsFormOpen(false);
      fetchPredecessors();

      onSubmitSuccess && onSubmitSuccess();
    }
  });

  const fetchPredecessors = useCallback(async () => {
    const data = await fetchTaskPredecessors({ taskId: task.id });
    setTaskPredecessors(data);
  }, [task.id]);

  useEffect(() => {
    fetchPredecessors();
  }, [fetchPredecessors]);

  useEffect(() => {
    if (
      [
        "OTK",
        "OR",
        "FDS",
        "milestone",
        "Tunel",
        "CertyfikacjaWE",
        "Certyfikacja",
        "ReadyToWE",
        "OC",
        "OSZ",
        "OtherTask",
      ].includes(ObjectTypeWacherValue)
    ) {
      fetchTasks({
        type: ObjectTypeWacherValue as TaskType,
        limit: 50,
        lineNumberFilter:
          railwayLineValue !== "" ? parseInt(railwayLineValue, 10) : undefined,
      }).then(({ data }) => {
        setAvailableObjects(data);
      });
    }
  }, [ObjectTypeWacherValue, railwayLineValue]);

  useEffect(() => {
    if (watch("object")) {
      fetchTaskChildren({ taskId: watch("object") }).then((children) =>
        setAvailableDatesHRF(children)
      );
    }
  }, [watch("object")]);

  useEffect(() => {
    if (task.predecessorsDatesManual && watch("relationType")) {
      const candidatePredecessor = availableDatesHRF.find(
        ({ id }) => id === watch("dateHRF")
      );

      const duration = Math.abs(
        differenceInBusinessDays(
          new Date(task.endDate),
          new Date(
            watch("relationType") === "end-start"
              ? candidatePredecessor.startDate
              : candidatePredecessor.endDate
          )
        )
      );

      setValue("duration", duration);
    }
  }, [task.predecessorsDatesManual, watch("relationType")]);

  return (
    <>
      <Box mb={3}>
        <h2>Poprzedniki - {task.name}</h2>
      </Box>
      <Flex mb={3} mr={2} justifyContent="end">
        <Button
          type="button"
          bordered
          onClick={() => {
            setIsFormOpen((prevIsFormOpen) => !prevIsFormOpen);
          }}
        >
          {isFormOpen ? "Wróć" : "Dodaj poprzednik"}
        </Button>
      </Flex>
      {isFormOpen ? (
        <form onSubmit={onSubmit}>
          <Box mr={2}>
            <Select label="Typ obiektu" {...register("objectType")}>
              <option value="" />
              <option value="OTK">OTK</option>
              <option value="OR">OR/ORR</option>
              <option value="FDS">FDS</option>
              <option value="Tunel">TUNELE</option>
              <option value="milestone">KAMIENIE MILOWE</option>
              <option value="OSZ">OSZ</option>
              <option value="OC">OC</option>
              <option value="CertyfikacjaWE">NR CERTYFIKACJI WE</option>
              <option value="Certyfikacja">NR DOPUSZCZENIA UTK</option>
              <option value="ReadyToWE">NR GOTOWOŚCI WE</option>
              <option value="OtherTask">INNE</option>
            </Select>
          </Box>
          {[
            "OTK",
            "OR",
            "FDS",
            "Tunel",
            "milestone",
            "OSZ",
            "OC",
            "CertyfikacjaWE",
            "Certyfikacja",
            "ReadyToWE",
            "OtherTask",
          ].includes(ObjectTypeWacherValue) && (
            <>
              <Box my={3}>
                <Flex alignItems="center" justifyContent="space-between">
                  <WrapperField>
                    <StyledSelect
                      label="Linia kolejowa"
                      {...register("railwayLine")}
                    >
                      <option value=""></option>
                      {railwayLines
                        ? Object.entries(railwayLines).map((item) => (
                            <option key={item[0]} value={item[1]}>
                              {item[0]}
                            </option>
                          ))
                        : null}
                    </StyledSelect>
                  </WrapperField>
                  <WrapperField>
                    <Box key={ObjectTypeWacherValue} ml={2}>
                      <Label>Wybierz obiekt</Label>
                      <Controller
                        control={control}
                        name="object"
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <StyledMultiSelect
                              onInputChange={handleORorFDSInputSearch}
                              error={!!errors.object?.message}
                              inputRef={ref}
                              required
                              onChange={(val) => {
                                onChange(val.value);
                              }}
                              options={availableObjects.map(
                                (availableObject) => {
                                  const code = availableObject.code
                                    ? `CSC: ${availableObject.code} - `
                                    : "";
                                  const locationName =
                                    availableObject.locationName || "";
                                  const chainage = availableObject.chainage
                                    ? ` - ${availableObject.chainage}km`
                                    : "";
                                  return {
                                    label: code + locationName + chainage,
                                    value: availableObject.id,
                                  };
                                }
                              )}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </WrapperField>
                </Flex>
              </Box>
              <Flex justifyContent="space-between">
                <WrapperField>
                  <Box key={ObjectTypeWacherValue} m={2}>
                    <Select label="Wybierz Date HRF" {...register("dateHRF")}>
                      <option key=""></option>
                      {availableDatesHRF.map((val) => (
                        <option key={val.id} value={val.id}>
                          {val.code + " - " + val.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </WrapperField>
                <WrapperField>
                  <Box m={2}>
                    <Select
                      label="Typ"
                      {...register("relationType")}
                      error={!!errors.relationType?.message}
                    >
                      <option key=""></option>
                      {typeOption.map((val) => (
                        <option key={val.label} value={val.value}>
                          {val.label}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </WrapperField>
              </Flex>

              <Flex alignItems="center" justifyContent="space-between">
                <WrapperField>
                  <Box mx={2}>
                    <Input
                      readOnly={task.predecessorsDatesManual}
                      {...register("duration")}
                      type="number"
                      label="Liczba dni opóźnienia"
                    />
                  </Box>
                </WrapperField>
              </Flex>
              <WrapperButtons justifyContent="flex-end">
                <Button type="submit" bordered onClick={onSubmit}>
                  Dodaj
                </Button>
              </WrapperButtons>
            </>
          )}
        </form>
      ) : (
        <PredecessorsList
          data={taskPredecessors}
          onDelete={(id: string) => {
            setTaskPredecessors((prevTaskPredecessors) =>
              prevTaskPredecessors.filter((item) => id !== item.id)
            );
          }}
        />
      )}
    </>
  );
};
