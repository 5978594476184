import styled from "styled-components";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Box } from "components/Box";
import { Flex } from "./Flex";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { ITaskDetails } from "types/taskDetails";
import { Problems } from "./Problems";
import { fetchTask, fetchTaskDetails } from "services/tasks";
import { toast } from "react-toastify";
import { differenceInBusinessDays } from "date-fns";
import { useTaskDetails } from "hooks/useTaskDetails";
import {
  useTableSidebarContext,
  useTableSidebarDispatchContext,
} from "contexts/TableSidebarContext";

const Label = styled.div`
  ${({ theme }) => `
    color: rgba(19, 19, 21, 0.65);
    margin-right: ${theme.space[2]}px;
  `}
`;
const StyledBox = styled(Box).attrs({ p: 4 })`
  overflow: auto;
  height: calc(100% - 36px);
`;
const Value = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
  `}
`;

export const TaskDetails = forwardRef<
  HTMLDivElement,
  { taskId: string; typeParam: string }
>(({ taskId, typeParam }, ref) => {
  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();

  const [taskDetails, setTaskDetails] = useState<ITaskDetails | null>(null);

  const { detailsFieldsSets } = useTaskDetails(typeParam);

  const getFullTaskDetails = useCallback(async () => {
    try {
      const rawTaskDetails = await fetchTaskDetails({ taskId });

      const {
        code,
        startDate,
        endDate,
        name,
        type,
        completion,
        chainageLength,
        duration,
      } = await fetchTask({
        taskId,
      });

      setTaskDetails({
        ...rawTaskDetails,
        code,
        startDate,
        endDate,
        duration,
        completion,
        name,
        type,
        chainageLength,
        year: new Date(startDate).getFullYear(),
      });

      setTableData({ ...tableData, detailsObject: true });
    } catch {
      toast.error("Nie udało się pobrać informacji.");
    }
  }, [taskId]);

  useEffect(() => {
    getFullTaskDetails();

    return () => {
      setTaskDetails(null);
    };
  }, [getFullTaskDetails]);

  return (
    <StyledBox>
      <FieldSetTitle>Dane obiektu</FieldSetTitle>
      <Flex flexDirection="column" justifyContent="space-evenly">
        <Problems problems={taskDetails?.problems} />

        {detailsFieldsSets
          ?.find(({ fieldSetTitle }) => fieldSetTitle === "Dane ogólne")
          ?.fields?.map(({ label, name }) => (
            <Flex key={name} justifyContent="space-between" my={2}>
              <Label>{label}</Label>
              <Value>{taskDetails && (taskDetails[name] || "--")}</Value>
            </Flex>
          ))}
      </Flex>
    </StyledBox>
  );
});
