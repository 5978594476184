import { DICTIONARY_PROBLEM_TYPES_URL } from "constants/path";
import { useFetchAPI } from "./fetchAPI";

export const usefetchDictionaryProblemTypes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useFetchAPI<{ [key: string]: string }>(
    DICTIONARY_PROBLEM_TYPES_URL
  );

  return { dictionaryProblemTypes: data };
};
