import { useFetchAPI } from "./fetchAPI";
import { DICTIONARY_KO_SIGNING_PERSON } from "constants/path";

export const useFetchKOSigningPerson = () => {
  const data = useFetchAPI<
    {
      id: string;
      name: string;
      value: string;
    }[]
  >(DICTIONARY_KO_SIGNING_PERSON);
  return { KOSigningPerson: data.data };
};
