/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomTable } from "components/CustomTable";
import styled from "styled-components";
import { Box } from "components/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "utilities/formatDate";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../components/Loader/Loader";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { fetchKeys } from "services/keys";
import { IKey } from "types/keys";
import { useForm } from "react-hook-form";
import { TableType } from "../../types/tableType";
import { KeysFilter } from "./components/KeysFilter";
import {
  IFilters,
  useKeysDispatchContext,
  useKeysStateContext,
} from "contexts/KeysContext";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const StyledButton = styled(Button)`
  ${({ theme }) => `
    font-size: 15px;
    padding: 10px;
    transition: all .1s ease-in-out;
    &:hover {
      background-color: ${theme.palette.primary};
      color: #fff;
    }
  `}
`;

const Link = styled.a`
  text-align: center;
`;

interface IColumn {
  width: number;
  name: string;
  value: (e) => void;
}

export const KeysView = () => {
  const [requestPending, setRequestPending] = useState<boolean>(true);
  const [keys, setKeys] = useState<Array<IKey>>();

  const [totalFilteredCount, setTotalFilteredCount] = useState(0);
  const [tableOffset, setTableOffset] = useState(0);

  const [tableColumnDataLoaded, setTableColumnDataLoaded] = useState(false);

  const history = useHistory();

  const [columnList, setColumnList] = useState<IColumn[]>();
  const [tablePageSize, setTablePageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);

  const [colVis, setColVis] = useState(Array(15).fill(true));

  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const { setTableData } = useTableSidebarDispatchContext();

  const { filters, initialFilters } = useKeysStateContext();
  const { setFilters, cleanFilters } = useKeysDispatchContext();

  const { register, reset, handleSubmit } = useForm<IFilters>();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  useEffect(() => {
    if (!tableColumnDataLoaded) {
      setTableColumnDataLoaded(true);

      setColumnList([
        {
          width: 110,
          name: "Numer klucza",
          value: (e) => (
            <Link
              style={{
                color: "gray",
                display: "inline-block",
                minWidth: 110,
                lineHeight: "57px",
                textDecoration: "none",
              }}
              href={`/keys/${e.id}`}
            >
              {e.keyNumber}
            </Link>
          ),
        },
        {
          width: 170,
          name: "Nr OR 7.1-36.2",
          value: (e) => e.objectNameIE50,
        },
        {
          width: 170,
          name: "Nazwa IE-50",
          value: (e) => e.objectNameIE50,
        },
        {
          width: 230,
          name: "Nazwa lokalizacji",
          value: (e) => e.locationName,
        },

        {
          width: 100,
          name: "Ilość kluczy",
          value: (e) => e.keysQuantity,
        },
        {
          width: 80,
          name: "Nr linii",
          value: (e) => e.lineNumber,
        },
        {
          width: 100,
          name: "km",
          value: (e) => e.chainage,
        },
        {
          width: 150,
          name: "Data wydania kluczy",
          value: (e) => formatDate(new Date(e.lastGiveOutDate)),
        },
        {
          width: 170,
          name: "Ilość wydanych kluczy",
          value: (e) => e.keysGivenQuantity,
        },
        {
          width: 170,
          name: "Ilość dni od wydania",
          value: (e) => e.daysSinceGiveOut,
        },
      ]);
    }
  }, [tableColumnDataLoaded]);

  useEffect(() => {
    retrieveKeys(filters);

    history.push({
      pathname: history.location.pathname,
      search: "",
    });
  }, [filters]);

  useEffect(() => {
    const storage = localStorage.getItem("keys_filters");

    if (storage) {
      const parsedStorage = JSON.parse(storage);
      reset(parsedStorage);
      setFilters(parsedStorage);
    } else {
      reset(initialFilters);
      cleanFilters();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTableData({
        tableName: TableType.KEYS_LIST,
        columnNames: columnList,
        hiddenSidebar: false,
        detailsObject: true,
        isOpen: true,
        FilterComponent: () => (
          <KeysFilter
            register={register}
            reset={reset}
            handleSubmit={handleSubmit}
          />
        ),
      });
    });
  }, []);

  useLayoutEffect(() => {
    loadMoreData();
  }, [tableOffset]);

  useLayoutEffect(() => {
    if (isLoadingNext) {
      const nextPageNumber = pageNumber + 1;
      setTableOffset(nextPageNumber * tablePageSize);
      setPageNumber(nextPageNumber);
    }
  }, [isLoadingNext]);

  const retrieveKeys = async (data?: IFilters) => {
    setRequestPending(true);

    const response = await fetchKeys({
      limit: tablePageSize,
      page: pageNumber,
      ...data,
    });

    setKeys(response.data);

    if (response) {
      if (response.pagination) {
        setTotalFilteredCount(response.pagination.countFiltered);
      } else
        console.warn("response.pagination == undefined", response.pagination);
    }

    setRequestPending(false);
  };

  const loadMoreData = async () => {
    const response = await fetchKeys({
      limit: tablePageSize,
      page: pageNumber,
    });

    if (keys && response.data) Array.prototype.push.apply(keys, response.data);

    if (response && response.pagination)
      setTotalFilteredCount(response.pagination.countFiltered);
    else console.warn("response.pagination == undefined", response.pagination);

    setIsLoadingNext(false);

    if (response.pagination)
      setTotalFilteredCount(response.pagination.countFiltered);
  };

  const handleAddKey = () => {
    history.push(`/keys/add`);
  };

  const onTableScroll = (event, ref) => {
    const top = event.target.scrollTop;
    const totalHeight = ref.current.scrollHeight - ref.current.clientHeight;
    const percent = ((top / totalHeight) * 100).toFixed(0);
    if (percent === "100") setIsLoadingNext(true);
  };

  return (
    <>
      <PageLayout
        displaySidebarTable={false}
        displayButtonsGroup={false}
        pageTitleSpace
      >
        <Box mx={5} p={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <h3>
              Klucze - {totalFilteredCount}{" "}
              {requestPending ? (
                " - ładowanie..."
              ) : isLoadingNext ? (
                <div
                  style={{
                    position: "relative",
                    height: 24,
                    display: "inline-block",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      position: "absolute",
                      top: 2,
                      left: 6,
                      transform: "scale(0.75)",
                    }}
                  >
                    <Loader />
                  </div>
                </div>
              ) : (
                ""
              )}
            </h3>
            {loggedUserRoles?.includes("ROLE_KEYS_ECONOMY_CREATE") && (
              <Flex mx={2}>
                <Button
                  onClick={() => {
                    history.push(`/keys-publication`);
                  }}
                  bordered
                >
                  Wydanie kluczy
                </Button>
                <Box ml={2}>
                  <StyledButton
                    onClick={handleAddKey}
                    bordered
                    title="Dodaj Klucz"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </StyledButton>
                </Box>
              </Flex>
            )}
          </Flex>
        </Box>
        {tableColumnDataLoaded &&
        keys !== undefined &&
        keys.length > 0 &&
        !requestPending ? (
          <CustomTable
            columnList={columnList}
            data={keys}
            colVis={colVis}
            onTableScroll={onTableScroll}
          />
        ) : (
          <Box>
            {requestPending ? (
              <Loader />
            ) : (
              <Flex justifyContent="center" alignItems="center">
                Brak obiektów
              </Flex>
            )}
          </Box>
        )}
      </PageLayout>
    </>
  );
};
