import { DICTIONARY_TAGS_URL } from "constants/path";
import { DisruptionTagsType } from "types/disruption";
import { useFetchAPI } from "./fetchAPI";

export const useFetchDictionaryTags = (objectType = "Disruption") => {
  const { data } = useFetchAPI<{ data: DisruptionTagsType }>(
    DICTIONARY_TAGS_URL + `?filter[objectType]=${objectType}`
  );

  return { dictionaryTags: data?.data };
};
