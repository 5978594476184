import { useAuthStateContext } from "contexts/AuthContext";
import { Redirect, Route } from "react-router-dom";
import { IRoute } from "types/route";

export const RouteWithSubRoutes = (route: IRoute) => {
  /** Authenticated flag */
  const { token } = useAuthStateContext();
  const authenticated = token !== null;

  return (
    <Route
      path={route.path}
      render={(props) =>
        route.redirect ? (
          <Redirect to={route.redirect} />
        ) : route.private ? (
          authenticated ? (
            route.component && (
              <route.component {...props} routes={route.routes} />
            )
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          route.component && (
            <route.component {...props} routes={route.routes} />
          )
        )
      }
    />
  );
};
