import { fetchAPI } from "utilities/fetchAPI";

export const getDisruptionByTypes = () => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/chart/disruption/dashboard/stats`
  );
};

export const getDisruptionByPartner = () => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/chart/disruption/dashboard/partners`
  );
};

export const getPartnersDisruptionsByDate = (
  startDate: string,
  endDate: string
) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/chart/disruption/dashboard/partners/bar/${startDate}/${endDate}`
  );

export const getStatsForStatuses = (partnerId: string) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/chart/disruption/dashboard/statuses${
      partnerId ? `/${partnerId}` : ""
    }`
  );
export const getStatsForCategories = (partnerId: string) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/chart/disruption/dashboard/categories${
      partnerId ? `/${partnerId}` : ""
    }`
  );
export const getStatsByUser = (partnerId: string) =>
  fetchAPI(
    `${
      process.env.REACT_APP_API_URL
    }/chart/disruption/dashboard/assigned-users${
      partnerId ? `/${partnerId}` : ""
    }`
  );
