/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
import React, { useCallback, useRef, useState, useEffect } from "react";
import styled from "styled-components";
import {
  useTable,
  Column,
  useFlexLayout,
  useResizeColumns,
  Row,
  useSortBy,
  HeaderGroup,
  useExpanded,
  SortingRule,
  IdType,
  useBlockLayout,
  useRowState,
  useRowSelect,
} from "react-table";
import AutoSizer from "react-virtualized-auto-sizer";
// import InfiniteLoader from "react-window-infinite-loader";
import { Box } from "./Box";
import { Flex } from "./Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import InfiniteLoader from "react-window-infinite-loader";
import is from "date-fns/esm/locale/is/index.js";
//import { TableSidebar } from "./TableSidebar";
import { TableType } from "types/tableType";
import { Button } from "./Button";
import { Card } from "./Card";
import { useSticky } from "react-table-sticky";
import { useAuthStateContext } from "contexts/AuthContext";
// import { ConditionalWrapper } from "./ConditionalWrapper";
import { FixedSizeList } from "react-window";
import {
  useTableSidebarDispatchContext,
  useTableSidebarContext,
} from "contexts/TableSidebarContext";
import Flag from "./Flag";
import { isSidebarOpen } from "utilities/toggleSidebar";
import { useWindowSize } from "../hooks/useWindowSize";

const TableCard = styled(Card)`
  ${({ theme }) => `
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${theme.space[4]}px !important;
  `};
`;

interface ITable<T extends Record<string, unknown>> {
  disableReactWindow?: boolean;
  fixedWidthColumnsCalc?: boolean;
  pageSizeOptions?: Array<T>;
  defaultPageSize?: number;
  showPageSizeOptions?: boolean;
  height?: number;
  rowHeight?: number;
  columns: Array<Column<T>>;
  data: Array<T>;
  onRowClick?: (row: Row<T>) => any;
  getLink?: (row: Row<T>) => string;
  onChangeSort?: (sortingRule: SortingRule<T>[]) => void;
  fullHeight?: boolean;
  isNextPageLoading?: boolean;
  loadNextPage?: (startIndex: number, stopIndex: number) => Promise<any>;
  tableType?: TableType;
  FilterComponent?: () => JSX.Element;
  RaportDownloadComponent?: () => JSX.Element;
  RaportExportComponent?: () => JSX.Element;
  fetchRowData?: (id: any) => Promise<any>;
  showSidebar?: boolean;
  useWhiteBGForStickyColumns?: boolean;
  onScrollDown?: () => void;
  customRow?: (
    row: Row<T>,
    toggleRowExpanded?: (id: Array<IdType<T>>, value?: boolean) => void
  ) => any;
  onScrollUp?: () => void;
}

const RowLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

interface IStyles {
  isFirstColumnSticky: boolean;
  isLastColumnSticky: boolean;
  useWhiteBGForStickyColumns: boolean;
}

const Styles = styled.div<IStyles>`
  ${({
    theme,
    isFirstColumnSticky,
    isLastColumnSticky,
    useWhiteBGForStickyColumns,
  }) => `
    ${
      "" /* These styles are required for a horizontaly scrollable table overflow */
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .table {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-spacing: 0;
      // border: 1px solid black;

      .thead {
        ${
          "" /* These styles are required for a scrollable body to align with the header properly */
        }
        // overflow-y: auto;
        // overflow-x: hidden;
        color: rgba(19, 19, 21, 0.65);
        font-size: 11px;
        line-height: 15px;
        font-weight: 700;
        letter-spacing: 1px;
      }

      .tbody {
        ${"" /* These styles are required for a scrollable table body */}
        // overflow-y: scroll;
        // overflow-x: hidden;
        // height: 250px;

        flex: 1;
        color: ${theme.palette.text.primary};
        font-family: Lato;
        font-size: 13px;
        letter-spacing: 0.4px;
        line-height: 16px;
      }


      .td {
        // border: 1px solid rgba(30,46,102,0.1);
        // border-width: 1px 0;
        overflow-wrap: anywhere;
        display: flex;
        align-items: center;

        &:first-child {
          // border-top-left-radius: 8px;
          // border-bottom-left-radius: 8px;
          // border-left-width: 1px;
          padding-left: ${theme.space[4] - 1}px;
        }

        &:last-child {
          // border-top-right-radius: 8px;
          // border-bottom-right-radius: 8px;
          // border-right-width: 1px;
          padding-right: ${theme.space[4] - 1}px;
        }
      }

      .th,
      .td {
        margin: 0;
        padding: ${theme.space[4]}px;
        // border-right: 1px solid black;

        ${
          "" /* In this example we use an absolutely position resizer,
        so this is required. */
        }
        // position: relative;
      }

      &.sticky {

        overflow: auto;

        .header
         {
          display: flex;
          position: sticky;
          z-index: 1;
          width: fit-content;
          background: white;
          width: 100%;
        }

        .header {
          top: 0;
          // box-shadow: 0px 3px 3px #ccc;

          .tr{
            border-bottom: 1px solid gray;
            background-color: #fff;
          }

          [data-sticky-td] {

            &:first-child {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }

            &:last-child {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }

        .body {
          display: flex;
          position: relative;
          flex-direction: column;
          z-index: 0;
          .selected {
            background-color: #f1f1f1;
          }
        }

        [data-sticky-td] {
          position: sticky;
        }

        [data-sticky-last-left-td] {
          transition: box-shadow 1s ease-out;
          box-shadow: ${isFirstColumnSticky && "2px 0px 3px #ccc"};
          background-color: ${useWhiteBGForStickyColumns ? "white" : "inherit"};
        }

        [data-sticky-first-right-td] {
          transition: box-shadow 1s ease-out;
          box-shadow: ${isLastColumnSticky && "-2px 0px 3px #ccc"};
          background-color: ${useWhiteBGForStickyColumns ? "white" : "inherit"};
        }
      }

    }
  `}
`;
const TableSticky = styled.div`
  width: 100%;
  height: 100%;
  > div {
    height: auto !important;
  }
`;

export const Table = <T extends Record<string, unknown>>({
  columns,
  fixedWidthColumnsCalc,
  disableReactWindow = false,
  rowHeight = 70,
  data,
  onRowClick,
  getLink,
  height,
  onChangeSort,
  fullHeight = true,
  tableType,
  FilterComponent,
  RaportDownloadComponent,
  RaportExportComponent,
  fetchRowData,
  showSidebar = true,
  onScrollDown,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Callback function responsible for loading the next page of items.
  loadNextPage,
  useWhiteBGForStickyColumns = true,
  customRow,
  onScrollUp,
}: ITable<T>) => {
  const tableBodyRef = useRef<HTMLDivElement>(null);
  // Use the state and functions returned from useTable to build your UI
  const [isFirstColumnSticky, setFirstColumnSticky] = useState(false);
  const [isLastColumnSticky, setLastColumnSticky] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const { userProfile } = useAuthStateContext();
  const { setTableData } = useTableSidebarDispatchContext();
  const { tableData } = useTableSidebarContext();

  // Pagination variables
  const [pageNumber, setPageNumber] = useState(10);
  const [activePageNumber, setActivePageNumber] = useState(1);

  // Pagination functions
  const clickPageNumber = (pageNum: number) => {
    setActivePageNumber(pageNum);
  };

  const sidebarIsOpen = isSidebarOpen();

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const columnsAccessors = columns.map((column) => column.accessor);
  const hiddenColumns = columnsAccessors.filter((col) => {
    if (tableType === TableType.DATA_LIST) "";
    return (
      userProfile?.columnsConfig &&
      tableType &&
      !userProfile?.columnsConfig[tableType]?.includes(col as string)
    );
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    allColumns,
    rows,
    prepareRow,
    state: { sortBy, selectedRowIds },
    toggleRowExpanded,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getSubRows: (row: any) => row?.subRows || [],
      disableMultiSort: false,
      initialState: {
        hiddenColumns: hiddenColumns as string[],
      },
    },
    useFlexLayout,
    useResizeColumns,
    useSticky,
    useSortBy,
    useExpanded,
    useRowState
  );

  const columnsWidth =
    Number(
      allColumns
        .map((el) => el.width)
        .reduce((a, b) => Number(a) + Number(b), 0)
    ) + 72;

  const generateSortingIndicator = (column: HeaderGroup<T>) => {
    const icon = column.isSorted
      ? column.isSortedDesc
        ? faSortDown
        : faSortUp
      : faSort;

    return (
      <Box ml={1}>
        <FontAwesomeIcon icon={icon} />
      </Box>
    );
  };

  const onTableScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
      if (scrollLeft === 0) {
        setFirstColumnSticky(false);
      } else {
        setFirstColumnSticky(true);
      }
      if (scrollLeft === scrollWidth - clientWidth) {
        setLastColumnSticky(false);
      } else {
        setLastColumnSticky(true);
      }
    },
    []
  );

  useEffect(() => {
    setTableData({
      ...tableData,
      tableType,
      allColumns,
      FilterComponent,
      fetchRowData,
      detailsObject: true,
    });
  }, []);

  const containerRef = useRef();
  useEffect(() => {
    if (containerRef && containerRef.current)
      // @ts-ignore
      containerRef.current.addEventListener("scroll", onTableScroll);
    return function cleanup() {
      if (containerRef && containerRef.current)
        // @ts-ignore
        containerRef.current.removeEventListener("scroll", onTableScroll);
    };
  });

  useEffect(() => {
    onChangeSort && onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  const handleScroll = () => {
    /*
    // @ts-ignore
    const { scrollHeight, scrollTop, clientHeight } = containerRef?.current;

    const bottom =
      scrollHeight - Math.floor(scrollTop) === clientHeight ||
      scrollHeight - Math.floor(scrollTop) === clientHeight - 1;

    const top = scrollTop === 0;

    if (bottom && onScrollDown) {
      // @ts-ignore
      containerRef?.current?.scrollTo({ top: 70 });
      onScrollDown();
    }
    if (top && onScrollUp) {
      // @ts-ignore
      setTimeout(() => containerRef?.current?.scrollTo({ top: 70 }), 0);
      onScrollUp();
    }
    */
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return customRow ? (
        customRow(row, toggleRowExpanded)
      ) : (
        <div
          {...row.getRowProps({
            style,
          })}
          onClick={
            onRowClick
              ? () => {
                  setSelectedRow(row.original.id as string);
                  onRowClick(row);
                }
              : undefined
          }
          // @ts-ignore
          className={`tr${onRowClick ? " u-pointer" : ""} ${
            selectedRow === row.original.id ? "selected" : ""
          }`}
        >
          {row.cells.map((cell, index) => {
            const div = (
              <div {...cell.getCellProps()} className="td">
                {cell.column.Header === "ID" && row.original.flag && (
                  <Flag active={true} isTable />
                )}
                {cell.render("Cell")}
              </div>
            );

            return div;
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  const [width] = useWindowSize();
  const tableWidth = fixedWidthColumnsCalc
    ? sidebarIsOpen
      ? width - 560 > columnsWidth
        ? columnsWidth
        : `calc(${columnsWidth}px - 300px)`
      : columnsWidth
    : sidebarIsOpen
    ? "calc(100% - 10px)"
    : "100%";

  // Render the UI for your table
  return (
    <TableCard
      // todo change solution to replace table width
      key={sidebarIsOpen}
      style={{
        width: tableWidth,
        margin: "0 auto",
        border: "20px solid #f8f8fa",
        borderBottom: "0",
        overflow: "auto",
        maxHeight: "calc(100vh - 150px)", // table card height
        marginBottom: "50px",
      }}
    >
      <Styles
        useWhiteBGForStickyColumns={useWhiteBGForStickyColumns}
        isFirstColumnSticky={isFirstColumnSticky}
        isLastColumnSticky={isLastColumnSticky}
      >
        {disableReactWindow ? (
          <TableSticky
            {...getTableProps()}
            className="table sticky"
            style={{ width: "100%", height: "100%" }}
            // onScroll={onTableScroll}
          >
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="th"
                    >
                      <Flex>
                        {column.render("Header")}
                        {column.disableSortBy ? null : (
                          <span>{generateSortingIndicator(column)}</span>
                        )}
                      </Flex>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} ref={tableBodyRef} className="body">
              {rows.map((row, i) => {
                prepareRow(row);

                return customRow ? (
                  customRow(row, toggleRowExpanded)
                ) : (
                  <Flex
                    {...row.getRowProps()}
                    key={i}
                    onClick={
                      onRowClick
                        ? () => {
                            onRowClick(row);
                          }
                        : undefined
                    }
                    // @ts-ignore
                    className={`tr${onRowClick ? " u-pointer" : ""}`}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <div {...cell.getCellProps()} className="td">
                          {cell.column.Header === "ID" && row.original.flag && (
                            <Flag active={true} isTable />
                          )}
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </Flex>
                );
              })}
            </div>
          </TableSticky>
        ) : (
          <TableSticky
            {...getTableProps()}
            className="table sticky"
            style={{ width: "100%", height: "100%" }}
          >
            <FixedSizeList
              outerRef={containerRef}
              onScroll={handleScroll}
              // className="hidden-scrollbar"
              innerElementType={({ children, style, ...rest }: any) => {
                return (
                  <>
                    <div className="header">
                      {headerGroups.map((headerGroup) => (
                        <div
                          {...headerGroup.getHeaderGroupProps()}
                          className="tr"
                        >
                          {headerGroup.headers.map((column) => (
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              className="th"
                            >
                              <Flex>
                                {column.render("Header")}
                                {column.disableSortBy ? null : (
                                  <span>
                                    {generateSortingIndicator(column)}
                                  </span>
                                )}
                              </Flex>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className="body">
                      <div {...getTableBodyProps()} {...rest} style={style}>
                        {children}
                      </div>
                    </div>
                  </>
                );
              }}
              height={
                fullHeight
                  ? rows.length * rowHeight + rowHeight
                  : height
                  ? height
                  : 1200
              }
              itemCount={rows.length}
              itemData={rows}
              itemSize={rowHeight}
              width="100%"
            >
              {RenderRow}
            </FixedSizeList>
          </TableSticky>
        )}
      </Styles>
    </TableCard>
  );
};
