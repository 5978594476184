import axios from "axios";
import qs from "qs";
import { IPagination } from "types/pagination";
import { IReport } from "types/report";
import { fetchAPI } from "utilities/fetchAPI";

interface IFetchReports {
  limit?: number;
  offset?: number;
  user?: string;
  type?: string;
}

interface IFetchDisruptionsResponse {
  data: Array<IReport>;
  pagination: IPagination;
}

export const fetchReports = ({
  limit = 100,
  offset = 0,
  user,
  type,
}: IFetchReports = {}) => {
  const query = qs.stringify(
    {
      limit,
      offset,
      filter: {
        user,
        type,
      },
    },
    { arrayFormat: "brackets", skipNulls: true }
  );
  return fetchAPI<IFetchDisruptionsResponse>(
    `${process.env.REACT_APP_API_URL}/report-log?${query}`
  );
};

export const downloadReport = (dateFrom: string, dateTo: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/report/disruption/updated/${dateFrom}/${dateTo}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const downloadReportById = (id: string) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/report/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
