import React, { useContext, useState } from "react";

export interface IFilters {
  keyNumber: string;
  object: string;
  contractor: string;
  recipientName: string;
  recipientEmail: string;
}

export interface IKeysStateContext {
  initialFilters: IFilters;
  filters: IFilters;
}

export interface IKeysDispatchContext {
  setFilters: (filters: IFilters) => void;
  cleanFilters: () => void;
}

const KeysStateContext = React.createContext<IKeysStateContext | undefined>(
  undefined
);

const KeysDispatchContext = React.createContext<
  IKeysDispatchContext | undefined
>(undefined);

export const KeysContextProvider: React.FC = ({ children }) => {
  const initialFilters: IFilters = {
    keyNumber: "",
    object: "",
    contractor: "",
    recipientName: "",
    recipientEmail: "",
  };
  const [filters, setFilters] = useState<IFilters>(initialFilters);

  const cleanFilters = () => {
    setFilters(initialFilters);
  };

  return (
    <KeysStateContext.Provider value={{ filters, initialFilters }}>
      <KeysDispatchContext.Provider
        value={{
          setFilters,
          cleanFilters,
        }}
      >
        {children}
      </KeysDispatchContext.Provider>
    </KeysStateContext.Provider>
  );
};

export const useKeysStateContext = () => {
  const context = useContext(KeysStateContext);
  if (!context) {
    throw new Error(
      "useKeysStateContext must be inside a KeysStateContextProvider with a value"
    );
  }
  return context;
};

export const useKeysDispatchContext = () => {
  const context = useContext(KeysDispatchContext);
  if (!context) {
    throw new Error(
      "useKeysDispatchContext must be inside a KeysStateDispatchProvider with a value"
    );
  }
  return context;
};
