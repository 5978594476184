import { DICTIONARY_TAGS_URL } from "constants/path";
import qs from "qs";
import { DisruptionTagsType } from "types/disruption";
import { fetchAPI } from "utilities/fetchAPI";

export const fetchTags = ({
  objectType = "Disruption",
}: {
  objectType: string;
}) => {
  const query = qs.stringify(
    {
      filter: { objectType },
    },
    { arrayFormat: "brackets", skipNulls: true }
  );
  return fetchAPI<{ data: DisruptionTagsType }>(
    `${DICTIONARY_TAGS_URL}?${query}`
  );
};
