import { getPossibleImpacts } from "constants/path";
import { useFetchAPI } from "./fetchAPI";

export const useFetchDictionaryPossibleImpacts = (taskId: string) => {
  const { data } = useFetchAPI<{ id: string; name: string }[]>(
    getPossibleImpacts(taskId)
  );

  return { dictionaryPossibleImpacts: data };
};
