import { StyledCard, StyledFlex, StyledTitle } from "./Accordion.styled";
import { ReactComponent as AccordionIcon } from "icons/accordionIcon.svg";
import { Flex } from "components/Flex";
import { ReactNode, useState } from "react";

interface AccordionProps {
  children?: ReactNode;
  title: string;
  isAccordionOpen?: boolean;
  OnOpenClick?: (arg?: number) => void;
}

export const Accordion = ({
  title,
  OnOpenClick,
  children,
  isAccordionOpen = false,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isAccordionOpen);

  const handleClick = () => {
    OnOpenClick && OnOpenClick();
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <StyledCard onClick={handleClick}>
        <Flex justifyContent="space-between" alignItems="center">
          <StyledTitle>{title}</StyledTitle>
          <AccordionIcon />
        </Flex>
      </StyledCard>
      <StyledFlex isOpen={isOpen}>{children}</StyledFlex>
    </>
  );
};
