import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { PageLayout } from "components/PageLayout";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Card } from "components/Card";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Modal } from "components/Modal";
import { Input } from "components/Input";
import { Textarea } from "components/Textarea";
import { DropArea } from "components/DropArea";
import { Flex } from "components/Flex";
import { AboutModal } from "components/AboutModal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateDataArray } from "utilities/replayItemInDataArray";
import {
  getBacklog,
  IBacklog,
  createBacklog,
  getTaskDetails,
  IRequestNewBacklog,
  updateTask,
  deleteTask,
  getBacklogAttachement,
} from "services/backlog";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useFetchDictionaryUsers } from "hooks/fetchDictionaryUsers";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { formatDate } from "utilities/formatDate";
import { format } from "date-fns";
import { downloadFile } from "utilities/downloadFile";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface IDataRequest {
  title: string;
  description: string;
  status: boolean;
}
interface IForm {
  title: string;
  description: string;
  status: boolean;
}
interface IStyledText {
  status?: boolean;
}

const FlexBox = styled(Box)<{
  $flexDirection?: string;
  $gap?: string;
  $alignItems?: string;
  $justifyContent?: string;
}>`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  gap: ${({ $gap }) => $gap};
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  padding: 50px 30px;
`;

const StyledText = styled.p<IStyledText>`
  ${({ status }) => `
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
  ${
    status &&
    `
      text-decoration: line-through;
      opacity: 0.5;
    `
  }
`}
`;

const StyledBox = styled(Box)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: ${theme.space[4]}px;
    background: ${theme.palette.background};
    border-radius: ${theme.shape.borderRadius}px;
    padding: 10px;
  `}
`;

const StyledTitle = styled.h3`
  ${({ theme }) => `
    font-color: ${theme.palette.text.primary}
    margin: 0;
  `}
`;
const StyledCard = styled(Card)`
  padding: 20px;
  height: 90%;
  overflow-y: auto;
`;
const ButtonUpload = styled.button`
  background-color: transparent;
  border: none;
`;
const StyledImg = styled.img`
  width: 400px;
`;

export const Backlog = () => {
  const [backlogList, setBacklogList] = useState<IBacklog[]>([]);
  const [taskDetails, setTaskDetails] = useState<IBacklog | null>(null);

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenAboutModal, setIsOpenAboutModal] = useState(false);
  const [preview, setPreview] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, register } = useForm<IForm>();

  const { dictionaryUsers } = useFetchDictionaryUsers();

  const { setTableData } = useTableSidebarDispatchContext();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const getBacklogs = async () => {
    getBacklog()
      .then(({ data }) => {
        setBacklogList(data);
      })
      .catch(() => toast.error("Nie udało się pobrać backlogu."));
  };

  const onSubmit = ({ title, description }: IRequestNewBacklog) => {
    const data = new FormData();

    data.append("title", title);
    data.append("description", description);
    data.append("file", file);
    data.append("status", "0");

    createBacklog(data)
      .then(() => {
        setIsOpenAddModal(false);
        setPreview("");
        setFile(null);
        toast.success("Dodano nowe zgłoszenie");
        setTimeout(() => {
          getBacklogs();
        }, 500);
      })
      .catch(() => toast.error("Nie udało się dodać nowego zgłoszenia."));
  };

  const findTaskUser = (userId: string) => {
    if (dictionaryUsers) {
      const foundUser = dictionaryUsers.find((user) => user.id === userId);
      return foundUser?.email ? foundUser.email : "Użytkownik nieznany";
    } else {
      return "Użytkownik nieznany";
    }
  };

  const updatedTask = (id: string, data: IDataRequest) => {
    const req = { ...data, file: preview };
    updateTask(id, req)
      .then((res) => {
        setIsOpenAboutModal(false);
        setTimeout(() => {
          getBacklogs();
        }, 500);
        toast.success("Zgłoszenie zostało usunięte.");
      })
      .catch(() => toast.error("Nie udało się usunąć zgłoszenia."));
  };

  const archiveTask = (id: string) => {
    deleteTask(id)
      .then(() => {
        setIsOpenAboutModal(false);
        setTimeout(() => {
          getBacklogs();
        }, 500);
        toast.success("Zgłoszenie zostało zarchiwizowane.");
      })
      .catch(() => toast.error("Nie udało się usunąć zgłoszenia."));
  };

  const handleDownloadAttachment = (id: string, fileName: string) => {
    getBacklogAttachement(id)
      .then((response) => response.blob())
      .then((blob) => {
        downloadFile(blob, `${fileName}`);
        toast.success("Pobrano załącznik.");
      })
      .catch(() => toast.error("Nie udało się pobrać załącznika."));
  };

  const foundUsers = (taskDetails: IBacklog) => ({
    ...taskDetails,
    user: findTaskUser(taskDetails.user),
  });

  const handleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    data: IDataRequest
  ) => {
    const status = event.currentTarget.checked;
    const request = { ...data, file: "", status };
    updateTask(id, request).then((res) =>
      setBacklogList(updateDataArray(backlogList, res))
    );
  };

  const displayDetailsModal = async (taskId: string) => {
    const taskDetails = await getTaskDetails(taskId);
    setTaskDetails(foundUsers(taskDetails));
    setIsOpenAboutModal(true);
  };

  useEffect(() => {
    if (file && file.type.split("/")[0] === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview("");
    }
  }, [file]);

  useEffect(() => {
    getBacklogs();
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, []);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <AboutModal
        onCancelClick={() => setIsOpenAboutModal(false)}
        isOpen={isOpenAboutModal}
        taskDetails={taskDetails}
        handleConfirm={updatedTask}
        onArchive={archiveTask}
        handleDownloadAttachment={handleDownloadAttachment}
      />
      <Modal
        isOpen={isOpenAddModal}
        onCancelClick={() => setIsOpenAddModal(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexBox $flexDirection="column" $gap="12px">
            <Input label="Tytuł zadania" {...register("title")} />
            <Textarea label="Opis" {...register("description")} />
            {preview ? (
              <StyledImg src={preview} />
            ) : file ? (
              <Box ml={2}>{file.name}</Box>
            ) : (
              <ButtonUpload
                onClick={(event) => {
                  event.preventDefault();
                  fileInputRef.current?.click();
                }}
              >
                <DropArea label="Dodaj zalącznik" />
              </ButtonUpload>
            )}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(event) => {
                if (event.target.files) {
                  const targetFile = event.target.files[0];
                  if (targetFile) setFile(targetFile);
                  else setFile(null);
                }
              }}
            />
            <FlexBox $gap="16px">
              <Button
                type="button"
                bordered
                onClick={() => {
                  setPreview("");
                  setFile(null);
                  setIsOpenAddModal(false);
                }}
              >
                Anuluj
              </Button>
              <Button type="submit" bordered>
                Dodaj zadanie
              </Button>
            </FlexBox>
          </FlexBox>
        </form>
      </Modal>
      <Flex ml={4} alignItems="center" justifyContent="space-between">
        <StyledTitle>Zgłoszenie klienta</StyledTitle>
        {loggedUserRoles?.includes("ROLE_DISRUPTIONS_BACKLOG_CREATE") && (
          <Button
            type="button"
            bordered
            onClick={() => setIsOpenAddModal(true)}
          >
            + Dodaj nowe zadanie
          </Button>
        )}
      </Flex>
      <Tabs>
        <TabList>
          <Tab>Aktywne</Tab>
          <Tab>Zarchiwizowane</Tab>
        </TabList>

        <TabPanel>
          <StyledCard>
            <FieldSetTitle label="Backlog">
              <StyledBox>
                {backlogList.length > 0 ? (
                  backlogList
                    .filter((item) => !item.archived)
                    .map((task) => (
                      <Flex
                        justifyContent="space-between"
                        flexDirection="row"
                        key={task.id}
                      >
                        <Flex>
                          <Checkbox
                            label=""
                            checked={task.status}
                            onChange={(event) =>
                              handleChangeStatus(event, task.id, {
                                title: task.title,
                                description: task.description,
                                status: task.status,
                              })
                            }
                          />
                          <StyledText
                            status={task.status}
                            onClick={() => {
                              loggedUserRoles?.includes(
                                "ROLE_DISRUPTIONS_BACKLOG_SHOW"
                              ) && displayDetailsModal(task.id);
                            }}
                          >
                            {task.title}
                          </StyledText>
                        </Flex>
                        <StyledText
                          onClick={() => displayDetailsModal(task.id)}
                        >
                          <Flex>
                            <StyledText status={task.status}>
                              ({findTaskUser(task.user)})
                            </StyledText>
                            <StyledText status={task.status}>
                              {format(
                                new Date(task.createdAt),
                                "dd-MM-yyyy HH:mm"
                              )}
                            </StyledText>
                          </Flex>
                        </StyledText>
                      </Flex>
                    ))
                ) : (
                  <p>brak zgłoszeń</p>
                )}
              </StyledBox>
            </FieldSetTitle>
          </StyledCard>
        </TabPanel>
        <TabPanel>
          <StyledCard>
            <FieldSetTitle label="Backlog">
              <StyledBox>
                {backlogList.length > 0 ? (
                  backlogList
                    .filter((item) => !!item.archived)
                    .map((task) => (
                      <Flex
                        justifyContent="space-between"
                        flexDirection="row"
                        key={task.id}
                      >
                        <Flex>
                          <Checkbox
                            label=""
                            checked={task.status}
                            onChange={(event) =>
                              handleChangeStatus(event, task.id, {
                                title: task.title,
                                description: task.description,
                                status: task.status,
                              })
                            }
                          />
                          <StyledText
                            status={task.status}
                            onClick={() => displayDetailsModal(task.id)}
                          >
                            {task.title}
                          </StyledText>
                        </Flex>
                        <StyledText
                          onClick={() => displayDetailsModal(task.id)}
                        >
                          <Flex>
                            <StyledText status={task.status}>
                              ({findTaskUser(task.user)})
                            </StyledText>
                            <StyledText status={task.status}>
                              {formatDate(new Date(task.createdAt))}
                            </StyledText>
                          </Flex>
                        </StyledText>
                      </Flex>
                    ))
                ) : (
                  <p>brak zgłoszeń</p>
                )}
              </StyledBox>
            </FieldSetTitle>
          </StyledCard>
        </TabPanel>
      </Tabs>
    </PageLayout>
  );
};
