/* eslint-disable import/named */
import { Box } from "components/Box";
import { Card } from "components/Card";
import {
  WrapperFixedButtons,
  Filters,
} from "views/TaskTypeView/TaskType.styled";

import { Input } from "components/Input";
import { Button } from "components/Button";
import {
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import { Flex } from "components/Flex";
import {
  IFilters,
  useKeysDispatchContext,
  useKeysStateContext,
} from "contexts/KeysContext";

export const KeysFilter = ({
  reset,
  handleSubmit,
  register,
}: {
  reset: UseFormReset<IFilters>;
  handleSubmit: UseFormHandleSubmit<IFilters>;
  register: UseFormRegister<IFilters>;
}) => {
  const { initialFilters } = useKeysStateContext();
  const { setFilters, cleanFilters } = useKeysDispatchContext();

  const onSubmit = (data: IFilters) => {
    setFilters(data);
    localStorage.setItem("keys_filters", JSON.stringify(data));
  };

  const cancelFilters = () => {
    reset(initialFilters);
    cleanFilters();
    localStorage.setItem(`keys_filters`, JSON.stringify(initialFilters));
  };

  return (
    <Card style={{ marginBottom: "1rem" }}>
      <Box px={4} pb={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <WrapperFixedButtons mb={5} py={2}>
            <Flex>
              <Button type="submit" bordered>
                Filtruj
              </Button>
              <Box ml={2}>
                <Button onClick={() => cancelFilters()}>Wyczyść</Button>
              </Box>
            </Flex>
          </WrapperFixedButtons>

          <Filters itemsInRowNumber={1}>
            <Box pt={12}>
              <Input label="Numer klucza" {...register("keyNumber")} />
            </Box>
            <Box>
              <Input label="obiekt" {...register("object")} />
            </Box>
            <Box>
              <Input label="Wykonawca" {...register("contractor")} />
            </Box>
            <Box>
              <Input label="odbiorca" {...register("recipientName")} />
            </Box>
            <Box>
              <Input label="email odbiorcy" {...register("recipientEmail")} />
            </Box>
          </Filters>
        </form>
      </Box>
    </Card>
  );
};
