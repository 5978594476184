import { Box } from "components/Box";
import { Flex } from "components/Flex";
import styled from "styled-components";
import optionsImage from "../../icons/options.png";

export const Header = styled(Box)`
  font-weight: bold;
`;

export const TagTableWrapper = styled(Flex)`
  max-width: 500px;
`;

export const TableRow = styled(Flex).attrs({
  justifyContent: "space-between",
})`
  > * {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > p {
    margin: 0;
  }
`;
export const IconOptions = styled.img.attrs({
  alt: "options",
  width: 4,
  height: 18,
  src: optionsImage,
})`
  cursor: pointer;
  max-width: 4px;
`;
