import * as yup from "yup";

export const updatePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .min(6, "Stare Hasło musi być większe od 6 znaków.")
    .max(128, "Stare Hasło musi być mniej od 128 znaków."),
  password: yup
    .string()
    .min(6, "Hasło musi być większe od 6 znaków.")
    .max(128, "Hasło musi być mniej od 128 znaków."),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Hasła nie pasują do siebie."),
});
