import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import SearchInput from "components/Search/Search";
import { PermissionsCard } from "containers/PermissionsCard/PermissionsCard";
import { UserGroupForm } from "containers/UserGroupForm/UserGroupForm";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUserGroups, getUserRoles } from "services/permissions";
import { IUserGroup } from "types/permissions";
import {
  ContentWrapper,
  Header,
  HelperPanelWrapper,
} from "./PermissionsView.styled";

const PermissionsView: FC = () => {
  const { setTableData } = useTableSidebarDispatchContext();
  const [searchValue, setSearchValue] = useState("");
  const [isUserGroupFormOpen, setIsUserGroupFormOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [chosenUserGroup, setChosenUserGroup] = useState<IUserGroup>();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const fetchUsersGroups = async () => {
    try {
      const { data } = await getUserGroups();
      setUserGroups(data);
    } catch (error) {
      toast.error("Nie udało się pobrać użytkowników.");
    }
  };

  useEffect(() => {
    fetchUsersGroups();
    getUserRoles();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, [setTableData]);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      {isUserGroupFormOpen ? (
        <>
          <Flex>
            <HelperPanelWrapper>
              Dodawanie nowego użytkownika
            </HelperPanelWrapper>
            <Box mt={4} mr={6}>
              <Button
                bordered
                onClick={() => {
                  setIsUserGroupFormOpen((prevState) => !prevState);
                  setChosenUserGroup(null);
                }}
              >
                Wróć
              </Button>
            </Box>
          </Flex>

          <ContentWrapper>
            <UserGroupForm
              chosenUserGroup={chosenUserGroup}
              setIsUserGroupFormOpen={setIsUserGroupFormOpen}
              fetchUsersGroups={fetchUsersGroups}
            />
          </ContentWrapper>
        </>
      ) : (
        <>
          <HelperPanelWrapper>
            <Header>Lista grup użytkowników</Header>
            <Box mr={10}>
              <ButtonsGroup>
                {loggedUserRoles?.includes("ROLE_USER_GROUP_CREATE") && (
                  <Button
                    bordered
                    onClick={() => {
                      setIsUserGroupFormOpen(true);
                      setChosenUserGroup(null);
                    }}
                  >
                    Dodaj
                  </Button>
                )}
                <SearchInput
                  placeholder="Szukaj"
                  value={searchValue}
                  onChange={setSearchValue}
                />
              </ButtonsGroup>
            </Box>
          </HelperPanelWrapper>
          <ContentWrapper>
            <PermissionsCard
              userGroups={userGroups}
              setUserGroups={setUserGroups}
              setChosenUserGroup={setChosenUserGroup}
              chosenUserGroup={chosenUserGroup}
              setIsUserGroupFormOpen={setIsUserGroupFormOpen}
            />
          </ContentWrapper>
        </>
      )}
    </PageLayout>
  );
};

export default PermissionsView;
