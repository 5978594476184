import { useState } from "react";
import { Button } from "components/Button";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { FieldSetTitle } from "components/FieldSetTitle";
import { ImplementationForm } from "./ImplementationForm";
import styled from "styled-components";
import { IAntenna } from "types/antenna";
import { updateTaskAntennas } from "services/tasks";
import { toast } from "react-toastify";
import { useNonInitialEffect } from "hooks/useNonInitialEffect";

const SingleImplementation = styled.div`
  ${({ theme }) => `
    position: relative;
    padding-left: ${theme.space[8]}px;

    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      background-color: ${theme.palette.primary};
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:not(:last-child) {
      margin-bottom: ${theme.space[12]}px;
    }
  `};
`;

interface IImplementation {
  taskId: string;
  antennas: Array<IAntenna>;
}

export const Implementation: React.FC<IImplementation> = ({
  taskId,
  antennas: initialAntennas,
}) => {
  const [antennas, setAntennas] = useState<Array<IAntenna>>(initialAntennas);
  const [showAddAntennaForm, setShowAddAntennaForm] = useState(false);

  const onUpdateAntennaFormSubmit = (
    newAntenna: IAntenna,
    indexToReplace: number
  ) => {
    setAntennas((prevState) => {
      const newState = prevState.map((antenna, index) =>
        index === indexToReplace ? newAntenna : antenna
      );

      return newState;
    });
  };

  const onAddAntennaFormSubmit = (newAntenna: IAntenna) => {
    setAntennas((prevState) => [...prevState, newAntenna]);
    setShowAddAntennaForm(false);
  };

  useNonInitialEffect(() => {
    updateTaskAntennas({ taskId, antennas })
      .then(() => {
        toast.success("Implementacja zadania została zaktualizowana.");
      })
      .catch(() => {
        toast.error("Nie udało się zaktualizować implementacji zadania.");
      });
  }, [antennas]);

  return (
    <>
      <Flex justifyContent="space-between" mb={4}>
        <FieldSetTitle>{antennas.length}/9 anten</FieldSetTitle>
        {antennas.length < 9 ? (
          <Button
            bordered
            disabled={showAddAntennaForm}
            onClick={() => setShowAddAntennaForm(true)}
          >
            Dodaj
          </Button>
        ) : null}
      </Flex>
      {showAddAntennaForm ? (
        <Box mb={4}>
          <ImplementationForm onSubmit={onAddAntennaFormSubmit} />
        </Box>
      ) : null}
      <div>
        {antennas.map((antenna, index) => (
          <SingleImplementation key={index}>
            <ImplementationForm
              antenna={antenna}
              onSubmit={(antenna) => onUpdateAntennaFormSubmit(antenna, index)}
              number={index + 1}
            />
          </SingleImplementation>
        ))}
      </div>
    </>
  );
};
