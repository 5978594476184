import React, { useState, useEffect, useRef } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Label } from "components/Label";
import { useHistory } from "react-router-dom";
import { Textarea } from "components/Textarea";
import { SearchKeyOR } from "components/SearchKeyOR";
import DatePicker from "react-datepicker";
import {
  deleteKeyAttachment,
  downloadKeyAttachment,
  fetchKeyReturnAttachments,
  fetchKeySingleReturn,
  postKeyReturnRequest,
  updateKeyReturnRequest,
  uploadKeyAttachment,
} from "services/keys";
import { IKeyGivenAttachments } from "types/keys";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { downloadFile } from "utilities/downloadFile";

const StyledFileFlex = styled(Flex)`
  border-radius: 5px;
  border: ${({ theme }) => theme.palette.grey} 2px solid;
`;

export const KeyPublicationReturnForm = ({
  givenRequestId,
  returnRequestId,
  returnId,
}: {
  givenRequestId: string;
  returnRequestId: string;
  returnId: string;
}) => {
  const [finalORs, setFinalORs] = useState<
    Array<{ key: string; quantity: string }>
  >([]);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>();
  const [oldFiles, setOldFiles] = useState<IKeyGivenAttachments[]>();

  const { register, handleSubmit, reset, control, watch, getValues, setValue } =
    useForm();
  const history = useHistory();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadKeyFileForm = (file: File, returnRequestId: string) => {
    const data = new FormData();

    data.append("file", file);
    data.append("keyReturnRequest", returnRequestId);

    uploadKeyAttachment(data).catch(() =>
      toast.error("Nie udało się wgrać pliku.")
    );
  };

  const onsubmit = (data) => {
    let keyData: keyof typeof data;
    for (keyData in data) {
      if (data[keyData] === undefined) delete data[keyData];
    }

    if (returnRequestId) {
      const reqPayload = {
        recipientName: data.recipientName,
        recipientEmail: data.recipientEmail,
        contractor: data.contractor,
        returnDate: data.returnDate,
        comment: data.comment,
      };

      updateKeyReturnRequest({ returnRequestId, payload: reqPayload })
        .then((response) => {
          if (response.id) {
            if (returnRequestId) {
              uploadedFiles?.map((uploadedFile) => {
                uploadKeyFileForm(uploadedFile, returnRequestId);
              });
            }

            reset();
            history.push("/keys-returns");
            toast.success("Edytowano zwrócone klucze.");
          } else {
            toast.error("Nie udało się edutować zwróconych kluczy.");
          }
        })
        .catch(() => {
          toast.error("Nie udało się edutować zwróconych kluczy.");
        });
    } else {
      const reqPayload = {
        givenRequest: givenRequestId,
        recipientName: data.recipientName,
        recipientEmail: data.recipientEmail,
        contractor: data.contractor,
        returnDate: data.returnDate,
        comment: data.comment,
        items: finalORs.map(({ key, quantity }) => ({
          keyGiven: key,
          quantity: quantity,
        })),
      };

      postKeyReturnRequest({ payload: reqPayload })
        .then((response) => {
          if (response.id) {
            uploadedFiles?.map((uploadedFile) => {
              uploadKeyFileForm(uploadedFile, response.id);
            });

            reset();
            history.push("/keys-returns");
            toast.success("Zwrócono klucze.");
          } else {
            toast.error("Nie udało się zwrócić kluczy.");
          }
        })
        .catch(() => {
          toast.error("Nie udało się zwrócić kluczy.");
        });
    }
  };

  useEffect(() => {
    returnId &&
      fetchKeySingleReturn({ returnId }).then((data) => {
        reset({ ...data });
      });
  }, [reset, returnId]);

  useEffect(() => {
    returnRequestId &&
      fetchKeyReturnAttachments({ returnRequestId }).then((data) => {
        setOldFiles(data);
      });
  }, [returnRequestId]);

  return (
    <>
      <Button
        bordered
        onClick={() => {
          history.push(returnRequestId ? "/keys-returns" : "/keys-given");
        }}
      >
        Wróć
      </Button>
      <form onSubmit={handleSubmit(onsubmit)}>
        <Flex justifyContent="flex-start" alignItems="center" p={10} pr={20}>
          <Box mr={2}>
            <Label>Wykonawca</Label>
            <Input {...register("contractor")} />
          </Box>

          <Box mr={2}>
            <Label>Osoba</Label>
            <Input {...register("recipientName")} />
          </Box>

          <Box mr={2}>
            <Label>Email</Label>
            <Input {...register("recipientEmail")} />
          </Box>

          <Box mr={2}>
            <Controller
              control={control}
              name="returnDate"
              render={({ field }) => (
                <DatePicker
                  selected={field.value ? new Date(field.value) : undefined}
                  onChange={(date) => {
                    field.onChange(date instanceof Date ? date : "");
                  }}
                  customInput={
                    <Input label="Data zwrotu kluczy" value={field.value} />
                  }
                  dateFormat="yyyy-MM-dd"
                  portalId="root-datepicker-portal"
                />
              )}
            />
          </Box>
        </Flex>

        {!returnRequestId && (
          <SearchKeyOR
            type="return"
            givenRequestId={givenRequestId}
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            register={register}
            setFinalORs={setFinalORs}
          />
        )}

        <Flex px={10} mb={5}>
          <Textarea fullWidth label="Uwagi" {...register("comment")} />
        </Flex>

        <Flex>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(event) => {
              if (event.target.files) {
                setUploadedFiles(Array.from(event.target.files));
              }
            }}
          />

          <Flex mt={1} flexWrap="wrap">
            {uploadedFiles?.map((file) => (
              <StyledFileFlex key={file.name} pl={2} mr={3} alignItems="center">
                {file.name}
                <Button
                  type="button"
                  onClick={() => {
                    setUploadedFiles((prevUploadedFiles) =>
                      prevUploadedFiles.filter(
                        (prevUploadedFile) =>
                          prevUploadedFile.name !== file.name
                      )
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </StyledFileFlex>
            ))}

            {oldFiles?.map((file) => (
              <StyledFileFlex key={file?.id} pl={2} mr={3} alignItems="center">
                {file?.fileName}
                <Button
                  type="button"
                  onClick={() => {
                    downloadKeyAttachment(file?.id)
                      .then((response) => response.blob())
                      .then((blob) => {
                        downloadFile(blob, file?.fileName);
                      })
                      .catch(() => toast.error("Nie udało się pobrać pliku."));
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    deleteKeyAttachment(file?.id)
                      .then(() => {
                        setOldFiles((prevOldFiles) =>
                          prevOldFiles.filter(
                            (prevOldFile) => prevOldFile.id !== file?.id
                          )
                        );
                      })
                      .catch(() => toast.error("Nie udało się usunąć pliku."));
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </StyledFileFlex>
            ))}

            <Button
              type="button"
              bordered
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current?.click();
              }}
            >
              Wgraj pliki
            </Button>
          </Flex>
        </Flex>

        <Flex justifyContent="flex-end" mr={10}>
          <Button type="submit" bordered>
            Zapisz
          </Button>
        </Flex>
      </form>
    </>
  );
};
