/* eslint-disable import/named */
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset, UseFormSetValue,
  UseFormWatch
} from "react-hook-form";
import MultiSelect from "react-select";
import {
  fetchAvailableForKeyReturnObjects,
  fetchSearchKey,
} from "services/keys";
import styled from "styled-components";
import { Box } from "./Box";
import { Button } from "./Button";
import { Flex } from "./Flex";
import { Hr } from "./Hr";
import { Input } from "./Input";
import { Label } from "./Label";
import { toast } from "react-toastify";

interface SearchKeyORProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setFinalORs: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        quantity: string;
      }[]
    >
  >;
  type: "publication" | "return";
  givenRequestId?: string;
}

const StyledFlex = styled(Flex)`
  width: 350px;
`;

export const SearchKeyOR = ({
  control,
  watch,
  reset,
  getValues,
  setValue,
  register,
  setFinalORs,
  type = "publication",
  givenRequestId,
}: SearchKeyORProps) => {
  const [isNewOROpen, setIsNewOROpen] = useState(false);
  const [availableORs, setAvailableORs] = useState([]);
  const [addedORs, setAddedORs] = useState([]);

  const handleORsInputSearch = (e) => {
    if (e && type !== "return") {
      _.debounce(() => {
        fetchSearchKey({ phrase: e }).then((data) => {
          setAvailableORs(data);
        });
      }, 1000)();
    }
  };

  useEffect(() => {
    if (type === "return") {
      fetchAvailableForKeyReturnObjects({
        givenRequestId: givenRequestId,
      }).then((data) => {
        setAvailableORs(data.map((item) => ({ ...item, id: item.givenId })));
      });
    }
  }, [givenRequestId, type]);

  return (
    <>
      <Hr />
      <StyledFlex m={2} alignItems="end">
        <Button
          type="button"
          bordered
          onClick={() => {
            setIsNewOROpen(true);
          }}
        >
          Dodaj OR
        </Button>

        {isNewOROpen && (
          <>
            <Box mx={2}>
              <Label>Dodaj OR</Label>

              <Controller
                control={control}
                name="newOR"
                render={({ field: { onChange, value, ref } }) => (
                  <MultiSelect
                    onInputChange={handleORsInputSearch}
                    inputRef={ref}
                    value={value}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={
                      availableORs.length
                        ? availableORs.map((OR) => {
                            const objectCode = OR.objectCode
                              ? `CSC: ${OR.objectCode} - `
                              : "";
                            const objectName = OR.objectName || "";
                            return {
                              label: objectCode + objectName,
                              value: OR.id,
                            };
                          })
                        : []
                    }
                  />
                )}
              />
            </Box>

            <Box ml={2}>
              <Button
                type="button"
                bordered
                onClick={() => {
                  setAddedORs((prevState) => [
                    ...prevState.filter(
                      (item) => watch("newOR").value !== item.id
                    ),
                    availableORs?.find(
                      (item) => item.id === watch("newOR")?.value
                    ),
                  ]);
                  reset({ ...getValues(), newOR: "" });
                  setIsNewOROpen(false);
                }}
              >
                Dodaj
              </Button>
            </Box>
          </>
        )}
      </StyledFlex>

      {addedORs.length > 0 && (
        <>
          <Hr />

          {addedORs.map(
            ({ id, objectName, freeQuantity, toReturnQuantity }) => (
              <Flex
                my={1}
                mx={4}
                justifyContent="space-between"
                alignItems="end"
                key={id}
              >
                <Box mb={2}>{objectName}</Box>
                <Input
                  label="Ilość dostępnych kluczy"
                  value={
                    (type === "publication" ? freeQuantity : toReturnQuantity)
                    // -
                    // parseInt(watch(`${id}-quantity`) || 0)
                  }
                />
                <Input
                  label={`Wprowadź ilość kluczy do ${
                    type === "publication" ? "wydania" : "zwrotu"
                  }`}
                  {...register(`${id}-quantity`)}
                  type="number"
                />
                <Button
                  type="button"
                  bordered
                  onClick={() => {
                    const value = type === "publication" ? freeQuantity : toReturnQuantity
                    if (+value < +watch(`${id}-quantity`)) {
                      setValue(`${id}-quantity`, '')
                      toast.error("Wartość nie może być większą niż ilość dostępnych kluczy")
                      return
                    }
                    if (+watch(`${id}-quantity`) < 1) {
                      setValue(`${id}-quantity`, '')
                      toast.error("Wartość nie może być mniejszą niż 1")
                      return
                    }
                    setFinalORs((prevState) => {
                      return [
                        ...prevState.filter(({ key }) => key !== id),
                        { key: id, quantity: watch(`${id}-quantity`) },
                      ]
                    });
                  }}
                >
                  Zapisz
                </Button>
              </Flex>
            )
          )}
        </>
      )}
      <Hr />
    </>
  );
};
