import { Box } from "../components/Box";
import React from "react";
import styled from "styled-components";
import { ProblemTag } from "components/ProblemTag";
import { formatDate } from "utilities/formatDate";
import { TooltipWrapper } from "components/TooltipWrapper";

const Link = styled.a`
  text-align: center;
`;

export const orrColumnList = [
  {
    width: 70,
    name: "CSC",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/OR,ORR/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 200,
    name: "Nazwa",
    idName: "name",
    value: (e: any) => <Box>{e.objectName ? e.objectName : "--"}</Box>,
  },
  {
    width: 200,
    name: "Nazwa IE-50",
    idName: "objectNameIE50",
    value: (e: any) => (e.objectNameIE50 ? e.objectNameIE50 : "--"),
  },
  {
    width: 200,
    name: "Nazwa lokalizacji",
    idName: "locationName",
    value: (e: any) => (e.locationName ? e.locationName : "--"),
  },
  {
    width: 120,
    name: "Czy jest w HRF",
    idName: "inHrf",
    value: (e: any) =>
      e.inHrf ? (
        <span className="green">tak</span>
      ) : (
        <span className="red">nie</span>
      ),
  },
  {
    width: 140,
    name: "Wykonawca SABP",
    idName: "contractorSA",
    value: (e: any) => (e.contractorSA ? e.contractorSA : "--"),
  },
  {
    width: 130,
    name: "Wykonawca CW",
    idName: "contractorCW",
    value: (e: any) => (e.contractorCW ? e.contractorCW : "--"),
  },
  {
    width: 120,
    name: "Typ obiektu",
    idName: "type",
    value: (e: any) => (e.type ? e.type : "--"),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km",
    idName: "chainage",
    value: (e: any) =>
      e.chainage
        ? `${e.chainage.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 150,
    name: "IZ",
    idName: "iz",
    value: (e: any) => (e.iZ ? e.iZ : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },

  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const otkColumnList = [
  {
    width: 170,
    name: "CSC",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/OTK/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km_od",
    idName: "chainageStart",
    value: (e: any) =>
      e.chainageStart || e.chainageStart === 0
        ? `${e.chainageStart.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 100,
    name: "km_do",
    idName: "chainageEnd",
    value: (e: any) =>
      e.chainageEnd || e.chainageEnd === 0
        ? `${e.chainageEnd.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 100,
    name: "Długość",
    idName: "length",
    value: (e: any) =>
      e.length || e.length === 0
        ? e.length.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })
        : "--",
  },
  {
    width: 120,
    name: "Czas trwania",
    idName: "duration",
    value: (e: any) => (e.duration || e.duration === 0 ? e.duration : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 100,
    name: "Załącznik",
    value: (e: any) => (e.attachment1 ? e.attachment1 : "--"),
  },
  { width: 100, name: "IZ", idName: "iz", value: (e: any) => e.iZ },
  {
    width: 100,
    name: "Etap",
    value: (e: any) => (e.phase ? e.phase : "--"),
  },
  {
    width: 130,
    name: "Wykonawca",
    idName: "company",
    value: (e: any) => (e.company ? e.company : "--"),
  },
  {
    width: 100,
    name: "Rok",
    value: (e: any) =>
      e.startDate ? new Date(e.startDate).getFullYear() : "--",
  },
  {
    width: 100,
    name: "Załącznik_2",
    value: (e: any) => (e.attachment2 ? e.attachment2 : "--"),
  },
  { width: 100, name: "Koordynator", value: (e: any) => "--" },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const fdsColumnList = [
  {
    width: 70,
    name: "CSC",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/FDS/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 200,
    name: "Nazwa",
    idName: "name",
    value: (e: any) => <Box>{e.objectName ? e.objectName : "--"}</Box>,
  },
  {
    width: 200,
    name: "Nazwa lokalizacji",
    idName: "locationName",
    value: (e: any) => (e.locationName ? e.locationName : "--"),
  },
  {
    width: 120,
    name: "Czy jest w HRF",
    idName: "inHrf",
    value: (e: any) =>
      e.inHrf ? (
        <span className="green">tak</span>
      ) : (
        <span className="red">nie</span>
      ),
  },
  {
    width: 130,
    name: "Wykonawca",
    idName: "company",
    value: (e: any) => (e.company ? e.company : "--"),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km",
    idName: "chainageEnd",
    value: (e: any) =>
      e.chainageEnd
        ? `${e.chainageEnd.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 150,
    name: "IZ",
    idName: "iz",
    value: (e: any) => (e.iZ ? e.iZ : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const oszColumnList = [
  {
    width: 70,
    name: "CSC",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/OSZ/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 200,
    name: "Nazwa",
    idName: "name",
    value: (e: any) => <Box>{e.objectName ? e.objectName : "--"}</Box>,
  },
  {
    width: 200,
    name: "Nazwa IE-50",
    idName: "objectNameIE50",
    value: (e: any) => (e.objectNameIE50 ? e.objectNameIE50 : "--"),
  },
  {
    width: 200,
    name: "Nazwa lokalizacji",
    idName: "locationName",
    value: (e: any) => (e.locationName ? e.locationName : "--"),
  },
  {
    width: 120,
    name: "Czy jest w HRF",
    idName: "inHrf",
    value: (e: any) =>
      e.inHrf ? (
        <span className="green">tak</span>
      ) : (
        <span className="red">nie</span>
      ),
  },
  {
    width: 140,
    name: "Wykonawca SABP",
    idName: "contractorSA",
    value: (e: any) => (e.contractorSA ? e.contractorSA : "--"),
  },
  {
    width: 130,
    name: "Wykonawca CW",
    idName: "contractorCW",
    value: (e: any) => (e.contractorCW ? e.contractorCW : "--"),
  },
  {
    width: 120,
    name: "Typ obiektu",
    idName: "type",
    value: (e: any) => (e.type ? e.type : "--"),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km",
    idName: "chainage",
    value: (e: any) =>
      e.chainage
        ? `${e.chainage.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 150,
    name: "IZ",
    idName: "iz",
    value: (e: any) => (e.iZ ? e.iZ : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const ocColumnList = [
  {
    width: 70,
    name: "CSC",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/OC/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 200,
    name: "Nazwa",
    idName: "name",
    value: (e: any) => <Box>{e.objectName ? e.objectName : "--"}</Box>,
  },
  {
    width: 200,
    name: "Nazwa IE-50",
    idName: "objectNameIE50",
    value: (e: any) => (e.objectNameIE50 ? e.objectNameIE50 : "--"),
  },
  {
    width: 200,
    name: "Nazwa lokalizacji",
    idName: "locationName",
    value: (e: any) => (e.locationName ? e.locationName : "--"),
  },
  {
    width: 120,
    name: "Czy jest w HRF",
    idName: "inHrf",
    value: (e: any) =>
      e.inHrf ? (
        <span className="green">tak</span>
      ) : (
        <span className="red">nie</span>
      ),
  },
  {
    width: 140,
    name: "Wykonawca SABP",
    idName: "contractorSA",
    value: (e: any) => (e.contractorSA ? e.contractorSA : "--"),
  },
  {
    width: 130,
    name: "Wykonawca CW",
    idName: "contractorCW",
    value: (e: any) => (e.contractorCW ? e.contractorCW : "--"),
  },
  {
    width: 120,
    name: "Typ obiektu",
    idName: "type",
    value: (e: any) => (e.type ? e.type : "--"),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km",
    idName: "chainage",
    value: (e: any) =>
      e.chainage
        ? `${e.chainage.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 150,
    name: "IZ",
    idName: "iz",
    value: (e: any) => (e.iZ ? e.iZ : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const tunelColumnList = [
  {
    width: 70,
    name: "CSC",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/Tunel/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 200,
    name: "Nazwa",
    idName: "name",
    value: (e: any) => <Box>{e.objectName ? e.objectName : "--"}</Box>,
  },
  {
    width: 200,
    name: "Nazwa IE-50",
    idName: "objectNameIE50",
    value: (e: any) => (e.objectNameIE50 ? e.objectNameIE50 : "--"),
  },
  {
    width: 200,
    name: "Nazwa lokalizacji",
    idName: "locationName",
    value: (e: any) => (e.locationName ? e.locationName : "--"),
  },
  {
    width: 120,
    name: "Czy jest w HRF",
    idName: "inHrf",
    value: (e: any) =>
      e.inHrf ? (
        <span className="green">tak</span>
      ) : (
        <span className="red">nie</span>
      ),
  },
  {
    width: 140,
    name: "Wykonawca SABP",
    idName: "contractorSA",
    value: (e: any) => (e.contractorSA ? e.contractorSA : "--"),
  },
  {
    width: 130,
    name: "Wykonawca CW",
    idName: "contractorCW",
    value: (e: any) => (e.contractorCW ? e.contractorCW : "--"),
  },
  {
    width: 120,
    name: "Typ obiektu",
    idName: "type",
    value: (e: any) => (e.type ? e.type : "--"),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km",
    idName: "chainage",
    value: (e: any) =>
      e.chainage
        ? `${e.chainage.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 150,
    name: "IZ",
    idName: "iz",
    value: (e: any) => (e.iZ ? e.iZ : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const milestoneColumnList = [
  {
    width: 140,
    name: "Numer KM",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/Milestone/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km_od",
    idName: "chainageStart",
    value: (e: any) =>
      e.chainageStart || e.chainageStart === 0
        ? `${e.chainageStart.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 100,
    name: "km_do",
    idName: "chainageEnd",
    value: (e: any) =>
      e.chainageEnd || e.chainageEnd === 0
        ? `${e.chainageEnd.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 100,
    name: "Długość",
    idName: "length",
    value: (e: any) =>
      e.length || e.length === 0
        ? e.length.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })
        : "--",
  },
  {
    width: 120,
    name: "Czas trwania",
    idName: "duration",
    value: (e: any) => (e.duration || e.duration === 0 ? e.duration : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 150,
    name: "Termin - aneks nr 2",
    idName: "deadline",
    value: (e: any) =>
      e.deadline && new Date(e.deadline).toString() !== "Invalid Date"
        ? formatDate(new Date(e.deadline))
        : "--",
  },
  {
    width: 120,
    name: "Opóźnienie",
    idName: "delay",
    value: (e: any) => (e.delay !== null ? e.delay : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const railwayLinesColumnList = [
  {
    width: 70,
    name: "CSC",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/Railway/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 200,
    name: "Nazwa",
    idName: "name",
    value: (e: any) => <Box>{e.objectName ? e.objectName : "--"}</Box>,
  },
  {
    width: 200,
    name: "Nazwa IE-50",
    idName: "objectNameIE50",
    value: (e: any) => (e.objectNameIE50 ? e.objectNameIE50 : "--"),
  },
  {
    width: 200,
    name: "Nazwa lokalizacji",
    idName: "locationName",
    value: (e: any) => (e.locationName ? e.locationName : "--"),
  },
  {
    width: 120,
    name: "Czy jest w HRF",
    idName: "inHrf",
    value: (e: any) =>
      e.inHrf ? (
        <span className="green">tak</span>
      ) : (
        <span className="red">nie</span>
      ),
  },
  {
    width: 140,
    name: "Wykonawca SABP",
    idName: "contractorSA",
    value: (e: any) => (e.contractorSA ? e.contractorSA : "--"),
  },
  {
    width: 130,
    name: "Wykonawca CW",
    idName: "contractorCW",
    value: (e: any) => (e.contractorCW ? e.contractorCW : "--"),
  },
  //{ width: 100, name: "Typ obiektu", idName: "type", value: (e: any) => e.type?e.type:'--' },
  {
    width: 80,
    name: "Nr linii",
    idName: "line_number",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km",
    idName: "chainage",
    value: (e: any) =>
      e.chainage
        ? `${e.chainage.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 150,
    name: "IZ",
    idName: "iz",
    value: (e: any) => (e.iZ ? e.iZ : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const certyfikacjaWEColumnList = [
  {
    width: 160,
    name: "Nr certyfikacji WE",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/CertyfikacjaWE/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (
      <TooltipWrapper content={e.line ? e.line : "--"} left={0} width={200}>
        {e.line ? e.line : "--"}
      </TooltipWrapper>
    ),
  },
  {
    width: 100,
    name: "Długość",
    idName: "length",
    value: (e: any) =>
      e.length || e.length === 0
        ? e.length.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })
        : "--",
  },
  {
    width: 120,
    name: "Czas trwania",
    idName: "duration",
    value: (e: any) => (e.duration || e.duration === 0 ? e.duration : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 100,
    name: "Etap",
    value: (e: any) => (e.phase ? e.phase : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const certyfikacjaUTKColumnList = [
  {
    width: 180,
    name: "Nr dopuszczenia UTK",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/Certyfikacja/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "Długość",
    idName: "length",
    value: (e: any) =>
      e.length || e.length === 0
        ? e.length.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })
        : "--",
  },
  {
    width: 120,
    name: "Czas trwania",
    idName: "duration",
    value: (e: any) => (e.duration || e.duration === 0 ? e.duration : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 100,
    name: "Etap",
    value: (e: any) => (e.phase ? e.phase : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const ReadyToWEColumnList = [
  {
    width: 160,
    name: "Nr gotowości WE",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/ReadyToWE/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 80,
    name: "Nr linii",
    idName: "line",
    value: (e: any) => (e.line ? e.line : "--"),
  },
  {
    width: 100,
    name: "km_od",
    idName: "chainageStart",
    value: (e: any) =>
      e.chainageStart || e.chainageStart === 0
        ? `${e.chainageStart.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 100,
    name: "km_do",
    idName: "chainageEnd",
    value: (e: any) =>
      e.chainageEnd || e.chainageEnd === 0
        ? `${e.chainageEnd.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })}`
        : "--",
  },
  {
    width: 100,
    name: "Długość",
    idName: "length",
    value: (e: any) =>
      e.length || e.length === 0
        ? e.length.toLocaleString("PL", {
            maximumFractionDigits: 3,
          })
        : "--",
  },
  {
    width: 120,
    name: "Czas trwania",
    idName: "duration",
    value: (e: any) => (e.duration || e.duration === 0 ? e.duration : "--"),
  },
  {
    width: 120,
    name: "Rozpoczęcie",
    idName: "startDate",
    value: (e: any) => (e.startDate ? formatDate(new Date(e.startDate)) : "--"),
  },
  {
    width: 120,
    name: "Zakończenie",
    idName: "endDate",
    value: (e: any) => (e.endDate ? formatDate(new Date(e.endDate)) : "--"),
  },
  {
    width: 100,
    name: "Etap",
    value: (e: any) => (e.phase ? e.phase : "--"),
  },
  {
    width: 120,
    name: "Wykonano %",
    idName: "completion",
    value: (e: any) =>
      e.completion !== null
        ? `${e.completion.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 120,
    name: "Rozliczono %",
    idName: "settledPercentage",
    value: (e) =>
      e.settledPercentage !== null
        ? `${e.settledPercentage.toLocaleString("PL", {
            maximumFractionDigits: 2,
          })}%`
        : "--",
  },
  {
    width: 140,
    name: "Błąd",
    value: (e: any) => {
      if (e.problems && e.problems.length) {
        return e.problems.map((item) => (
          <ProblemTag key={item.name} severity={item.severity}>
            !
          </ProblemTag>
        ));
      } else return "--";
    },
  },
  {
    width: 150,
    name: "Tagi",
    value: (e) => {
      if (e.tags && e.tags.length > 0) {
        return (
          <>
            <TooltipWrapper
              content={e.tags.map((T) => T).join(", ")}
              left={0}
              width={200}
            >
              {e.tags.map((T) => T).join(", ")}
            </TooltipWrapper>
          </>
        );
      } else return "-";
    },
  },
];

export const OtherTaskColumnList = [
  {
    width: 160,
    name: "Nr gotowości WE",
    idName: "code",
    value: (e: any) => (
      <Link
        style={{
          color: "gray",
          display: "inline-block",
          minWidth: 70,
          lineHeight: "57px",
          textDecoration: "none",
        }}
        href={`/objects/ReadyToWE/${e.id}`}
      >
        {e.code}
      </Link>
    ),
  },
  {
    width: 80,
    name: "Nazwa",
    idName: "name",
    value: (e: any) => (e.name ? e.name : "--"),
  },
];
