import styled from "styled-components";
import {
  DisruptionCategoriesType,
  DisruptionStatusesType,
  IDisruption,
} from "types/disruption";
import { Hr } from "components/Hr";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Box } from "components/Box";
import { Flex } from "./Flex";
import { forwardRef, useMemo, useState } from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import { getDisruptionDaysLength } from "utilities/getDisruptionDaysLength";
import { RadioGroup } from "./RadioGroup";
import { Radio } from "./Radio";
import { useFetchAPI } from "hooks/fetchAPI";
import { DisruptionTag } from "components/DisruptionTag";
import {
  DISRUPTION_CATEGORIES_URL,
  DISRUPTION_STATUSES_URL,
  DISRUPTION_PARTNERS_URL,
} from "constants/path";
import { formatDate } from "utilities/formatDate";
import { Problems } from "./Problems";

interface IDisruptionDetails {
  disruption?: IDisruption;
}

const transitionDuration = 300;

interface IRoot {
  state: TransitionStatus;
}

interface IDiscruptionPartner {
  id: string;
  name: string;
}

const Root = styled.div<IRoot>`
  ${({ theme, state }) => `
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    // navbar
    padding-top: 64px;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 2px 8px 0 rgba(30,46,102,0.1);
    font-size: 12px;
    line-height: 15px;
    transition: transform ${transitionDuration}ms ease-in-out;
    transform: translateX(
        ${state === "entering" || state === "entered" ? 0 : 460}px
    );
    z-index: 1;
  `}
`;

const Grid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${theme.space[6]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const Label = styled.div`
  ${({ theme }) => `
    color: rgba(19, 19, 21, 0.65);
    margin-right: ${theme.space[2]}px;
  `}
`;

const Value = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
  `}
`;
const StyledBox = styled(Box)`
  overflow: auto;
  height: calc(100% - 36px);
`;

const Scope = styled.div`
  ${({ theme }) => `
    position: relative;
    padding-left: ${theme.space[4]}px;

    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      background-color: ${theme.palette.primary};
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:not(:last-child) {
      margin-bottom: ${theme.space[4]}px;
    }
  `};
`;

export const DisruptionDetails = forwardRef<HTMLDivElement, IDisruptionDetails>(
  ({ disruption }, ref) => {
    const { data: disruptionCategories } =
      useFetchAPI<DisruptionCategoriesType>(DISRUPTION_CATEGORIES_URL);
    const { data: disruptionStatuses } = useFetchAPI<DisruptionStatusesType>(
      DISRUPTION_STATUSES_URL
    );
    const { data: discruptionPartners } = useFetchAPI<IDiscruptionPartner[]>(
      DISRUPTION_PARTNERS_URL
    );

    // hide "Brak aktualizacji powyżej 30 dni" if status - "Wycofane przez Wykonawcę"
    const problems =
      disruption.status === "4ed2ea63-8fe5-4fc4-ac4e-fa952712a7fe"
        ? disruption?.problems?.filter(
            (el) => el.type !== "older_than_30_days_and_no_updates"
          )
        : disruption?.problems;

    return (
      <StyledBox p={4}>
        <Problems problems={problems} />

        <FieldSetTitle>Szczegóły zakłócenia</FieldSetTitle>
        <Flex flexDirection="column" justifyContent="space-evenly">
          <Flex justifyContent="space-between" my={2}>
            <Label>CSC</Label>
            <Value>{disruption?.code}</Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Kontynuacja</Label>
            <Value>
              {disruption?.continuationData
                ? disruption.continuationData.name
                : null}
            </Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Nr pisma - pow. o roszczeniu</Label>
            <Value>{disruption?.startDocument}</Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Zgłaszający</Label>
            <Value>
              {
                discruptionPartners?.find(
                  (val) => val.id === disruption?.submittingEntity
                )?.name
              }
            </Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Kategoryzacja</Label>
            <Value>
              {disruption?.category && disruptionCategories
                ? disruptionCategories.find(
                    ({ id }) => id === disruption.category
                  )?.name
                : null}
            </Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Status</Label>
            <Value>
              {disruption?.status && disruptionStatuses
                ? disruptionStatuses.find(({ id }) => id === disruption.status)
                    ?.name
                : null}
            </Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Data pisma</Label>
            <Value>{disruption?.endDocument || "--"}</Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Nazwa</Label>
            <Value>{disruption?.name}</Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Dzień rozpoczęcia</Label>
            <Value>
              {disruption?.startDate
                ? formatDate(new Date(disruption.startDate))
                : "--"}
            </Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Dzień skończenia</Label>
            <Value>
              {disruption?.endDate
                ? formatDate(new Date(disruption.endDate))
                : "--"}
            </Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Ilość dni roboczych</Label>
            <Value>
              {disruption
                ? getDisruptionDaysLength({
                    startDate: disruption.startDate,
                    endDate: disruption.endDate,
                    business: true,
                  })
                : null}
            </Value>
          </Flex>
          <Flex justifyContent="space-between" my={2}>
            <Label>Ilość dni kalendarzowych</Label>
            <Value>
              {disruption
                ? getDisruptionDaysLength({
                    startDate: disruption.startDate,
                    endDate: disruption.endDate,
                  })
                : null}
            </Value>
          </Flex>
        </Flex>
        <Box mt={3} mb={3}>
          <Label>Opis</Label>
          <Value>{disruption?.description}</Value>
        </Box>

        <Box mb={3}>
          <FieldSetTitle>IZ</FieldSetTitle>
          <Flex>
            {disruption?.izs &&
              disruption.izs.map((item) => item.name).join(", ")}
            {disruption?.izs && disruption?.izs.length === 0 && "-"}
          </Flex>
        </Box>

        <Box mb={3}>
          <FieldSetTitle>Tagi</FieldSetTitle>
          <Flex>
            {disruption?.tags &&
              disruption.tags.map(({ id, name, active }) => (
                <DisruptionTag key={id} value={id} label={name} />
              ))}
            {disruption?.tags && disruption?.tags.length === 0 && "-"}
          </Flex>
        </Box>

        <Box mb={3}>
          <FieldSetTitle>Możliwy wpływ na partnera</FieldSetTitle>
          <Flex>
            {disruption?.affectedEntities &&
              disruption.affectedEntities.map((item) => item.name).join(", ")}
            {disruption?.affectedEntities &&
              disruption?.affectedEntities.length === 0 &&
              "-"}
          </Flex>
        </Box>
      </StyledBox>
    );
  }
);
