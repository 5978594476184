import styled from "styled-components";

export const Hr = styled.hr`
  ${({ theme }) => `
    height: 1px;
    margin: ${theme.space[6]}px 0;
    background-color: rgba(30,46,102,0.1);
    border: 0;
  `};
`;
