import { CommandBar, Text } from "@fluentui/react";
import { GraphFolder } from "types/msGraph";

interface MsCommandBarProps {
  folder: GraphFolder;
  loadParent: () => void;
}

export const MsCommandBar = ({ folder, loadParent }: MsCommandBarProps) => (
  <CommandBar
    items={[
      {
        key: "Nazwa",
        text: folder.name,
        onRender: (i) => <Text variant="xxLarge">{i.text}</Text>,
      },
    ]}
    farItems={[
      {
        key: "back",
        text: "Folder wyżej",
        iconProps: { iconName: "Up" },
        onClick: () => loadParent(),
        disabled: !folder.parent,
      },
      {
        key: "open",
        text: "Otwórz w SharePoint",
        iconProps: { iconName: "FileSymlink" },
        href: folder.webUrl,
        target: "_blank",
      },
    ]}
  />
);
