import { Box } from "components/Box";
import { Checkbox } from "components/Checkbox";
import React, { useState } from "react";
import { MainOption, IconCollapse } from "./PermissionsCollapse.styled";

interface PermissionsCollapseProps {
  title: string;
  children?: React.ReactNode;
  showIconCollapse?: boolean;
  viewRoles: string[];
  editRoles: string[];
  chosenRoles: string[];
  setChosenRoles: React.Dispatch<React.SetStateAction<string[]>>;
  expandAll?: boolean;
}

const PermissionsCollapse = ({
  title,
  children,
  showIconCollapse,
  viewRoles,
  editRoles,
  chosenRoles,
  setChosenRoles,
  expandAll,
}: PermissionsCollapseProps) => {
  const [isContentVisible, setIsContentVisible] = useState<boolean>(false);

  const handleCheckboxClick = (roles: string[]) => {
    setChosenRoles((prevState) =>
      !prevState.find((searchRole) => searchRole === roles[0])
        ? [...prevState, ...roles]
        : [
            ...prevState.filter(
              (filterRole) =>
                !roles.find(
                  (filterSearchRole) => filterSearchRole === filterRole
                )
            ),
          ]
    );
  };

  return (
    <Box pl={showIconCollapse ? 0 : 9}>
      <MainOption>
        <div>
          {showIconCollapse && (
            <IconCollapse
              onClick={() => setIsContentVisible((prevState) => !prevState)}
            />
          )}
          <p>{title}</p>
        </div>
        <div>
          <Checkbox
            onClick={() => handleCheckboxClick(viewRoles)}
            checked={
              viewRoles
                ? viewRoles?.every((viewRole) =>
                    chosenRoles?.includes(viewRole)
                  )
                : false
            }
            label=""
          />
          <Checkbox
            onClick={() => handleCheckboxClick(editRoles)}
            checked={
              editRoles.length
                ? editRoles?.every((editRole) =>
                    chosenRoles?.includes(editRole)
                  )
                : false
            }
            label=""
          />
        </div>
      </MainOption>
      {(isContentVisible || expandAll) && children}
    </Box>
  );
};

export default PermissionsCollapse;
