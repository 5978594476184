import { useEffect, useState } from "react";
import { IScope, IScopeInfluencedTask } from "types/scope";
import { Button } from "./Button";
import { FieldSetTitle } from "./FieldSetTitle";
import { createPortal } from "react-dom";
import { Flex } from "./Flex";
import { Label, Select } from "components/Select";
import { Input } from "./Input";
import { useFetchAPI } from "hooks/fetchAPI";
import { ConfirmModal } from "components/ConfirmModal";
import {
  DICTIONARY_LINES_URL,
  DICTIONARY_POSITION_TYPES,
} from "constants/path";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { Box } from "./Box";
import { formatDate } from "utilities/formatDate";
import { fetchTask, findOTKTasks, fetchTasks } from "services/tasks";
import {
  addOTKScopeObjects,
  fetchDisruption,
  updateDisruptionScope,
  addInfluencedTaskToDisruptionScope,
  addDisruptionScope,
  removeDisruptionScope,
  removeInfluencedTaskFromDisruptionScope,
  editInfluencedTaskToDisruptionScope,
  removeDisruptionSearchParam,
} from "services/disruptions";
import { IDisruption, ISearchParams } from "types/disruption";
import { ITask, TaskType } from "types/task";
import { Hr } from "./Hr";
import { toast } from "react-toastify";
import { ButtonsGroup } from "./ButtonsGroup";
import MultiSelect from "react-select";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

export interface IScopesForm {
  disruptionId: string;
  scopes?: Array<IScope>;
  disruption: IDisruption;
  getDisruption: any;
  otkSearchParams?: Array<ISearchParams>;
}

interface IOption {
  label: string;
  value: string;
}
export interface IScopesFormData {
  type:
    | ""
    | "OTK"
    | "OR"
    | "FDS"
    | "TUNELE"
    | "milestone"
    | "OSZ"
    | "OC"
    | "CERTYFIKACJA_WE"
    | "Certyfikacja"
    | "ReadyToWE"
    | "OtherTask";
  railwayLine: string;
  chainageStart: string;
  chainageEnd: string;
  ORorFDS: string | IOption;
  positionType: string;
}

interface ICustomSearch {
  customLineNumber: string;
  customChainageStart: string;
  customChainageEnd: string;
  positionType?: string;
}

const Grid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const AlignedButton = styled(Button)`
  align-self: end;
`;

const Task = styled.div`
  ${({ theme }) => `
    position: relative;
    margin-left: ${theme.space[6]}px;
    padding-left: ${theme.space[2]}px;

    &::before {
      content: '';
      display: block;
      width: 3px;
      height: 30px;
      background-color: ${theme.palette.primary};
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: -5px;
    }

    &:not(:last-child) {
      margin-bottom: ${theme.space[12]}px;
    }
  `};
`;
const WrapperCostButton = styled.div`
  position: relative;
  border: 1px solid rgba(30, 46, 102, 0.15);
  border-radius: 50px;
  font-size: 10px;
  overflow: hidden;
`;
const CloseCostButton = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    line-height: 34px;
    width: 25px;
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: ${theme.palette.background};
    }
  `}
`;
const TaskGrid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
    align-items: end;
    margin-bottom: ${theme.space[5]}px;
  `};
`;

const StyledMultiSelect = styled(MultiSelect)`
  width: 100%;
`;
const StyledInput = styled(Input)`
  height: 38px;
`;
const StyledSelect = styled(Select)`
  height: 38px;
`;
const StyledButton = styled(Button)`
  font-size: 10px;
  /* padding: 0; */
`;
const WrapperFields = styled.div`
  width: 100%;
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;
const WrapperFieldSetTitle = styled.div`
  width: 60%;
`;

const ScopeTypeTitleWrapper = styled(Flex)`
  ${({ theme }) => `
    width: 100%;
    color: ${theme.palette.primary};
    font-size: 16px;
    font-weight: 700;
    height: ${theme.space[5]}px;
    margin-top: ${theme.space[10]}px;
    padding-bottom: ${theme.space[6]}px;
    border-bottom: 0.5px solid #D0D0D0;
  `};
`;

const customSelectStyles = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
  }),
};

const scopesFormDefaultData: IScopesFormData = {
  type: "",
  railwayLine: "",
  chainageStart: "",
  chainageEnd: "",
  ORorFDS: "",
  positionType: "",
};

export const ScopesForm: React.FC<IScopesForm> = ({
  disruptionId,
  scopes: defaultScopes,
  disruption,
  getDisruption,
  otkSearchParams: defeaultOtkSearchParams,
}) => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenDeleteSearchGroupModal, setIsOpenDeleteSearchGroupModal] =
    useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);

  const [scopes, setScopes] = useState<Array<IScope>>(defaultScopes ?? []);
  const [otkSearchParams, setOtkSearchParams] = useState<Array<ISearchParams>>(
    []
  );
  const [availableORorFDSs, setavailableORorFDSs] = useState<Array<ITask>>([]);
  const [tasks, setTasks] = useState<any>([]);
  const [selectedScope, setSelectedScope] = useState<IScope | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedInfluence, setSelectedInfluence] = useState(-1);
  const [editingTask, setEditingTask] = useState(null);
  const [taskId, setTaskId] = useState("");
  const [searchParamId, setSearchParamId] = useState("");
  const [customSearch, setCustomSearch] = useState<ICustomSearch | null>();
  const [isFoundObjects, setIsFoundObjects] = useState(false);
  const [searchHeader, setSearchHeader] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>();

  const { data: railwayLines } =
    useFetchAPI<Record<string, number>>(DICTIONARY_LINES_URL);

  const { data: positionTypes } = useFetchAPI<string[]>(
    DICTIONARY_POSITION_TYPES
  );

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const { register, handleSubmit, reset, control, watch } =
    useForm<IScopesFormData>({
      defaultValues: scopesFormDefaultData,
    });

  const {
    register: taskFormRegister,
    watch: taskFormWatch,
    control: taskControl,
    reset: taskReset,
    setValue: taskSetValue,
  } = useForm<any>({
    defaultValues: {
      taskId: "",
      influenceType: "",
      days: "",
      influenceEndDate: null,
      taskSetValue: "",
      positionType: "",
    },
  });

  const typeValue = watch("type");
  const railwayLineValue = watch("railwayLine");
  const chainageStartValue = watch("chainageStart");
  const chainageEndValue = watch("chainageEnd");
  const ORorFDSValue = watch("ORorFDS");
  const positionType = watch("positionType");

  const handleOTKSearch = (
    customLineNumber?: string,
    customChainageStart?: string,
    customChainageEnd?: string,
    customPositionType?: string
  ) => {
    findOTKTasks({
      lineNumber: customLineNumber || railwayLineValue,
      chainageStart: customChainageStart || chainageStartValue,
      chainageEnd: customChainageEnd || chainageEndValue,
      positionType: customPositionType || positionType,
    }).then(async (tasksCount) => {
      setCustomSearch({
        customLineNumber,
        customChainageStart,
        customChainageEnd,
        positionType,
      });

      setSearchHeader(
        tasksCount
          ? `Znaleziono ${tasksCount} ${
              tasksCount === 1
                ? "obiekt"
                : tasksCount % 10 > 1 && tasksCount % 10 < 5
                ? "obiekty"
                : "obiektów"
            } OTK dla wybranych parametrów, czy chcesz je dodać do zakresu?`
          : "Nie znaleziono obiektów"
      );
      setIsFoundObjects(tasksCount ? true : false);

      setIsOpenSearchModal(true);
    });
  };

  useEffect(() => {
    if (
      [
        "OR",
        "FDS",
        "milestone",
        "Tunel",
        "CertyfikacjaWE",
        "Certyfikacja",
        "ReadyToWE",
        "OC",
        "OSZ",
        "OtherTask",
      ].includes(typeValue)
    ) {
      fetchTasks({
        type: (typeValue === "OR" ? ["OR", "ORR"] : typeValue) as TaskType,
        limit: 50,
        lineNumberFilter:
          railwayLineValue !== "" ? parseInt(railwayLineValue, 10) : undefined,
        filters: { positionType: positionType, active: true },
      }).then(({ data }) => {
        setavailableORorFDSs(data);
      });
    }
  }, [typeValue, railwayLineValue, positionType]);

  const handleOpenModal = (task: string) => {
    setIsOpenConfirmModal(true);
    setTaskId(task);
  };

  const handleOpenDeleteSearchGroupModal = (searchGroupId: string) => {
    setIsOpenDeleteSearchGroupModal(true);
    setSearchParamId(searchGroupId);
  };

  const handleSearchClick = handleSubmit((data) => {
    addOTKScopeObjects({
      disruptionId,
      lineNumber: customSearch.customLineNumber || data.railwayLine,
      chainageStart: customSearch.customChainageStart || data.chainageStart,
      chainageEnd: customSearch.customChainageEnd || data.chainageEnd,
      positionType: customSearch.positionType || data.positionType,
    })
      .then(async () => {
        toast.success("Zakres zakłócenia został zaktualizowany.");
        await getDisruption();
        reset(scopesFormDefaultData);

        setIsOpenSearchModal(false);
      })
      .catch(() => {
        toast.error("Nie udało się zaktualizować zakresu zakłócenia.");
      });
  });

  const handleDeleteScopeClick = async () => {
    if (taskId) {
      try {
        await removeDisruptionScope(taskId);
        setScopes((prevState) =>
          prevState.filter((scope) => scope.id !== taskId)
        );
        toast.success("Zakres zakłócenia został usunięty.");
      } catch (error) {
        toast.error("Nie udało się usunąć zakresu zakłócenia.");
      }
      setIsOpenConfirmModal(false);
    }
  };

  const handleDeleteSearchGroupClick = async () => {
    if (searchParamId) {
      try {
        await removeDisruptionSearchParam(searchParamId);
        setOtkSearchParams((prevState) =>
          prevState.filter((searchParam) => searchParam.id !== searchParamId)
        );
        toast.success("Grupa zakresów zakłócenia została usunięta.");
      } catch (error) {
        toast.error("Nie udało się usunąć grupy zakresów zakłócenia.");
      }
      setIsOpenDeleteSearchGroupModal(false);
    }
  };

  const handleORAdd = () => {
    handleSubmit((data) => {
      addDisruptionScope({
        disruptionId,
        taskId:
          typeof ORorFDSValue !== "string" ? ORorFDSValue.value : ORorFDSValue,
        endDate: taskFormWatch("endDate"),
      })
        .then(async () => {
          toast.success("Zakres zakłócenia został zaktualizowany.");
          await getDisruption();
        })
        .catch();
      reset(scopesFormDefaultData);
    })();
  };

  const getTasks = async () => {
    const tasks: Array<ITask> = [];
    for (const scope of scopes) {
      await fetchTask({ taskId: scope.task }).then((data) => {
        tasks.push(data);
      });
    }

    const finalTasks = tasks.map((task) => ({
      ...task,
      children: task.children.map(
        ({ nameNormalized, chainageStart, chainageEnd, ...rest }) => ({
          ...rest,
          name: `${nameNormalized} w km od ${chainageStart} do km ${chainageEnd}`,
        })
      ),
    }));

    setTasks(finalTasks);
  };

  const handleRemoveInfluencedTaskClick = async ({
    scopeId,
    taskId,
    influencedTaskUuid,
  }: {
    taskId: string;
    scopeId: string;
    influencedTaskUuid: string;
  }) => {
    try {
      await removeInfluencedTaskFromDisruptionScope(influencedTaskUuid);
      setScopes((prevState) =>
        prevState.map((scope) =>
          scope.id !== scopeId
            ? scope
            : {
                ...scope,
                influencedTasks: scope.influencedTasks.filter(
                  (task) => task.task !== taskId
                ),
              }
        )
      );
      toast.success("Usunięto wpływ na zadanie.");
    } catch (error) {
      toast.error("Nie udało się usunąć wpływu na zadanie.");
    }
  };

  const handleORorFDSInputSearch = (e) => {
    if (e) {
      clearTimeout(searchTimeout);
      setSearchTimeout(
        setTimeout(() => {
          fetchTasks({
            type: typeValue as TaskType,
            lineNumberFilter:
              railwayLineValue !== ""
                ? parseInt(railwayLineValue, 10)
                : undefined,
            filters: { search: e, positionType, active: true },
          }).then(({ data }) => {
            if (!availableORorFDSs.find((item) => item.code === data[0].code)) {
              setavailableORorFDSs(data);
            }
          });
        }, 1000)
      );
    }
  };

  useEffect(() => {
    getTasks();
  }, [scopes]);

  useEffect(() => {
    defaultScopes && setScopes(defaultScopes);
    defeaultOtkSearchParams && setOtkSearchParams(defeaultOtkSearchParams);
  }, [defaultScopes, defeaultOtkSearchParams]);

  const PageHeaderButtonsGroupPortal = document.getElementById(
    "page-header-buttons-group-portal"
  );

  return (
    <>
      {loggedUserRoles?.includes("ROLE_DISRUPTIONS_LIST_RANGE_CREATE") && (
        <Grid>
          <Box>
            <StyledSelect label="Typ obiektu" {...register("type")}>
              <option value="" />
              <option value="OTK">OTK</option>
              <option value="OR">OR/ORR</option>
              <option value="FDS">FDS</option>
              <option value="Tunel">TUNELE</option>
              <option value="milestone">KAMIENIE MILOWE</option>
              <option value="OSZ">OSZ</option>
              <option value="OC">OC</option>
              <option value="CertyfikacjaWE">NR CERTYFIKACJI WE</option>
              <option value="Certyfikacja">NR DOPUSZCZENIA UTK</option>
              <option value="ReadyToWE">NR GOTOWOŚCI WE</option>
              <option value="OtherTask">Inne</option>
            </StyledSelect>
          </Box>
          {[
            "OTK",
            "OR",
            "FDS",
            "Tunel",
            "milestone",
            "OSZ",
            "OC",
            "CertyfikacjaWE",
            "Certyfikacja",
            "ReadyToWE",
            "OtherTask",
          ].includes(typeValue) ? (
            <>
              {typeValue === "OTK" && (
                <Select label="Typ pozycji" {...register("positionType")}>
                  <option value=""></option>
                  {positionTypes?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              )}
              <StyledSelect label="Linia kolejowa" {...register("railwayLine")}>
                <option value=""></option>
                {railwayLines
                  ? Object.entries(railwayLines).map((item) => (
                      <option key={item[0]} value={item[1]}>
                        {item[0]}
                      </option>
                    ))
                  : null}
              </StyledSelect>
              {typeValue === "OTK" ? (
                <>
                  <StyledInput
                    label="Kilometry od"
                    type="string"
                    {...register("chainageStart")}
                  />
                  <StyledInput
                    label="Kilometry do"
                    type="string"
                    {...register("chainageEnd")}
                  />
                  {railwayLineValue !== "" &&
                  ![undefined, 0, ""].includes(chainageStartValue) &&
                  chainageEndValue !== "" ? (
                    <Box alignSelf="end">
                      <Button bordered onClick={() => handleOTKSearch()}>
                        Szukaj
                      </Button>
                    </Box>
                  ) : null}
                </>
              ) : null}
              {[
                "OR",
                "FDS",
                "Tunel",
                "milestone",
                "OSZ",
                "OC",
                "CertyfikacjaWE",
                "Certyfikacja",
                "ReadyToWE",
                "OtherTask",
              ].includes(typeValue) ? (
                <Box key={typeValue}>
                  {/* <Select label={`Wybierz ${typeValue}`} {...register("ORorFDS")}>
                  <option value="" />
                  {availableORorFDSs.map((ORorFDS) => (
                    <option key={ORorFDS.id} value={ORorFDS.id}>
                      {ORorFDS.code ? `CSC: ${ORorFDS.code} - ` : null}
                      {ORorFDS.locationName}
                      {ORorFDS.chainage ? ` - ${ORorFDS.chainage}km` : null}
                    </option>
                  ))}
                </Select> */}
                  {/* {/* <Label>{`Wybierz ${typeValue}`}</Label> */}
                  <Label>Wybierz obiekt</Label>
                  <Controller
                    control={control}
                    name="ORorFDS"
                    render={({ field: { onChange, value, ref } }) => (
                      <StyledMultiSelect
                        filterOption={() => true}
                        onInputChange={handleORorFDSInputSearch}
                        styles={customSelectStyles}
                        inputRef={ref}
                        value={value}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={availableORorFDSs.map((ORorFDS) => {
                          const code = ORorFDS.code
                            ? `CSC: ${ORorFDS.code} - `
                            : "";
                          const locationName = ORorFDS.locationName || "";
                          const chainage = ORorFDS.chainage
                            ? ` - ${ORorFDS.chainage}km`
                            : "";
                          return {
                            label: code + locationName + chainage,
                            value: ORorFDS.id,
                          };
                        })}
                      />
                    )}
                  />
                </Box>
              ) : null}
              {ORorFDSValue !== "" ? (
                <Box alignSelf="end">
                  <Button bordered onClick={handleORAdd}>
                    Dodaj
                  </Button>
                </Box>
              ) : null}
            </>
          ) : null}
        </Grid>
      )}

      {otkSearchParams.map((searchParam) => (
        <>
          <ScopeTypeTitleWrapper
            key={searchParam.id}
            justifyContent="space-between"
            alignItems="center"
          >
            OTK na linii nr {searchParam.lineNumber} w km od{" "}
            {searchParam.chineageStart} do km {searchParam.chineageEnd}
            <ButtonsGroup>
              <Button
                bordered
                onClick={() =>
                  handleOTKSearch(
                    searchParam.lineNumber.toString(),
                    searchParam.chineageStart.toString(),
                    searchParam.chineageEnd.toString()
                  )
                }
              >
                Przelicz
              </Button>
              {loggedUserRoles?.includes(
                "ROLE_DISRUPTIONS_LIST_RANGE_DELETE"
              ) && (
                <Button
                  bordered
                  onClick={() =>
                    handleOpenDeleteSearchGroupModal(searchParam.id)
                  }
                >
                  Usuń
                </Button>
              )}
            </ButtonsGroup>
          </ScopeTypeTitleWrapper>
          {scopes.map(
            (scope, index) =>
              scope?.searchParams?.id === searchParam.id && (
                <Box key={index} mt={6}>
                  <Task key={index}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <WrapperFieldSetTitle>
                        <FieldSetTitle>{scope.taskName}</FieldSetTitle>
                      </WrapperFieldSetTitle>
                      <ButtonsGroup>
                        <Button
                          bordered
                          onClick={() => {
                            taskReset({
                              positionType: scope?.searchParams?.positionType,
                            });
                            setSelectedInfluence(index);
                            setEditingTask(null);
                            taskSetValue("influenceType", "ZZ");
                            taskSetValue("days", 60);
                          }}
                        >
                          Dodaj wpływ na zadanie
                        </Button>
                        {loggedUserRoles?.includes(
                          "ROLE_DISRUPTIONS_LIST_RANGE_DELETE"
                        ) && (
                          <Button
                            bordered
                            onClick={() => handleOpenModal(scope.id)}
                          >
                            Usuń
                          </Button>
                        )}
                      </ButtonsGroup>
                    </Flex>
                    <TaskGrid></TaskGrid>
                    {selectedInfluence === index && (
                      <>
                        <TaskGrid>
                          <Select
                            label="Typ Pozycji"
                            {...taskFormRegister("positionType")}
                          >
                            <option value=""></option>
                            {positionTypes?.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Select>
                          <Select label="Zadanie" {...taskFormRegister("task")}>
                            {tasks[index]?.children
                              .filter(({ positionType }) =>
                                taskFormWatch("positionType")
                                  ? positionType ===
                                    taskFormWatch("positionType")
                                  : true
                              )
                              .map(
                                ({
                                  name,
                                  id,
                                }: {
                                  name: string;
                                  id: string;
                                }) => (
                                  <option key={name} value={id}>
                                    {name}
                                  </option>
                                )
                              )}
                          </Select>
                          <Select
                            label="Rodzaj wpływu"
                            {...taskFormRegister("influenceType")}
                          >
                            <option value="ZR">
                              ZR - Zakończenie - Rozpoczęcie
                            </option>
                            <option value="ZZ">
                              ZZ - Zakończenie - Zakończenie
                            </option>
                            <option value="RR">
                              RR - Rozpoczęcie - Rozpoczęcie
                            </option>
                          </Select>
                          <Input
                            label="Rozpoczęcie"
                            value=""
                            type="text"
                            disabled
                          />
                          <Input
                            label="Liczba dni"
                            {...taskFormRegister("days")}
                            type="number"
                          />
                          <Input
                            label="Zakończenie"
                            value=""
                            type="text"
                            disabled
                          />
                          <Controller
                            control={taskControl}
                            name="influenceEndDate"
                            render={({ field }) => {
                              return (
                                <StyledDatePicker
                                  selected={
                                    field.value === ""
                                      ? null
                                      : field.value !== undefined &&
                                        field.value !== null
                                      ? new Date(field.value)
                                      : scope.influenceEndDate
                                      ? new Date(scope.influenceEndDate)
                                      : null
                                  }
                                  // disabled={selectedIndex !== index}
                                  onChange={(date) => {
                                    const formatDate =
                                      date instanceof Date
                                        ? format(date, "yyyy-MM-dd")
                                        : "";

                                    field.onChange(formatDate);

                                    setTasks({
                                      ...tasks,
                                      influenceEndDate: formatDate,
                                    });

                                    setSelectedScope({
                                      ...scope,
                                      influenceEndDate: formatDate,
                                    });
                                  }}
                                  customInput={
                                    <Input label="Data ustąpienia zdarzenia" />
                                  }
                                  dateFormat="dd-MM-yyyy"
                                  portalId="root-datepicker-portal"
                                />
                              );
                            }}
                          />
                          <Box ml={6}>
                            <Button
                              bordered
                              onClick={() => {
                                addInfluencedTaskToDisruptionScope({
                                  disruptionScope: scope.id,
                                  disruptionId,
                                  taskId: taskFormWatch("task"),
                                  scopeId: scope.task,
                                  influenceType: taskFormWatch("influenceType"),
                                  days: taskFormWatch("days").toString(),
                                  influenceEndDate:
                                    taskFormWatch("influenceEndDate"),
                                }).then(() => {
                                  setSelectedInfluence(-1);
                                  getDisruption();
                                });
                              }}
                            >
                              Dodaj
                            </Button>
                          </Box>
                        </TaskGrid>
                      </>
                    )}

                    {scope.influencedTasks &&
                      scope.influencedTasks.map((task) => (
                        <TaskGrid key={task.task}>
                          {editingTask === task.task ? (
                            <>
                              <Select
                                label="Typ Pozycji"
                                {...taskFormRegister("positionType")}
                              >
                                <option value=""></option>
                                {positionTypes?.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Select>
                              <Select
                                label="Zadanie"
                                {...taskFormRegister("task")}
                              >
                                {tasks[index]?.children
                                  .filter(({ positionType }) =>
                                    taskFormWatch("positionType") === ""
                                      ? true
                                      : taskFormWatch("positionType")
                                      ? positionType ===
                                        taskFormWatch("positionType")
                                      : tasks[index]?.children.filter(
                                          ({ id }: { id: string }) =>
                                            task.task === id
                                        )[0]?.positionType &&
                                        positionType ===
                                          tasks[index]?.children.filter(
                                            ({ id }: { id: string }) =>
                                              task.task === id
                                          )[0]?.positionType
                                  )
                                  .map(
                                    ({
                                      name,
                                      id,
                                    }: {
                                      name: string;
                                      id: string;
                                    }) => (
                                      <option key={name} value={id}>
                                        {name}
                                      </option>
                                    )
                                  )}
                              </Select>
                            </>
                          ) : (
                            <>
                              <Select
                                label="Typ Pozycji"
                                value={
                                  tasks[index]?.children.filter(
                                    ({ id }: { id: string }) => task.task === id
                                  )[0]?.positionType
                                }
                                disabled={
                                  editingTask === task.task ? false : true
                                }
                              >
                                <option value=""></option>
                                {positionTypes?.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Select>
                              <Input
                                label="Zadanie"
                                value={
                                  tasks[index]?.children.filter(
                                    ({ id }: { id: string }) => task.task === id
                                  )[0]?.name
                                }
                                disabled={
                                  editingTask === task.task ? false : true
                                }
                              />
                            </>
                          )}
                          {editingTask === task.task ? (
                            <Select
                              label="Rodzaj wpływu"
                              {...taskFormRegister("influenceType")}
                            >
                              <option value="ZR">
                                ZR - Zakończenie - Rozpoczęcie
                              </option>
                              <option value="ZZ">
                                ZZ - Zakończenie - Zakończenie
                              </option>
                              <option value="RR">
                                RR - Rozpoczęcie - Rozpoczęcie
                              </option>
                            </Select>
                          ) : (
                            <Input
                              label="Rodzaj wpływu"
                              value={task.influenceType}
                              disabled
                            />
                          )}
                          <Input
                            label="Rozpoczęcie"
                            value={formatDate(new Date(task.taskStartDate))}
                            disabled
                          />

                          {editingTask === task.task ? (
                            <Input
                              label="Liczba dni"
                              {...taskFormRegister("days")}
                              type="number"
                            />
                          ) : (
                            <Input
                              label="Liczba dni"
                              value={task.days}
                              type="number"
                              disabled
                            />
                          )}
                          <Input
                            label="Zakończenie"
                            value={formatDate(new Date(task.taskEndDate))}
                            disabled
                          />
                          {editingTask === task.task ? (
                            <Controller
                              control={taskControl}
                              name="influenceEndDate"
                              render={({ field }) => {
                                return (
                                  <StyledDatePicker
                                    selected={
                                      field.value === ""
                                        ? null
                                        : field.value !== undefined &&
                                          field.value !== null
                                        ? new Date(field.value)
                                        : task.influenceEndDate
                                        ? new Date(task.influenceEndDate)
                                        : null
                                    }
                                    onChange={(date) => {
                                      const formatDate =
                                        date instanceof Date
                                          ? format(date, "yyyy-MM-dd")
                                          : "";

                                      field.onChange(formatDate);

                                      setTasks({
                                        ...tasks,
                                        influenceEndDate: formatDate,
                                      });

                                      setSelectedScope({
                                        ...scope,
                                        influenceEndDate: formatDate,
                                      });
                                    }}
                                    customInput={
                                      <Input label="Data ustąpienia zdarzenia" />
                                    }
                                    dateFormat="dd-MM-yyyy"
                                    portalId="root-datepicker-portal"
                                  />
                                );
                              }}
                            />
                          ) : (
                            <Input
                              label="Data ustąpienia zdarzenia"
                              value={
                                task.influenceEndDate &&
                                formatDate(new Date(task.influenceEndDate))
                              }
                              disabled
                            />
                          )}
                          {editingTask === task.task ? (
                            <Button
                              bordered
                              onClick={() => {
                                editInfluencedTaskToDisruptionScope({
                                  influencedTaskUuid: task.id,
                                  disruptionScope: scope.id,
                                  disruptionId,
                                  taskId: taskFormWatch("task"),
                                  scopeId: scope.task,
                                  influenceType: taskFormWatch("influenceType"),
                                  days: taskFormWatch("days").toString(),
                                  influenceEndDate:
                                    taskFormWatch("influenceEndDate"),
                                }).then(() => {
                                  setEditingTask(null);
                                  setSelectedInfluence(-1);
                                  getDisruption();
                                });
                              }}
                            >
                              Zapisz
                            </Button>
                          ) : (
                            loggedUserRoles?.includes(
                              "ROLE_DISRUPTIONS_LIST_RANGE_UPDATE"
                            ) && (
                              <Button
                                bordered
                                onClick={() => {
                                  taskReset({
                                    days: task.days,
                                    influenceType: task.influenceType,
                                    task: task.task,
                                    positionType: task.positionType,
                                    influenceEndDate: task.influenceEndDate,
                                  });
                                  setSelectedInfluence(-1);
                                  setEditingTask(task.task);
                                }}
                              >
                                Edytuj
                              </Button>
                            )
                          )}

                          {loggedUserRoles?.includes(
                            "ROLE_DISRUPTIONS_LIST_RANGE_DELETE"
                          ) && (
                            <Button
                              bordered
                              onClick={() =>
                                handleRemoveInfluencedTaskClick({
                                  scopeId: scope.id,
                                  taskId: task.task,
                                  influencedTaskUuid: task.id,
                                })
                              }
                            >
                              Usuń
                            </Button>
                          )}
                        </TaskGrid>
                      ))}

                    <Hr />
                  </Task>
                </Box>
              )
          )}
        </>
      ))}

      {scopes.map(
        (scope, index) =>
          !scope.searchParams && (
            <Box key={index} mt={6}>
              <ScopeTypeTitleWrapper
                key={scope.id}
                justifyContent="space-between"
                alignItems="center"
              >
                {scope.taskName}

                <ButtonsGroup>
                  <Button
                    bordered
                    onClick={() => {
                      taskReset({
                        positionType: scope?.searchParams?.positionType,
                      });
                      setSelectedInfluence(index);
                      setEditingTask(null);
                      taskSetValue("influenceType", "ZZ");
                      taskSetValue("days", 60);
                    }}
                  >
                    Dodaj wpływ na zadanie
                  </Button>
                  {loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_RANGE_DELETE"
                  ) && (
                    <Button bordered onClick={() => handleOpenModal(scope.id)}>
                      Usuń
                    </Button>
                  )}
                </ButtonsGroup>
              </ScopeTypeTitleWrapper>

              <TaskGrid></TaskGrid>
              {selectedInfluence === index && (
                <>
                  <TaskGrid>
                    <Select label="Zadanie" {...taskFormRegister("task")}>
                      {tasks[index]?.children.map(
                        ({ name, id }: { name: string; id: string }) => (
                          <option key={name} value={id}>
                            {name}
                          </option>
                        )
                      )}
                    </Select>
                    <Select
                      label="Rodzaj wpływu"
                      {...taskFormRegister("influenceType")}
                    >
                      <option value="ZR">ZR - Zakończenie - Rozpoczęcie</option>
                      <option value="ZZ">ZZ - Zakończenie - Zakończenie</option>
                      <option value="RR">RR - Rozpoczęcie - Rozpoczęcie</option>
                    </Select>
                    <Input label="Rozpoczęcie" value="" type="text" disabled />
                    <Input
                      label="Liczba dni"
                      {...taskFormRegister("days")}
                      type="number"
                    />
                    <Input label="Zakończenie" value="" type="text" disabled />
                    <Controller
                      control={taskControl}
                      name="influenceEndDate"
                      render={({ field }) => {
                        return (
                          <StyledDatePicker
                            selected={
                              field.value === ""
                                ? null
                                : field.value !== undefined &&
                                  field.value !== null
                                ? new Date(field.value)
                                : scope.influenceEndDate
                                ? new Date(scope.influenceEndDate)
                                : null
                            }
                            onChange={(date) => {
                              const formatDate =
                                date instanceof Date
                                  ? format(date, "yyyy-MM-dd")
                                  : "";

                              field.onChange(formatDate);

                              setTasks({
                                ...tasks,
                                influenceEndDate: formatDate,
                              });

                              setSelectedScope({
                                ...scope,
                                influenceEndDate: formatDate,
                              });
                            }}
                            customInput={
                              <Input label="Data ustąpienia zdarzenia" />
                            }
                            dateFormat="dd-MM-yyyy"
                            portalId="root-datepicker-portal"
                          />
                        );
                      }}
                    />
                    <Box ml={6}>
                      <Button
                        bordered
                        onClick={() => {
                          addInfluencedTaskToDisruptionScope({
                            disruptionScope: scope.id,
                            disruptionId,
                            taskId: taskFormWatch("task"),
                            scopeId: scope.task,
                            influenceType: taskFormWatch("influenceType"),
                            days: taskFormWatch("days").toString(),
                            influenceEndDate: taskFormWatch("influenceEndDate"),
                          }).then(() => {
                            setSelectedInfluence(-1);
                            getDisruption();
                          });
                        }}
                      >
                        Dodaj
                      </Button>
                    </Box>
                  </TaskGrid>
                </>
              )}

              {scope.influencedTasks &&
                scope.influencedTasks.map((task) => (
                  <TaskGrid key={task.task}>
                    {editingTask === task.task ? (
                      <>
                        <Select label="Zadanie" {...taskFormRegister("task")}>
                          {tasks[index]?.children.map(
                            ({ name, id }: { name: string; id: string }) => (
                              <option key={name} value={id}>
                                {name}
                              </option>
                            )
                          )}
                        </Select>
                      </>
                    ) : (
                      <>
                        <Input
                          label="Zadanie"
                          value={
                            tasks[index]?.children.filter(
                              ({ id }: { id: string }) => task.task === id
                            )[0]?.name
                          }
                          disabled={editingTask === task.task ? false : true}
                        />
                      </>
                    )}
                    {editingTask === task.task ? (
                      <Select
                        label="Rodzaj wpływu"
                        {...taskFormRegister("influenceType")}
                      >
                        <option value="ZR">
                          ZR - Zakończenie - Rozpoczęcie
                        </option>
                        <option value="ZZ">
                          ZZ - Zakończenie - Zakończenie
                        </option>
                        <option value="RR">
                          RR - Rozpoczęcie - Rozpoczęcie
                        </option>
                      </Select>
                    ) : (
                      <Input
                        label="Rodzaj wpływu"
                        value={task.influenceType}
                        disabled
                      />
                    )}
                    <Input
                      label="Rozpoczęcie"
                      value={formatDate(new Date(task.taskStartDate))}
                      disabled
                    />

                    {editingTask === task.task ? (
                      <Input
                        label="Liczba dni"
                        {...taskFormRegister("days")}
                        type="number"
                      />
                    ) : (
                      <Input
                        label="Liczba dni"
                        value={task.days}
                        type="number"
                        disabled
                      />
                    )}
                    <Input
                      label="Zakończenie"
                      value={formatDate(new Date(task.taskEndDate))}
                      disabled
                    />
                    {editingTask === task.task ? (
                      <Controller
                        control={taskControl}
                        name="influenceEndDate"
                        render={({ field }) => {
                          return (
                            <StyledDatePicker
                              selected={
                                field.value === ""
                                  ? null
                                  : field.value !== undefined &&
                                    field.value !== null
                                  ? new Date(field.value)
                                  : task.influenceEndDate
                                  ? new Date(task.influenceEndDate)
                                  : null
                              }
                              onChange={(date) => {
                                const formatDate =
                                  date instanceof Date
                                    ? format(date, "yyyy-MM-dd")
                                    : "";

                                field.onChange(formatDate);

                                setTasks({
                                  ...tasks,
                                  influenceEndDate: formatDate,
                                });

                                setSelectedScope({
                                  ...scope,
                                  influenceEndDate: formatDate,
                                });
                              }}
                              customInput={
                                <Input label="Data ustąpienia zdarzenia" />
                              }
                              dateFormat="dd-MM-yyyy"
                              portalId="root-datepicker-portal"
                            />
                          );
                        }}
                      />
                    ) : (
                      <Input
                        label="Data ustąpienia zdarzenia"
                        value={
                          task.influenceEndDate &&
                          formatDate(new Date(task.influenceEndDate))
                        }
                        disabled
                      />
                    )}
                    {editingTask === task.task ? (
                      <Button
                        bordered
                        onClick={() => {
                          editInfluencedTaskToDisruptionScope({
                            influencedTaskUuid: task.id,
                            disruptionScope: scope.id,
                            disruptionId,
                            taskId: taskFormWatch("task"),
                            scopeId: scope.task,
                            influenceType: taskFormWatch("influenceType"),
                            days: taskFormWatch("days").toString(),
                            influenceEndDate:
                              taskFormWatch("influenceEndDate") ||
                              task.influenceEndDate,
                          }).then(() => {
                            setEditingTask(null);
                            setSelectedInfluence(-1);
                            getDisruption();
                          });
                        }}
                      >
                        Zapisz
                      </Button>
                    ) : (
                      loggedUserRoles?.includes(
                        "ROLE_DISRUPTIONS_LIST_RANGE_UPDATE"
                      ) && (
                        <Button
                          bordered
                          onClick={() => {
                            taskReset({
                              days: task.days,
                              influenceType: task.influenceType,
                              task: task.task,
                              positionType: task.positionType,
                              influenceEndDate: task.influenceEndDate,
                            });
                            setSelectedInfluence(-1);
                            setEditingTask(task.task);
                          }}
                        >
                          Edytuj
                        </Button>
                      )
                    )}
                    {loggedUserRoles?.includes(
                      "ROLE_DISRUPTIONS_LIST_RANGE_DELETE"
                    ) && (
                      <Button
                        bordered
                        onClick={() =>
                          handleRemoveInfluencedTaskClick({
                            scopeId: scope.id,
                            taskId: task.task,
                            influencedTaskUuid: task.id,
                          })
                        }
                      >
                        Usuń
                      </Button>
                    )}
                  </TaskGrid>
                ))}
            </Box>
          )
      )}
      {PageHeaderButtonsGroupPortal
        ? createPortal(
            <>
              <Button
                bordered
                onClick={() => {
                  updateDisruptionScope(selectedScope).then(() => {
                    toast.success("Zapisano");
                    setSelectedIndex(-1);
                    setSelectedScope(null);
                  });
                }}
              >
                Zapisz
              </Button>
            </>,
            PageHeaderButtonsGroupPortal
          )
        : null}
      <ConfirmModal
        handleConfirm={handleDeleteScopeClick}
        onCancelClick={() => setIsOpenConfirmModal(false)}
        isOpen={isOpenConfirmModal}
        header="Czy na pewno chcesz usunąć zakres zakłócenia?"
        confirmBtnText="Usuń"
      />
      <ConfirmModal
        handleConfirm={handleDeleteSearchGroupClick}
        onCancelClick={() => setIsOpenDeleteSearchGroupModal(false)}
        isOpen={isOpenDeleteSearchGroupModal}
        header="Czy na pewno chcesz usunąć grupę zakresów zakłócenia?"
        confirmBtnText="Usuń"
      />
      <ConfirmModal
        handleConfirm={
          isFoundObjects
            ? handleSearchClick
            : () => {
                setIsOpenSearchModal(false);
                reset(scopesFormDefaultData);
              }
        }
        onCancelClick={() => setIsOpenSearchModal(false)}
        isOpen={isOpenSearchModal}
        header={searchHeader}
        confirmBtnText={isFoundObjects ? "Dodaj" : "OK"}
      />
    </>
  );
};
