import { theme } from "config/theme";
import styled from "styled-components";

export const HelperPanelWrapper = styled.div`
  height: 64px;
  background: ${theme.palette.lightGrey};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;
  gap: 10px;
`;

export const TitlePanelWrapper = styled.div`
  height: 54px;
  background: ${theme.palette.neutral.white};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
`;

export const Header = styled.h3`
  font-weight: 700;
  margin-right: auto;
`;

export const ColouredColumnName = styled.p`
  color: #5bacf8;
  cursor: pointer;
`;
