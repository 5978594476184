import { useState } from "react";
import styled from "styled-components";
import { Flex } from "./Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button";

interface IProblem {
  severity: string;
}

const Problem = styled(Flex)<IProblem>`
  ${({ theme, severity }) => `
    background: ${
      severity === "error"
        ? theme.palette.neutral.error
        : theme.palette.neutral.warring
    };
  `}
  padding: 15px;
  border-radius: 10px;
  font-size: 20px;
  margin: 10px 0;

  p {
    width: 80%;
    font-size: 10px;
  }
`;

export const Problems = ({ problems }) => {
  const [isMoreProblemsOpen, setIsMoreProblemsOpen] = useState(false);

  return (
    <>
      {problems
        ?.slice(0, isMoreProblemsOpen ? problems.lenght : 2)
        ?.map((problem) => (
          <Problem
            alignItems="center"
            justifyContent="space-between"
            key={problem.name}
            severity={problem.severity}
          >
            <p>{problem.name}</p>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Problem>
        ))}

      {problems?.length > 2 && (
        <Flex
          justifyContent="center"
          mb={5}
          onClick={() => {
            setIsMoreProblemsOpen((prevState) => !prevState);
          }}
        >
          <Button>
            {isMoreProblemsOpen ? "Schowaj błędy" : "Pokaż więcej błędów"}
          </Button>
        </Flex>
      )}
    </>
  );
};
