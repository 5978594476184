/* eslint-disable import/named */
import { Box } from "components/Box";
import { Card } from "components/Card";
import { FC, useEffect, useState } from "react";
import {
  WrapperFixedButtons,
  Filters,
  StyledMultiSelect,
} from "views/TaskTypeView/TaskType.styled";

import { Input } from "components/Input";
import { Label, Select } from "components/Select";
import DatePicker from "react-datepicker";
import { Button } from "components/Button";
import {
  Control,
  Controller,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { Flex } from "components/Flex";
import ORFilter from "../ORFilter/ORFilter";
import { urlToPageTitleMap } from "views/TaskTypeView/TaskType.constants";
import { IFilters } from "contexts/TasksContext";
import { TaskType } from "types/task";
import { DatePickerHeader } from "../../../../components/DatePickerHeader";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

interface TasksFilterProps {
  optionsIzs: { label: string; value: string }[];
  optionsTags: { label: string; value: string }[];
  optionsCompanies: { label: string; value: string }[];
  optionsContractorsCW: { label: string; value: string }[];
  optionsContractorsSA: { label: string; value: string }[];
  handleSubmit: UseFormHandleSubmit<IFilters>;
  onSubmit: (data: IFilters) => void;
  control: Control<IFilters>;
  register: UseFormRegister<IFilters>;
  typeParam: string;
  setValue?: any;
  cancelFilters: () => void;
  watcher?: IFilters;
}

const StyledDatePicker = styled(DatePicker)`
  width: 95%;
`;

const StyledFlex = styled(Flex)`
  width: 95%;
`;

const TasksFilter: FC<TasksFilterProps> = ({
  optionsIzs,
  optionsTags,
  optionsCompanies,
  optionsContractorsCW,
  optionsContractorsSA,
  handleSubmit,
  onSubmit,
  control,
  register,
  cancelFilters,
  setValue,
  typeParam,
  watcher,
}) => {
  const [isCompanyFilterVisble, setCompanyFilterVisible] =
    useState<boolean>(true);

  const currentLocation = useLocation().pathname;

  useEffect(() => {
    if (
      currentLocation === "/objects/OR,ORR" ||
      currentLocation === "/objects/OR,ORR/" ||
      currentLocation === "/objects/CertyfikacjaWE/" ||
      currentLocation === "/objects/CertyfikacjaWE" ||
      currentLocation === "/objects/milestone" ||
      currentLocation === "/objects/milestone/"
    ) {
      setCompanyFilterVisible(false);
    } else {
      setCompanyFilterVisible(true);
    }
  }, []);

  return (
    <Card style={{ marginBottom: "1rem" }}>
      <Box px={4} pb={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <WrapperFixedButtons mb={5} py={2}>
            <Flex>
              <Button type="submit" bordered>
                Filtruj
              </Button>
              <Box ml={2}>
                <Button onClick={() => cancelFilters()}>Wyczyść</Button>
              </Box>
            </Flex>
          </WrapperFixedButtons>

          <Filters itemsInRowNumber={1}>
            <Box pt={12}>
              <Label>Kod/csc</Label>
              <Input {...register("search")} />
            </Box>
            <Box style={{ display: isCompanyFilterVisble ? "block" : "none" }}>
              <Label>Firma</Label>
              <Controller
                control={control}
                name="company"
                render={({ field: { onChange, value, ref } }) => (
                  <StyledMultiSelect
                    closeMenuOnSelect={false}
                    inputRef={ref}
                    value={optionsCompanies.filter(
                      (c) => value && value.includes(c.value)
                    )}
                    onChange={(val: { value: string }[]) => {
                      onChange(val.map((c: { value: string }) => c.value));
                    }}
                    options={optionsCompanies}
                    isMulti
                  />
                )}
              />
            </Box>

            {typeParam === "OR,ORR" && (
              <>
                <Label>Komentarz</Label>
                <Input {...register("comment")} />
              </>
            )}
            {typeParam === "OR,ORR" && (
              <>
                <Box>
                  <Label>Wykonawca SABP</Label>
                  <Controller
                    control={control}
                    name="contractorSA"
                    render={({ field: { onChange, value, ref } }) => (
                      <StyledMultiSelect
                        closeMenuOnSelect={false}
                        inputRef={ref}
                        value={optionsContractorsSA?.filter(
                          (c) => value && value.includes(c.value)
                        )}
                        onChange={(val: { value: string }[]) => {
                          onChange(val.map((c: { value: string }) => c.value));
                        }}
                        options={optionsContractorsSA}
                        isMulti
                      />
                    )}
                  />
                </Box>

                <Box>
                  <Label>Wykonawca CW</Label>
                  <Controller
                    control={control}
                    name="contractorCW"
                    render={({ field: { onChange, value, ref } }) => (
                      <StyledMultiSelect
                        closeMenuOnSelect={false}
                        inputRef={ref}
                        value={optionsContractorsCW?.filter(
                          (c) => value && value.includes(c.value)
                        )}
                        onChange={(val: { value: string }[]) => {
                          onChange(val.map((c: { value: string }) => c.value));
                        }}
                        options={optionsContractorsCW}
                        isMulti
                      />
                    )}
                  />
                </Box>
              </>
            )}

            {typeParam === "OR,ORR" && (
              <Box>
                <Select label="Typ obiektu" {...register("type")}>
                  <option value="" />
                  <option>OR</option>
                  <option>ORR</option>
                </Select>
              </Box>
            )}
            {!["OR,ORR", "Tunel", "FDS"].includes(
              urlToPageTitleMap[typeParam as TaskType]
            ) && (
              <Box>
                <Label>Kilometraż</Label>
                <Input {...register("chainage")} />
              </Box>
            )}
            {urlToPageTitleMap[typeParam as TaskType] !== "milestone" && (
              <Box>
                <Label>IZ</Label>
                <Controller
                  control={control}
                  name="iz"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsIzs.filter(
                        (c) => value && value.includes(c.value)
                      )}
                      onChange={(val) => {
                        val.find((option) => option.value === "all")
                          ? onChange(optionsIzs.map((c) => c.value))
                          : onChange(val.map((c) => c.value));
                      }}
                      options={[
                        { value: "all", label: "Wszystkie" },
                        ...optionsIzs,
                      ]}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}

            {!optionsTags ? (
              <span style={{ color: "orangered" }}>Tags loading...</span>
            ) : (
              ""
            )}

            {optionsTags && (
              <Box>
                <Label>Tag</Label>
                <Controller
                  control={control}
                  name="tags"
                  render={({ field: { onChange, value, ref } }) => (
                    <StyledMultiSelect
                      closeMenuOnSelect={false}
                      inputRef={ref}
                      value={optionsTags.filter((c) =>
                        value?.includes(c.value)
                      )}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={optionsTags}
                      isMulti
                    />
                  )}
                />
              </Box>
            )}

            <Box>
              <Label>Linia</Label>
              <Input {...register("lineNumber")} />
            </Box>

            <Label>Km</Label>

            {["OTK", "Nr Certyfikacji WE", "Nr Dopuszczenia UTK"].includes(
              urlToPageTitleMap[typeParam as TaskType]
            ) ? (
              <StyledFlex>
                <Box>
                  <Input
                    type="number"
                    placeholder="od"
                    {...register("chainageStartFrom")}
                  />
                </Box>
                <Box ml={2}>
                  <Input
                    type="number"
                    placeholder="do"
                    {...register("chainageEndTo")}
                  />
                </Box>
              </StyledFlex>
            ) : (
              <StyledFlex>
                <Box>
                  <Input
                    type="number"
                    placeholder="od"
                    {...register("chainageFrom")}
                  />
                </Box>
                <Box ml={2}>
                  <Input
                    type="number"
                    placeholder="do"
                    {...register("chainageTo")}
                  />
                </Box>
              </StyledFlex>
            )}

            <Box>
              <Select label="Aktywny" {...register("active")}>
                <option value="" />
                <option value="true">tak</option>
                <option value="false">nie</option>
              </Select>
            </Box>

            {urlToPageTitleMap[typeParam as TaskType] !== "milestone" && (
              <Box>
                <Select label="Czy jest w hrf" {...register("inHRF")}>
                  <option value="" />
                  <option value="true">tak</option>
                  <option value="false">nie</option>
                </Select>
              </Box>
            )}

            <Box>
              <Select label="Czy ma błędy" {...register("hasError")}>
                <option value="" />
                <option value="true">tak</option>
                <option value="false">nie</option>
              </Select>
            </Box>

            <DatePickerHeader text={"Data rozpoczęcia"} />
            <Flex>
              <Controller
                control={control}
                name="startDateFrom"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText={"od"}
                  />
                )}
              />
              <Controller
                control={control}
                name="startDateTo"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText={"do"}
                  />
                )}
              />
            </Flex>

            <DatePickerHeader text={"Data zakończenia"} />
            <Flex>
              <Controller
                control={control}
                name="endDateFrom"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText={"od"}
                  />
                )}
              />
              <Controller
                control={control}
                name="endDateTo"
                render={({ field }) => (
                  <StyledDatePicker
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(date) => {
                      field.onChange(date instanceof Date ? date : "");
                    }}
                    customInput={<Input value={field.value} />}
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                    placeholderText={"do"}
                  />
                )}
              />
            </Flex>

            <Label>Wykonano %</Label>

            <StyledFlex>
              <Box>
                <Input
                  type="number"
                  placeholder="od"
                  {...register("completionPercentageFrom")}
                />
              </Box>
              <Box ml={2}>
                <Input
                  type="number"
                  placeholder="do"
                  {...register("completionPercentageTo")}
                />
              </Box>
            </StyledFlex>

            {urlToPageTitleMap[typeParam as TaskType] !== "milestone" && (
              <>
                <Label>Rozliczono %</Label>

                <Flex>
                  <Box>
                    <Input
                      type="number"
                      placeholder="od"
                      {...register("settledPercentageFrom")}
                    />
                  </Box>
                  <Box ml={2}>
                    <Input
                      type="number"
                      placeholder="do"
                      {...register("settledPercentageTo")}
                    />
                  </Box>
                </Flex>
              </>
            )}
          </Filters>

          {urlToPageTitleMap[typeParam as TaskType] === "OR,ORR" && (
            <ORFilter setValue={setValue} watcher={watcher} />
          )}
        </form>
      </Box>
    </Card>
  );
};

export default TasksFilter;
