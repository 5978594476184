import { IPagePermission, IUserGroup } from "types/permissions";
import { fetchAPI } from "utilities/fetchAPI";
import { IUser } from "./user";

interface GetUserGroupsRes {
  data: IUserGroup[];
}

export const getUserGroups = () =>
  fetchAPI<GetUserGroupsRes>(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/`
  );

export const createUserGroup = (data: IUserGroup) =>
  fetchAPI<IUserGroup>(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/`,
    {
      method: "POST",
      body: JSON.stringify({ ...data }),
    }
  );

export const updateUserGroup = (
  groupId: string,
  data: Pick<IUserGroup, "humanizeName" | "groupRole" | "description">
) =>
  fetchAPI<IUserGroup>(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}`,
    {
      method: "PUT",
      body: JSON.stringify({ ...data }),
    }
  );

export const deleteUserGroup = (groupId: string) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}`,
    {
      method: "DELETE",
    }
  );

export const getUserRoles = () =>
  fetchAPI<GetUserGroupsRes>(
    `${process.env.REACT_APP_API_URL}/permissions/user-roles/list`
  );

export const getFormatedUserRoles = () =>
  fetchAPI<IPagePermission[]>(
    `${process.env.REACT_APP_API_URL}/permissions/user-roles/to-frontend-format`
  );

interface GetUserGroupUsersRes {
  data: IUser[];
}

export const getUserGroupUsers = (groupId: string) =>
  fetchAPI<GetUserGroupUsersRes>(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}/users/`
  );

export const getUserGroupRoles = (groupId: string) =>
  fetchAPI<string[]>(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}/roles/`
  );

export const addRolesToGroup = (groupId: string, roles: string[]) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}/roles/add`,
    {
      method: "PUT",
      body: JSON.stringify(roles),
    }
  );

export const removeRolesFromGroup = (groupId: string, roles: string[]) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}/roles/remove`,
    {
      method: "PUT",
      body: JSON.stringify(roles),
    }
  );
