/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Comment } from "components/Comment";
import { format } from "date-fns";
import { useParams, useHistory } from "react-router-dom";
import { fetchComments, newComment, IComment } from "services/comments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { TrixEditor } from "react-trix";
import "trix/dist/trix";
import { toast } from "react-toastify";
import styled from "styled-components";
import qs from "qs";

interface ICommentsWindow {
  users: IUser[];
  fullWidth?: boolean;
  fullHeight?: boolean;
  showComments: boolean;
}
interface IUser {
  avatar: string;
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  partner: string;
  position: string;
  tag: string;
}
interface IRoot {
  fullHeight: boolean;
}

const Root = styled.div<IRoot>`
  ${({ fullHeight }) => `
    position: relative;
    height: ${fullHeight ? "100%" : "calc(100% - 36px)"};
    width: 250px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(30, 46, 102, 0.1);
  `}
`;
const StyledBox = styled(Box)`
  height: 100%;
`;
const StyledFlex = styled(Flex)`
  height: 100%;
`;
const CommentWindow = styled.div`
  border-radius: 10px;
  height: 100%;
  overflow: auto;
`;
const WriteMessage = styled.div`
  position: relative;
  padding: 5px;
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 100%;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => `
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 3px;
    font-size: 18px;
    color: ${theme.palette.primary};
    cursor: pointer;
  `}
`;
const StyledTrixEditor = styled(TrixEditor)`
  width: 100%;
  display: flex;
  border: none !important;
  max-height: 100px;
  overflow: auto;
`;

const StyledCommentsHeading = styled.h3`
  text-align: center;
`;

export const CommentsWindow: React.FC<ICommentsWindow> = ({
  users,
  fullWidth,
  fullHeight,
  showComments,
}) => {
  const [messages, setMessages] = useState<IComment[]>([]);
  const [usersTag, setUsersTag] = useState<any[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [messageContent, setMessageContent] = useState<string>("");
  const history = useHistory();
  const { type } = useParams<{ type: string; disruptionId: string }>();

  const { selected } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const commentWindowRef = useRef<HTMLDivElement>(null);

  const createNewComment = async (content: string) => {
    const url = history.location.pathname;
    const peyload = {
      url: `${url}${selected ? `${selected}` : ""}`,
      taggedUsers: tags,
      content,
    };
    await newComment(peyload);
  };

  const mergeTags = () => {
    const usersTags = [
      {
        trigger: "@",
        tags: users.map((user) => ({
          name: user.tag,
          tag: `@${user.tag}`,
        })),
      },
    ];
    setUsersTag(usersTags);
  };
  const sendMessage = (message: string) => {
    const user = localStorage.getItem("currentUser");
    if (user) {
      const currentUser = JSON.parse(user);
      const newMsg = {
        user: currentUser.email,
        taggedUsers: [],
        createdAt: `${format(new Date(), "dd-MM-yyyy HH:mm")}`,
        content: message,
      };
      const newValue = [newMsg, ...messages];
      const element = document.querySelector(".trixEditor");
      if (element) {
        element.innerHTML = "";
      }
      setMessages(newValue);
      createNewComment(message);
    }
  };

  const handleClickSendMessage = () => {
    setMessageContent("");
    sendMessage(messageContent);
  };

  const handleChange = (html: string) => {
    setMessageContent(html);
  };

  const handleEditorReady = (editor: any) => {
    editor.insertString(messageContent);
  };

  useEffect(() => {
    const url = history.location.pathname;
    mergeTags();
    const urlChat = `${url}${selected ? `${selected}` : ""}`;
    console.log(
      "CommentsWindow.tsx (line 166) useEffect[users, type] urlChat =",
      urlChat
    );
    fetchComments(urlChat, 0, 100)
      .then(({ data }) => {
        const parsedMsg = data
          ? data.map((msg) => ({
              ...msg,
              user: users.find((val) => val.id === msg.user)?.email,
            }))
          : [];
        setMessages(parsedMsg);
      })
      .catch(() => toast.error("Nie udało się pobrać komentarzy."));

    return () => setMessages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, type]);

  useEffect(() => {
    if (commentWindowRef.current) {
      const element = commentWindowRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const pattern = / @([a-z]*[/.\-/_/@]?){12}/gm;
    const foundTags = messageContent.match(pattern);
    if (foundTags) {
      const tag = foundTags.map((val) => val.slice(1));
      setTags(tag);
    }
  }, [messageContent]);

  return (
    <Root
      style={{ width: fullWidth ? "100%" : "250px" }}
      fullHeight={!!fullHeight}
    >
      <StyledBox p={3}>
        <StyledFlex flexDirection="column" justifyContent="space-between">
          {selected || showComments ? (
            <h4>Komentarze</h4>
          ) : (
            <StyledCommentsHeading>
              Wybierz dany obiekt, aby zobaczyć historię jego komentarzy
            </StyledCommentsHeading>
          )}

          <CommentWindow ref={commentWindowRef}>
            <Flex flexDirection="column-reverse">
              {messages.map((msg) => (
                <div key={msg.id}>
                  <Comment {...msg} />
                </div>
              ))}
            </Flex>
          </CommentWindow>
          {(selected || showComments) && (
            <Box mt={2}>
              <WriteMessage>
                <StyledTrixEditor
                  autoFocus={false}
                  className="trixEditor"
                  value={messageContent}
                  placeholder="Wiadomość"
                  mergeTags={usersTag}
                  onChange={handleChange}
                  onEditorReady={handleEditorReady}
                />
                <StyledFontAwesomeIcon
                  title="Wyślij"
                  icon={faPaperPlane}
                  onClick={() => handleClickSendMessage()}
                />
              </WriteMessage>
            </Box>
          )}
        </StyledFlex>
      </StyledBox>
    </Root>
  );
};

// %2Fobjects%2FOR%2CORR%2Fc05dcdd4-d924-4f05-b181-2df2b143e8af

// %2Fobjects%2FOR%2CORR%2F%2Fc05dcdd4-d924-4f05-b181-2df2b143e8af
