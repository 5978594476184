import React, { useEffect } from "react";
import { Button } from "components/Button";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Input } from "components/Input";
import { Select } from "components/Select";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { ITaskDetails } from "types/taskDetails";
import { Box } from "components/Box";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { Flex } from "components/Flex";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { formatDate } from "utilities/formatDate";
import { useHistory } from "react-router-dom";
import { IKey } from "types/keys";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface IKeyDetailsComponent {
  details: IKey;
  keyId: string;
}

const Grid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const detailsFields = [
  {
    fieldSetTitle: "Dane ogólne",
    fields: [
      {
        label: "Nr klucza.",
        name: "keyNumber",
      },
      {
        label: "Nr OR 7.1-36.2",
        name: "objectNameIE50",
      },
      {
        label: "Nazwa IE-50",
        name: "objectNameIE50",
      },
      {
        label: "Nazwa lokalizacji",
        name: "locationName",
      },
      {
        label: "Ilość kluczy",
        name: "keysQuantity",
      },
      {
        label: "Nr lini",
        name: "lineNumber",
      },
      {
        label: "km",
        name: "chainage",
      },
      {
        label: "Data wydania kluczy",
        name: "lastGiveOutDate",
        type: "date",
        Cell: (item) =>
          item.row.original.lastGiveOutDate
            ? formatDate(new Date(item.row.original.lastGiveOutDate))
            : "--",
      },
      {
        label: "Ilość wydanych kluczy",
        name: "keysGivenQuantity",
      },
      {
        label: "Ilość dni od wydania",
        name: "daysSinceGiveOut",
      },
    ],
  },
];

export const KeyDetails = ({ details, keyId }: IKeyDetailsComponent) => {
  const { setTableData } = useTableSidebarDispatchContext();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const history = useHistory();
  const defaultValues = Object.fromEntries(
    Object.entries(details).filter(([key, value]) => value !== null)
  );

  const { register, handleSubmit, control } = useForm<ITaskDetails>({
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    setTableData({
      detailsObject: false,
    });
  }, []);

  return (
    <Box p={4}>
      <form>
        <Flex justifyContent="right">
          {loggedUserRoles?.includes("ROLE_KEYS_ECONOMY_UPDATE") && (
            <Box mr={2}>
              <Button type="submit" bordered>
                Zapisz
              </Button>
            </Box>
          )}
        </Flex>

        {detailsFields.map((detailsFieldsSet) => (
          <Box mb={6} key={detailsFieldsSet.fieldSetTitle}>
            <Flex mb={3} justifyContent="space-between" alignItems="center">
              <FieldSetTitle>{detailsFieldsSet.fieldSetTitle}</FieldSetTitle>
            </Flex>
            <Grid>
              {detailsFieldsSet.fields.map((detailField) =>
                detailField.type === "select" ? (
                  <Controller
                    key={detailField.name}
                    control={control}
                    name={detailField.name as "name"}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={detailField.label as "label"}
                        onChange={(e) =>
                          field.onChange(e.target.value === "true")
                        }
                      >
                        <option value="true" selected={true}>
                          Tak
                        </option>
                        <option value="false" selected={false}>
                          Nie
                        </option>
                      </Select>
                    )}
                  />
                ) : detailField.type === "date" ? (
                  <Controller
                    key={detailField.name}
                    control={control}
                    name={detailField.name as "name"}
                    render={({ field }) => (
                      <DatePicker
                        selected={
                          new Date(field?.value).toString() !==
                            "Invalid Date" && new Date(field.value)
                        }
                        onChange={(date) => {
                          field.onChange(
                            date instanceof Date
                              ? format(date, "yyyy-MM-dd")
                              : ""
                          );
                        }}
                        customInput={
                          <Input
                            label={detailField.label as "label"}
                            value={field.value?.toString()}
                          />
                        }
                        dateFormat="dd-MM-yyyy"
                        portalId="root-datepicker-portal"
                      />
                    )}
                  />
                ) : (
                  <Input
                    key={detailField.name}
                    label={detailField.label}
                    {...register(detailField.name as "name")}
                  />
                )
              )}
            </Grid>
          </Box>
        ))}
      </form>
    </Box>
  );
};
