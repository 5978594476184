import React, { useState, useEffect, useMemo } from "react";
import { PageLayout } from "components/PageLayout";
import { Box } from "components/Box";
import { Table } from "components/Table";
import { Column } from "react-table";
import format from "date-fns/format";
import { formatDate } from "utilities/formatDate";
import styled from "styled-components";
import { toast } from "react-toastify";
import { activityUser, IActiveResponse } from "services/user";
import { Input } from "components/Input";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { IUser } from "types/disruption";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";

const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 100px);
  margin: 50px;
  height: 85vh;
  overflow: auto;
  padding: 5px;
`;
const Line = styled.div`
  position: fixed;
  height: 100%;
  width: 2px;
  background-color: rgba(30, 46, 102, 0.1);
`;
const DateText = styled.p`
  position: absolute;
  left: 20px;
  margin: 0;
`;
const TimeElement = styled.div<ITimelineElement>`
  ${({ theme }) => `
		position: relative;
		padding: 16px;
		margin-left: 100px;
		margin-bottom: 20px;

		&::before {
			content: '';
			position: absolute;
			top: 3px;
			left: -105px;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background-color: ${theme.palette.primary};
			border: 1px solid ${theme.palette.primary};
		`}
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;
interface ITimelineElement {
  date: string;
}
interface IFiltersData {
  dateFrom: string;
  dateTo: string;
}
type IChanges = {
  changedBy: string;
  name: string;
  newValue: string;
  oldValue: string;
  newValueIdDate: boolean;
  oldValueIdDate: boolean;
};

const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
firstDay.setMonth(firstDay.getMonth() - 3);

const today = new Date();

export const UserActivityView = () => {
  const [timelineData, setTimelineData] = useState<IActiveResponse[]>([]);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);

  const { setTableData } = useTableSidebarDispatchContext();

  const { control, handleSubmit } = useForm<IFiltersData>();

  const columns: Array<Column<IChanges>> = useMemo(
    () => [
      {
        Header: "Pole",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Zmieniający",
        accessor: "changedBy",
        disableSortBy: true,
      },
      {
        Header: "Stara wartość",
        accessor: "oldValue",
        Cell: (item) =>
          item.row.original.oldValueIdDate
            ? formatDate(Date.parse(item.row?.original?.oldValue))
            : item.row.original.oldValue
            ? `${item.row.original.oldValue}`
            : "---",
        disableSortBy: true,
      },
      {
        Header: "Nowa wartość",
        accessor: "newValue",
        Cell: (item) =>
          item.row.original.newValueIdDate
            ? formatDate(Date.parse(item.row?.original?.newValue))
            : item.row.original.newValue
            ? `${item.row.original.newValue}`
            : "---",
        disableSortBy: true,
      },
    ],
    []
  );

  const fetchData = async (id: string) => {
    try {
      const timeline = await activityUser(
        id,
        format(firstDay, "dd-MM-yyyy"),
        format(today, "dd-MM-yyyy")
      );

      timeline.forEach(({ changedProperties }) => {
        changedProperties.map((obj) => {
          if (obj.name.includes("Date") && obj.name) {
            if (obj.newValue) {
              obj.newValue = formatDate(new Date(obj.newValue));
            }
            if (obj.oldValue) {
              obj.oldValue = formatDate(new Date(obj.oldValue));
            }
          }
        });
      });

      setTimelineData(timeline);
    } catch (err) {
      toast.error("Wystąpił błąd podczas pobierania listy aktywności.");
    }
  };

  const generateNewFilters = async ({ dateFrom, dateTo }: IFiltersData) => {
    if (!currentUser) return;
    const filteredData = await activityUser(
      currentUser.id,
      format(new Date(dateFrom || firstDay), "dd-MM-yyyy"),
      format(new Date(dateTo || today), "dd-MM-yyyy")
    );
  };

  useEffect(() => {
    const user = localStorage.getItem("currentUser");
    if (!user) return;
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    setTimeout(() => {
      setTableData({ hiddenSidebar: false, onlyFilters: true });
    });

    fetchData(parsedUser.id);
  }, []);

  const activityUserFilters = () => (
    <Box px={4} pb={3}>
      <Box mb={5} py={2}>
        <form onSubmit={handleSubmit(generateNewFilters)}>
          <Flex justifyContent="start">
            <Button type="submit" bordered>
              Filtruj
            </Button>
          </Flex>
        </form>
      </Box>

      <Controller
        control={control}
        name="dateFrom"
        render={({ field }) => (
          <DatePicker
            selected={
              field.value
                ? new Date(field.value)
                : new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            }
            onChange={(date) => {
              field.onChange(
                date instanceof Date ? format(date, "dd-MM-yyyy") : ""
              );
            }}
            customInput={<Input label="Data od" value={field.value} />}
            dateFormat="dd-MM-yyyy"
            portalId="root-datepicker-portal"
          />
        )}
      />
      <Controller
        control={control}
        name="dateTo"
        render={({ field }) => (
          <DatePicker
            selected={field.value ? new Date(field.value) : new Date()}
            onChange={(date) => {
              field.onChange(
                date instanceof Date ? format(date, "dd-MM-yyyy") : ""
              );
            }}
            customInput={<Input label="Data do" value={field.value} />}
            dateFormat="dd-MM-yyyy"
            portalId="root-datepicker-portal"
          />
        )}
      />
    </Box>
  );

  return (
    <PageLayout isScroll>
      <Wrapper>
        <Line />
        {timelineData.length &&
          timelineData?.map((prop) => (
            <React.Fragment key={prop.date}>
              <DateText>
                {formatDate(new Date(prop.date))} <br />{" "}
                {format(new Date(prop.date), "HH:mm")}
              </DateText>
              <TimeElement key={prop.date} date={prop.date}>
                <Box style={{ width: "90%" }}>
                  <Table<IChanges>
                    showSidebar={false}
                    fullHeight={false}
                    FilterComponent={activityUserFilters}
                    columns={columns}
                    data={prop.changedProperties}
                    getLink={() => ""}
                  />
                </Box>
              </TimeElement>
            </React.Fragment>
          ))}
      </Wrapper>
    </PageLayout>
  );
};
