import { Box } from "components/Box";
import { Flex } from "components/Flex";
import styled from "styled-components";

export const StyledHeading = styled.h3`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const WrapperInput = styled(Box)`
  min-width: 150px;
`;

export const StyledWrapperFlex = styled(Flex)`
  border-radius: 7px;
  background-color: #fff;
`;
