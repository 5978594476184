import React from "react";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { INotification } from "services/notifications";
import { format } from "date-fns";
import styled from "styled-components";

interface Props extends INotification {
  redirectAction: (id: string) => void;
}
interface ITextWithoutLineHeight {
  read?: boolean;
}

const WrapperNotification = styled.div`
  position: relative;
  margin-bottom: 10px;
  /* cursor: pointer; */
`;
const RightValue = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
`;

const Typography = styled.p`
  text-align: right;
  font-size: 12px;
`;
const TextWithoutLineHeight = styled.p<ITextWithoutLineHeight>`
  ${({ read = true }) => `
		font-weight: ${read ? "300" : "800"};
	`}
  line-height: 0;
`;

export const Notification: React.FC<Props> = ({
  content,
  createdAt,
  id,
  level,
  read,
  title,
  user,
  contextMeta,
  redirectAction,
}) => {
  const findUser = (userId: string) => "Rafał Łochina";

  return (
    <WrapperNotification>
      <Card>
        <Box p={1} px={3}>
          <Flex>
            <Box mr={1}>
              <TextWithoutLineHeight read={read}>{level}</TextWithoutLineHeight>
            </Box>
            <TextWithoutLineHeight read={read}>{title}</TextWithoutLineHeight>
          </Flex>

          <TextWithoutLineHeight
            onClick={() => redirectAction(id)}
            dangerouslySetInnerHTML={{ __html: content }}
          />

          <RightValue>
            <Typography>
              {contextMeta.userId
                ? findUser(contextMeta.userId)
                : "Użytkonik nieznany"}
              <br />
              {format(new Date(createdAt), "dd-MM-yyyy HH:mm")}
            </Typography>
          </RightValue>
        </Box>
      </Card>
    </WrapperNotification>
  );
};
