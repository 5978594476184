export const dictionaryCategories: Record<string, string> = {
  Kolizje: "Kolizje z inwestycjami w toku.",
  "Kolizje potencjalne":
    "Potencjalne kolizje zgłaszane w związku z uzgodnieniami dokumentacji.",
  "Warunki fiz.": "Nieprzewidywalne Warunki Fizyczne (Subklauzula 4.12).",
  "Władza - opóźnienia": "Opóźnienia w działaniu władzy (Subklauzula 8.5).",
  Infrastruktura: "Braki w infrastrukturze zapewnianej przez Zamawiającego.",
  PDN: "Prawo dostępu do nieruchomości (PDN).",
  Kolokacja: "Kolokacja/sektoryzacja.",
  PnB: "Pozwolenia na budowę.",
  Dewastacja: "Dewastacja Placu Budowy/uszkodzenia prac Wykonawcy.",
  Dokumentacja: "Uzgodnienia dokumentacji z Zamawiającym.",
  "Podmioty trzecie":
    "Uzgodnienia z podmiotami trzecimi (w tym w zakresie umów przyłączeniowych).",
  "Siła wyższa": "Siła Wyższa.",
  FDS: "Zmiany w FDS.",
  "Planowanie radiowe": "Planowanie radiowe.",
  "Zmiany prawa": "Zmiany prawa (Subklauzula 13.7).",
  Pozostałe: "Pozostałe (Roszczenie nie pasujące dla żadnej z ww. kategorii).",
  NSS: "Integracja systemu NSS",
};
