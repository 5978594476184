import { theme } from "config/theme";
import styled from "styled-components";
import loupe from "../../icons/loupe.svg";

export const StyledSearch = styled.input<{
  background?: string;
  width?: number;
}>`
  display: flex;
  align-items: center;
  ${({ background }) => (background ? `background:${background};` : "")}
  ${({ width }) => (width ? `width: ${width}px;` : "width: 155px;")}
  height: 100%;
  padding: 8px 9px;
  border: none;
  border-radius: 5px;
  position: relative;
  transition: border-color 0.3s;
  color: ${theme.palette.text.dark};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${theme.palette.text.dark};
  }
`;

export const Label = styled.label<{
  width?: number;
}>`
  position: relative;
  ${({ width }) => (width ? `width: ${width}px;` : "width: 155px;")}
  &::before {
    z-index: 2;
    position: absolute;
    content: " ";
    top: 10px;
    right: 6px;
    min-width: 12px;
    min-height: 12px;
    background-image: url(${loupe});
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
`;
