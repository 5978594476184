import {
  DetailsList,
  DetailsListLayoutMode,
  Icon,
  SelectionMode,
} from "@fluentui/react";
import { GraphFolder, GraphItem } from "types/msGraph";
import { MsFileIcon } from "./MsFileIcon";

interface Props {
  folder: GraphFolder;
  loadFolder: (id: string) => void;
  onSelectFile: (f: GraphItem) => void;
}

export const MsDetailList = ({ folder, loadFolder, onSelectFile }: Props) => (
  <DetailsList
    items={folder?.children || []}
    columns={[
      {
        key: "column1",
        name: "File Type",
        iconName: "Page",
        isIconOnly: true,
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: GraphItem) => <MsFileIcon name={item.name} />,
      },
      {
        key: "column2",
        name: "Name",
        minWidth: 150,
        maxWidth: 200,
        onRender: (item: GraphItem) => <span>{item.name}</span>,
      },
      {
        key: "column3",
        name: "Last Modified",
        minWidth: 70,
        maxWidth: 200,
        onRender: (item: GraphItem) => <span>{item.lastModifiedDateTime}</span>,
      },
      {
        key: "column4",
        name: "Modified By",
        minWidth: 70,
        maxWidth: 200,
        onRender: (item: GraphItem) => <span>{item.lastModifiedBy}</span>,
      },
      {
        key: "column5",
        name: "Created By",
        minWidth: 70,
        maxWidth: 200,
        onRender: (item: GraphItem) => <span>{item.createdBy}</span>,
      },
      {
        key: "column6",
        name: "Size",
        minWidth: 70,
        maxWidth: 200,
        onRender: (item: GraphItem) => <span>{item.size} KB</span>,
      },
      {
        key: "column5",
        name: "SharePoint",
        iconName: "FileSymlink",
        minWidth: 70,
        maxWidth: 90,
        isIconOnly: true,
        onRender: (item: GraphItem) => (
          <a
            target="_blank"
            href={item.webUrl}
            style={{ color: "inherit" }}
            rel="noreferrer"
          >
            <Icon iconName="FileSymlink" />
          </a>
        ),
      },
    ]}
    layoutMode={DetailsListLayoutMode.justified}
    selectionMode={SelectionMode.none}
    onItemInvoked={(f: GraphItem) => {
      if (!/\.([a-z]+)/.test(f.name)) {
        loadFolder(f.parent.path + "/" + f.name);
      } else {
        onSelectFile(f);
      }
    }}
  />
);
