/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Flex } from "components/Flex";
import { DisruptionPaymentsSummary } from "components/DisruptionPaymentsSummary";
import { DisruptionPaymentForm } from "containers/DisruptionPaymentForm";
import {
  IDisruptionPayments,
  IDisruptionPaymentsSummary,
  getDisruptionPayments,
  getDisruptionPaymentsSummary,
} from "services/disruptions";
import { Button } from "components/Button";
import { Table } from "components/Table";
import { useFetchDictionaryPartners } from "hooks/fetchDictionaryPartners";
import { Column } from "react-table";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

export const DisruptionPayments = () => {
  const [displayForm, setDisplayForm] = React.useState(false);
  const [data, setData] = React.useState<IDisruptionPayments[]>([]);
  const [summaryData, setSummaryData] = React.useState<
    IDisruptionPaymentsSummary[]
  >([]);
  const [selected, setSelected] = React.useState<IDisruptionPayments | null>(
    null
  );
  const { disruptionId } = useParams<{ disruptionId: string }>();

  const { dictionaryPartners } = useFetchDictionaryPartners();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const columns: Array<Column<IDisruptionPayments>> = React.useMemo(
    () => [
      {
        Header: "Partner",
        accessor: "partner",
      },
      {
        Header: "Wpływ",
        accessor: "unitCostCategories",
      },
      {
        Header: "Wycena",
        accessor: "costEstimation",
        Cell: (item) => (
          <p>{item.row.original.costEstimation.toLocaleString()} pln</p>
        ),
      },
      {
        Header: "Ilość jednostek",
        accessor: "units",
        Cell: (item) => <p>{item.row.original.units} szt.</p>,
      },
      {
        Header: "Suma kosztów",
        accessor: "sum",
        Cell: (item) => <p>{item.row.original.sum.toLocaleString()} pln</p>,
      },
      {
        Header: "Komentarz",
        accessor: "comment",
        Cell: (item) => {
          if (item.row.original.comment)
            return <FontAwesomeIcon icon={faComment} />;
          else return <FontAwesomeIcon icon={faTimes} />;
        },
      },
    ],
    []
  );

  React.useEffect(() => {
    getDisruptionPayments(disruptionId)
      .then((response) => {
        const newObject = response.map((item) => ({
          ...item,
          partner: dictionaryPartners?.find((val) => val.id === item.partner)
            ?.name,
        }));

        setData(newObject);
      })
      .catch(() => {
        toast.error("Nie udało się pobrać płatności.");
      });

    getDisruptionPaymentsSummary(disruptionId)
      .then((response) => {
        setSummaryData(response);
      })
      .catch(() => {
        toast.error("Nie udało się pobrać płatności.");
      });
  }, [displayForm, dictionaryPartners, disruptionId]);

  if (displayForm)
    return (
      <DisruptionPaymentForm
        handleCancelForm={setDisplayForm}
        selected={selected}
        data={data}
        setData={setData}
        setSelected={setSelected}
      />
    );

  return (
    <div>
      <Flex justifyContent="space-between">
        <h2>Koszty</h2>

        {loggedUserRoles?.includes("ROLE_DISRUPTIONS_LIST_COST_CREATE") && (
          <Button
            bordered
            onClick={() => {
              setSelected(null);
              setDisplayForm(true);
            }}
          >
            dodaj koszt
          </Button>
        )}
      </Flex>

      {data.length && <DisruptionPaymentsSummary data={summaryData} />}

      {data.length ? (
        <Table<IDisruptionPayments>
          columns={columns}
          data={data}
          onRowClick={(row) => {
            if (
              loggedUserRoles?.includes("ROLE_DISRUPTIONS_LIST_COST_UPDATE")
            ) {
              const partnerId = dictionaryPartners?.find(
                (val) => val.name === row.original.partner
              )?.id;

              setSelected({ ...row.original, partner: partnerId });
              setDisplayForm(true);
            }
          }}
        />
      ) : (
        <p>brak kosztów</p>
      )}
    </div>
  );
};
