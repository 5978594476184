import React, { useEffect } from "react";
import { Flex } from "components/Flex";
import { FC } from "react";
import { Filters } from "views/TaskTypeView/TaskType.styled";
import { RangeDatePicker } from "components/RangeDatePicker";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Input } from "components/Input";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { IFilters } from "contexts/TasksContext";
import { ORFilterDatePicker } from "./ORFilterDatePicker";

import qs from "qs";
import { DatePickerHeader } from "components/DatePickerHeader";

interface ORFIlterProps {
  setValue?: (name: string, value: string) => void;
  watcher?: IFilters;
}

const ORFilter: FC<ORFIlterProps> = React.memo(({ setValue, watcher }) => {
  const history = useHistory();

  const { control } = useForm();

  const { filter } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const data = filter || watcher;

  const [
    subtaskPraceProjektoweStartDateRange,
    setSubtaskPraceProjektoweStartDateFrom,
  ] = React.useState([
    data["subtaskPraceProjektoweStartDateFrom"],
    data["subtaskPraceProjektoweStartDateTo"],
  ]);
  const [subtaskPraceProjektoweEndDate, setSubtaskPraceProjektoweEndDate] =
    React.useState([
      data["subtaskPraceProjektoweEndDateFrom"],
      data["subtaskPraceProjektoweEndDateTo"],
    ]);
  const [subtaskDULICPStartDate, setSubtaskDULICPStartDate] = React.useState([
    data["subtaskDULICPStartDateFrom"],
    data["subtaskDULICPStartDateTo"],
  ]);
  const [subtaskDULICPEndDate, setSubtaskDULICPEndDate] = React.useState([
    data["subtaskDULICPEndDateFrom"],
    data["subtaskDULICPEndDateTo"],
  ]);
  const [subtaskPnBStartDate, setSubtaskPnBStartDate] = React.useState([
    data["subtaskPnBStartDateFrom"],
    data["subtaskPnBStartDateTo"],
  ]);
  const [subtaskPnBEndDate, setSubtaskPnBEndDate] = React.useState([
    data["subtaskPnBEndDateFrom"],
    data["subtaskPnBEndDateTo"],
  ]);
  const [subtaskPraceBudowlaneStartDate, setSubtaskPraceBudowlaneStartDate] =
    React.useState([
      data["subtaskPraceBudowlaneStartDateFrom"],
      data["subtaskPraceBudowlaneStartDateTo"],
    ]);
  const [subtaskPraceBudowlaneEndDate, setSubtaskPraceBudowlaneEndDate] =
    React.useState([
      data["subtaskPraceBudowlaneEndDateFrom"],
      data["subtaskPraceBudowlaneEndDateTo"],
    ]);
  const [subtaskPradNaObiekcieStartDate, setSubtaskPradNaObiekcieStartDate] =
    React.useState([
      data["subtaskPradNaObiekcieStartDateFrom"],
      data["subtaskPradNaObiekcieStartDateTo"],
    ]);
  const [subtaskPradNaObiekcieEndDate, setSubtaskPradNaObiekcieEndDate] =
    React.useState([
      data["subtaskPradNaObiekcieEndDateFrom"],
      data["subtaskPradNaObiekcieEndDateTo"],
    ]);
  const [
    subtaskZagospodarowanieStartDate,
    setSubtaskZagospodarowanieStartDate,
  ] = React.useState([
    data["subtaskZagospodarowanieStartDateFrom"],
    data["subtaskZagospodarowanieStartDateTo"],
  ]);
  const [subtaskZagospodarowanieEndDate, setSubtaskZagospodarowanieEndDate] =
    React.useState([
      data["subtaskZagospodarowanieEndDateFrom"],
      data["subtaskZagospodarowanieEndDateTo"],
    ]);
  const [subtaskWdrozenieStartDate, setSubtaskWdrozenieStartDate] =
    React.useState([
      data["subtaskWdrozenieStartDateFrom"],
      data["subtaskWdrozenieStartDateTo"],
    ]);
  const [subtaskWdrozenieEndDate, setSubtaskWdrozenieEndDate] = React.useState([
    data["subtaskWdrozenieEndDateFrom"],
    data["subtaskWdrozenieEndDateTo"],
  ]);
  const [subtaskUruchomienieStartDate, setSubtaskUruchomienieStartDate] =
    React.useState([
      data["subtaskUruchomienieStartDateFrom"],
      data["subtaskUruchomienieStartDateTo"],
    ]);
  const [subtaskUruchomienieEndDate, setSubtaskUruchomienieEndDate] =
    React.useState([
      data["subtaskUruchomienieEndDateFrom"],
      data["subtaskUruchomienieEndDateTo"],
    ]);
  const [
    taskMetaActualMontazWiezyEndDate,
    setTaskMetaActualMontazWiezyEndDate,
  ] = React.useState([
    data["taskMetaActualMontazWiezyEndDateFrom"],
    data["taskMetaActualMontazWiezyEndDateTo"],
  ]);
  const [
    taskMetaForecastMontazWiezyEndDate,
    setTaskMetaForecastMontazWiezyEndDate,
  ] = React.useState([
    data["taskMetaForecastMontazWiezyEndDateFrom"],
    data["taskMetaForecastMontazWiezyEndDateTo"],
  ]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskPraceProjektoweStartDateFrom",
      subtaskPraceProjektoweStartDateRange[0]
    );
    setValue(
      "subtaskPraceProjektoweStartDateTo",
      subtaskPraceProjektoweStartDateRange[1]
    );
  }, [subtaskPraceProjektoweStartDateRange]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskPraceProjektoweEndDateFrom",
      subtaskPraceProjektoweEndDate[0]
    );
    setValue(
      "subtaskPraceProjektoweEndDateTo",
      subtaskPraceProjektoweEndDate[1]
    );
  }, [subtaskPraceProjektoweEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue("subtaskDULICPStartDateFrom", subtaskDULICPStartDate[0]);
    setValue("subtaskDULICPStartDateTo", subtaskDULICPStartDate[1]);
  }, [subtaskDULICPStartDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue("subtaskDULICPEndDateFrom", subtaskDULICPEndDate[0]);
    setValue("subtaskDULICPEndDateTo", subtaskDULICPEndDate[1]);
  }, [subtaskDULICPEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue("subtaskPnBEndDateFrom", subtaskPnBEndDate[0]);
    setValue("subtaskPnBEndDateTo", subtaskPnBEndDate[1]);
  }, [subtaskPnBEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskPraceBudowlaneStartDateFrom",
      subtaskPraceBudowlaneStartDate[0]
    );
    setValue(
      "subtaskPraceBudowlaneStartDateTo",
      subtaskPraceBudowlaneStartDate[1]
    );
  }, [subtaskPraceBudowlaneStartDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskPraceBudowlaneEndDateFrom",
      subtaskPraceBudowlaneEndDate[0]
    );
    setValue("subtaskPraceBudowlaneEndDateTo", subtaskPraceBudowlaneEndDate[1]);
  }, [subtaskPraceBudowlaneEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskPradNaObiekcieStartDateFrom",
      subtaskPradNaObiekcieStartDate[0]
    );
    setValue(
      "subtaskPradNaObiekcieStartDateTo",
      subtaskPradNaObiekcieStartDate[1]
    );
  }, [subtaskPradNaObiekcieStartDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskPradNaObiekcieEndDateFrom",
      subtaskPradNaObiekcieEndDate[0]
    );
    setValue("subtaskPradNaObiekcieEndDateTo", subtaskPradNaObiekcieEndDate[1]);
  }, [subtaskPradNaObiekcieEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskZagospodarowanieStartDateFrom",
      subtaskZagospodarowanieStartDate[0]
    );
    setValue(
      "subtaskZagospodarowanieStartDateTo",
      subtaskZagospodarowanieStartDate[1]
    );
  }, [subtaskZagospodarowanieStartDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskZagospodarowanieEndDateFrom",
      subtaskZagospodarowanieEndDate[0]
    );
    setValue(
      "subtaskZagospodarowanieEndDateTo",
      subtaskZagospodarowanieEndDate[1]
    );
  }, [subtaskZagospodarowanieEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue("subtaskWdrozenieStartDateFrom", subtaskWdrozenieStartDate[0]);
    setValue("subtaskWdrozenieStartDateTo", subtaskWdrozenieStartDate[1]);
  }, [subtaskWdrozenieStartDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue("subtaskWdrozenieEndDateFrom", subtaskWdrozenieEndDate[0]);
    setValue("subtaskWdrozenieEndDateTo", subtaskWdrozenieEndDate[1]);
  }, [subtaskWdrozenieEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "subtaskUruchomienieStartDateFrom",
      subtaskUruchomienieStartDate[0]
    );
    setValue("subtaskUruchomienieStartDateTo", subtaskUruchomienieStartDate[1]);
  }, [subtaskUruchomienieStartDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue("subtaskUruchomienieEndDateFrom", subtaskUruchomienieEndDate[0]);
    setValue("subtaskUruchomienieEndDateTo", subtaskUruchomienieEndDate[1]);
  }, [subtaskUruchomienieEndDate]);
  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "taskMetaActualMontazWiezyEndDateFrom",
      taskMetaActualMontazWiezyEndDate[0]
    );
    setValue(
      "taskMetaActualMontazWiezyEndDateTo",
      taskMetaActualMontazWiezyEndDate[1]
    );
  }, [taskMetaActualMontazWiezyEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue(
      "taskMetaForecastMontazWiezyEndDateFrom",
      taskMetaForecastMontazWiezyEndDate[0]
    );
    setValue(
      "taskMetaForecastMontazWiezyEndDateTo",
      taskMetaForecastMontazWiezyEndDate[1]
    );
  }, [taskMetaForecastMontazWiezyEndDate]);

  React.useEffect(() => {
    if (!setValue) return;
    setValue("subtaskPnBStartDateFrom", subtaskPnBStartDate[0]);
    setValue("subtaskPnBStartDateTo", subtaskPnBStartDate[1]);
  }, [subtaskPnBStartDate]);

  return (
    <Filters itemsInRowNumber={1}>
      <DatePickerHeader text={"Prace projektowe rozpoczęcie"} />
      <Flex>
        <Controller
          control={control}
          name="praceProjektoweRozpoczecieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceProjektoweStartDateRange[0]}
              setValue={setSubtaskPraceProjektoweStartDateFrom}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="praceProjektoweRozpoczecieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceProjektoweStartDateRange[1]}
              setValue={setSubtaskPraceProjektoweStartDateFrom}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Prace projektowe zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="praceProjektoweZakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceProjektoweEndDate[0]}
              setValue={setSubtaskPraceProjektoweEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="praceProjektoweZakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceProjektoweEndDate[1]}
              setValue={setSubtaskPraceProjektoweEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"DULICP rozpoczęcie"} />
      <Flex>
        <Controller
          control={control}
          name="DULICProzpoczecieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskDULICPStartDate[0]}
              setValue={setSubtaskDULICPStartDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="DULICProzpoczecieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskDULICPStartDate[1]}
              setValue={setSubtaskDULICPStartDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"DULICP zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="DULICPzakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskDULICPEndDate[0]}
              setValue={setSubtaskDULICPEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="DULICPzakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskDULICPEndDate[1]}
              setValue={setSubtaskDULICPEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"PnB rozpoczęcie"} />
      <Flex>
        <Controller
          control={control}
          name="PnBrozpoczecieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPnBStartDate[0]}
              setValue={setSubtaskPnBStartDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="PnBrozpoczecieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPnBStartDate[1]}
              setValue={setSubtaskPnBStartDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"PnB zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="PnBzakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPnBEndDate[0]}
              setValue={setSubtaskPnBEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="PnBzakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPnBEndDate[1]}
              setValue={setSubtaskPnBEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Prace budowlane rozpoczęcie"} />
      <Flex>
        <Controller
          control={control}
          name="praceBudowlaneRozpoczecieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceBudowlaneStartDate[0]}
              setValue={setSubtaskPraceBudowlaneStartDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="praceBudowlaneRozpoczecieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceBudowlaneStartDate[1]}
              setValue={setSubtaskPraceBudowlaneStartDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Prace budowlane zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="praceBudowlaneZakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceBudowlaneEndDate[0]}
              setValue={setSubtaskPraceBudowlaneEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="praceBudowlaneZakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPraceBudowlaneEndDate[1]}
              setValue={setSubtaskPraceBudowlaneEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Prąd na obiekcie rozpoczęcie"} />
      <Flex>
        <Controller
          control={control}
          name="pradNaObiekcieRozpoczecieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPradNaObiekcieStartDate[0]}
              setValue={setSubtaskPradNaObiekcieStartDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="pradNaObiekcieRozpoczecieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPradNaObiekcieStartDate[1]}
              setValue={setSubtaskPradNaObiekcieStartDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Prąd na obiekcie zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="pradNaObiekcieZakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPradNaObiekcieEndDate[0]}
              setValue={setSubtaskPradNaObiekcieEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="pradNaObiekcieZakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskPradNaObiekcieEndDate[1]}
              setValue={setSubtaskPradNaObiekcieEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Zagospodarowanie rozpoczęcie od"} />
      <Flex>
        <Controller
          control={control}
          name="zagospodarowanieRozpoczecieOdStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskZagospodarowanieStartDate[0]}
              setValue={setSubtaskZagospodarowanieStartDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="zagospodarowanieRozpoczecieOdEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskZagospodarowanieStartDate[1]}
              setValue={setSubtaskZagospodarowanieStartDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Zagospodarowanie zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="zagospodarowanieZakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskZagospodarowanieEndDate[0]}
              setValue={setSubtaskZagospodarowanieEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="zagospodarowanieZakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskZagospodarowanieEndDate[1]}
              setValue={setSubtaskZagospodarowanieEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Wdrożenie rozpoczęcie"} />
      <Flex>
        <Controller
          control={control}
          name="wdrozenieRozpoczecieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskWdrozenieStartDate[0]}
              setValue={setSubtaskWdrozenieStartDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="wdrozenieRozpoczecieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskWdrozenieStartDate[1]}
              setValue={setSubtaskWdrozenieStartDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Wdrożenie zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="wdrozenieZakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskWdrozenieEndDate[0]}
              setValue={setSubtaskWdrozenieEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="wdrozenieZakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskWdrozenieEndDate[1]}
              setValue={setSubtaskWdrozenieEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Uruchomienie rozpoczęcie"} />
      <Flex>
        <Controller
          control={control}
          name="uruchomienieRozpoczecieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskUruchomienieStartDate[0]}
              setValue={setSubtaskUruchomienieStartDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="uruchomienieRozpoczecieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskUruchomienieStartDate[1]}
              setValue={setSubtaskUruchomienieStartDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Uruchomienie zakończenie"} />
      <Flex>
        <Controller
          control={control}
          name="uruchomienieZakonczenieStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskUruchomienieEndDate[0]}
              setValue={setSubtaskUruchomienieEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="uruchomienieZakonczenieEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={subtaskUruchomienieEndDate[1]}
              setValue={setSubtaskUruchomienieEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Data zakończenia montaży wieży Aktual"} />
      <Flex>
        <Controller
          control={control}
          name="dataZakonczeniaMontazyWiezyAktualStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={taskMetaActualMontazWiezyEndDate[0]}
              setValue={setTaskMetaActualMontazWiezyEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="dataZakonczeniaMontazyWiezyAktualEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={taskMetaActualMontazWiezyEndDate[1]}
              setValue={setTaskMetaActualMontazWiezyEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
      <DatePickerHeader text={"Data zakończenia montaży wieży Forecast"} />
      <Flex>
        <Controller
          control={control}
          name="dataZakonczeniaMontazyWiezyForecastStart"
          render={({ field }) => (
            <ORFilterDatePicker
              value={taskMetaForecastMontazWiezyEndDate[0]}
              setValue={setTaskMetaForecastMontazWiezyEndDate}
              field={field}
              status="start"
            />
          )}
        />
        <Controller
          control={control}
          name="dataZakonczeniaMontazyWiezyForecastEnd"
          render={({ field }) => (
            <ORFilterDatePicker
              value={taskMetaForecastMontazWiezyEndDate[1]}
              setValue={setTaskMetaForecastMontazWiezyEndDate}
              field={field}
              status="end"
            />
          )}
        />
      </Flex>
    </Filters>
  );
});

export default ORFilter;
