import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Input } from "components/Input";
import { Textarea } from "components/Textarea";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { IUpdate } from "types/update";
import { Flex } from "components/Flex";
import React, { useState } from "react";
import {
  addDisruptionUpdate,
  updateDisruptionUpdate,
} from "services/disruptions";
import { toast } from "react-toastify";
import { differenceInCalendarDays } from "date-fns/esm";
import { IDisruption } from "types/disruption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { MsFilePicker } from "components/MsFilePicker";

interface IUpdateForm {
  disruption: IDisruption;
  onSubmit?: (response: IUpdate) => any;
  update?: IUpdate;
}

interface IUpdateTaskFormData {
  date: string;
  sharepointUrl: Array<string>;
  description: string;
}

const Grid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const AddLinkButtonWrapper = styled.div`
  ${({ theme }) => `
    flex: 0 0 auto;
    margin-left: ${theme.space[4]}px;
    margin-top: 10px;
  `};
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;
const StyledParagraph = styled(Flex)`
  p {
    margin: 0;
    white-space: nowrap;
  }
`;
export const StyledLinkFieldWrapper = styled(Flex)`
  width: 80%;
`;

export const UpdateForm: React.FC<IUpdateForm> = ({
  disruption,
  onSubmit,
  update,
}) => {
  const { register, control, handleSubmit, reset, setValue, watch } =
    useForm<IUpdateTaskFormData>({
      defaultValues: {
        ...update,
        date: update?.date ?? "",
      },
    });
  const [fillLatest, setFillLatest] = useState(false);
  const [sharepointUrlsCount, setSharepointUrlsCount] = useState(
    update?.sharepointUrl.length || 1
  );
  const date = watch("date");

  const handleAutofillClick = () => {
    setFillLatest(false);
    const calendarDaysFromDisruptionStart = differenceInCalendarDays(
      date !== "" ? new Date(date) : new Date(),
      new Date(disruption.startDate)
    );

    setValue(
      "description",

      `Roszczenie pozostaje aktualne i przybiera charakter roszczenia ciągłego.\r\nNa chwilę obecną Wykonawca oczekuje na całkowitą dokumentację kosztów. Wykonawca informuje, że tworzony rejestr kosztów, będzie na bieżąco aktualizowany i dokumentowany.\r\nWykonawca analizuje wpływ okoliczności stanowiących podstawę roszczenia na terminy realizacji Kontraktu.\r\nWykonawca zwraca uwagę, że istnieje ryzyko wpływu roszczenia na wszystkie asortymenty prac istotne z punktu widzenia terminów realizacji Przedmiotu Zamówienia.\r\nSzczegóły w tym zakresie zostały podane w powiadomieniu o roszczeniu, nowe okoliczności będą zgłaszane przez Wykonawcę w ramach roszczeń przejściowych oraz w odrębnej korespondencji i zostaną podsumowane w roszczeniu ostatecznym.\r\nBiorąc pod uwagę fakt uniemożliwienia Wykonawcy z dniem ${format(
        new Date(disruption.startDate),
        "dd-MM-yyyy"
      )} prowadzenia w pełnym zakresie Robót z przyczyn niezależnych od Wykonawcy, kierując się wyłącznie prostym sposobem obliczania dni przedmiotowego zakłócenia (bez uwzględnienia potencjalnego wpływu na inne zadania/zakresy określone w HRF), obecne opóźnienie dla Robót objętych Roszczeniem nr ${
        disruption.number
      } wynosi, wg stanu na dzień ${format(
        date !== "" ? new Date(date) : new Date(),
        "dd-MM-yyyy"
      )} r., ${calendarDaysFromDisruptionStart} dni kalendarzowych, którego wymiar zwiększać się będzie z każdym kolejnym dniem trwającego zakłócenia, aż do dnia ustania skutków. Na obecnym etapie Wykonawca nie ma możliwości wskazania Inżynierowi Projektu dodatkowych informacji dot. roszczenia, ponad te dotychczas wskazane.`

      // `Roszczenie pozostaje aktualne i przybiera charakter roszczenia ciągłego.\r\nNa chwilę obecną
      // Wykonawca oczekuje na całkowitą dokumentację kosztów. Wykonawca informuje, że tworzony rejestr kosztów, będzie na bieżąco aktualizowany i dokumentowany.
      // \r\nNa obecnym etapie Wykonawca nie ma możliwości wskazania Inżynierowi Projektu dodatkowych informacji dot. roszczenia, ponad te dotychczas wskazane.\r\n
      // Biorąc pod uwagę fakt uniemożliwienia Wykonawcy z dniem ${format(
      //   new Date(disruption.startDate),
      //   "dd-MM-yyyy"
      // )} prowadzenia w pełnym zakresie Robót z przyczyn niezależnych od Wykonawcy, kierując się wyłącznie prostym sposobem obliczania dni przedmiotowego zakłócenia
      // (bez uwzględnienia potencjalnego wpływu na inne zadania/zakresy określone w HRF), obecne opóźnienie dla Robót objętych Roszczeniem nr ${
      //   disruption.number
      // } wynosi, wg stanu na dzień ${format(
      //   date !== "" ? new Date(date) : new Date(),
      //   "dd-MM-yyyy"
      // )} r., ${calendarDaysFromDisruptionStart} dni kalendarzowych, którego wymiar zwiększać się będzie z każdym kolejnym dniem trwającego zakłócenia, aż do dnia ustania skutków.`
    );
  };

  const handleAutoFillLastUpdateClick = () => {
    setFillLatest(true);
    setValue("description", disruption.updates[0].description);
  };

  const onFormSubmit = handleSubmit((data) => {
    if (update) {
      updateDisruptionUpdate({ ...update, ...data })
        .then((response) => {
          toast.success("Aktualizacja została dodana.");
          if (onSubmit) onSubmit(response);
        })
        .catch(() => {
          toast.error("Nie udało się zaktualizować aktualizacji.");
        });
    } else {
      addDisruptionUpdate({
        disruptionId: disruption.id,
        update: data,
      })
        .then((response) => {
          toast.success("Aktualizacja zostało zaktualizowana.");
          setSharepointUrlsCount(1);
          reset({ date: "", sharepointUrl: [""], description: "" });
          if (onSubmit) onSubmit(response);
        })
        .catch(() => {
          toast.error("Nie udało się zaktualizować aktualizacji.");
        });
    }
  });

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(`Skopiowano: ${text}`);
  };

  return (
    <form onSubmit={onFormSubmit}>
      <Grid>
        <Box mb={3}>
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : undefined}
                onChange={(date) => {
                  field.onChange(
                    date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                  );
                }}
                customInput={
                  <Input label="Data aktualizacji" value={field.value} />
                }
                dateFormat="yyyy-MM"
                showMonthYearPicker
                portalId="root-datepicker-portal"
              />
            )}
          />
        </Box>
      </Grid>
      {Array.from(Array(sharepointUrlsCount)).map((item, index) => (
        <Flex
          mb={3}
          key={index}
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <Flex alignItems="center">
              <StyledLinkFieldWrapper>
                <Input
                  {...register(`sharepointUrl.${index}` as const)}
                  label={`Link do sharepont ${index + 1}`}
                />
              </StyledLinkFieldWrapper>
              <Box pt={3}>
                <MsFilePicker
                  selectedFilePath={watch(`sharepointUrl.${index}`) || null}
                  customOnSelectFile={(path: string) => {
                    setValue(`sharepointUrl.${index}`, path);
                  }}
                />
              </Box>
            </Flex>
            {index + 1 === sharepointUrlsCount ? (
              <AddLinkButtonWrapper>
                <Button
                  bordered
                  onClick={() =>
                    setSharepointUrlsCount((prevState) => prevState + 1)
                  }
                >
                  Dodaj kolejny
                </Button>
              </AddLinkButtonWrapper>
            ) : null}
          </Flex>
          {fillLatest && (
            <Flex flexDirection="column">
              <StyledParagraph
                alignItems="center"
                justifyContent="space-between"
              >
                <p>
                  Number zakłócenia: <strong>{disruption.number}</strong>
                </p>
                <StyledFontAwesomeIcon
                  icon={faCopy}
                  onClick={() => handleCopyClick(`${disruption.number}`)}
                />
              </StyledParagraph>
              <StyledParagraph
                alignItems="center"
                justifyContent="space-between"
              >
                <p>
                  Data rozpoczęcia:{" "}
                  <strong>
                    {format(new Date(disruption.startDate), "dd-MM-yyyy")}
                  </strong>
                </p>
                <StyledFontAwesomeIcon
                  icon={faCopy}
                  onClick={() =>
                    handleCopyClick(
                      `${format(new Date(disruption.startDate), "dd-MM-yyyy")}`
                    )
                  }
                />
              </StyledParagraph>
              <StyledParagraph
                alignItems="center"
                justifyContent="space-between"
              >
                <p>
                  Opóźnienie na dzień:{" "}
                  <strong>
                    {format(
                      date !== "" ? new Date(date) : new Date(),
                      "dd-MM-yyyy"
                    )}
                  </strong>
                </p>
                <StyledFontAwesomeIcon
                  icon={faCopy}
                  onClick={() =>
                    handleCopyClick(
                      `${format(
                        date !== "" ? new Date(date) : new Date(),
                        "dd-MM-yyyy"
                      )}`
                    )
                  }
                />
              </StyledParagraph>
              <StyledParagraph
                alignItems="center"
                justifyContent="space-between"
              >
                <p>
                  Ilość dni kalendarzowych:{" "}
                  <strong>
                    {" "}
                    {differenceInCalendarDays(
                      date !== "" ? new Date(date) : new Date(),
                      new Date(disruption.startDate)
                    )}
                  </strong>
                </p>
                <StyledFontAwesomeIcon
                  icon={faCopy}
                  onClick={() =>
                    handleCopyClick(
                      `${differenceInCalendarDays(
                        date !== "" ? new Date(date) : new Date(),
                        new Date(disruption.startDate)
                      )}`
                    )
                  }
                />
              </StyledParagraph>
            </Flex>
          )}
        </Flex>
      ))}
      <Textarea {...register("description")} label="Aktualizacja status" />
      <Flex mt={3} justifyContent="flex-end">
        <ButtonsGroup>
          {!update ? (
            <>
              <Button
                bordered
                onClick={handleAutoFillLastUpdateClick}
                type="button"
              >
                Wypełnij poprzednim
              </Button>
              <Button bordered onClick={handleAutofillClick} type="button">
                Wypełnij
              </Button>
            </>
          ) : null}
          <Button bordered>{update ? "Zapisz" : "Dodaj"}</Button>
        </ButtonsGroup>
      </Flex>
    </form>
  );
};
