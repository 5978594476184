import { differenceInBusinessDays, differenceInCalendarDays } from "date-fns";

interface IGetDisruptionDaysLength {
  startDate: string;
  endDate: string | null;
  business?: boolean;
}

export const getDisruptionDaysLength = ({
  startDate,
  endDate,
  business = false,
}: IGetDisruptionDaysLength) => {
  if (business) {
    return differenceInBusinessDays(
      endDate ? new Date(endDate) : new Date(),
      new Date(startDate)
    );
  } else {
    return differenceInCalendarDays(
      endDate ? new Date(endDate) : new Date(),
      new Date(startDate)
    );
  }
};
