import { Flex } from "components/Flex";
import { theme } from "config/theme";
import styled from "styled-components";
import collapseIcon from "../../../icons/collapseIcon.svg";

export const MainOption = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  > * {
    display: flex;
    gap: 24px;
    align-items: center;
    p {
      font-size: 13px;
      font-weight: bold;
      color: ${theme.palette.text.shadowGrey};
    }
  }
`;

export const IconCollapse = styled.img.attrs({
  alt: "show/hide",
  width: 10,
  height: 12,
  src: collapseIcon,
})`
  cursor: pointer;
  max-width: 10px;
`;
