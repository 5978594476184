import DatePicker from "react-datepicker";
import { Ref } from "react";
import { Input } from "components/Input";
import styled from "styled-components";


const StyledDatePicker = styled(DatePicker)`
  width: 95%;
`;

interface IORFilterDatePicker {
  value: Date;
  setValue: (value) => void;
  field: {
    name: string;
    value: number;
    onBlur: (event) => void;
    onChange: (event) => void;
    ref: Ref<void>;
  };
  status: string;
}

export const ORFilterDatePicker = ({
  value,
  setValue,
  field,
  status,
}: IORFilterDatePicker) => {
  const placeholderText = status === "end" ? "do" : "od";
  return (
    <StyledDatePicker
      selected={value ? new Date(value) : undefined}
      onChange={(date: Date) => {
        field.onChange(date instanceof Date ? date : "");

        if (status === "start") {
          setValue((prevState) => [date, prevState[0]]);
        } else {
          setValue((prevState) => [prevState[0], date]);
        }
      }}
      customInput={<Input value={field.value} />}
      dateFormat="dd-MM-yyyy"
      portalId="root-datepicker-portal"
      placeholderText={placeholderText}
    />
  );
};
