import { fetchAPI } from "utilities/fetchAPI";
import qs from "qs";
import { IPagination } from "types/pagination";
import { IFilters } from "contexts/RailwayLinesContext";
import { IRailwayLine } from "types/railway-line";

interface IFetchRailwayLines {
  limit?: number;
  offset?: number;
  filter?: Partial<IFilters>;
  lineNumberFilter?: number;
}

export interface IFetchRailwayLinesResponse {
  data: Array<IRailwayLine>;
  pagination: IPagination;
}

export const fetchRailwayLines = ({
                                    limit,
                                    offset,
                                    filter
                                  }: IFetchRailwayLines) => {
  const query = qs.stringify({
    limit: limit ?? undefined,
    offset,
    filter
  }, { arrayFormat: "brackets" });
  return fetchAPI<IFetchRailwayLinesResponse>(`${process.env.REACT_APP_API_URL}/line-section?${query}`);
};

interface IPostRailwayLine {
  lineNumber: number,
  chainageStart: number,
  chainageEnd: number,
  attachments: string[];
}

export interface IPostRailwayLineResponse {
  id: string,
  lineNumber: number,
  chainageStart: number,
  chainageEnd: number,
  attachments: string[]
}

export const postRailwayLine = (payload: IPostRailwayLine) => {
  return fetchAPI<IPostRailwayLineResponse>(`${process.env.REACT_APP_API_URL}/line-section`, {
    method: "POST",
    body: JSON.stringify(payload)
  });
};

interface IPutRailwayLine {
  railwayLineId: string;
  railwayLine: {
    lineNumber: number,
    chainageStart: number,
    chainageEnd: number,
    attachments: string[];
  }
}

export interface IPutRailwayLineResponse {
  id: string,
  lineNumber: number,
  chainageStart: number,
  chainageEnd: number,
  attachments: string[]
}

export const putRailwayLine = ({ railwayLineId, railwayLine }: IPutRailwayLine) =>
  fetchAPI<IPutRailwayLineResponse>(`${process.env.REACT_APP_API_URL}/line-section/${railwayLineId}`, {
    method: "PUT",
    body: JSON.stringify(railwayLine)
  });

export const deleteRailwayLine = (railwayLineId: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/line-section/${railwayLineId}`, {
    method: "DELETE"
  });
