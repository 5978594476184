import { useMsContext } from "contexts/MsContext";
import { Button } from "./Button";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

export const MsLoginButton = () => {
  const app = useMsContext();

  if (!app?.user?.email)
    return (
      <UnauthenticatedTemplate>
        <Button onClick={app.signIn}>Zaloguj sie do Microsoft</Button>
      </UnauthenticatedTemplate>
    );

  return (
    <AuthenticatedTemplate>
      <Button onClick={app.signOut}>Wyloguj się z Microsoft</Button>
    </AuthenticatedTemplate>
  );
};
