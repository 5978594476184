import { useState } from "react";
import styled from "styled-components";

const TooltipChildren = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TooltipContent = styled.div<{ left: number; width?: number }>`
  ${({ left, width }) => `
    position: absolute;
    left: ${left}%;
    top: 100%;
    transform: translate(-50%);
    background: white;
    padding: 0.5rem;
    border: 1px solid gray;
    z-index: 20;
    pointer-events: none;
    min-width: 100px;
    white-space: normal;
    ${width && `width: ${width}px;`}
  `}
`;

interface IProps {
  content?: string;
  left: number;
  width?: number;
}

export const TooltipWrapper: React.FC<IProps> = ({
  children,
  content,
  left,
  width,
}) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {isVisible && content && (
        <TooltipContent left={left} width={width}>
          {content}
        </TooltipContent>
      )}
      <TooltipChildren>{children}</TooltipChildren>
    </div>
  );
};
