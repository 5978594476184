import { fetchAPI } from "utilities/fetchAPI";
import { IPassword } from "../views/Settings";

export interface IUser {
  id: string;
  firstname?: string;
  lastname?: string;
  avatar?: string;
  position?: string;
  email: string;
  partner?: string;
  tag?: string;
  groups?: string[];
}

export interface IUserGroup {
  id: string;
  humanizeName: string;
}

export const getUser = (userId: string) =>
  fetchAPI<IUser>(`${process.env.REACT_APP_API_URL}/user/${userId}`);

export const createUser = (data: IUser) =>
  fetchAPI<IUser>(`${process.env.REACT_APP_API_URL}/user`, {
    method: "POST",
    body: JSON.stringify({ ...data }),
  });

export const updateCurrentUser = (userId: string, data: IUser) =>
  fetchAPI<IUser>(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
    method: "PUT",
    body: JSON.stringify({ ...data }),
  });

export const updateUserPassword = (data: IPassword) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/user/profile/change-password`, {
    method: "PUT",
    body: JSON.stringify({ ...data }),
  });

export interface IChangedProperties {
  changedBy: string;
  name: string;
  newValue: string;
  oldValue: string;
  newValueIdDate: boolean;
  oldValueIdDate: boolean;
}
export interface IActiveResponse {
  contest: string;
  date: string;
  type: string;
  user: IUser;
  changedProperties: IChangedProperties[];
}

export const activityUser = (
  userId: string,
  dateFrom: string,
  dateTo: string
) =>
  fetchAPI<IActiveResponse[]>(
    `${process.env.REACT_APP_API_URL}/user/${userId}/history/${dateFrom}/${dateTo}`
  );

export const getUsersList = () =>
  fetchAPI<IUser[]>(`${process.env.REACT_APP_API_URL}/user`);

export interface UserGroupsResType {
  data: IUserGroup[];
}

export const getUserGroups = () =>
  fetchAPI<UserGroupsResType>(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/`
  );

export interface PartnerGroupsResType {
  data: IUserGroup[];
}

export const getPartnerGroups = (partnerId: string) =>
  fetchAPI<PartnerGroupsResType>(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/partner/${partnerId}/groups-list`
  );

export const deleteUser = (userId: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
    method: "DELETE",
  });

export const addUserToGroups = (groupId: string, data: { user: string }) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}/users/`,
    {
      method: "POST",
      body: JSON.stringify({ ...data }),
    }
  );

export const removeUserFromGroup = (groupId: string, userId: string) =>
  fetchAPI(
    `${process.env.REACT_APP_API_URL}/permissions/user-groups/${groupId}/users/${userId}`,
    {
      method: "DELETE",
    }
  );

export const updateSomeonesPassword = ({
  id,
  password,
}: {
  id: string;
  password: string;
}) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/user/${id}/change-password`, {
    method: "PUT",
    body: JSON.stringify({ password }),
  });
