import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { useDropdown } from "hooks/dropdown";
import React from "react";
import styled from "styled-components";
import { Hr } from "./Hr";
import { rgba } from "utilities/rgba";
import { ReactComponent as PencilIcon } from "icons/pencil.svg";
import { INotification } from "services/notifications";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

interface ITypography {
  size: "9" | "11" | "13";
  bold?: boolean;
  opacity: number;
}
interface INavigationTypography {
  isActive?: boolean;
}
export interface INotificationDropdown {
  items: Array<INotification>;
}
interface IStyledBox {
  read: boolean;
}

const StyledBox = styled(Box)<IStyledBox>`
  ${({ read }) => `
    display: block;
    border-left: 1px solid ${read ? `rgba(30, 46, 102, 0.3)` : "black"};
    cursor: pointer;
  `}
`;
const Root = styled.div`
  position: relative;
`;
const WrapperChildren = styled.div`
  cursor: pointer;
`;
const Typography = styled.p<ITypography>`
  ${({ size, bold, opacity }) => `
    font-size: ${size}px;
    color: ${rgba("#191921", opacity)};
    font-weight: ${bold ? "600" : "300"};
    line-height: 18px;
    margin: 0;
  `}
`;

const WrapperDropdown = styled.div`
  ${({ theme }) => `
    right: 0;
    position: absolute;
    top: 100%;
    margin-top: ${theme.space[2]}px;
    min-width: 320px;
    z-index: 1000;
  `}
`;
const StyledPencilIcon = styled(PencilIcon)`
  ${({ theme }) => `
    position: absolute;
    left: -20px;
    width: 10px;
    opacity: 0.5;
    margin-right: ${theme.space[1]}px;
  `}
`;
const NavigationTypography = styled(Typography)<INavigationTypography>`
  ${({ isActive, theme }) => `
    cursor: pointer;
    ${
      isActive &&
      `
      color: ${theme.palette.primary};
    `
    }
  `}
`;
const WrapperBox = styled(Box)`
  max-height: 500px;
  overflow: auto;
`;
const RelativeBox = styled(Box)`
  position: relative;
`;
const Line = styled(Hr)`
  margin: 0;
`;
const StyledTypography = styled(Typography)`
  cursor: pointer;
`;

export const NotificationDropdown: React.FC<INotificationDropdown> = ({
  items,
  children,
}) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);
  const history = useHistory();

  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  return (
    <Root>
      <WrapperChildren ref={buttonRef} role="button" aria-hidden="true">
        {children}
      </WrapperChildren>
      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Card>
            <Box py={5} pl={5}>
              <Flex alignItems="center">
                <Box mr={3}>
                  <NavigationTypography isActive size="9" bold opacity={1}>
                    GLOBALNE
                  </NavigationTypography>
                </Box>
                {/* <NavigationTypography size="9" bold opacity={1}>
                  LOGI
                </NavigationTypography> */}
              </Flex>
            </Box>
            <Line />
            <WrapperBox p={5}>
              {items.map((item) => (
                <StyledBox
                  key={item.id}
                  pl={4}
                  mb={6}
                  read={item.read}
                  onClick={() => console.log("kliknięte.")}
                >
                  <Flex justifyContent="space-between" mb={2}>
                    <Typography size="11" opacity={0.8}>
                      {item.contextMeta.userId
                        ? "Rafał Łochina"
                        : "Użytkownik nieznany"}
                    </Typography>
                    <Typography size="11" opacity={0.8}>
                      {format(new Date(item.createdAt), "dd-MM-yyyy HH:mm")}
                    </Typography>
                  </Flex>
                  <Box mb={3}>
                    <Typography size="13" opacity={0.8}>
                      {item.level} {item.title}
                    </Typography>
                  </Box>
                  <RelativeBox>
                    <Box mb={1}>
                      <Flex alignItems="center">
                        {/* <StyledPencilIcon /> */}
                        <Typography
                          size="13"
                          opacity={0.8}
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </Flex>
                    </Box>
                  </RelativeBox>
                </StyledBox>
              ))}
            </WrapperBox>
            <Line />
            <Flex alignItems="center" justifyContent="center">
              <Box my={4}>
                <StyledTypography
                  size="11"
                  opacity={0.9}
                  onClick={() => history.push("/notifications")}
                >
                  ZOBACZ WIĘCEJ
                </StyledTypography>
              </Box>
            </Flex>
          </Card>
        </WrapperDropdown>
      )}
    </Root>
  );
};
