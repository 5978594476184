/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from "react";
import { Card } from "components/Card";
import EChartsReact from "echarts-for-react";
import styled from "styled-components";
import { useFetchDictionaryPartners } from "hooks/fetchDictionaryPartners";
import { Select } from "components/Select";
import { useForm } from "react-hook-form";
import { Flex } from "components/Flex";
import { getStatsByUser } from "services/statistics";
import { Box } from "components/Box";

interface IForm {
  partner: string;
}

export const DisruptionPerUserChart = () => {
  const { dictionaryPartners } = useFetchDictionaryPartners();
  const { register, watch } = useForm<IForm>({
    defaultValues: { partner: "" },
  });
  const watcher = watch("partner");

  const [statsByUser, setStatsByUser] = useState<Record<string, never>[]>([]);

  useEffect(() => {
    getStatsByUser(watcher).then((res) => {
      setStatsByUser(res as Record<string, never>[]);
    });
  }, [watcher]);

  const yAxisData = statsByUser?.map((user) => user.email);
  const xAxisData = statsByUser?.map((user) => user.cnt);

  const Heading = styled(Flex)`
    width: 100%;
    padding: 40px 32px 0;
  `;
  const Title = styled.p`
    align-self: flex-start;
    flex: 1;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.dark};
  `;

  const ChartWrapper = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  `;

  const NoDataMessage = styled(Box)`
    height: 380px;
    display: flex;
    flex-direction: column;
    items-align: center;
    justify-content: space-around;
  `;

  const option = {
    grid: {
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    yAxis: {
      type: "category",
      data: yAxisData,
      axisLabel: {
        height: 40,
      },
    },
    xAxis: {
      type: "value",
      axisLabel: {
        show: true,
      },
    },
    series: [
      {
        data: xAxisData,
        name: "Liczba zakłóceń",
        type: "bar",
        barGap: 0,
        color: "#005AFF",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          position: "right",
        },
      },
    ],
  };

  return (
    <ChartWrapper>
      <Heading justifyContent="space-between">
        <Title>Przypisani względem liczby zakłóceń</Title>
        <Select label="Partner" {...register("partner")}>
          <option value={""} key=""></option>
          {dictionaryPartners
            ?.filter(({ status }) => status)
            .map((partner) => (
              <option value={partner.id} key={partner.id}>
                {partner.name}
              </option>
            ))}
        </Select>
      </Heading>
      {statsByUser.length ? (
        <EChartsReact
          option={option}
          style={{
            width: "100%",
            height: 380,
          }}
        />
      ) : (
        <NoDataMessage>
          <p>Brak użytkowników</p>
        </NoDataMessage>
      )}
    </ChartWrapper>
  );
};

export default DisruptionPerUserChart;
