import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  createTaskNotification,
  deleteTaskNotification,
  getTaskNotifications,
  ITaskNotification,
  updateTaskNotification,
} from "services/tasks";
import { toast } from "react-toastify";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { CustomTable } from "components/CustomTable";
import { Box } from "components/Box";
import { Avatar } from "components/Avatar";
import { format } from "date-fns";
import { Input } from "components/Input";
import { IUser } from "views/Settings";
import { Select } from "components/Select";
import { useForm } from "react-hook-form";
import { useFetchDictionaryPossibleImpacts } from "hooks/fetchDictionaryPossibleImpacts";
import styled from "styled-components";
import { ConfirmModal } from "components/ConfirmModal";

const StyledContent = styled.div`
  width: 340px;
  padding: 5px;
  margin-top: 10px;
`;

const ContentContainer = styled.div`
  max-width: 1180px;
`;

interface IColumnList {
  width: number;
  name: string;
  value:
    | JSX.Element
    | ((data?: { [key: string]: string }) => JSX.Element | string);
}

export const Notifications: React.FC<{ taskId: string }> = ({ taskId }) => {
  const [taskNotifications, setTaskNotifications] = useState<
    ITaskNotification[] | null
  >(null);
  const [colVis, setColVis] = useState(Array(14).fill(true));
  const [editItemIndex, setEditItemIndex] = useState<string | null>(null);
  const [itemId, setItemId] = useState<string | null>(null);
  const [user, setUser] = useState<IUser>();
  const [rowFormList, setRowFormList] = useState<
    Pick<IColumnList, "value">[] | null
  >();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const { register, reset, handleSubmit } = useForm();

  const { dictionaryPossibleImpacts } =
    useFetchDictionaryPossibleImpacts(taskId);

  const onAddClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    reset();
    setRowFormList(customRowList);
  };

  const onCancelClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setRowFormList(null);
    setItemId(null);
    setEditItemIndex(null);
    reset();
  };

  const onDeleteNotification = useCallback(() => {
    deleteTaskNotification({ taskId, notificationId: itemId })
      .then(() => {
        setIsOpenConfirmModal(false);
        setItemId(null);
        getTaskNotifications({ taskId }).then((res) =>
          setTaskNotifications(res.data || [])
        );
      })
      .finally(() => {
        toast.success("Zgłoszenie zostało usuniętę.");
      });
  }, [itemId, taskId]);

  const onSubmit = handleSubmit((data: Partial<ITaskNotification>) => {
    if (itemId) {
      updateTaskNotification({
        taskId,
        notificationId: itemId,
        taskDetails: data,
      })
        .then(() => {
          setItemId(null);
          setEditItemIndex(null);
          getTaskNotifications({ taskId }).then((res) =>
            setTaskNotifications(res.data || [])
          );
          toast.info("Zgłoszenie zostało zapisane.");
          reset();
        })
        .catch(() => toast.error("Nie udało się zapisać zgłoszenia."));
    } else {
      createTaskNotification({ taskId, taskDetails: data })
        .then(() => {
          setItemId(null);
          setEditItemIndex(null);
          getTaskNotifications({ taskId }).then((res) =>
            setTaskNotifications(res.data || [])
          );
          toast.info("Zgłoszenie zostało zapisane.");
          reset();
        })
        .catch(() => toast.error("Nie udało się zapisać zgłoszenia."));
    }
    setRowFormList(null);
    reset();
  });

  useEffect(() => {
    getTaskNotifications({ taskId })
      .then((res) => setTaskNotifications(res.data || []))
      .catch(() => toast.error("Nie udało się pobrać zgłoszeń."));
  }, [taskId]);

  useEffect(() => {
    const currentUser = localStorage.getItem("currentUser");

    if (currentUser) {
      const user = JSON.parse(currentUser);
      setUser(user);
    }
  }, []);

  const customRowList = useMemo<Pick<IColumnList, "value">[]>(
    () => [
      {
        value: ({ content }) => (
          <Box p={3}>
            <Input required {...register("content")} defaultValue={content} />
          </Box>
        ),
      },
      {
        value: ({ date }) => (
          <Input
            {...register("date")}
            defaultValue={date && format(new Date(date), "yyyy-MM-dd")}
            type="date"
          />
        ),
      },
      {
        value: () => <Avatar url={user?.avatar} />,
      },
      {
        value: ({ impactName }) => (
          <Select
            defaultValue={
              impactName &&
              dictionaryPossibleImpacts.find((item) => item.name === impactName)
                .id
            }
            {...register("impact")}
          >
            {dictionaryPossibleImpacts?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        ),
      },
      {
        value: ({ status }) => (
          <Select defaultValue={status} {...register("status")}>
            <option value="open">Otwarte</option>
            <option value="closed">Zamknięte</option>
          </Select>
        ),
      },
    ],
    [dictionaryPossibleImpacts, register, user?.avatar]
  );

  const columnList = useMemo<IColumnList[]>(
    () => [
      {
        width: 340,
        name: "Treść zgłoszenia",
        value: ({ content }) => <StyledContent>{content}</StyledContent>,
      },
      {
        width: 210,
        name: "Data",
        value: ({ date }) => (
          <Box mt={2}>{date ? format(new Date(date), "dd-MM-yyyy") : "--"}</Box>
        ),
      },
      {
        width: 80,
        name: "Osoba",
        value: ({ personAvatar }) =>
          personAvatar ? <Avatar url={personAvatar} /> : <Box mt={2}>--</Box>,
      },
      {
        width: 160,
        name: "Wpływ",
        value: ({ impactName }) => <Box mt={2}>{impactName || "--"}</Box>,
      },
      {
        width: 150,
        name: "Status",
        value: ({ status }) => (
          <Box mt={2}>
            {status == "open"
              ? "Otwarte"
              : status == "closed"
              ? "Zamknięte"
              : "--"}
          </Box>
        ),
      },
      {
        width: 90,
        name: "",
        value: ({ id }) =>
          id && (
            <Button
              type="button"
              onClick={() => {
                setItemId(id);
                setIsOpenConfirmModal(true);
              }}
              bordered
            >
              Usuń
            </Button>
          ),
      },
      {
        width: 110,
        name: "",
        value: ({ id, rowIndex }) =>
          id && (
            <Button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                setEditItemIndex(rowIndex);
                setRowFormList(customRowList);
                setItemId(id);
              }}
              bordered
            >
              Edytuj
            </Button>
          ),
      },
    ],
    [customRowList]
  );

  return (
    <>
      {taskNotifications ? (
        <form onSubmit={onSubmit}>
          <ContentContainer>
            <Flex mb={5} mr={5} justifyContent="flex-end">
              {rowFormList ? (
                <>
                  <Box mr={2}>
                    <Button type="button" bordered onClick={onCancelClick}>
                      Anuluj
                    </Button>
                  </Box>
                  <Button type="submit" bordered>
                    Zapisz
                  </Button>
                </>
              ) : (
                <Button type="button" onClick={onAddClick} bordered>
                  Dodaj
                </Button>
              )}
            </Flex>

            <CustomTable
              showLongText
              columnList={columnList}
              editItemIndex={editItemIndex}
              colVis={colVis}
              customRowList={rowFormList}
              data={
                taskNotifications.length
                  ? taskNotifications
                  : [
                      {
                        id: "",
                        content: "Brak zgłoszeń",
                        date: "",
                        status: "",
                        personId: 0,
                        personAvatar: "",
                        impactName: "",
                      },
                    ]
              }
            />
          </ContentContainer>
        </form>
      ) : (
        <div>Ładowanie...</div>
      )}
      <ConfirmModal
        handleConfirm={onDeleteNotification}
        onCancelClick={() => {
          setIsOpenConfirmModal(false);
          setItemId(null);
        }}
        isOpen={isOpenConfirmModal}
        header="Czy na pewno chcesz usuąć zgłoszenie?"
        confirmBtnText="Usuń"
      />
    </>
  );
};
