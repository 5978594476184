import { DISRUPTION_PARTNERS_URL } from "constants/path";
import { useFetchAPI } from "./fetchAPI";

interface IDiscruptionPartner {
  id: string;
  name: string;
  status: boolean;
}

export const useFetchDictionaryPartners = () => {
  const { data } = useFetchAPI<IDiscruptionPartner[]>(DISRUPTION_PARTNERS_URL);
  return { dictionaryPartners: data };
};
