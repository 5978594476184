import { FieldSetTitle } from "components/FieldSetTitle";
import { Label } from "components/Select";
import MultiSelect, { OptionsType } from "react-select";
import { useState, useEffect } from "react";
import { useFetchDictionaryIzs } from "hooks/fetchDictionaryIzs";
import { IDisruption } from "types/disruption";

interface IDisruptionIz {
  disruption?: IDisruption;
  value: string[];
  onChange: (izs: OptionsType<IOption>) => void;
}

interface IOption {
  label: string;
  value: string;
}

export const DisruptionIz: React.FC<IDisruptionIz> = ({
  disruption,
  value,
  onChange,
}) => {
  const [optionsIzs, setOptionsIzs] = useState<Array<IOption>>([]);
  const { dictionaryIzs } = useFetchDictionaryIzs();

  useEffect(() => {
    setOptionsIzs(
      dictionaryIzs
        ?.filter(({ status }) => status)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })) ?? []
    );
  }, [dictionaryIzs]);

  return optionsIzs ? (
    <>
      <FieldSetTitle>Wybierz IZ</FieldSetTitle>
      <Label>IZ</Label>
      <MultiSelect<IOption, true>
        defaultValue={
          disruption && disruption.izs
            ? disruption.izs.map((iz) => ({
                label: iz.name,
                value: iz.id,
              }))
            : null
        }
        value={optionsIzs?.filter((c) => value && value.includes(c.value))}
        onChange={(val) => {
          val.find((option) => option.value === "all")
            ? onChange(optionsIzs)
            : onChange(val);
        }}
        options={[{ value: "all", label: "Wszystkie" }, ...optionsIzs]}
        isMulti
        closeMenuOnSelect={false}
      />
    </>
  ) : null;
};
