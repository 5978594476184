import { Icon } from "@fluentui/react";
import styled from "styled-components";

const StyledImg = styled.img`
  max-width: 20px;
`;

export const MsFileIcon = ({ name }: { name: string }) => {
  const ext = /\.([a-z]+)/.exec(name);
  if (ext == null || ext.length == 0) return <Icon iconName="FabricFolder" />;
  return (
    <StyledImg
      src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${ext[1]}.svg`}
      alt={`file icon`}
    />
  );
};
