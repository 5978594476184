import React, { useEffect, useMemo, useState } from "react";
import { TaskDisruptionType } from "types/task";
import { fetchTaskDisruptions } from "services/tasks";
import { toast } from "react-toastify";
import { CustomTable } from "components/CustomTable";
import styled from "styled-components";
import { formatDate } from "utilities/formatDate";

const StyledContent = styled.div`
  width: 80px;
  padding-top: 15px;
`;

const TableContainer = styled.div`
  max-width: 1380px;
`;

export const ObjectDisruptions: React.FC<{ taskId: string }> = ({ taskId }) => {
  const [colVis, setColVis] = useState(Array(6).fill(true));
  const [objectDisruptions, setObjectDisruptions] = useState<
    TaskDisruptionType[]
  >([]);

  useEffect(() => {
    fetchTaskDisruptions({ taskId })
      .then(({ data }) => setObjectDisruptions(data))
      .catch(() => toast.error("Nie udało się pobrać zakłóceń."));
  }, [taskId]);

  const columns = useMemo(
    () => [
      {
        name: "Nr zakłócenia",
        width: 110,
        value: ({ number }) => <StyledContent>{number}</StyledContent>,
      },
      {
        name: "Zgłaszający",
        width: 120,
        value: ({ submittingName }) => submittingName,
      },
      {
        name: "Kategoryzacja",
        width: 220,
        value: ({ categoryName }) => categoryName,
      },
      {
        name: "Status",
        width: 120,
        value: ({ status }) => status,
      },
      {
        name: "Rozpoczęcie",
        width: 150,
        value: (item) =>
          item.startDate ? formatDate(new Date(item.startDate)) : "-",
      },
      {
        name: "Zakończenie",
        width: 150,
        value: (item) =>
          item.endDate ? formatDate(new Date(item.endDate)) : "-",
      },
    ],
    []
  );

  return (
    <TableContainer>
      {objectDisruptions.length ? (
        <CustomTable
          showLongText
          columnList={columns}
          data={objectDisruptions}
          colVis={colVis}
        />
      ) : (
        "Brak zakłóceń"
      )}
    </TableContainer>
  );
};
