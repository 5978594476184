import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { GlobalStyles } from "GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "config/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContextProvider } from "contexts/AuthContext";
import { DisruptionsContextProvider } from "contexts/DisruptionsContext";
import { LayoutContextProvider } from "contexts/LayoutContext";
import { TasksDateContextProvider } from "contexts/TasksDateContext";
import { TasksContextProvider } from "./contexts/TasksContext";
import { TableSidebarContextProvider } from "contexts/TableSidebarContext";
import { RailwayLinesContextProvider } from "./contexts/RailwayLinesContext";
import { MsalProvider } from "@azure/msal-react";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";

import { msSharepointConfig } from "config/msSharepoint";
import ProvideMsContext from "contexts/MsContext";
import { KeysContextProvider } from "contexts/KeysContext";

const queryClient = new QueryClient();

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: msSharepointConfig.appId,
    redirectUri: msSharepointConfig.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authResult = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(authResult.account);
  }
});

ReactDOM.render(
  <>
    <MsalProvider instance={msalInstance}>
      <ProvideMsContext>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <TasksContextProvider>
                <TasksDateContextProvider>
                  <DisruptionsContextProvider>
                    <RailwayLinesContextProvider>
                      <KeysContextProvider>
                        <LayoutContextProvider>
                          <TableSidebarContextProvider>
                            <Router>
                              <App />
                            </Router>
                          </TableSidebarContextProvider>
                        </LayoutContextProvider>
                      </KeysContextProvider>
                    </RailwayLinesContextProvider>
                  </DisruptionsContextProvider>
                </TasksDateContextProvider>
              </TasksContextProvider>
            </AuthContextProvider>
          </QueryClientProvider>
          <GlobalStyles />
        </ThemeProvider>
      </ProvideMsContext>
    </MsalProvider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
