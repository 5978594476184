import React from "react";
import { Switch } from "react-router";
import { IRoute } from "types/route";
import { RouteWithSubRoutes } from "./RouteWithSubRoutes";
import { TableSidebar } from "components/TableSidebar";
import { isSidebarOpen, toggleSidebar } from "utilities/toggleSidebar";
import { useAuthStateContext } from "contexts/AuthContext";
import {
  useTableSidebarContext,
  useTableSidebarDispatchContext,
} from "contexts/TableSidebarContext";

interface IProps {
  routes: IRoute[];
}

export const Router: React.FC<IProps> = ({ routes }) => {
  const [displayDetails, setDisplayDetails] = React.useState(true);
  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();
  const sidebarIsOpen = isSidebarOpen();
  const { token } = useAuthStateContext();

  const toggleSidebarState = () => {
    toggleSidebar();
    setTableData({
      ...tableData,
      isOpen: sidebarIsOpen,
    });
  };

  React.useEffect(() => {
    setDisplayDetails(!!tableData?.detailsObject);
  }, [tableData?.detailsObject]);

  return (
    <>
      <Switch>
        {routes.map((route: IRoute) => (
          <RouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
      {token && !tableData?.hiddenSidebar && (
        <TableSidebar
          {...tableData}
          toggleSidebar={toggleSidebarState}
          isOpen={sidebarIsOpen}
          detailsObject={displayDetails}
        />
      )}
    </>
  );
};
