/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import { LineGraph } from "containers/LineGraph";
import { OTKCharts } from "containers/OTKCharts";
import { TableCharts } from "containers/TableCharts";
import { TaskType } from "types/task";
import { CertificationChart } from "containers/CertyficationChart";
import styled from "styled-components";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Card } from "components/Card";
import { ORCharts } from "containers/ORCharts/ORCharts";

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 1000px;
  overflow-x: hidden;
`;

export const DashboardView: React.FC = () => {
  const { setTableData } = useTableSidebarDispatchContext();

  React.useEffect(() => {
    setTimeout(() => {
      setTableData({
        hiddenSidebar: true,
      });
    });
  }, []);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <Tabs>
        <Card>
          <TabList>
            <Tab>OR/ORR/DOR</Tab>
            <Tab>FDS</Tab>
            <Tab>OTK</Tab>
            <Tab>Nr Certyfikacji WE</Tab>
            <Tab>Certyfikacja UTK</Tab>
          </TabList>
        </Card>

        <StyledBox px={4} py={3}>
          <Box>
            <TabPanel>
              <ORCharts />
            </TabPanel>

            <TabPanel>
              <TableCharts taskType={TaskType.FDS} />
            </TabPanel>

            <TabPanel>
              <OTKCharts />
            </TabPanel>

            <TabPanel>
              <CertificationChart defaultTaskType="CertyfikacjaWE" />
              <Box mt={4}>
                <LineGraph />
              </Box>
            </TabPanel>

            <TabPanel>
              <CertificationChart defaultTaskType="Certyfikacja" />
              <Box mt={4}>
                <LineGraph />
              </Box>
            </TabPanel>
          </Box>
        </StyledBox>
      </Tabs>
    </PageLayout>
  );
};
