import { Card } from "components/Card";
import { Flex } from "components/Flex";
import styled from "styled-components";

interface IStyledFlex {
  isOpen: boolean;
}

export const StyledCard = styled(Card)`
  ${({ theme }) => `
    margin: ${theme.space[3]}px ${theme.space[5]}px;
    padding: ${theme.space[1]}px ${theme.space[5]}px;
    cursor: pointer;
  `};
`;

export const StyledTitle = styled.h3`
  ${({ theme }) => `
    color: ${theme.palette.primary};
    font-weight: 700;
    font-size: 16px;
  `};
`;

export const StyledFlex = styled(Flex)<IStyledFlex>`
  ${({ theme, isOpen }) => `
    margin: -15px ${theme.space[4]}px ${theme.space[2]}px;
    padding: ${theme.space[1]}px ${theme.space[5]}px;
    display: ${isOpen ? "block" : "none"};
  `};
`;
