import React, {
  useContext,
  createContext,
  useState,
  MouseEventHandler,
  useEffect,
} from "react";

import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { getUser } from "services/msGraphService";
import { msSharepointConfig } from "config/msSharepoint";
import { toast } from "react-toastify";

export interface AppUser {
  displayName?: string;
  email?: string;
  avatar?: string;
  timeZone?: string;
  timeFormat?: string;
}

export interface AppError {
  message: string;
  debug?: string;
}

type MsContext = {
  user?: AppUser;
  error?: AppError;
  signIn?: () => void;
  signOut?: MouseEventHandler<HTMLElement>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  displayError?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  clearError?: Function;
  authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
};

const appContext = createContext<MsContext>({
  user: undefined,
  error: undefined,
  signIn: undefined,
  signOut: undefined,
  displayError: undefined,
  clearError: undefined,
  authProvider: undefined,
});

export function useMsContext(): MsContext {
  return useContext(appContext);
}

interface ProvideMsContextProps {
  children: React.ReactNode;
}

export default function ProvideMsContext({ children }: ProvideMsContextProps) {
  const auth = useProvideMsContext();
  return <appContext.Provider value={auth}>{children}</appContext.Provider>;
}

function useProvideMsContext() {
  const [user, setUser] = useState<AppUser | undefined>(undefined);
  const [error, setError] = useState<AppError | undefined>(undefined);

  const msal = useMsal();

  const displayError = (message: string, debug?: string) => {
    setError({ message, debug });
  };

  const clearError = () => {
    setError(undefined);
  };

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      account: msal.instance.getActiveAccount()!,
      scopes: msSharepointConfig.scopes,
      interactionType: InteractionType.Popup,
    }
  );

  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        try {
          const account = msal.instance.getActiveAccount();
          if (account) {
            const user = await getUser(authProvider);

            setUser({
              displayName: user.displayName || "",
              email: user.mail || user.userPrincipalName || "",
              timeFormat: user.mailboxSettings?.timeFormat || "h:mm a",
              timeZone: user.mailboxSettings?.timeZone || "UTC",
            });
          }
        } catch (err) {
          toast.error("Nie udało się aktywować konta Microsoft.");
        }
      }
    };
    checkUser();
  });

  const signIn = async () => {
    await msal.instance.loginPopup({
      scopes: msSharepointConfig.scopes,
      prompt: "select_account",
    });

    const user = await getUser(authProvider);

    setUser({
      displayName: user.displayName || "",
      email: user.mail || user.userPrincipalName || "",
      timeFormat: user.mailboxSettings?.timeFormat || "",
      timeZone: user.mailboxSettings?.timeZone || "UTC",
    });
  };

  const signOut = async () => {
    await msal.instance.logoutPopup();
    setUser(undefined);
  };

  return {
    user,
    error,
    signIn,
    signOut,
    displayError,
    clearError,
    authProvider,
  };
}
