import qs from 'qs';
import { fetchAPI } from "utilities/fetchAPI";
import { IPagination } from "types/pagination";

interface IFetchCommentsResponse {
  pagination: IPagination;
  data: IComment[];
}

export interface IComment {
  id?: string;
  user?: string;
  url?: string;
  taggedUsers?: any[];
  content?: string;
  contentTranslated?: string;
  createdAt?: string;
  updatedAt?: string;
}
export const fetchComments = (url: string, offset: number, limit: number) => {
  const query = qs.stringify({
    offset, limit, url
  })
  return fetchAPI<IFetchCommentsResponse>(
    `${process.env.REACT_APP_API_URL}/comments?${query}`);
}

export interface INewComment {
  url: string;
  taggedUsers: any[];
  content: string;
}

export const newComment = (request: INewComment) => {
  fetchAPI(`${process.env.REACT_APP_API_URL}/comments`, {
    method: "POST",
    body: JSON.stringify({...request})
  });
}