import { fetchAPI } from "../utilities/fetchAPI";
import { DICTIONARY_UNIT_COSTS_DICT_CATEGORIES } from "../constants/path";

export interface IDictionaryCommon {
  id: string;
  name: string;
  status: boolean;
}

export interface IDictionaryLines {
  [key: string]: number;
}

export interface IDictionaryUnitCosts {
  id: string;
  name: string;
  status: boolean;
  description: string;
  categories: string[];
}

export interface IDictionaryUnitCostsCategories {
  "categories": string[];
}

export type IDictionary = IDictionaryCommon | IDictionaryLines | IDictionaryUnitCosts

export const fetchDictionaryUnitCostsCategories = () => fetchAPI<IDictionaryUnitCostsCategories[]>(DICTIONARY_UNIT_COSTS_DICT_CATEGORIES);
export const fetchDictionary = (url) => fetchAPI<IDictionary[]>(url);
export const postDictionary = ({
                                 url,
                                 name,
                                 status,
                                 description,
                                 categories
                               }: { [key: string]: number | string | string[] | boolean }) => {
  const data: { [key: string]: number | string | string[] | boolean } = { name, status };
  if (description) data.description = description;
  if (categories) data.categories = categories;
  return fetchAPI(`${url}`, {
    method: "POST",
    body: JSON.stringify(data)
  });
};
export const putDictionary = ({
                                url,
                                itemId,
                                name,
                                status,
                                description,
                                categories
                              }: { [key: string]: number | string | string[] | boolean }) => {
  const data: { [key: string]: number | string | string[] | boolean } = { name, status };
  if (description) data.description = description;
  if (categories) data.categories = categories;
  return fetchAPI(`${url}/${itemId}`, {
    method: "PUT",
    body: JSON.stringify(data)
  });
};
export const deleteDictionary = (url, itemId) => fetchAPI(`${url}/${itemId}`, {
  method: "DELETE"
});
