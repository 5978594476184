import { Button } from "components/Button";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Flex } from "components/Flex";
import { IUpdate } from "types/update";
import styled from "styled-components";
import { removeDisruptionUpdate } from "services/disruptions";
import { ButtonsGroup } from "components/ButtonsGroup";
import { UpdateForm } from "containers/UpdateForm";
import { useState } from "react";
import { toast } from "react-toastify";
import { IDisruption } from "types/disruption";
import { formatDate } from "utilities/formatDate";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const DataGrid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: ${theme.space[6]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const Label = styled.div`
  ${({ theme }) => `
    margin-right: ${theme.space[4]}px;
    flex: 0 0 auto;
    color: rgba(19, 19, 21, 0.65);
  `}
`;

const Value = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
  `}
`;

export const Update: React.FC<{
  disruption: IDisruption;
  update: IUpdate;
  onRemove: (updateId: string) => any;
}> = ({ disruption, update, onRemove }) => {
  const [updateState, setUpdateState] = useState<IUpdate>(update);
  const [displayEditForm, setDisplayEditForm] = useState(false);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const handleEditClick = () => {
    setDisplayEditForm((prevState) => !prevState);
  };

  const handleDeleteUpdateClick = () => {
    removeDisruptionUpdate({ updateId: update.id })
      .then(() => {
        toast.success("Aktualizacja została usunięta.");
        if (onRemove) onRemove(update.id);
      })
      .catch(() => {
        toast.error("Nie udało się usunąć aktualizacji");
      });
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <FieldSetTitle>
          Aktualizacja {update.number}-{update.date.substring(0, 7)}
        </FieldSetTitle>
        <ButtonsGroup>
          {loggedUserRoles?.includes("ROLE_DISRUPTIONS_LIST_UPDATE_UPDATE") && (
            <Button onClick={handleEditClick}>Edytuj aktualizację</Button>
          )}
          {loggedUserRoles?.includes("ROLE_DISRUPTIONS_LIST_UPDATE_DELETE") && (
            <Button onClick={handleDeleteUpdateClick}>Usuń aktualizację</Button>
          )}
        </ButtonsGroup>
      </Flex>
      {!displayEditForm ? (
        <DataGrid>
          <Flex justifyContent="space-between">
            <Label>Data aktualizacji</Label>
            <Value>{formatDate(new Date(updateState.date))}</Value>
          </Flex>
          {updateState.sharepointUrl.map((url, index) => (
            <Flex key={url} justifyContent="space-between">
              <Label>Link do sharepoint {index + 1}</Label>
              <Value>{url}</Value>
            </Flex>
          ))}
          <Flex justifyContent="space-between">
            <Label>Aktualizacja statusu</Label>
            <Value>{updateState.description}</Value>
          </Flex>
        </DataGrid>
      ) : (
        <UpdateForm
          update={update}
          disruption={disruption}
          onSubmit={(update) => {
            setUpdateState(update);
            setDisplayEditForm(false);
          }}
        />
      )}
    </>
  );
};
