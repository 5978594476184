import styled from "styled-components";

interface IButton {
  bordered?: boolean;
}

export const Button = styled.button<IButton>`
  ${({ theme, bordered, disabled }) => `
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 ${theme.space[4]}px;
    color: rgba(30,46,102,0.65);
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;
    background: transparent;
    border: ${bordered ? `1px solid rgba(30,46,102,0.15)` : 0};
    border-radius: 16px;
    outline: none;
    cursor: pointer;
    ${disabled ? "opacity: 0.5;" : ""}
  `};
`;
