import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";

export const TooltipText = styled.div`
  background: white;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  position: absolute;
`;

export const TooltipIcon = styled.div`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 0;
  z-index: 2;
  font-size: 0.7rem;
  user-select: none;
`;

export const Tooltip = ({ text }: { text: string }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <TooltipIcon
        onMouseEnter={() => {
          setTooltipVisible(true);
        }}
        onMouseLeave={() => {
          setTooltipVisible(false);
        }}
        style={{
          color: isTooltipVisible
            ? "rgba(19, 19, 21, 0.75)"
            : "rgba(19, 19, 21, 0.50)",
        }}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </TooltipIcon>
      {isTooltipVisible && <TooltipText>{text}</TooltipText>}
    </>
  );
};
