export const isSidebarOpen = () => {
  const localData = window.localStorage.getItem("isSidebar");
  return localData && JSON.parse(localData);
};

export const toggleSidebar = () => {
  if (isSidebarOpen()) {
    window.localStorage.setItem("isSidebar", "false");
  } else {
    window.localStorage.setItem("isSidebar", "true");
  }
};
