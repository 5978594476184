import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { theme } from "config/theme";
import styled from "styled-components";
import optionsImage from "../../icons/options.png";

export const StyledBox = styled(Box)`
  max-width: 1125px;
`;

export const Header = styled.div`
  font-weight: bold;
  color: ${theme.palette.text.darkGrey};
`;

export const UsersCardWrapper = styled(Card)`
  padding: 24px 48px;
`;

export const TableRow = styled(Flex).attrs({
  justifyContent: "space-between",
})`
  padding: 16px;
  > * {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > p {
    margin: 0;
    font-weight: 700;
    color: ${theme.palette.text.dark};
  }
`;
export const IconOptions = styled.img.attrs({
  alt: "options",
  width: 4,
  height: 18,
  src: optionsImage,
})`
  cursor: pointer;
  max-width: 4px;
`;
