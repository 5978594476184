import React, { useEffect, useState } from "react";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  DetailsRow,
  DateTwoDetailsBox,
  CustomTitle,
} from "./DateTwoDetails.styled";
import { getConvertedDate } from "../../utilities/formatDate";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { Box } from "components/Box";
import { updateDateTwoSimpleDate } from "services/tasks";
import { Select } from "components/Select";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { rolesByObjects } from "constants/rolesByObjects";
import { useParams } from "react-router-dom";

interface IProps {
  details: IDetails[];
  saveUpdateDate: (
    dateId: string,
    dateValue: {
      name: string;
      value: string;
    }
  ) => void;
  fetchData?: () => void;
  dataType: "CW" | "TI" | "SABP";
}
interface IDetails {
  type: string;
  name: string;
  context: number;
  id: {
    forecast: string;
    actual: string;
    custom: string;
    customText: string;
  }[];
  value: {
    forecast: string;
    actual: string;
    customText?: string;
    custom: {
      id: string;
      value: string;
      label: string;
      type: string;
    }[];
  };
  items?: {
    customText?: string;
    name: string;
    forecast: string;
    actual: string;
    taskId: string;
    itemType: string;
    forecastProperty: string;
    actualProperty: string;
    items: [];
    subCollection: [];
    type: string;
    editInfo: {
      taskId: string;
      itemType: string;
      forecastProperty: string;
      actualProperty: string;
    };
  }[];
  meta: null;
  editInfo?: {
    taskId: string;
    itemType: string;
    forecastProperty: string;
    actualProperty: string;
  };
}

interface IDateValue {
  actual: { id: string; value: string };
  forecast: { id: string; value: string };
  customText: { id: string; value: string };
  custom: {
    id: string;
    value: string;
    label: string;
    type: string;
  }[];
  type?: string;
  editInfo?: {
    taskId: string;
    itemType: string;
    forecastProperty: string;
    actualProperty: string;
  };
  items?: {
    customText?: string;
    forecast: string;
    actual: string;
    taskId: string;
    itemType: string;
    forecastProperty: string;
    actualProperty: string;
  }[];
}

const blockedFoundationFields = [
  "Odbiór zbrojenia",
  "Fundament zakończenie wykonanie zasypki fundamentu",
  "Geodezja powykonawcza fundamentu zaakceptowana",
  "Protokół 2 odbioru fundamentu zaakceptowany",
];

export const DateTwoDetails: React.FC<IProps> = ({
  details,
  saveUpdateDate,
  fetchData,
  dataType,
}) => {
  const [editItemIndex, setEditItemIndex] = useState<number | null>(null);
  const [dateValue, setDateValue] = useState<IDateValue | null>(null);
  const [blockFoundationFields, setBlockFoundationFields] = useState(false);
  const params = useParams<{ taskId: string; type: string }>();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const onCustomChange = (event, item, customItem) => {
    setDateValue((prevDateValue) => {
      if (prevDateValue?.custom) {
        return {
          ...prevDateValue,
          custom: [
            ...prevDateValue?.custom.filter(
              (cItem) => cItem.id !== customItem.id
            ),
            {
              id: customItem.id,
              label: customItem.label,
              type: customItem.type,
              value: event.target.value || "",
            },
          ],
          type: item?.type,
          editInfo: item?.editInfo,
        };
      } else {
        return {
          ...prevDateValue,
          custom: [
            {
              id: customItem.id,
              label: customItem.label,
              type: customItem.type,
              value: event.target.value || "",
            },
          ],
        };
      }
    });
  };

  const handleSaveChages = () => {
    if (!dateValue) {
      setEditItemIndex(null);
    } else if (dateValue.type === "simple_date") {
      updateDateTwoSimpleDate({
        ...dateValue.editInfo,
        forecast: dateValue.forecast?.value,
        actual: dateValue.actual?.value,
      })
        .then(() => {
          dateValue?.items?.length &&
            dateValue?.items?.map((customItem) => {
              updateDateTwoSimpleDate({
                ...customItem,
              });
            });
        })
        .then(() => {
          setTimeout(() => {
            fetchData && fetchData();
          }, 1000);
        });
    } else {
      Object.entries(dateValue).forEach(([key, value]) => {
        if (key === "custom") {
          value?.map((customValue) => {
            saveUpdateDate(customValue.id, {
              name: key,
              value: customValue.value,
            });
          });
        }
        if (dateValue[key]?.id) {
          saveUpdateDate(dateValue[key].id, { name: key, value: value.value });
        }
      });
    }
    setEditItemIndex(null);
  };

  useEffect(() => {
    const foundValue = details?.find(
      ({ name }) => name === "Fundament rozpoczęcie rozpoczęcia zbrojenia"
    )?.value?.custom[0]?.value;

    if (dateValue?.custom?.length)
      if (
        (foundValue === "Pal" && dateValue?.custom[0].value === "Pal") ||
        dateValue?.custom[0].value === "Pal"
      ) {
        setBlockFoundationFields(true);
      } else {
        setBlockFoundationFields(false);
      }
    else if (foundValue === "Pal") {
      setBlockFoundationFields(true);
    }
  }, [dateValue, dateValue?.custom, dateValue?.type, details]);

  return (
    <>
      {/* <DetailsButtonBack bordered onClick={handleBackToDateList}>
        Wróć
      </DetailsButtonBack> */}
      <DateTwoDetailsBox>
        <DetailsRow isHeader>
          <p>Nazwa</p>
          <p>Forecast</p>
          <p>Actual</p>
          <p>Custom</p>
          {loggedUserRoles?.includes(
            params.type === "OR,ORR" || params.type === "Tunel"
              ? rolesByObjects[params.type][`DATE2_${dataType}_UPDATE`]
              : rolesByObjects[params.type]["DATE2_UPDATE"]
          ) && <p>Edit</p>}
        </DetailsRow>
        {details.map((item, idx) => (
          <div key={item.name + item?.id?.toString()}>
            <DetailsRow
              disable={
                blockFoundationFields &&
                blockedFoundationFields.includes(item.name)
              }
            >
              <p>{item.name}</p>
              {idx === editItemIndex ? (
                <>
                  <DatePicker
                    name="forecast"
                    selected={
                      dateValue?.forecast?.value || item?.value?.forecast
                        ? new Date(
                            dateValue?.forecast?.value || item?.value?.forecast
                          )
                        : undefined
                    }
                    onChange={(value) => {
                      setDateValue((prevDateValue) => ({
                        ...prevDateValue,
                        forecast: {
                          id: item?.id ? item?.id[0]?.forecast : "",
                          value:
                            value instanceof Date
                              ? format(value, "yyyy-MM-dd")
                              : "",
                        },
                        actual: {
                          id: item?.id ? item?.id[0]?.actual : "",
                          value:
                            dateValue?.actual?.value || item?.value?.actual,
                        },
                        type: item?.type,
                        editInfo: item?.editInfo,
                      }));
                    }}
                    customInput={
                      <Input
                        value={
                          dateValue?.forecast?.value ||
                          getConvertedDate(item?.value?.forecast)
                        }
                      />
                    }
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                  />
                  <DatePicker
                    name="actual"
                    selected={
                      dateValue?.actual?.value || item?.value?.actual
                        ? new Date(
                            dateValue?.actual?.value || item?.value?.actual
                          )
                        : undefined
                    }
                    onChange={(value) => {
                      setDateValue((prevDateValue) => ({
                        ...prevDateValue,
                        forecast: {
                          id: item?.id ? item?.id[0]?.forecast : "",
                          value:
                            dateValue?.forecast?.value || item?.value?.forecast,
                        },
                        actual: {
                          id: item?.id ? item?.id[0]?.actual : "",
                          value:
                            value instanceof Date
                              ? format(value, "yyyy-MM-dd")
                              : "",
                        },
                        type: item?.type,
                        editInfo: item?.editInfo,
                      }));
                    }}
                    customInput={
                      <Input
                        value={
                          dateValue?.actual?.value ||
                          getConvertedDate(item?.value?.actual)
                        }
                      />
                    }
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                  />
                  <div>
                    {item.type !== "simple_date"
                      ? item?.value?.custom?.map((customItem) =>
                          item.name ===
                          "Fundament rozpoczęcie rozpoczęcia zbrojenia" ? (
                            <Box my={2} key={customItem.id}>
                              <Select
                                name="custom"
                                label={customItem.label}
                                value={
                                  dateValue?.custom?.find(
                                    (cItem) => cItem.id === customItem.id
                                  )?.value || customItem.value
                                }
                                onChange={(e) => {
                                  onCustomChange(e, item, customItem);
                                }}
                              >
                                <option>Stopa</option>
                                <option>Pal</option>
                                <option>Studnia</option>
                              </Select>
                            </Box>
                          ) : (
                            <Box my={2} key={customItem.id}>
                              <Input
                                name="custom"
                                label={customItem.label}
                                value={
                                  dateValue?.custom?.find(
                                    (cItem) => cItem.id === customItem.id
                                  )?.value || customItem.value
                                }
                                onChange={(e) => {
                                  onCustomChange(e, item, customItem);
                                }}
                              />
                            </Box>
                          )
                        )
                      : item?.items?.map((customItem) => (
                          <Box my={2} key={customItem.editInfo.taskId}>
                            <Input
                              name="custom"
                              label="custom"
                              defaultValue={customItem?.customText}
                              onChange={(value) => {
                                const {
                                  editInfo,
                                  items,
                                  name,
                                  subCollection,
                                  type,
                                  ...rest
                                } = customItem;

                                setDateValue((prevDateValue) => ({
                                  ...prevDateValue,
                                  forecast: {
                                    id: item?.id ? item?.id[0]?.forecast : "",
                                    value:
                                      dateValue?.forecast?.value ||
                                      item?.value?.forecast,
                                  },
                                  actual: {
                                    id: item?.id ? item?.id[0]?.actual : "",
                                    value:
                                      dateValue?.actual?.value ||
                                      item?.value?.actual,
                                  },
                                  items: [
                                    ...(prevDateValue?.items?.length
                                      ? prevDateValue?.items.filter(
                                          (filterItem) =>
                                            filterItem.taskId !==
                                            editInfo.taskId
                                        )
                                      : []),
                                    {
                                      ...rest,
                                      ...editInfo,
                                      customText: value.target.value || "",
                                    },
                                  ],
                                  type: item?.type,
                                  editInfo: item?.editInfo,
                                }));
                              }}
                            />
                          </Box>
                        ))}
                  </div>
                  <Button onClick={() => handleSaveChages()}>
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </>
              ) : (
                <>
                  <p>{getConvertedDate(item.value?.forecast) || "-"}</p>
                  <p>{getConvertedDate(item.value?.actual) || "-"}</p>
                  <p>
                    {item.type !== "simple_date"
                      ? item.value?.custom?.map((item) => (
                          <Box key={item.id} mb={2}>
                            <CustomTitle>{item.label}</CustomTitle>

                            {item.value || "-"}
                          </Box>
                        ))
                      : item?.items?.map((customItem) => (
                          <Box key={customItem.name} mb={2}>
                            <CustomTitle>{customItem.name}</CustomTitle>

                            {customItem.customText || "-"}
                          </Box>
                        ))}
                  </p>
                  {!(
                    blockFoundationFields &&
                    blockedFoundationFields.includes(item.name)
                  ) &&
                    loggedUserRoles?.includes(
                      params.type === "OR,ORR" || params.type === "Tunel"
                        ? rolesByObjects[params.type][
                            `DATE2_${dataType}_UPDATE`
                          ]
                        : rolesByObjects[params.type]["DATE2_UPDATE"]
                    ) && (
                      <Button onClick={() => setEditItemIndex(idx)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                    )}
                </>
              )}
            </DetailsRow>
          </div>
        ))}
      </DateTwoDetailsBox>
    </>
  );
};
