import React, { useEffect, useMemo, useState } from "react";
import { ITaskChild, TaskType } from "types/task";
import { compareDates } from "utilities/dateCompare";
import { getDisruptionDaysLength } from "utilities/getDisruptionDaysLength";
import {
  fetchTaskChildren,
  fetchTaskPredecessors,
  IUpdateTask,
  updateHRFTask,
  updatePredecessorTaskMode,
} from "services/tasks";
import { toast } from "react-toastify";
import { CustomTable } from "components/CustomTable";
import { Button } from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencilAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Input } from "components/Input";
import styled from "styled-components";
import { formatDate } from "utilities/formatDate";
import { Select } from "components/Select";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { PredecessorDateHRF } from "./PredecessorDateHRF";
import { Flex } from "components/Flex";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { rolesByObjects } from "constants/rolesByObjects";
import {
  useTableSidebarContext,
  useTableSidebarDispatchContext,
} from "contexts/TableSidebarContext";
import { TableType } from "types/tableType";

const StyledContent = styled.div`
  width: 260px;
  padding: 5px;
`;

const TableContainer = styled.div`
  max-width: 1380px;
`;

interface IColumnList {
  width: number;
  name: string;
  value:
    | JSX.Element
    | ((data: { [key: string]: string }) => JSX.Element | string);
}

const taskPhases = ["Etap 1", "Etap 2", "Etap 3", "Etap 4", "Etap 5"];

export const DateHRF: React.FC<{ taskId: string }> = ({ taskId }) => {
  const [colVis, setColVis] = useState(Array(31).fill(true));
  const [taskChildren, setTaskChildren] = useState<ITaskChild[]>([]);
  const [editItemIndex, setEditItemIndex] = useState<string | null>(null);
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [rowFormList, setRowFormList] = useState<
    Pick<IColumnList, "value">[] | null
  >();
  const [isPredecessorsOpen, setIsPredecessorsOpen] = useState(false);
  const [chosenItem, setChosenItem] = useState<ITaskChild | null>(null);

  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const { type } = useParams<{ type: TaskType }>();

  const { register, reset, handleSubmit } = useForm();

  useEffect(() => {
    fetchTaskChildren({ taskId })
      .then((children) => setTaskChildren(children))
      .catch(() => console.error("Failed to fetch task children"));
  }, []);

  const preparedTasks = useMemo(
    () =>
      taskChildren.map((child, index) => {
        child.business_days = 0;
        child.precent_complete = "0%";
        if (
          compareDates(new Date(child.startDate), new Date(), "<") &&
          compareDates(new Date(child.endDate), new Date())
        )
          child.precent_complete = "50%";
        if (
          child.endDate &&
          compareDates(new Date(child.endDate), new Date(), "<")
        )
          child.precent_complete = "100%";
        if (
          compareDates(new Date(child.startDate), new Date(), "<") &&
          compareDates(new Date(child.endDate), new Date(), ">")
        ) {
          child.precent_complete = "50%";
          child.business_days = getDisruptionDaysLength({
            startDate: child.startDate,
            endDate: child.endDate,
            business: true,
          });
        }
        return child;
      }),
    [taskChildren]
  );

  const customRowList = useMemo(
    () => [
      {
        value: (item) => {
          if (item.name === "Roboty budowlane") {
            return <StyledContent>Prace budowlane</StyledContent>;
          } else {
            return <StyledContent>{item.name}</StyledContent>;
          }
        },
      },
      {
        name: "Wykonawca",
        width: 120,
        value: (item) => (
          <Input defaultValue={item.contractor} {...register("contractor")} />
        ),
      },
      {
        name: "Etap",
        width: 140,
        value: (item) => (
          <Select defaultValue={item.phase} {...register("phase")}>
            {taskPhases.map((taskPhase) => (
              <option key={taskPhase} value={taskPhase}>
                {taskPhase}
              </option>
            ))}
          </Select>
        ),
      },
      {
        name: "Czy jest sumaryczny?",
        width: 150,
        value: (item) => (item.summaryTask ? "tak" : "nie"),
      },
      {
        name: "Czas trwania",
        width: 120,
        value: (item) => item.duration,
      },
      {
        value: (item) => (
          <Input
            type="date"
            {...register("startDate")}
            defaultValue={formatDate(new Date(item.startDate), "yyyy-MM-dd")}
          />
        ),
      },
      {
        value: (item) => (
          <Input
            type="date"
            {...register("endDate")}
            defaultValue={formatDate(new Date(item.endDate), "yyyy-MM-dd")}
          />
        ),
      },
      {
        name: "Wykonano (%)",
        width: 150,
        value: (item) => item.precent_complete,
      },
      ...(["OR,ORR", "Tunel", "FDS", "OC", "OSZ", "OTK"].includes(type)
        ? [
            {
              name: "poziom konspektu",
              width: 150,
              value: (item) => (
                <Input
                  type="number"
                  defaultValue={item.conspectLevel}
                  {...register("conspectLevel", {
                    valueAsNumber: true,
                  })}
                />
              ),
            },
            {
              name: "zasoby",
              width: 150,
              value: (item) => (
                <Input
                  defaultValue={item.resources}
                  {...register("resources")}
                />
              ),
            },
            {
              name: "km od",
              width: 150,
              value: (item) => (
                <Input
                  type="number"
                  defaultValue={item.chainage}
                  {...register("chainage", {
                    valueAsNumber: true,
                  })}
                />
              ),
            },
          ]
        : []),
      ...(["OTK"].includes(type)
        ? [
            {
              name: "km do",
              width: 150,
              value: (item) => (
                <Input
                  type="number"
                  defaultValue={item.chainageEnd}
                  {...register("chainageEnd", {
                    valueAsNumber: true,
                  })}
                />
              ),
            },
            {
              name: "załącznik",
              width: 150,
              value: (item) => (
                <Input
                  defaultValue={item.zalacznik}
                  {...register("zalacznik")}
                />
              ),
            },
            {
              name: "załącznik pomocniczy",
              width: 150,
              value: (item) => (
                <Input
                  defaultValue={item.zalacznik2}
                  {...register("zalacznik2")}
                />
              ),
            },
            {
              name: "długość",
              width: 150,
              value: (item) => Math.round(item.chainageLength * 100) / 100,
            },
            {
              name: "typ pozycji",
              width: 150,
              value: (item) => (
                <Input
                  defaultValue={item.typPozycji}
                  {...register("typPozycji")}
                />
              ),
            },
            {
              name: "Rok",
              width: 150,
              value: (item) => (
                <Input
                  type="number"
                  defaultValue={item.year}
                  {...register("year", {
                    valueAsNumber: true,
                  })}
                />
              ),
            },
            {
              name: "koordynator",
              width: 150,
              value: (item) => (
                <Input
                  defaultValue={item.koordynator}
                  {...register("koordynator")}
                />
              ),
            },
          ]
        : []),
      {
        name: "Tryb taska",
        width: 140,
        value: (item) => (
          <Select
            defaultValue={
              item.predecessorsDatesManual === true ? "manual" : "automatic"
            }
            {...register("taskMode")}
          >
            <option value="automatic">automatyczny</option>
            <option value="manual">ręczny</option>
          </Select>
        ),
      },
      {
        name: "Aktywny",
        width: 140,
        value: (item) => (
          <Select
            defaultValue={item.active === true ? "true" : "false"}
            {...register("active")}
          >
            <option value="true">tak</option>
            <option value="false">nie</option>
          </Select>
        ),
      },
      {
        name: "",
        value: () => (
          <Button type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        ),
      },
      {
        name: "",
        value: () => (
          <Button
            type="button"
            onClick={(event) => {
              event.preventDefault();
              setEditItemIndex(null);
              setRowFormList(null);
              setEditItemId(null);
              reset();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        ),
      },
    ],
    [register]
  );

  const columns = useMemo(
    () => [
      {
        name: "Nazwa zadania",
        width: 260,
        value: (item) => {
          if (item.name === "Roboty budowlane") {
            return <StyledContent>Prace budowlane</StyledContent>;
          } else {
            return <StyledContent>{item.name}</StyledContent>;
          }
        },
      },
      {
        name: "Wykonawca",
        width: 120,
        value: (item) => item.contractor,
      },
      {
        name: "Etap",
        width: 80,
        value: (item) => item.phase,
      },
      {
        name: "Czy jest sumaryczny?",
        width: 150,
        value: (item) => (item.summaryTask ? "tak" : "nie"),
      },
      {
        name: "Czas trwania",
        width: 120,
        value: (item) => item.duration,
      },
      {
        name: "Rozpoczęcie",
        width: 200,
        value: (item) => formatDate(new Date(item.startDate)),
      },
      {
        name: "Zakończenie",
        width: 200,
        value: (item) => formatDate(new Date(item.endDate)),
      },
      {
        name: "Wykonano (%)",
        width: 150,
        value: (item) => item.precent_complete,
      },
      ...(["OR,ORR", "Tunel", "FDS", "OC", "OSZ", "OTK"].includes(type)
        ? [
            {
              name: "poziom konspektu",
              width: 150,
              value: (item) => item.conspectLevel,
            },
            {
              name: "zasoby",
              width: 150,
              value: (item) => item.resources,
            },
            {
              name: "km od",
              width: 100,
              value: (item) => item.chainage,
            },
          ]
        : []),
      ...(["OTK"].includes(type)
        ? [
            {
              name: "km do",
              width: 150,
              value: (item) => item.chainageEnd,
            },
            {
              name: "załącznik",
              width: 150,
              value: (item) => item.zalacznik,
            },
            {
              name: "załącznik pomocniczy",
              width: 150,
              value: (item) => item.zalacznik2,
            },
            {
              name: "długość",
              width: 150,
              value: (item) => Math.round(item.chainageLength * 100) / 100,
            },
            {
              name: "typ pozycji",
              width: 150,
              value: (item) => item.typPozycji,
            },
            {
              name: "Rok",
              width: 150,
              value: (item) => item.year,
            },
            {
              name: "koordynator",
              width: 150,
              value: (item) => item.koordynator,
            },
          ]
        : []),
      {
        name: "Tryb taska",
        width: 170,
        value: (item) =>
          item.predecessorsDatesManual ? "ręczny" : "automatyczny",
      },
      {
        name: "Aktywny",
        width: 170,
        value: (item) => (item.active ? "tak" : "nie"),
      },
      {
        width: 150,
        name: "",
        disableClickRow: true,
        value: (item) => (
          <Button
            type="button"
            bordered
            onClick={(event) => {
              event.preventDefault();
              setIsPredecessorsOpen(true);
              setChosenItem(item);
            }}
          >
            Poprzedniki
          </Button>
        ),
      },
      ...(loggedUserRoles?.includes(rolesByObjects[type]["DATES_HRF_UPDATE"])
        ? [
            {
              width: 80,
              name: "",
              disableClickRow: true,
              value: ({ id, rowIndex }) => (
                <Button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setEditItemIndex(rowIndex);
                    setRowFormList(customRowList);
                    setEditItemId(id);
                  }}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              ),
            },
          ]
        : []),
      {
        width: 80,
        name: "",
        disableClickRow: true,
        value: () => <></>,
      },
    ],
    [customRowList, loggedUserRoles]
  );

  const handleClickTableRow = ({ id }) => {
    fetchTaskPredecessors({ taskId: id }).then((data) => {
      setTableData({
        ...tableData,
        tableType: TableType.PREDECESSORS_LIST,
        data: data,
        isOpen: true,
        detailsObject: true,
      });
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    const { taskMode, active, ...restData } = data;
    const oldData = _.pick(
      taskChildren.find((el) => el.id === editItemId),
      [
        "contractor",
        "phase",
        "stage",
        "startDate",
        "endDate",
        "conspectLevel",
        "resources",
        "chainage",
        "chainageStart",
        "chainageEnd",
        "zalacznik",
        "zalacznik2",
        "typPozycji",
        "year",
        "koordynator",
      ]
    );

    await updatePredecessorTaskMode(editItemId, {
      predecessorsDatesManual: taskMode === "manual",
    });

    const response = await updateHRFTask(editItemId, {
      ...oldData,
      ...restData,
      active: active === "true",
    } as unknown as IUpdateTask);
    if (response && response.success) {
      setTaskChildren((prevState) => {
        return prevState.map((el) =>
          el.id === editItemId
            ? {
                ...el,
                ...data,
                predecessorsDatesManual: taskMode === "manual",
                active: active === "true",
              }
            : el
        );
      });
      setRowFormList(null);
      setEditItemId(null);
      setEditItemIndex(null);
      reset();
      toast.success("Dane zostały zmienione.");
      return;
    }
    toast.error("Dane nie zostały zmienione.");
  });

  return (
    <>
      {isPredecessorsOpen ? (
        <>
          <Flex justifyContent="end">
            <Button
              bordered
              onClick={() => {
                setIsPredecessorsOpen(false);
                setEditItemId(null);
              }}
            >
              Anuluj
            </Button>
          </Flex>
          <PredecessorDateHRF task={chosenItem} />
        </>
      ) : (
        <form onSubmit={onSubmit}>
          <TableContainer>
            <CustomTable
              showLongText
              columnList={columns}
              data={preparedTasks}
              editItemIndex={editItemIndex}
              customRowList={rowFormList}
              colVis={colVis}
              clickTableRow={handleClickTableRow}
            />
          </TableContainer>
        </form>
      )}
    </>
  );
};
