export const msSharepointConfig = {
  appId: "5625f4b9-db31-4dcb-8ee0-b2b1a4d30b9e",
  redirectUri: "https://nokia.stage.softmakerdev.com/",
  scopes: ["user.read", "Files.Read", "Files.Read.All", "Files.Read.Selected"],
  system: {
    windowHashTimeout: 9000,
    iframeHashTimeout: 9000,
    loadFrameTimeout: 9000,
  },
};
