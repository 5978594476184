import { isMatch } from "date-fns";
import { useMemo, useState } from "react";

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    const sortableItems = items?.length ? [...items] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (
          ((isMatch(a[sortConfig.key], "dd-MM-yyyy") &&
            isMatch(b[sortConfig.key], "dd-MM-yyyy")) ||
            (isMatch(a[sortConfig.key], "yyyy-MM-dd") &&
              isMatch(b[sortConfig.key], "yyyy-MM-dd"))) &&
          new Date(a[sortConfig.key]).toString() !== "Invalid Date" &&
          new Date(b[sortConfig.key]).toString() !== "Invalid Date"
        ) {
          if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }

        if (
          (parseFloat(a[sortConfig.key]) || a[sortConfig.key]) <
          (parseFloat(b[sortConfig.key]) || b[sortConfig.key])
        ) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (
          (parseFloat(a[sortConfig.key]) || a[sortConfig.key]) >
          (parseFloat(b[sortConfig.key]) || b[sortConfig.key])
        ) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [JSON.stringify(items), sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { sortedItems, requestSort, sortConfig };
};
