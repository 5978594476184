import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import styled from "styled-components";

export const StyledBox = styled(Box)`
  background: white;
`;

export const StyledSectionTitle = styled(Flex)`
  background: #f9f9f9;
  padding: 14px;
  color: #6f6c6d;
  font-size: 14px;
  white-space: nowrap;
}
`;

export const FieldFlex = styled(Flex)`
  ${({ theme }) => `
    width: 50%;
    padding: ${theme.space[2]}px;
  `};
`;

export const DatePickerFlex = styled(Flex)`
  ${({ theme }) => `
    width: 50%;
    padding: ${theme.space[2]}px;

    > div.react-datepicker-wrapper, 
    .customDatePickerWidth > div > div.react-datepicker__input-container
    .customDatePickerWidth > div > div.react-datepicker__input-container input {
      width: 100%;
    }
  `};
`;

export const TextareaFlex = styled(Flex)`
  ${({ theme }) => `
    width: 100%;
    padding: ${theme.space[2]}px;
  `};
`;

export const Line = styled(Flex)`
  ${({ theme }) => `
	  display: flex;
    background-color: #D0D0D0;
    margin: ${theme.space[2]}px 0 0;
    width: 100%;
    height: 1px;
  `};
`;

export const FileNameButton = styled(Button)`
  ${({ theme }) => `
    color: ${theme.palette.primary};
  `};
`;

export const StyledLinkFieldWrapper = styled(Flex)`
  width: 80%;
`;
