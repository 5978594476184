/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { TableType } from "types/tableType";
import { ColumnInstance } from "react-table";

export interface ITableData {
  objectId?: string;
  isOpen?: boolean;
  columnNames?: Array<string | any> | [];
  columnVisibility?: Array<boolean> | [];
  changeColVis?: any | null;
  renewSidebar?: any | null;
  tableName?: string | null;
  allColumns?: Array<ColumnInstance<any>> | null;
  FilterComponent?: () => JSX.Element;
  RaportDownloadComponent?: () => JSX.Element;
  RaportExportComponent?: () => JSX.Element;
  fetchRowData?: (id: any) => Promise<any>;
  tableType?: TableType;
  detailsObject?: boolean;
  hiddenSidebar?: boolean;
  onlyFilters?: boolean;
  hideFiltersAndOptions?: boolean;
  data?: any;
  dataId?: string;
  dataPayload?: any;
  openDetailsView?: () => void;
  isLoading?: boolean;
  showComments?: boolean;
}

interface ISidebarInfoContext {
  tableData: ITableData | null;
}
interface ITableSidebarDispatch {
  setTableData: (tableData: ITableData | null) => void;
}

const TableSidebarContext = React.createContext<
  ISidebarInfoContext | undefined
>(undefined);

const TableSidebarDispatch = React.createContext<
  ITableSidebarDispatch | undefined
>(undefined);

export const TableSidebarContextProvider: React.FC = ({ children }) => {
  const [tableData, setTableData] = React.useState<ITableData | null>(null);
  return (
    <TableSidebarContext.Provider value={{ tableData }}>
      <TableSidebarDispatch.Provider value={{ setTableData }}>
        {children}
      </TableSidebarDispatch.Provider>
    </TableSidebarContext.Provider>
  );
};

export const useTableSidebarContext = () => {
  const context = React.useContext(TableSidebarContext);
  if (!context)
    throw new Error(
      "useTableSidebarContext must be inside a TableSidebarContextProvider with a value"
    );

  return context;
};

export const useTableSidebarDispatchContext = () => {
  const context = React.useContext(TableSidebarDispatch);
  if (!context) {
    throw new Error(
      "useTableSidebarDispatchContext must be inside a TableSidebarContextProvider with a value"
    );
  }
  return context;
};
