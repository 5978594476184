import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { formatDate } from "utilities/formatDate";
import { format, parseISO } from "date-fns";
import { fetchTaskHistory } from "services/tasks";
import { ITaskHistory } from "types/task";
import { CustomTable } from "components/CustomTable";

type IChanges = {
  name: string;
  value: (item: { [key: string]: string }) => string;
  width: number;
};

interface ITimelineElement {
  date: string;
}

const Wrapper = styled.div`
  width: 980px;
  position: relative;
`;
const Line = styled.div`
  position: absolute;
  top: 0;
  left: 6px;
  height: 100%;
  width: 2px;
  background-color: rgba(30, 46, 102, 0.1);
`;
const TimeElement = styled(Card)<ITimelineElement>`
  ${({ theme }) => `
		position: relative;
		padding: 16px;
		margin-left: 100px;
		margin-bottom: 20px;

		&::before {
			content: '';
			position: absolute;
			top: 3px;
			left: -100px;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background-color: ${theme.palette.primary};
			border: 1px solid ${theme.palette.primary};
		`}
`;

const DateText = styled.p`
  position: absolute;
  left: 20px;
  margin: 0;
`;

const TableContainer = styled.div`
  max-width: 825px;
`;

export const Timeline = ({ taskId }: { taskId: string }) => {
  const [timelineData, setTimelineData] = useState<ITaskHistory[]>([]);
  const [colVis, setColVis] = useState(Array(4).fill(true));

  const retrieveTimeline = useCallback(async () => {
    const fromDate = new Date("1990-01-01");
    const toDate = new Date();
    const response = await fetchTaskHistory(
      taskId,
      formatDate(fromDate),
      formatDate(toDate)
    );

    if (response.length === undefined) return;

    const filtredData = response.map((item) => {
      const newProperties = item.changedProperties.filter(
        ({ name, oldValue, newValue }) => {
          if (Array.isArray(oldValue) || Array.isArray(newValue)) {
            return {
              name,
              newValue: "tablica",
              oldValue: "tablica",
            };
          } else if (
            typeof newValue === "boolean" ||
            typeof oldValue === "boolean"
          ) {
            return {
              name,
              oldValue: oldValue ? "tak" : "nie",
              newValue: newValue ? "tak" : "nie",
            };
          } else {
            return {
              name,
              oldValue,
              newValue,
            };
          }
        }
      );

      return { ...item, changedProperties: newProperties };
    });
    setTimelineData(filtredData);
  }, [taskId]);

  useEffect(() => {
    retrieveTimeline();
  }, [retrieveTimeline]);

  const columns: Array<IChanges> = useMemo(
    () => [
      {
        name: "Pole",
        value: (item) => item.translatedName,
        width: 190,
      },
      {
        name: "Stara wartość",
        value: (item) =>
          item.oldValueIsDate
            ? formatDate(Date.parse(item.oldValue))
            : item.oldValue
            ? `${item.oldValue}`
            : "---",
        width: 300,
      },
      {
        name: "Nowa wartość",
        value: (item) =>
          item.newValueIsDate
            ? formatDate(Date.parse(item.newValueIsDate))
            : item.newValueIsDate
            ? `${item.newValueIsDate}`
            : "---",
        width: 300,
      },
    ],
    []
  );

  return (
    <Wrapper>
      <Line />
      {timelineData?.map((prop: ITaskHistory) => (
        <React.Fragment key={prop.date}>
          <DateText>
            {formatDate(new Date(parseISO(prop.date)))} <br />{" "}
            {format(new Date(parseISO(prop.date)), "HH:mm")} <br />{" "}
            {prop.changedProperties[0]?.changedBy || "brak autora"}
          </DateText>
          <TimeElement key={prop.date} date={prop.date}>
            <TableContainer>
              <CustomTable
                showLongText
                columnList={columns}
                colVis={colVis}
                data={prop.changedProperties}
              />
            </TableContainer>
          </TimeElement>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
