import { useEffect, useState } from "react";
import {
  getNotifications,
  INotification,
  readNotfication,
} from "services/notifications";
import { PageLayout } from "components/PageLayout";
import { Notification } from "components/Notification";
import styled from "styled-components";

const Root = styled.div`
  height: 100%;
  overflow: auto;
`;

export const NotificationsView = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);

  const handleClick = (id: string) => {
    markNotificationAsRead(id);
    readNotfication(id);
  };

  const markNotificationAsRead = (id: string) => {
    const noti = [...notifications];
    const objIndex = notifications.findIndex((el) => el.id === id);
    noti[objIndex].read = true;

    setNotifications(noti);
  };

  useEffect(() => {
    getNotifications().then((response) => {
      setNotifications(response.data);
      setNotificationsCount(response.pagination.count);
    });
  }, []);

  return (
    <PageLayout
      pageTitle="powiadomienia"
      pageHeaderRight={`ilość powiadomień: ${notificationsCount}`}
    >
      <Root>
        {notifications &&
          notifications.map((notification) => (
            <div key={notification.id}>
              <Notification {...notification} redirectAction={handleClick} />
            </div>
          ))}
      </Root>
    </PageLayout>
  );
};
