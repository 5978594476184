import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { CustomTable } from "components/CustomTable";
import styled from "styled-components";
import { formatDate } from "utilities/formatDate";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";
import {
  fetchDownloadKeyGivenDocument,
  fetchDownloadKeyGivenLeadingDocument,
  fetchKeyGiven,
} from "services/keys";
import { downloadFile } from "utilities/downloadFile";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const StyledContent = styled.div`
  width: 80px;
  padding-top: 15px;
  padding-left: 15px;
`;

const TableContainer = styled.div`
  max-width: 1490px;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => `padding: ${theme.space[2]}px;`}
`;

export const KeyPublicationsList = ({ keyId }: { keyId: string }) => {
  const [colVis, setColVis] = useState(Array(9).fill(true));
  const [givenKeys, setGivenKeys] = useState([]);

  const history = useHistory();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  useEffect(() => {
    fetchKeyGiven({ keyId })
      .then(({ data }) => setGivenKeys(data))
      .catch(() => toast.error("Nie udało się pobrać wydań."));
  }, [keyId]);

  const columns = useMemo(
    () => [
      {
        name: "Numer Wydania",
        width: 120,
        value: ({ givenNumber }) => (
          <StyledContent>{givenNumber}</StyledContent>
        ),
      },
      {
        name: "Wykonawca",
        width: 120,
        value: ({ contractor }) => contractor,
      },
      {
        name: "Osoba",
        width: 90,
        value: ({ recipientName }) => recipientName,
      },
      {
        name: "Data wydania",
        width: 120,
        value: ({ givenDate }) => formatDate(new Date(givenDate)),
      },
      {
        name: "Ilość sztuk",
        width: 90,
        value: ({ quantity }) => quantity,
      },
      {
        name: "Odbiór osobisty / wysyłka",
        width: 200,
        value: ({ shipment }) => (shipment ? "wysyłka" : "Odbiór osobisty"),
      },
      ...(loggedUserRoles?.includes("ROLE_KEYS_ECONOMY_UPDATE")
        ? [
            {
              name: "",
              width: 110,
              value: ({ status, keyId, givenId, givenRequestId }) =>
                status === "ready_to_given" ? (
                  <Button
                    bordered
                    onClick={() => {
                      history.push(
                        `/keys/${keyId}/publication/edit/${givenId}/${givenRequestId}`
                      );
                    }}
                  >
                    Edytuj
                  </Button>
                ) : (
                  "edycja niemożliwa"
                ),
            },
            {
              name: "",
              width: 110,
              value: ({ givenRequestId }) => (
                <Button
                  bordered
                  onClick={() => {
                    history.push(`/keys/${keyId}/return/${givenRequestId}`);
                  }}
                >
                  Zwrot kluczy
                </Button>
              ),
            },
          ]
        : []),
      {
        name: "",
        width: 200,
        value: ({ givenRequestId, givenNumber }) => (
          <StyledButton
            bordered
            onClick={() => {
              fetchDownloadKeyGivenDocument(givenRequestId)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${givenNumber}.xlsx`);
                })
                .catch(() => toast.error("Nie udało się pobrać pliku."));

              fetchDownloadKeyGivenDocument(givenRequestId, true)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${givenNumber}-kopia.xlsx`);
                })
                .catch(() => toast.error("Nie udało się pobrać kopii pliku."));

              fetchDownloadKeyGivenLeadingDocument(givenRequestId)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${givenNumber}-pismo-przewodnie.xlsx`);
                })
                .catch(() =>
                  toast.error("Nie udało się pobrać pliku przewodniego.")
                );
            }}
          >
            Pobierz dokument wydania
          </StyledButton>
        ),
      },
    ],
    [loggedUserRoles]
  );

  return (
    <TableContainer>
      {givenKeys?.length ? (
        <CustomTable
          showLongText
          columnList={columns}
          data={givenKeys}
          colVis={colVis}
        />
      ) : (
        "Brak wydań"
      )}
    </TableContainer>
  );
};
