import { Button } from "components/Button";
import { PageLayout } from "components/PageLayout";
import { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";

import {
  HelperPanelWrapper,
  Header,
  ContentCard,
  Heading,
  StyledInput,
  InputContainer,
  InstructionContainer,
  ExampleFileContainer,
  ContentText,
  StyledExampleFile,
  MiddleTitle,
  StatusBar,
  Status,
  LoadingText,
  StyledSelect,
} from "./NewImportView.styled";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import {
  fetchImportFile,
  fetchImportsHistory,
  fetchPostImportFile,
  fetchPostImportMRWFile,
  fetchPutMRWKOSettings,
  taskMetaContextType,
} from "services/importExport";
import { toast } from "react-toastify";
import { useFetchKOSigningPerson } from "hooks/fetchKOSingingPerson";
import { envProjectName } from "envData";
import { downloadFile } from "utilities/downloadFile";

const taskMetaContexts: taskMetaContextType[] = [
  "SA",
  "CW",
  "TI",
  "MRW",
  "OTK",
  "PŚP",
  "task_areas",
  "FDS",
  "objects",
  "payment_date",
];

const NewImportView: FC = () => {
  const params = useParams<{ actionType: string; type: string }>();
  const history = useHistory();

  const [file, setFile] = useState<File | null>(null);
  const [importingStatus, setImportingStatus] = useState(0);
  const [contextType, setContextType] = useState<taskMetaContextType | null>();
  const [signingPerson, setSigningPerson] = useState("");
  const [inter, setInter] = useState<NodeJS.Timeout | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { KOSigningPerson } = useFetchKOSigningPerson();

  const getContextTypeFromURL = (url: string) => {
    taskMetaContexts.map((item) => {
      url.includes(item) && setContextType(item);
    });
  };

  const handleSaveSigningPerson = () => {
    fetchPutMRWKOSettings({
      name: "signing_person_nokia",
      value: signingPerson,
    }).then(({ success }) => {
      success && toast.success("Zmieniono osobę podpisującą");
    });
  };

  const handleComeBackClick = () => {
    history.push(`/import-export-panel/import/${params.type}`);
  };

  const handleImportClick = () => {
    const translatedContextParam = {
      "OTK-Fonon": "fonon",
      "OTK-SPC-2": "spc-2",
      PŚP: "psp",
      FDS: "fds",
      objects: "general",
    };

    const translatedContextType = {
      OTK: "otk",
      PŚP: "psp",
      task_areas: "task_areas",
      FDS: "fds",
      objects: "objects",
      payment_date: "payment_date",
    };

    if (contextType && file?.name) {
      const data = new FormData();
      data.append("file", file);

      const interId = setInterval(() => {
        setImportingStatus((prevStatus) => prevStatus + 4);
      }, 800);

      setInter(interId);

      if (contextType === "MRW") {
        fetchPostImportMRWFile({
          data,
        }).catch(() => {
          toast.error("Plik nie spełnia wymagań.");
          clearInterval(interId);
          setImportingStatus(0);
        });
      } else {
        fetchPostImportFile({
          data,
          context: translatedContextParam[params.type] || contextType,
          type: translatedContextType[contextType] || "task_meta",
        }).catch(() => {
          toast.error("Plik nie spełnia wymagań.");
          clearInterval(interId);
          setImportingStatus(0);
        });
      }
    }
  };

  const handleDownloadExampleFile = async () => {
    if (params.type === "OTK-Fonon") {
      const url = "/FONON.xlsx";
      const link = document.createElement("a");
      link.download = "FONON.xlsx";
      link.href = url;
      link.click();
    } else if (params.type === "OTK-SPC-2") {
      const url = "/SPC-2.xlsx";
      const link = document.createElement("a");
      link.download = "SPC-2.xlsx";
      link.href = url;
      link.click();
    } else if (params.type === "OR,ORR,DOR-CW") {
      const res: any = await fetchImportsHistory({
        context: "CW",
        type: "task_meta",
      });

      fetchImportFile(res.data[0].id)
        .then((response) => response.blob())
        .then((blob) => {
          downloadFile(blob, `CW.xlsx`);
        })
        .catch(() => {
          toast.error("Wystąpił problem z pobieraniem pliku.");
        });
    } else if (params.type === "OR,ORR,DOR-SABP") {
      const res: any = await fetchImportsHistory({
        context: "SA",
        type: "task_meta",
      });

      fetchImportFile(res.data[0].id)
        .then((response) => response.blob())
        .then((blob) => {
          downloadFile(blob, `SA.xlsx`);
        })
        .catch(() => {
          toast.error("Wystąpił problem z pobieraniem pliku.");
        });
    }
  };

  useEffect(() => {
    if (importingStatus >= 100) {
      if (inter) clearInterval(inter);
    }
  }, [importingStatus]);

  useEffect(() => {
    KOSigningPerson?.length && setSigningPerson(KOSigningPerson[0]?.value);
  }, [KOSigningPerson]);

  useEffect(() => {
    getContextTypeFromURL(params.type);
  }, [params.type]);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <HelperPanelWrapper>
        <Header>Nowy import {params.type}</Header>

        <Button bordered onClick={handleComeBackClick}>
          Wróć
        </Button>
      </HelperPanelWrapper>

      <ContentCard>
        {importingStatus > 0 ? (
          importingStatus >= 100 ? (
            <>
              <Heading>
                Zaimportowany plik pojawi się w systemie w ciągu trzech minut.
              </Heading>
              <ContentText>{file?.name}</ContentText>
            </>
          ) : (
            <>
              <Heading>Importowanie pliku</Heading>
              <ContentText>{file?.name}</ContentText>
              <Box my={4}>
                <StatusBar>
                  <LoadingText>{importingStatus}% / 100%</LoadingText>
                  <Status status={importingStatus} />
                </StatusBar>
              </Box>
              <Flex mt={5} justifyContent="flex-end">
                <Button
                  type="button"
                  bordered
                  onClick={(event) => {
                    event.preventDefault();
                    if (inter) clearInterval(inter);
                    setImportingStatus(0);
                  }}
                >
                  Anuluj
                </Button>
              </Flex>
            </>
          )
        ) : (
          <>
            <Heading>Importuj plik</Heading>

            {contextType === "MRW" && envProjectName !== "herkules" && (
              <InputContainer>
                <StyledSelect
                  label="Osoba podpisująca"
                  onChange={(e) => {
                    setSigningPerson(e.target.value);
                  }}
                  value={signingPerson}
                >
                  <option></option>
                  <option>Jacek Wasilewski</option>
                  <option>Adam Kotucha</option>
                  <option>Andrzej Wiśniewski</option>
                  <option>Paweł Caban</option>
                </StyledSelect>
                <Box mt={4} ml={2}>
                  <Button
                    bordered
                    type="button"
                    onClick={handleSaveSigningPerson}
                  >
                    Zapisz
                  </Button>
                </Box>
              </InputContainer>
            )}

            <InputContainer>
              <StyledInput
                value={file ? file.name : ""}
                icon={<DeleteIcon />}
                onIconClick={() => {
                  setFile(null);
                }}
                label="Wybierz plik"
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(event) => {
                  if (event.target.files) {
                    const targetFile = event.target.files[0];
                    if (targetFile) {
                      setFile(targetFile);
                      event.target.value = "";
                    } else {
                      setFile(null);
                    }
                  }
                }}
              />
              <Box mt={4}>
                {file ? (
                  <Button type="button" bordered onClick={handleImportClick}>
                    Importuj
                  </Button>
                ) : (
                  <Button
                    type="button"
                    bordered
                    onClick={(event) => {
                      event.preventDefault();
                      fileInputRef.current?.click();
                    }}
                  >
                    Wybierz
                  </Button>
                )}
              </Box>
            </InputContainer>

            <Heading>Instrukcja</Heading>
            <InstructionContainer>
              <ContentText>
                Pamiętaj aby zaimportować plik o określonej strukturze, wykonaj
                poniższe kroki:
                <br />
                1. Upewnij się, że masz odpowiedni plik, który pozwala na
                zaimportowanie. Przykładowy plik o odpowiedniej strukturze
                pobierzesz klikając z przycisk „POBIERZ PRZYKŁADOWY PLIK”
                <br />
                2. Sprawdź strukturę pliku, który chcesz zaimportować. Obejmuje
                to format pliku, rozmiar, nagłówki, kolumny, separator itp. W
                niektórych przypadkach możesz musieć użyć narzędzi do
                przetwarzania wstępnego, takich jak Excel, aby dostosować
                strukturę pliku.
                <br />
                3. Zaimportuj plik wskazując plik za pomocą przycisku „WYBIERZ”,
                a następnie „IMPORTUJ”
              </ContentText>

              <ExampleFileContainer>
                <Flex mt={15} flexDirection="column">
                  <StyledExampleFile />
                  <MiddleTitle>Plik</MiddleTitle>
                  <Box mb={2}>
                    <Button
                      type="button"
                      bordered
                      onClick={handleDownloadExampleFile}
                    >
                      Pobierz przykładowy plik
                    </Button>
                  </Box>
                </Flex>
              </ExampleFileContainer>
            </InstructionContainer>
          </>
        )}
      </ContentCard>
    </PageLayout>
  );
};

export default NewImportView;
