import ReactEcharts from "echarts-for-react";
// import dataLine from "data/lines.json";

interface IEpisodes {
  ID: number;
  name: string;
  from: string;
  to: string;
  distance: string;
}
interface IDataLine {
  episodes: IEpisodes[];
  outlineLevel: string;
}
interface IProps {
  dataLine: IDataLine[];
}

export const Chart: React.FC<IProps> = ({ dataLine }) => {
  const convertXAxisLine = () => {
    const dataPrepared: string[] = [];

    dataLine.map((item) => {
      return item.episodes.forEach((val, index) => {
        dataPrepared.push(`${val.from}`);
        dataPrepared.push(`${val.to}`);

        // const uniqueValue = dataPrepared.reduce(
        //   (target: string[], value: string) => {
        //     if (target.indexOf(value) === -1) target.push(value);
        //     return target;
        //   },
        //   []
        // );

        // noDuplicate.push(...uniqueValue);

        const largest = dataPrepared.sort((a: string, b: string) => {
          return (
            parseFloat(a.replace(",", ",")) - parseFloat(b.replace(",", "."))
          );
        })[dataPrepared.length - 1];

        const episodes = index * 20;

        if (parseFloat(largest.replace(",", ".")) >= episodes) {
          dataPrepared.push(`${episodes}`);
        }
      });
    });

    // console.log("dataPrepared", dataPrepared);

    return dataPrepared.filter((v, i, a) => a.indexOf(v) === i);
  };

  const generateOutlineData = (episodes: IEpisodes[], position: number) => {
    const arr: any[] = [];

    episodes.forEach((element, idx) => {
      arr.push([`${element.from}`, position], [`${element.to}`, position]);

      if (episodes[idx].to !== episodes[idx + 1]?.from) {
        arr.push([`${element.to}`, position], undefined, [
          `${episodes[idx + 1]?.from}`,
          position,
        ]);
      }
      if (episodes[episodes.length - 1] === element) {
        arr.push([`${element.to}`, position]);
      }

      if (idx === episodes.length - 1) {
        arr.push([`${element.from}`, position], [`${element.to}`, position]);
      }
    });
    return arr;
  };

  const generateData = (item: any) => {
    if (item.outlineLevel === "OTK")
      return generateOutlineData(item.episodes, 0);
    if (item.outlineLevel === "Projekt budowlany")
      return generateOutlineData(item.episodes, 1);
    if (item.outlineLevel === "Rurociąg szlak")
      return generateOutlineData(item.episodes, 2);
    if (item.outlineLevel === "Projekt wykonawczy")
      return generateOutlineData(item.episodes, 3);
    if (item.outlineLevel === "Kabel szlak")
      return generateOutlineData(item.episodes, 4);
    if (item.outlineLevel === "Dok. powykonawcza")
      return generateOutlineData(item.episodes, 5);
  };

  const generateArea = (item: any) => {
    if (item.specialLayer) {
      return [
        [
          {
            name: "Gotowe do certyfikacji",
            xAxis: `${item.episodes[0].from}`,
          },
          {
            xAxis: `${item.episodes[0].to}`,
          },
        ],
      ];
    }
  };

  const generateSeries = () =>
    dataLine.map((item) => ({
      name: item.outlineLevel,
      data: generateData(item),
      type: "line",
      showAllSymbol: true,
      markArea: {
        itemStyle: {
          color: "rgba(255, 173, 177, 0.4)",
        },
        data: generateArea(item),
      },
    }));

  const getOption = () => ({
    title: {
      text: "Wykres",
      subtext: "Wykres",
    },
    tooltip: {
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisTick: {
        alignWithLabel: true,
      },
      data: convertXAxisLine().sort(
        (a: string, b: string) =>
          parseFloat(a.replace(",", ".")) - parseFloat(b.replace(",", "."))
      ),
      axisLabel: {
        interval: 1,
        hideOverlap: true,
        formatter: (value: string) => {
          if (parseFloat(value.replace(",", ".")) % 20 !== 0) {
            return value;
          }
        },
      },
    },
    yAxis: {
      data: dataLine.map((item) => item.outlineLevel),
      axisPointer: {
        snap: true,
      },
    },
    series: generateSeries(),
  });

  return (
    <div className="App">
      <ReactEcharts option={getOption()} style={{ height: 300 }} />
    </div>
  );
};
