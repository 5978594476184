import styled from "styled-components";

const DatePickerHeaderStyled = styled.div`
  margin: 0;
  color: rgba(19, 19, 21, 0.65);
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 1px;
  margin-left: 12px;
  padding-left: 4px;
`;

export const DatePickerHeader = ({ text }) => (
  <DatePickerHeaderStyled>{text}</DatePickerHeaderStyled>
);
