import { Flex } from "components/Flex";
import React, { useCallback, useMemo, useState } from "react";
import { IconOptions, StyledBox } from "./UsersCard.styled";
import { Avatar } from "components/Avatar";
import { CustomTable } from "components/CustomTable";
import { Box } from "components/Box";
import { Dropdown } from "components/Dropdown";
import { deleteUser, IUser } from "services/user";
import { toast } from "react-toastify";
import { ConfirmModal } from "components/ConfirmModal";
import { useHistory } from "react-router-dom";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface UsersCardProps {
  users: IUser[];
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
  chosenUser?: IUser;
  setChosenUser: React.Dispatch<React.SetStateAction<IUser>>;
  setIsUserFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UsersCard = ({
  users,
  setUsers,
  chosenUser,
  setChosenUser,
  setIsUserFormOpen,
}: UsersCardProps) => {
  const [colVis, setColVis] = useState(Array(8).fill(true));
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const history = useHistory();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const handleDeleteUser = useCallback(async () => {
    try {
      await deleteUser(chosenUser.id);
      setUsers((prevUsers) =>
        prevUsers.filter((prevUser) => prevUser.id !== chosenUser.id)
      );
      toast.success("Użytkownik został usunięty.");
      setIsOpenConfirmModal(false);
    } catch (error) {
      toast.error("Nie udało się usunąć użytkownika.");
    }
  }, [chosenUser, setUsers]);

  const dropdownItems = useMemo(
    () => [
      ...(loggedUserRoles?.includes("ROLE_USER_UPDATE")
        ? [
            {
              label: "Edytuj",
              onClick: () => {
                setIsUserFormOpen(true);
              },
            },
            {
              label: "Zmień hasło",
              onClick: () => {
                history.push(`/admin/users/change-password/${chosenUser?.id}`);
              },
            },
          ]
        : []),
      ...(loggedUserRoles?.includes("ROLE_USER_DELETE")
        ? [
            {
              label: "Usuń",
              onClick: () => setIsOpenConfirmModal(true),
            },
          ]
        : []),
    ],
    [loggedUserRoles, chosenUser?.id, history, setIsUserFormOpen]
  );

  const columns = useMemo(
    () => [
      {
        name: "ID",
        value: (e) => <Box pt={5}>{e.id}</Box>,
        width: 100,
      },
      {
        name: "Użytkownik",
        value: (e) => <Avatar url={e.avatar} />,
        width: 120,
      },
      {
        name: "Imię",
        value: (e) => e.firstname,
        width: 120,
      },
      {
        name: "Nazwisko",
        value: (e) => e.lastname,
        width: 120,
      },
      {
        name: "Email",
        value: (e) => e.email,
        width: 230,
      },
      {
        name: "Partner",
        value: (e) => e.partner,
        width: 150,
      },
      {
        name: "Rola",
        value: (e) =>
          e.groups.map((group) => (
            <>
              {group} <br />
            </>
          )),
        width: 200,
      },
      {
        name: "",
        value: (e) => (
          <Dropdown items={dropdownItems}>
            <IconOptions />
          </Dropdown>
        ),
        width: 50,
      },
    ],
    [dropdownItems]
  );

  return (
    <>
      <Flex flexDirection={"column"}>
        <StyledBox>
          <CustomTable
            showLongText
            columnList={columns}
            data={users}
            colVis={colVis}
            clickTableRow={(user) => {
              setChosenUser(user);
            }}
          />
        </StyledBox>
      </Flex>

      <ConfirmModal
        handleConfirm={handleDeleteUser}
        onCancelClick={() => {
          setIsOpenConfirmModal(false);
          setChosenUser(null);
        }}
        isOpen={isOpenConfirmModal}
        header="Czy na pewno chcesz usuąć użytkownika?"
        confirmBtnText="Usuń"
      />
    </>
  );
};
