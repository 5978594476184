import { envProjectName } from "envData";

export const theme = {
  palette: {
    primary: envProjectName === "herkules" ? "#f77a19" : "#005AFF",
    text: {
      primary: "#001135",
      error: "#856404",
      warring: "#721c24",
      grey: "#1e2e66a6",
      darkGrey: "#AEAEAE",
      dark: "#CCCCCC",
      shadowGrey: "#666666",
    },
    neutral: {
      white: "#fff",
      black: "#000",
      error: "#f8d7da",
      warring: "#fff3cb",
    },
    background: "#f8f8fA",
    grey: "#dadada",
    lightGrey: "#FBFBFB",
  },
  shape: {
    borderRadius: 4,
  },
  space: new Array(25).fill(null).map((value, index) => index * 4),
};
