import { Box } from "components/Box";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { useForm } from "react-hook-form";
import { InputsGrid } from "components/InputsGrid";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Flex } from "components/Flex";
import { useState } from "react";
import { IAntenna } from "types/antenna";
import { updateTaskAntennas } from "services/tasks";
import { useEffect } from "react";

interface IImplementationFormData {
  siteConfig: string;
  transmitter: string;
  antenna: string;
  height: string;
  azimuth: string;
  mechanicalDownTilt: string;
  splitterTrapper: string;
}

export interface IImplementationForm {
  antenna?: IAntenna;
  number?: number;
  onSubmit: (data: IAntenna) => any;
}

const schema = yup.object().shape({
  siteConfig: yup.string().required(),
  transmitter: yup.string().required(),
  antenna: yup.string().required(),
  height: yup.number().min(0).required(),
  azimuth: yup.number().min(0).max(360).required(),
  mechanicalDownTilt: yup.number().required(),
  splitterTrapper: yup.number().required(),
});

export const ImplementationForm: React.FC<IImplementationForm> = ({
  antenna,
  onSubmit,
  number,
}) => {
  const [isDisabled, setIsDisabled] = useState(antenna ? true : false);
  const { register, handleSubmit, reset } = useForm<IImplementationFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...antenna,
      height: antenna?.height.toString(),
      azimuth: antenna?.azimuth.toString(),
      mechanicalDownTilt: antenna?.mechanicalDownTilt.toString(),
    },
  });

  const onFormSubmit = (data: IImplementationFormData) => {
    const newAntenna: IAntenna = {
      ...data,
      height: parseFloat(data.height),
      azimuth: parseInt(data.azimuth),
      mechanicalDownTilt: parseInt(data.mechanicalDownTilt),
    };

    onSubmit(newAntenna);

    if (antenna) {
      setIsDisabled(true);
    } else {
      reset();
    }
  };

  return (
    <>
      {antenna ? (
        <Flex justifyContent="space-between">
          <FieldSetTitle>Antenna #{number}</FieldSetTitle>
          <Button onClick={() => setIsDisabled((prevState) => !prevState)}>
            Edytuj
          </Button>
        </Flex>
      ) : null}
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box mb={4}>
          <InputsGrid columns={4}>
            <Input
              label="Site config"
              {...register("siteConfig")}
              disabled={isDisabled}
            />
            <Input
              label="Transmitter"
              {...register("transmitter")}
              disabled={isDisabled}
            />
            <Input
              label="Antenna"
              {...register("antenna")}
              disabled={isDisabled}
            />
            <Input
              label="Height"
              type="number"
              step="0.01"
              {...register("height")}
              disabled={isDisabled}
            />
            <Input
              label="Azimuth"
              type="number"
              min={0}
              max={360}
              step="1"
              {...register("azimuth")}
              disabled={isDisabled}
            />
            <Input
              label="Mechanical down tilt"
              min={0}
              step="1"
              {...register("mechanicalDownTilt")}
              disabled={isDisabled}
            />
            <Input
              label="splitterTrapper"
              type="number"
              step="1"
              min={0}
              max={100}
              {...register("splitterTrapper")}
              disabled={isDisabled}
            />
          </InputsGrid>
        </Box>
        <Button bordered disabled={isDisabled}>
          Zapisz
        </Button>
      </form>
    </>
  );
};
