import axios from "axios";
import { IFilters } from "contexts/KeysContext";
import qs from "qs";
import { IGivenKey, IKey, IKeyGivenAttachments, IReturnKey } from "types/keys";
import { IPagination } from "types/pagination";
import { fetchAPI, fetchFilesAPI } from "utilities/fetchAPI";

interface IFetchKeysResponse {
  data: Array<IKey>;
  pagination: IPagination;
}

export const fetchKeys = ({
  page,
  limit = 10,
  keyNumber,
  object,
  contractor,
  recipientName,
  recipientEmail,
}: {
  page?: number;
  limit?: number;
  keyNumber?: string;
  object?: string;
  contractor?: string;
  recipientName?: string;
  recipientEmail?: string;
}) => {
  const query = qs.stringify(
    {
      page,
      limit,
      filter: { keyNumber, object, contractor, recipientName, recipientEmail },
    },
    { arrayFormat: "brackets", skipNulls: true }
  );
  return fetchAPI<IFetchKeysResponse>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key/?${query}`
  );
};

export const fetchKey = ({ keyId }: { keyId: string }) =>
  fetchAPI<IKey>(`${process.env.REACT_APP_API_URL}/keys-economy/key/${keyId}`);

export const fetchSearchKey = ({ phrase }: { phrase: string }) => {
  const query = qs.stringify(
    {
      phrase,
    },
    { arrayFormat: "brackets", skipNulls: true }
  );
  return fetchAPI<IKey[]>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key/action/search-key?${query}`
  );
};

interface IFetchAvailableForKeyReturnObjectsResponse {
  givenId: number;
  keyId: number;
  keyNumber: string;
  objectCode: string;
  objectNameIE50: string;
  objectName: string;
  objectType: string;
  locationName: string;
  lineNumber: number;
  chainage: number;
  givenNumber: string;
  quantity: number;
  returnedQuantity: number;
  toReturnQuantity: number;
  nrOR71362: string;
}

export const fetchAvailableForKeyReturnObjects = ({
  givenRequestId,
}: {
  givenRequestId: string;
}) =>
  fetchAPI<IFetchAvailableForKeyReturnObjectsResponse[]>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-returns/action/list-available-for-return/${givenRequestId}`
  );

export const fetchKeyGiven = ({ keyId }: { keyId: string }) =>
  fetchAPI<{ data: IGivenKey[] }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given/${keyId}`
  );

export const fetchKeysGiven = ({
  keyNumber,
  object,
  contractor,
  recipientName,
  recipientEmail,
}: {
  keyNumber?: string;
  object?: string;
  contractor?: string;
  recipientName?: string;
  recipientEmail?: string;
}) => {
  const query = qs.stringify(
    {
      filter: { keyNumber, object, contractor, recipientName, recipientEmail },
    },
    { arrayFormat: "brackets", skipNulls: true }
  );
  return fetchAPI<{ data: IGivenKey[] }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given/?${query}`
  );
};

export const fetchKeySingleGiven = ({ keyGivenId }: { keyGivenId: string }) =>
  fetchAPI<IGivenKey>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given/single/${keyGivenId}`
  );

export const fetchKeySingleReturn = ({ returnId }: { returnId: string }) =>
  fetchAPI<IGivenKey>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-returns/single/${returnId}`
  );

export const fetchKeyReturns = ({ keyId }: { keyId: string }) =>
  fetchAPI<{ data: IReturnKey[] }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-returns/${keyId}`
  );

export const fetchKeysReturns = ({
  keyNumber,
  object,
  contractor,
  recipientName,
  recipientEmail,
}: {
  keyNumber?: string;
  object?: string;
  contractor?: string;
  recipientName?: string;
  recipientEmail?: string;
}) => {
  const query = qs.stringify(
    {
      filter: { keyNumber, object, contractor, recipientName, recipientEmail },
    },
    { arrayFormat: "brackets", skipNulls: true }
  );
  return fetchAPI<{ data: IReturnKey[] }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-returns/?${query}`
  );
};

interface IPostKey extends Pick<IKey, "keyNumber" | "keysQuantity"> {
  task: string;
}

export const postKey = ({ key }: { key: IPostKey }) =>
  fetchAPI<{ id: string }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key/`,
    {
      method: "POST",
      body: JSON.stringify(key),
    }
  );

interface IPostKeyGiveRequest {
  recipientName: string;
  contractor: string;
  recipientEmail: string;
  shipment: boolean;
  shippingAddress: {
    city: string;
    zipCode: string;
    street: string;
    houseNumber: string;
    phoneNumber: string;
  };
  items: { key: string; quantity: string }[];
}

export const postKeyGiveRequest = ({
  payload,
}: {
  payload: IPostKeyGiveRequest;
}) =>
  fetchAPI<{ id: string }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given-request/`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );

export const updateKeyGiveRequest = ({
  requestId,
  payload,
}: {
  requestId: string;
  payload: Omit<IPostKeyGiveRequest, "items">;
}) =>
  fetchAPI<{ id: string }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given-request/${requestId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    }
  );

export const fetchDownloadKeyGivenDocument = (
  requestId: string,
  copy?: boolean
) =>
  fetchFilesAPI(
    `${
      process.env.REACT_APP_API_URL
    }/keys-economy/key-given-request/download-document/${requestId}${
      copy ? "?copy=0" : ""
    }`
  );

export const fetchDownloadKeyGivenLeadingDocument = (requestId: string) =>
  fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given-request/${requestId}/download-leading-document`
  );

export const fetchDownloadKeyReturnDocument = (
  returnRequestId: string,
  copy?: boolean
) => {
  return fetchFilesAPI(
    `${
      process.env.REACT_APP_API_URL
    }/keys-economy/key-return-request/${returnRequestId}/download-document${
      copy ? "?copy=0" : ""
    }`
  );
};

export const postKeyGivenConfirm = ({ linkId }: { linkId: string }) =>
  fetchAPI<{ id: string }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given-request/confirm-receipt/${linkId}`,
    {
      method: "POST",
    }
  );

interface IPostKeyReturnRequest {
  recipientName: string;
  recipientEmail: string;
  contractor: string;
  givenRequest: string;
  returnDate: string;
  comment: string;
  items: {
    keyGiven: string;
    quantity: string;
  }[];
}

export const postKeyReturnRequest = ({
  payload,
}: {
  payload: IPostKeyReturnRequest;
}) =>
  fetchAPI<{ id: string }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-return-request/`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );

export const updateKeyReturnRequest = ({
  returnRequestId,
  payload,
}: {
  returnRequestId: string;
  payload: Omit<IPostKeyReturnRequest, "items" | "givenRequest">;
}) =>
  fetchAPI<{ id: string }>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-return-request/${returnRequestId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    }
  );

export const downloadKeyReturnsFilteredReport = (filter: IFilters) => {
  const query = qs.stringify({
    filter,
  });

  return axios.get(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-returns/download/report?${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const downloadKeyGivenFilteredReport = (filter: IFilters) => {
  const query = qs.stringify({
    filter,
  });

  return axios.get(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given/download/report?${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const uploadKeyAttachment = (data: FormData) =>
  fetchFilesAPI(`${process.env.REACT_APP_API_URL}/keys-economy/attachment/`, {
    method: "POST",
    body: data,
  });

export const downloadKeyAttachment = (id: string) =>
  fetchFilesAPI(
    `${process.env.REACT_APP_API_URL}/keys-economy/attachment/${id}`
  );

export const deleteKeyAttachment = (id: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/keys-economy/attachment/${id}`, {
    method: "DELETE",
  });

export const fetchKeyGivenAttachments = ({
  keyRequestId,
}: {
  keyRequestId: string;
}) =>
  fetchAPI<IKeyGivenAttachments[]>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-given-request/${keyRequestId}/attachments`
  );

export const fetchKeyReturnAttachments = ({
  returnRequestId,
}: {
  returnRequestId: string;
}) =>
  fetchAPI<IKeyGivenAttachments[]>(
    `${process.env.REACT_APP_API_URL}/keys-economy/key-return-request/${returnRequestId}/attachments`
  );
