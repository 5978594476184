import qs from "qs";
import {
  API_URL,
  getDisruptionPath,
  DISRUPTION_PAYMENTS_URL,
  CREATE_DISRUPTION_PAYMENTS_URL,
  OPERATION_ON_DISRUPTION_PAYMENT,
  DISRUPTION_PAYMENTS_SUMMARY_URL,
  getUnitCostsPerCategory,
} from "constants/path";
import {
  IDisruption,
  IDisruptionHistory,
  IDisruptionToTask,
  IDisruptionUnitCost,
} from "types/disruption";
import { fetchAPI } from "utilities/fetchAPI";
import { IUpdate } from "types/update";
import axios from "axios";
import { IScope } from "types/scope";
import { IPagination } from "types/pagination";

interface IFetchDisruptionsResponse {
  data: Array<IDisruption>;
  pagination: IPagination;
}

export const fetchDisruptions = ({
  offset,
  limit = 10,
  submittingEntity,
  affectedEntity,
  category,
  status,
  iz,
  tags,
  startDateFrom,
  startDateTo,
  endDateFrom,
  endDateTo,
  submissionDateFrom,
  submissionDateTo,
  endDocumentSubmissionDate,
  continuationSearch,
  scopeSearch,
  number,
  sortBy,
  sortOrder,
  assignedUser,
  problems,
  problemType,
  code,
  endDocumentSubmissionDateFrom,
  endDocumentSubmissionDateTo,
  lineNumber,
}: {
  offset?: number;
  limit?: number;
  submittingEntity?: string[];
  affectedEntity?: string[];
  category?: string[];
  status?: string[];
  iz?: string[];
  tags?: string[];
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  submissionDateFrom?: string;
  endDocumentSubmissionDate?: string;
  endDocumentSubmissionDateTo?: string;
  endDocumentSubmissionDateFrom?: string;
  scopeSearch?: string;
  submissionDateTo?: string;
  continuationSearch?: string;
  number?: string;
  sortBy?: string;
  sortOrder?: "asc" | "desc";
  assignedUser?: string[];
  problems?: string[];
  problemType?: string[];
  code?: string;
  lineNumber?: number[];
}) => {
  const query = qs.stringify(
    {
      offset,
      limit,
      filter: {
        submittingEntity,
        affectedEntity,
        category,
        status,
        iz,
        tags,
        startDateFrom,
        startDateTo,
        endDocumentSubmissionDateFrom,
        endDocumentSubmissionDateTo,
        endDateFrom,
        endDateTo,
        submissionDateTo,
        submissionDateFrom,
        endDocumentSubmissionDate,
        continuationSearch,
        scopeSearch,
        number,
        assignedUser,
        problems,
        problemType,
        code,
        lineNumber,
      },
      sort: {
        by: sortBy,
        order: sortOrder,
      },
    },
    { arrayFormat: "brackets", skipNulls: true }
  );
  return fetchAPI<IFetchDisruptionsResponse>(
    `${process.env.REACT_APP_API_URL}/disruptions?${query}`
  );
};

interface IFetchDisruption {
  disruptionId: string;
}

export const fetchDisruption = ({ disruptionId }: IFetchDisruption) =>
  fetchAPI<IDisruption>(getDisruptionPath(disruptionId));

interface IPostDisruption {
  disruption: Omit<
    IDisruption,
    "id" | "tasks" | "scope" | "updates" | "number"
  >;
}

export const postDisruption = ({ disruption }: IPostDisruption) =>
  fetchAPI<IDisruption>(`${process.env.REACT_APP_API_URL}/disruptions`, {
    method: "POST",
    body: JSON.stringify(disruption),
  });

interface IUpdateDisruption {
  disruptionId: string;
  disruption: Omit<
    IDisruption,
    "id" | "tasks" | "scope" | "updates" | "number"
  >;
}

export const updateDisruption = ({
  disruptionId,
  disruption,
}: IUpdateDisruption) =>
  fetchAPI<IDisruption>(getDisruptionPath(disruptionId), {
    method: "PUT",
    body: JSON.stringify(disruption),
  });

export const removeDisruption = ({ disruptionId }: { disruptionId: string }) =>
  fetchAPI<null>(getDisruptionPath(disruptionId), {
    method: "DELETE",
  });

interface IFetchDisruptionTask {
  disruptionId: string;
  taskId: string;
}

export const fetchDisruptionTask = ({
  disruptionId,
  taskId,
}: IFetchDisruptionTask) =>
  fetchAPI<IDisruptionToTask>(
    `${process.env.REACT_APP_API_URL}/disruptions-to-tasks/${taskId}/${disruptionId}`
  );

interface IAddDisruptionScope {
  disruptionId: string;
  taskId: string;
  endDate: string;
}

export const addDisruptionScope = ({
  disruptionId,
  taskId,
  endDate,
}: IAddDisruptionScope) => {
  return fetchAPI<IScope>(
    `${process.env.REACT_APP_API_URL}/disruption-scopes/create/${disruptionId}/${taskId}`,
    {
      method: "POST",
      body: JSON.stringify({ cost: null, endDate }),
    }
  );
};

interface IRemoveDisruptionScope {
  disruptionId: string;
  taskId: string;
}

export const removeDisruptionScope = (disruptionScopeUuid: string) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/disruption-scopes/${disruptionScopeUuid}`,
    {
      method: "DELETE",
    }
  );
};

export const removeDisruptionSearchParam = (searchParamId: string) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/otk-search-params/${searchParamId}`,
    {
      method: "DELETE",
    }
  );
};

interface IAddOTKScopeObjects {
  disruptionId: string;
  lineNumber: string;
  chainageStart: string;
  chainageEnd: string;
  positionType?: string;
}

export const addOTKScopeObjects = ({
  disruptionId,
  lineNumber,
  chainageStart,
  chainageEnd,
  positionType,
}: IAddOTKScopeObjects) => {
  const query = qs.stringify(
    {
      "filter[positionType]": positionType ?? undefined,
    },
    { arrayFormat: "brackets" }
  );
  return fetchAPI<IScope>(
    `${process.env.REACT_APP_API_URL}/disruptions/${disruptionId}/otk/add-objects/${lineNumber}/${chainageStart}/${chainageEnd}?${query}`,
    { method: "PUT" }
  );
};

export const addORScopeObjects = ({
  disruptionId,
  lineNumber,
  chainageStart,
  chainageEnd,
}: IAddOTKScopeObjects) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/disruptions/${disruptionId}/or/add-objects/${lineNumber}/${chainageStart}/${chainageEnd}`,
    { method: "PUT" }
  );
};

interface IAddDisruptionUpdate {
  disruptionId: string;
  update: {
    date: string;
    sharepointUrl: Array<string>;
    description: string;
  };
}

export const addDisruptionUpdate = ({
  disruptionId,
  update,
}: IAddDisruptionUpdate) => {
  return fetchAPI<IUpdate>(
    `${process.env.REACT_APP_API_URL}/disruption-update/${disruptionId}`,
    { method: "POST", body: JSON.stringify(update) }
  );
};

export const updateDisruptionUpdate = (update: IUpdate) => {
  return fetchAPI<IUpdate>(
    `${process.env.REACT_APP_API_URL}/disruption-update/${update.id}`,
    { method: "PUT", body: JSON.stringify(update) }
  );
};

export const removeDisruptionUpdate = ({ updateId }: { updateId: string }) => {
  return fetchAPI(
    `${process.env.REACT_APP_API_URL}/disruption-update/${updateId}`,
    { method: "DELETE" }
  );
};

export const getDisruptionScope = (disruptionId: string, taskId: string) => {
  return fetchAPI<IUpdate>(
    `${process.env.REACT_APP_API_URL}/disruption-scopes/${disruptionId}/${taskId}`
  );
};

export const addInfluencedTaskToDisruptionScope = ({
  disruptionScope,
  disruptionId,
  taskId,
  scopeId,
  influenceType,
  days,
  influenceEndDate,
}: {
  disruptionScope: string;
  disruptionId: string;
  scopeId: string;
  taskId: string;
  influenceType: string;
  days: string;
  influenceEndDate: string;
}) => {
  return fetchAPI<IUpdate>(
    `${process.env.REACT_APP_API_URL}/influenced-tasks`,
    {
      method: "POST",
      body: JSON.stringify({
        disruptionScope,
        disruption: disruptionId,
        scopedTask: scopeId,
        task: taskId,
        influenceType,
        days,
        influenceEndDate: influenceEndDate || null,
      }),
    }
  );
};

export const editInfluencedTaskToDisruptionScope = ({
  influencedTaskUuid,
  disruptionScope,
  disruptionId,
  taskId,
  scopeId,
  influenceType,
  days,
  influenceEndDate,
}: {
  influencedTaskUuid: string;
  disruptionScope: string;
  disruptionId: string;
  scopeId: string;
  taskId: string;
  influenceType: string;
  days: string;
  influenceEndDate: string;
}) => {
  return fetchAPI<IUpdate>(
    `${process.env.REACT_APP_API_URL}/influenced-tasks/${influencedTaskUuid}`,
    {
      method: "PUT",
      body: JSON.stringify({
        disruptionScope,
        disruption: disruptionId,
        scopedTask: scopeId,
        task: taskId,
        influenceType,
        days,
        influenceEndDate,
      }),
    }
  );
};

export const removeInfluencedTaskFromDisruptionScope = (
  influencedTaskUuid: string
) =>
  fetchAPI<IUpdate>(
    `${process.env.REACT_APP_API_URL}/influenced-tasks/${influencedTaskUuid}`,
    { method: "DELETE" }
  );

export const updateDisruptionScope = (scope: any) => {
  return fetchAPI<IUpdate>(
    `${process.env.REACT_APP_API_URL}/disruption-scopes/${scope.disruption}/${scope.task}`,
    {
      method: "PUT",
      body: JSON.stringify({ cost: scope.cost, endDate: scope.endDate }),
    }
  );
};

export const downloadDisruptionReport = (dateFrom: string, dateTo: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/report/disruption/updated/${dateFrom}/${dateTo}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const downloadDisruptionFilteredReport = (
  filter: {
    offset?: number;
    limit?: number;
    submittingEntity?: string[];
    affectedEntity?: string[];
    category?: string[];
    status?: string[];
    iz?: string[];
    tags?: string[];
    startDateFrom?: string;
    startDateTo?: string;
    endDateFrom?: string;
    endDateTo?: string;
    submissionDateFrom?: string;
    endDocumentSubmissionDate?: string;
    scopeSearch?: string;
    submissionDateTo?: string;
    continuationSearch?: string;
    number?: string;
    assignedUser?: string[];
    problems?: string[];
    problemType?: string[];
    code?: string;
    lineNumber?: number[];
  },
  select?: { selectedColumns: string[] }
) => {
  const query = qs.stringify({
    filter,
    select,
  });

  return axios.get(
    `${process.env.REACT_APP_API_URL}/report/disruption/filtered?${query}`,
    {
      // params: {
      //   filter: filters,
      // },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const downloadSummaryReports = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/report/disruption/summary`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });

export const downloadSubmittedReports = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: string;
  dateTo: string;
}) =>
  axios.get(
    `${process.env.REACT_APP_API_URL}/report/disruption/submitted/${dateFrom}/${dateTo}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );

export const fetchDisruptionHistory = (
  disruptionId: string,
  dateFrom: string,
  dateTo: string
) =>
  fetchAPI<IDisruptionHistory[]>(
    `${getDisruptionPath(disruptionId)}/history/${dateFrom}/${dateTo}`
  );

export const fetchDisruptionFlag = (disruptionId: string) =>
  fetchAPI(`${API_URL}/disruption-flag/${disruptionId}`);

export const addDisruptionFlag = (disruptionId: string) =>
  fetchAPI(`${API_URL}/disruption-flag/${disruptionId}`, {
    method: "POST",
  });

export const removeDisruptionFlag = (disruptionId: string) =>
  fetchAPI(`${API_URL}/disruption-flag/${disruptionId}`, {
    method: "DELETE",
  });

export type IDisruptionPayments = {
  id?: string;
  partner?: string;
  costType: string;
  costEstimation: number;
  units: number;
  documentationType: string;
  comment: string;
  sum: number;
  disruption: string;
  unitCost: string;
  // dictItemUnitCostsImpacts: string;
  unitCostCategories: string;
};

export type IDisruptionPaymentsSummary = {
  partner: { id: string; name: string };
  summary: { sum: number; units: number };
};

export const getDisruptionPayments = (disruptionId: string) =>
  fetchAPI<IDisruptionPayments[]>(DISRUPTION_PAYMENTS_URL(disruptionId));

export const getDisruptionPaymentsSummary = (disruptionId: string) =>
  fetchAPI<IDisruptionPaymentsSummary[]>(
    DISRUPTION_PAYMENTS_SUMMARY_URL(disruptionId)
  );

export const createDisruptionPayment = (data: IDisruptionPayments) =>
  fetchAPI<IDisruptionPayments>(CREATE_DISRUPTION_PAYMENTS_URL, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const updateDisruptionPayment = (
  changedData: {
    id?: string;
    partner?: string;
    costType?: string;
    costEstimation?: number;
    units?: number;
    documentationType?: string;
    comment?: string;
    sum?: number;
    disruption?: string;
  },
  disruptionPaymentId: string
) =>
  fetchAPI<IDisruptionPayments>(
    OPERATION_ON_DISRUPTION_PAYMENT(disruptionPaymentId),
    {
      method: "PUT",
      body: JSON.stringify(changedData),
    }
  );

export const deleteDisruptionPayment = (disruptionPaymentId: string) =>
  fetchAPI(OPERATION_ON_DISRUPTION_PAYMENT(disruptionPaymentId), {
    method: "DELETE",
  });

export const getDisruptionUnitCosts = (categories: string) =>
  fetchAPI<IDisruptionUnitCost[]>(getUnitCostsPerCategory(categories));
