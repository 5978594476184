import { Modal, ProgressIndicator } from "@fluentui/react";
import { useMsContext } from "contexts/MsContext";
import { useEffect, useState } from "react";
import { getFolderContents } from "services/msGraphService";
import { GraphFolder, GraphItem } from "types/msGraph";
import { Box } from "./Box";
import { Button } from "./Button";
import { MsCommandBar } from "./MsCommandBar";
import { MsDetailList } from "./MsDetailList";

export interface FileExplorerProps {
  customOnSelectFile?: (path: string) => void;
  selectedFilePath?: string;
}

interface FileExplorerState {
  folder: GraphFolder | "loading";
  selectedFile: GraphItem | "none";
  mode: "open" | "closed";
}

export const MsFilePicker = ({
  customOnSelectFile,
  selectedFilePath,
}: FileExplorerProps) => {
  const [state, setState] = useState<FileExplorerState>({
    folder: "loading",
    selectedFile: selectedFilePath
      ? { parent: { path: selectedFilePath }, id: "", name: "" }
      : "none",
    mode: "closed",
  });

  const app = useMsContext();

  const loadFolder = async (id?: string) => {
    setState((s) => ({ ...s, folder: "loading" }));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const folder = await getFolderContents(app.authProvider!, id);
    setState((s) => ({ ...s, folder }));
  };

  const loadParentFolder = () => {
    if (state.folder != "loading") loadFolder(state.folder.parent.path);
  };

  const onSelectFile = (f: GraphItem) => {
    setState((s) => ({ ...s, selectedFile: f, mode: "closed" }));
    customOnSelectFile(f.parent.path + "/" + f.name);
  };

  useEffect(() => {
    if (app?.user?.email && selectedFilePath?.length)
      loadFolder(selectedFilePath.split("/").slice(0, -1).join("/"));
    else if (app?.user?.email) loadFolder();
  }, [app?.user, selectedFilePath]);

  if (!app?.user?.email) return <Box px={2}>brak uprawnień</Box>;

  if (state.mode == "closed")
    return (
      <Button
        onClick={() => {
          setState((s) => ({ ...s, mode: "open" }));
        }}
      >
        Wybierz
      </Button>
    );
  if (state.folder == "loading")
    return (
      <Modal
        isOpen
        onDismiss={() => setState((s) => ({ ...s, mode: "closed" }))}
      >
        <ProgressIndicator label="Loading folder" description="loading..." />
      </Modal>
    );

  return (
    <Modal isOpen onDismiss={() => setState((s) => ({ ...s, mode: "closed" }))}>
      <MsCommandBar
        folder={state.folder}
        loadParent={() => loadParentFolder()}
      />
      <MsDetailList
        folder={state.folder}
        loadFolder={(id) => loadFolder(id)}
        onSelectFile={onSelectFile}
      />
    </Modal>
  );
};
