import styled from "styled-components";

export const InputsGrid = styled.div<{ columns: number }>`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;
