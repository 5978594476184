import { Box } from "components/Box";
import { Flex } from "components/Flex";
import styled from "styled-components";

export const Wrapper = styled(Box)`
  height: calc(100% - 100px);
  overflow: auto;
`;
export const StyledBox = styled(Box)`
  background-color: #f8f8fa;
  border-radius: 4px;
`;
export const Paragraph = styled.p`
  margin: 5px;
`;

export const Heading = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 8px;
  p {
    color: #6f6c6d;
    font-weight: 600;
  }
  button {
    height: 26px;
  }
`;
