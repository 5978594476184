import { Box } from "components/Box";
import { Checkbox } from "components/Checkbox";
import { CustomTable } from "components/CustomTable";
import { Dropdown } from "components/Dropdown";
import { Flex } from "components/Flex";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { deleteTag } from "services/tasks";
import { TagType } from "types/tags";
import { Header, IconOptions, TagTableWrapper } from "./TagCard.styled";

interface TagCardProps {
  title: string;
  tags: TagType[];
  setTags: React.Dispatch<React.SetStateAction<TagType[]>>;
  chosenTag?: TagType;
  setChosenTag: React.Dispatch<React.SetStateAction<TagType>>;
  setIsTagFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedTags: string[];
  setCheckedTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const objectTypeTranslation = {
  milestone: "Kamień milowy",
  Disruption: "Zakłócenie",
};

export const TagCard = ({
  title,
  tags,
  setTags,
  chosenTag,
  setChosenTag,
  setIsTagFormOpen,
  checkedTags,
  setCheckedTags,
}: TagCardProps) => {
  const [colVis, setColVis] = useState(Array(4).fill(true));

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const handleDeleteTag = useCallback(async () => {
    try {
      await deleteTag(chosenTag.id);
      setTags((prevtags) =>
        prevtags?.filter((prevtag) => prevtag.id !== chosenTag.id)
      );
      toast.success("Tag został usunięty.");
    } catch (error) {
      toast.error("Nie udało się usunąć tagu.");
    }
  }, [chosenTag, setTags]);

  const dropdownItems = useMemo(
    () => [
      ...(loggedUserRoles?.includes("ROLE_ADMINISTRATION_UPDATE")
        ? [
            {
              label: "Edytuj",
              onClick: () => {
                setIsTagFormOpen(true);
              },
            },
          ]
        : []),
      ...(loggedUserRoles?.includes("ROLE_ADMINISTRATION_DELETE")
        ? [
            {
              label: "Usuń",
              onClick: handleDeleteTag,
            },
          ]
        : []),
    ],
    [loggedUserRoles, handleDeleteTag, setIsTagFormOpen]
  );

  const columns = useMemo(
    () => [
      {
        name: "Zaznacz",
        value: (e) => (
          <Flex mt={5} justifyContent="center">
            <Checkbox
              checked={checkedTags?.includes(e.id)}
              onChange={() => {
                if (!checkedTags?.includes(e.id)) {
                  setCheckedTags((prevCheckedTags) => [
                    ...prevCheckedTags,
                    e.id,
                  ]);
                } else {
                  setCheckedTags((prevCheckedTags) =>
                    prevCheckedTags?.filter((id) => id !== e.id)
                  );
                }
              }}
            />
          </Flex>
        ),
        width: 100,
      },

      {
        name: "Nazwa",
        value: (e) => e.name,
        width: 120,
      },
      {
        name: "Typ",
        value: (e) => (
          <Box>{objectTypeTranslation[e.objectType] || e.objectType}</Box>
        ),
        width: 170,
      },
      {
        name: "Opcje",
        value: (e) => (
          <Dropdown items={dropdownItems}>
            <IconOptions />
          </Dropdown>
        ),
        width: 70,
      },
    ],
    [checkedTags, dropdownItems, setCheckedTags]
  );

  return (
    <div>
      <Header ml={5}>{title}</Header>
      <TagTableWrapper flexDirection={"column"} gap={16}>
        <CustomTable
          showLongText
          columnList={columns}
          data={tags}
          colVis={colVis}
          clickTableRow={(tag) => {
            setChosenTag(tag);
          }}
          windowHeight={false}
        />
      </TagTableWrapper>
    </div>
  );
};
