import { Box } from "components/Box";
import { Card } from "components/Card";
import styled from "styled-components";
import MultiSelect from "react-select";

export const TableCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  box-shadow: unset;
`;
export const Filters = styled.div<{ itemsInRowNumber: number }>`
  ${({ theme, itemsInRowNumber }) => `
    display: grid;
    grid-template-columns: repeat(${itemsInRowNumber}, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[2]}px;
    &:not(:last-child) {
      margin-bottom: ${theme.space[2]}px;
    }
  `};
`;
export const StyledMultiSelect = styled(MultiSelect)`
  width: 100%;
`;
export const WrapperFixedButtons = styled(Box)`
  position: fixed;
  z-index: 2;
  width: 256px;
  background-color: #fff;
`;
export const TableWrapper = styled.div`
  position: relative;
  height: 100%;
  .tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
