import { PageLayout } from "components/PageLayout";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import React, { FC } from "react";
import { Card } from "components/Card";
import { Box } from "../../components/Box";
import styled from "styled-components";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DictionaryTable } from "../../containers/Dictionary/DictionaryTable";
import {
  DICTIONARY_IZS_URL,
  DICTIONARY_LINES_URL,
  DICTIONARY_UNIT_COSTS_DICT,
  DISRUPTION_PARTNERS_URL
} from "../../constants/path";

const StyledBox = styled(Box)`
  height: calc(100vh - 120px);
  overflow: auto;
  overscroll-behavior: contain;
`;

const DictionaryView: FC = () => {
  const { setTableData } = useTableSidebarDispatchContext();

  React.useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, [setTableData]);

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <Card>
        <Tabs>
          <TabList>
            <Tab>IZs</Tab>
            <Tab>Linie</Tab>
            <Tab>Partnerzy</Tab>
            <Tab>Koszty</Tab>
          </TabList>

          <StyledBox px={8} py={6}>
             <TabPanel>
              <DictionaryTable dictionary="izs" url={DICTIONARY_IZS_URL} />
            </TabPanel>
            <TabPanel>
              <DictionaryTable dictionary="lines" url={DICTIONARY_LINES_URL} />
            </TabPanel>
            <TabPanel>
              <DictionaryTable dictionary="partners" url={DISRUPTION_PARTNERS_URL} />
            </TabPanel>
            <TabPanel>
              <DictionaryTable dictionary="unit_costs" url={DICTIONARY_UNIT_COSTS_DICT} />
            </TabPanel>
          </StyledBox>
        </Tabs>
      </Card>
    </PageLayout>
  );
};

export default DictionaryView;
