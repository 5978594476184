import React from "react";
import { useForm } from "react-hook-form";
import { Input } from "components/Input";
import styled from "styled-components";
import { Flex } from "components/Flex";
import { IPayment, IDictionary } from "services/tasks";
import { Button } from "components/Button";
import { Select } from "components/Select";
import { Box } from "components/Box";

interface IPaymentCreate {
  submit: (data: IPayment) => void;
  handleCancelCreatePayment: () => void;
  paymentScope: IDictionary[];
  paymentTask: IDictionary[];
  editPayment: IPayment;
}

const Grid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

export const PaymentCreate: React.FC<IPaymentCreate> = ({
  submit,
  handleCancelCreatePayment,
  paymentScope,
  paymentTask,
  editPayment,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: editPayment,
  });

  const onsubmit = (data: IPayment) => {
    const payload = {
      id: editPayment?.id || "",
      mrwNumber: data.mrwNumber,
      paymentTask: data.paymentTask,
      scope: data.scope,
      lumpSumAmount: Number(data.lumpSumAmount),
      settledAmount: Number(data.settledAmount),
      remainingAmount: Number(data.remainingAmount),
      paymentDateRelations: [
        {
          name: "delivery_date",
          dateTaskCode: data.delivery_date,
        },
        {
          name: "launch_date",
          dateTaskCode: data.launch_date,
        },
        {
          name: "project_date",
          dateTaskCode: data.project_date,
        },
        {
          name: "cwe_date",
          dateTaskCode: data.cwe_date,
        },
        {
          name: "utk_date",
          dateTaskCode: data.utk_date,
        },
      ],
    };
    submit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onsubmit)}>
      <Flex m={2} justifyContent="end">
        <Button bordered>{editPayment ? "Zapisz" : "Dodaj"}</Button>
        <Box ml={4}>
          <Button type="button" bordered onClick={handleCancelCreatePayment}>
            Anuluj
          </Button>
        </Box>
      </Flex>
      <Grid>
        <Input label="MRW" {...register("mrwNumber")} />

        <Select label="Opis zadania" {...register("paymentTask")}>
          <option value=""></option>
          {paymentTask.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Select>
        <Select label="Opis zakresu" {...register("scope")}>
          <option value=""></option>
          {paymentScope.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Select>
        <Input
          label="Ustalona kwota ryczałtowa"
          type="number"
          step="0.1"
          {...register("lumpSumAmount")}
        />
        <Input
          label="Rozliczono"
          type="number"
          step="0.1"
          {...register("settledAmount")}
        />
        <Input
          label="Zostaje do zapłaty"
          type="number"
          step="0.1"
          {...register("remainingAmount")}
        />
        <Input
          label="Powiązanie HRF suffix taska Data dostarczenia"
          {...register("delivery_date")}
        />
        <Input
          label="Powiązanie HRF suffix taska Data startu"
          {...register("launch_date")}
        />
        <Input
          label="Powiązanie HRF suffix taska projekt"
          {...register("project_date")}
        />
        <Input
          label="Powiązanie HRF suffix taska CWE"
          {...register("cwe_date")}
        />
        <Input
          label="Powiązanie HRF suffix taska UTK"
          {...register("utk_date")}
        />
      </Grid>
    </form>
  );
};
