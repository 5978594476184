/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from "components/Box";

import { PageLayout } from "components/PageLayout";
import { Table } from "components/Table";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { fetchAPI } from "utilities/fetchAPI";
import {
  fetchTaskDetails,
  fetchTaskMetaList,
  fetchTasks,
} from "services/tasks";
import { ITask, TaskType } from "types/task";
import { useForm } from "react-hook-form";
import { useFetchDictionaryIzs } from "hooks/fetchDictionaryIzs";
import { useFetchPartners } from "hooks/fetchPartners";
import { TableType } from "types/tableType";

import { Flex } from "components/Flex";
import { useColumnsData } from "hooks/columnsData";
import {
  IFilters,
  useTasksDispatchContext,
  useTasksStateContext,
} from "contexts/TasksContext";
import {
  useTableSidebarContext,
  useTableSidebarDispatchContext,
} from "contexts/TableSidebarContext";
import qs from "qs";
import { Loader } from "components/Loader/Loader";
import { TableCard, TableWrapper } from "./TaskType.styled";
import { urlToPageTitleMap } from "./TaskType.constants";
import TasksFilter from "./components/TasksFilter/TasksFilter";
import { CustomTable } from "./../../components/CustomTable";
import styled from "styled-components";
import { ITaskDetails } from "../../types/taskDetails";
import { IDisruption } from "../../types/disruption";
import { fetchDisruption } from "../../services/disruptions";

import {
  orrColumnList,
  tunelColumnList,
  fdsColumnList,
  otkColumnList,
  oszColumnList,
  ocColumnList,
  milestoneColumnList,
  railwayLinesColumnList,
  certyfikacjaWEColumnList,
  certyfikacjaUTKColumnList,
  ReadyToWEColumnList,
  OtherTaskColumnList,
} from "../TableColumnDef";
import { ReportsDownload } from "components/ReportsDownload/ReportsDownload";
import { useFetchContractors } from "hooks/fetchContractors";
import { fetchTags } from "services/tags";

// Task name to object name mapping
const taskNameToObject = {
  "OR,ORR": { columnList: orrColumnList },
  Tunel: { columnList: tunelColumnList },
  FDS: { columnList: fdsColumnList },
  OTK: { columnList: otkColumnList },
  OSZ: { columnList: oszColumnList },
  OC: { columnList: ocColumnList },
  milestone: { columnList: milestoneColumnList },
  "railway-lines": { columnList: railwayLinesColumnList },
  CertyfikacjaWE: { columnList: certyfikacjaWEColumnList },
  Certyfikacja: { columnList: certyfikacjaUTKColumnList },
  ReadyToWE: { columnList: ReadyToWEColumnList },
  OtherTask: { columnList: OtherTaskColumnList },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TasksTypeView = () => {
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [optionsIzs, setOptionsIzs] = useState([{ label: "", value: "" }]);
  const [optionsTags, setOptionsTags] =
    useState<{ label: string; value: string }[]>(null);
  const [optionsCompanies, setOptionsCompanies] = useState([
    { label: "", value: "" },
  ]);
  const [optionsContractorsCW, setOptionsContractorsCW] = useState([
    { label: "", value: "" },
  ]);
  const [optionsContractorsSA, setOptionsContractorsSA] = useState([
    { label: "", value: "" },
  ]);

  const [totalCount, setTotalCount] = useState(0);
  const [offsetTable, setOffsetTable] = useState(0);
  const [requestPending, setRequestPending] = useState(false);

  const { type: typeParam } = useParams<{ type: string }>();
  const history = useHistory();

  const { filters, initialFilters } = useTasksStateContext();
  const { setFilters, cleanFilters } = useTasksDispatchContext();
  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();

  const { register, control, watch, setValue, reset, handleSubmit } =
    useForm<IFilters>();

  const taskTypes = typeParam.split(",") as Array<TaskType>;

  const initialTasksFetchCount = 10;

  const { dictionaryIzs } = useFetchDictionaryIzs();
  const { dictionaryCompanies } = useFetchPartners();
  const { dictionaryContractors } = useFetchContractors();

  const { columns } = useColumnsData(typeParam);

  // Custom table data
  const [colVisOrr, setColVisOrr] = useState(
    Array(orrColumnList.length).fill(true)
  );

  // Table state
  const [totalFilteredCount, setTotalFilteredCount] = useState(0);
  const [tableOffset, setTableOffset] = useState(0);
  // const [columnList, setColumnList] = useState(tableCellDefinition);
  const [tablePageOffset, setTablePageOffset] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(50);

  // Pagination state
  const [tableTotalPages, setTableTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [maxSegmentNumber, setMaxSegmentNumber] = useState(0);
  const [currentPageSegment, setCurrentPageSegment] = useState(0);
  const [selectedRow, setSelectedRow] = useState("");

  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  // Column state
  const [colVis, setColVis] = useState(Array(21).fill(true));
  const [colNames, setColNames] = useState(0);
  const [columnNames, setColumnNames] = useState<Array<string>>([""]);

  // Infinite scroll
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  43;

  const updateTableData = (colnames: Array<any>) => {
    const Filters = () => (
      <TasksFilter
        watcher={watcher}
        setValue={setValue}
        optionsCompanies={optionsCompanies}
        optionsContractorsCW={optionsContractorsCW}
        optionsContractorsSA={optionsContractorsSA}
        optionsIzs={optionsIzs}
        optionsTags={optionsTags}
        handleSubmit={handleSubmit}
        control={control}
        onSubmit={onSubmit}
        register={register}
        cancelFilters={cancelFilters}
        typeParam={typeParam}
      />
    );

    setTableData({
      tableName: typeParam.replace(/[\W_]+/g, ""), // tableName, // "disruptions",
      columnVisibility: [], //colVis,
      changeColVis: (vis: Array<boolean>) => {
        setColVis(vis);
      },
      renewSidebar: () => {
        setColNames(Math.random());
      },
      columnNames: colnames, // columnNames,
      hiddenSidebar: false,
      detailsObject: true, // display sidebar tabs
      isOpen: true,
      FilterComponent: Filters,
    });
  };

  const handleLoadTags = async () => {
    const { data } = await fetchTags({
      objectType: typeParam === "OR,ORR" ? "OR" : typeParam,
    });

    if (data) {
      const arr: any = [];
      data
        .filter((val) => val.active === true)
        .forEach(({ id, name }) =>
          arr.push({
            value: id,
            label: name,
          })
        );
      setOptionsTags(arr);
    }
  };

  useEffect(() => {
    handleLoadTags();
  }, [typeParam]);

  useLayoutEffect(() => {
    if (tableOffset) loadMoreData();
  }, [tableOffset]);

  useLayoutEffect(() => {
    if (isLoadingNext) {
      const nextPageNumber = pageNumber + 1;
      setTablePageOffset(nextPageNumber * tablePageSize);
      setTableOffset(nextPageNumber * tablePageSize);
      setPageNumber(nextPageNumber);
    }
  }, [isLoadingNext]);

  const watcher = watch();
  const {
    id,
    search,
    comment,
    company,
    contractorSA,
    contractorCW,
    type,
    chainage,
    iz,
    tags,
    active,
    inHRF,
    hasError,
    chainageFrom,
    chainageTo,
    chainageStartFrom,
    chainageEndTo,
    completionPercentageFrom,
    completionPercentageTo,
    lineNumber,
    completion,
    locationName,
    startDateFrom,
    startDateTo,
    endDateFrom,
    endDateTo,
    settledPercentageFrom,
    settledPercentageTo,
    subtaskPraceProjektoweStartDateFrom,
    subtaskPraceProjektoweStartDateTo,
    subtaskPraceProjektoweEndDateFrom,
    subtaskPraceProjektoweEndDateTo,
    subtaskDULICPStartDateFrom,
    subtaskDULICPStartDateTo,
    subtaskDULICPEndDateFrom,
    subtaskDULICPEndDateTo,
    subtaskPnBStartDateFrom,
    subtaskPnBStartDateTo,
    subtaskPnBEndDateFrom,
    subtaskPnBEndDateTo,
    subtaskPraceBudowlaneStartDateFrom,
    subtaskPraceBudowlaneStartDateTo,
    subtaskPraceBudowlaneEndDateFrom,
    subtaskPraceBudowlaneEndDateTo,
    subtaskPradNaObiekcieStartDateFrom,
    subtaskPradNaObiekcieStartDateTo,
    subtaskPradNaObiekcieEndDateFrom,
    subtaskPradNaObiekcieEndDateTo,
    subtaskZagospodarowanieStartDateFrom,
    subtaskZagospodarowanieStartDateTo,
    subtaskZagospodarowanieEndDateFrom,
    subtaskZagospodarowanieEndDateTo,
    subtaskWdrozenieStartDateFrom,
    subtaskWdrozenieStartDateTo,
    subtaskWdrozenieEndDateFrom,
    subtaskWdrozenieEndDateTo,
    subtaskUruchomienieStartDateFrom,
    subtaskUruchomienieStartDateTo,
    subtaskUruchomienieEndDateFrom,
    subtaskUruchomienieEndDateTo,
    taskMetaActualMontazWiezyEndDateFrom,
    taskMetaActualMontazWiezyEndDateTo,
    taskMetaForecastMontazWiezyEndDateFrom,
    taskMetaForecastMontazWiezyEndDateTo,
  } = watcher;

  const retrieveTasks = (customOffset?: number) => {
    if (
      taskTypes.every((typeParam) =>
        Object.values(TaskType).includes(typeParam as TaskType)
      )
    ) {
      setRequestPending(true);
      fetchTasks({
        filters: {
          id,
          search,
          company,
          comment,
          contractorSA,
          contractorCW,
          type,
          chainage,
          iz,
          tags,
          active,
          inHRF,
          chainageFrom,
          chainageTo,
          chainageStartFrom,
          chainageEndTo,
          completionPercentageFrom,
          completionPercentageTo,
          hasError,
          lineNumber,
          completion,
          locationName,
          startDateFrom,
          startDateTo,
          endDateFrom,
          endDateTo,
          settledPercentageFrom,
          settledPercentageTo,
          subtaskPraceProjektoweStartDateFrom,
          subtaskPraceProjektoweStartDateTo,
          subtaskPraceProjektoweEndDateFrom,
          subtaskPraceProjektoweEndDateTo,
          subtaskDULICPStartDateFrom,
          subtaskDULICPStartDateTo,
          subtaskDULICPEndDateFrom,
          subtaskDULICPEndDateTo,
          subtaskPnBStartDateFrom,
          subtaskPnBStartDateTo,
          subtaskPnBEndDateFrom,
          subtaskPnBEndDateTo,
          subtaskPraceBudowlaneStartDateFrom,
          subtaskPraceBudowlaneStartDateTo,
          subtaskPraceBudowlaneEndDateFrom,
          subtaskPraceBudowlaneEndDateTo,
          subtaskPradNaObiekcieStartDateFrom,
          subtaskPradNaObiekcieStartDateTo,
          subtaskPradNaObiekcieEndDateFrom,
          subtaskPradNaObiekcieEndDateTo,
          subtaskZagospodarowanieStartDateFrom,
          subtaskZagospodarowanieStartDateTo,
          subtaskZagospodarowanieEndDateFrom,
          subtaskZagospodarowanieEndDateTo,
          subtaskWdrozenieStartDateFrom,
          subtaskWdrozenieStartDateTo,
          subtaskWdrozenieEndDateFrom,
          subtaskWdrozenieEndDateTo,
          subtaskUruchomienieStartDateFrom,
          subtaskUruchomienieStartDateTo,
          subtaskUruchomienieEndDateFrom,
          subtaskUruchomienieEndDateTo,
          taskMetaActualMontazWiezyEndDateFrom,
          taskMetaActualMontazWiezyEndDateTo,
          taskMetaForecastMontazWiezyEndDateFrom,
          taskMetaForecastMontazWiezyEndDateTo,
        },
        type: taskTypes,
        limit: tablePageSize,
        offset: customOffset !== undefined ? customOffset : tableOffset,
      })
        .then((response) => {
          const { countFiltered } = response.pagination;
          setTotalCount(countFiltered);
          setTasks(response.data);

          updateTableData(columnNames);
          setRequestPending(false);
        })
        .catch(() => {
          setTasks([]);
        });
    } else {
      console.error(`At least one of the task types is incorrect`);
    }
  };

  const loadMoreData = async () => {
    if (
      taskTypes.every((typeParam) =>
        Object.values(TaskType).includes(typeParam as TaskType)
      )
    ) {
      const response = await fetchTasks({
        filters: {
          id,
          search,
          company,
          type,
          chainage,
          iz,
          tags,
          active,
          inHRF,
          chainageFrom,
          chainageTo,
          chainageStartFrom,
          chainageEndTo,
          completionPercentageFrom,
          completionPercentageTo,
          hasError,
          lineNumber,
          completion,
          locationName,
          startDateFrom,
          startDateTo,
          endDateFrom,
          endDateTo,
          settledPercentageFrom,
          settledPercentageTo,
          subtaskPraceProjektoweStartDateFrom,
          subtaskPraceProjektoweStartDateTo,
          subtaskPraceProjektoweEndDateFrom,
          subtaskPraceProjektoweEndDateTo,
          subtaskDULICPStartDateFrom,
          subtaskDULICPStartDateTo,
          subtaskDULICPEndDateFrom,
          subtaskDULICPEndDateTo,
          subtaskPnBStartDateFrom,
          subtaskPnBStartDateTo,
          subtaskPnBEndDateFrom,
          subtaskPnBEndDateTo,
          subtaskPraceBudowlaneStartDateFrom,
          subtaskPraceBudowlaneStartDateTo,
          subtaskPraceBudowlaneEndDateFrom,
          subtaskPraceBudowlaneEndDateTo,
          subtaskPradNaObiekcieStartDateFrom,
          subtaskPradNaObiekcieStartDateTo,
          subtaskPradNaObiekcieEndDateFrom,
          subtaskPradNaObiekcieEndDateTo,
          subtaskZagospodarowanieStartDateFrom,
          subtaskZagospodarowanieStartDateTo,
          subtaskZagospodarowanieEndDateFrom,
          subtaskZagospodarowanieEndDateTo,
          subtaskWdrozenieStartDateFrom,
          subtaskWdrozenieStartDateTo,
          subtaskWdrozenieEndDateFrom,
          subtaskWdrozenieEndDateTo,
          subtaskUruchomienieStartDateFrom,
          subtaskUruchomienieStartDateTo,
          subtaskUruchomienieEndDateFrom,
          subtaskUruchomienieEndDateTo,
          taskMetaActualMontazWiezyEndDateFrom,
          taskMetaActualMontazWiezyEndDateTo,
          taskMetaForecastMontazWiezyEndDateFrom,
          taskMetaForecastMontazWiezyEndDateTo,
        },
        type: taskTypes,
        limit: tablePageSize,
        offset: tableOffset,
      });

      // After this "tasks"
      // will contain updated list
      if (tasks && response.data) {
        Array.prototype.push.apply(tasks, response.data);
      }

      setTasks(tasks);

      if (response.pagination) {
        const { countFiltered } = response.pagination;
        setTotalCount(countFiltered);
      }

      setIsLoadingNext(false);
    } else {
      console.error(`At least one of the task types is incorrect`);
    }
  };

  const generateFiltersForStorage = (data: IFilters) => {
    localStorage.setItem(`${typeParam}_filters`, JSON.stringify(data));
  };

  const onSubmit = (data: IFilters) => {
    setTableOffset(0);
    setPageNumber(0);
    setFilters(data);
    generateFiltersForStorage(data);
  };

  const cancelFilters = () => {
    reset(initialFilters);
    cleanFilters();
    localStorage.setItem(
      `${typeParam}_filters`,
      JSON.stringify(initialFilters)
    );
  };

  /*
  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetTable]);
  */

  useEffect(() => {
    const cols = Object.entries(taskNameToObject).filter(
      (obj, i) => obj[0] === typeParam
    )[0];
    const CustomColumns =
      cols && cols[1].columnList ? cols[1].columnList : null;

    setColumnNames(CustomColumns?.map((entry: any) => entry.name));
  }, [typeParam]);

  useEffect(() => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringify(
        { filter: { ...watcher } },
        {
          arrayFormat: "brackets",
        }
      ),
    });
    retrieveTasks(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    optionsIzs,
    optionsTags,
    columnNames,
    optionsCompanies,
    optionsContractorsSA,
    optionsContractorsCW,
  ]);

  const setSelectOptions = (options, setter) => {
    if (options) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const arr: any = [];
      options
        .filter(({ status }) => status)
        .forEach(({ name }) =>
          arr.push({
            value: name,
            label: name,
          })
        );
      setter(arr);
    }
  };

  useEffect(() => {
    setSelectOptions(dictionaryIzs, setOptionsIzs);
  }, [dictionaryIzs]);

  useEffect(() => {
    setSelectOptions(dictionaryCompanies, setOptionsCompanies);
  }, [dictionaryCompanies]);

  useEffect(() => {
    setOptionsContractorsCW(
      dictionaryContractors?.CW?.map((optionContractor) => ({
        label: optionContractor,
        value: optionContractor,
      }))
    );

    setOptionsContractorsSA(
      dictionaryContractors?.SA?.map((optionContractor) => ({
        label: optionContractor,
        value: optionContractor,
      }))
    );
  }, [dictionaryContractors]);

  useEffect(() => {
    const storage = localStorage.getItem(`${typeParam}_filters`);
    if (storage) {
      const val = JSON.parse(storage);
      reset(val);
      setFilters(val);
    } else {
      reset(initialFilters);
      cleanFilters();
      retrieveTasks();
    }
    setOffsetTable(0);
    setTableOffset(0);
    setTimeout(() => {
      // setTableData({ hiddenSidebar: false, onlyFilters: false });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeParam, columnNames]);

  useEffect(() => {
    setTimeout(() => {
      setTableData({
        hiddenSidebar: false,
        onlyFilters: false,
        detailsObject: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNextPage = async (startIndex: number, stopIndex: number) => {
    if (
      taskTypes.every((typeParam) =>
        Object.values(TaskType).includes(typeParam as TaskType)
      )
    ) {
      setRequestPending(true);
      setIsNextPageLoading(true);
      const response = await fetchTasks({
        type: taskTypes,
        offset: startIndex,
        limit: stopIndex - startIndex + 1,
      });

      setTasks((prevTasks) => {
        const newTasks = [...prevTasks];
        for (let index = 0; index + startIndex <= stopIndex; index++) {
          newTasks[index + startIndex] = response.data[index];
        }
        return newTasks;
      });

      setIsNextPageLoading(false);
    } else {
      console.error(`At least one of the task types is incorrect`);
    }
  };

  const totalDevided = Math.floor(totalCount / 16);

  const shouldScrollUp = () => {
    if (offsetTable > 16) {
      return () => setOffsetTable((prev) => prev - 16);
    } else if (offsetTable < 16 && offsetTable > 0) {
      return () => setOffsetTable(0);
    }
    return undefined;
  };

  const shouldScrollDown = () => {
    if (offsetTable / 16 < totalDevided - 1) {
      return () => setOffsetTable((prev) => prev + 16);
    } else if (offsetTable / 16 === totalDevided - 1) {
      return () => setOffsetTable(() => totalCount - 16);
    }
    return undefined;
  };

  const onScrollUp = shouldScrollUp();
  const onScrollDown = shouldScrollDown();

  //const retrieveTaskDetails = async (data?: IFilters) => {

  const [taskDetails, setTaskDetails] = useState<ITaskDetails>();

  const retrieveTaskDetails = async () => {
    const id = "c05dcdd4-d924-4f05-b181-2df2b143e8af";
    //const taskdets = await fetchTaskDetails({taskId:id})
    //const metalist = await fetchTaskMetaList(id)
    //console.log('taskdets=',taskdets)
    //console.log('metalist=',metalist)
    //setTaskDetails(taskdets)
  };

  useEffect(() => {
    retrieveTaskDetails();
    setTimeout(() => {
      setTableData({
        ...tableData,
        isOpen: false,
        hiddenSidebar: true,
        detailsObject: true,
      });
    });
  }, []);

  useEffect(() => {}, [taskDetails]);

  const clickTaskTableRow = ({ id }, typeParam: string) => {
    history.push(`/objects/${typeParam}/?selected=${id}`);

    // Show loading animation in sidebar
    setTableData({
      ...tableData,
      dataPayload: {}, // this hides last loaded data
      isOpen: true,
      isLoading: true,
      detailsObject: true,
    });

    // Get Task Data
    typeParam in taskNameToObject
      ? fetchTaskDetails({
          taskId: id,
        }).then((taskdetails: ITaskDetails) => {
          // Update in sidebar
          setTableData({
            ...tableData,
            detailsObject: true,
            dataId: id,
            dataPayload: taskdetails,
            tableType: TableType.TASK_LIST,
            isLoading: false,
            isOpen: true,
          });
        })
      : null;
  };

  // Todo 1~ limit to vertical scroll
  // Todo 2~ calculate based on %, not 800px
  const onTableScroll = (event: any, ref: any) => {
    const top = event.target.scrollTop;
    const totalHeight = ref.current.scrollHeight - ref.current.clientHeight;
    const percent = ((top / totalHeight) * 100).toFixed(0);
    if (percent === "100") setIsLoadingNext(true);
  };

  // Choose column list object based on task name
  const cols = Object.entries(taskNameToObject).filter(
    (obj, i) => obj[0] === typeParam
  )[0];

  const customColumnList =
    cols && cols[1].columnList ? cols[1].columnList : null;

  return (
    <PageLayout>
      <Box mx={5}>
        <Flex justifyContent="space-between" alignItems="center">
          <h3>
            {urlToPageTitleMap[typeParam as TaskType]} - {totalCount}{" "}
            {requestPending ? (
              " - ładowanie..."
            ) : isLoadingNext ? (
              <div
                style={{
                  position: "relative",
                  height: 24,
                  display: "inline-block",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    position: "absolute",
                    top: 2,
                    left: 6,
                    transform: "scale(0.75)",
                  }}
                >
                  <Loader />
                </div>
              </div>
            ) : (
              ""
            )}
          </h3>

          <ReportsDownload
            type="task"
            taskType={typeParam.split(",")[0]}
            colVis={colVis}
            tableCellDefinition={customColumnList}
            filters={{
              id,
              search,
              comment,
              company,
              contractorSA,
              contractorCW,
              type,
              chainage,
              iz,
              tags,
              active,
              inHRF,
              hasError,
              chainageFrom,
              chainageTo,
              chainageStartFrom,
              chainageEndTo,
              completionPercentageFrom,
              completionPercentageTo,
              lineNumber,
              completion,
              locationName,
              startDateFrom,
              startDateTo,
              endDateFrom,
              endDateTo,
              settledPercentageFrom,
              settledPercentageTo,
              subtaskPraceProjektoweStartDateFrom,
              subtaskPraceProjektoweStartDateTo,
              subtaskPraceProjektoweEndDateFrom,
              subtaskPraceProjektoweEndDateTo,
              subtaskDULICPStartDateFrom,
              subtaskDULICPStartDateTo,
              subtaskDULICPEndDateFrom,
              subtaskDULICPEndDateTo,
              subtaskPnBStartDateFrom,
              subtaskPnBStartDateTo,
              subtaskPnBEndDateFrom,
              subtaskPnBEndDateTo,
              subtaskPraceBudowlaneStartDateFrom,
              subtaskPraceBudowlaneStartDateTo,
              subtaskPraceBudowlaneEndDateFrom,
              subtaskPraceBudowlaneEndDateTo,
              subtaskPradNaObiekcieStartDateFrom,
              subtaskPradNaObiekcieStartDateTo,
              subtaskPradNaObiekcieEndDateFrom,
              subtaskPradNaObiekcieEndDateTo,
              subtaskZagospodarowanieStartDateFrom,
              subtaskZagospodarowanieStartDateTo,
              subtaskZagospodarowanieEndDateFrom,
              subtaskZagospodarowanieEndDateTo,
              subtaskWdrozenieStartDateFrom,
              subtaskWdrozenieStartDateTo,
              subtaskWdrozenieEndDateFrom,
              subtaskWdrozenieEndDateTo,
              subtaskUruchomienieStartDateFrom,
              subtaskUruchomienieStartDateTo,
              subtaskUruchomienieEndDateFrom,
              subtaskUruchomienieEndDateTo,
              taskMetaActualMontazWiezyEndDateFrom,
              taskMetaActualMontazWiezyEndDateTo,
              taskMetaForecastMontazWiezyEndDateFrom,
              taskMetaForecastMontazWiezyEndDateTo,
            }}
          />
        </Flex>
      </Box>
      <TableCard style={{ background: "transparent" }}>
        {!requestPending && tasks && tasks.length > 0 ? (
          typeParam in taskNameToObject ? (
            <CustomTable
              columnList={customColumnList}
              data={tasks}
              colVis={colVis}
              onTableScroll={onTableScroll}
              typeParam={typeParam} // "OR,ORR", "OTK", etc.
              clickTableRow={clickTaskTableRow}
            />
          ) : (
            <TableWrapper>
              <Table<ITask>
                key={history.location.pathname}
                columns={columns}
                data={tasks}
                onRowClick={(row) => {
                  history.push(
                    `/objects/${typeParam}/?selected=${row?.original?.id}`
                  );
                }}
                fetchRowData={fetchTaskDetails}
                loadNextPage={loadNextPage}
                isNextPageLoading={isNextPageLoading}
                tableType={TableType.TASK_LIST}
                FilterComponent={() => (
                  <TasksFilter
                    optionsCompanies={optionsCompanies}
                    optionsContractorsCW={optionsContractorsCW}
                    optionsContractorsSA={optionsContractorsSA}
                    watcher={watcher}
                    optionsIzs={optionsIzs}
                    optionsTags={optionsTags}
                    setValue={setValue}
                    handleSubmit={handleSubmit}
                    control={control}
                    onSubmit={onSubmit}
                    register={register}
                    cancelFilters={cancelFilters}
                    typeParam={typeParam}
                  />
                )}
                onScrollUp={onScrollUp}
                onScrollDown={onScrollDown}
              />
            </TableWrapper>
          )
        ) : (
          <Box mt={20}>
            {requestPending ? (
              <Loader />
            ) : (
              <Flex justifyContent="center" alignItems="center">
                Brak obiektów
              </Flex>
            )}
          </Box>
        )}
      </TableCard>
    </PageLayout>
  );
};
