import * as yup from "yup";

export const relatedSchema = yup.object().shape({
  duration: yup.number().required(),
  object: yup.string().required(),
  type: yup.string().required()
})

export const connectToCertificationSchema = yup.object().shape({
  duration: yup.number().required(),
  taskId: yup.string().required(),
  type: yup.string().required()
})
