import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Box } from "components/Box";
import { IUser, IDisruptionHistory } from "types/disruption";
import { Card } from "components/Card";
import { Table } from "components/Table";
import { Column } from "react-table";
import { formatDate } from "utilities/formatDate";
import { fetchDisruptionHistory } from "services/disruptions";
import { parseISO } from "date-fns";
import format from "date-fns/format";

type IChanges = {
  name: string;
  oldValue: string;
  newValue: string;
  changedBy: string;
  newValueIsDate: boolean;
  oldValueIsDate: boolean;
};
interface ITimelineElement {
  date: string;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
const Line = styled.div`
  position: absolute;
  top: 0;
  left: 6px;
  height: 100%;
  width: 2px;
  background-color: rgba(30, 46, 102, 0.1);
`;
const DateText = styled.p`
  position: absolute;
  left: 20px;
  margin: 0;
`;
const TimeElement = styled.div<ITimelineElement>`
  ${({ theme }) => `
		position: relative;
		padding: 16px;
		margin-left: 100px;
		margin-bottom: 20px;
    padding-top: 70px;

		&::before {
			content: '';
			position: absolute;
			top: 3px;
			left: -100px;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background-color: ${theme.palette.primary};
			border: 1px solid ${theme.palette.primary};
		`}
`;

interface IDisruptionTimeline {
  disruptionId: string;
}

const translateNameField = (name: string) => {
  switch (name) {
    case "id":
      return "id";
    case "costType":
      return "Rodzaj kosztów";
    case "units":
      return "Ilość jednostek";
    case "costEstimation":
      return "Wycena";
    case "sum":
      return "Suma kosztów";
    case "documentationType":
      return "Sposób dokumentacji";
    case "comment":
      return "Komentarz";
    case "partner":
      return "Partner";
    case "overheadCost":
      return "Koszta Ogólne";
    case "name":
      return "Nazwa";
    case "description":
      return "Opis";
    case "number":
      return "Numer";
    case "submissionDate":
      return "Data pisma";
    case "endDocument":
      return "Nr pisma";
    case "category":
      return "Kategoryzacja";
    case "submitingEntity":
      return "Zgłaszający";
    case "startDocument":
      return "Nr pisma - pow. o roszczeniu";
    case "assignedUser":
      return "Przypisany";
    case "continuation":
      return "Kontynuacja";
    case "code":
      return "CSC";
    case "updatedAt":
      return "Zaktualizowano";
    case "startDate":
      return "Data rozpoczęcia";
    case "endDocumentSubmissionDate":
      return "Data pisma";
    case "affectsEntities":
      return "Wpływ na innego partnera";
    case "affectedEntities":
      return "Możliwy wpływ na partnera";
    case "submittingEntity":
      return "Zgłaszający";
    case "status":
      return "Status";
    default:
      return "---";
  }
};

const renderCellForValue = (item: any) => {
  const timestamp = Date.parse(item.row?.original?.oldValue);
  if (isNaN(timestamp) === false && timestamp > 0) {
    return formatDate(timestamp);
  } else {
    return item.row?.original?.oldValue;
  }
};

export const DisruptionTimeline: React.FC<IDisruptionTimeline> = ({
  disruptionId,
}) => {
  const [timelineData, setTimelineData] = useState<IDisruptionHistory[]>([]);
  const columns: Array<Column<IChanges>> = useMemo(
    () => [
      {
        Header: "Pole",
        accessor: "name",
        Cell: (item) => translateNameField(item.row.original.name),
        disableSortBy: true,
        width: 60,
      },
      {
        Header: "Stara wartość",
        accessor: "oldValue",
        Cell: (item) =>
          item.row.original.oldValueIsDate
            ? formatDate(Date.parse(item.row?.original?.oldValue))
            : item.row.original.oldValue
            ? `${item.row.original.oldValue}`
            : "---",
        disableSortBy: true,
      },
      {
        Header: "Nowa wartość",
        accessor: "newValue",
        Cell: (item) =>
          item.row.original.newValueIsDate
            ? formatDate(Date.parse(item.row?.original?.newValue))
            : item.row.original.newValue
            ? `${item.row.original.newValue}`
            : "---",
        disableSortBy: true,
      },
    ],
    []
  );

  const retrieveTimeline = async () => {
    const fromDate = new Date("01,01,1990");
    const toDate = new Date();
    const response = await fetchDisruptionHistory(
      disruptionId,
      formatDate(fromDate),
      formatDate(toDate)
    );

    // todo check response for error from BE
    if (response.length === undefined) return;
    // Hotfix for backend random error in changedProperties
    const filtredData = response.map((item) => {
      const newProperties = item.changedProperties.map(
        ({ name, oldValue, newValue, ...rest }) => {
          if (Array.isArray(oldValue) || Array.isArray(newValue)) {
            return {
              ...rest,
              name,
              newValue: "tablica",
              oldValue: "tablica",
            };
          } else if (
            typeof newValue === "boolean" ||
            typeof oldValue === "boolean"
          ) {
            return {
              ...rest,
              name,
              oldValue: oldValue ? "tak" : "nie",
              newValue: newValue ? "tak" : "nie",
            };
          } else if (
            typeof oldValue === "object" ||
            typeof newValue === "object"
          ) {
            return {
              ...rest,
              name,
              newValue:
                (newValue as unknown as { id: string })?.id || newValue || "--",
              oldValue:
                (oldValue as unknown as { id: string })?.id || oldValue || "--",
            };
          } else {
            return {
              ...rest,
              name,
              oldValue,
              newValue,
            };
          }
        }
      );

      return { ...item, changedProperties: newProperties };
    });
    setTimelineData(filtredData);
  };

  useEffect(() => {
    retrieveTimeline();
  }, [disruptionId]);

  return (
    <Wrapper>
      <Line />
      {timelineData?.map((prop: IDisruptionHistory) => (
        <React.Fragment key={prop.date}>
          <DateText>
            {formatDate(new Date(parseISO(prop.date)))} <br />{" "}
            {format(new Date(parseISO(prop.date)), "HH:mm")} <br />{" "}
            {prop?.user?.email || "brak autora"}
          </DateText>
          <TimeElement key={prop.date} date={prop.date}>
            <Box style={{ width: "90%" }}>
              <Table<IChanges>
                showSidebar={false}
                fullHeight={false}
                disableReactWindow
                columns={columns}
                data={prop.changedProperties}
                getLink={() => ""}
              />
            </Box>
          </TimeElement>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
