import { Button } from "components/Button";
import { Flex } from "components/Flex";
import styled from "styled-components";
import MultiSelect from "react-select";
import { Input } from "components/Input";
import { Box } from "components/Box";

export const Root = styled.div`
  position: relative;
`;

export const WrapperChildren = styled.div`
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => `
    font-size: 15px;
    padding: 10px;
    transition: all .1s ease-in-out;
    &:hover {
        background-color: ${theme.palette.primary};
        color: #fff;
    }
  `}
`;

export const WrapperDropdown = styled.div`
  ${({ theme }) => `
    right: 0;
    position: absolute;
    top: 100%;
    margin-top: ${theme.space[2]}px;
    min-width: 320px;
    z-index: 4;
    border: 1px solid rgba(30,46,102,0.2);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(30,46,102,0.1);
  `}
`;

export const StyledFlex = styled(Flex)`
  width: 100%;
`;

export const StyledBox = styled(Box)`
  width: 100%;
`;

export const StyledMultiSelect = styled(MultiSelect)`
  width: 100%;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin: 0;
`;
