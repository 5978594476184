/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from "components/Box";
import { Card } from "components/Card";
import { PageLayout } from "components/PageLayout";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchRailwayLines } from "services/railwayLines";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Loader } from "components/Loader/Loader";
import {
  IFilters,
  useRailwayLinesDispatchContext,
  useRailwayLinesStateContext,
} from "contexts/RailwayLinesContext";
import { IRailwayLine } from "types/railway-line";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import { Input } from "../components/Input";
import { Label } from "../components/Select";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { CustomTable } from "components/CustomTable";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { TooltipWrapper } from "components/TooltipWrapper";

interface IRailwayLineWithChildren extends IRailwayLine {
  status: string;
  subRows?: IRailwayLineWithChildren[];
  childrenCount: number;
}

const Filters = styled.div<{ itemsInRowNumber: number }>`
  ${({ theme, itemsInRowNumber }) => `
    display: grid;
    grid-template-columns: repeat(${itemsInRowNumber}, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[2]}px;

    &:not(:last-child) {
      margin-bottom: ${theme.space[2]}px;
    }
  `};
`;

const StyledButton = styled(Button)`
  ${({ theme }) => `
    font-size: 15px;
    padding: 10px;
    transition: all .1s ease-in-out;
    &:hover {
      background-color: ${theme.palette.primary};
      color: #fff;
    }
  `}
`;
const TableWrapper = styled.div`
  width: 785px;
  height: 100%;
`;
const WrapperFixedButtons = styled(Box)`
  position: fixed;
  z-index: 2;
  width: 256px;
  background-color: #fff;
`;

const Link = styled.a`
  text-align: center;
  color: gray;
  display: inline-block;
  min-width: 70;
  line-height: 57px;
  text-decoration: none;
`;

export const RailwayLinesView = () => {
  const [colVis, setColVis] = useState(Array(6).fill(true));
  const [requestPending, setRequestPending] = useState<boolean>(true);
  const history = useHistory();
  const [railwayLinesInit, setRailwayLinesInit] = useState<Array<IRailwayLine>>(
    []
  );
  const [railwayLines, setRailwayLines] = useState<
    Array<IRailwayLineWithChildren>
  >([]);
  const [totalCount, setTotalCount] = useState(0);

  const initialTasksFetchCount = 1000;

  const { filters, initialFilters } = useRailwayLinesStateContext();
  const { setFilters, cleanFilters } = useRailwayLinesDispatchContext();
  const { register, watch, reset, handleSubmit } = useForm<IFilters>({
    defaultValues: filters,
    mode: "onTouched",
  });

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const { setTableData } = useTableSidebarDispatchContext();

  const watcher = watch();
  const {
    lineNumber,
    chainageStartFrom,
    chainageStartTo,
    chainageEndFrom,
    chainageEndTo,
    attachments,
  } = watcher;

  const onSubmit = (data: IFilters) => {
    setFilters(data);
    retrieveRailwayLines(data);
  };

  const separateRailwayLinesChildren = (
    railwayLines: Array<IRailwayLineWithChildren>
  ) => {
    const railwayLinesSeparated: IRailwayLineWithChildren[] = [];
    for (let i = 0; i < railwayLines.length; i++) {
      if (!railwayLines[i]) continue;
      const index = railwayLinesSeparated.findIndex(
        (el) => el.lineNumber == railwayLines[i].lineNumber
      );
      if (index === -1) {
        railwayLines[i].chainage = Number(
          (railwayLines[i].chainageEnd - railwayLines[i].chainageStart).toFixed(
            3
          )
        );
        railwayLinesSeparated.push({
          id: `summaryrow`,
          lineNumber: railwayLines[i].lineNumber,
          csc: railwayLines[i].csc,
          chainageStart: railwayLines[i].chainageStart,
          chainageEnd: railwayLines[i].chainageEnd,
          chainage: railwayLines[i].chainage,
          // attachment: childAttachment,
          attachments: [],
          subRows: [
            {
              ...railwayLines[i],
              status: "child" /*attachment: childAttachment*/,
            },
          ],
          childrenCount: 1,
          status: "parent",
        });
        continue;
      }
      railwayLines[i].status = "child";
      railwayLines[i].chainage = Number(
        (railwayLines[i].chainageEnd - railwayLines[i].chainageStart).toFixed(3)
      );

      // @ts-ignore
      railwayLinesSeparated[index].subRows.push(railwayLines[i]);
      railwayLinesSeparated[index].childrenCount += 1;
      railwayLinesSeparated[index].chainage = Number(
        (
          railwayLinesSeparated[index].chainage + railwayLines[i].chainage
        ).toFixed(3)
      );
      railwayLinesSeparated[index].chainageStart =
        railwayLinesSeparated[index].chainageStart <
        railwayLines[i].chainageStart
          ? railwayLinesSeparated[index].chainageStart
          : railwayLines[i].chainageStart;
      railwayLinesSeparated[index].chainageEnd =
        railwayLinesSeparated[index].chainageEnd > railwayLines[i].chainageEnd
          ? railwayLinesSeparated[index].chainageEnd
          : railwayLines[i].chainageEnd;
    }
    railwayLinesSeparated.sort((a, b) => a.lineNumber - b.lineNumber);
    return railwayLinesSeparated;
  };

  const retrieveRailwayLines = async (data?: IFilters) => {
    setRequestPending(true);
    const filter: Partial<IFilters> = {
      lineNumber: data && data.lineNumber,
      attachments: data && data.attachments,
    };
    if (chainageStartFrom) filter.chainageStartFrom = chainageStartFrom;
    if (chainageStartTo) filter.chainageStartTo = chainageStartTo;
    if (chainageEndFrom) filter.chainageEndFrom = chainageEndFrom;
    if (chainageEndTo) filter.chainageEndTo = chainageEndTo;
    const response = await fetchRailwayLines({
      filter,
      limit: initialTasksFetchCount,
    });

    const { countFiltered, count: responseCount } = response.pagination;
    const finalCount = countFiltered || responseCount;

    setRailwayLinesInit(response.data);
    setRailwayLines(
      separateRailwayLinesChildren(response.data as IRailwayLineWithChildren[])
    );
    setTotalCount(finalCount);
    setRequestPending(false);
  };

  const cancelFilters = () => {
    history.push({
      pathname: history.location.pathname,
      search: "",
    });
    retrieveRailwayLines();
    reset(initialFilters);
    cleanFilters();
  };

  const RailwayLinesFilter = () => (
    <Card style={{ marginBottom: "1rem" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box px={4} pb={3}>
          <WrapperFixedButtons mb={5} py={2}>
            <Flex>
              <Button type="submit" bordered>
                Filtruj
              </Button>
              <Box ml={2}>
                <Button onClick={() => cancelFilters()}>Wyczyść</Button>
              </Box>
            </Flex>
          </WrapperFixedButtons>
          <Filters itemsInRowNumber={1}>
            <Box pt={12}>
              <Label>Nr. Linii</Label>
              <Input
                {...register("lineNumber")}
                placeholder=""
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </Box>
            <Box>
              <Label>Km od (od)</Label>
              <Input placeholder="" {...register("chainageStartFrom")} />
            </Box>
            <Box>
              <Label>Km od (do)</Label>
              <Input placeholder="" {...register("chainageStartTo")} />
            </Box>
            <Box>
              <Label>Km do (od)</Label>
              <Input placeholder="" {...register("chainageEndFrom")} />
            </Box>
            <Box>
              <Label>Km do (do)</Label>
              <Input placeholder="" {...register("chainageEndTo")} />
            </Box>
            <Box>
              <Label>Nr. Załącznika</Label>
              <Input placeholder="" {...register("attachments")} />
            </Box>
          </Filters>
        </Box>
      </form>
    </Card>
  );

  useEffect(() => {
    retrieveRailwayLines();
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Nr linii",
        width: 120,
        value: (item) =>
          item.status === "child" ? (
            item?.lineNumber
          ) : (
            <Link href={`/objects/railway-lines/${item?.lineNumber}`}>
              {item.lineNumber}
            </Link>
          ),
      },
      {
        name: "CSC",
        // value: ({ csc }) => csc,
        width: 200,
        value: (item) => {
          if (item.status === "child") return item.csc;
          const cscs: string[] = [];
          item.subRows?.forEach((child) => {
            cscs.push(child.csc);
          });
          return (
            <TooltipWrapper
              content={cscs.filter((el) => !!el.length).join(" / ")}
              left={0}
              width={200}
            >
              {cscs.filter((el) => !!el.length).join(" / ")}
            </TooltipWrapper>
          );
        },
      },
      {
        name: "Km od",
        value: ({ chainageStart }) =>
          chainageStart.toLocaleString("PL", {
            maximumFractionDigits: 3,
          }),
        width: 100,
      },
      {
        name: "Km do",
        value: ({ chainageEnd }) =>
          chainageEnd.toLocaleString("PL", {
            maximumFractionDigits: 3,
          }),
        width: 100,
      },
      {
        name: "długość",
        value: ({ chainage }) =>
          chainage.toLocaleString("PL", {
            maximumFractionDigits: 3,
          }),
        width: 100,
      },
      {
        name: "nr załącznika",
        width: 200,
        value: (item) => {
          if (item.status === "child") return item.attachments.join(" / ");
          const attachments: string[] = [];
          item.subRows?.forEach((child) => {
            child.attachments.forEach(
              (el) => !attachments.includes(el) && attachments.push(el)
            );
          });
          return attachments.filter((el) => !!el.length).join(" / ");
        },
      },
      {
        name: "liczba odcinków",
        value: ({ childrenCount }) => childrenCount,
        width: 130,
      },
    ],
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: false, onlyFilters: true });
    });
  }, []);

  return (
    <PageLayout displayButtonsGroup={false} pageTitleSpace>
      <Box mx={5}>
        <Flex justifyContent="flex-end" alignItems="center">
          <Box mr="auto">
            <h3>LINIE KOLEJOWE - {totalCount}</h3>
          </Box>

          {loggedUserRoles?.includes("ROLE_LINES_OVERALL_CREATE") && (
            <Box mx={2}>
              <StyledButton
                onClick={() => history.push(`/objects/railway-lines/item`)}
                bordered
                title="Dodaj kolej liniowy"
              >
                <FontAwesomeIcon icon={faPlus} />
              </StyledButton>
            </Box>
          )}
        </Flex>
      </Box>
      {railwayLines.length > 0 && !requestPending ? (
        <TableWrapper>
          <CustomTable
            columnList={columns}
            data={railwayLines}
            colVis={colVis}
          />
        </TableWrapper>
      ) : (
        <Box mt={20}>
          {requestPending ? (
            <Loader />
          ) : (
            <Flex justifyContent="center" alignItems="center">
              Brak obiektów
            </Flex>
          )}
        </Box>
      )}
    </PageLayout>
  );
};
