import { Box } from "components/Box";
import { Button } from "components/Button";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Hr } from "components/Hr";
import { useForm } from "react-hook-form";
import { IUpdate } from "types/update";
import { Flex } from "components/Flex";
import { UpdateForm } from "containers/UpdateForm";
import { Update } from "containers/Update";
import React, { useState } from "react";
import { IDisruption } from "types/disruption";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface IUpdates {
  disruption: IDisruption;
}

interface IUpdateTaskFormData {
  date: string;
  sharepointUrl: Array<string>;
  description: string;
}

export const Updates: React.FC<IUpdates> = ({ disruption }) => {
  const { handleSubmit, reset } = useForm<IUpdateTaskFormData>({
    defaultValues: {
      date: "",
      sharepointUrl: [""],
      description: "",
    },
  });
  const [displayUpdateForm, setDisplayUpdateForm] = useState(false);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const defaultValue = disruption.updates.map((item) => ({
    ...item,
    number: disruption.number,
  }));

  const [updatesState, setUpdatesState] = useState<Array<IUpdate>>(
    defaultValue ?? []
  );

  const onRemoveUpdate = (updateId: string) => {
    setUpdatesState((prevState) => {
      return prevState.filter((update) => update.id !== updateId);
    });
  };

  return (
    <>
      <Flex mb={2} alignItems="center" justifyContent="space-between">
        <FieldSetTitle>Aktualizacje</FieldSetTitle>
        {loggedUserRoles?.includes("ROLE_DISRUPTIONS_LIST_UPDATE_CREATE") && (
          <Button
            bordered
            type="button"
            onClick={() => setDisplayUpdateForm((prevState) => !prevState)}
          >
            Dodaj aktualizację
          </Button>
        )}
      </Flex>
      {displayUpdateForm ? (
        <Box mb={6}>
          <UpdateForm
            disruption={disruption}
            onSubmit={(newUpdate) => {
              setUpdatesState((prevState) => [newUpdate, ...prevState]);
              setDisplayUpdateForm(false);
            }}
          />
        </Box>
      ) : null}
      {[...updatesState].map((update, index) => (
        <Box key={update.id} mb={index + 1 < updatesState.length ? 3 : 0}>
          <Update
            disruption={disruption}
            update={update}
            onRemove={onRemoveUpdate}
          />
          {index + 1 < updatesState.length ? <Hr /> : null}
        </Box>
      ))}
    </>
  );
};
