import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { CustomTable } from "components/CustomTable";
import styled from "styled-components";
import { formatDate } from "utilities/formatDate";
import { fetchDownloadKeyReturnDocument, fetchKeyReturns } from "services/keys";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";
import { downloadFile } from "utilities/downloadFile";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const StyledContent = styled.div`
  width: 80px;
  padding-top: 15px;
  padding-left: 15px;
`;

const TableContainer = styled.div`
  max-width: 1380px;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => `padding: ${theme.space[2]}px;`}
`;

export const KeyReturnsList = ({ keyId }: { keyId: string }) => {
  const [colVis, setColVis] = useState(Array(8).fill(true));
  const [returnKeys, setReturnKeys] = useState([]);

  const history = useHistory();

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  useEffect(() => {
    fetchKeyReturns({ keyId })
      .then(({ data }) => setReturnKeys(data))
      .catch(() => toast.error("Nie udało się pobrać wydań."));
  }, [keyId]);

  const columns = useMemo(
    () => [
      {
        name: "Numer Zwrotu",
        width: 120,
        value: ({ returnNumber }) => (
          <StyledContent>{returnNumber}</StyledContent>
        ),
      },
      {
        name: "Wykonawca",
        width: 120,
        value: ({ contractor }) => contractor,
      },
      {
        name: "Osoba",
        width: 90,
        value: ({ recipientName }) => recipientName,
      },
      {
        name: "E-mail osoby",
        width: 90,
        value: ({ recipientEmail }) => recipientEmail,
      },
      {
        name: "Data zwrotu",
        width: 120,
        value: ({ returnDate }) => formatDate(new Date(returnDate)),
      },
      {
        name: "Ilość sztuk",
        width: 90,
        value: ({ quantity }) => quantity,
      },
      ...(loggedUserRoles?.includes("ROLE_KEYS_ECONOMY_UPDATE")
        ? [
            {
              name: "",
              width: 110,
              value: ({ keyId, returnRequestId, returnId }) => (
                <Button
                  bordered
                  onClick={() => {
                    history.push(
                      `/keys/${keyId}/return/edit/${returnRequestId}/${returnId}`
                    );
                  }}
                >
                  Edytuj
                </Button>
              ),
            },
          ]
        : []),
      {
        name: "",
        width: 200,
        value: ({ returnRequestId, returnNumber }) => (
          <StyledButton
            bordered
            onClick={() => {
              fetchDownloadKeyReturnDocument(returnRequestId)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${returnNumber}.xlsx`);
                })
                .catch(() => toast.error("Nie udało się pobrać pliku."));

              fetchDownloadKeyReturnDocument(returnRequestId, true)
                .then((response) => response.blob())
                .then((blob) => {
                  downloadFile(blob, `${returnNumber}-kopia.xlsx`);
                })
                .catch(() => toast.error("Nie udało się pobrać kopii pliku."));
            }}
          >
            Pobierz dokument
          </StyledButton>
        ),
      },
    ],
    [loggedUserRoles]
  );

  return (
    <TableContainer>
      {returnKeys?.length ? (
        <CustomTable
          showLongText
          columnList={columns}
          data={returnKeys}
          colVis={colVis}
        />
      ) : (
        "Brak zwrotów"
      )}
    </TableContainer>
  );
};
