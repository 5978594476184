/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PageLayout } from "components/PageLayout";
import { Chart } from "components/Chart";

export const Charts: React.FC = () => {
  return (
    <PageLayout>
      <Chart dataLine={[]} />
    </PageLayout>
  );
};
