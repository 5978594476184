import { forwardRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

interface ISelect extends React.InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  style?: React.CSSProperties;
  tooltipMessage?: string | null;
  withTooltip?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  isDisabled?: boolean;
}

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => `
    position: relative;
    ${fullWidth ? "width: 100%;" : ""}
  `}
`;

export const Label = styled.label`
  ${({ theme }) => `
    // z-index: 1;
    display: table;
    // margin-bottom: -${theme.space[1]}px;
    margin-left: ${theme.space[3]}px;
    padding-left: ${theme.space[1]}px;
    position: relative;
    color: rgba(19, 19, 21, 0.65);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 15px;
    background-color: ${theme.palette.neutral.white};
  `};
`;

const SelectInput = styled.select<{ error?: boolean }>`
  ${({ theme, disabled, error }) => `
    width: 100%;
    height: 32px;
    padding: 0 ${theme.space[4]}px;
    border: 1px solid rgba(30, 46, 102, 0.15);
    border-radius: 8px;
    outline: none;
    ${disabled ? "background-color: grey" : ""}
    ${
      error &&
      `
      border: 1px solid red;
    `
    }
  `}
`;

const Tooltip = styled.div`
  background: white;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0;
  transform: translateX(100%);
  user-select: none;
`;

const TooltipIcon = styled.div`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -0.05rem;
  z-index: 2;
  font-size: 0.7rem;
  user-select: none;
`;

export const Select = forwardRef<HTMLSelectElement, ISelect>(
  (
    {
      label,
      style,
      tooltipMessage,
      withTooltip,
      fullWidth,
      children,
      error,
      disabled,
      ...inputProps
    },
    ref
  ) => {
    const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const toggleTooltip = () => setTooltipVisible(!isTooltipVisible);
    return (
      <Wrapper
        fullWidth={fullWidth}
        style={{ ...style, zIndex: tooltipMessage ? 2 : 0 }}
      >
        <Label>{label}</Label>
        {withTooltip && (
          <TooltipIcon
            onClick={toggleTooltip}
            style={{
              color: isTooltipVisible
                ? "rgba(19, 19, 21, 0.75)"
                : "rgba(19, 19, 21, 0.50)",
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </TooltipIcon>
        )}
        {tooltipMessage && isTooltipVisible && (
          <Tooltip>{tooltipMessage}</Tooltip>
        )}

        <SelectInput error={error} ref={ref} disabled={disabled} {...inputProps}>
          {children}
        </SelectInput>
      </Wrapper>
    );
  }
);
