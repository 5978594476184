import { Box } from "components/Box";
import { Button } from "components/Button";
import { CustomTable } from "components/CustomTable";
import { Tooltip } from "components/Tooltip";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { deleteTaskPredecessor } from "services/tasks";
import { ITaskPredecessor } from "types/task";
import { TableContainer } from "./PredecessorsList.styled";

export const PredecessorsList = ({
  data,
  hoverName,
  onDelete,
}: {
  data: ITaskPredecessor[];
  hoverName?: boolean;
  onDelete?: (id: string) => void;
}) => {
  const [colVis, setColVis] = useState(Array(7).fill(true));

  const columns = useMemo(
    () => [
      {
        name: "CSC",
        width: 70,
        value: ({ predecessor }) => <Box mt={4}>{predecessor.code}</Box>,
      },
      {
        name: "Nazwa",
        width: hoverName ? 60 : 200,
        value: ({ predecessor }) =>
          hoverName ? <Tooltip text={predecessor.name} /> : predecessor.name,
      },
      {
        name: "Typ",
        width: 100,
        value: ({ relationType }) =>
          relationType === "end-start"
            ? "Zakończenie-Rozpoczęcie"
            : "Zakończenie-Zakończenie",
      },
      {
        name: "Ilość dni zwłoki",
        width: 120,
        value: ({ duration }) => duration,
      },
      {
        name: "Data zakończenia",
        width: 120,
        value: ({ predecessor }) => predecessor.endDate.substring(0, 10),
      },
      ...(onDelete
        ? [
            {
              name: "",
              width: 100,
              value: ({ id }) => (
                <Button
                  bordered
                  onClick={() => {
                    deleteTaskPredecessor(id);
                    toast.success("Usunięto poprzednik");
                    onDelete(id);
                  }}
                >
                  Usuń
                </Button>
              ),
            },
          ]
        : []),
    ],
    [hoverName, onDelete]
  );

  return (
    <TableContainer>
      <h3>Lista poprzedników</h3>
      <CustomTable
        showLongText
        columnList={columns}
        data={data}
        colVis={colVis}
      />
    </TableContainer>
  );
};
