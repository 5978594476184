import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import {
  IPayment,
  getPayments,
  IDictionary,
  getPaymentsSummary,
  IPaymentsSummary,
  deletePayment,
} from "services/tasks";
import { PaymentCreate } from "./PaymentCreate";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { FieldSetTitle } from "components/FieldSetTitle";
import styled from "styled-components";
import { CustomTable } from "components/CustomTable";
import { Box } from "components/Box";
import { ConfirmModal } from "components/ConfirmModal";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";
import { rolesByObjects } from "constants/rolesByObjects";
import { formatDate } from "utilities/formatDate";

type IPayments = {
  createNewPayment: (payload: IPayment) => void;
  handleUpdatePayment: (payload: IPayment) => void;
  paymentScope: IDictionary[] | null;
  paymentTask: IDictionary[] | null;
};

const Wrapper = styled.div`
  width: 100%;
`;

const StyledDescription = styled.div`
  width: 200px;
`;

const ContentContainer = styled.div`
  max-width: 1610px;
`;

export const Payments: React.FC<IPayments> = ({
  createNewPayment,
  handleUpdatePayment,
  paymentScope,
  paymentTask,
}) => {
  const [colVis, setColVis] = useState(Array(12).fill(true));
  const [createPayment, setCreatePayment] = useState(false);
  const [paymentsList, setPaymentsList] = useState<IPayment[]>([]);
  const [paymentsSummary, setPaymentsSummary] = useState<IPaymentsSummary>();
  const [editPayment, setEditPayment] = useState<IPayment>();
  const [removePaymentId, setRemovePaymentId] = useState();
  const [isOpenRemoveModal, setIsOpenRemoveModal] = React.useState(false);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const { type, taskId } = useParams<{ type: string; taskId: string }>();

  const columns = useMemo(
    () => [
      {
        name: "MRW",
        value: (e) => <Box pt={1}>{e.mrwNumber}</Box>,
        minWidth: 100,
        width: 10,
      },
      {
        name: "Opis zadania",
        value: (e) => <StyledDescription>{e.paymentTask}</StyledDescription>,
        minWidth: 200,
        width: 15,
      },
      {
        name: "Opis zakresu",
        value: (e) => <StyledDescription>{e.scope}</StyledDescription>,
        minWidth: 200,
        width: 15,
      },
      {
        name: "Ustalona kwota ryczałtowa (ZŁ)",
        value: (e) =>
          e?.lumpSumAmount?.toLocaleString("PL", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) || "",
        width: 230,
      },
      // {
      //   name: "Ustalona kwota ryczałtowa (%)",
      //   value: (e) => e?.lumpSumAmountPercentageComplete?.value || "",
      //   width: 230,
      // },
      {
        name: "Rozliczono (ZŁ)",
        value: (e) => {
          return (
            e.totalSettledAmount?.toLocaleString("PL", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) || ""
          );
        },
        minWidth: 120,
        width: 10,
        textRight: true,
      },
      {
        name: "Rozliczono (%)",
        value: (e) => {
          return e.totalSettledAmountPercentageComplete?.value;
        },
        minWidth: 120,
        width: 10,
        textRight: true,
      },
      {
        name: "Pozostaje do zapłaty (ZŁ)",
        value: (e) => {
          const val =
            e?.remainingAmount?.toLocaleString("PL", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) || "";
          return val;
        },
        minWidth: 180,
        width: 10,
        textRight: true,
      },
      {
        name: "Pozostaje do zapłaty (%)",
        // value: (e) => e?.remainingAmountPercentageComplete?.value || "",
        value: (e) => {
          const val = (e?.remainingAmount / e?.lumpSumAmount) * 100;
          return `${
            val?.toLocaleString("PL", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) || ""
          } %`;
        },
        minWidth: 180,
        width: 10,
        textRight: true,
      },
      {
        name: "Daty z HRF",
        value: (e) =>
          e?.paymentDateRelations.map((paymentDateRelation) => (
            <p key={paymentDateRelation?.name}>{`${
              paymentDateRelation?.dateTaskCode || "brak"
            } ${formatDate(
              new Date(
                e?.paymentDates.find(
                  (searchPaymentDate) =>
                    searchPaymentDate.name === paymentDateRelation.name
                )?.date
              )
            )}`}</p>
          )),
        minWidth: 200,
        width: 10,
      },
      ...(loggedUserRoles?.includes(rolesByObjects[type]["PAYMENT_UPDATE"])
        ? [
            {
              name: "",
              value: (item) => (
                <Button
                  bordered
                  onClick={() => {
                    const foundPayment = paymentsList.find(
                      (payment) => payment.id === item.id
                    );

                    foundPayment.paymentDateRelations.map(
                      (paymentDateRelation) => {
                        Object.assign(foundPayment, {
                          [paymentDateRelation.name]:
                            paymentDateRelation.dateTaskCode,
                        });
                      }
                    );

                    setEditPayment(foundPayment);
                  }}
                >
                  Edytuj
                </Button>
              ),
              minWidth: 120,
              width: 10,
            },
          ]
        : []),
      ...(loggedUserRoles?.includes(rolesByObjects[type]["PAYMENT_DELETE"])
        ? [
            {
              name: "",
              value: (item) => (
                <Button
                  bordered
                  onClick={() => {
                    setRemovePaymentId(item.id);
                    setIsOpenRemoveModal(true);
                  }}
                >
                  Usuń
                </Button>
              ),
              minWidth: 120,
              width: 10,
            },
          ]
        : []),
    ],
    [paymentsList, loggedUserRoles]
  );

  const openFormToCreatePayment = () => {
    setCreatePayment(true);
  };

  const create = (data: IPayment) => {
    setCreatePayment(false);
    createNewPayment(data);

    setTimeout(() => {
      loadPaymentsData();
    }, 1000);
  };

  const update = async (data: IPayment) => {
    setEditPayment(null);
    handleUpdatePayment(data);

    setTimeout(() => {
      loadPaymentsData();
    }, 1000);
  };

  const handleDeletePayment = (paymentUuid: string) => {
    setPaymentsList((prevState) =>
      prevState.filter((payment) => payment.id !== paymentUuid)
    );

    deletePayment(paymentUuid)
      .then(() => {
        toast.success("Płatność została usunięta.");
        setRemovePaymentId(null);
        setIsOpenRemoveModal(false);
      })
      .catch(() => toast.error("Coś poszło nie tak z usuwaniem płatności."));
  };

  const handleCancelCreatePayment = () => {
    setEditPayment(null);
    setCreatePayment(false);
  };

  const loadPaymentsData = useCallback(() => {
    getPayments(taskId).then((response) => {
      setPaymentsList(response);
    });

    getPaymentsSummary(taskId).then((response) => {
      setPaymentsSummary(response);
    });
  }, [taskId]);

  useEffect(() => {
    loadPaymentsData();
  }, [loadPaymentsData, taskId]);

  return (
    <>
      {createPayment || editPayment ? (
        <>
          {paymentTask && paymentScope && (
            <PaymentCreate
              handleCancelCreatePayment={handleCancelCreatePayment}
              submit={editPayment ? update : create}
              paymentScope={paymentScope}
              paymentTask={paymentTask}
              editPayment={editPayment}
            />
          )}
        </>
      ) : (
        <>
          <ContentContainer>
            <Flex>
              <Wrapper>
                <Flex justifyContent="space-between" mb={4}>
                  <FieldSetTitle label="Ustalona kwota ryczałtowa (ZŁ)">
                    {paymentsSummary?.lumpSumAmount}
                  </FieldSetTitle>
                  <FieldSetTitle label="Rozliczono">
                    {paymentsSummary?.totalSettledAmount}
                  </FieldSetTitle>
                  <FieldSetTitle label="Pozostało do rozliczenia">
                    {paymentsSummary?.remainingAmount}
                  </FieldSetTitle>
                  <FieldSetTitle label="Zaawansowanie">
                    {paymentsSummary?.fraction &&
                      (paymentsSummary?.fraction * 100).toFixed(0) + "%"}
                  </FieldSetTitle>
                </Flex>
                {loggedUserRoles?.includes(
                  rolesByObjects[type]["PAYMENT_CREATE"]
                ) && (
                  <Flex mr={5} justifyContent="flex-end">
                    <Button bordered onClick={openFormToCreatePayment}>
                      Dodaj płatność
                    </Button>
                  </Flex>
                )}
              </Wrapper>
            </Flex>
            {paymentsList.length ? (
              <CustomTable
                showLongText
                columnList={columns}
                data={paymentsList}
                colVis={colVis}
                smallCell
                percentageWidth
              />
            ) : (
              <h2>Brak płatności</h2>
            )}
          </ContentContainer>

          <ConfirmModal
            handleConfirm={() => {
              handleDeletePayment(removePaymentId);
            }}
            onCancelClick={() => {
              setIsOpenRemoveModal(false);
              setRemovePaymentId(null);
            }}
            isOpen={isOpenRemoveModal}
            header="Czy na pewno chcesz usuąć płatność?"
            confirmBtnText="Usuń"
          />
        </>
      )}
    </>
  );
};
