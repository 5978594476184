import { Button } from "components/Button";
import { theme } from "config/theme";
import styled from "styled-components";
import addButtonImage from "../../icons/addButton.png";

export const HelperPanelWrapper = styled.div`
  height: 64px;
  background: ${theme.palette.lightGrey};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;
  gap: 10px;
  >button: {
    border: solid red;
  }
`;

export const Header = styled.p`
  font-weight: 700;
  margin-right: auto;
`;

export const ContentWrapper = styled.div`
  padding: 24px 28px;
`;
