import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { theme } from "config/theme";

interface IFlag {
  active: boolean;
  isTable?: boolean;
}

interface IIcon {
  isTable?: boolean;
}

const Icon = styled(FontAwesomeIcon)<IIcon>`
  margin: 0 10px;
  ${({ isTable }) =>
    isTable &&
    css`
      position: absolute;
      top: 0px;
      left: -8px;
      z-index: 100;
    `}
`;

const Flag: React.FC<IFlag> = ({ active, isTable }) => {
  return (
    <Icon
      color={theme.palette.primary}
      size="lg"
      isTable={isTable}
      icon={active ? faBookmarkSolid : faBookmark}
    />
  );
};

export default Flag;
