import { Box } from "components/Box";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { CustomTable } from "components/CustomTable";
import { ConfirmModal } from "components/ConfirmModal";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { dictionaries } from "types/dictionary";
import { toast } from "react-toastify";
import {
  deleteDictionary,
  fetchDictionary,
  fetchDictionaryUnitCostsCategories,
  IDictionary,
  postDictionary,
  putDictionary,
} from "services/dictionaries";
import { StyledMultiSelect } from "views/TaskTypeView/TaskType.styled";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface IColumnList {
  width: number;
  name: string;
  value:
    | JSX.Element
    | ((data?: { [key: string]: string }) => JSX.Element | string);
}

const StyledTableContainer = styled.div<{ width: number }>`
  ${({ width }) => `
    max-width: ${width > 1250 ? 1250 : width + 35}px;
  `}
`;

export const DictionaryTable = ({
  dictionary,
  url,
}: {
  dictionary: dictionaries;
  url: string;
}) => {
  const [tableData, setTableData] = useState<IDictionary[]>([]);
  const [colVis, setColVis] = useState(Array(14).fill(true));
  const [editItemIndex, setEditItemIndex] = useState<string | null>(null);
  const [itemId, setItemId] = useState<string | null>(null);
  const [rowFormList, setRowFormList] = useState<
    Pick<IColumnList, "value">[] | null
  >();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [unitCostCategories, setUnitCostCategories] = useState<
    { label: string; value: string }[]
  >([]);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const { register, reset, handleSubmit, control, watch } = useForm();
  const onAddClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setRowFormList(customRowList);
  };

  const onCancelClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setRowFormList(null);
    setItemId(null);
    setEditItemIndex(null);
    reset();
  };

  const refetchData = () =>
    fetchDictionary(url)
      .then((res) => {
        const data = Array.isArray(res)
          ? res
          : Object.entries(res).map((el) => {
              return { id: el[0], name: el[1] as string, status: true };
            });
        setTableData(data);
      })
      .catch(() => toast.error("Nie udało się pobrać dane."));

  const onDeleteTranslation = useCallback(() => {
    deleteDictionary(url, itemId)
      .then(() => {
        toast.success("Zgłoszenie zostało usuniętę.");
      })
      .catch(() => {
        toast.error("Nie można usunąć wartości z powodu powiązania w bazie.");
      });

    setIsOpenConfirmModal(false);
    setItemId(null);
    refetchData();
  }, [itemId]);

  const onSubmit = handleSubmit((data: Partial<IDictionary>) => {
    const description = data["description"] ? data["description"] : undefined;
    const categories = Array.isArray(data["categories"])
      ? data["categories"].map((el) => el.label)
      : undefined;
    const method = itemId ? putDictionary : postDictionary;
    const status =
      data.status && data.status["value"] ? data.status["value"] : false;

    method({ url, itemId, name: data.name, status, description, categories })
      .then(() => {
        setItemId(null);
        setEditItemIndex(null);
        refetchData().then(() => reset());
        toast.info("Wartość została zapisana.");
      })
      .catch(() => toast.error("Nie udało się zapisać wartości."))
      .finally(() => setRowFormList(null));
  });

  const customRowListBase: Pick<IColumnList, "value">[] = [
    // {
    //   value: ({ id }) => (
    //     <Box p={3}>
    //       <Input disabled defaultValue={id} required {...register("id")} />
    //     </Box>
    //   )
    // },
    {
      value: ({ name, id }) => (
        <Box p={3} mt={2}>
          <Input defaultValue={name} required {...register("name")} />
        </Box>
      ),
    },
    {
      value: (data) => (
        <>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value, ref } }) => (
              <>
                <StyledMultiSelect
                  inputRef={ref}
                  value={value}
                  onChange={(val) => onChange(val)}
                  options={[
                    { label: "Aktywny", value: true },
                    { label: "Nie aktywny", value: false },
                  ]}
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 1001,
                    }),
                  }}
                />
              </>
            )}
          />
        </>
      ),
    },
  ];
  const customRowListUnitCosts: Pick<IColumnList, "value">[] = [
    {
      value: (data) => (
        <Box mt={1}>
          <Input
            defaultValue={data.description}
            required
            {...register("description")}
          />
        </Box>
      ),
    },
    {
      value: (data) => (
        <>
          <Controller
            control={control}
            name="categories"
            render={({ field: { onChange, value, ref } }) => (
              <>
                <StyledMultiSelect
                  isMulti
                  inputRef={ref}
                  value={value}
                  onChange={(val) => onChange(val)}
                  options={unitCostCategories}
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 1001,
                    }),
                  }}
                />
              </>
            )}
          />
        </>
      ),
    },
  ];
  const customRowList = useMemo<Pick<IColumnList, "value">[]>(
    () =>
      dictionary === "unit_costs"
        ? [...customRowListBase, ...customRowListUnitCosts]
        : customRowListBase,
    [register, dictionary, unitCostCategories]
  );

  const baseColumnList: IColumnList[] = [
    // {
    //   width: 240,
    //   name: "Id",
    //   value: ({ id }) => <StyledContent>{id}</StyledContent>
    // },
    {
      width: 350,
      name: "Wartość",
      value: ({ name }) => (
        <Box pt={3} mt={2}>
          {name}
        </Box>
      ),
    },
    {
      width: 160,
      name: "Status",
      value: ({ status }) => (
        <Box mt={1}>{status ? "Aktywny" : "Nie aktywny"}</Box>
      ),
    },
  ];
  const editableColumnList: IColumnList[] = [
    {
      width: 90,
      name: "",
      value: ({ id }) =>
        id &&
        loggedUserRoles?.includes("ROLE_ADMINISTRATION_DELETE") && (
          <Button
            type="button"
            onClick={() => {
              setItemId(id);
              setIsOpenConfirmModal(true);
            }}
            bordered
          >
            Usuń
          </Button>
        ),
    },
    {
      width: 110,
      name: "",
      value: ({ id, categories, rowIndex, status }) =>
        id &&
        loggedUserRoles?.includes("ROLE_ADMINISTRATION_UPDATE") && (
          <Button
            type="button"
            onClick={(event) => {
              event.preventDefault();
              reset({
                categories: Array.isArray(categories)
                  ? categories.map((el) => {
                      return { label: el, value: el };
                    })
                  : { label: categories, value: categories },
                status: status
                  ? { label: "Aktywny", value: true }
                  : { label: "Nie aktywny", value: false },
              });
              setEditItemIndex(rowIndex);
              setRowFormList(customRowList);
              setItemId(id);
            }}
            bordered
          >
            Edytuj
          </Button>
        ),
    },
  ];
  const unitCostsColumnList: IColumnList[] = [
    {
      width: 350,
      name: "Opis",
      value: ({ description }) => <Box mt={2}>{description}</Box>,
    },
    {
      width: 120,
      name: "Kategorie",
      value: ({ categories }) => (
        <Box mt={2}>
          {Array.isArray(categories) ? categories.join(" ") : categories}
        </Box>
      ),
    },
  ];
  const columnListData: { [key in dictionaries]: IColumnList[] } = {
    lines: baseColumnList,
    unit_costs: [
      ...baseColumnList,
      ...unitCostsColumnList,
      ...editableColumnList,
    ],
    izs: [...baseColumnList, ...editableColumnList],
    partners: [...baseColumnList, ...editableColumnList],
  };

  const columnList = useMemo<IColumnList[]>(
    () => columnListData[dictionary],
    [loggedUserRoles, customRowList, dictionary]
  );

  useEffect(() => {
    refetchData();
    if (dictionary === "unit_costs") {
      fetchDictionaryUnitCostsCategories().then((res) => {
        let arr = [];
        res.forEach((el) => {
          arr = arr.concat(
            el.categories.map((data) => {
              return { label: data, value: data };
            })
          );
        });
        setUnitCostCategories(arr);
      });
    }
  }, [dictionary]);

  useEffect(() => {
    columnList.map(({ width }) => {
      setTableWidth((prevTableWidth) => prevTableWidth + width);
    });
  }, [columnList]);

  return (
    <>
      <form onSubmit={onSubmit}>
        {dictionary !== "lines" && (
          <Flex mb={5} justifyContent="flex-end">
            {rowFormList ? (
              <>
                <Box mr={2}>
                  <Button type="button" bordered onClick={onCancelClick}>
                    Anuluj
                  </Button>
                </Box>
                <Button type="submit" bordered>
                  Zapisz
                </Button>
              </>
            ) : (
              loggedUserRoles?.includes("ROLE_ADMINISTRATION_CREATE") && (
                <Button type="button" onClick={onAddClick} bordered>
                  Dodaj
                </Button>
              )
            )}
          </Flex>
        )}
        <StyledTableContainer width={tableWidth}>
          <CustomTable
            showLongText
            columnList={columnList}
            editItemIndex={editItemIndex}
            colVis={colVis}
            customRowList={rowFormList}
            data={
              tableData.length
                ? tableData
                : [
                    {
                      id: "",
                      content: "Brak tłumaczeń",
                      date: "",
                      status: "",
                      personId: 0,
                      personAvatar: "",
                      impactName: "",
                    },
                  ]
            }
          />
        </StyledTableContainer>
      </form>
      <ConfirmModal
        handleConfirm={onDeleteTranslation}
        onCancelClick={() => {
          setIsOpenConfirmModal(false);
          setItemId(null);
        }}
        isOpen={isOpenConfirmModal}
        header="Czy na pewno chcesz usunąć wiersz?"
        confirmBtnText="Usuń"
      />
    </>
  );
};
