import { fetchAPI, fetchFilesAPI } from "utilities/fetchAPI";
import { IPagination } from "types/pagination";

interface IFile {
  id: string;
  mime: string;
  content: string;
  filename: string;
}

export interface IBacklog {
  id: string;
  user: string;
  title: string;
  description: string;
  file: IFile[];
  fileName: string;
  createdAt: string;
  updatedAt: string;
  status: boolean;
  archived?: string;
}

interface IBacklogResponse {
  pagination: IPagination;
  data: IBacklog[];
}

export interface IRequestNewBacklog {
  title: string;
  description: string;
  file: string;
  status: boolean;
}

export const getBacklog = () =>
  fetchAPI<IBacklogResponse>(`${process.env.REACT_APP_API_URL}/backlog`);

export const getTaskDetails = (id: string) =>
  fetchAPI<IBacklog>(`${process.env.REACT_APP_API_URL}/backlog/${id}`);

export const createBacklog = (data: FormData) =>
  fetchFilesAPI(`${process.env.REACT_APP_API_URL}/backlog`, {
    method: "POST",
    body: data,
  });

export const updateTask = (id: string, data: IRequestNewBacklog) =>
  fetchAPI<IBacklog>(`${process.env.REACT_APP_API_URL}/backlog/${id}`, {
    method: "PUT",
    body: JSON.stringify({ ...data }),
  });

export const deleteTask = (id: string) =>
  fetchAPI<IBacklog>(`${process.env.REACT_APP_API_URL}/backlog/${id}`, {
    method: "DELETE",
  });

export const getBacklogAttachement = (id: string) =>
  fetchFilesAPI(`${process.env.REACT_APP_API_URL}/backlog/download/${id}`);
