import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "components/PageLayout";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import styled from "styled-components";
import {
  useTableSidebarDispatchContext,
  useTableSidebarContext,
} from "contexts/TableSidebarContext";
import { KeyDetails } from "containers/KeyDetails/KeyDetails";
import { KeyPublicationsList } from "containers/KeyPublicationsList/KeyPublicationsList";
import { fetchKey } from "services/keys";
import { IKey } from "types/keys";
import { KeyReturnsList } from "containers/KeyReturnsList/KeyReturnsList";
import { Button } from "components/Button";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const Root = styled(Flex)`
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
`;
const StyledBox = styled(Box)`
  height: calc(100% - 60px);
  width: 100%;
  background-color: #fff;
  border: 20px solid #f4f5f8;
  border-bottom: 0;
  overflow: auto;
`;
const StyledCard = styled(Card)`
  overflow: auto;
  width: 100%;
  background-color: #f4f5f8;
`;

export const KeyView = () => {
  const [key, setKey] = useState<IKey | null>(null);
  const [keyReturnShow, setKeyReturnShow] = useState(false);
  const { id: keyId } = useParams<{ id: string }>();

  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();

  const getKey = () => {
    fetchKey({
      keyId,
    }).then((response) => {
      setKey(response);
    });
  };

  useEffect(() => {
    getKey();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTableData({
        ...tableData,
        detailsObject: false,
      });
    });
  }, []);

  return (
    <PageLayout>
      <Root justifyContent="space-between">
        <StyledCard>
          <StyledBox px={8} py={6}>
            {key && <KeyDetails details={key} keyId={keyId} />}

            <Box py={3}>
              <Button
                bordered
                onClick={() => {
                  setKeyReturnShow((prevState) => !prevState);
                }}
              >
                pokaż {keyReturnShow ? "wydania" : "zwroty"}
              </Button>
            </Box>

            {keyReturnShow ? (
              <KeyReturnsList keyId={keyId} />
            ) : (
              <KeyPublicationsList keyId={keyId} />
            )}
          </StyledBox>
        </StyledCard>
      </Root>
    </PageLayout>
  );
};
