import styled from "styled-components";

const Root = styled.h1`
  margin: 0;
  font-family: Lato;
  font-size: 25px;
  line-height: 29px;
  font-weight: 900;
`;

export const PageTitle: React.FC = ({ children }) => {
  return <Root>{children}</Root>;
};
