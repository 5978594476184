import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Input } from "components/Input";
import {
  addUserToGroups,
  createUser,
  getPartnerGroups,
  getUserGroups,
  IUserGroup,
  removeUserFromGroup,
  updateCurrentUser,
} from "services/user";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import MultiSelect from "react-select";

import {
  PreviewImage,
  StyledHeading,
  StyledSelect,
  StyledWrapperFlex,
  WrapperInput,
} from "./UserForm.styled";
import { useFetchDictionaryPartners } from "hooks/fetchDictionaryPartners";
import { Label } from "components/Label";
import { removeItemsFromArray } from "utilities/removeItemsFromArray";

export interface IUser {
  avatar?: string;
  email: string;
  firstname?: string;
  id: string;
  lastname?: string;
  partner?: string;
  position?: string;
  tag?: string;
  groups?: string[];
}

export interface IPassword {
  password?: string;
  repeatPassword?: string;
  oldPassword?: string;
}

interface UserFormProps {
  chosenUser?: IUser | null;
  setIsUserFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchUsersList: () => void;
}

export const UserForm = ({
  chosenUser,
  setIsUserFormOpen,
  fetchUsersList,
}: UserFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>();
  const [userGroups, setUserGroups] = useState<IUserGroup[]>();
  const [selectedGroups, setSelectedGroups] = useState<string[]>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { dictionaryPartners } = useFetchDictionaryPartners();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: chosenUser,
  });

  const handleAddUserToGroups = async (userId: string) => {
    if (chosenUser) {
      const { data: allUserGroups } = await getUserGroups();

      const chosenUserGroupsIDs = chosenUser?.groups.map(
        (group) =>
          allUserGroups?.find(
            (searchedGroup) => searchedGroup.humanizeName === group
          )?.id
      );

      const groupsToAdd = removeItemsFromArray(
        [...selectedGroups],
        ...chosenUserGroupsIDs
      );

      const groupsToRemove = removeItemsFromArray(
        [...chosenUserGroupsIDs],
        ...selectedGroups
      );

      groupsToAdd?.map((selectedGroup) => {
        addUserToGroups(selectedGroup, { user: userId })
          .then(() => {
            toast.success("Dodano użytkownika do grup.");
          })
          .catch(() => {
            toast.error("Nie udało się dodać użytkownika do grup.");
          });
      });

      groupsToRemove?.map((selectedGroup) => {
        removeUserFromGroup(selectedGroup, userId).catch(() => {
          toast.error("Nie udało się usunąć użytkownika z grupy.");
        });
      });
    } else {
      selectedGroups?.map((selectedGroup) => {
        addUserToGroups(selectedGroup, { user: userId })
          .then(() => {
            toast.success("Zaktualizowano grupy użytkownika.");
          })
          .catch(() => {
            toast.error("Nie udało się zaktualizować grup użytkownika.");
          });
      });
    }
  };

  const handleChangePartner = async (e) => {
    const partnerName = e.target.value;
    setSelectedGroups([]);

    const res = partnerName
      ? await getPartnerGroups(
          dictionaryPartners.find((partner) => partner.name === partnerName)?.id
        )
      : await getUserGroups();
    if (res.data) setUserGroups(res.data);
  };

  const onsubmit = (data: IUser) => {
    let keyData: keyof typeof data;
    for (keyData in data) {
      if (data[keyData] === undefined) delete data[keyData];
    }

    const reqPayload = { ...data, tag: data.email, avatar: preview };

    if (chosenUser?.id) {
      updateCurrentUser(chosenUser?.id, reqPayload)
        .then(async (editedUser) => {
          await handleAddUserToGroups(editedUser?.id);

          toast.success("Zaktualizowano użytkownika.");
        })
        .then(() => {
          reset();
          setIsUserFormOpen((prevState) => !prevState);
          setTimeout(() => {
            fetchUsersList();
          }, 1000);
        })
        .catch(() => {
          toast.error("Nie udało się zaktualizować użytkownika.");
        });
    } else {
      createUser(reqPayload)
        .then((newUser) => {
          handleAddUserToGroups(newUser?.id);

          reset();
          setIsUserFormOpen((prevState) => !prevState);
          setTimeout(() => {
            fetchUsersList();
          }, 1000);

          toast.success("Dodano użytkownika.");
        })
        .catch(() => {
          toast.error("Nie udało się dodać użytkownika.");
        });
    }
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else if (chosenUser?.avatar) {
      setPreview(chosenUser.avatar);
    } else {
      setPreview("");
    }
  }, [chosenUser, image]);

  useEffect(() => {
    if (chosenUser) {
      setIsLoading(true);

      try {
        setTimeout(async () => {
          reset(chosenUser);

          if (dictionaryPartners) {
            const res = chosenUser.partner
              ? await getPartnerGroups(
                  dictionaryPartners?.find(
                    (partner) => partner.name === chosenUser.partner
                  )?.id
                )
              : await getUserGroups();

            if (res) {
              const resUserGroups = res.data;

              setUserGroups(resUserGroups);

              setSelectedGroups(
                chosenUser?.groups.map(
                  (group) =>
                    resUserGroups?.find(
                      (searchedGroup) => searchedGroup.humanizeName === group
                    )?.id
                )
              );

              setIsLoading(false);
            }
          }
        }, 2000);
      } catch (e) {
        toast.error("Coś poszło nie tak");

        reset();
        setIsUserFormOpen((prevState) => !prevState);
        setTimeout(() => {
          fetchUsersList();
        }, 1000);

        setIsLoading(false);
      }
    }
  }, [chosenUser, dictionaryPartners, reset]);

  if (isLoading) return <StyledHeading>ładowanie...</StyledHeading>;

  return (
    <form onSubmit={handleSubmit(onsubmit)}>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledHeading>1. Dane ogólne</StyledHeading>
      </Flex>
      <StyledWrapperFlex
        justifyContent="flex-start"
        alignItems="center"
        p={10}
        pr={20}
        mb={10}
      >
        <WrapperInput mr={2}>
          <Label>Imię</Label>
          <Input {...register("firstname")} />
        </WrapperInput>

        <WrapperInput mr={2}>
          <Label>Nazwisko</Label>
          <Input {...register("lastname")} />
        </WrapperInput>

        <WrapperInput mr={2}>
          <Label>Email</Label>
          <Input {...register("email")} />
        </WrapperInput>
      </StyledWrapperFlex>

      <Flex justifyContent="space-between" alignItems="center">
        <StyledHeading>2. Dodaj avatar</StyledHeading>
      </Flex>
      <StyledWrapperFlex
        justifyContent="flex-start"
        alignItems="center"
        p={10}
        pr={20}
        mb={10}
      >
        <Box mr={10}>
          <Flex
            flexDirection="column"
            alignItems="center"
            style={{ width: "150px" }}
          >
            {preview ? (
              <PreviewImage
                src={preview}
                style={{
                  objectFit: "cover",
                  width: "50px",
                  height: "50px",
                }}
              />
            ) : (
              <Avatar />
            )}

            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              style={{ display: "none" }}
              onChange={(event) => {
                if (event.target.files) {
                  const file = event.target.files[0];
                  if (file) {
                    setImage(file);
                  } else {
                    setImage(null);
                  }
                }
              }}
            />
          </Flex>
        </Box>
        <Button
          type="button"
          bordered
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        >
          Dodaj zdjęcie
        </Button>
      </StyledWrapperFlex>

      <Flex justifyContent="space-between" alignItems="center">
        <StyledHeading>3. Pozostałe informacje</StyledHeading>
      </Flex>
      <StyledWrapperFlex
        justifyContent="flex-start"
        alignItems="center"
        p={10}
        pr={20}
        mb={10}
      >
        <WrapperInput mr={2}>
          <StyledSelect
            label="Partner"
            {...register("partner")}
            onChange={handleChangePartner}
          >
            <option value="" />
            {dictionaryPartners
              ?.filter(({ status }) => status)
              .map((partner) => (
                <option value={partner.name} key={partner.name}>
                  {partner.name}
                </option>
              ))}
          </StyledSelect>
        </WrapperInput>
        {userGroups && (
          <Box>
            <Label>Role</Label>
            <MultiSelect<
              {
                label: string;
                value: string;
              },
              true
            >
              value={
                selectedGroups
                  ?.map((group) => ({
                    value: group,
                    label: userGroups?.find(
                      (searchedGroup) => searchedGroup.id === group
                    )?.humanizeName,
                  }))
                  .filter(({ value }) => value) || null
              }
              onChange={(values) => {
                setSelectedGroups(values.map((value) => value.value));
              }}
              options={
                userGroups?.map((group) => ({
                  label: group.humanizeName,
                  value: group.id,
                })) ?? []
              }
              isMulti
            />
          </Box>
        )}
      </StyledWrapperFlex>

      <Flex justifyContent="flex-end" mr={10}>
        <Button type="submit" bordered>
          Zapisz
        </Button>
      </Flex>
    </form>
  );
};
