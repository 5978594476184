import { Box } from "components/Box";
import { envProjectName } from "envData";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

interface IRoot {
  visibleSidebar: boolean;
  open: boolean;
}
export const Root = styled.div<IRoot>`
  ${({ theme, visibleSidebar, open }) => `
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: ${visibleSidebar ? "260px" : "60px"};
    height: 100%;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 2px 8px 0 rgba(30,46,102,0.1);
    transition: all .3s ease-in-out;
    ${
      !visibleSidebar &&
      open &&
      `
      width: 260px;
    `
    }
    ${
      !visibleSidebar &&
      !open &&
      `
      width: 60px;
    `
    }
  `};
`;

export const LogoOuterWrapper = styled.div`
  ${({ theme }) => `
    padding: 0 ${theme.space[2]}px;
  `};
`;

export const LogoInnerWrapper = styled.div`
  ${envProjectName === "herkules" && "position: relative;"}

  height: 64px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid rgba(30, 46, 102, 0.1);
`;

export const LogoLink = styled(Link)`
  height: 100%;
`;

export const Logo = styled.img`
  ${envProjectName === "herkules"
    ? `width: 170px;
       position: absolute;
       top: 50%;
       left: 20px;
       transform: translateY(-50%);`
    : `
      height: 30px; 
      margin-top: 15px;
      width: 170px;
    `}
`;

export const SidebarItems = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
`;

export const FirstLevelMenu = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[3]}px 0;
    height: 100%;
  `}
`;

export const FirstLevelMenuInner = styled.div`
  width: 61px;
  height: 100%;
  border-right: 1px solid rgba(30, 46, 102, 0.1);
  > * {
    width: 100%;
    cursor: pointer;
  }
`;

export const NestedLevelMenusWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 100%;
    padding: ${theme.space[3]}px 0;
  `}
`;
export const Hamburger = styled.div`
  ${envProjectName === "herkules" &&
  `position: absolute;
   top: 50%;
   right: 20px;
   transform: translateY(-50%);
  `}

  font-size: 15px;
  color: #666;
  cursor: pointer;
`;

interface IMenuItem {
  active?: boolean;
  nested?: boolean;
}

export const MenuItem = styled(NavLink)<IMenuItem>`
  ${({ theme, active, nested }) => `
    ${nested ? `margin-left: ${theme.space[5]}px` : ""};
    padding: 0 ${theme.space[5]}px;
    display: flex;
    align-items: center;
    height: 45px;
    color: ${active ? theme.palette.primary : "rgba(30, 46, 102, 0.65)"};
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 13px;
    text-transform: uppercase;
    text-decoration: none;
    ${nested ? "border-left: 1px solid rgba(30, 46, 102, 0.1);" : ""}

    &.active {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -2px;
        top: 50%;
        margin-top: -12px;
        display: block;
        background-color: ${theme.palette.primary};
        height: 24px;
        width: 3px;
        border-radius: 4px;
      }
    }
  `}
`;
export const Heading = styled.h3<{ active: boolean }>`
  ${({ theme, active }) => `
    cursor: pointer;
    color: ${theme.palette.primary};
    margin-left: 10px;
    opacity: ${active ? "1" : "0.6"};
  `}
`;

export const AppVersionContainer = styled(Box)`
  ${({ theme }) => `
    color: ${theme.palette.primary};
  `}
`;
