import { DISRUPTION_CATEGORIES_URL } from "constants/path";
import { DisruptionCategoriesType } from "types/disruption";
import { useFetchAPI } from "./fetchAPI";

export const useFetchDisruptionCategories = () => {
  const { data } = useFetchAPI<DisruptionCategoriesType>(
    DISRUPTION_CATEGORIES_URL
  );

  return { disruptionCategories: data };
};
