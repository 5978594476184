import { Box } from "components/Box";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Flex } from "components/Flex";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IPostRailwayLineResponse, postRailwayLine, putRailwayLine } from "services/railwayLines";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import qs from "qs";
import { IRailwayLine } from "types/railway-line";
import { Label } from "components/Select";
import ReactSelect from "react-select";

interface IRailwayLineFormData {
  lineNumber: number,
  chainageStart: number,
  chainageEnd: number,
  attachments: { label: string, value: string }[];
}

const schema = yup.object().shape({
  lineNumber: yup.number().min(1).required(),
  chainageStart: yup.number().required(),
  chainageEnd: yup.number().required()
});

export const RailwayLineForm: React.FC = () => {
  const attachmentOptions = [
    { label: "Załącznik nr 1", value: "Załącznik nr 1" },
    { label: "Załącznik nr 5", value: "Załącznik nr 5" },
    { label: "Załącznik nr 21", value: "Załącznik nr 21" },
    { label: "inne", value: "inne" }
  ];
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [lineObj, setLineObj] = useState<IRailwayLine | IPostRailwayLineResponse | null>(id ? qs.parse(id) : null);
  const defaultAttachments = lineObj?.attachments ? lineObj.attachments.map(el => {
    return { label: el, value: el };
  }) : [{ label: "Załącznik nr 1", value: "Załącznik nr 1" }];

  const {
    register, handleSubmit, control
  } = useForm<IRailwayLineFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...lineObj,
      attachments: defaultAttachments
    }
  });

  const onSubmit = handleSubmit((data) => {
    const parsedFormData = {
      ...data,
      attachments: data.attachments ? data.attachments.map(el => el.value) : [],
      lineNumber: Number(data.lineNumber),
      chainageStart: Number(data.chainageStart),
      chainageEnd: Number(data.chainageEnd)
    };

    postRailwayLine(parsedFormData)
      .then((response) => {
        if (response.id) {
          setLineObj(response);
          history.push(`/objects/railway-lines`);
          // history.push(`/objects/railway-lines/item/${qs.stringify(response)}`);
          toast.success("Zakłócenie zostało dodane.");
        } else {
          toast.error("Proszę uzupełnić pola.");
        }
      })
      .catch(() => {
        toast.error("Nie udalo się zapisać linię kolejową.");
      });

    // if (lineObj && lineObj.id) {
    //   putRailwayLine({
    //     railwayLine: parsedFormData,
    //     railwayLineId: lineObj.id
    //   })
    //     .then((response) => {
    //       setLineObj(response);
    //       history.push(`/objects/railway-lines`);
    //       // history.push(`/objects/railway-lines/item/${qs.stringify(response)}`);
    //       toast.success("Zakłócenie zostało zaktualizowane.");
    //     })
    //     .catch(() => {
    //       toast.error("Nie udało się zaktualizować zakłócenia.");
    //     });
    // } else {
    // }
  });

  const isValidFloatNumber = (str: string) => {
    return !str.split("").filter(el => !"-.0123456789".includes(el)).length
      && (str.match(/-/g) || []).length < 2
      && (str.match(/\./g) || []).length < 2
      && [-1, 0].includes(str.indexOf("-"))
      && str.indexOf("-.") === -1;
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Flex alignItems="center" justifyContent="space-between">
          <FieldSetTitle>Dane</FieldSetTitle>
          <Flex>
            <Button onClick={onSubmit} bordered>
              {lineObj ? "Zapisz" : "Dodaj"}
            </Button>
            <Box ml={4}>
              <Button onClick={() => history.push("/objects/railway-lines")} bordered>
                Anuluj
              </Button>
            </Box>
          </Flex>
        </Flex>
        <Flex>
          <Flex flexDirection="column">
            <Input label="nr. linii" {...register("lineNumber")} />
            <Controller
              control={control}
              name="chainageStart"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Km od"
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (value == "-" || value.substr(value.length - 1) == "." || Number.isNaN(parseFloat(value))) field.onChange(0);
                  }}
                  onChange={(e) => {
                    field.onChange(isValidFloatNumber(e.target.value) ? e.target.value : isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value));
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="chainageEnd"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Km do"
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (value == "-" || value.substr(value.length - 1) == "." || Number.isNaN(parseFloat(value))) field.onChange(0);
                  }}
                  onChange={(e) => {
                    field.onChange(isValidFloatNumber(e.target.value) ? e.target.value : isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value));
                  }}
                />
              )}
            />
          </Flex>
          <Box mx={4}>
            <Label>załączniki</Label>
            <Controller
              name="attachments"
              control={control}
              render={({ field }) => (
                <ReactSelect<{ label: string; value: string; }, true>
                  {...field}
                  options={attachmentOptions}
                  isMulti
                  closeMenuOnSelect={false}
                />
              )}
            />
          </Box>
        </Flex>
      </form>
    </>
  )
    ;
};
