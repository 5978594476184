/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from "react";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import DatePicker from "react-datepicker";
import EChartsReact from "echarts-for-react";
import { useForm, Controller } from "react-hook-form";
import { format } from "date-fns";
import { Input } from "components/Input";
import { getPartnersDisruptionsByDate } from "services/statistics";

interface IForm {
  dateFrom: Date;
  dateTo: Date;
  taskType: string;
}

interface IDataItem {
  length: number;
  date: string;
  nokia: number;
  herkules: number;
  fonon: number;
  "spc-2": number;
}

export const DisruptionBasicChart = () => {
  const [disruptionsByDate, setDisruptionsByDate] = useState<
    Record<string, IDataItem>[]
  >([]);

  const { watch, control } = useForm<IForm>({
    defaultValues: {
      dateFrom: format(new Date("2019-01-02"), "yyyy-MM-dd"),
      dateTo: format(new Date("2020-01-02"), "yyyy-MM-dd"),
    },
  });
  const watcher = watch();

  useEffect(() => {
    const dateFrom = format(new Date(watcher.dateFrom), "yyyy-MM-dd");
    const dateTo = format(new Date(watcher.dateTo), "yyyy-MM-dd");

    getPartnersDisruptionsByDate(`${dateFrom}`, `${dateTo}`).then((res) => {
      setDisruptionsByDate(res as Record<string, never>[]);
    });
  }, [watcher.dateFrom, watcher.dateTo]);

  console.log("watcher.dateTo", watcher);

  console.log("disruptionsByDate", disruptionsByDate);
  const dataChart = disruptionsByDate?.map((item) => {
    const month = item.date?.toString().substring(item.date?.length - 2);
    const year = item.date?.toString().substring(0, item.date?.length - 2);
    const dateToDisplay = month + "." + year;
    return { ...item, date: dateToDisplay };
  });

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    dataset: {
      source: dataChart,
    },
    xAxis: {
      type: "category",
    },
    yAxis: {},
    series: [
      {
        type: "bar",
        color: "#23ABB6",
        label: {
          show: true,
          position: "top",
          fontSize: 9,
        },
      },
      {
        type: "bar",
        color: "#005AFF",
        label: {
          show: true,
          position: "top",
          fontSize: 9,
        },
      },
      {
        type: "bar",
        color: "#7D33F2",
        label: {
          show: true,
          position: "top",
          fontSize: 9,
        },
      },
      {
        type: "bar",
        color: "#F47F31",
        label: {
          show: true,
          position: "top",
          fontSize: 9,
        },
      },
    ],
  };

  return (
    <Card>
      <Box px={4} py={3}>
        <Flex justifyContent="space-between">
          <Flex>
            <Box mr={2}>
              <Controller
                control={control}
                name="dateFrom"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value ? new Date(field.value) : new Date()}
                    onChange={(date) => {
                      field.onChange(
                        date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                      );
                    }}
                    customInput={
                      <Input label="Data od" value={field.value.toString()} />
                    }
                    dateFormat="dd-MM-yyyy"
                    portalId="root-datepicker-portal"
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name="dateTo"
              render={({ field }) => (
                <DatePicker
                  selected={field.value ? new Date(field.value) : new Date()}
                  onChange={(date) => {
                    field.onChange(
                      date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                    );
                  }}
                  customInput={
                    <Input label="Data do" value={field.value.toString()} />
                  }
                  dateFormat="dd-MM-yyyy"
                  portalId="root-datepicker-portal"
                />
              )}
            />
          </Flex>
        </Flex>
      </Box>
      <EChartsReact option={option} style={{ width: "100%", height: 400 }} />
    </Card>
  );
};
