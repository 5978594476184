import { IUser } from "./users";

export type ITask = {
  id: string;
  code: string;
  company: string;
  type: string;
  chainage: number;
  iZ: string;
  line: string;
  completion: number;
  locationName: string;
  inHrf?: string;
  contractorCW?: string;
  contractorSA?: string;
  name?: string;
  objectName?: string;
  taskName?: string;
  objectNameIE50?: string;
  startDate: string;
  endDate: string;
  subtaskPraceProjektoweStartDate?: string;
  subtaskPraceProjektoweEndDate?: string;
  subtaskDULICPStartDate?: string;
  subtaskDULICPEndDate?: string;
  subtaskPnBStartDate?: string;
  subtaskPnBEndDate?: string;
  subtaskPraceBudowlaneStartDate?: string;
  subtaskPraceBudowlaneEndDate?: string;
  subtaskPradNaObiekcieStartDate?: string;
  subtaskPradNaObiekcieEndDate?: string;
  subtaskZagospodarowanieStartDate?: string;
  subtaskZagospodarowanieEndDate?: string;
  subtaskWdrozenieStartDate?: string;
  subtaskWdrozenieEndDate?: string;
  subtaskUruchomienieStartDate?: string;
  subtaskUruchomienieEndDate?: string;
  taskmetaActualMontazWiezyEndDate?: string;
  taskmetaForecastMontazWiezyEndDate?: string;
  chainageStart?: number;
  chainageEnd?: number;
  chainageLength?: number;
  length?: number;
  duration?: number;
  problems?: {
    name: string;
    severity: string;
    type: string;
  }[];
  coordinator?: string;
  contractor?: string;
  children: {
    id: string;
    name: string;
    nameNormalized: string;
    chainageStart: number;
    chainageEnd: number;
  }[];
  active?: boolean;
};

export type ITaskMeta = {
  id: string;
  task: string;
  name: string;
  groupName: string;
  type: string;
  label: string;
  value: string;
};

export enum TaskType {
  OR = "OR",
  ORR = "ORR",
  DOR = "DOR",
  FDS = "FDS",
  OTK = "OTK",
  OSZ = "OSZ",
  OC = "OC",
  Tunel = "Tunel",
  milestone = "milestone",
  CertyfikacjaWE = "CertyfikacjaWE",
  Certyfikacja = "Certyfikacja",
  ReadyToWE = "ReadyToWE",
  task = "task",
  bedrock = "bedrock",
  OtherTask = "OtherTask",
}

export type ITaskChild = {
  id: string;
  code: string;
  type: string; // todo enum?
  name: string;
  business_days?: number;
  parent: string;
  startDate: string;
  endDate: string;
  disruptedStartDate: string;
  disruptedEndDate: string;
  duration: number;
  children: [
    {
      id: string;
      name: string;
    }
  ];
  relations: string[];
  relatedTo: string[];
  disruptions: string[];
  precent_complete?: string;
  predecessorsDatesManual: boolean;
};

export type ITaskPredecessor = {
  id: string;
  relationType: string;
  duration: number;
  task: {
    id: string;
    code: string;
    type: string;
    name: string;
    duration: number;
    endDate: string;
    startDate: string;
  };
  predecessor: {
    id: string;
    code: string;
    type: string;
    name: string;
    duration: number;
    endDate: string;
    startDate: string;
  };
};

interface ChangedProperties {
  name: string;
  newValue: string;
  oldValue: string;
  changedBy: string;
  newValueIsDate: boolean;
  oldValueIsDate: boolean;
}

export interface ITaskHistory {
  changedProperties: ChangedProperties[];
  context: string;
  date: string;
  type: string;
  user: IUser;
}

export interface DictionaryContractorsType {
  CW: string[];
  SA: string[];
}

export interface TaskDisruptionType {
  id: string;
  csc: string;
  submittingName: string;
  categoryName: string;
  status: string;
  startDate: string;
  endDate: string;
}

export interface SharedDirectoryItemType {
  relativePath: string;
  filename: string;
  basename: string;
  pathname: string;
  extension: string;
  realPath: string;
  aTime: number;
  mTime: number;
  cTime: number;
  size: number;
  type: string;
  file: boolean;
  dir: boolean;
  link: boolean;
}
