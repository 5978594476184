import { Box } from "components/Box";
import { CustomTable } from "components/CustomTable";
import { PageLayout } from "components/PageLayout";
import { DISRUPTION_PARTNERS_URL } from "constants/path";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchDictionary } from "services/dictionaries";
import { ContentWrapper } from "./PartnersView.styled";

export const PartnersView = () => {
  const [partnersList, setPartnersList] = useState([]);
  const [colVis, setColVis] = useState(Array(2).fill(true));

  const { setTableData } = useTableSidebarDispatchContext();

  const fetchData = () =>
    fetchDictionary(DISRUPTION_PARTNERS_URL)
      .then((res) => {
        const data = Array.isArray(res)
          ? res
          : Object.entries(res).map((el) => {
              return { id: el[0], name: el[1] as string, status: true };
            });
        setPartnersList(data);
      })
      .catch(() => toast.error("Nie udało się pobrać dane."));

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, [setTableData]);

  const columns = [
    {
      width: 350,
      name: "Wartość",
      value: ({ name }) => (
        <Box pt={3} mt={2}>
          {name}
        </Box>
      ),
    },
    {
      width: 160,
      name: "Status",
      value: ({ status }) => (
        <Box mt={1}>{status ? "Aktywny" : "Nie aktywny"}</Box>
      ),
    },
  ];

  return (
    <PageLayout displaySidebarTable={false} isScroll>
      <ContentWrapper>
        <CustomTable
          showLongText
          columnList={columns}
          data={partnersList}
          colVis={colVis}
        />
      </ContentWrapper>
    </PageLayout>
  );
};
