export const rolesByImports = {
  "OR,ORR,DOR-SABP": {
    LIST: "ROLE_IMPORTS_SA_LIST",
    CREATE: "ROLE_IMPORTS_SA_CREATE",
    SHOW: "ROLE_IMPORTS_SA_SHOW",
  },
  "OR,ORR,DOR-CW": {
    LIST: "ROLE_IMPORTS_CW_LIST",
    CREATE: "ROLE_IMPORTS_CW_CREATE",
    SHOW: "ROLE_IMPORTS_CW_SHOW",
  },
  FDS: {
    LIST: "ROLE_IMPORTS_FDS_TASK_LIST",
    CREATE: "ROLE_IMPORTS_FDS_TASK_CREATE",
    SHOW: "ROLE_IMPORTS_FDS_TASK_SHOW",
  },
  "OTK-Fonon": {
    LIST: "ROLE_IMPORTS_OTK_FONON_LIST",
    CREATE: "ROLE_IMPORTS_OTK_FONON_CREATE",
    SHOW: "ROLE_IMPORTS_OTK_FONON_SHOW",
  },
  "OTK-SPC-2": {
    LIST: "ROLE_IMPORTS_OTK_SPC_2_LIST",
    CREATE: "ROLE_IMPORTS_OTK_SPC_2_CREATE",
    SHOW: "ROLE_IMPORTS_OTK_SPC_2_SHOW",
  },
  MRW: {
    LIST: "ROLE_IMPORTS_MRW_LIST",
    CREATE: "ROLE_IMPORTS_MRW_CREATE",
    SHOW: "ROLE_IMPORTS_MRW_SHOW",
  },
  PŚP: {
    LIST: "ROLE_IMPORTS_PSP_LIST",
    CREATE: "ROLE_IMPORTS_PSP_CREATE",
    SHOW: "ROLE_IMPORTS_PSP_SHOW",
  },
  objects: {
    LIST: "ROLE_IMPORTS_OBJECT_LIST",
    CREATE: "ROLE_IMPORTS_OBJECT_CREATE",
    SHOW: "ROLE_IMPORTS_OBJECT_SHOW",
  },
  task_areas: {
    LIST: "ROLE_IMPORTS_TASK_AREA_LIST",
    CREATE: "ROLE_IMPORTS_TASK_AREA_CREATE",
    SHOW: "ROLE_IMPORTS_TASK_AREA_SHOW",
  },
  payment_date: {
    LIST: "ROLE_IMPORTS_PAYMENT_DATE_LIST",
    CREATE: "ROLE_IMPORTS_PAYMENT_DATE_CREATE",
    SHOW: "ROLE_IMPORTS_PAYMENT_DATE_SHOW",
  },
};
