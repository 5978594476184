import { Box } from "components/Box";
import { Card } from "components/Card";
import { Loader } from "components/Loader/Loader";
import { Select } from "components/Select";
import { useCallback, useEffect, useState } from "react";
import {
  getDictSubtask,
  getOTKStatus,
  getOTKoptions,
} from "services/dashboard";
import styled from "styled-components";
import { TaskType } from "types/task";
import ReactEcharts from "echarts-for-react";
import { useForm } from "react-hook-form";

export interface Fonon {
  company: string;
  finished: number;
  current: number;
  planned: number;
}

export interface SPC2 {
  company: string;
  finished: number;
  current: number;
  planned: number;
}

export interface OTKChart {
  fonon: Fonon;
  "spc-2": SPC2;
}

interface IForm {
  subtask: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  select {
    width: 250px;
  }
`;

export const Label = styled.div`
  position: relative;
  line-height: 1;

  &::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const TableGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
`;

interface ICell {
  blackText?: boolean;
  boldText?: boolean;
  borderTop?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
}

const Cell = styled.div<ICell>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-weight: ${({ boldText }) => (boldText ? 700 : 400)};
  color: ${({ blackText }) =>
    blackText ? "black" : "rgba(30, 46, 102, 0.65);"};
  border-top: ${({ borderTop }) => borderTop && "1px solid lightgray"};
  border-left: ${({ borderLeft }) => borderLeft && "1px solid lightgray"};
  border-right: ${({ borderRight }) => borderRight && "1px solid lightgray"};
  border-bottom: ${({ borderBottom }) => borderBottom && "1px solid lightgray"};
`;

const TitleChart = styled.p`
  color: rgba(30, 46, 102, 0.65);
  text-align: center;
  font-weight: 700;
`;

export const OTKCharts = () => {
  const [data, setData] = useState<OTKChart | null>();
  const [subtasksName, setSubtasksName] = useState<string[]>();

  const { register, watch, reset } = useForm<IForm>({
    defaultValues: {
      subtask: "",
    },
  });

  const { subtask: watchSubtask } = watch();

  useEffect(() => {
    getOTKStatus(watchSubtask).then((res) => setData(res as OTKChart));
  }, [watchSubtask]);

  useEffect(() => {
    getOTKoptions().then((res) => {
      reset({ subtask: res[0] });
      setSubtasksName(res);
    });
  }, []);

  const getOptions = (planned: number, current: number, finished: number) => ({
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["30%", "60%"],
        label: {
          formatter: "{c}",
          position: "inside",
          color: "white",
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: planned, name: "Planowane" },
          { value: current, name: "W realizacji" },
          { value: finished, name: "Zakończone" },
        ],
        color: ["#23ABB6", "#37CC73", "#005AFF"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  });

  const renderSumValue = (task: "fonon" | "spc-2") => {
    const val = [
      data?.[task].current,
      data?.[task].finished,
      data?.[task].planned,
    ];

    if (data?.fonon) {
      return val.reduce((prev: any, curr: any) => prev + curr, 0).toFixed(2);
    } else {
      return 0;
    }
  };

  if (!data) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <Card>
        <Box px={4} py={3}>
          <Select label="Rodzaj prac" {...register("subtask")}>
            {/* <option key=""></option> */}
            {subtasksName?.map((name) => (
              <option key={name}>{name}</option>
            ))}
          </Select>
          <Box px={8}>
            <TableGrid>
              <Cell />
              <Cell borderRight boldText>
                OTK - FONON
              </Cell>
              <Cell boldText>OTK - SPC - 2</Cell>
              <Cell borderBottom />
              <Cell borderTop borderBottom borderRight>
                {watchSubtask === "Przyłącze" ? "Sztuki" : "Długość"}
              </Cell>
              <Cell borderTop borderBottom>
                {watchSubtask === "Przyłącze" ? "Sztuki" : "Długość"}
              </Cell>
              <Cell borderBottom borderRight>
                <Label color="#23ABB6">Planowane</Label>
              </Cell>
              <Cell borderBottom borderRight blackText>
                {data.fonon.planned}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
              <Cell borderBottom blackText>
                {data["spc-2"].planned}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
              <Cell borderBottom borderRight>
                <Label color="#37CC73">W realizacji</Label>
              </Cell>
              <Cell borderBottom borderRight blackText>
                {data.fonon.current}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
              <Cell borderBottom blackText>
                {data["spc-2"].current}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
              <Cell borderRight>
                <Label color="#005AFF">Zakończone</Label>
              </Cell>
              <Cell borderRight blackText>
                {data.fonon.finished}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
              <Cell blackText>
                {data["spc-2"].finished}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
              <Cell borderRight borderTop>
                <Label color="#7D33F2">Podsumowanie</Label>
              </Cell>
              <Cell blackText borderTop borderRight>
                {renderSumValue("fonon")}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
              <Cell blackText borderTop>
                {renderSumValue("spc-2")}{" "}
                {watchSubtask === "Przyłącze" ? "sztuki" : "km"}
              </Cell>
            </TableGrid>
          </Box>
        </Box>
      </Card>
      <Box>
        <ReactEcharts
          option={getOptions(
            data.fonon.planned,
            data.fonon.current,
            data.fonon.finished
          )}
          style={{ height: 400, transform: "scale(1.5)" }}
        />
        <TitleChart>OTK - FONON</TitleChart>
      </Box>
      <Box>
        <ReactEcharts
          option={getOptions(
            data["spc-2"].planned,
            data["spc-2"].current,
            data["spc-2"].finished
          )}
          style={{ height: 400, transform: "scale(1.5)" }}
        />
        <TitleChart>OTK - SPC - 2</TitleChart>
      </Box>
    </Wrapper>
  );
};
