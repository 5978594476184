import { format } from "date-fns";

export const FORMAT_DATE_BE = "dd-MM-yyyy";
export const formatDate = (date: number | Date, formatVariant?: string) =>
  format(date, formatVariant || "dd-MM-yyyy");

// converts string "yyyy-mm-dd" to "dd-mm-yyyy"
// if @val contains not a "date" string, returns @val
export const getConvertedDate = (val?: string) => {
  if (!val) return;
  const arr = val.split("-");
  if (arr.length === 3 && arr[0].length === 4) {
    return [arr[2], arr[1], arr[0]].join("-");
  }
  return val;
};

export const ddmmyyyyTo_mmddyyyy = (dateString: string) => {
  if (!dateString) return "-";

  const initial = dateString.split(/-/);

  return [initial[1], initial[0], initial[2]].join("-");
};
