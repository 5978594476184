import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import styled from "styled-components";
import { ReactComponent as ExampleFile } from "icons/exampleFile.svg";
import { Select } from "components/Select";

export const HelperPanelWrapper = styled.div`
  ${({ theme }) => `
    height: 64px;
    background: ${theme.palette.lightGrey};
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 ${theme.space[10]}px;
    gap: ${theme.space[3]}px;;
    >button: {
      border: solid red;
    }
  `};
`;

export const Header = styled.h3`
  font-weight: 700;
  margin-right: auto;
`;

export const ContentCard = styled(Card)`
  ${({ theme }) => `
    max-width: 828px;
    min-height: 455px;
    margin-top: ${theme.space[6]}px;
    margin-left: ${theme.space[7]}px;
    padding: ${theme.space[8]}px;
    display: flex;
    flex-direction: column;
  `};
`;

export const Heading = styled.h3`
  font-weight: 700;
  margin-right: auto;
  color: #5bacf8;
  width: 100%;
  display: flex;
`;

export const InputContainer = styled(Flex)`
  ${({ theme }) => `
    width: 100%;
    align-items: center;
    margin-bottom: ${theme.space[5]}px;
  `};
`;

export const StyledInput = styled(Input)`
  ${({ theme }) => `
    display: flex;
    width: 625px;
    margin-right: ${theme.space[3]}px;
  `};
`;

export const StyledSelect = styled(Select)`
  display: flex;
  width: 625px;
`;

export const InstructionContainer = styled(Flex)`
  width: 100%;
`;

export const ContentText = styled.p`
  ${({ theme }) => `
    display: flex;
    top: 0;
    max-width: 400px;
    vertical-align: baseline;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    margin: ${theme.space[3]}px ${theme.space[20]}px 0 0;
    font-size: 13px;
    color: #6F6C6D;
  `};
`;

export const ExampleFileContainer = styled(Flex)`
  ${({ theme }) => `
    border: solid 0.5px #D0D0D0;
    border-radius: ${theme.space[3]}px;
    width: 270px;
    height: 210px;
    align-items: center;
    flex-direction: column;
  `};
`;

export const StyledExampleFile = styled(ExampleFile)`
  margin-left: auto;
  margin-right: auto;
`;

export const MiddleTitle = styled.h3`
  margin-left: auto;
  margin-right: auto;
`;

export const StatusBar = styled.div`
  ${({ theme }) => `
    border-radius: ${theme.space[3]}px;
    width: 770px;
    height: 25px;
    background-color: #DDDDDD;
  `};
`;

export const Status = styled.div<{ status: number }>`
  ${({ theme, status }) => `
    border-radius: ${theme.space[3]}px;
    width: ${status}%;
    height: 25px;
    background-color: #5BACF8;
  `};
`;

export const LoadingText = styled.p`
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 780px;
  height: 0;
  vertical-align: baseline;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: 13px;
  color: #6f6c6d;
`;
