import React, { useState } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { IBacklog, deleteTask } from "services/backlog";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import {
  faPencilAlt,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Input } from "./Input";
import { Textarea } from "./Textarea";
import { updateStrings } from "yargs";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

interface IAboutModal {
  onCancelClick: () => void;
  handleConfirm: (
    id: string,
    data: { title: string; description: string; status: boolean }
  ) => void;
  onArchive: (id: string) => void;
  isOpen: boolean;
  taskDetails: IBacklog | null;
  handleDownloadAttachment: (id: string, fileName: string) => void;
}
interface IButton {
  variant: "success" | "cancel";
}
interface ITypography {
  label?: boolean;
}

const Root = styled(Box)`
  width: 500px;
`;
const Header = styled.p`
  font-size: 18px;
`;
const Typography = styled.p<ITypography>`
  ${({ label }) => `
    font-size: ${label ? "12px" : "15px"};
    line-height: 15px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    ${
      label &&
      `
      border-bottom: 1px solid #f2f2f2;
    `
    }
  `}
`;
const FileImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  > img {
    width: 100%;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
`;
// const Button = styled.div<IButton>`
//   ${({ theme, variant }) => `
//       background-color: ${
//         variant === "success" ? theme.palette.primary : "transparent"
//       };
//       padding: ${theme.space[2]}px;
//       cursor: pointer;
//   `}
// `;
const StyledFlex = styled(Flex)`
  width: 100%;
`;

const StyledBox = styled(Box)`
  width: 100%;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  opacity: 0.6;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const StyledFileName = styled(Box)`
  width: 100%;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledImg = styled.img`
  width: 100%;
  cursor: pointer;
`;

const defaultValue = {
  title: "",
  description: "",
};

export const AboutModal: React.FC<IAboutModal> = ({
  onCancelClick,
  onArchive,
  handleConfirm,
  isOpen,
  taskDetails,
  handleDownloadAttachment,
}) => {
  const [editItem, setEditItem] = useState({
    title: false,
    description: false,
  });
  const [updatedState, setUpdatedState] = useState({
    title: "",
    description: "",
  });
  const [displayImage, setDisplayImage] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const { handleSubmit, register, reset, watch } = useForm({
    defaultValues: defaultValue,
  });

  const titleWatch = watch("title");
  const descriptionWatch = watch("description");

  const handleEditItem = (item: "title" | "description") => {
    if (item === "title") setEditItem({ ...editItem, title: true });
    else setEditItem({ ...editItem, description: true });
  };

  const handleCancelEdit = (item: "title" | "description") => {
    reset({
      title: taskDetails?.title,
      description: taskDetails?.description,
    });
    if (item === "title") {
      setUpdatedState({ ...updatedState, title: "" });
      setEditItem({ ...editItem, title: false });
    } else {
      setUpdatedState({ ...updatedState, description: "" });
      setEditItem({ ...editItem, description: false });
    }
  };

  const handleConfirmField = (field: "title" | "description") => {
    if (field === "title") {
      setUpdatedState({ ...updatedState, title: titleWatch });
      setEditItem({ ...editItem, title: false });
    } else if (field === "description") {
      setUpdatedState({ ...updatedState, description: descriptionWatch });
      setEditItem({ ...editItem, description: false });
    }
  };

  const handleCancel = () => {
    setUpdatedState({
      title: "",
      description: "",
    });
    setConfirmDelete(false);
    onCancelClick();
  };

  const onSubmit = (data: { title: string; description: string }) => {
    if (data && taskDetails?.id)
      handleConfirm(taskDetails.id, { ...data, status: taskDetails.status });
  };

  const handleDelete = () => {
    if (taskDetails?.id) {
      onArchive(taskDetails.id);
    }
  };

  React.useEffect(() => {
    reset({
      title: taskDetails?.title,
      description: taskDetails?.description,
    });
  }, [taskDetails]);

  return (
    <Modal onCancelClick={onCancelClick} isOpen={isOpen}>
      <Root p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <StyledFlex justifyContent="space-between" alignItems="center">
              <Header>Szczegóły zadania</Header>
              <Typography label>{taskDetails?.createdAt}</Typography>
            </StyledFlex>
            <Box mb={4}>
              <Flex flexDirection="column">
                <Typography label>Zgłaszający</Typography>
                <Typography>{taskDetails?.user}</Typography>
              </Flex>
            </Box>
            <Box mb={4}>
              <Flex flexDirection="column">
                <Typography label>tytuł zadania</Typography>
                <Typography>{taskDetails?.title}</Typography>
              </Flex>
            </Box>
            <Flex flexDirection="column">
              <Typography label>Opis zadania</Typography>
              <Typography>{taskDetails?.description}</Typography>
            </Flex>
            <StyledFlex justifyContent="center">
              {taskDetails?.file ? (
                <StyledBox my={5} onClick={() => setDisplayImage(true)}>
                  <StyledImg src={`${taskDetails?.file}`} />
                </StyledBox>
              ) : (
                <StyledFileName
                  my={5}
                  onClick={() =>
                    handleDownloadAttachment(
                      taskDetails?.id,
                      taskDetails?.fileName
                    )
                  }
                >
                  {taskDetails?.fileName}
                </StyledFileName>
              )}
            </StyledFlex>
            <Flex justifyContent="space-between">
              {confirmDelete ? (
                <Flex justifyContent="space-between">
                  <Box mr={2}>
                    <Button
                      type="button"
                      bordered
                      onClick={() => setConfirmDelete(false)}
                    >
                      anuluj
                    </Button>
                  </Box>

                  <Button type="button" bordered onClick={() => handleDelete()}>
                    potwierdź
                  </Button>
                </Flex>
              ) : (
                loggedUserRoles?.includes(
                  "ROLE_DISRUPTIONS_BACKLOG_DELETE"
                ) && (
                  <Button
                    type="button"
                    bordered
                    onClick={() => setConfirmDelete(true)}
                  >
                    Zarchiwizuj
                  </Button>
                )
              )}
              <Button onClick={() => handleCancel()} bordered>
                Wróć
              </Button>
            </Flex>
          </>
        </form>
      </Root>
      {displayImage && (
        <FileImage>
          <CloseButton>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => setDisplayImage(false)}
            />
          </CloseButton>
          <img src={`${taskDetails?.file}`} />
        </FileImage>
      )}
    </Modal>
  );
};
