import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { fetchAPI } from "utilities/fetchAPI";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

interface ILoginFormData {
  email: string;
  password: string;
}

const filtersType = [
  "OR,ORR",
  "milestone",
  "OR",
  "ORR",
  "Tunel",
  "DOR",
  "FDS",
  "OTK",
  "OSZ",
  "OC",
  "Nr Certyfikacji WE",
  "Nr Dopuszczenia UTK",
  "Nr gotowości WE",
  "Task",
  "Bedrock",
  "disruptions",
];

export const LoginView = () => {
  const history = useHistory();
  const { setToken } = useAuthDispatchContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { register, handleSubmit } = useForm<ILoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const fetchProfileUser = () => {
    fetchAPI(`${process.env.REACT_APP_API_URL}/user/profile`).then(
      (response) => {
        localStorage.setItem("currentUser", JSON.stringify(response));
      }
    );
  };

  const onSubmit = handleSubmit((data) => {
    if (data.email.length && data.password.length) {
      fetchAPI(`${process.env.REACT_APP_API_URL}/login_check`, {
        method: "POST",
        body: JSON.stringify({ username: data.email, password: data.password }),
      })
        .then((response: any) => {
          setToken(response.token);
          fetchProfileUser();
          clearFilters();
          history.push("/");
        })
        .catch((e) => setErrorMessage("Błędnie wpisany email lub hasło."));
    }
  });

  const clearFilters = () => {
    filtersType.map((item) => {
      localStorage.removeItem(`${item}_filters`);
    });
  };

  return (
    <Root>
      <Card>
        <Box p={4}>
          <form onSubmit={onSubmit}>
            <Box mb={2}>
              <Input type="text" label="Email" {...register("email")} />
            </Box>
            <Box mb={4}>
              <Input type="password" label="Hasło" {...register("password")} />
            </Box>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            <Flex justifyContent="flex-end">
              <Button bordered>Zaloguj</Button>
            </Flex>
          </form>
        </Box>
      </Card>
    </Root>
  );
};
