import React, { useEffect, useState } from "react";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { addUserToGroups, removeUserFromGroup } from "services/user";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  StyledHeading,
  GroupDescriptionWrapper,
  GroupDescription,
  GroupMembers,
  AddUserButton,
  Title,
  UserHeadingRow,
  IconDelete,
  UserRow,
  PermissionsPanel,
  PermissionsHeadingRow,
  OpenAllOptionsButton,
  StyledHeadingFlex,
} from "./UserGroupForm.styled";
import { Divider } from "components/Divider";
import SearchInput from "components/Search/Search";
import { theme } from "config/theme";
import PermissionsCollapse from "./components/PermissionsCollapse";
import { IPagePermission, IUserGroup } from "types/permissions";
import { AddUserToGroupModal } from "components/AddUserToGroupModal";
import {
  addRolesToGroup,
  createUserGroup,
  getFormatedUserRoles,
  getUserGroupRoles,
  getUserGroupUsers,
  removeRolesFromGroup,
  updateUserGroup,
} from "services/permissions";
import { Box } from "components/Box";

export interface IUser {
  avatar?: string;
  email: string;
  firstname?: string;
  id: string;
  lastname?: string;
  partner?: string;
  position?: string;
  tag?: string;
  groups?: string[];
}

export interface IPassword {
  password?: string;
  repeatPassword?: string;
  oldPassword?: string;
}

interface UserGroupFormProps {
  chosenUserGroup?: IUserGroup | null;
  setIsUserGroupFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchUsersGroups: () => void;
}

export const UserGroupForm = ({
  chosenUserGroup,
  setIsUserGroupFormOpen,
  fetchUsersGroups,
}: UserGroupFormProps) => {
  const [pagePermissions, setPagePermissions] = useState<IPagePermission[]>([]);
  const [chosenRoles, setChosenRoles] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: chosenUserGroup,
  });

  const onAddUserClick = (newUser: IUser) => {
    if (
      !selectedUsers.find(
        (searchSelectedUser) => searchSelectedUser.id === newUser.id
      )
    ) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, newUser]);
      setIsCreateNewOpen(false);
    }
  };

  const handleUpdateGroupUsers = (groupId: string) => {
    if (chosenUserGroup) {
      getUserGroupUsers(chosenUserGroup.id).then(({ data }) => {
        data.map((oldUser) => {
          !selectedUsers.find((searchUser) => oldUser.id === searchUser.id) &&
            removeUserFromGroup(chosenUserGroup.id, oldUser.id).catch(() => {
              toast.error("Nie udało się zaktualizować grup użytkownika.");
            });
        });

        selectedUsers.map(
          (selectedUser) =>
            !data.find((searchUser) => selectedUser.id === searchUser.id) &&
            addUserToGroups(groupId, { user: selectedUser.id }).catch(() => {
              toast.error("Nie udało się zaktualizować grup użytkownika.");
            })
        );
      });
    } else {
      selectedUsers?.map((selectedUser) => {
        addUserToGroups(groupId, { user: selectedUser.id }).catch(() => {
          toast.error("Nie udało się zaktualizować grup użytkownika.");
        });
      });
    }
  };

  const handleUpdateGroupRoles = (groupId: string) => {
    if (chosenUserGroup) {
      getUserGroupRoles(chosenUserGroup.id).then((roles) => {
        try {
          const rolesToRemove = roles.filter(
            (role) => !chosenRoles.includes(role)
          );
          const rolesToAdd = chosenRoles.filter(
            (role) => !roles.includes(role)
          );

          rolesToRemove &&
            removeRolesFromGroup(chosenUserGroup.id, rolesToRemove);
          rolesToAdd && addRolesToGroup(chosenUserGroup.id, rolesToAdd);
        } catch (e) {
          toast.error("Nie udało się zaktualizować ról grupy użytkowników.");
        }
      });
    } else {
      addRolesToGroup(groupId, chosenRoles);
    }
  };

  const onsubmit = (data: IUserGroup) => {
    let keyData: keyof typeof data;
    for (keyData in data) {
      if (data[keyData] === undefined) delete data[keyData];
    }

    if (chosenUserGroup?.id) {
      const reqPayload = {
        humanizeName: data.humanizeName,
        groupRole: data.groupRole,
        description: data.description,
      };

      updateUserGroup(chosenUserGroup?.id, reqPayload)
        .then((editedUser) => {
          handleUpdateGroupUsers(editedUser?.id);
          handleUpdateGroupRoles(editedUser?.id);

          reset();
          setIsUserGroupFormOpen((prevState) => !prevState);
          setTimeout(() => {
            fetchUsersGroups();
          }, 1000);
          toast.success("Zaktualizowano grupe użytkowników.");
        })
        .catch(() => {
          toast.error("Nie udało się grupy użytkowników.");
        });
    } else {
      const reqPayload = {
        ...data,
        name: data.humanizeName,
        groupRole: "ROLE_USER",
      };

      createUserGroup(reqPayload)
        .then((newUserGroup) => {
          handleUpdateGroupUsers(newUserGroup.id);
          handleUpdateGroupRoles(newUserGroup.id);

          reset();
          setIsUserGroupFormOpen((prevState) => !prevState);
          setTimeout(() => {
            fetchUsersGroups();
          }, 1000);
          toast.success("Dodano grupe użytkowników.");
        })
        .catch(() => {
          toast.error("Nie udało się grupy użytkowników.");
        });
    }
  };

  const fetchPagePermissions = () => {
    getFormatedUserRoles().then((res) => {
      const restucturedRes = res.map((page) => ({
        ...page,
        viewRoles: page.children.length
          ? page.children.flatMap((subPage) => [
              ...subPage.viewRoles,
              ...subPage.children.flatMap((detail) => detail.viewRoles),
              ...subPage.children.flatMap((detail) =>
                detail.children.flatMap((subDetail) => subDetail.viewRoles)
              ),
            ])
          : page.viewRoles,
        editRoles: page.children.length
          ? page.children.flatMap((subPage) => [
              ...subPage.editRoles,
              ...subPage.children.flatMap((detail) => detail.editRoles),
              ...subPage.children.flatMap((detail) =>
                detail.children.flatMap((subDetail) => subDetail.editRoles)
              ),
            ])
          : page.editRoles,
        children: page.children.map((subPage) => ({
          ...subPage,
          viewRoles: [
            ...subPage.viewRoles,
            ...subPage.children.flatMap((detail) => detail.viewRoles),
            ...subPage.children.flatMap((detail) =>
              detail.children.flatMap((subDetail) => subDetail.viewRoles)
            ),
          ],
          editRoles: [
            ...subPage.editRoles,
            ...subPage.children.flatMap((detail) => detail.editRoles),
            ...subPage.children.flatMap((detail) =>
              detail.children.flatMap((subDetail) => subDetail.editRoles)
            ),
          ],
          children: subPage.children.map((detail) => ({
            ...detail,
            viewRoles: [
              ...detail.viewRoles,
              ...detail.children.flatMap((subDetail) => subDetail.viewRoles),
            ],
            editRoles: [
              ...detail.editRoles,
              ...detail.children.flatMap((subDetail) => subDetail.editRoles),
            ],
          })),
        })),
      }));

      setPagePermissions(restucturedRes);
    });
  };

  useEffect(() => {
    fetchPagePermissions();
  }, []);

  useEffect(() => {
    if (chosenUserGroup) {
      getUserGroupUsers(chosenUserGroup.id).then(({ data }) => {
        setSelectedUsers(data);
      });

      getUserGroupRoles(chosenUserGroup.id).then((data) => {
        setChosenRoles(data);
      });
    }
  }, [chosenUserGroup]);

  useEffect(() => {
    if (!searchValue) {
      fetchPagePermissions();
    } else {
      setPagePermissions((prevState) =>
        [
          ...prevState,
          ...prevState.map((page) => page.children),
          ...prevState.map((page) =>
            page.children.map((subPage) => subPage.children)
          ),
        ]
          .flat(2)
          .filter((page) =>
            page.name?.toLowerCase().includes(searchValue.toLowerCase())
          )
      );
    }
  }, [searchValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onsubmit)}>
        <StyledHeadingFlex justifyContent="space-between" alignItems="center">
          <StyledHeading>1. Ogólne informacje</StyledHeading>
          <Button type="submit" bordered>
            Zapisz
          </Button>
        </StyledHeadingFlex>

        <GroupDescriptionWrapper>
          <Input label="Nazwa grupy" {...register("humanizeName")} />
          <GroupDescription
            label="Opis grupy"
            textarea
            {...register("description")}
          />
        </GroupDescriptionWrapper>

        <Flex mt={6} alignItems="start">
          <Flex flexWrap="wrap">
            <StyledHeadingFlex>
              <StyledHeading>2. Dostęp do stron</StyledHeading>
            </StyledHeadingFlex>

            <PermissionsPanel>
              <Flex justifyContent={"space-between"} alignItems="center">
                <Title>Wybrane strony</Title>
                <SearchInput
                  placeholder="Szukaj"
                  value={searchValue}
                  onChange={setSearchValue}
                  background={theme.palette.lightGrey}
                />
              </Flex>
              <Divider mt={20} mb={20} />
              <PermissionsHeadingRow>
                <OpenAllOptionsButton
                  type="button"
                  bordered
                  onClick={() => {
                    setExpandAll((prevState) => !prevState);
                  }}
                >
                  {expandAll ? "ZWIŃ WSZYSTKO" : "ROZWIŃ WSZYSTKO"}
                </OpenAllOptionsButton>
                <Flex gap={16}>
                  <p>Wgląd</p>
                  <p>Edycja</p>
                </Flex>
              </PermissionsHeadingRow>

              {pagePermissions.map((page) => (
                <PermissionsCollapse
                  key={page.name}
                  title={page.name}
                  showIconCollapse={page.children.length > 0}
                  expandAll={expandAll}
                  chosenRoles={chosenRoles}
                  setChosenRoles={setChosenRoles}
                  viewRoles={page.viewRoles}
                  editRoles={page.editRoles}
                >
                  {page.children.map((subPage) => (
                    <Box key={subPage.name} pl={5}>
                      <PermissionsCollapse
                        title={subPage.name}
                        showIconCollapse={subPage.children.length > 0}
                        expandAll={expandAll}
                        chosenRoles={chosenRoles}
                        setChosenRoles={setChosenRoles}
                        viewRoles={subPage.viewRoles}
                        editRoles={subPage.editRoles}
                      >
                        {subPage.children.map((detail) => (
                          <Box key={detail.name} pl={5}>
                            <PermissionsCollapse
                              title={detail.name}
                              showIconCollapse={detail.children.length > 0}
                              expandAll={expandAll}
                              chosenRoles={chosenRoles}
                              setChosenRoles={setChosenRoles}
                              viewRoles={detail.viewRoles}
                              editRoles={detail.editRoles}
                            >
                              {detail.children.map((subDetail) => (
                                <Box key={subDetail.name} pl={5}>
                                  <PermissionsCollapse
                                    title={subDetail.name}
                                    chosenRoles={chosenRoles}
                                    setChosenRoles={setChosenRoles}
                                    viewRoles={subDetail.viewRoles}
                                    editRoles={subDetail.editRoles}
                                  />
                                </Box>
                              ))}
                            </PermissionsCollapse>
                          </Box>
                        ))}
                      </PermissionsCollapse>
                    </Box>
                  ))}
                </PermissionsCollapse>
              ))}
            </PermissionsPanel>
          </Flex>

          <Flex flexWrap="wrap">
            <StyledHeadingFlex>
              <StyledHeading> 3. Członkowie </StyledHeading>
            </StyledHeadingFlex>

            <GroupMembers>
              <Flex justifyContent={"space-between"} alignItems="center">
                <Title>Członkowie grupy</Title>
                <AddUserButton
                  bordered
                  type="button"
                  onClick={() => setIsCreateNewOpen(true)}
                >
                  DODAJ UŻYTKOWNIKA
                </AddUserButton>
              </Flex>
              <Divider mt={20} mb={20} />
              <UserHeadingRow>
                <p>Użytkownik</p>
                <p>Usuń</p>
              </UserHeadingRow>
              {selectedUsers.map((selectedUser) => (
                <UserRow key={selectedUser.id}>
                  <p>{selectedUser.email}</p>
                  <IconDelete
                    onClick={() => {
                      setSelectedUsers((prevSelectedUsers) =>
                        prevSelectedUsers.filter(
                          (filteredUser) => filteredUser.id !== selectedUser.id
                        )
                      );
                    }}
                  />
                </UserRow>
              ))}
            </GroupMembers>
          </Flex>
        </Flex>
      </form>

      <AddUserToGroupModal
        isOpen={isCreateNewOpen}
        handleCloseModal={() => setIsCreateNewOpen(false)}
        onAddUserClick={onAddUserClick}
      />
    </>
  );
};
