import { Button } from "components/Button";
import { Input } from "components/Input";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Flex } from "components/Flex";
import { Select } from "components/Select";
import { useFetchAPI } from "hooks/fetchAPI";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { DICTIONARY_LINES_URL } from "constants/path";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchTasks } from "services/tasks";
import { TaskType } from "types/task";
import { postKey } from "services/keys";
import { FormError } from "types/error";

interface IKeyFormData {
  keyNumber: string;
  keysQuantity: number;
  railwayLine: string;
  keyAmount: number;
  task: string;
}

interface IGrid {
  columns: number;
}

const Grid = styled.div<IGrid>`
  ${({ theme, columns }) => `
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;
const StyleSelect = styled(Select)`
  height: 38px;
`;

export const KeyForm = () => {
  const history = useHistory();
  const [availableORs, setAvailableORs] = useState([]);

  const { data: railwayLines } =
    useFetchAPI<Record<string, number>>(DICTIONARY_LINES_URL);

  const { register, handleSubmit, watch } = useForm<IKeyFormData>({
    defaultValues: {
      keyNumber: "",
      keysQuantity: undefined,
      railwayLine: "",
      keyAmount: undefined,
      task: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    const parsedData = {
      task: data.task,
      keyNumber: data.keyNumber,
      keysQuantity: data.keysQuantity,
    };

    postKey({
      key: parsedData,
    })
      .then((response) => {
        if (response.id) {
          history.push(`/keys/${response.id}`);
          toast.success("Zakłócenie zostało dodane.");
        } else {
          toast.error("Proszę uzupełnić pola.");
        }
      })
      .catch((error: FormError) => {
        toast.error(
          <>
            {error.map(({ field, message }) => (
              <div key={field}>
                <strong>{field}</strong>: {message}
              </div>
            ))}
          </>
        );
      });
  });

  useEffect(() => {
    fetchTasks({
      type: ["OR" as TaskType, "ORR" as TaskType],
      limit: 100,
      lineNumberFilter:
        watch("railwayLine") !== ""
          ? parseInt(watch("railwayLine"), 10)
          : undefined,
    }).then(({ data }) => {
      setAvailableORs(data);
    });
  }, [watch("railwayLine")]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <Flex alignItems="center" justifyContent="space-between" mb={5}>
          <FieldSetTitle>Gospodarka kluczami</FieldSetTitle>
          <Flex alignItems="center" justifyContent="space-between">
            <Button type="submit" bordered>
              Dodaj
            </Button>
          </Flex>
        </Flex>
        <Grid columns={4}>
          <Input label="Numer klucza" {...register("keyNumber")} />

          <Input label="Ilość kluczy" {...register("keysQuantity")} />

          <StyleSelect label="Linia kolejowa" {...register("railwayLine")}>
            <option value=""></option>
            {railwayLines
              ? Object.entries(railwayLines).map((item) => (
                  <option key={item[0]} value={item[1]}>
                    {item[0]}
                  </option>
                ))
              : null}
          </StyleSelect>

          <StyleSelect label="Wybierz obiekt" {...register("task")}>
            <option value="" />
            {availableORs?.map((object) => (
              <option value={object.id} key={object.id}>
                {object.name}
              </option>
            ))}
          </StyleSelect>
        </Grid>
      </form>
    </>
  );
};
