import React, { useEffect } from "react";
import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { PageLayout } from "components/PageLayout";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { updateCurrentUser, updateUserPassword } from "services/user";
import { useTableSidebarDispatchContext } from "contexts/TableSidebarContext";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePasswordSchema } from "schemas/SettingsPasswordForm";

export interface IUser {
  avatar?: string;
  email: string;
  firstname?: string;
  id: string;
  lastname?: string;
  partner?: string;
  position?: string;
  tag?: string;
}

export interface IPassword {
  password?: string;
  repeatPassword?: string;
  oldPassword?: string;
}

const WrapperInput = styled(Box)`
  min-width: 150px;
`;
const Label = styled.span`
  font-size: 10px;
  border-bottom: 1px solid #e6e5e5;
`;
const Typography = styled.p<{ variant?: "error" }>`
  line-height: 15px;
  margin: 0;
  ${({ theme, variant }) => `
    ${variant === "error" && `color: ${theme.palette.text.warring}`}
  `}
`;
const StyledWrapperBox = styled(Box)`
  background-color: #fff;
`;
const defaultValues = {
  email: "",
  firstname: "",
  lastname: "",
  partner: "",
  position: "",
  tag: "",
};
const defaultPasswordValues = {
  password: "",
  repeatPassword: "",
  oldPassword: "",
};
const defaultPasswordState = {
  password: "",
  repeatPassword: "",
  oldPassword: "",
};
const PreviewImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 auto;
`;

export const SettingsView = () => {
  const [user, setUser] = React.useState<IUser | null>(null);
  const [image, setImage] = React.useState<File | null>(null);
  const [preview, setPreview] = React.useState<string>();

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const { setTableData } = useTableSidebarDispatchContext();

  const { register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: formStatePassword,
    reset: resetPassword,
  } = useForm({
    defaultValues: defaultPasswordValues,
    resolver: yupResolver(updatePasswordSchema),
  });

  const onsubmit = (data: IUser) => {
    let keyData: keyof typeof data;
    for (keyData in data) {
      if (data[keyData] === undefined) delete data[keyData];
    }

    if (user) {
      if (preview) {
        user.avatar = preview;
      }

      const reqPayload = { ...user, ...data };

      updateCurrentUser(user.id, reqPayload)
        .then((res) => {
          localStorage.setItem("currentUser", JSON.stringify(reqPayload));
          toast.success("Zmieniono dane użytkownika");
        })
        .catch(() => {
          toast.error("Nie udało się zmienić danych.");
        });
    }
  };

  const onsubmitPassword = (data: IPassword) => {
    if (data.password && data.oldPassword && data.repeatPassword) {
      updateUserPassword({
        password: data.password,
        oldPassword: data.oldPassword,
        repeatPassword: data.repeatPassword,
      })
        .then((res) => {
          toast.success("Hasło zostało zmienione");
        })
        .catch(() => {
          toast.error("Nie udało się zmienić hasło.");
        })
        .finally(() => resetPassword(defaultPasswordState));
    }
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview("");
    }
  }, [image]);

  useEffect(() => {
    if (user) {
      const { firstname, lastname, partner, position, tag, email } = user;
      reset({
        email,
        firstname,
        lastname,
        partner,
        position,
        tag,
      });
    }
  }, [user]);

  useEffect(() => {
    const currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      const userParsed = JSON.parse(currentUser);
      setUser(userParsed);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTableData({ hiddenSidebar: true });
    });
  }, []);

  return (
    <PageLayout displaySidebarTable={false}>
      <form onSubmit={handleSubmit(onsubmit)}>
        <Box mx={10}>
          <Flex justifyContent="space-between" alignItems="center">
            <h3>Ustawienia użytkownika.</h3>
            <Button type="submit" bordered>
              Zapisz
            </Button>
          </Flex>
        </Box>
        <StyledWrapperBox mx={10} p={10} pr={20} mb={10}>
          <Flex justifyContent="flex-start" alignItems="center">
            <Box mr={10}>
              <Flex
                flexDirection="column"
                alignItems="center"
                style={{ width: "150px" }}
              >
                {preview ? (
                  <PreviewImage
                    src={preview}
                    style={{
                      objectFit: "cover",
                      width: "50px",
                      height: "50px",
                    }}
                  />
                ) : (
                  <Avatar url={user?.avatar} />
                )}

                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0];
                      if (file) {
                        setImage(file);
                      } else {
                        setImage(null);
                      }
                    }
                  }}
                />
                <Button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    fileInputRef.current?.click();
                  }}
                >
                  Zmień avatar
                </Button>
              </Flex>
            </Box>
            <Flex flexDirection="column">
              <Box mr={10}>
                <Flex>
                  <WrapperInput mr={2}>
                    <Label>Imię</Label>
                    <Input {...register("firstname")} />
                  </WrapperInput>
                </Flex>

                <Box>
                  <Flex>
                    <WrapperInput mr={2}>
                      <Label>Nazwisko</Label>
                      <Input {...register("lastname")} />
                    </WrapperInput>
                  </Flex>
                </Box>
              </Box>
            </Flex>

            <Flex flexDirection="column">
              <Box mr={10}>
                <Flex>
                  <WrapperInput mr={2}>
                    <Label>Email</Label>
                    <Input disabled {...register("email")} />
                  </WrapperInput>
                </Flex>

                <Flex>
                  <WrapperInput mr={2}>
                    <Label>Partner</Label>
                    <Input {...register("partner")} />
                  </WrapperInput>
                </Flex>
              </Box>
            </Flex>

            <Flex flexDirection="column">
              <Flex>
                <WrapperInput mr={2}>
                  <Label>Pozycja</Label>
                  <Input {...register("position")} />
                </WrapperInput>
              </Flex>

              <Flex>
                <WrapperInput mr={2}>
                  <Label>Tag</Label>
                  <Input {...register("tag")} />
                </WrapperInput>
              </Flex>
            </Flex>
          </Flex>
        </StyledWrapperBox>
      </form>
      <form onSubmit={handleSubmitPassword(onsubmitPassword)}>
        <Box px={10}>
          <Flex justifyContent="space-between" alignItems="center">
            <h3>Zmiana hasła</h3>
            <Button type="submit" bordered>
              Zmień hasło
            </Button>
          </Flex>
        </Box>
        <StyledWrapperBox mx={10} p={10} pr={20}>
          <Flex>
            <Flex flexWrap="wrap">
              <Box mr={2}>
                <Input
                  type="password"
                  label="stare hasło"
                  {...registerPassword("oldPassword")}
                />
                <Input
                  type="password"
                  label="nowe hasło"
                  {...registerPassword("password")}
                />
                <Input
                  type="password"
                  label="powtórz nowe hasło"
                  {...registerPassword("repeatPassword")}
                />
              </Box>
              {formStatePassword.errors.oldPassword && (
                <>
                  <Flex flexBasis="100%" justifyContent="flex-start">
                    <Typography variant="error">
                      {formStatePassword.errors.oldPassword.message}
                    </Typography>
                  </Flex>
                </>
              )}
              {formStatePassword.errors.password && (
                <>
                  <Flex flexBasis="100%" justifyContent="flex-start">
                    <Typography variant="error">
                      {formStatePassword.errors.password.message}
                    </Typography>
                  </Flex>
                </>
              )}
              {formStatePassword.errors.repeatPassword && (
                <>
                  <Flex flexBasis="100%" justifyContent="flex-start">
                    <Typography variant="error">
                      {formStatePassword.errors.repeatPassword.message}
                    </Typography>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </StyledWrapperBox>
      </form>
    </PageLayout>
  );
};
