import { useState, useEffect } from "react";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { PageLayout } from "components/PageLayout";
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import {
  RailwayLineContextProvider,
  useRailwayLineDispatchContext,
} from "contexts/RailwayLineContext";
import { RailwayLineDetails } from "containers/RailwayLineDetails";
import { Chart } from "components/Chart";
import { fetchChartLineStatus, IDataLine } from "services/tasks";
import { toast } from "react-toastify";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

const StyledBox = styled(Box)`
  height: calc(100vh - 220px);
  overflow: auto;
  overscroll-behavior: contain;
`;

const RailwayLine: React.FC = () => {
  const [dataLine, setDataLine] = useState<IDataLine[]>([]);
  const { lineNumber } = useParams<{ lineNumber: string }>();
  const { setLineNumber } = useRailwayLineDispatchContext();
  setLineNumber(Number(lineNumber));

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  useEffect(() => {
    fetchChartLineStatus(parseInt(lineNumber))
      .then((res) => {
        setDataLine(res);
      })
      .catch(() => toast.error("Nie udało się pobrać danych do wykresu."));
  }, [lineNumber]);

  return (
    <PageLayout pageTitle={`Szczegóły linii kolejowej ${lineNumber ?? ""}`}>
      <Card>
        <Tabs>
          <TabList>
            {loggedUserRoles?.includes("ROLE_LINES_OVERALL_LIST") && (
              <Tab>Dane ogólne</Tab>
            )}
            {loggedUserRoles?.includes("ROLE_LINES_CHARTS_LIST") && (
              <Tab>Wykres</Tab>
            )}
          </TabList>

          <StyledBox px={8} py={6}>
            {loggedUserRoles?.includes("ROLE_LINES_OVERALL_LIST") && (
              <TabPanel>
                <Box mb={6}>
                  <RailwayLineDetails />
                </Box>
              </TabPanel>
            )}

            {loggedUserRoles?.includes("ROLE_LINES_CHARTS_LIST") && (
              <TabPanel>
                <Box mb={6}>
                  <Chart dataLine={dataLine} />
                </Box>
              </TabPanel>
            )}
          </StyledBox>
        </Tabs>
      </Card>
    </PageLayout>
  );
};

export const RailwayLineView: React.FC = () => (
  <RailwayLineContextProvider>
    <RailwayLine />
  </RailwayLineContextProvider>
);
