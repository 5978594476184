import { ReactComponent as UserIcon } from "icons/user.svg";
import React from "react";
import styled from "styled-components";

export interface AvatarI {
  url?: string;
}

interface ImageI {
  url?: AvatarI["url"];
}

const Root = styled.div`
  ${({ theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40px;
    width: 40px;
    flex: 0 0 auto;
    border-radius: 50%;
    color: ${theme.palette.text};
    background-color: ${theme.palette.background};
    overflow: hidden;
  `}
`;

const Image = styled.div<ImageI>`
  ${({ url }) => `
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    ${url && `background-image: url('${url}');`}
    background-position: center;
    background-size: cover;
  `}
`;

export const Avatar: React.FC<AvatarI> = ({ url }) => (
  <Root>
    <UserIcon />
    <Image url={url} />
  </Root>
);
