import React, { useContext, useState } from "react";

export interface IFilters {
  lineNumber: string;
  chainageStartFrom: number | null;
  chainageStartTo: number | null;
  chainageEndFrom: number | null;
  chainageEndTo: number | null;
  attachments: string;
}

export interface IRailwayLinesStateContext {
  initialFilters: IFilters;
  filters: IFilters;
}

export interface IRailwayLinesDispatchContext {
  setFilters: (filters: IFilters) => void;
  cleanFilters: () => void;
}

const RailwayLinesStateContext = React.createContext<
  IRailwayLinesStateContext | undefined
>(undefined);

const RailwayLinesDispatchContext = React.createContext<
  IRailwayLinesDispatchContext | undefined
>(undefined);

export const RailwayLinesContextProvider: React.FC = ({ children }) => {
  const initialFilters: IFilters = {
    lineNumber: "",
    chainageStartFrom: null,
    chainageStartTo: null,
    chainageEndFrom: null,
    chainageEndTo: null,
    attachments: "",
  };
  const [filters, setFilters] = useState<IFilters>(initialFilters);

  const cleanFilters = () => {
    setFilters(initialFilters);
  };

  return (
    <RailwayLinesStateContext.Provider value={{ filters, initialFilters }}>
      <RailwayLinesDispatchContext.Provider
        value={{
          setFilters,
          cleanFilters,
        }}
      >
        {children}
      </RailwayLinesDispatchContext.Provider>
    </RailwayLinesStateContext.Provider>
  );
};

export const useRailwayLinesStateContext = () => {
  const context = useContext(RailwayLinesStateContext);
  if (!context) {
    throw new Error(
      "useRailwayLinesStateContext must be inside a RailwayLinesStateContextProvider with a value"
    );
  }
  return context;
};

export const useRailwayLinesDispatchContext = () => {
  const context = useContext(RailwayLinesDispatchContext);
  if (!context) {
    throw new Error(
      "useRailwayLinesDispatchContext must be inside a RailwayLinesStateDispatchProvider with a value"
    );
  }
  return context;
};
